import React from 'react';

// Icons
import buttonLeft from '../../../../assets/icons/button-left.svg';
import { ButtonRight } from '../../../../assets/icons/ButtonRight';

// Types
import { ButtonTabsProps } from './type';

//Styles Components
import { ImgTabsButton, TabsButton } from './styles';

export const ButtonTabs = ({ variant, onClick }: ButtonTabsProps) => (
  <TabsButton onClick={onClick}>
    {variant === 'left' ? (
      <ImgTabsButton src={buttonLeft} alt={`button-${variant}`} />
    ) : (
      <ButtonRight />
    )}
  </TabsButton>
);

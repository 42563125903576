import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { GraphQLError } from 'graphql/error';
import { InvalidationPolicyCache } from '@nerdwallet/apollo-cache-policies';
import {
  ApolloClient,
  ApolloProvider,
  FetchResult,
  from,
  HttpLink,
  NormalizedCacheObject,
  Observable,
} from '@apollo/client';
import { ContextSetter, setContext } from '@apollo/client/link/context';
import { ErrorHandler, onError } from '@apollo/client/link/error';
import { loadErrorMessages, loadDevMessages } from '@apollo/client/dev';
import { CachePersistor, SessionStorageWrapper } from 'apollo3-cache-persist';
import { MockApolloClient, createMockClient } from 'mock-apollo-client';
import { RetryLink } from '@apollo/client/link/retry';
import { SentryLink } from 'apollo-link-sentry';

// Sentry
import * as Sentry from '@sentry/react';

// Mocks
import { addMockHandlersToClient } from './mocks';

// Service
import { refreshToken } from './services/auth';

// Utils
import {
  getSessionStorageUser,
  setSessionStorageUser,
} from '../utils/sessionStorage';

// Policies
import { policies } from './policies';

if (import.meta.env.MODE === 'development') {
  // Adds messages only in a dev environment
  loadDevMessages();
  loadErrorMessages();
}

const useApolloMock = import.meta.env.VITE_USE_APOLLO_MOCK === 'true';

const httpLink = new HttpLink({ uri: import.meta.env.VITE_API_URL });

export let client: ApolloClient<NormalizedCacheObject>;

const retryLink = new RetryLink();
const sentryLink = new SentryLink({
  uri: import.meta.env.VITE_API_URL,
  setTransaction: false,
  attachBreadcrumbs: {
    includeQuery: true,
    includeVariables: true,
    includeError: true,
  },
});

export const cacheApolloSessionStorage = new InvalidationPolicyCache({
  typePolicies: policies,
  invalidationPolicies: {
    timeToLive: 3600 * 1000 * 3, // 3hr TTL on all types in the cache
  },
});

export let cacheApolloPersistor: CachePersistor<NormalizedCacheObject>;

export const AppApolloProvider = ({ children }: { children: ReactNode }) => {
  const [persistor, setPersistor] =
    useState<CachePersistor<NormalizedCacheObject>>();
  const setHeader = useCallback<ContextSetter>((_, { headers }) => {
    const dataUser = getSessionStorageUser();
    return {
      headers: {
        ...headers,
        authorization: dataUser?.accessToken
          ? `Bearer ${dataUser?.accessToken}`
          : '',
      },
    };
  }, []);
  const authMiddleware = setContext(setHeader);

  const setError = useCallback<ErrorHandler>(
    ({ graphQLErrors, networkError, operation, forward }) => {
      if (graphQLErrors) {
        for (const err of graphQLErrors) {
          switch (err.extensions.code) {
            case 'FORBIDDEN': {
              if (err?.message === 'Refresh token expire') {
                setSessionStorageUser(null);
              } else {
                return;
              }
              break;
            }
            case 'UNAUTHENTICATED': {
              // ignore 401 error for a refresh request
              if (operation.operationName === 'regenerateAccessToken') return;

              return new Observable<FetchResult>((observer) => {
                // used an annonymous function for using an async function
                (async () => {
                  try {
                    const user = getSessionStorageUser();
                    if (!user?.accessToken) {
                      throw new GraphQLError('Empty AccessToken');
                    }
                    const accessToken = await refreshToken(user?.refreshToken);

                    setSessionStorageUser({
                      ...user,
                      accessToken,
                    });

                    // Retry the failed request
                    const subscriber = {
                      next: observer.next.bind(observer),
                      error: observer.error.bind(observer),
                      complete: observer.complete.bind(observer),
                    };

                    forward(operation).subscribe(subscriber);
                  } catch (err) {
                    Sentry.captureException(err);
                    observer.error(err);
                  }
                })();
              });
            }
            case 'INTERNAL_SERVER_ERROR': {
              if (
                err?.message?.includes('Broken pipe') ||
                err?.message?.includes('Operation timed out') ||
                err?.message?.includes('write conflict or a deadlock')
              ) {
                return forward(operation);
              } else {
                return;
              }
            }
          }
        }
      }

      if (networkError) console.log(`[Network error]: ${networkError}`);
    },
    []
  );

  const errorLink = onError(setError);
  // TODO: fix persistCacheSync is necessary persistCache with loading

  useEffect(() => {
    async function init() {
      cacheApolloPersistor = new CachePersistor({
        cache: cacheApolloSessionStorage,
        storage: new SessionStorageWrapper(window.sessionStorage),
      });
      await cacheApolloPersistor.restore();
      setPersistor(cacheApolloPersistor);
      client = useApolloMock
        ? createMockClient()
        : new ApolloClient({
            link: from([
              retryLink,
              errorLink,
              authMiddleware,
              sentryLink,
              httpLink,
            ]),
            cache: cacheApolloSessionStorage,
            connectToDevTools: true,
          });
      if (useApolloMock) {
        addMockHandlersToClient(client as MockApolloClient);
      }
    }
    init().catch(console.error);
  }, []);

  return persistor && client ? (
    <ApolloProvider client={client}>{children}</ApolloProvider>
  ) : null;
};

import React, { useCallback, useState } from 'react';
import { format } from 'date-fns';

// Types
import { ShowOTActionProps } from './types';
import { variant } from '../../SpecialTag/types';

// Styles Components
import {
  ContainerContent,
  ContainerData,
  ContainerDescription,
  ContainerHeader,
  ContainerRefIcon,
  ContainerTitleArrow,
  DateContainer,
  InputAction,
  InputDescription,
  InputOperaris,
  RefCotic,
  RightArrow,
  SwitchButton,
  TagsContainer,
  TitleDate,
  TitleOrder,
} from '../MainStyledComponents';

// Components
import { SpecialTag } from '../../SpecialTag';
import { DateTime } from '../../../inputs/DateTime';

// Icons
import ArrowRight from '../../../../assets/icons/triangle-right.svg';

// Media
import { useIsLandscapeTablet } from '../../../../hooks/useMediaBreakPoints';
import { ContainerOTStyled } from './styles';
import { dataTransferNaming } from '../../../forms/ActionForms/constants';
import { useDispatch } from 'react-redux';
import { openModal } from '../../../../state/actions/ui/modal';
import { modalIds } from '../../../modals/SuperModal/types';
import { OrderState, OrderType } from '../../../../types/orders';

// Hooks

export const ActionOT = ({
  cotic,
  tag,
  date,
  endDate,
  operators,
  capitol,
  capitols,
  laneCutting,
  description,
  isTransferred = false,
  id,
  parentOrder,
  revalidatorFunction,
  state,
  stateParentExpeditionOrder,
}: ShowOTActionProps): JSX.Element => {
  const dispatch = useDispatch();
  const [showInfo, setShowInfo] = useState(false);
  const toggleInfo = useCallback(() => setShowInfo(!showInfo), [showInfo]);
  const isLandscapeTablet = useIsLandscapeTablet();
  const namingOperators =
    operators?.map(
      (operator: { name: string; surName: string }) =>
        `${operator.name} ${operator.surName}`
    ) ?? [];
  const openModalTransferSubOrder = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    e.stopPropagation();

    dispatch(
      openModal(modalIds.TRANSFER_SUBORDER_MODAL, {
        namingPersistForm: dataTransferNaming.SUB_ORDER_ACTION,
        orderToTransfer: {
          id,
          type: OrderType.Action,
          orderParent: parentOrder.id,
        },
        currentParentOrder: {
          id: parentOrder.id,
          type: parentOrder.type,
        },
        revalidatorFunction: revalidatorFunction,
      })
    );
  };
  return (
    <ContainerOTStyled>
      <ContainerHeader onClick={toggleInfo}>
        <TitleOrder>
          Actuació
          <TitleDate>{` ${format(
            new Date(date),
            'dd/MM/yyyy - HH:mm'
          )} a ${format(new Date(endDate), 'HH:mm')}`}</TitleDate>
        </TitleOrder>
        <ContainerTitleArrow>
          <ContainerRefIcon>
            <RefCotic>{cotic}</RefCotic>
            <TagsContainer>
              <SpecialTag
                variant={tag}
                sizeTag={isLandscapeTablet ? 'small' : 'default'}
              />
              <SpecialTag
                variant={isTransferred ? variant.Transferred : variant.Transfer}
                sizeTag={isLandscapeTablet ? 'small' : 'default'}
                onClick={
                  state !== OrderState.Annulled &&
                  stateParentExpeditionOrder !== OrderState.Annulled &&
                  stateParentExpeditionOrder !== OrderState.End &&
                  !isTransferred
                    ? (e) => openModalTransferSubOrder(e)
                    : undefined
                }
              />
            </TagsContainer>
          </ContainerRefIcon>
          <RightArrow
            src={ArrowRight}
            alt="Icona desplegar informació"
            showInfo={showInfo}
          />
        </ContainerTitleArrow>
      </ContainerHeader>
      {showInfo ? (
        <ContainerContent>
          <ContainerData>
            <DateContainer>
              <DateTime
                label="Data de registre:"
                labelHour="Hora inici:"
                isDisabled={true}
                initialDate={date}
              />
              <DateTime
                labelHour="Hora final:"
                onlyHour={true}
                isDisabled={true}
                initialDate={endDate}
              />
            </DateContainer>
            <InputOperaris
              label="Operaris"
              typeInput="normal"
              disabled={true}
              value={namingOperators?.join(', ')}
            />
            <InputAction
              label="Capitol"
              typeInput="normal"
              disabled={true}
              value={
                capitols?.find((capitolInter) => capitolInter.id === capitol)
                  ?.name
              }
            />
            <SwitchButton
              label="Tall de carril"
              currentState={laneCutting}
              isDisabled={true}
            />
          </ContainerData>
          <ContainerDescription>
            <InputDescription
              label="Descripció:"
              disabled={true}
              value={description}
            />
          </ContainerDescription>
        </ContainerContent>
      ) : null}
    </ContainerOTStyled>
  );
};

import React from 'react';

type CloseIconProps = {
  className?: string;
};

export const CloseIcon = ({ className }: CloseIconProps): JSX.Element => {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M0.76923 10L0 9.23077L4.23077 5L0 0.76923L0.76923 0L5 4.23077L9.23077 0L10 0.76923L5.76923 5L10 9.23077L9.23077 10L5 5.76923L0.76923 10Z"
        fill="#757575"
      />
    </svg>
  );
};

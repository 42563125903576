import React, { useLayoutEffect } from 'react';
import { useAsyncValue, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

// Components
import OperatorsAndVehicleForm from '../../../../../components/forms/ActionForms/OperatorsAndVehicles';

// Constants
import { formActionValidationNaming } from '../../../../../components/forms/ActionForms/constants';

// Types
import { type OperatorsAndVehiclesFormValues } from '../../../../../components/forms/ActionForms/OperatorsAndVehicles/types';
import { OrderActionReferences } from '../types';

// Redux
import { coticOTSelected } from '../../../../../state/actions/ui/coticOTSelectedGeneralView';

export default function OperatorsAndVehiclesFormValidationPage(): JSX.Element {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const loaderData = useAsyncValue() as OperatorsAndVehiclesFormValues &
    OrderActionReferences;

  const onFormSubmit = async () => {
    navigate('../location');
  };

  useLayoutEffect(() => {
    dispatch(
      coticOTSelected(
        'actions-validation',
        loaderData.cotic,
        loaderData.orderId
      )
    );
  }, []);

  return (
    <OperatorsAndVehicleForm
      onFormSubmit={onFormSubmit}
      initialData={loaderData}
      namingPersistForm={
        formActionValidationNaming.NEW_VALIDATION_OPERATORS_AND_VEHICLE_FORM
      }
      textButton="Validar"
    />
  );
}

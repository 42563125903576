import styled from 'styled-components';
import { DayPicker } from './DayPicker';

import { colors } from '../../../styles/colors';
import {
  spaces,
  breakPoints,
  percentBaseResponsive,
} from '../../../styles/spaces';

import { LabelProps } from './types';

import { Typography } from '../../display/Typography';
import { fontSize } from '../../../styles/fonts';

export const Container = styled.div`
  position: relative;
`;

export const Calendar = styled(DayPicker)`
  position: absolute;
  margin-top: 0.5rem;
  left: -13rem;
  padding: 2.5rem;

  @media (max-width: ${breakPoints.landscapeTablet *
    percentBaseResponsive}rem) {
    position: fixed;
    left: 0;
    bottom: 0;
    margin: 0;
    width: 100%;
    padding: 5rem 10rem;
  }

  @media (max-width: ${breakPoints.portraitTablet * percentBaseResponsive}rem) {
    max-height: 70%;
    overflow-y: auto;
    font-size: ${fontSize.S};
  }
`;

export const CloseIconContainer = styled.button`
  position: absolute;
  top: 4rem;
  right: 4rem;
  z-index: 999;
  svg > path {
    fill: ${colors.black};
  }
`;

export const InputContainer = styled.div`
  display: flex;
  align-items: flex-end;
  gap: ${spaces.xxs}rem;
`;

export const Label = styled(Typography)<LabelProps>``;

export const InputLabel = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spaces.xxxs}rem;
`;

export const InputDate = styled.input`
  appearance: none;
  font-size: ${fontSize.S};
  color: ${colors.black};
  width: 100%;
  max-width: 15rem;
  height: 4rem;
  padding: 0 1rem;
  border-radius: 0.5rem;
  border: 0.1rem ${colors.black} solid;

  :focus {
    outline: 0.08rem solid ${colors.black};
  }

  ::placeholder {
    color: ${colors.greySoftLight};
    opacity: 1;
  }

  @media (max-width: ${breakPoints.landscapeTablet *
    percentBaseResponsive}rem) {
    font-size: ${fontSize.XS};
  }
`;

export const TextInput = styled(Typography);

type Parent = {
  actionPkInitRoad?: string;
  pkInitRoad?: string;
  actionPkEndRoad?: string;
  pkEndRoad?: string;
};

export const pkValidation = (value: string, parent: Parent) => {
  const val = value.split('+');
  const initValue = (parent?.actionPkInitRoad?.split('+') ??
    parent?.pkInitRoad?.split('+')) as string[];
  const endValue = (parent?.actionPkEndRoad?.split('+') ??
    parent?.pkEndRoad?.split('+')) as string[];
  const maxKilometer = Math.max(Number(initValue[0]), Number(endValue[0]));
  const minKilometer = Math.min(Number(initValue[0]), Number(endValue[0]));
  const maxMeter =
    maxKilometer === Number(initValue[0])
      ? Number(initValue[1])
      : Number(endValue[1]);

  const minMeter =
    minKilometer === Number(initValue[0])
      ? Number(initValue[1])
      : Number(endValue[1]);

  if (Number(val[0]) === maxKilometer) {
    return Number(val[val.length - 1]) <= maxMeter;
  } else if (Number(val[0]) === minKilometer) {
    return Number(val[val.length - 1]) >= minMeter;
  } else {
    return Number(val[0]) > minKilometer && Number(val[0]) < maxKilometer;
  }
};

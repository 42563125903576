// Dependencies
import React, { useMemo } from 'react';

import {
  useReactTable,
  getCoreRowModel,
  createColumnHelper,
  flexRender,
} from '@tanstack/react-table';

// Types
import { VehiclesType, TableProps, columnNames } from './types';

// Mocks
import { mockData } from './mocks';

// Styles
import { Container, Table, Tbody, Th, Thead, Tr } from '../SummaryTablesStyles';

// Styled Components
import { TableContainerStyled, TdStyled } from './styles';

export const VehiclesTable = ({ data, ...props }: TableProps): JSX.Element => {
  const dataAll = data ? data : mockData;

  const columnHelper = createColumnHelper<VehiclesType>();

  const columns = useMemo(
    () => [
      columnHelper.accessor((row) => row.mat, {
        id: columnNames.mat.id,
        header: () => columnNames.mat.label,
      }),
      columnHelper.accessor((row) => row.driver, {
        id: columnNames.driver.id,
        header: () => columnNames.driver.label,
      }),
      columnHelper.accessor((row) => row.concession, {
        id: columnNames.concession.id,
        header: () => columnNames.concession.label,
      }),
    ],
    []
  );

  const table = useReactTable({
    data: dataAll,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <Container>
      <TableContainerStyled>
        <Table {...props}>
          <Thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <Th key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {table.getRowModel().rows.map((row) => (
              <Tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <TdStyled key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TdStyled>
                ))}
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainerStyled>
    </Container>
  );
};

import styled, { css } from 'styled-components';

import { colors } from '../../../../../styles/colors';

//Styled Components
import {
  Td,
  TableContainer,
  Th,
} from '../../summaryTables/SummaryTablesStyles';
import InputText from '../../../../inputs/InputText';
import { CellProps } from './types';
import {
  breakPoints,
  percentBaseResponsive,
} from '../../../../../styles/spaces';

export const TdStyled = styled(Td)``;

export const InputNumber = styled(InputText)`
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input {
    -moz-appearance: textfield;
  }
  width: 11rem;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 2.8rem;
`;

export const SubContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3rem;
`;

export const ContainerFilters = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 3.1rem;
`;

export const SubContainerFilters = styled(ContainerFilters)`
  align-items: flex-end;
`;

export const ContainerRowButton = styled.div`
  display: flex;
  align-items: flex-end;
  height: 100%;
`;

export const ContainerInputCompanies = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3rem;
`;

export const StyledTableContainer = styled(TableContainer)`
  max-width: 60rem;
  min-height: 19.1rem;
  max-height: 19.1rem;
  flex: 1;
  @media (max-width: ${breakPoints.landscapeTablet *
    percentBaseResponsive}rem) {
    max-width: initial;
  }
`;

export const Img = styled.img`
  width: 1.5rem;
  height: 2rem;
  cursor: pointer;
`;

export const Input = styled.input`
  width: 100%;
  padding: 0;
  border: none;
  appearance: none;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: ${colors.black};
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  &[type='number'] {
    -moz-appearance: textfield;
  }
  :focus {
    outline: none;
  }
`;

export const TRBody = styled.tr``;

export const ThStyled = styled(Th)<CellProps>`
  ${({ cell }) =>
    ({
      company: css`
        width: 50rem;
      `,
      operatorsNumber: css`
        width: 15rem;
      `,
      remove: css`
        width: 5.8rem;
      `,
    }[cell])};
`;

import { defer, LoaderFunction } from 'react-router-dom';

// Sentry
import * as Sentry from '@sentry/react';

// GQL
import { client } from '../../../../gql/client';
import {
  FETCH_AUX_MACHINES,
  FETCH_AUX_MACHINE_TYPES,
  FETCH_AUX_MACHINE_TYPE_BY_ID,
} from '../../../../gql/queries/actions/auxMachines';

// Utils
import { AuthContextType } from '../../../../hooks/useAuth';
import { decompressSessionStorage } from '../../../../utils/sessionStorage';

// Constants
import { formMachineryManagementNaming } from '../../../../components/forms/AddMachineryManagementForm/constants';
import {
  FETCH_MEASURE_TYPES,
  GET_MEASURE_TYPES_BY_ID,
} from '../../../../gql/queries/actions/measureTypes';

const loadTableData = async (userData: AuthContextType | null) => {
  try {
    if (userData?.user) {
      let auxMachinesToReturn;

      const {
        data: { auxMachines },
      } = await client.query({
        query: FETCH_AUX_MACHINES,
        fetchPolicy: 'cache-only',
      });
      if (!auxMachines || auxMachines.length === 0) {
        const {
          data: { auxMachines },
        } = await client.query({
          query: FETCH_AUX_MACHINES,
        });

        auxMachinesToReturn = auxMachines;
      } else {
        auxMachinesToReturn = auxMachines;
      }

      auxMachinesToReturn = [...auxMachinesToReturn].sort((a, b) =>
        a.description.localeCompare(b.description)
      );

      const [
        {
          data: { measureTypes },
        },
        {
          data: { auxMachineTypes },
        },
      ] = await Promise.all([
        client.query({
          query: FETCH_MEASURE_TYPES,
        }),
        client.query({
          query: FETCH_AUX_MACHINE_TYPES,
        }),
      ]);

      const auxMachinesData = await Promise.all(
        auxMachinesToReturn.map(
          async (machine: {
            id: string;
            auxMachineId: string;
            description: string;
            type: string;
            typeOfMeasurement: string;
            measureTypeIDs: string[];
            auxMachineTypeIDs: string[];
          }) => {
            const [
              {
                data: { measureType },
              },
              {
                data: { auxMachineType },
              },
            ] = await Promise.all([
              client.query({
                query: GET_MEASURE_TYPES_BY_ID,
                variables: {
                  id: machine?.measureTypeIDs[0],
                },
                fetchPolicy: 'cache-only',
              }),
              client.query({
                query: FETCH_AUX_MACHINE_TYPE_BY_ID,
                variables: {
                  id: machine?.auxMachineTypeIDs[0],
                },
                fetchPolicy: 'cache-only',
              }),
            ]);
            return {
              id: machine.id,
              auxMachineId: machine.auxMachineId,
              name: machine.description,
              type: auxMachineType.name,
              typeOfMeasurement: measureType.name,
            };
          }
        )
      );
      return {
        auxMachinesData,
        measureTypes,
        auxMachineTypes,
      };
    }
    return {};
  } catch (err) {
    Sentry.captureException(err);
    console.log('> User management loading error:', err);
    return true;
  }
};

export const MachineryManagementLoader: (
  machineryData: AuthContextType | null
) => LoaderFunction | undefined = (machineryData) => async () => {
  const data = await loadTableData(machineryData);

  let cacheData =
    sessionStorage.getItem(
      formMachineryManagementNaming.NEW_AUX_MACHINERY_MANAGEMENT_FORM
    ) || {};

  if (Object.keys(cacheData).length > 0) {
    cacheData = JSON.parse(decompressSessionStorage(cacheData as string));
  }

  return defer({
    data: {
      data,
      cacheData,
    },
  });
};

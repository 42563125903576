export const formAccidentNaming = {
  ADD_BASIC_INFO_ACCIDENT_FORM: 'add-basic-info-accident-form',
  NEW_ACCIDENT_FORM: 'new-accident-form',
  SELECTED_ACCIDENTS_FILES: 'selected-accidents-files',
  IMAGE_CANVAS: 'image-canvas',
  SVG_CANVAS: 'svg-canvas',
  ARRAY_CANVAS: 'array-canvas',
};

export const directoryNaming = {
  CANVAS: 'canvas-accident',
};

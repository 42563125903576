// Dependencies

import styled from 'styled-components';

// Spaces

import {
  spaces,
  breakPoints,
  percentBaseResponsive,
} from '../../../../styles/spaces';

// Color

import { colors } from '../../../../styles/colors';

// Fonts

import { fontSize } from '../../../../styles/fonts';

// Components

import { Typography } from '../../Typography';

import { RedArrow } from '../../../../assets/icons/RedArrow';

import { GreenArrow } from '../../../../assets/icons/GreenArrow';

import { InputRadio } from '../../../inputs/InputRadio';

import { CheckBox } from '../../../inputs/CheckBox';

import InputCalendar from '../../../inputs/Calendar/index';

// Types

import { cellProps, columnNames, trProps } from './types';

import DropDownInput from '../../../inputs/DropDown/DropDownInput';

export const Container = styled.div``;

export const FiltersContainer = styled.div`
  background-color: ${colors.verySoftRed};

  border-radius: 1rem;

  padding: ${spaces.xxl}rem;

  @media (max-width: ${breakPoints.smallDesktop * percentBaseResponsive}rem) {
    padding: ${spaces.xl}rem;
  }
`;

export const Title = styled(Typography)`
  font-size: ${fontSize.L};

  @media (max-width: ${breakPoints.landscapeTablet *
    percentBaseResponsive}rem) {
    font-size: ${fontSize.M};
  }
`;

export const DropDownInputStyled = styled(DropDownInput)`
  @media (max-width: ${breakPoints.smallDesktop * percentBaseResponsive}rem) {
    max-width: 17rem;
  }

  @media (max-width: ${breakPoints.landscapeTablet *
    percentBaseResponsive}rem) {
    max-width: 16rem;
  }
`;

export const SubContainerFilters = styled.div`
  display: flex;

  gap: ${spaces.l}rem;

  flex-wrap: wrap;

  padding-top: ${spaces.l}rem;
`;

export const ContainerButtons = styled.div`
  display: flex;

  align-items: flex-end;

  gap: ${spaces.s}rem;

  @media (max-width: ${breakPoints.portraitTablet * percentBaseResponsive}rem) {
    gap: 1rem;
  }
`;

export const TableContainer = styled.div`
  padding: ${spaces.xl}rem 0 0;

  overflow-x: auto;
`;

export const Table = styled.table`
  width: 100%;

  text-align: left;
`;

export const TextHeader = styled(Typography)`
  white-space: nowrap;
`;

export const Tbody = styled.tbody``;

export const Th = styled.th`
  border-bottom: 0.1rem solid ${colors.black};

  padding: 0 ${spaces.xxs}rem ${spaces.xxxs}rem 0;

  p {
    font-size: ${fontSize.S};
  }

  :first-child {
    padding-left: 0.8rem;
  }

  :last-child {
    padding-right: 0.8rem;
  }

  @media (max-width: ${breakPoints.landscapeTablet *
    percentBaseResponsive}rem) {
    p {
      font-size: ${fontSize.XS};
    }
  }
`;

export const Td = styled.td<cellProps>`
  border-bottom: 0.1rem solid ${colors.greyLight};

  vertical-align: middle;

  padding: ${spaces.xxs}rem ${spaces.m}rem ${spaces.xxs}rem 0;

  :first-child {
    padding-left: 0.8rem;
  }

  :last-child {
    padding-right: 0.8rem;
  }

  ${(props) =>
    props.isFigure && {
      paddingLeft: '1.5rem',
    }}

  ${(props) =>
    props.column === columnNames.capitol.id && {
      maxWidth: '12rem',
    }}

  ${(props) =>
    props.column === columnNames.name.id && {
      maxWidth: '14rem',
    }}



  ${(props) =>
    props.column === columnNames.subCapitol.id && {
      maxWidth: '12rem',
    }}



  ${(props) =>
    props.column === columnNames.road.id && {
      maxWidth: '12rem',
    }}

  ${(props) =>
    props.column === columnNames.description.id && {
      maxWidth: '45rem',
    }}

  @media (max-width: ${breakPoints.landscapeTablet *
  percentBaseResponsive}rem) {
    ${({ hiddenColumns }) =>
      hiddenColumns > 6 && {
        maxWidth: 'none',
      }}
  }
`;

export const TextCell = styled(Typography)`
  white-space: nowrap;

  overflow: hidden;

  text-overflow: ellipsis;

  @media (max-width: ${breakPoints.landscapeTablet *
    percentBaseResponsive}rem) {
    font-size: ${fontSize.XS};
  }
`;

export const FigureRed = styled.figure`
  width: 2.5rem;

  height: 2.5rem;

  border-radius: 100%;

  background-color: ${colors.clearRed};

  display: inline-flex;

  align-items: center;

  justify-content: center;
`;

export const FigureGreen = styled(FigureRed)`
  background-color: ${colors.softGreen};
`;

export const TitleInvisible = styled(Typography)`
  opacity: 0;

  position: absolute;
`;

export const GreenArrowStyled = styled(GreenArrow)`
  width: 0.8rem;

  height: 1.1rem;

  color: ${colors.forestGreen};
`;

export const RedArrowStyled = styled(RedArrow)`
  width: 0.8rem;

  height: 1.1rem;

  color: ${colors.red};
`;

export const InputRadioContainer = styled.div``;

export const InputRadioStyled = styled(InputRadio)``;

export const InputCheckContainer = styled.div``;

export const CheckBoxStyled = styled(CheckBox)``;

export const Tr = styled.tr<trProps>`
  cursor: pointer;
`;

export const InputCalendarStyled = styled(InputCalendar)``;

import React from 'react';

// Components Styled
import { Body, Container, Header, Button, CloseIconImg } from './styles';

// Types
import { ModalContainerProps } from './types';

// Components
import { Typography } from '../../../display/Typography';

export default function ModalContainer({
  children,
  onClickClose,
  title,
  width,
  modalType,
}: ModalContainerProps): JSX.Element {
  return (
    <Container width={width} type={modalType}>
      <Header>
        {title && (
          <Typography size="L" variant="semiBold">
            {title}
          </Typography>
        )}
        <Button onClick={onClickClose}>
          <CloseIconImg />
        </Button>
      </Header>
      <Body>{children}</Body>
    </Container>
  );
}

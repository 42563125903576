import React, { useRef, useState, useLayoutEffect, useCallback } from 'react';
import imageCompression from 'browser-image-compression';

// Sentry
import * as Sentry from '@sentry/react';

// Components
import ModalContainer from '../../containers/ModalContainer';

// Styled Components
import {
  Container,
  ContainerButtons,
  HiddenCanvas,
  PhotoPreview,
  ActionButton,
  LargeActionButton,
  Camera,
  ButtonLeftIconAction,
  ButtonRightIconAction,
  CameraContainer,
} from './styles';

// Colors
import { colors } from '../../../../styles/colors';

// Types
import { ModalProps } from './types';
import { Loader } from '../../../inputs/Loader';
import { Typography } from '../../../display/Typography';

// Icons
import { TakePhotoIcon } from '../../../../assets/icons/TakePhotoIcon';
import { CircularArrowsIcon } from '../../../../assets/icons/CircularArrowsIcon';

// Utils
import { generateFileName } from '../../../../utils/files';

export const OpenCameraModal = ({
  onClickClose,
  setImagesState,
  setFilesState,
  dataBaseNamingCaching,
  type,
  ...props
}: ModalProps): JSX.Element => {
  // Ref
  const videoRef = useRef<HTMLVideoElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  // States
  const [mediaStream, setMediaStream] = useState<MediaStream | null>(null);
  const [cameraPermission, setCameraPermission] = useState<boolean>(false);
  const [photoFromCamera, setPhotoFromCamera] = useState<string | null>(null);
  const [cameras, setCameras] = useState<MediaDeviceInfo[]>([]);
  const [selectedCamera, setSelectedCamera] = useState<
    MediaDeviceInfo | undefined
  >(undefined);
  const [selectedCameraIndex, setSelectedCameraIndex] = useState<number>(0);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [videoLoaded, setVideoLoaded] = useState(false);

  useLayoutEffect(() => {
    getCameras();

    return () => turnOffLastCamera();
  }, []);

  useLayoutEffect(() => {
    if (selectedCamera) {
      const constraints = {
        video: {
          deviceId: selectedCamera.deviceId,
        },
      };

      mediaStreamInit(constraints);
    }

    return () => turnOffLastCamera();
  }, [cameraPermission, selectedCamera]);

  const turnOffLastCamera = () => {
    if (videoRef.current && videoRef.current.srcObject) {
      const stream = videoRef.current.srcObject as MediaStream;
      const tracks = stream.getTracks();
      tracks.forEach((track) => track.stop());
      videoRef.current.srcObject = null;
    }
  };

  const stopCurrenStreaming = () => {
    mediaStream?.getTracks().forEach((track) => track.stop());
  };

  const getCameras = async () => {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const cameraDevices = devices.filter(
        (device) => device.kind === 'videoinput'
      );
      const cameraBack = cameraDevices.find((device, index) => {
        const isBack = device.label.includes('back');
        if (isBack) setSelectedCameraIndex(index);
        return isBack;
      });
      setCameraPermission(true);
      setCameras(cameraDevices);
      setSelectedCamera(cameraBack ? cameraBack : cameraDevices[0]);
    } catch (err) {
      Sentry.captureException(err);
      setErrorMessage(`Error accessing camera:  ${err}`);
    }
  };

  const mediaStreamInit = async (constraints: MediaStreamConstraints) => {
    try {
      const mediaStream = await navigator.mediaDevices.getUserMedia(
        constraints
      );
      setMediaStream(mediaStream);
      setCameraPermission(true);
      if (videoRef.current) {
        videoRef.current.srcObject = mediaStream;
        setVideoLoaded(true);
      }
    } catch (err) {
      Sentry.captureException(err);
      setErrorMessage(`Error accessing camera:  ${err}`);
    }
  };

  const resetCamera = () => {
    if (mediaStream) {
      stopCurrenStreaming();
      setMediaStream(null);
      setCameraPermission(false);
      if (videoRef.current) {
        videoRef.current.srcObject = null;
      }
    }
  };

  const takePhoto = () => {
    if (videoRef.current && canvasRef.current && videoLoaded) {
      const videoWidth = videoRef.current.videoWidth;
      const videoHeight = videoRef.current.videoHeight;

      canvasRef.current.width = videoWidth;
      canvasRef.current.height = videoHeight;
      const ctx = canvasRef.current.getContext('2d');
      if (ctx) {
        ctx.drawImage(videoRef.current, 0, 0, videoWidth, videoHeight);
        const photoDataURL = canvasRef.current.toDataURL('image/jpg');
        setPhotoFromCamera(photoDataURL);
      }
      stopCurrenStreaming();
    }
  };

  const handleCapturePhoto = async () => {
    if (canvasRef.current) {
      const photoBlob = dataURItoBlob(photoFromCamera || '');
      const creationDate = new Date();

      const file: File = new File(
        [photoBlob],
        `${generateFileName(
          type as string,
          dataBaseNamingCaching as string
        )}.png`,
        {
          type: 'image/png',
        }
      );

      let fileToSave = file;
      const sizeInMB = file.size / 1024 / 1000;

      if (sizeInMB > 3) {
        const blob = await imageCompression(file, {
          maxSizeMB: 3,
        });

        fileToSave = new File([blob], blob.name, {
          type: blob.type,
          lastModified: blob.lastModified,
        });
      }
      setFilesState((prevFiles) => [
        ...prevFiles,
        { id: fileToSave.name, file: fileToSave },
      ]);
      setImagesState((prevImages) => [
        ...prevImages,
        {
          id: fileToSave.name,
          date: creationDate.toISOString(),
          name: fileToSave.name,
        },
      ]);

      stopCurrenStreaming();
      onClickClose();
    }
  };

  const dataURItoBlob = (dataURI: string) => {
    const byteString = atob(dataURI.split(',')[1]);
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);

    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ab], { type: mimeString });
  };

  const handleCancel = () => {
    setPhotoFromCamera(null);
    resetCamera();
  };

  const changeCamera = useCallback(() => {
    if (cameras.length > 1) {
      stopCurrenStreaming();
      const nextCameraId = (selectedCameraIndex + 1) % cameras.length;
      setSelectedCameraIndex(nextCameraId);

      const newCamera = cameras.find(
        (camera) => camera.deviceId === cameras[nextCameraId].deviceId
      );
      setSelectedCamera(newCamera);
    }
  }, [cameras, selectedCameraIndex]);

  return (
    <ModalContainer
      width={72}
      modalType={'camera'}
      onClickClose={onClickClose}
      fill={colors.black}
    >
      <Container {...props}>
        {errorMessage ? (
          <Typography>{errorMessage}</Typography>
        ) : (
          <>
            {cameraPermission && !photoFromCamera ? (
              <CameraContainer>
                <Camera ref={videoRef} autoPlay playsInline />
              </CameraContainer>
            ) : !cameraPermission && !photoFromCamera ? (
              <Loader />
            ) : (
              <PhotoPreview
                src={photoFromCamera || undefined}
                alt="Photo took"
              />
            )}

            <ContainerButtons>
              {photoFromCamera ? (
                <>
                  <ButtonLeftIconAction
                    variant="check"
                    onClick={handleCapturePhoto}
                  />
                  <ButtonRightIconAction
                    variant="cancel"
                    onClick={handleCancel}
                  />
                </>
              ) : (
                <>
                  <LargeActionButton onClick={takePhoto}>
                    <TakePhotoIcon />
                  </LargeActionButton>
                  {cameras.length > 1 && (
                    <ActionButton onClick={changeCamera}>
                      <CircularArrowsIcon />
                    </ActionButton>
                  )}
                </>
              )}
            </ContainerButtons>
            <HiddenCanvas ref={canvasRef} />
          </>
        )}
      </Container>
    </ModalContainer>
  );
};

// Actions
import { OPEN_MODAL, CLOSE_MODAL } from '../../actions/ui/modal';

export const initialModalState = {
  id: null,
  data: null,
};

type actionType = {
  type: string;
  payload: { id: string; data: unknown };
};

export default function modal(
  state = initialModalState,
  { type, payload }: actionType
) {
  switch (type) {
    case OPEN_MODAL:
      return {
        ...state,
        id: payload.id,
        data: payload.data,
      };
    case CLOSE_MODAL:
      return {
        ...initialModalState,
      };
    default:
      return state;
  }
}

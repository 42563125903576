// Sentry
import * as Sentry from '@sentry/react';

// Utils
import { getFilesFromDirectory } from './files';

type imageData = {
  creatingDate: string;
  cloudinaryPublicId: string;
  name: string;
};

export const UploadImage = async (
  picturesDirectoryName: string,
  canvasDirectoryName?: string
) => {
  const arrayImages: imageData[] = [];

  const files = (await getFilesFromDirectory(picturesDirectoryName)).map(
    (item) => item.file
  );

  const imagesBlob = files.map((file) => new Blob([file], { type: file.type }));

  if (canvasDirectoryName) {
    const [fileCanvas] = await getFilesFromDirectory(canvasDirectoryName);
    if (fileCanvas) {
      const blobCanvas = new Blob([fileCanvas.file], {
        type: fileCanvas.file.type,
      });
      const nameCanvas = fileCanvas.file.name;

      const dataCanvas = new FormData();
      dataCanvas.append('file', blobCanvas);
      dataCanvas.append('upload_preset', `ml_ora_${import.meta.env.MODE}`);

      try {
        const response = await fetch(
          'https://api.cloudinary.com/v1_1/' +
            import.meta.env.VITE_CLOUD_NAME +
            '/image/upload',
          {
            method: 'POST',
            body: dataCanvas,
          }
        );

        const imageData = await response.json();

        arrayImages.push({
          creatingDate: imageData.created_at,
          cloudinaryPublicId: imageData.public_id,
          name: nameCanvas,
        });
      } catch (err) {
        Sentry.captureException(err);
        console.log('> Error uploading croquis', err);
      }
    }
  }

  try {
    await Promise.all(
      imagesBlob.map(async (file, index) => {
        const blob = file;
        const data = new FormData();
        data.append('file', blob);
        data.append('upload_preset', `ml_ora_${import.meta.env.MODE}`);

        const response = await fetch(
          'https://api.cloudinary.com/v1_1/' +
            import.meta.env.VITE_CLOUD_NAME +
            '/image/upload',
          {
            method: 'POST',
            body: data,
          }
        );

        const imageData = await response.json();
        arrayImages.push({
          creatingDate: imageData.created_at,
          cloudinaryPublicId: imageData.public_id,
          name: files[index].name,
        });
      })
    );
  } catch (err) {
    Sentry.captureException(err);
    console.log('> Error uploading images', err);
  }

  return arrayImages;
};

import { defer, LoaderFunction } from 'react-router-dom';

// Sentry
import * as Sentry from '@sentry/react';

// Constants
import {
  formActionNaming,
  formActionValidationNaming,
} from '../../../../../components/forms/ActionForms/constants';

// Utils
import { decompressSessionStorage } from '../../../../../utils/sessionStorage';

// Selectors
import { selectLocationValues } from '../../../../../gql/selectors/actions';
import { AuthContextType } from '../../../../../hooks/useAuth';
import { GET_ORDER_BY_ID } from '../../../../../gql/queries/orders/orders';
import { client } from '../../../../../gql/client';

const loadFormOptions = async (
  userData: AuthContextType | null,
  actionId: string
) => {
  try {
    if (userData?.user) {
      const locationValues = await selectLocationValues(actionId);
      return locationValues;
    }
  } catch (err) {
    Sentry.captureException(err);
    console.log('> Error loading location action data', err);
  }
};

export const LocationValidationLoader: (
  userData: AuthContextType | null
) => LoaderFunction = (userData) => async () => {
  const stringifyForm = sessionStorage.getItem(
    formActionValidationNaming.NEW_VALIDATION_LOCATION_FORM
  );

  const stringifyActionData = sessionStorage.getItem(
    formActionNaming.NEW_VALIDATION_ACTION_DATA
  );

  let sessionStorageData;
  let actionData;
  let parentExpeditionOrderStatus;
  let state;

  if (stringifyForm) {
    sessionStorageData = JSON.parse(decompressSessionStorage(stringifyForm));
  }

  if (stringifyActionData) {
    actionData = JSON.parse(decompressSessionStorage(stringifyActionData));
    const {
      data: { order },
    } = await client.query({
      query: GET_ORDER_BY_ID,
      variables: {
        id: actionData.orderId,
      },
    });
    const parentOrder = await client.query({
      query: GET_ORDER_BY_ID,
      variables: {
        id: order.parentExpeditionOrderIDs[0],
      },
    });
    parentExpeditionOrderStatus = parentOrder.data.order.state;
    state = order.state;
  }

  const loaderToResolve = await loadFormOptions(userData, actionData.id);

  const combinedData = {
    ...loaderToResolve,
    ...sessionStorageData,
    ...actionData,
    parentExpeditionOrderStatus,
    state,
    dataFromServer: loaderToResolve,
  };

  return defer({
    data: combinedData,
  });
};

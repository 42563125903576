import { defer, LoaderFunction } from 'react-router-dom';

// Sentry
import * as Sentry from '@sentry/react';

// Types
import { AuthContextType } from '../../../../../hooks/useAuth';

// GraphQL
import { FETCH_MATERIALS } from '../../../../../gql/queries/actions/materials';
import { client } from '../../../../../gql/client';
import { selectMaterialsValue } from '../../../../../gql/selectors/actions';

// Constants
import {
  formActionNaming,
  formActionValidationNaming,
} from '../../../../../components/forms/ActionForms/constants';

// Utils
import { decompressSessionStorage } from '../../../../../utils/sessionStorage';
import {
  FETCH_MEASURE_TYPES,
  GET_MEASURE_TYPES_BY_ID,
} from '../../../../../gql/queries/actions/measureTypes';
import {
  FETCH_CODIFICATIONS,
  GET_CODIFICATION_BY_ID,
} from '../../../../../gql/queries/actions/codification';
import {
  FETCH_SUBCODIFICATIONS,
  GET_SUBCODIFICATION_BY_ID,
} from '../../../../../gql/queries/actions/subCodification';
import { GET_ORDER_BY_ID } from '../../../../../gql/queries/orders/orders';

const loadFormValues = async (
  userData: AuthContextType | null,
  actionId: string
) => {
  try {
    if (userData?.user) {
      const formValues = await selectMaterialsValue(actionId);
      return formValues;
    }
  } catch (err) {
    Sentry.captureException(err);
    console.log(`> Erro loading materialsData`, err);
  }
};

export const MaterialsValidationLoader: (
  userData: AuthContextType | null
) => LoaderFunction | undefined = (userData) => async () => {
  const stringifyForm = sessionStorage.getItem(
    formActionValidationNaming.NEW_VALIDATION_MATERIALS_FORM
  );

  const stringifyActionData = sessionStorage.getItem(
    formActionNaming.NEW_VALIDATION_ACTION_DATA
  );

  let sessionStorageData;
  let actionData;
  let parentExpeditionOrderStatus;
  let state;

  if (stringifyForm) {
    sessionStorageData = JSON.parse(decompressSessionStorage(stringifyForm));
  }

  if (stringifyActionData) {
    actionData = JSON.parse(decompressSessionStorage(stringifyActionData));
    const {
      data: { order },
    } = await client.query({
      query: GET_ORDER_BY_ID,
      variables: {
        id: actionData.orderId,
      },
    });
    const parentOrder = await client.query({
      query: GET_ORDER_BY_ID,
      variables: {
        id: order.parentExpeditionOrderIDs[0],
      },
    });
    parentExpeditionOrderStatus = parentOrder.data.order.state;
    state = order.state;
  }

  const {
    data: { materials: materialsUnsorted },
  } = await client.query({
    query: FETCH_MATERIALS,
  });

  await Promise.all([
    client.query({
      query: FETCH_MEASURE_TYPES,
    }),
    client.query({
      query: FETCH_CODIFICATIONS,
    }),
    client.query({
      query: FETCH_SUBCODIFICATIONS,
    }),
  ]);

  const materialsData = await Promise.all(
    materialsUnsorted.map(
      async (material: {
        id: string;
        materialId: string;
        name: string;
        typeOfMeasurement: string;
        codification: string;
        subCodification: string;
        measureTypeIDs: string[];
        codificationIDs: string[];
        subCodificationIDs: string[];
      }) => {
        const [
          {
            data: { measureType },
          },
          {
            data: { codification },
          },
          {
            data: { subCodification },
          },
        ] = await Promise.all([
          client.query({
            query: GET_MEASURE_TYPES_BY_ID,
            variables: {
              id: material?.measureTypeIDs[0],
            },
            fetchPolicy: 'cache-only',
          }),
          client.query({
            query: GET_CODIFICATION_BY_ID,
            variables: {
              id: material?.codificationIDs[0],
            },
            fetchPolicy: 'cache-only',
          }),
          client.query({
            query: GET_SUBCODIFICATION_BY_ID,
            variables: {
              id: material?.subCodificationIDs[0],
            },
            fetchPolicy: 'cache-only',
          }),
        ]);

        return {
          id: material.id,
          materialId: material.materialId,
          name: material.name,
          typeOfMeasurement: measureType.name,
          codification: codification.name,
          subCodification: subCodification.name,
        };
      }
    )
  );

  const materials = [...materialsData].sort((a, b) =>
    a.materialId.localeCompare(b.materialId)
  );
  const loaderToResolve = await loadFormValues(userData, actionData.id);
  const combinedData = {
    ...loaderToResolve,
    ...sessionStorageData,
    ...actionData,
    parentExpeditionOrderStatus,
    state,
    dataFromServer: { ...loaderToResolve, materials },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    materialsData: materials.map((material: any) => ({
      ...material,
      materialUnitId: null,
    })),
  };

  return defer({
    data: combinedData,
  });
};

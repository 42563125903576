import React, { useCallback, useState, useRef, useEffect } from 'react';

// Sentry
import * as Sentry from '@sentry/react';

// Types
import { OTObject, ShowOTProps, GroupTypes } from './types';

// Styles Components
import {
  ContainerContent,
  ContainerData,
  ContainerDescription,
  ContainerHeader,
  ContainerOT,
  ContainerRefIcon,
  DateInput,
  InputDescription,
  RefCotic,
  RightArrow,
  ButtonSave,
  ButtonEdit,
  SpecialTagStyled,
} from './styles';

// Icons
import ArrowRight from '../../../../assets/icons/triangle-right.svg';

// Media
import { useIsLandscapeTablet } from '../../../../hooks/useMediaBreakPoints';
import {
  Controller,
  ControllerProps,
  SubmitHandler,
  useForm,
  useWatch,
} from 'react-hook-form';
import { useSubmit } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';

// GQL
import { client } from '../../../../gql/client';
import { GET_SUB_CAPITOLS_BY_IDS } from '../../../../gql/queries/orders/predefinedData';

// Components
import DropDownInput from '../../../inputs/DropDown/DropDownInput';

// Types
import { OptionSelected } from '../../../inputs/DropDown/DropDownList/types';
import { ImperativeRefInput } from '../../../inputs/DropDown/DropDownInput/types';

// Forms
import { schemaOT } from './schema';
import { createFormDataFromValues } from '../../../../utils/forms';
import {
  GET_CONCESSION_BY_ID,
  GET_ROADS_BY_IDS,
  GET_ROAD_BY_ID,
} from '../../../../gql/queries/geoPositions/roads';
import { difference } from '../../../../utils/difference';
import { Typography } from '../../Typography';
import InputText from '../../../inputs/InputText';
import { pkFormatter } from '../../../../utils/transform';
import { variant } from '../../SpecialTag/types';
import { useDispatch } from 'react-redux';
import { openModal } from '../../../../state/actions/ui/modal';
import { modalIds } from '../../../modals/SuperModal/types';
import { OrderType } from '../../../../types/orders';
import { TitleOrder } from '../MainStyledComponents';
import { ArrowRadioGroup } from '../../../../pages/main/comunications/NewComunication/styles';
import { PartialDirection } from '../../../inputs/buttons/ArrowDirectionButton/types';
import { ActionDirection } from '../../../forms/ActionForms/LocationForm/types';
import { UNSPECIFIC } from '../../../../types/utils';
import { Margin } from '../../../../types/workOrder';

export const WorkOrder = ({
  id,
  name,
  cotic,
  type,
  tag,
  date,
  capitol,
  subCapitol,
  concession,
  concessions,
  road,
  roadsValues,
  capitols,
  pkInitRoad,
  pkEndRoad,
  pkInit,
  pkEnd,
  description,
  subCapitolsValues,
  modelTypeId,
  revalidatorFunction,
  indexExpedition,
  state,
  classification,
  classifications,
  disabled,
  groupTracks,
  track,
  lane,
  groupLanes,
  margin,
  direction,
}: ShowOTProps): JSX.Element => {
  const dispatch = useDispatch();
  const [showInfo, setShowInfo] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const toggleInfo = useCallback(() => setShowInfo(!showInfo), [showInfo]);
  const toggleEdit = useCallback(
    () => setIsDisabled(!isDisabled),
    [isDisabled]
  );
  const submit = useSubmit();
  const subCapitolDropDownRef = useRef<ImperativeRefInput>(null);

  const isLandscapeTablet = useIsLandscapeTablet();
  const [errorMessage, setErrorMessage] = useState<string>('');

  const initialData: OTObject = {
    capitols,
    concessions,
    date,
    pkInitRoad,
    pkEndRoad,
    pkInit,
    pkEnd,
    description,
    capitol,
    concession,
    road,
    roads: roadsValues,
    subCapitol,
    subCapitols: subCapitolsValues,
    classification,
    classifications,
    groupTracks,
    track,
    lane,
    groupLanes,
    margin,
    direction,
  };

  const {
    handleSubmit,
    control,
    setValue,
    register,
    formState: { errors },
  } = useForm<OTObject>({
    defaultValues: initialData,
    resolver: yupResolver(schemaOT),
  });

  const roads = useWatch({
    control,
    name: 'roads',
  });

  const subCapitols = useWatch({
    control,
    name: 'subCapitols',
  });

  const watchedFields = useWatch({
    control,
    defaultValue: initialData,
  });

  const onSelectCapitol = useCallback(
    (
      option: OptionSelected | undefined,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onChange: (...event: any[]) => void
    ) => {
      try {
        const { readSubCapitols } = client.readQuery({
          query: GET_SUB_CAPITOLS_BY_IDS,
          variables: {
            ids: option?.data ?? [],
          },
        });
        setValue('subCapitols', readSubCapitols);
        subCapitolDropDownRef.current?.clearSelected();
        setValue('subCapitol', '');
      } catch (err) {
        Sentry.captureException(err);
        console.log(err);
      }
      onChange(option?.value ?? '');
    },
    []
  );

  const renderSubCapitols = useCallback<
    ControllerProps<OTObject, 'subCapitol'>['render']
  >(
    ({ field: { onChange }, formState: { defaultValues } }) => {
      const options =
        subCapitols?.map((subCapitol) => ({
          label: subCapitol?.name || '',
          value: subCapitol?.id || '',
          data: subCapitol?.elementOrdersIds || '',
        })) || [];
      return (
        <DropDownInput
          ref={subCapitolDropDownRef}
          inputSize="L"
          onChangeSelected={(option) => onChange(option?.value ?? '')}
          typeDropDown="Default"
          labelText="Subcapitol"
          placeholder="Selecciona"
          defaultValue={defaultValues?.subCapitol}
          options={options}
          isDisabled={isDisabled}
          borderError={!subCapitol && !!errors.subCapitol}
        />
      );
    },
    [subCapitols, isDisabled, showInfo]
  );

  const roadDropDownRef = useRef<ImperativeRefInput>(null);
  const onSelectConcession = useCallback(
    (
      option: OptionSelected | undefined,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onChange: (...event: any[]) => void
    ) => {
      const tasks = async () => {
        const {
          data: { concession },
        } = await client.query({
          query: GET_CONCESSION_BY_ID,
          variables: { id: option?.value },
        });

        const { readRoads } = client.readQuery({
          query: GET_ROADS_BY_IDS,
          variables: { ids: concession.roadIDs ?? [] },
        });

        setValue('roads', readRoads);

        roadDropDownRef.current?.clearSelected();
        setValue('road', '');

        onChange(option?.value ?? '');
      };
      tasks().catch((err) => {
        Sentry.captureException(err);
        console.log(err);
      });
    },
    []
  );

  const onSelectRoad = useCallback(
    (
      option: OptionSelected | undefined,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onChange: (...event: any[]) => void
    ) => {
      const tasks = async () => {
        const {
          data: { road },
        } = await client.query({
          query: GET_ROAD_BY_ID,
          variables: { id: option?.value },
        });

        setValue(
          'pkInitRoad',
          pkFormatter(road.pkInitKm, road.pkInitMeter, true)
        );
        setValue('pkEndRoad', pkFormatter(road.pkEndKm, road.pkEndMeter, true));

        onChange(option?.value ?? '');
      };
      tasks().catch((err) => {
        Sentry.captureException(err);
        console.log(err);
      });
    },
    []
  );

  useEffect(() => {
    if (initialData?.road && roads) {
      const currentRoad =
        roads?.find((road) => road.name === initialData.road) ||
        roads?.find((road) => road.id === initialData.road);
      const tasks = async () => {
        const {
          data: { road },
        } = await client.query({
          query: GET_ROAD_BY_ID,
          variables: { id: currentRoad?.id },
        });
        setValue(
          'pkInitRoad',
          pkFormatter(road.pkInitKm, road.pkInitMeter, true)
        );
        setValue('pkEndRoad', pkFormatter(road.pkEndKm, road.pkEndMeter, true));
      };
      tasks().catch((err) => {
        Sentry.captureException(err);
        console.log(err);
      });
    }
  }, []);

  const onFormSubmit: SubmitHandler<OTObject> = async () => {
    const dataDifference = difference(watchedFields, initialData);
    if (Object.keys(dataDifference).length > 0) {
      setIsDisabled(true);
      const formData = createFormDataFromValues({
        ...dataDifference,
        id,
        cotic,
        modelTypeId,
        type,
      });
      submit(formData, {
        method: 'POST',
        action: '.',
      });
    } else {
      setErrorMessage(
        'You must make at least one change to the form before proceeding'
      );
    }
  };

  const openModalChangeState: React.MouseEventHandler<HTMLDivElement> = (
    event
  ) => {
    dispatch(
      openModal(modalIds.CHANGE_STATE_MODAL, {
        title: "Vols canviar l'estat de l'ordre de treball?",
        titleSaveModal: "Esteu segur d'aquest canvi?",
        indexExpedition,
        revalidatorFunction,
        currentState: state,
        orderId: id,
      })
    );

    event.stopPropagation();
  };

  return (
    <ContainerOT>
      <ContainerHeader onClick={toggleInfo}>
        <TitleOrder>{name}</TitleOrder>
        <ContainerRefIcon>
          <RefCotic size="S">
            {Array.isArray(cotic) ? cotic?.join('.') : cotic}
          </RefCotic>
          {type !== OrderType.Accident && (
            <SpecialTagStyled
              variant={tag}
              sizeTag={isLandscapeTablet ? 'small' : 'default'}
              onClick={openModalChangeState}
            />
          )}
          <RightArrow
            src={ArrowRight}
            alt="Icona desplegar informació"
            showInfo={showInfo}
          />
        </ContainerRefIcon>
      </ContainerHeader>
      {showInfo && (
        <ContainerContent onSubmit={handleSubmit(onFormSubmit)}>
          <ContainerData>
            <Controller
              control={control}
              name="date"
              render={({ field: { onChange } }) => {
                return (
                  <DateInput
                    label="Data de registre:"
                    labelHour="Hour:"
                    onChangeDate={(value) => onChange(value)}
                    initialDate={date}
                    borderError={!date && !!errors.date}
                    isDisabled={isDisabled}
                  />
                );
              }}
            />

            <Controller
              control={control}
              name="capitol"
              render={({
                field: { onChange },
                formState: { defaultValues },
              }) => {
                const options =
                  defaultValues?.capitols?.map((capitol) => ({
                    label: capitol?.name || '',
                    value: capitol?.id || '',
                    data: capitol?.subCapitolsIDs || '',
                  })) || [];
                return (
                  <DropDownInput
                    inputSize={'L'}
                    onChangeSelected={(option) =>
                      onSelectCapitol(option, onChange)
                    }
                    typeDropDown="Default"
                    labelText="Capitol"
                    placeholder="Selecciona"
                    defaultValue={defaultValues?.capitol}
                    options={options}
                    isDisabled={isDisabled}
                    borderError={!capitol && !!errors.capitol}
                  />
                );
              }}
            />
            <Controller
              control={control}
              name="subCapitol"
              render={renderSubCapitols}
            />
            <Controller
              control={control}
              name="concession"
              render={({
                field: { onChange },
                formState: { defaultValues },
              }) => {
                const options =
                  defaultValues?.concessions?.reduce((acc, curr) => {
                    if (curr?.name !== 'CX') {
                      acc.push({
                        label: curr?.name || '',
                        value: curr?.id || '',
                      });
                    }

                    return acc;
                  }, [] as GroupTypes[]) || [];
                return (
                  <DropDownInput
                    inputSize={'XS'}
                    onChangeSelected={(option) =>
                      onSelectConcession(option, onChange)
                    }
                    typeDropDown="Default"
                    labelText="Concessió"
                    placeholder="Selec."
                    defaultValue={defaultValues?.concession}
                    options={options}
                    isDisabled={isDisabled}
                    borderError={!!errors.concession}
                  />
                );
              }}
            />
            <Controller
              control={control}
              name="road"
              render={({
                field: { onChange },
                formState: { defaultValues },
              }) => {
                const options =
                  roads?.map((road) => ({
                    label: road?.name || '',
                    value: road?.id || '',
                  })) || [];

                return (
                  <DropDownInput
                    inputSize="XS"
                    ref={roadDropDownRef}
                    onChangeSelected={(option) =>
                      onSelectRoad(option, onChange)
                    }
                    typeDropDown="Default"
                    labelText="Carretera"
                    placeholder="Selec."
                    borderError={!!errors.road}
                    defaultValue={defaultValues?.road}
                    options={options}
                    isDisabled={isDisabled}
                  />
                );
              }}
            />

            <InputText
              {...register('pkInitRoad')}
              label="Inici"
              typeInput="normal"
              disabled
              placeholder="000 + 000"
              inputSize={'11'}
              borderError={!pkInitRoad && !!errors.pkInitRoad}
            />

            <InputText
              {...register('pkEndRoad')}
              label="Final"
              typeInput="normal"
              disabled
              placeholder="000 + 000"
              inputSize={'11'}
              borderError={!pkEndRoad && !!errors.pkEndRoad}
            />

            <InputText
              {...register('pkInit')}
              label="Pk Inicial"
              typeInput="normal"
              disabled={isDisabled}
              defaultValue={pkInit}
              inputSize={'11'}
              borderError={!pkInit && !!errors.pkInit}
            />

            <InputText
              {...register('pkEnd')}
              label="Pk Final"
              typeInput="normal"
              disabled={isDisabled}
              defaultValue={pkEnd}
              inputSize={'11'}
              borderError={!pkEnd && !!errors.pkEnd}
            />

            <Controller
              control={control}
              name="classification"
              render={({
                field: { onChange },
                formState: { defaultValues },
              }) => {
                const options =
                  classifications?.map((classification) => ({
                    label: classification?.name || '',
                    value: classification?.id || '',
                  })) || [];
                return (
                  <DropDownInput
                    inputSize="S"
                    onChangeSelected={(option) => onChange(option?.value ?? '')}
                    typeDropDown="Default"
                    labelText="Classificació"
                    placeholder="Selecciona"
                    defaultValue={
                      classification || defaultValues?.classification
                    }
                    borderError={!!errors.classification}
                    options={options}
                    isDisabled={isDisabled}
                  />
                );
              }}
            />

            <Controller
              name="track"
              control={control}
              render={({
                field: { onChange },
                formState: { defaultValues },
              }) => {
                const options =
                  defaultValues?.groupTracks?.map((track) => ({
                    label: track?.name || '',
                    value: track?.value || '',
                  })) || [];

                return (
                  <DropDownInput
                    labelText="Tipus vial"
                    placeholder={'Selecciona.'}
                    inputSize={'M'}
                    options={options}
                    typeDropDown={'Default'}
                    onChangeSelected={(option) => onChange(option?.value ?? '')}
                    defaultValue={defaultValues?.track}
                    borderError={!!errors.track}
                    isDisabled={isDisabled}
                  />
                );
              }}
            />
            <Controller
              control={control}
              name="direction"
              render={({
                field: { onChange },
                formState: { defaultValues },
              }) => {
                const defaultStatusValue: PartialDirection = {
                  [ActionDirection.DOWN]: 'down',
                  [ActionDirection.TOP]: 'top',
                  [ActionDirection.BOTH]: 'both',
                };

                const directionOptions: PartialDirection = {
                  down: ActionDirection.DOWN,
                  top: ActionDirection.TOP,
                  both: ActionDirection.BOTH,
                };
                return (
                  <ArrowRadioGroup
                    directions={['down', 'both', 'top']}
                    name="direction"
                    label={'Sentit'}
                    defaultValue={
                      defaultStatusValue[
                        defaultValues?.direction as ActionDirection
                      ]
                    }
                    onChangeDirection={(dir) => {
                      dir
                        ? onChange(directionOptions[dir])
                        : onChange(UNSPECIFIC);
                    }}
                    borderError={!!errors.direction}
                    disabled={isDisabled}
                  />
                );
              }}
            />
            <Controller
              control={control}
              name="margin"
              render={({
                field: { onChange },
                formState: { defaultValues },
              }) => {
                const defaultStatusValue: PartialDirection = {
                  [Margin.BOTH]: 'both',
                  [Margin.LEFT]: 'left',
                  [Margin.RIGHT]: 'right',
                };

                const options: PartialDirection = {
                  both: Margin.BOTH,
                  left: Margin.LEFT,
                  right: Margin.RIGHT,
                };

                return (
                  <ArrowRadioGroup
                    directions={['left', 'both', 'right']}
                    name="margin"
                    label={'Marge'}
                    defaultValue={
                      defaultStatusValue[defaultValues?.margin as Margin]
                    }
                    borderError={!!errors.margin}
                    onChangeDirection={(dir) => {
                      onChange(options[dir]);
                    }}
                    disabled={isDisabled}
                  />
                );
              }}
            />
            <Controller
              name="lane"
              control={control}
              render={({
                field: { onChange },
                formState: { defaultValues },
              }) => {
                const options =
                  defaultValues?.groupLanes?.map((lane) => ({
                    label: lane?.name || '',
                    value: lane?.value || '',
                  })) || [];
                return (
                  <DropDownInput
                    labelText="Carril"
                    placeholder={'Selecciona.'}
                    inputSize={'S'}
                    options={options}
                    typeDropDown={'Default'}
                    onChangeSelected={(option) => onChange(option?.value ?? '')}
                    defaultValue={defaultValues?.lane}
                    borderError={!!errors.lane}
                    isDisabled={isDisabled}
                  />
                );
              }}
            />
          </ContainerData>
          <ContainerDescription>
            <InputDescription
              {...register('description')}
              label="Descripció"
              disabled={isDisabled}
              defaultValue={description}
              borderError={!description && !!errors.description}
            />
            {isDisabled ? (
              <ButtonEdit
                variant={'edit'}
                onClick={toggleEdit}
                type={'button'}
                disabled={(tag && tag !== variant.Open) || disabled}
              />
            ) : (
              <ButtonSave variant={'save'} type="submit" />
            )}
          </ContainerDescription>
          {errorMessage && (
            <Typography colorText="lightCoralRed" size="XS">
              {errorMessage}
            </Typography>
          )}
        </ContainerContent>
      )}
    </ContainerOT>
  );
};

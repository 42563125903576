import styled from 'styled-components';

// Styles utils
import {
  breakPoints,
  percentBaseResponsive,
  spaces,
} from '../../../styles/spaces';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spaces.xl}rem;
  @media (max-width: ${breakPoints.smallDesktop * percentBaseResponsive}rem) {
    gap: ${spaces.m}rem;
  }
  @media (max-width: ${breakPoints.landscapeTablet *
    percentBaseResponsive}rem) {
    flex-direction: column;
    gap: ${spaces.l}rem;
  }
`;

export const SubContainer = styled.div<{ isVertical: boolean }>`
  display: flex;
  @media (max-width: ${breakPoints.smallDesktop * percentBaseResponsive}rem) {
    flex-direction: column;
    gap: 0;
  }

  ${({ isVertical }) =>
    isVertical
      ? {
          flexDirection: 'column',
        }
      : {
          flexDirection: 'row',
          gap: `${spaces.m}rem`,
        }}
`;

export const ContainerIframe = styled.div`
  position: relative;
  width: 100%;
  padding-top: 48.25%;
`;

export const Iframe = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

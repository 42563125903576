// Dependencies
import React from 'react';

// Types
import { typographyProps } from './types';

// Styles
import { Container } from './styles';

export const Typography = ({
  colorText = 'black',
  size = 'M',
  variant = 'regular',
  children,
  component = 'p',
  ...props
}: typographyProps): JSX.Element => (
  <Container
    as={component}
    variant={variant}
    size={size}
    colorText={colorText}
    {...props}
  >
    {children}
  </Container>
);

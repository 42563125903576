import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useQuery } from '@apollo/client';
import { useSelector } from 'react-redux';
import isEqual from 'lodash.isequal';

//Styled Components
import {
  Container,
  ButtonContainer,
  PaginationButtonsStyled,
  MainButtonStyled,
  CloseButton,
} from './styles';

// Types
import { ModalProps } from './types';
import {
  dataTable,
  imperativeRefPagination,
} from '../../../display/tables/InputRadioDefaultTable/types';
import { tableIds, takeBase, takeModal } from '../../../display/tables/types';
import { dataTypes as DataTypesGenericOrderModal } from '../../../display/tables/InputRadioDefaultTable/types';
import { dataTypes } from '../../../display/tables/CampaignTable/types';

// Components
import ModalContainer from '../../containers/ModalContainer';
import InputRadioDefaultTable from '../../../display/tables/InputRadioDefaultTable';
import { useDispatch } from 'react-redux';
import { closeModal, openModal } from '../../../../state/actions/ui/modal';
import { RelatedCampaignTable } from '../../../display/tables/RelatedCampaignTable';

// GraphQL
import {
  FETCH_CAPITOLS,
  FETCH_SUB_CAPITOLS,
} from '../../../../gql/queries/orders/predefinedData';
import {
  GET_CONCESSIONS,
  GET_ORDER_BY_ID,
} from '../../../../gql/queries/orders/orders';
import { GET_GENERIC_ORDERS } from '../../../../gql/queries/genericOrders';
import { GET_CAMPAIGNS } from '../../../../gql/queries/campaign';

// Selectors
import {
  selectedRowModalAcceptData,
  selectedRowModalTable,
} from '../../../../state/actions/ui/selectRowTableModal';

// Redux
import { getCursor } from '../../../../state/selectors/ui/tables';
import { selectAllOrders } from '../../../../gql/selectors/orders';
import { setCursor } from '../../../../state/actions/ui/cursorTables';
import { tabsType } from '../../../../state/reducers/ui/tabs';
import { campaignIdTypes } from '../../../../pages/main/workOrders/Campaigns/types';
import { setTake } from '../../../../state/actions/ui/takeTables';
import * as Sentry from '@sentry/react';
import Tabs from '../../../display/Tabs';
import { getCurrentRowDataModal } from '../../../../state/selectors/ui/modal';
import { client } from '../../../../gql/client';
import {
  TRANSFER_ACCIDENT,
  TRANSFER_ACTION,
  TRANSFER_COMMUNICATION,
} from '../../../../gql/mutations/orders';
import { modalIds } from '../../SuperModal/types';
import { OrderType } from '../../../../types/orders';
import { loadingData } from '../../../../state/actions/ui/loadingData';
import { FETCH_ACTIONS } from '../../../../gql/queries/actions/actions';
import { FETCH_COMMUNICATIONS } from '../../../../gql/queries/communication';
import { FETCH_ACCIDENTS } from '../../../../gql/queries/accidents/accidents';
import { store } from '../../../../state/configureStore';

export const TransferSubOrderModal = ({
  onClickClose,
  namingPersistForm,
  orderToTransfer,
  currentParentOrder,
  revalidatorFunction,
  ...props
}: ModalProps) => {
  const dispatch = useDispatch();
  const [genericOrdersData, setGenericOrdersData] = useState<dataTable[]>([]);
  const [campaignData, setCampaignData] = useState<dataTable[]>([]);
  const [campaignRowState, setCampaignRowState] = useState<campaignIdTypes>({});
  const [genericOrderRowState, setGenericOrderRowState] =
    useState<boolean>(true);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [allData, setAllData] = useState<any>();
  const [tabIndex, setTabIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const selector = useSelector(
    getCurrentRowDataModal(namingPersistForm),
    isEqual
  );

  const selectorCursorGenericOrders = useSelector(
    getCursor(tableIds.GENERIC_ORDER_MODAL),
    isEqual
  );

  const selectorCursorCampaign = useSelector(
    getCursor(tableIds.CAMPAIGN_MODAL),
    isEqual
  );

  const [pageSizeCampaigns, setPageSizeCampaigns] = useState(
    selectorCursorCampaign.take
  );

  const [pageSizeGenericsOrders, setPageSizeGenericsOrders] = useState(
    selectorCursorGenericOrders.take
  );

  useEffect(() => {
    setPageSizeCampaigns(selectorCursorCampaign.take);
    setPageSizeGenericsOrders(selectorCursorGenericOrders.take);
  }, [selectorCursorCampaign, selectorCursorGenericOrders]);

  const { error, loading, data } =
    tabIndex === 1
      ? useQuery(GET_CAMPAIGNS, {
          variables: {
            input: {
              cursor: selectorCursorCampaign?.cursor,
              take: selectorCursorCampaign?.take,
              orderBy: selectorCursorCampaign?.orderBy,
              concessionId: selectorCursorCampaign?.concessionId,
              fromDate: selectorCursorCampaign?.fromDate,
              toDate: selectorCursorCampaign?.toDate,
              name: selectorCursorCampaign.name,
              state: selectorCursorCampaign.state,
            },
          },
        })
      : useQuery(GET_GENERIC_ORDERS, {
          variables: {
            input: {
              take: selectorCursorGenericOrders.take,
              orderBy: selectorCursorGenericOrders.orderBy,
              cursor: selectorCursorGenericOrders.cursor,
              toDate: selectorCursorGenericOrders.toDate,
              fromDate: selectorCursorGenericOrders.fromDate,
              indexType: selectorCursorGenericOrders.indexType,
              indexExpedition: selectorCursorGenericOrders.indexExpedition,
              classification: selectorCursorGenericOrders.classification,
              capitolId: selectorCursorGenericOrders.capitolId,
              subCapitolId: selectorCursorGenericOrders.subCapitolId,
              concessionId: selectorCursorGenericOrders.concessionId,
            },
          },
        });

  useEffect(() => {
    if (!loading) {
      setAllData(data);
    }
  }, [data]);

  const { refetch } =
    tabIndex === 1
      ? useQuery(GET_CAMPAIGNS, {
          variables: {
            input: {
              cursor: '',
              take: Math.abs(pageSizeCampaigns),
              orderBy: 'desc',
              concessionId: selectorCursorCampaign?.concessionId,
              fromDate: selectorCursorCampaign?.fromDate,
              toDate: selectorCursorCampaign?.toDate,
              name: selectorCursorCampaign.name,
              state: selectorCursorCampaign.state,
            },
          },
        })
      : useQuery(GET_GENERIC_ORDERS, {
          variables: {
            input: {
              take: Math.abs(pageSizeGenericsOrders),
              orderBy: 'desc',
              cursor: '',
              toDate: selectorCursorGenericOrders.toDate,
              fromDate: selectorCursorGenericOrders.fromDate,
              indexType: selectorCursorGenericOrders.indexType,
              indexExpedition: selectorCursorGenericOrders.indexExpedition,
              classification: selectorCursorGenericOrders.classification,
              capitolId: selectorCursorGenericOrders.capitolId,
              subCapitolId: selectorCursorGenericOrders.subCapitolId,
              concessionId: selectorCursorGenericOrders.concessionId,
            },
          },
        });

  const ref = useRef<imperativeRefPagination>(null);

  const makeTransfer = async () => {
    setIsLoading(true);
    const cursorActions = getCursor(tableIds.VALIDATION_ACTIONS)(
      store.getState()
    );
    const cursorAccidents = getCursor(tableIds.ACCIDENTS)(store.getState());
    const cursorCommunication = getCursor(tableIds.WORKORDER_COMMUNICATIONS)(
      store.getState()
    );
    if (orderToTransfer.type === OrderType.Accident) {
      const {
        data: { order },
      } = await client.query({
        query: GET_ORDER_BY_ID,
        variables: {
          id: orderToTransfer.id,
        },
      });

      await client.mutate({
        mutation: TRANSFER_ACCIDENT,
        variables: {
          input: {
            newParentOrderId: selector?.data.id,
            accidentId: order.accidentIDs[0],
            accidentOrderId: orderToTransfer.id,
          },
        },
        update(cache) {
          cache.modify({
            fields: {
              accidents() {
                return {};
              },
            },
          });
        },
      });
      await client.query({
        query: FETCH_ACCIDENTS,
        variables: {
          input: {
            take: takeBase,
            cursor: '',
            concessionId: cursorAccidents.concessionId,
            weatherId: cursorAccidents.weatherId,
            mediumKnowledgeId: cursorAccidents.mediumKnowledgeId,
            orderBy: cursorAccidents.orderBy,
            toDate: cursorAccidents.toDate,
            fromDate: cursorAccidents.fromDate,
            indexType: cursorAccidents.indexType,
            indexExpedition: cursorAccidents.indexExpedition,
            classification: cursorAccidents.classification,
          },
        },
        fetchPolicy: 'network-only',
      });
    }

    if (orderToTransfer.type === OrderType.Action) {
      const {
        data: { order },
      } = await client.query({
        query: GET_ORDER_BY_ID,
        variables: {
          id: orderToTransfer.id,
        },
      });

      await client.mutate({
        mutation: TRANSFER_ACTION,
        variables: {
          input: {
            newParentOrderId: selector?.data.id,
            actionId: order.actionIDs[0],
            actionOrderId: orderToTransfer.id,
          },
        },
        update(cache) {
          cache.modify({
            fields: {
              actions() {
                return {};
              },
            },
          });
        },
      });

      await client.query({
        query: FETCH_ACTIONS,
        variables: {
          input: {
            take: takeBase,
            cursor: '',
            concessionId: cursorActions.concessionId,
            capitolId: cursorActions.capitolId,
            subCapitolId: cursorActions.subCapitolId,
            orderBy: cursorActions.orderBy,
            toDate: cursorActions.toDate,
            fromDate: cursorActions.fromDate,
            indexType: cursorActions.indexType,
            indexExpedition: cursorActions.indexExpedition,
            classification: cursorActions.classification,
            state: cursorActions.state,
          },
        },
        fetchPolicy: 'network-only',
      });
    }
    if (orderToTransfer.type === OrderType.Communication) {
      const {
        data: { order },
      } = await client.query({
        query: GET_ORDER_BY_ID,
        variables: {
          id: orderToTransfer.id,
        },
      });

      await client.mutate({
        mutation: TRANSFER_COMMUNICATION,
        variables: {
          input: {
            newParentOrderId: selector?.data.id,
            communicationId: order.communicationIDs[0],
            communicationOrderId: orderToTransfer.id,
          },
        },
        update(cache) {
          cache.modify({
            fields: {
              communications() {
                return {};
              },
            },
          });
        },
      });
      await client.query({
        query: FETCH_COMMUNICATIONS,
        variables: {
          input: {
            take: takeBase,
            cursor: '',
            orderBy: cursorCommunication.orderBy,
            roadId: cursorCommunication.roadId,
            concessionId: cursorCommunication.concessionId,
            interlocutorGroupId: cursorCommunication.interlocutorGroupId,
            fromDate: cursorCommunication.fromDate,
            toDate: cursorCommunication.toDate,
            indexType: cursorCommunication.indexType,
            indexExpedition: cursorCommunication.indexExpedition,
            classification: cursorCommunication?.classification,
          },
        },
        fetchPolicy: 'network-only',
      });
    }
    await client.query({
      query: GET_ORDER_BY_ID,
      variables: {
        id: selector?.data.id,
      },
      fetchPolicy: 'network-only',
    });

    await client.query({
      query: GET_ORDER_BY_ID,
      variables: {
        id: currentParentOrder.id,
      },
      fetchPolicy: 'network-only',
    });

    await client.query({
      query: GET_ORDER_BY_ID,
      variables: {
        id: orderToTransfer.id,
      },
      fetchPolicy: 'network-only',
    });

    revalidatorFunction();
    setIsLoading(false);
  };

  const acceptData = useCallback(async () => {
    dispatch(selectedRowModalAcceptData(namingPersistForm));
    dispatch(closeModal());
    dispatch(
      openModal(modalIds.CONFIRM_TRANSFER_MODAL, {
        onTransferOrder: async () => {
          await makeTransfer();
          dispatch(closeModal());
          setIsLoading(false);
        },
        orderType: orderToTransfer?.type,
        newParentOrderType: currentParentOrder?.type,
        isLoading: isLoading,
        isSameOrder: selector?.data?.id === orderToTransfer?.orderParent,
      })
    );
  }, [namingPersistForm, selector, isLoading]);

  useEffect(() => {
    dispatch(
      selectedRowModalTable(namingPersistForm as string, campaignRowState)
    );
    const tasks = async () => {
      if (tabIndex === 0 && allData?.genericOrders?.genericOrders.length > 0) {
        const genericOrders = await selectAllOrders(
          allData.genericOrders.genericOrders
        );
        setGenericOrdersData(genericOrders);
      } else {
        setGenericOrdersData([]);
      }
      if (tabIndex === 1 && allData?.campaigns?.campaigns.length > 0) {
        const campaigns = await selectAllOrders(allData.campaigns.campaigns);
        setCampaignData(campaigns);
      } else {
        setCampaignData([]);
      }
    };
    tasks().catch((err) => {
      Sentry.captureException(err);
      console.log(err);
    });
    if (error) {
      console.warn(error);
    }
  }, [allData, error, campaignRowState]);

  useEffect(() => {
    tabIndex === 1
      ? dispatch(loadingData(tableIds.CAMPAIGN_MODAL, loading))
      : dispatch(loadingData(tableIds.GENERIC_ORDER_MODAL, loading));
  }, [loading]);

  const onReFetchData = useCallback(async () => {
    const { data } = await refetch();
    setAllData(data);
  }, [refetch]);

  const onReFetchPagination = useCallback(async () => {
    return data;
  }, [data]);

  const [
    {
      data: { capitols },
    },
    {
      data: { concessions },
    },
    {
      data: { subCapitols },
    },
  ] = [
    useQuery(FETCH_CAPITOLS),
    useQuery(GET_CONCESSIONS),
    useQuery(FETCH_SUB_CAPITOLS),
  ];
  const filters = {
    concessions,
  };
  const filtersDataOptions = {
    capitols,
    concessions,
    subCapitols,
  };

  const pageInfo =
    tabIndex === 1 ? data?.campaigns.pageInfo : data?.genericOrders.pageInfo;

  const lastCursor =
    tabIndex === 1
      ? data?.campaigns?.pageInfo.lastCursor
      : data?.genericOrders?.pageInfo.lastCursor;

  const totalCount =
    tabIndex === 1
      ? data?.campaigns.totalCount
      : data?.genericOrders.totalCount;

  const combinedData =
    tabIndex === 1
      ? {
          campaigns: campaignData,
          filters,
          totalCount,
          pageInfo,
          loading,
        }
      : {
          genericOrdersData,
          filtersDataOptions,
          totalCount,
          pageInfo,
          loading,
        };

  const fetchGenericOrderID = useCallback(
    async (id: string, take = 8) => {
      const dataSelector = {
        take,
        cursor: id,
        indexType: selectorCursorGenericOrders?.indexType,
        indexExpedition: selectorCursorGenericOrders?.indexExpedition,
        concessionId: selectorCursorGenericOrders?.concessionId,
        fromDate: selectorCursorGenericOrders?.fromDate,
        toDate: selectorCursorGenericOrders?.toDate,
        orderBy: 'desc',
        classification: selectorCursorGenericOrders?.classification,
        capitolId: selectorCursorGenericOrders?.capitolId,
        subCapitolId: selectorCursorGenericOrders?.subCapitolId,
      };

      if (take > 0) {
        const lastGenericOrder =
          genericOrdersData[genericOrdersData.length - 1]
            .genericOrCampaignOrderID;
        if (lastGenericOrder) {
          dispatch(
            setCursor(tableIds.GENERIC_ORDER_MODAL as string, dataSelector)
          );
        }
      } else {
        const firstGenericOrder = genericOrdersData[0].genericOrCampaignOrderID;

        if (firstGenericOrder) {
          dispatch(
            setCursor(tableIds.GENERIC_ORDER_MODAL as string, dataSelector)
          );
        }
      }

      return dataSelector;
    },
    [selectorCursorGenericOrders, genericOrdersData]
  );

  const fetchCampaignID = useCallback(
    async (id: string, take = pageSizeCampaigns) => {
      const dataSelector = {
        take,
        cursor: id,
        concessionId: selectorCursorCampaign?.concessionId,
        fromDate: selectorCursorCampaign?.fromDate,
        toDate: selectorCursorCampaign?.toDate,
        orderBy: 'desc',
        state: selectorCursorCampaign?.state,
        name: selectorCursorCampaign?.name,
      };

      if (take > 0) {
        const lastCampaign =
          campaignData[campaignData.length - 1].genericOrCampaignOrderID;
        if (lastCampaign) {
          dispatch(setCursor(tableIds.CAMPAIGN_MODAL as string, dataSelector));
        }
      } else {
        const firstCampaign = campaignData[0].genericOrCampaignOrderID;

        if (firstCampaign) {
          dispatch(setCursor(tableIds.CAMPAIGN_MODAL as string, dataSelector));
        }
      }

      return dataSelector;
    },
    [selectorCursorCampaign, campaignData]
  );

  const nextPagination = async () => {
    if (
      tabIndex === 0 &&
      genericOrdersData.length >= 1 &&
      pageInfo.hasNextPage
    ) {
      await fetchGenericOrderID(
        genericOrdersData[genericOrdersData.length - 1]
          .genericOrCampaignOrderID as string
      );
      onReFetchPagination();
    }
    if (tabIndex === 1 && campaignData.length >= 1 && pageInfo.hasNextPage) {
      await fetchCampaignID(
        campaignData[campaignData.length - 1]
          .genericOrCampaignOrderID as string,
        8
      );
      onReFetchPagination();
    }
  };

  const backPagination = async () => {
    if (
      tabIndex === 0 &&
      genericOrdersData.length >= 1 &&
      pageInfo.hasPreviousPage
    ) {
      await fetchGenericOrderID(
        genericOrdersData[0].genericOrCampaignOrderID as string,
        -8
      );
      onReFetchPagination();
    }
    if (
      tabIndex === 1 &&
      campaignData.length >= 1 &&
      pageInfo.hasPreviousPage
    ) {
      await fetchCampaignID(
        campaignData[0].genericOrCampaignOrderID as string,
        -8
      );
      onReFetchPagination();
    }
  };

  const firstPage = useCallback(() => {
    const dataCursor =
      tabIndex === 1
        ? {
            take: takeModal,
            cursor: '',
            concessionId: selectorCursorCampaign?.concessionId,
            fromDate: selectorCursorCampaign?.fromDate,
            toDate: selectorCursorCampaign?.toDate,
            orderBy: 'desc',
            name: selectorCursorCampaign?.name,
            state: selectorCursorCampaign?.state,
          }
        : {
            take: takeModal,
            cursor: '',
            indexType: selectorCursorGenericOrders?.indexType,
            indexExpedition: selectorCursorGenericOrders?.indexExpedition,
            concessionId: selectorCursorGenericOrders?.concessionId,
            fromDate: selectorCursorGenericOrders?.fromDate,
            toDate: selectorCursorGenericOrders?.toDate,
            classification: selectorCursorGenericOrders?.classification,
            orderBy: 'desc',
            capitolId: selectorCursorGenericOrders?.capitolId,
            subCapitolId: selectorCursorGenericOrders?.subCapitolId,
            state: selectorCursorGenericOrders?.state,
          };
    tabIndex === 1
      ? dispatch(setCursor(tableIds.CAMPAIGN_MODAL as string, dataCursor))
      : dispatch(setCursor(tableIds.GENERIC_ORDER_MODAL as string, dataCursor));
    onReFetchPagination();
  }, [selectorCursorGenericOrders, selectorCursorCampaign]);

  const lastPage = useCallback(async () => {
    const restCount = totalCount % 8;
    const take = restCount === 0 ? 8 : restCount;
    const cursor = lastCursor;
    const dataCursor =
      tabIndex === 1
        ? {
            take,
            cursor,
            concessionId: selectorCursorCampaign?.concessionId,
            fromDate: selectorCursorCampaign?.fromDate,
            toDate: selectorCursorCampaign?.toDate,
            orderBy: 'desc',
            name: selectorCursorCampaign?.name,
            state: selectorCursorCampaign?.state,
          }
        : {
            take,
            cursor,
            indexType: selectorCursorGenericOrders?.indexType,
            indexExpedition: selectorCursorGenericOrders?.indexExpedition,
            concessionId: selectorCursorGenericOrders?.concessionId,
            fromDate: selectorCursorGenericOrders?.fromDate,
            toDate: selectorCursorGenericOrders?.toDate,
            classification: selectorCursorGenericOrders?.classification,
            orderBy: 'desc',
            capitolId: selectorCursorGenericOrders?.capitolId,
            subCapitolId: selectorCursorGenericOrders?.subCapitolId,
            state: selectorCursorGenericOrders?.state,
          };
    if (tabIndex === 1) {
      dispatch(setCursor(tableIds.CAMPAIGN_MODAL as string, dataCursor));
      dispatch(setTake(pageSizeCampaigns));
    } else {
      dispatch(setCursor(tableIds.GENERIC_ORDER_MODAL as string, dataCursor));
      dispatch(setTake(pageSizeGenericsOrders));
    }
    onReFetchPagination();
  }, [
    genericOrdersData,
    selectorCursorGenericOrders,
    campaignData,
    selectorCursorCampaign,
  ]);

  // En las tabs seria bueno poner un loader para que el usuario vea que esta cargando
  const dataTabs = [
    {
      label: 'Ordres de Treball',
      content: (
        <Container {...props}>
          <InputRadioDefaultTable
            data={combinedData as DataTypesGenericOrderModal}
            onReFetchData={onReFetchData}
            onRefetchPagination={onReFetchPagination}
            namingPersistForm={namingPersistForm}
            ref={ref}
            titleText={'Filtrar Ordres de Treball per:'}
            tableId={tableIds.GENERIC_ORDER_MODAL}
            setIsSelectedRow={setGenericOrderRowState}
          />
        </Container>
      ),
    },
    {
      label: 'Campanyes',
      content: (
        <Container {...props}>
          <RelatedCampaignTable
            titleText={'Filtrar Campanyas per:'}
            data={combinedData as dataTypes}
            setRowState={setCampaignRowState}
            onReFetchData={onReFetchData}
            onReFetchPagination={onReFetchPagination}
            tableId={tableIds.CAMPAIGN_MODAL}
            showButtonsPagination={false}
            setPageSize={setPageSizeCampaigns}
          />
        </Container>
      ),
    },
  ];

  return (
    <ModalContainer onClickClose={onClickClose} fill="#445275">
      <Tabs
        defaultIndex={0}
        data={dataTabs}
        typeTab={tabsType.TRANSFER_SUBORDER}
        setSelectedIndexTab={setTabIndex}
      />
      <ButtonContainer>
        <CloseButton
          text="Tornar enrere"
          variant="secondary"
          onClick={onClickClose}
        />
        <PaginationButtonsStyled
          pageCount={2}
          currentPage={0}
          nextPage={() => pageInfo?.hasNextPage && nextPagination()}
          previousPage={() => pageInfo?.hasPreviousPage && backPagination()}
          firstPage={() => pageInfo?.hasPreviousPage && firstPage()}
          lastPage={() => pageInfo?.hasNextPage && lastPage()}
          totalCount={totalCount}
        />
        <MainButtonStyled
          disabled={
            tabIndex === 1 ? !campaignRowState?.id : genericOrderRowState
          }
          text="Relacionar OT"
          onClick={acceptData}
        />
      </ButtonContainer>
    </ModalContainer>
  );
};

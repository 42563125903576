// Dependencies
import React from 'react';

export type size = 'default' | 'medium' | 'small' | 'circle';

export enum variant {
  Open = 'open',
  Partial = 'partial',
  End = 'end',
  Validated = 'validated',
  SurveillanceRoute = 'surveillanceRoute',
  Campaign = 'campaign',
  Duplicate = 'duplicate',
  Annulled = 'annulled',
  Cotic = 'cotic',
  Default = 'default',
  Transfer = 'transfer',
  Transferred = 'transferred',
}

export type tagProps = React.HTMLAttributes<HTMLDivElement> & {
  variant: variant;
  children?: string;
  cotic?: string;
  sizeTag?: size;
};

export type ContainerProps = {
  variant: variant;
  sizeTag: size;
};

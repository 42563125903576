//Dependencies
import React, {
  useState,
  useRef,
  useId,
  forwardRef,
  useImperativeHandle,
  Ref,
  useCallback,
} from 'react';
import { ca } from 'date-fns/locale';
import { format, add } from 'date-fns';
import { useClickAway } from 'react-use';

// Styled Components
import {
  Container,
  Calendar,
  InputContainer,
  InputDate,
  Label,
  InputLabel,
} from './styles';

// Types
import {
  CalendarProps,
  ImperativeRefCalendarInput,
  onSelectCalendar,
} from './types';
import { DateRange } from './DayPicker';

// Media
import { useIsLandscapeTablet } from '../../../hooks/useMediaBreakPoints';

const InputCalendar = (
  { label, name, dateRange, onChangeInput, disabled, ...props }: CalendarProps,
  innerRef: Ref<ImperativeRefCalendarInput> | undefined
): JSX.Element => {
  const [range, setRange] = useState<DateRange | undefined>(dateRange);

  const [displayCalendar, setDisplayCalendar] = useState(false);

  const initDay = range?.from ? format(range.from, 'dd / MM / yyyy') : '';

  const endDay = range?.to ? format(range.to, 'dd / MM / yyyy') : '';

  const calendarRef = useRef(null);
  const childRef = useRef<HTMLDivElement>(null);

  const today = new Date();
  const monthDisplayed = add(today, { months: 1 });

  useClickAway(calendarRef, () => setDisplayCalendar(false));

  useImperativeHandle(innerRef, () => ({
    clearSelected: () => setRange(undefined),
  }));

  const onClickInputDate = useCallback(
    (event: { preventDefault: () => void }) => {
      event.preventDefault();
      setDisplayCalendar(!displayCalendar);
    },
    []
  );

  const isLandscapeTablet = useIsLandscapeTablet();

  const onSelectCalendar = useCallback<onSelectCalendar>((value) => {
    setRange(value);
    if (onChangeInput && value?.to) {
      onChangeInput(value);
      setDisplayCalendar(false);
    }
  }, []);

  const onCloseCalendar = useCallback(() => {
    setDisplayCalendar(false);
  }, []);

  const id = useId();

  return (
    <Container {...props} ref={calendarRef}>
      <InputContainer className="input-container">
        <InputLabel>
          <Label
            size={isLandscapeTablet ? 'XS' : 'S'}
            component="label"
            htmlFor={`${id}-${name}`}
          >
            {label}
          </Label>
          <InputDate
            id={`${id}-${name}`}
            name={name}
            readOnly
            placeholder="00 / 00 / 0000"
            value={initDay}
            type="text"
            onClick={onClickInputDate}
            disabled={disabled ?? false}
          />
        </InputLabel>
        <InputDate
          readOnly
          placeholder="00 / 00 / 0000"
          value={endDay}
          type="text"
          onClick={onClickInputDate}
          disabled={disabled ?? false}
        />
      </InputContainer>
      {displayCalendar && (
        <Calendar
          ref={childRef}
          defaultMonth={monthDisplayed}
          toDate={today}
          mode="range"
          locale={ca}
          hideHead={false}
          selected={range}
          onSelect={onSelectCalendar}
          numberOfMonths={2}
          onClose={onCloseCalendar}
        />
      )}
    </Container>
  );
};

export default forwardRef(InputCalendar);

import React, { useRef, useEffect, useState } from 'react';
import { Wrapper } from '@googlemaps/react-wrapper';
import { createCustomEqual, CustomEqualCreatorOptions } from 'fast-equals';
import { isLatLngLiteral } from '@googlemaps/typescript-guards';

// Types
import { MapProps, MapComponentProps } from './types';

// Styled Components
import { ContainerMap, styleMap } from './styles';

// Colors
import { colors } from '../../../styles/colors';

export const MapComponent = ({
  zoom,
  center,
  path,
  geoloc,
  apiKey,
}: MapComponentProps) => {
  const customMarker: google.maps.Symbol = {
    path: 'M-10,0 A10,10 0 1 1 10,0 A10,10 0 0 1 -10,0',
    fillColor: 'white',
    strokeOpacity: 1,
    fillOpacity: 1,
  };

  return (
    <ContainerMap>
      <Wrapper apiKey={apiKey} language={'ca'} region={'SP'}>
        <Map
          center={center}
          zoom={zoom}
          style={styleMap}
          clickableIcons={false}
          disableDefaultUI={true}
        >
          <Path
            path={path}
            geodesic={true}
            strokeColor={colors.garnet}
            strokeOpacity={1.0}
            strokeWeight={8}
          />
          {geoloc && (
            <Marker position={geoloc} icon={customMarker} clickable={false} />
          )}
        </Map>
      </Wrapper>
    </ContainerMap>
  );
};

const Map: React.FC<MapProps> = ({
  onClick,
  onIdle,
  children,
  style,
  ...options
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<google.maps.Map>();

  useEffect(() => {
    if (ref.current && !map) {
      setMap(new window.google.maps.Map(ref.current, {}));
    }
  }, [ref, map]);

  useDeepCompareEffectForMaps(() => {
    if (map) {
      map.setOptions(options);
    }
  }, [map, options]);

  useEffect(() => {
    if (map) {
      ['click', 'idle'].forEach((eventName) =>
        google.maps.event.clearListeners(map, eventName)
      );

      if (onClick) {
        map.addListener('click', onClick);
      }

      if (onIdle) {
        map.addListener('idle', () => onIdle(map));
      }
    }
  }, [map, onClick, onIdle]);

  return (
    <>
      <div ref={ref} style={style} />
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          // set the map prop on the child component
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          return React.cloneElement(child, { map });
        }
      })}
    </>
  );
};

const Path: React.FC<google.maps.PolylineOptions> = ({ ...options }) => {
  const [path, setPath] = useState<google.maps.Polyline>();

  useEffect(() => {
    if (!path) {
      setPath(new google.maps.Polyline());
    }

    return () => {
      if (path) {
        path.setMap(null);
      }
    };
  }, [path]);

  useEffect(() => {
    if (path) {
      path.setOptions(options);
    }
  }, [path, options]);

  return null;
};

const Marker: React.FC<google.maps.MarkerOptions> = ({ ...options }) => {
  const [marker, setMarker] = useState<google.maps.Marker>();

  useEffect(() => {
    if (!marker) {
      setMarker(new google.maps.Marker());
    }

    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [marker]);

  useEffect(() => {
    if (marker) {
      marker.setOptions(options);
    }
  }, [marker, options]);

  return null;
};

const deepCompareEqualsForMaps = createCustomEqual(((
    deepEqual: <A, B>(a: A, b: B) => boolean
  ) =>
  (a: google.maps.LatLng, b: google.maps.LatLng) => {
    if (
      isLatLngLiteral(a) ||
      a instanceof google.maps.LatLng ||
      isLatLngLiteral(b) ||
      b instanceof google.maps.LatLng
    ) {
      return new google.maps.LatLng(a).equals(new google.maps.LatLng(b));
    }
    return deepEqual(a, b);
  }) as CustomEqualCreatorOptions<undefined>);

function useDeepCompareMemoize(value: object | undefined) {
  const ref = React.useRef<object>();
  if (!deepCompareEqualsForMaps(value, ref.current)) {
    ref.current = value;
  }
  return ref.current;
}

function useDeepCompareEffectForMaps(
  callback: React.EffectCallback,
  dependencies: [google.maps.Map | undefined, google.maps.MapOptions]
) {
  React.useEffect(callback, dependencies.map(useDeepCompareMemoize));
}

export const INDEX_TAB_FORM_VERTICAL = 'INDEX_TAB_FORM_VERTICAL';
export const INDEX_TAB_FORM_RESET_VERTICAL = 'INDEX_TAB_FORM_RESET_VERTICAL';

export function tabsVertical(type: string, index: number) {
  return {
    type: INDEX_TAB_FORM_VERTICAL,
    payload: {
      type,
      index,
    },
  };
}

export function indexTabFormResetVertical(type: string) {
  return {
    type: INDEX_TAB_FORM_RESET_VERTICAL,
    payload: {
      type,
    },
  };
}

// Types
import { dataTable } from './types';

export const mocksTable: dataTable[] = [
  {
    id: 'i4yh534hj5gv346',
    cotic: ['1O', '0000', '1234567', '00'],
    date: '2022-01-10T10:10:00.000Z',
    hour: '2022-01-10T10:10:00.000Z',
    capitol: 'Entorns de carretera',
    subCapitol: 'Senyalització',
    concession: 'LL',
    road: 'C-16',
    pkInit: '12+350',
    pkEnd: '12+380',
    description: 'Acidente de ciclomotor en  vía asasd',
    state: 'validated',
    type: 'order',
    assistanceArrival: '2023-09-07T10:00:56.752Z',
    assistanceExit: '2023-09-07T10:00:57.022Z',
    assistanceNotice: '2023-09-07T10:00:56.468Z',
    conservationCenterArrival: '2023-09-07T10:00:50.966Z',
    conservationCenterExit: '2023-09-07T10:00:52.003Z',
    conservationCenterNotice: '2023-09-07T10:00:49.765Z',
    controlCenterArrival: '2023-09-07T10:00:54.754Z',
    controlCenterExit: '2023-09-07T10:00:55.232Z',
    controlCenterNotice: '2023-09-07T10:00:53.107Z',
    images: [
      {
        id: 'http://localhost:5173/cdd95223-702f-40de-97ae-ce911984f5a7',
        date: '2023-09-06T10:52:25.373Z',
      },
      {
        id: 'http://localhost:5173/e18ca8b4-74d2-403f-a268-f003ac7db90e',
        date: '2023-09-06T10:52:31.818Z',
      },
    ],
    policeReport: true,
    mediumKnowledge: 'Centre',
    laneCutting: false,
    luminosity: 'VAL',
    roadConditionDescription: 'TEST',
    otherArrival: '2023-09-07T19:00:58.313Z',
    otherExit: '2023-09-07T19:00:58.573Z',
    otherNotice: '2023-09-07T19:00:58.103Z',
    policeReportNumber: '468435',
    conditionsDriving: 'VAL',
    roadTeamArrival: '2023-09-07T10:00:57.493Z',
    roadTeamExit: '2023-09-07T10:01:57.782Z',
    roadTeamNotice: '2023-09-07T10:00:57.232Z',
    sketch: null,
    squadCopsArrival: '2023-09-07T10:00:55.927Z',
    squadCopsExit: '2023-09-07T10:00:56.217Z',
    squadCopsNotice: '2023-09-07T10:00:55.661Z',
    surfaceCondition: 'VAL',
    surveillanceBody: 'VAL',
    weather: 'Assolellat',
    vehicles: [
      {
        vehicle: '',
        licensePlate: '',
        model: '',
        cia: '',
        policy: '',
        people: 1,
      },
    ],
  },
  {
    id: '3jk4g5hj3g43789',
    cotic: ['1O', '0000', '2234567', '00'],
    date: '2022-11-15T14:40:00.000Z',
    hour: '2022-11-15T14:40:00.000Z',
    capitol: 'Accident',
    subCapitol: 'Animals',
    concession: 'AR',
    road: 'C-17',
    pkInit: '15+789',
    pkEnd: '15+001',
    description: 'Acidente de ciclomotor en  vía asasd',
    state: 'open',
    type: 'order',
    assistanceArrival: '2023-09-07T10:00:56.752Z',
    assistanceExit: '2023-09-07T10:00:57.022Z',
    assistanceNotice: '2023-09-07T10:00:56.468Z',
    conservationCenterArrival: '2023-09-07T10:00:50.966Z',
    conservationCenterExit: '2023-09-07T10:00:52.003Z',
    conservationCenterNotice: '2023-09-07T10:00:49.765Z',
    controlCenterArrival: '2023-09-07T10:00:54.754Z',
    controlCenterExit: '2023-09-07T10:00:55.232Z',
    controlCenterNotice: '2023-09-07T10:00:53.107Z',
    images: [
      {
        id: 'http://localhost:5173/cdd95223-702f-40de-97ae-ce911984f5a7',
        date: '2023-09-06T10:52:25.373Z',
      },
      {
        id: 'http://localhost:5173/e18ca8b4-74d2-403f-a268-f003ac7db90e',
        date: '2023-09-06T10:52:31.818Z',
      },
    ],
    policeReport: true,
    mediumKnowledge: 'Recorreg',
    laneCutting: true,
    luminosity: 'VAL',
    roadConditionDescription: 'TEST',
    otherArrival: '2023-09-07T19:00:58.313Z',
    otherExit: '2023-09-07T19:00:58.573Z',
    otherNotice: '2023-09-07T19:00:58.103Z',
    policeReportNumber: '468435',
    conditionsDriving: 'VAL',
    roadTeamArrival: '2023-09-07T10:00:57.493Z',
    roadTeamExit: '2023-09-07T10:01:57.782Z',
    roadTeamNotice: '2023-09-07T10:00:57.232Z',
    sketch: null,
    squadCopsArrival: '2023-09-07T10:00:55.927Z',
    squadCopsExit: '2023-09-07T10:00:56.217Z',
    squadCopsNotice: '2023-09-07T10:00:55.661Z',
    surfaceCondition: 'VAL',
    surveillanceBody: 'VAL',
    weather: 'Nuvolat',
    vehicles: [
      {
        vehicle: '',
        licensePlate: '',
        model: '',
        cia: '',
        policy: '',
        people: 1,
      },
    ],
  },
  {
    id: '6wrf8swwgwgr8',
    cotic: ['1R', '0000', '3234567', '00'],
    date: '2022-11-23T14:40:00.000Z',
    hour: '2022-11-23T14:40:00.000Z',
    capitol: 'Altres',
    subCapitol: 'Senyalització',
    concession: 'TE',
    road: 'C-16',
    pkInit: '12+345',
    pkEnd: '12+346',
    description: 'Acidente de ciclomotor en  vía asasd',
    state: 'partial',
    type: 'order',
    assistanceArrival: '2023-09-07T10:00:56.752Z',
    assistanceExit: '2023-09-07T10:00:57.022Z',
    assistanceNotice: '2023-09-07T10:00:56.468Z',
    conservationCenterArrival: '2023-09-07T10:00:50.966Z',
    conservationCenterExit: '2023-09-07T10:00:52.003Z',
    conservationCenterNotice: '2023-09-07T10:00:49.765Z',
    controlCenterArrival: '2023-09-07T10:00:54.754Z',
    controlCenterExit: '2023-09-07T10:00:55.232Z',
    controlCenterNotice: '2023-09-07T10:00:53.107Z',
    images: [
      {
        id: 'http://localhost:5173/cdd95223-702f-40de-97ae-ce911984f5a7',
        date: '2023-09-06T10:52:25.373Z',
      },
      {
        id: 'http://localhost:5173/e18ca8b4-74d2-403f-a268-f003ac7db90e',
        date: '2023-09-06T10:52:31.818Z',
      },
    ],
    policeReport: true,
    mediumKnowledge: 'Actuació',
    laneCutting: true,
    luminosity: 'VAL',
    roadConditionDescription: 'TEST',
    otherArrival: '2023-09-07T19:00:58.313Z',
    otherExit: '2023-09-07T19:00:58.573Z',
    otherNotice: '2023-09-07T19:00:58.103Z',
    policeReportNumber: '468435',
    conditionsDriving: 'VAL',
    roadTeamArrival: '2023-09-07T10:00:57.493Z',
    roadTeamExit: '2023-09-07T10:01:57.782Z',
    roadTeamNotice: '2023-09-07T10:00:57.232Z',
    sketch: null,
    squadCopsArrival: '2023-09-07T10:00:55.927Z',
    squadCopsExit: '2023-09-07T10:00:56.217Z',
    squadCopsNotice: '2023-09-07T10:00:55.661Z',
    surfaceCondition: 'VAL',
    surveillanceBody: 'VAL',
    weather: 'Pluja',
    vehicles: [
      {
        vehicle: '',
        licensePlate: '',
        model: '',
        cia: '',
        policy: '',
        people: 1,
      },
    ],
  },
  {
    id: '7ew6g87w6s5g856s',
    cotic: ['1O', '0000', '5234567', '00'],
    date: '2022-11-13T14:40:00.000Z',
    hour: '2022-11-13T14:40:00.000Z',
    capitol: 'Entorns de carretera',
    subCapitol: 'Animals',
    concession: 'LL',
    road: 'C-18',
    pkInit: '12+344',
    pkEnd: '12+349',
    description: 'Acidente de ciclomotor en  vía asasd',
    state: 'open',
    type: 'order',
    assistanceArrival: '2023-09-07T10:00:56.752Z',
    assistanceExit: '2023-09-07T10:00:57.022Z',
    assistanceNotice: '2023-09-07T10:00:56.468Z',
    conservationCenterArrival: '2023-09-07T10:00:50.966Z',
    conservationCenterExit: '2023-09-07T10:00:52.003Z',
    conservationCenterNotice: '2023-09-07T10:00:49.765Z',
    controlCenterArrival: '2023-09-07T10:00:54.754Z',
    controlCenterExit: '2023-09-07T10:00:55.232Z',
    controlCenterNotice: '2023-09-07T10:00:53.107Z',
    images: [
      {
        id: 'http://localhost:5173/cdd95223-702f-40de-97ae-ce911984f5a7',
        date: '2023-09-06T10:52:25.373Z',
      },
      {
        id: 'http://localhost:5173/e18ca8b4-74d2-403f-a268-f003ac7db90e',
        date: '2023-09-06T10:52:31.818Z',
      },
    ],
    policeReport: true,
    mediumKnowledge: 'Actuació',
    laneCutting: false,
    luminosity: 'VAL',
    roadConditionDescription: 'TEST',
    otherArrival: '2023-09-07T19:00:58.313Z',
    otherExit: '2023-09-07T19:00:58.573Z',
    otherNotice: '2023-09-07T19:00:58.103Z',
    policeReportNumber: '468435',
    conditionsDriving: 'VAL',
    roadTeamArrival: '2023-09-07T10:00:57.493Z',
    roadTeamExit: '2023-09-07T10:01:57.782Z',
    roadTeamNotice: '2023-09-07T10:00:57.232Z',
    sketch: null,
    squadCopsArrival: '2023-09-07T10:00:55.927Z',
    squadCopsExit: '2023-09-07T10:00:56.217Z',
    squadCopsNotice: '2023-09-07T10:00:55.661Z',
    surfaceCondition: 'VAL',
    surveillanceBody: 'VAL',
    weather: 'Pluvisque',
    vehicles: [
      {
        vehicle: '',
        licensePlate: '',
        model: '',
        cia: '',
        policy: '',
        people: 1,
      },
    ],
  },
  {
    id: '9w8g79w8gw89g6',
    cotic: ['1O', '0000', '1234567', '00'],
    date: '2022-10-13T16:40:00.000Z',
    hour: '2022-10-13T16:40:00.000Z',
    capitol: 'Altres',
    subCapitol: 'Animals',
    concession: 'AR',
    road: 'C-16',
    pkInit: '12+340',
    pkEnd: '12+380',
    description: 'Acidente de ciclomotor en  vía asasd',
    state: 'validated',
    type: 'order',
    assistanceArrival: '2023-09-07T10:00:56.752Z',
    assistanceExit: '2023-09-07T10:00:57.022Z',
    assistanceNotice: '2023-09-07T10:00:56.468Z',
    conservationCenterArrival: '2023-09-07T10:00:50.966Z',
    conservationCenterExit: '2023-09-07T10:00:52.003Z',
    conservationCenterNotice: '2023-09-07T10:00:49.765Z',
    controlCenterArrival: '2023-09-07T10:00:54.754Z',
    controlCenterExit: '2023-09-07T10:00:55.232Z',
    controlCenterNotice: '2023-09-07T10:00:53.107Z',
    images: [
      {
        id: 'http://localhost:5173/cdd95223-702f-40de-97ae-ce911984f5a7',
        date: '2023-09-06T10:52:25.373Z',
      },
      {
        id: 'http://localhost:5173/e18ca8b4-74d2-403f-a268-f003ac7db90e',
        date: '2023-09-06T10:52:31.818Z',
      },
    ],
    policeReport: true,
    mediumKnowledge: 'Fortuït',
    laneCutting: true,
    luminosity: 'VAL',
    roadConditionDescription: 'TEST',
    otherArrival: '2023-09-07T19:00:58.313Z',
    otherExit: '2023-09-07T19:00:58.573Z',
    otherNotice: '2023-09-07T19:00:58.103Z',
    policeReportNumber: '468435',
    conditionsDriving: 'VAL',
    roadTeamArrival: '2023-09-07T10:00:57.493Z',
    roadTeamExit: '2023-09-07T10:01:57.782Z',
    roadTeamNotice: '2023-09-07T10:00:57.232Z',
    sketch: null,
    squadCopsArrival: '2023-09-07T10:00:55.927Z',
    squadCopsExit: '2023-09-07T10:00:56.217Z',
    squadCopsNotice: '2023-09-07T10:00:55.661Z',
    surfaceCondition: 'VAL',
    surveillanceBody: 'VAL',
    weather: 'Tempest',
    vehicles: [
      {
        vehicle: '',
        licensePlate: '',
        model: '',
        cia: '',
        policy: '',
        people: 1,
      },
    ],
  },
  {
    id: 'e9r78gy9ds7gh',
    cotic: ['1O', '0000', '2234567', '00'],
    date: '2022-11-16T14:40:00.000Z',
    hour: '2022-11-16T14:40:00.000Z',
    capitol: 'Entorns de carretera',
    subCapitol: 'Senyalització',
    concession: 'TE',
    road: 'C-17',
    pkInit: '22+899',
    pkEnd: '22+900',
    description: 'Acidente de ciclomotor en  vía asasd',
    state: 'partial',
    type: 'order',
    assistanceArrival: '2023-09-07T10:00:56.752Z',
    assistanceExit: '2023-09-07T10:00:57.022Z',
    assistanceNotice: '2023-09-07T10:00:56.468Z',
    conservationCenterArrival: '2023-09-07T10:00:50.966Z',
    conservationCenterExit: '2023-09-07T10:00:52.003Z',
    conservationCenterNotice: '2023-09-07T10:00:49.765Z',
    controlCenterArrival: '2023-09-07T10:00:54.754Z',
    controlCenterExit: '2023-09-07T10:00:55.232Z',
    controlCenterNotice: '2023-09-07T10:00:53.107Z',
    images: [
      {
        id: 'http://localhost:5173/cdd95223-702f-40de-97ae-ce911984f5a7',
        date: '2023-09-06T10:52:25.373Z',
      },
      {
        id: 'http://localhost:5173/e18ca8b4-74d2-403f-a268-f003ac7db90e',
        date: '2023-09-06T10:52:31.818Z',
      },
    ],
    policeReport: true,
    mediumKnowledge: 'Autoritat',
    laneCutting: true,
    luminosity: 'VAL',
    roadConditionDescription: 'TEST',
    otherArrival: '2023-09-07T19:00:58.313Z',
    otherExit: '2023-09-07T19:00:58.573Z',
    otherNotice: '2023-09-07T19:00:58.103Z',
    policeReportNumber: '468435',
    conditionsDriving: 'VAL',
    roadTeamArrival: '2023-09-07T10:00:57.493Z',
    roadTeamExit: '2023-09-07T10:01:57.782Z',
    roadTeamNotice: '2023-09-07T10:00:57.232Z',
    sketch: null,
    squadCopsArrival: '2023-09-07T10:00:55.927Z',
    squadCopsExit: '2023-09-07T10:00:56.217Z',
    squadCopsNotice: '2023-09-07T10:00:55.661Z',
    surfaceCondition: 'VAL',
    surveillanceBody: 'VAL',
    weather: 'Vent Fort',
    vehicles: [
      {
        vehicle: '',
        licensePlate: '',
        model: '',
        cia: '',
        policy: '',
        people: 1,
      },
    ],
  },
  {
    id: 'a74fa754af89ayfg',
    cotic: ['1R', '0000', '3234567', '00'],
    date: '2022-11-20T17:40:00.000Z',
    hour: '2022-11-20T17:40:00.000Z',
    capitol: 'Accident',
    subCapitol: 'Animals',
    concession: 'LL',
    road: 'C-17',
    pkInit: '99+870',
    pkEnd: '99+990',
    description: 'Acidente de ciclomotor en  vía asasd',
    state: 'open',
    type: 'order',
    assistanceArrival: '2023-09-07T10:00:56.752Z',
    assistanceExit: '2023-09-07T10:00:57.022Z',
    assistanceNotice: '2023-09-07T10:00:56.468Z',
    conservationCenterArrival: '2023-09-07T10:00:50.966Z',
    conservationCenterExit: '2023-09-07T10:00:52.003Z',
    conservationCenterNotice: '2023-09-07T10:00:49.765Z',
    controlCenterArrival: '2023-09-07T10:00:54.754Z',
    controlCenterExit: '2023-09-07T10:00:55.232Z',
    controlCenterNotice: '2023-09-07T10:00:53.107Z',
    images: [
      {
        id: 'http://localhost:5173/cdd95223-702f-40de-97ae-ce911984f5a7',
        date: '2023-09-06T10:52:25.373Z',
      },
      {
        id: 'http://localhost:5173/e18ca8b4-74d2-403f-a268-f003ac7db90e',
        date: '2023-09-06T10:52:31.818Z',
      },
    ],
    policeReport: true,
    mediumKnowledge: 'Auxili poli',
    laneCutting: false,
    luminosity: 'VAL',
    roadConditionDescription: 'TEST',
    otherArrival: '2023-09-07T19:00:58.313Z',
    otherExit: '2023-09-07T19:00:58.573Z',
    otherNotice: '2023-09-07T19:00:58.103Z',
    policeReportNumber: '468435',
    conditionsDriving: 'VAL',
    roadTeamArrival: '2023-09-07T10:00:57.493Z',
    roadTeamExit: '2023-09-07T10:01:57.782Z',
    roadTeamNotice: '2023-09-07T10:00:57.232Z',
    sketch: null,
    squadCopsArrival: '2023-09-07T10:00:55.927Z',
    squadCopsExit: '2023-09-07T10:00:56.217Z',
    squadCopsNotice: '2023-09-07T10:00:55.661Z',
    surfaceCondition: 'VAL',
    surveillanceBody: 'VAL',
    weather: 'Boira',
    vehicles: [
      {
        vehicle: '',
        licensePlate: '',
        model: '',
        cia: '',
        policy: '',
        people: 1,
      },
    ],
  },
  {
    id: '8s65g8as65fh9sg',
    cotic: ['1O', '0000', '5234567', '00'],
    date: '2022-11-13T14:40:00.000Z',
    hour: '2022-11-13T14:40:00.000Z',
    capitol: 'Entorns de carretera',
    subCapitol: 'Animals',
    concession: 'AR',
    road: 'C-18',
    pkInit: '78+900',
    pkEnd: '79+500',
    description: 'Acidente de ciclomotor en  vía asasd',
    state: 'partial',
    type: 'order',
    assistanceArrival: '2023-09-07T10:00:56.752Z',
    assistanceExit: '2023-09-07T10:00:57.022Z',
    assistanceNotice: '2023-09-07T10:00:56.468Z',
    conservationCenterArrival: '2023-09-07T10:00:50.966Z',
    conservationCenterExit: '2023-09-07T10:00:52.003Z',
    conservationCenterNotice: '2023-09-07T10:00:49.765Z',
    controlCenterArrival: '2023-09-07T10:00:54.754Z',
    controlCenterExit: '2023-09-07T10:00:55.232Z',
    controlCenterNotice: '2023-09-07T10:00:53.107Z',
    images: [
      {
        id: 'http://localhost:5173/cdd95223-702f-40de-97ae-ce911984f5a7',
        date: '2023-09-06T10:52:25.373Z',
      },
      {
        id: 'http://localhost:5173/e18ca8b4-74d2-403f-a268-f003ac7db90e',
        date: '2023-09-06T10:52:31.818Z',
      },
    ],
    policeReport: true,
    mediumKnowledge: 'Auxili bo',
    laneCutting: true,
    luminosity: 'VAL',
    roadConditionDescription: 'TEST',
    otherArrival: '2023-09-07T19:00:58.313Z',
    otherExit: '2023-09-07T19:00:58.573Z',
    otherNotice: '2023-09-07T19:00:58.103Z',
    policeReportNumber: '468435',
    conditionsDriving: 'VAL',
    roadTeamArrival: '2023-09-07T10:00:57.493Z',
    roadTeamExit: '2023-09-07T10:01:57.782Z',
    roadTeamNotice: '2023-09-07T10:00:57.232Z',
    sketch: null,
    squadCopsArrival: '2023-09-07T10:00:55.927Z',
    squadCopsExit: '2023-09-07T10:00:56.217Z',
    squadCopsNotice: '2023-09-07T10:00:55.661Z',
    surfaceCondition: 'VAL',
    surveillanceBody: 'VAL',
    weather: 'Boira Inte',
    vehicles: [
      {
        vehicle: '',
        licensePlate: '',
        model: '',
        cia: '',
        policy: '',
        people: 1,
      },
    ],
  },
  {
    id: '9s7fg687s5gh',
    cotic: ['1O', '0000', '1234567', '00'],
    date: '2022-12-13T14:40:00.000Z',
    hour: '2022-12-13T14:40:00.000Z',
    capitol: 'Accident',
    subCapitol: 'Senyalització',
    concession: 'TE',
    road: 'C-16',
    pkInit: '98+760',
    pkEnd: '98+850',
    description: 'Acidente de ciclomotor en  vía asasd',
    state: 'validated',
    type: 'order',
    assistanceArrival: '2023-09-07T10:00:56.752Z',
    assistanceExit: '2023-09-07T10:00:57.022Z',
    assistanceNotice: '2023-09-07T10:00:56.468Z',
    conservationCenterArrival: '2023-09-07T10:00:50.966Z',
    conservationCenterExit: '2023-09-07T10:00:52.003Z',
    conservationCenterNotice: '2023-09-07T10:00:49.765Z',
    controlCenterArrival: '2023-09-07T10:00:54.754Z',
    controlCenterExit: '2023-09-07T10:00:55.232Z',
    controlCenterNotice: '2023-09-07T10:00:53.107Z',
    images: [
      {
        id: 'http://localhost:5173/cdd95223-702f-40de-97ae-ce911984f5a7',
        date: '2023-09-06T10:52:25.373Z',
      },
      {
        id: 'http://localhost:5173/e18ca8b4-74d2-403f-a268-f003ac7db90e',
        date: '2023-09-06T10:52:31.818Z',
      },
    ],
    policeReport: true,
    mediumKnowledge: 'Auxili per',
    laneCutting: false,
    luminosity: 'VAL',
    roadConditionDescription: 'TEST',
    otherArrival: '2023-09-07T19:00:58.313Z',
    otherExit: '2023-09-07T19:00:58.573Z',
    otherNotice: '2023-09-07T19:00:58.103Z',
    policeReportNumber: '468435',
    conditionsDriving: 'VAL',
    roadTeamArrival: '2023-09-07T10:00:57.493Z',
    roadTeamExit: '2023-09-07T10:01:57.782Z',
    roadTeamNotice: '2023-09-07T10:00:57.232Z',
    sketch: null,
    squadCopsArrival: '2023-09-07T10:00:55.927Z',
    squadCopsExit: '2023-09-07T10:00:56.217Z',
    squadCopsNotice: '2023-09-07T10:00:55.661Z',
    surfaceCondition: 'VAL',
    surveillanceBody: 'VAL',
    weather: 'Neu',
    vehicles: [
      {
        vehicle: '',
        licensePlate: '',
        model: '',
        cia: '',
        policy: '',
        people: 1,
      },
    ],
  },
  {
    id: '68sd5gv78sad5g7s6g',
    cotic: ['1O', '0000', '2234567', '00'],
    date: '2022-11-13T10:40:00.000Z',
    hour: '2022-11-13T10:40:00.000Z',
    capitol: 'Accident',
    subCapitol: 'Animals',
    concession: 'AR',
    road: 'C-16',
    pkInit: '87+654',
    pkEnd: '98+765',
    description: 'Acidente de ciclomotor en  vía asasd',
    state: 'validated',
    type: 'order',
    assistanceArrival: '2023-09-07T10:00:56.752Z',
    assistanceExit: '2023-09-07T10:00:57.022Z',
    assistanceNotice: '2023-09-07T10:00:56.468Z',
    conservationCenterArrival: '2023-09-07T10:00:50.966Z',
    conservationCenterExit: '2023-09-07T10:00:52.003Z',
    conservationCenterNotice: '2023-09-07T10:00:49.765Z',
    controlCenterArrival: '2023-09-07T10:00:54.754Z',
    controlCenterExit: '2023-09-07T10:00:55.232Z',
    controlCenterNotice: '2023-09-07T10:00:53.107Z',
    images: [
      {
        id: 'http://localhost:5173/cdd95223-702f-40de-97ae-ce911984f5a7',
        date: '2023-09-06T10:52:25.373Z',
      },
      {
        id: 'http://localhost:5173/e18ca8b4-74d2-403f-a268-f003ac7db90e',
        date: '2023-09-06T10:52:31.818Z',
      },
    ],
    policeReport: true,
    mediumKnowledge: 'Centre',
    laneCutting: false,
    luminosity: 'VAL',
    roadConditionDescription: 'TEST',
    otherArrival: '2023-09-07T19:00:58.313Z',
    otherExit: '2023-09-07T19:00:58.573Z',
    otherNotice: '2023-09-07T19:00:58.103Z',
    policeReportNumber: '468435',
    conditionsDriving: 'VAL',
    roadTeamArrival: '2023-09-07T10:00:57.493Z',
    roadTeamExit: '2023-09-07T10:01:57.782Z',
    roadTeamNotice: '2023-09-07T10:00:57.232Z',
    sketch: null,
    squadCopsArrival: '2023-09-07T10:00:55.927Z',
    squadCopsExit: '2023-09-07T10:00:56.217Z',
    squadCopsNotice: '2023-09-07T10:00:55.661Z',
    surfaceCondition: 'VAL',
    surveillanceBody: 'VAL',
    weather: 'Calamar',
    vehicles: [
      {
        vehicle: '',
        licensePlate: '',
        model: '',
        cia: '',
        policy: '',
        people: 1,
      },
    ],
  },
  {
    id: 'sfg457s5g65sg4',
    cotic: ['1O', '0000', '1234567', '00'],
    date: '2022-11-13T14:40:00.000Z',
    hour: '2022-11-13T14:40:00.000Z',
    capitol: 'Entorns de carretera',
    subCapitol: 'Senyalització',
    concession: 'TE',
    road: 'C-18',
    pkInit: '45+678',
    pkEnd: '56+789',
    description: 'Acidente de ciclomotor en  vía asasd',
    state: 'open',
    type: 'order',
    assistanceArrival: '2023-09-07T10:00:56.752Z',
    assistanceExit: '2023-09-07T10:00:57.022Z',
    assistanceNotice: '2023-09-07T10:00:56.468Z',
    conservationCenterArrival: '2023-09-07T10:00:50.966Z',
    conservationCenterExit: '2023-09-07T10:00:52.003Z',
    conservationCenterNotice: '2023-09-07T10:00:49.765Z',
    controlCenterArrival: '2023-09-07T10:00:54.754Z',
    controlCenterExit: '2023-09-07T10:00:55.232Z',
    controlCenterNotice: '2023-09-07T10:00:53.107Z',
    images: [
      {
        id: 'http://localhost:5173/cdd95223-702f-40de-97ae-ce911984f5a7',
        date: '2023-09-06T10:52:25.373Z',
      },
      {
        id: 'http://localhost:5173/e18ca8b4-74d2-403f-a268-f003ac7db90e',
        date: '2023-09-06T10:52:31.818Z',
      },
    ],
    policeReport: true,
    mediumKnowledge: 'Autoritat',
    laneCutting: true,
    luminosity: 'VAL',
    roadConditionDescription: 'TEST',
    otherArrival: '2023-09-07T19:00:58.313Z',
    otherExit: '2023-09-07T19:00:58.573Z',
    otherNotice: '2023-09-07T19:00:58.103Z',
    policeReportNumber: '468435',
    conditionsDriving: 'VAL',
    roadTeamArrival: '2023-09-07T10:00:57.493Z',
    roadTeamExit: '2023-09-07T10:01:57.782Z',
    roadTeamNotice: '2023-09-07T10:00:57.232Z',
    sketch: null,
    squadCopsArrival: '2023-09-07T10:00:55.927Z',
    squadCopsExit: '2023-09-07T10:00:56.217Z',
    squadCopsNotice: '2023-09-07T10:00:55.661Z',
    surfaceCondition: 'VAL',
    surveillanceBody: 'VAL',
    weather: 'Assolellat',
    vehicles: [
      {
        vehicle: '',
        licensePlate: '',
        model: '',
        cia: '',
        policy: '',
        people: 1,
      },
    ],
  },
  {
    id: 's97dg6s87d5g8',
    cotic: ['1O', '0000', '2234567', '00'],
    date: '2022-11-15T16:50:00.000Z',
    hour: '2022-11-15T16:50:00.000Z',
    capitol: 'Altres',
    subCapitol: 'Animals',
    concession: 'AR',
    road: 'C-18',
    pkInit: '35+790',
    pkEnd: '46+800',
    description: 'Acidente de ciclomotor en  vía asasd',
    state: 'partial',
    type: 'order',
    assistanceArrival: '2023-09-07T10:00:56.752Z',
    assistanceExit: '2023-09-07T10:00:57.022Z',
    assistanceNotice: '2023-09-07T10:00:56.468Z',
    conservationCenterArrival: '2023-09-07T10:00:50.966Z',
    conservationCenterExit: '2023-09-07T10:00:52.003Z',
    conservationCenterNotice: '2023-09-07T10:00:49.765Z',
    controlCenterArrival: '2023-09-07T10:00:54.754Z',
    controlCenterExit: '2023-09-07T10:00:55.232Z',
    controlCenterNotice: '2023-09-07T10:00:53.107Z',
    images: [
      {
        id: 'http://localhost:5173/cdd95223-702f-40de-97ae-ce911984f5a7',
        date: '2023-09-06T10:52:25.373Z',
      },
      {
        id: 'http://localhost:5173/e18ca8b4-74d2-403f-a268-f003ac7db90e',
        date: '2023-09-06T10:52:31.818Z',
      },
    ],
    policeReport: true,
    mediumKnowledge: 'Fortuït',
    laneCutting: true,
    luminosity: 'VAL',
    roadConditionDescription: 'TEST',
    otherArrival: '2023-09-07T19:00:58.313Z',
    otherExit: '2023-09-07T19:00:58.573Z',
    otherNotice: '2023-09-07T19:00:58.103Z',
    policeReportNumber: '468435',
    conditionsDriving: 'VAL',
    roadTeamArrival: '2023-09-07T10:00:57.493Z',
    roadTeamExit: '2023-09-07T10:01:57.782Z',
    roadTeamNotice: '2023-09-07T10:00:57.232Z',
    sketch: null,
    squadCopsArrival: '2023-09-07T10:00:55.927Z',
    squadCopsExit: '2023-09-07T10:00:56.217Z',
    squadCopsNotice: '2023-09-07T10:00:55.661Z',
    surfaceCondition: 'VAL',
    surveillanceBody: 'VAL',
    weather: 'Nuvolat',
    vehicles: [
      {
        vehicle: '',
        licensePlate: '',
        model: '',
        cia: '',
        policy: '',
        people: 1,
      },
    ],
  },
  {
    id: 's9f78ds7fhdh58',
    cotic: ['1R', '0000', '3234567', '00'],
    date: '2022-11-11T16:20:00.000Z',
    hour: '2022-11-11T16:20:00.000Z',
    capitol: 'Altres',
    subCapitol: 'Senyalització',
    concession: 'LL',
    road: 'C-16',
    pkInit: '44+777',
    pkEnd: '44+789',
    description: 'Acidente de ciclomotor en  vía asasd',
    state: 'partial',
    type: 'order',
    assistanceArrival: '2023-09-07T10:00:56.752Z',
    assistanceExit: '2023-09-07T10:00:57.022Z',
    assistanceNotice: '2023-09-07T10:00:56.468Z',
    conservationCenterArrival: '2023-09-07T10:00:50.966Z',
    conservationCenterExit: '2023-09-07T10:00:52.003Z',
    conservationCenterNotice: '2023-09-07T10:00:49.765Z',
    controlCenterArrival: '2023-09-07T10:00:54.754Z',
    controlCenterExit: '2023-09-07T10:00:55.232Z',
    controlCenterNotice: '2023-09-07T10:00:53.107Z',
    images: [
      {
        id: 'http://localhost:5173/cdd95223-702f-40de-97ae-ce911984f5a7',
        date: '2023-09-06T10:52:25.373Z',
      },
      {
        id: 'http://localhost:5173/e18ca8b4-74d2-403f-a268-f003ac7db90e',
        date: '2023-09-06T10:52:31.818Z',
      },
    ],
    policeReport: true,
    mediumKnowledge: 'Guárdia',
    laneCutting: true,
    luminosity: 'VAL',
    roadConditionDescription: 'TEST',
    otherArrival: '2023-09-07T19:00:58.313Z',
    otherExit: '2023-09-07T19:00:58.573Z',
    otherNotice: '2023-09-07T19:00:58.103Z',
    policeReportNumber: '468435',
    conditionsDriving: 'VAL',
    roadTeamArrival: '2023-09-07T10:00:57.493Z',
    roadTeamExit: '2023-09-07T10:01:57.782Z',
    roadTeamNotice: '2023-09-07T10:00:57.232Z',
    sketch: null,
    squadCopsArrival: '2023-09-07T10:00:55.927Z',
    squadCopsExit: '2023-09-07T10:00:56.217Z',
    squadCopsNotice: '2023-09-07T10:00:55.661Z',
    surfaceCondition: 'VAL',
    surveillanceBody: 'VAL',
    weather: 'Pluja',
    vehicles: [
      {
        vehicle: '',
        licensePlate: '',
        model: '',
        cia: '',
        policy: '',
        people: 1,
      },
    ],
  },
  {
    id: '9sa7d56g78sa5dg',
    cotic: ['1O', '0000', '5234567', '00'],
    date: '2022-11-15T16:50:00.000Z',
    hour: '2022-11-15T16:50:00.000Z',
    capitol: 'Altres',
    subCapitol: 'Animals',
    concession: 'AR',
    road: 'C-17',
    pkInit: '11+111',
    pkEnd: '11+116',
    description: 'Acidente de ciclomotor en  vía asasd',
    state: 'partial',
    type: 'order',
    assistanceArrival: '2023-09-07T10:00:56.752Z',
    assistanceExit: '2023-09-07T10:00:57.022Z',
    assistanceNotice: '2023-09-07T10:00:56.468Z',
    conservationCenterArrival: '2023-09-07T10:00:50.966Z',
    conservationCenterExit: '2023-09-07T10:00:52.003Z',
    conservationCenterNotice: '2023-09-07T10:00:49.765Z',
    controlCenterArrival: '2023-09-07T10:00:54.754Z',
    controlCenterExit: '2023-09-07T10:00:55.232Z',
    controlCenterNotice: '2023-09-07T10:00:53.107Z',
    images: [
      {
        id: 'http://localhost:5173/cdd95223-702f-40de-97ae-ce911984f5a7',
        date: '2023-09-06T10:52:25.373Z',
      },
      {
        id: 'http://localhost:5173/e18ca8b4-74d2-403f-a268-f003ac7db90e',
        date: '2023-09-06T10:52:31.818Z',
      },
    ],
    policeReport: true,
    mediumKnowledge: 'Actuació',
    laneCutting: false,
    luminosity: 'VAL',
    roadConditionDescription: 'TEST',
    otherArrival: '2023-09-07T19:00:58.313Z',
    otherExit: '2023-09-07T19:00:58.573Z',
    otherNotice: '2023-09-07T19:00:58.103Z',
    policeReportNumber: '468435',
    conditionsDriving: 'VAL',
    roadTeamArrival: '2023-09-07T10:00:57.493Z',
    roadTeamExit: '2023-09-07T10:01:57.782Z',
    roadTeamNotice: '2023-09-07T10:00:57.232Z',
    sketch: null,
    squadCopsArrival: '2023-09-07T10:00:55.927Z',
    squadCopsExit: '2023-09-07T10:00:56.217Z',
    squadCopsNotice: '2023-09-07T10:00:55.661Z',
    surfaceCondition: 'VAL',
    surveillanceBody: 'VAL',
    weather: 'Pluvisque',
    vehicles: [
      {
        vehicle: '',
        licensePlate: '',
        model: '',
        cia: '',
        policy: '',
        people: 1,
      },
    ],
  },
  {
    id: '9s8g6879asdg5',
    cotic: ['1O', '0000', '1234567', '00'],
    date: '2022-09-15T22:50:00.000Z',
    hour: '2022-09-15T22:50:00.000Z',
    capitol: 'Altres',
    subCapitol: 'Senyalització',
    concession: 'TE',
    road: 'C-16',
    pkInit: '64+300',
    pkEnd: '65+200',
    description: 'Acidente de ciclomotor en  vía asasd',
    state: 'open',
    type: 'order',
    assistanceArrival: '2023-09-07T10:00:56.752Z',
    assistanceExit: '2023-09-07T10:00:57.022Z',
    assistanceNotice: '2023-09-07T10:00:56.468Z',
    conservationCenterArrival: '2023-09-07T10:00:50.966Z',
    conservationCenterExit: '2023-09-07T10:00:52.003Z',
    conservationCenterNotice: '2023-09-07T10:00:49.765Z',
    controlCenterArrival: '2023-09-07T10:00:54.754Z',
    controlCenterExit: '2023-09-07T10:00:55.232Z',
    controlCenterNotice: '2023-09-07T10:00:53.107Z',
    images: [
      {
        id: 'http://localhost:5173/cdd95223-702f-40de-97ae-ce911984f5a7',
        date: '2023-09-06T10:52:25.373Z',
      },
      {
        id: 'http://localhost:5173/e18ca8b4-74d2-403f-a268-f003ac7db90e',
        date: '2023-09-06T10:52:31.818Z',
      },
    ],
    policeReport: true,
    mediumKnowledge: 'Auxili poli',
    laneCutting: false,
    luminosity: 'VAL',
    roadConditionDescription: 'TEST',
    otherArrival: '2023-09-07T19:00:58.313Z',
    otherExit: '2023-09-07T19:00:58.573Z',
    otherNotice: '2023-09-07T19:00:58.103Z',
    policeReportNumber: '468435',
    conditionsDriving: 'VAL',
    roadTeamArrival: '2023-09-07T10:00:57.493Z',
    roadTeamExit: '2023-09-07T10:01:57.782Z',
    roadTeamNotice: '2023-09-07T10:00:57.232Z',
    sketch: null,
    squadCopsArrival: '2023-09-07T10:00:55.927Z',
    squadCopsExit: '2023-09-07T10:00:56.217Z',
    squadCopsNotice: '2023-09-07T10:00:55.661Z',
    surfaceCondition: 'VAL',
    surveillanceBody: 'VAL',
    weather: 'Tempest',
    vehicles: [
      {
        vehicle: '',
        licensePlate: '',
        model: '',
        cia: '',
        policy: '',
        people: 1,
      },
    ],
  },
  {
    id: '9s7a5g78f5h7868dfh',
    cotic: ['1O', '0000', '1234567', '00'],
    date: '2022-11-15T16:50:00.000Z',
    hour: '2022-11-15T16:50:00.000Z',
    capitol: 'Altres',
    subCapitol: 'Animals',
    concession: 'TE',
    road: 'C-17',
    pkInit: '25+800',
    pkEnd: '25+900',
    description: 'Acidente de ciclomotor en  vía asasd',
    state: 'partial',
    type: 'order',
    assistanceArrival: '2023-09-07T10:00:56.752Z',
    assistanceExit: '2023-09-07T10:00:57.022Z',
    assistanceNotice: '2023-09-07T10:00:56.468Z',
    conservationCenterArrival: '2023-09-07T10:00:50.966Z',
    conservationCenterExit: '2023-09-07T10:00:52.003Z',
    conservationCenterNotice: '2023-09-07T10:00:49.765Z',
    controlCenterArrival: '2023-09-07T10:00:54.754Z',
    controlCenterExit: '2023-09-07T10:00:55.232Z',
    controlCenterNotice: '2023-09-07T10:00:53.107Z',
    images: [
      {
        id: 'http://localhost:5173/cdd95223-702f-40de-97ae-ce911984f5a7',
        date: '2023-09-06T10:52:25.373Z',
      },
      {
        id: 'http://localhost:5173/e18ca8b4-74d2-403f-a268-f003ac7db90e',
        date: '2023-09-06T10:52:31.818Z',
      },
    ],
    policeReport: true,
    mediumKnowledge: 'Centre',
    laneCutting: false,
    luminosity: 'VAL',
    roadConditionDescription: 'TEST',
    otherArrival: '2023-09-07T19:00:58.313Z',
    otherExit: '2023-09-07T19:00:58.573Z',
    otherNotice: '2023-09-07T19:00:58.103Z',
    policeReportNumber: '468435',
    conditionsDriving: 'VAL',
    roadTeamArrival: '2023-09-07T10:00:57.493Z',
    roadTeamExit: '2023-09-07T10:01:57.782Z',
    roadTeamNotice: '2023-09-07T10:00:57.232Z',
    sketch: null,
    squadCopsArrival: '2023-09-07T10:00:55.927Z',
    squadCopsExit: '2023-09-07T10:00:56.217Z',
    squadCopsNotice: '2023-09-07T10:00:55.661Z',
    surfaceCondition: 'VAL',
    surveillanceBody: 'VAL',
    weather: 'Vent Fort',
    vehicles: [
      {
        vehicle: '',
        licensePlate: '',
        model: '',
        cia: '',
        policy: '',
        people: 1,
      },
    ],
  },
  {
    id: '8s75g76sd4g67',
    cotic: ['1O', '0000', '2234567', '00'],
    date: '2022-12-15T22:50:00.000Z',
    hour: '2022-12-15T22:50:00.000Z',
    capitol: 'Altres',
    subCapitol: 'Senyalització',
    concession: 'AR',
    road: 'C-18',
    pkInit: '87+364',
    pkEnd: '89+346',
    description: 'Acidente de ciclomotor en  vía asasd',
    state: 'partial',
    type: 'order',
    assistanceArrival: '2023-09-07T10:00:56.752Z',
    assistanceExit: '2023-09-07T10:00:57.022Z',
    assistanceNotice: '2023-09-07T10:00:56.468Z',
    conservationCenterArrival: '2023-09-07T10:00:50.966Z',
    conservationCenterExit: '2023-09-07T10:00:52.003Z',
    conservationCenterNotice: '2023-09-07T10:00:49.765Z',
    controlCenterArrival: '2023-09-07T10:00:54.754Z',
    controlCenterExit: '2023-09-07T10:00:55.232Z',
    controlCenterNotice: '2023-09-07T10:00:53.107Z',
    images: [
      {
        id: 'http://localhost:5173/cdd95223-702f-40de-97ae-ce911984f5a7',
        date: '2023-09-06T10:52:25.373Z',
      },
      {
        id: 'http://localhost:5173/e18ca8b4-74d2-403f-a268-f003ac7db90e',
        date: '2023-09-06T10:52:31.818Z',
      },
    ],
    policeReport: true,
    mediumKnowledge: 'Auxili bo',
    laneCutting: true,
    luminosity: 'VAL',
    roadConditionDescription: 'TEST',
    otherArrival: '2023-09-07T19:00:58.313Z',
    otherExit: '2023-09-07T19:00:58.573Z',
    otherNotice: '2023-09-07T19:00:58.103Z',
    policeReportNumber: '468435',
    conditionsDriving: 'VAL',
    roadTeamArrival: '2023-09-07T10:00:57.493Z',
    roadTeamExit: '2023-09-07T10:01:57.782Z',
    roadTeamNotice: '2023-09-07T10:00:57.232Z',
    sketch: null,
    squadCopsArrival: '2023-09-07T10:00:55.927Z',
    squadCopsExit: '2023-09-07T10:00:56.217Z',
    squadCopsNotice: '2023-09-07T10:00:55.661Z',
    surfaceCondition: 'VAL',
    surveillanceBody: 'VAL',
    weather: 'Neu',
    vehicles: [
      {
        vehicle: '',
        licensePlate: '',
        model: '',
        cia: '',
        policy: '',
        people: 1,
      },
    ],
  },
];

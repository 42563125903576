import React from 'react';
import { Cloudinary } from '@cloudinary/url-gen';
import { GridProps } from './types';
import { List } from './styles';
import { Photo } from '../Photo';

export const GridOfPhotos = ({
  cloudName,
  imagesToShow,
}: GridProps): JSX.Element => {
  const cloud = new Cloudinary({
    cloud: {
      cloudName: cloudName,
    },
  });

  return (
    <List>
      {cloudName &&
        imagesToShow &&
        imagesToShow.map((image) => (
          <Photo
            key={image.cloudinaryPublicId}
            publicId={image.cloudinaryPublicId}
            title={image.name}
            date={image.creatingDate}
            cotic={image.cotic}
            cloud={cloud}
          />
        ))}
    </List>
  );
};

import { MockApolloClient } from 'mock-apollo-client';
import { GET_COMMUNICATION_BY_ID } from '../queries/communication';
import {
  FETCH_ORDERS,
  GET_CONCESSIONS,
  GET_ORDER_BY_ID,
  GET_SELECTORS_DATA,
} from '../queries/orders/orders';
import { CREATE_NEW_COMMUNICATION } from '../mutations/communication';
import {
  FETCH_CAPITOLS,
  FETCH_CLASSIFICATIONS,
  FETCH_PREDEFINED_DATA_LISTS,
  FETCH_PREDEFINED_ORDERS,
  GET_CAPITOL_BY_ID,
  GET_CLASSIFICATION_BY_ID_OR_COTIC,
  GET_SUB_CAPITOL_BY_ID,
} from '../queries/orders/predefinedData';
import {
  GET_CONCESSION_BY_ID,
  GET_ROAD_BY_ID,
} from '../queries/geoPositions/roads';
import { CREATE_NEW_ORDER, UPDATE_ORDER } from '../mutations/orders';

// DataTable
import { mocksTable } from '../../components/display/tables/InputRadioTagTable/mocks';
import { FETCH_ACCIDENTS } from '../queries/accidents/accidents';
import { mocksTable as mocksAccident } from '../../components/display/tables/AccidentsTable/mocks';

export const addMockHandlersToClient = (mockClient: MockApolloClient) => {
  // Mock Queries
  mockClient.setRequestHandler(GET_COMMUNICATION_BY_ID, (id) => {
    return Promise.resolve({
      data: {
        communication: {
          id: id,
          operator: 'Mock Operator',
          registerInitDate: 1672570800000,
          groupInter: 'mock_option',
          media: 'mock_option',
          sendStatus: 'sent',
          concession: 'mock_option',
          city: "Platja d' Aro",
          pkInitRoad: 'mock start',
          pkEndRoad: 'mock_option',
          road: 'mock_option',
          track: 'mock_option',
          direction: 'mock_option',
          margin: 'mock_option',
          lane: 'mock_option',
          predefine: 'mock_predefine',
          specialTag: 'mock_predefine',
          pkInitKm: 'mock_option',
          pkInitMeter: 'mock_option',
          pkEndKm: 'mock_option',
          pkEndMeter: 'mock_option',
          description: 'mock description',
          classification: 'mock_option',
          subCapitol: 'mock subCapitol',
          capitol: 'mock capitol',
          team: 'mock_option',
        },
      },
    });
  });

  mockClient.setRequestHandler(GET_SELECTORS_DATA, () => {
    return Promise.resolve({
      data: {
        interlocutorGroups: [
          { id: '0', name: 'Centre Control' },
          { id: '1', name: 'Mossos' },
          { id: '2', name: 'Bombers' },
          { id: '3', name: 'Policia Local' },
          { id: '4', name: 'Altres' },
          { id: '5', name: 'CCCV' },
          { id: '6', name: '112' },
          { id: '7', name: 'CECAT' },
          { id: '8', name: 'SCT' },
          { id: '9', name: 'SubContractes' },
          { id: '10', name: 'CIVICAT' },
        ],
        mediaOfCommunications: [
          { id: '0', name: 'Telèfon' },
          { id: '1', name: 'Fax' },
          { id: '2', name: 'Email' },
          { id: '3', name: 'Pal SOS' },
          { id: '4', name: 'SMS' },
        ],
        concessions: [
          { id: '0', name: 'LL' },
          { id: '1', name: 'TE' },
          { id: '2', name: 'AR' },
        ],
        roads: [
          { id: '0', name: 'C-16' },
          { id: '2', name: 'C-17' },
          { id: '3', name: 'C-35' },
        ],
      },
    });
  });

  mockClient.setRequestHandler(GET_ORDER_BY_ID, (params) => {
    let order = mocksTable.find((value) => value.id === params.id);
    if (order) {
      order = {
        ...order,
        registerInitDate: order?.date,
      };
      return Promise.resolve({ data: { order } });
    }

    return Promise.reject('Not found');
  });

  mockClient.setRequestHandler(FETCH_PREDEFINED_DATA_LISTS, () => {
    return Promise.resolve({ data: {} });
  });

  mockClient.setRequestHandler(FETCH_ORDERS, () => {
    return Promise.resolve({ data: mocksTable });
  });

  mockClient.setRequestHandler(FETCH_CLASSIFICATIONS, () => {
    return Promise.resolve({ data: {} });
  });

  mockClient.setRequestHandler(FETCH_PREDEFINED_ORDERS, () => {
    return Promise.resolve({ data: {} });
  });

  mockClient.setRequestHandler(GET_ROAD_BY_ID, () => {
    return Promise.resolve({ data: {} });
  });

  mockClient.setRequestHandler(GET_CLASSIFICATION_BY_ID_OR_COTIC, () => {
    return Promise.resolve({ data: {} });
  });

  mockClient.setRequestHandler(GET_CAPITOL_BY_ID, () => {
    return Promise.resolve({ data: {} });
  });

  mockClient.setRequestHandler(GET_SUB_CAPITOL_BY_ID, () => {
    return Promise.resolve({ data: {} });
  });

  mockClient.setRequestHandler(FETCH_CAPITOLS, () => {
    return Promise.resolve({ data: {} });
  });

  mockClient.setRequestHandler(GET_CONCESSIONS, () => {
    return Promise.resolve({ data: {} });
  });

  mockClient.setRequestHandler(GET_CONCESSION_BY_ID, () => {
    return Promise.resolve({ data: {} });
  });

  // Mock Mutations
  mockClient.setRequestHandler(CREATE_NEW_COMMUNICATION, (args) => {
    return Promise.resolve({
      data: {
        createCommunication: args,
      },
    });
  });

  mockClient.setRequestHandler(UPDATE_ORDER, (args) => {
    const orderToUpdateId = mocksTable.findIndex(
      (order) => order.id === args.input.id
    );

    const orderToUpdate = mocksTable.find(
      (order) => order.id === args.input.id
    );
    const orderUpdated = {
      ...orderToUpdate,
      ...args.input,
    };
    mocksTable.splice(orderToUpdateId, 1, orderUpdated);
    return Promise.resolve({
      data: {
        updateOrder: args.input,
      },
    });
  });

  mockClient.setRequestHandler(CREATE_NEW_ORDER, (args) => {
    mocksTable.push(args.input);
    const orderCreated = {
      ...args.input,
      id: new Date().getTime(),
    };
    return Promise.resolve({
      data: {
        createOrder: orderCreated,
      },
    });
  });
  mockClient.setRequestHandler(FETCH_ACCIDENTS, () => {
    return Promise.resolve({ data: mocksAccident });
  });
};

import styled from 'styled-components';

// Styles utils
import { colors } from '../../../../styles/colors';
import {
  breakPoints,
  percentBaseResponsive,
  spaces,
} from '../../../../styles/spaces';

// Components
import InputText from '../../../inputs/InputText';
import { InputTextArea } from '../../../inputs/InputTextArea';
import { SpecialTag } from '../../SpecialTag';

// Types
import { Area } from '../../../inputs/InputTextArea/styles';

export const ContainerOT = styled.div`
  background-color: ${colors.verySoftRed};
  border-radius: 1rem;
  padding: ${spaces.xl}rem;
`;

export const ContainerHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 0 ${spaces.l}rem;
`;

export const ContainerContent = styled.form`
  display: flex;
  position: relative;
  gap: 3.2rem;

  @media (max-width: ${breakPoints.landscapeTablet *
    percentBaseResponsive}rem) {
    flex-direction: column;
  }
`;

export const ContainerDescription = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 3.2rem;
`;

export const PkInputsContainer = styled.div`
  display: flex;
  gap: 3.2rem;
`;

export const InputPKInit = styled(InputText)`
  max-width: 16rem;
`;

export const InputPKEnd = styled(InputText)`
  max-width: 16rem;
`;

export const InputDescription = styled(InputTextArea)`
  width: 100%;
  @media (max-width: ${breakPoints.landscapeTablet *
    percentBaseResponsive}rem) {
    ${Area} {
      min-height: 3rem;
    }
  }
`;

export const StyledSpecialTag = styled(SpecialTag)`
  cursor: pointer;
`;

import { number, object, string } from 'yup';
import {
  Damages,
  Vehicle,
} from '../../../pages/main/workOrders/AddVehicle/types';

export const VehicleSchema = object<Vehicle>({
  colorVehicle: string().required(),
  insurerVehicle: string().required(),
  modelVehicle: string().required(),
  occupantsVehicle: number().required(),
  policyVehicle: string().required(),
  registVehicle: string().required(),
});

export const DamagesSchema = object<Damages>({
  damages: string().when(
    '$damagesSwitchState',
    ([damagesSwitchState], schema) =>
      damagesSwitchState
        ? schema.required('Si us plau, descriu els danys')
        : schema.notRequired()
  ),
});

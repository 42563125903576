import React from 'react';

import {
  createColumnHelper,
  useReactTable,
  getCoreRowModel,
  flexRender,
} from '@tanstack/react-table';

import { Controller, FieldErrors } from 'react-hook-form';

// Types

import { TableProps, dataTable, headers } from './types';

// Styled Components

import {
  Table,
  Container,
  TrHeader,
  Tr,
  TrStyled,
  ThStyled,
  Th,
  ThInput,
  InputDate,
  InputOthers,
  Text,
  ContainerInput,
} from './styles';

// Hooks

import { useIsPortraitTablet } from '../../../../../hooks/useMediaBreakPoints';
import { Accident } from '../../../../../types/accident';

export const AttentionAccidentTable = ({
  disabled,
  control,
  defaultValues,
  errors,
  accidentDate,
  ...props
}: { errors?: FieldErrors<Accident> } & TableProps): JSX.Element => {
  const columnHelper = createColumnHelper<dataTable>();
  const isPortraitTablet = useIsPortraitTablet();

  const columns = [
    columnHelper.accessor((row) => row.avis, {
      id: 'avis',

      header: () => <Text colorText="white">Avís</Text>,
    }),

    columnHelper.accessor((row) => row.arribada, {
      id: 'arribada',

      header: () => <Text colorText="white">Arribada</Text>,
    }),

    columnHelper.accessor((row) => row.sortida, {
      id: 'sortida',

      header: () => <Text colorText="white">Sortida</Text>,
    }),
  ];

  const table = useReactTable({
    data: [],
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <Container {...props}>
      <Table>
        <thead>
          {table.getHeaderGroups().map((headerGroup, positionHeaderGroup) => (
            <TrHeader key={positionHeaderGroup} empty={headerGroup.id === '0'}>
              {headerGroup.headers.map((header, positionHeader) =>
                !isPortraitTablet ? (
                  <Th key={positionHeader}>
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                  </Th>
                ) : (
                  <Th key={positionHeader}>
                    {flexRender(
                      header.column.columnDef.header,

                      header.getContext()
                    )}
                  </Th>
                )
              )}
            </TrHeader>
          ))}
        </thead>

        <tbody>
          <Tr>
            <ThStyled colSpan={6}>
              <Text>{headers.roadTeam.label}</Text>
            </ThStyled>
          </Tr>
          <TrStyled>
            <ThInput disabled={disabled}>
              <ContainerInput>
                <Controller
                  name={'noticeRoadTeamDate'}
                  control={control}
                  defaultValue={
                    defaultValues?.noticeRoadTeamDate ?? accidentDate
                  }
                  render={({ field: { onChange } }) => {
                    return (
                      <InputDate
                        borderError={!!errors?.noticeRoadTeamDate}
                        onChangeDate={onChange}
                        isDisabled={disabled}
                        initialDate={
                          defaultValues?.noticeRoadTeamDate ?? accidentDate
                        }
                      />
                    );
                  }}
                />
              </ContainerInput>
            </ThInput>
            <ThInput disabled={disabled}>
              <ContainerInput>
                <Controller
                  name={'arrivalRoadTeamDate'}
                  control={control}
                  defaultValue={
                    defaultValues?.arrivalRoadTeamDate ?? new Date()
                  }
                  render={({ field: { onChange } }) => {
                    return (
                      <InputDate
                        borderError={!!errors?.arrivalRoadTeamDate}
                        onChangeDate={onChange}
                        isDisabled={disabled}
                        initialDate={
                          defaultValues?.arrivalRoadTeamDate ?? new Date()
                        }
                      />
                    );
                  }}
                />
              </ContainerInput>
            </ThInput>
            <ThInput disabled={disabled}>
              <ContainerInput>
                <Controller
                  name={'departureRoadTeamDate'}
                  control={control}
                  defaultValue={defaultValues?.departureRoadTeamDate}
                  render={({ field: { onChange } }) => {
                    return (
                      <InputDate
                        borderError={!!errors?.departureRoadTeamDate}
                        onChangeDate={onChange}
                        isDisabled={disabled}
                        initialDate={defaultValues?.departureRoadTeamDate}
                      />
                    );
                  }}
                />
              </ContainerInput>
            </ThInput>
          </TrStyled>
          <Tr>
            <ThStyled colSpan={6}>
              <Text>{headers.squadCops.label}</Text>
            </ThStyled>
          </Tr>
          <TrStyled>
            <ThInput disabled={disabled}>
              <ContainerInput>
                <Controller
                  name={'noticeSquadBoysDate'}
                  control={control}
                  defaultValue={defaultValues?.noticeSquadBoysDate}
                  render={({ field: { onChange } }) => {
                    return (
                      <InputDate
                        onChangeDate={onChange}
                        isDisabled={disabled}
                        initialDate={defaultValues?.noticeSquadBoysDate}
                      />
                    );
                  }}
                />
              </ContainerInput>
            </ThInput>
            <ThInput disabled={disabled}>
              <ContainerInput>
                <Controller
                  name={'arrivalSquadBoysDate'}
                  control={control}
                  defaultValue={defaultValues?.arrivalSquadBoysDate}
                  render={({ field: { onChange } }) => {
                    return (
                      <InputDate
                        onChangeDate={onChange}
                        isDisabled={disabled}
                        initialDate={defaultValues?.arrivalSquadBoysDate}
                      />
                    );
                  }}
                />
              </ContainerInput>
            </ThInput>
            <ThInput disabled={disabled}>
              <ContainerInput>
                <Controller
                  name={'departureSquadBoysDate'}
                  control={control}
                  defaultValue={defaultValues?.departureSquadBoysDate}
                  render={({ field: { onChange } }) => {
                    return (
                      <InputDate
                        onChangeDate={onChange}
                        isDisabled={disabled}
                        initialDate={defaultValues?.departureSquadBoysDate}
                      />
                    );
                  }}
                />
              </ContainerInput>
            </ThInput>
          </TrStyled>

          <Tr>
            <ThStyled colSpan={6}>
              <Text>{headers.assistance.label}</Text>
            </ThStyled>
          </Tr>

          <TrStyled>
            <ThInput disabled={disabled}>
              <ContainerInput>
                <Controller
                  name={'noticeAmbulanceCraneAssistanceDate'}
                  control={control}
                  defaultValue={
                    defaultValues?.noticeAmbulanceCraneAssistanceDate
                  }
                  render={({ field: { onChange } }) => {
                    return (
                      <InputDate
                        onChangeDate={onChange}
                        isDisabled={disabled}
                        initialDate={
                          defaultValues?.noticeAmbulanceCraneAssistanceDate
                        }
                      />
                    );
                  }}
                />
              </ContainerInput>
            </ThInput>
            <ThInput disabled={disabled}>
              <ContainerInput>
                <Controller
                  name={'arrivalAmbulanceCraneAssistanceDate'}
                  control={control}
                  defaultValue={
                    defaultValues?.arrivalAmbulanceCraneAssistanceDate
                  }
                  render={({ field: { onChange } }) => {
                    return (
                      <InputDate
                        onChangeDate={onChange}
                        isDisabled={disabled}
                        initialDate={
                          defaultValues?.arrivalAmbulanceCraneAssistanceDate
                        }
                      />
                    );
                  }}
                />
              </ContainerInput>
            </ThInput>
            <ThInput disabled={disabled}>
              <ContainerInput>
                <Controller
                  name={'departureAmbulanceCraneAssistanceDate'}
                  control={control}
                  defaultValue={
                    defaultValues?.departureAmbulanceCraneAssistanceDate
                  }
                  render={({ field: { onChange } }) => {
                    return (
                      <InputDate
                        onChangeDate={onChange}
                        isDisabled={disabled}
                        initialDate={
                          defaultValues?.departureAmbulanceCraneAssistanceDate
                        }
                      />
                    );
                  }}
                />
              </ContainerInput>
            </ThInput>
          </TrStyled>

          <Tr>
            <ThStyled colSpan={6}>
              <Controller
                name={'otherName'}
                control={control}
                defaultValue={headers.otherName.label}
                render={({ field: { onChange } }) => {
                  return (
                    <InputOthers
                      type={'text'}
                      defaultValue={
                        defaultValues?.otherName || headers.otherName.label
                      }
                      onChange={onChange}
                      disabled={disabled}
                    />
                  );
                }}
              />
            </ThStyled>
          </Tr>

          <TrStyled>
            <ThInput disabled={disabled}>
              <ContainerInput>
                <Controller
                  name={'noticeOtherDate'}
                  control={control}
                  defaultValue={defaultValues?.noticeOtherDate}
                  render={({ field: { onChange } }) => {
                    return (
                      <InputDate
                        onChangeDate={onChange}
                        isDisabled={disabled}
                        initialDate={defaultValues?.noticeOtherDate}
                      />
                    );
                  }}
                />
              </ContainerInput>
            </ThInput>
            <ThInput disabled={disabled}>
              <ContainerInput>
                <Controller
                  name={'arrivalOtherDate'}
                  control={control}
                  defaultValue={defaultValues?.arrivalOtherDate}
                  render={({ field: { onChange } }) => {
                    return (
                      <InputDate
                        onChangeDate={onChange}
                        isDisabled={disabled}
                        initialDate={defaultValues?.arrivalOtherDate}
                      />
                    );
                  }}
                />
              </ContainerInput>
            </ThInput>
            <ThInput disabled={disabled}>
              <ContainerInput>
                <Controller
                  name={'departureOtherDate'}
                  control={control}
                  defaultValue={defaultValues?.departureOtherDate}
                  render={({ field: { onChange } }) => {
                    return (
                      <InputDate
                        onChangeDate={onChange}
                        isDisabled={disabled}
                        initialDate={defaultValues?.departureOtherDate}
                      />
                    );
                  }}
                />
              </ContainerInput>
            </ThInput>
          </TrStyled>
        </tbody>
      </Table>
    </Container>
  );
};

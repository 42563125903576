import { Control, UseFormRegister, UseFormSetValue } from 'react-hook-form';
import React from 'react';

export type CreateInputCell = {
  fieldName: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register: UseFormRegister<any>;
} & React.InputHTMLAttributes<HTMLInputElement>;

export type FormUtilities = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setValue: UseFormSetValue<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register: UseFormRegister<any>;
};

export type dataTable = { id?: string } & {
  animalId?: string;
  animalUnitId?: string;
  name?: string;
  ut?: string;
  statusId?: string;
};

export type NewAnimal = {
  animalId?: string;
  name?: string;
  ut?: string | number;
};

export type tableProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any & {
    animals: dataTable[];
  };
  animalStatus?: {
    id: string;
    description: string;
    code: string;
  }[];
  defaultValues?: dataTable[];
  onDelete?: (id: string, unitId: string) => void;
  onAdd?: (id: string, statusId: string) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any, any>;
  onUpdate: (
    eventValue: string | number,
    id: string,
    unitId: string,
    completeId: string,
    statusId: string
  ) => void;
} & FormUtilities;

type columnIDTypes = { [key: string]: { label: string; id: string } };

export const columnNames: columnIDTypes = {
  animalId: {
    id: 'animalId',
    label: 'Codi',
  },
  name: {
    id: 'name',
    label: 'Animal',
  },
  status: {
    id: 'status',
    label: 'Estat',
  },
  ut: {
    id: 'ut',
    label: 'Ut.',
  },
};

export type thProps = {
  variant: variantThLeft;
};

export type variantThRight = 'code' | 'animal' | 'remove' | 'ut';

export type variantThLeft = 'code' | 'animal' | 'add';

export type thRightProps = {
  variant: variantThRight;
};

export type onChangeInputTypes = (value: string) => void;

import styled from 'styled-components';

// components
import { Typography } from '../../../components/display/Typography';
import { spaces } from '../../../styles/spaces';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 20rem;
`;

export const H1 = styled(Typography)`
  margin-bottom: ${spaces.l}rem;
`;

export const ContainerText = styled.div`
  p:first-child {
    margin-bottom: ${spaces.l}rem;
  }
`;

import React, { useLayoutEffect } from 'react';
import { useAsyncValue, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

// Components
import MachineryForm from '../../../../../components/forms/ActionForms/MachineryForm';

// Types
import { MachineryTypesFormValues } from '../../../../../components/forms/ActionForms/MachineryForm/types';
import { OrderActionReferences } from '../types';

// Constants
import { formActionValidationNaming } from '../../../../../components/forms/ActionForms/constants';

// Redux
import { coticOTSelected } from '../../../../../state/actions/ui/coticOTSelectedGeneralView';

export default function MachineryValidationFormPage(): JSX.Element {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loaderData = useAsyncValue() as MachineryTypesFormValues &
    OrderActionReferences & {
      parentExpeditionOrderStatus?: string;
      state?: string;
    };

  const onFormSubmit = () => {
    navigate('../animals');
  };

  useLayoutEffect(() => {
    dispatch(
      coticOTSelected(
        'actions-validation',
        loaderData.cotic,
        loaderData.orderId
      )
    );
  }, []);

  return (
    <MachineryForm
      onFormSubmit={onFormSubmit}
      initialData={loaderData}
      sessionStorageKey={
        formActionValidationNaming.NEW_VALIDATION_MACHINERY_FORM
      }
      updateKey={formActionValidationNaming.DATA_CHANGES}
      textButton="Validar"
    />
  );
}

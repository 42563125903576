import React, {
  forwardRef,
  Ref,
  useId,
  useState,
  useImperativeHandle,
  useCallback,
} from 'react';

// Types
import { InputCoticProps, ImperativeRefInputCotic } from './types';

// Components Styled
import { Container, InputsContainer, InputValue, Label } from './styles';

// Media
import { useIsLandscapeTablet } from '../../../hooks/useMediaBreakPoints';
import isEqual from 'lodash.isequal';

/**
 * Primary UI component for user interaction
 */
function InputCotic(
  {
    label,
    disabled,
    onChangeInputCotic,
    cotic = ['', '', '', ''],
    ...props
  }: InputCoticProps,
  innerRef: Ref<ImperativeRefInputCotic>
): JSX.Element {
  const [inputValues, setInputValues] = useState(cotic);

  const onChangeInput = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, i: number) => {
      setInputValues((prevValues) => {
        const newValues = [...prevValues];
        newValues[i] = event.target.value;
        onChangeInputCotic(newValues);
        return newValues;
      });
    },
    []
  );

  useImperativeHandle(innerRef, () => ({
    clearSelected: () => {
      setInputValues(['', '', '', '']);
    },
  }));

  const id = useId();

  const isLandscapeTablet = useIsLandscapeTablet();

  return (
    <Container {...props}>
      {label && (
        <Label
          component={'label'}
          htmlFor={`${id}-cotic-input`}
          colorText="black"
          size={isLandscapeTablet ? 'XS' : 'S'}
        >
          {label}
        </Label>
      )}
      <InputsContainer>
        <InputValue
          id={`${id}-cotic-input`}
          type="text"
          disabled={disabled}
          maxLength={2}
          placeholder={'00'}
          value={inputValues[0]}
          onChange={(event) => {
            onChangeInput(event, 0);
          }}
          onKeyDown={(e) => {
            /[+*./,\\-]/.test(e.key) && e.preventDefault();
          }}
        />
        <InputValue
          id={`${id}-cotic-input`}
          type="text"
          disabled={disabled}
          maxLength={4}
          placeholder={'0000'}
          value={inputValues[1]}
          onChange={(event) => onChangeInput(event, 1)}
          onKeyDown={(e) => {
            /[+*./,\\-]/.test(e.key) && e.preventDefault();
          }}
        />
        <InputValue
          id={`${id}-cotic-input`}
          type="text"
          disabled={disabled}
          maxLength={7}
          placeholder={'0000000'}
          value={inputValues[2]}
          onChange={(event) => onChangeInput(event, 2)}
          onKeyDown={(e) => {
            /[+*./,\\-]/.test(e.key) && e.preventDefault();
          }}
        />
        <InputValue
          id={`${id}-cotic-input`}
          type="text"
          disabled={disabled}
          maxLength={2}
          placeholder={'00'}
          value={inputValues[3]}
          onChange={(event) => onChangeInput(event, 3)}
          onKeyDown={(e) => {
            /[+*./,\\-]/.test(e.key) && e.preventDefault();
          }}
        />
      </InputsContainer>
    </Container>
  );
}

export default React.memo(forwardRef(InputCotic), isEqual);

export const INDEX_TAB_FORM = 'INDEX_TAB_FORM';
export const INDEX_TAB_FORM_RESET = 'INDEX_TAB_FORM_RESET';

export function tabs(type: string, index: number) {
  return {
    type: INDEX_TAB_FORM,
    payload: {
      type,
      index,
    },
  };
}

export function indexTabFormReset(type: string) {
  return {
    type: INDEX_TAB_FORM_RESET,
    payload: {
      type,
    },
  };
}

import { AuxiliaryMachineryType } from './types';

export const mockData: AuxiliaryMachineryType[] = [
  {
    codiMaq: 'M00001',
    maquina: 'Retroescavaora de 30 kg',
    ut: '03',
    um: 'Kg',
  },
  {
    codiMaq: 'M00001',
    maquina: 'Retroescavaora de 30 kg',
    ut: '03',
    um: 'Kg',
  },
  {
    codiMaq: 'M00001',
    maquina: 'Retroescavaora de 30 kg',
    ut: '03',
    um: 'Kg',
  },
  {
    codiMaq: 'M00001',
    maquina: 'Retroescavaora de 30 kg',
    ut: '03',
    um: 'Kg',
  },
  {
    codiMaq: 'M00001',
    maquina: 'Retroescavaora de 30 kg',
    ut: '03',
    um: 'Kg',
  },
  {
    codiMaq: 'M00001',
    maquina: 'Retroescavaora de 30 kg',
    ut: '03',
    um: 'Kg',
  },
  {
    codiMaq: 'M00001',
    maquina: 'Retroescavaora de 30 kg',
    ut: '03',
    um: 'Kg',
  },
  {
    codiMaq: 'M00001',
    maquina: 'Retroescavaora de 30 kg',
    ut: '03',
    um: 'Kg',
  },
];

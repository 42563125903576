import styled from 'styled-components';

// Components
import { ContainerButton } from '../components/MainStyledComponents';

// Spaces
import { breakPoints, percentBaseResponsive } from '../../../../styles/spaces';

export const ContainerButtonStyled = styled(ContainerButton)`
  align-items: flex-end;
  @media (max-width: ${breakPoints.landscapeTablet *
    percentBaseResponsive}rem) {
    display: flex;
    justify-content: center;
    margin-top: 3.2rem;
  }
  @media (max-width: ${breakPoints.portraitTablet * percentBaseResponsive}rem) {
    flex-direction: column;
    align-items: center;
    gap: 2.3rem;
  }
`;

export const ContainerButtonSmall = styled(ContainerButton)`
  margin-top: 0;
`;

import React, { useLayoutEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

// Styles
import {
  AdviceContainer,
  ButtonContainer,
  ContainerError,
  GeneralContainer,
  TitleContainerWithButton,
} from '../styles';

// Components
import { Typography } from '../../../display/Typography';
import { MaterialSelectionTable } from '../../../display/tables/formTables/MaterialsSelectionTable';
import SimpleBackgroundSwitch from '../../../inputs/buttons/SwitchButton/SimpleBackground';
import { MainButton } from '../../../inputs/buttons/MainButton';

// Types
import { type MaterialFormValues, type MaterialType } from './types';
import { type dataTable } from '../../../display/tables/formTables/MaterialsSelectionTable/types';
import { Materials } from '../MachineryForm/types';

// Utils
import { useCacheFormInSessionStorage } from '../../../../hooks/useCacheForm';
import { handleAdd, handleDelete, handleUpdate } from '../formTableUtils';
import {
  compressSessionStorage,
  decompressSessionStorage,
} from '../../../../utils/sessionStorage';
import { DataState } from '../types';
import { OrderState } from '../../../../types/orders';

const MaterialsForm = ({
  initialData,
  onFormSubmit,
  sessionStorageKey,
  updateKey,
  textButton = 'Continuar',
}: MaterialType) => {
  const { control, handleSubmit, setValue, register, getValues } =
    useForm<MaterialFormValues>({
      defaultValues: {
        ...initialData,
        isMaterialTableDisplayed: initialData.isMaterialTableDisplayed
          ? initialData.isMaterialTableDisplayed
          : 'true',
      },
    });
  const [dataFromTables, setDataFromTables] = useState<null | dataTable[]>(
    null
  );
  const [switchState, setSwitchState] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const updateTableKey = 'materialsData';
  useCacheFormInSessionStorage(sessionStorageKey, control);

  const switchStateValue = () => {
    const { isMaterialTableDisplayed } = initialData;

    if (isMaterialTableDisplayed) {
      return isMaterialTableDisplayed === 'true';
    }

    setValue('isMaterialTableDisplayed', 'true');
    return true;
  };

  useLayoutEffect(() => {
    setDataFromTables(Object.values(initialData?.materialsTable ?? {}));
    setSwitchState(switchStateValue());
  }, []);

  const handleChange = () => {
    if (switchState) {
      setSwitchState(false);
      setValue('isMaterialTableDisplayed', 'false');
      setValue('materialsTable', null);
      if (updateKey) {
        const idsToDelete = Object.values(
          initialData?.materialsTable ?? {}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ).map((item: any) => item.materialUnitId);

        const currentCacheData = sessionStorage.getItem(updateKey)
          ? JSON.parse(
              decompressSessionStorage(
                sessionStorage.getItem(updateKey) as string
              )
            )
          : {};

        currentCacheData[updateTableKey] ??= {};
        currentCacheData[updateTableKey][DataState.deleted] = idsToDelete;
        currentCacheData[updateTableKey][DataState.added] = [];
        currentCacheData[updateTableKey][DataState.updated] = [];

        sessionStorage.setItem(
          updateKey,
          compressSessionStorage(JSON.stringify(currentCacheData))
        );
      }
      setDataFromTables([]);
    } else {
      setSwitchState(true);
      setValue('isMaterialTableDisplayed', 'true');
    }
  };

  const validateMaterialsTable = (tableValues: Materials[]) => {
    const existAtLeastOneValue = Object.entries(tableValues ?? {}).some(
      ([, element]) => !!element
    );

    const allTheElementsHasUnits = Object.entries(tableValues ?? {}).every(
      ([, element]) =>
        element === undefined ? true : !!element && Number(element.ut) > 0
    );

    !existAtLeastOneValue
      ? setError('Afegiu almenys un element a la taula principal')
      : setError('Si us plau, ompliu tots els camps dels elements afegits');

    return existAtLeastOneValue && allTheElementsHasUnits;
  };

  const checkTableValues = () => {
    if (switchState) {
      const tableValues = getValues('materialsTable');
      return validateMaterialsTable(tableValues as never[]);
    }

    return true;
  };

  const handleUpdateItem = (
    eventValue: string,
    id: string,
    unitId: string,
    completeId: string
  ) => {
    setValue(completeId as keyof MaterialFormValues, eventValue);

    if (!updateKey) {
      return;
    }

    handleUpdate(
      eventValue,
      id,
      unitId,
      initialData,
      updateTableKey,
      'materialsTable',
      updateKey
    );
  };

  const handleAddItem = (id: string) => {
    if (updateKey) {
      handleAdd(id, updateTableKey, updateKey);
    }
  };
  const handleDeleteItem = (id: string, unitId: string) => {
    if (!updateKey) {
      return;
    }

    handleDelete(id, unitId, updateTableKey, updateKey);
  };
  return (
    <GeneralContainer
      onSubmit={(e) => {
        if (checkTableValues()) {
          handleSubmit(onFormSubmit)(e);
        } else {
          const tableValues = getValues('materialsTable');
          validateMaterialsTable(tableValues as never[]);
          e.preventDefault();
        }
      }}
    >
      <TitleContainerWithButton>
        <Typography variant="semiBold" component="h3" size="L">
          Selecció dels materials
        </Typography>
        <SimpleBackgroundSwitch
          changeState={handleChange}
          state={switchState}
          disabled={
            initialData?.parentExpeditionOrderStatus === OrderState.Annulled ||
            initialData?.parentExpeditionOrderStatus === OrderState.End ||
            initialData?.state === OrderState.Annulled
          }
        />
      </TitleContainerWithButton>
      {switchState && dataFromTables ? (
        <MaterialSelectionTable
          data={initialData.materialsData}
          register={register}
          setValue={setValue}
          defaultValues={dataFromTables}
          onUpdate={handleUpdateItem}
          onDelete={updateKey ? handleDeleteItem : undefined}
          onAdd={updateKey ? handleAddItem : undefined}
        />
      ) : (
        <AdviceContainer>
          <Typography component="p" variant="semiBold" colorText="greyDark">
            No hi ha materials associats a l{`'`} actuació
          </Typography>
        </AdviceContainer>
      )}
      {error && switchState && (
        <ContainerError>
          <Typography component="p" size="S" colorText="lightCoralRed">
            {error}
          </Typography>
        </ContainerError>
      )}
      <ButtonContainer>
        <MainButton
          text={
            initialData?.parentExpeditionOrderStatus === OrderState.Annulled ||
            initialData?.parentExpeditionOrderStatus === OrderState.End ||
            initialData?.state === OrderState.Annulled
              ? 'Continuar'
              : textButton
          }
          type="submit"
        />
      </ButtonContainer>
    </GeneralContainer>
  );
};

export default MaterialsForm;

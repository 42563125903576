import {
  toast,
  ToastOptions,
  ToastContainerProps,
  Slide,
} from 'react-toastify';

export const toastFunctionConfig: ToastOptions = {
  icon: false,
  style: {
    backgroundColor: 'transparent',
    width: 'max-content',
    boxShadow: 'none',
    right: 75,
    top: 50,
    position: 'relative',
    marginBottom: '-1rem',
    animationDuration: '0.4s',
  },
};

export const toastContainerConfig: ToastContainerProps = {
  position: toast.POSITION.TOP_RIGHT,
  autoClose: 8000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  closeButton: false,
  theme: 'light',
  transition: Slide,
};

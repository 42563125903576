import React, { useCallback } from 'react';
import { useAsyncValue, useRevalidator } from 'react-router-dom';

// Components
import { MaterialsManagementTable } from '../../../../components/display/tables/formTables/managementTables/MaterialsManagementTable';
import { AddMaterialManagementForm } from '../../../../components/forms/AddMaterialManagementForm';
import { formMaterialManagementNaming } from '../../../../components/forms/AddMaterialManagementForm/constants';

// Types
import { MaterialManagementLoader } from './types';

// Styles
import { Container, MaterialsManagementSection } from './styles';

// GQL
import { client } from '../../../../gql/client';
import { MaterialManagement } from '../../../../components/forms/AddMaterialManagementForm/types';
import { CREATE_NEW_MATERIAL } from '../../../../gql/mutations/materials';
import { NEW_MATERIAL } from '../../../../gql/fragments/material';

export default function MaterialsManagementPage(): JSX.Element {
  const loaderData = useAsyncValue() as MaterialManagementLoader;
  const revalidator = useRevalidator();
  const allMaterialsNames = loaderData.data.materialsData.map(
    (material: { name: string }) =>
      material.name
        .toLowerCase()
        .split('')
        .filter((letter) => letter !== ' ')
        .join('')
  );

  const onReFetchData = useCallback(() => {
    revalidator.revalidate();
  }, []);

  const onFormSubmit = async (data: MaterialManagement) => {
    const parsedNameToCompare = data.name
      .toLowerCase()
      .split('')
      .filter((letter) => letter !== ' ')
      .join('');

    if (
      allMaterialsNames.some((item: string) => item === parsedNameToCompare)
    ) {
      return 'L’element ja existeix.';
    }

    await client.mutate({
      mutation: CREATE_NEW_MATERIAL,
      variables: {
        input: {
          name: data.name,
          measureTypeIDs: data.typeOfMeasurement,
          codificationIDs: data.codification,
          subCodificationIDs: data.subCodification,
        },
      },

      update(cache, { data: { createMaterial } }) {
        cache.modify({
          fields: {
            materials(existingMaterials = []) {
              const createMaterialRef = client.writeFragment({
                fragment: NEW_MATERIAL,
                data: createMaterial,
              });

              return [...existingMaterials, createMaterialRef];
            },
          },
        });
      },
    });

    onReFetchData();
  };
  return (
    <Container>
      <MaterialsManagementSection>
        <AddMaterialManagementForm
          initialData={loaderData.cacheData}
          onFormSubmit={onFormSubmit}
          namingPersistForm={
            formMaterialManagementNaming.NEW_MATERIAL_MANAGEMENT_FORM
          }
          data={loaderData.data}
        />
      </MaterialsManagementSection>
      <MaterialsManagementSection>
        <MaterialsManagementTable
          data={{
            materials: loaderData.data.materialsData,
          }}
        />
      </MaterialsManagementSection>
    </Container>
  );
}

import styled from 'styled-components';
import {
  breakPoints,
  percentBaseResponsive,
  spaces,
} from '../../../styles/spaces';
import { colors } from '../../../styles/colors';

export const Container = styled.form`
  display: flex;
  align-items: start;
  flex-direction: column;
  gap: ${spaces.l}rem;
`;

export const InputsContainer = styled.div`
  display: flex;
  align-items: end;
  gap: ${spaces.l}rem;
  flex-wrap: wrap;
  width: 100%;
  @media (max-width: ${breakPoints.landscapeTablet *
    percentBaseResponsive}rem) {
    gap: ${spaces.m}rem;
  }
`;

export const AddMaterialButton = styled.button`
  font-size: 2rem;
  color: ${colors.white};
  min-height: 4rem;
  min-width: 4rem;
  width: 4rem;
  border: none;
  border-radius: 5px;
  background-color: ${colors.garnet};
`;

import styled from 'styled-components';

// Colors
import { colors } from '../../../styles/colors';

// Components
import { Typography } from '../../display/Typography';

// Spaces
import {
  breakPoints,
  percentBaseResponsive,
  spaces,
} from '../../../styles/spaces';

// Types
import { fontSize } from '../../../styles/fonts';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colors.white};
  border: 0.1rem solid ${colors.black};
  border-radius: 0.5rem;
  padding: 0 1rem;
  width: 30rem;
`;

export const ContainerInput = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: end;
  gap: ${spaces.s}rem;
  padding: 0.9rem 0;
  &:first-of-type {
    border-top: 1px solid transparent;
  }
  &:not(:first-of-type) {
    border-top: 1px solid ${colors.paleDarkGrey};
  }
`;

export const SmallContainer = styled.div`
  display: flex;
  align-items: end;
  gap: 1rem;
  overflow: hidden;
  @media (max-width: ${breakPoints.landscapeTablet *
    percentBaseResponsive}rem) {
    align-items: center;
  }
`;

export const Text = styled(Typography)`
  line-height: 1.2em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @media (max-width: ${breakPoints.landscapeTablet *
    percentBaseResponsive}rem) {
    font-size: ${fontSize.XS};
  }
`;

export const ContainerFileIcon = styled.img``;

export const ContainerRemoveIcon = styled.img`
  height: 1.9rem;
  width: 1.4rem;
  cursor: pointer;
`;

import React, { useEffect } from 'react';
import { useNavigate, Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// Components
import Tabs from '../../../../components/display/Tabs';

// Redux
import { getCurrentIndexTabFormByTab } from '../../../../state/selectors/ui/tabs';
import { tabsType } from '../../../../state/reducers/ui/tabs';
import { coticOTSelectedReset } from '../../../../state/actions/ui/coticOTSelectedGeneralView';

//Styles
import { ContainerStyled } from './styles';

// Redux

export default function GeneralViewPage(): JSX.Element {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const index = useSelector(
    getCurrentIndexTabFormByTab(tabsType.WORK_ORDERS_GENERAL)
  );

  const dataTab = [
    {
      label: 'Vista previa d’O.T.',
    },
    {
      label: 'Accidents',
    },
    {
      label: 'Comunicacions',
    },
    {
      label: 'Multimedia',
    },
  ];

  useEffect(() => {
    switch (index) {
      case 0:
        navigate('/work-orders/');
        break;
      case 1:
        dispatch(coticOTSelectedReset());
        navigate('/work-orders/accidents');
        break;
      case 2:
        dispatch(coticOTSelectedReset());
        navigate('/work-orders/communications');
        break;
      case 3:
        dispatch(coticOTSelectedReset());
        navigate('/work-orders/multimedia');
        break;
      default:
        navigate('/work-orders/');
        break;
    }
  }, [index]);

  return (
    <ContainerStyled>
      <Tabs
        data={dataTab}
        typeTab={tabsType.WORK_ORDERS_GENERAL}
        defaultIndex={index}
      />
      <Outlet />
    </ContainerStyled>
  );
}

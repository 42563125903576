import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useQuery } from '@apollo/client';
import { useSelector } from 'react-redux';
import isEqual from 'lodash.isequal';

//Styled Components
import {
  Container,
  ButtonContainer,
  PaginationButtonsStyled,
  MainButtonStyled,
  CloseButton,
} from './styles';

// Types
import { ModalProps } from './types';
import {
  dataTable,
  imperativeRefPagination,
} from '../../../display/tables/InputRadioDefaultTable/types';
import { tableIds, takeModal } from '../../../display/tables/types';
import { dataTypes as DataTypesGenericOrderModal } from '../../../display/tables/InputRadioDefaultTable/types';
import { dataTypes } from '../../../display/tables/CampaignTable/types';
import { formNaming } from '../../../forms/NewCommunicationForm/constants';

// Components
import ModalContainer from '../../containers/ModalContainer';
import InputRadioDefaultTable from '../../../display/tables/InputRadioDefaultTable';
import { useDispatch } from 'react-redux';
import { closeModal } from '../../../../state/actions/ui/modal';
import { RelatedCampaignTable } from '../../../display/tables/RelatedCampaignTable';

// GraphQL
import {
  FETCH_CAPITOLS,
  FETCH_SUB_CAPITOLS,
} from '../../../../gql/queries/orders/predefinedData';
import { GET_CONCESSIONS } from '../../../../gql/queries/orders/orders';
import { GET_GENERIC_ORDERS } from '../../../../gql/queries/genericOrders';
import { GET_CAMPAIGNS } from '../../../../gql/queries/campaign';

// Selectors
// import { selectCommunicationOrdersWithStatusCapitolSubCapitolAndOnlyRoot } from '../../../../gql/selectors/communications';
import {
  selectedRowModalAcceptData,
  selectedRowModalTable,
} from '../../../../state/actions/ui/selectRowTableModal';

// Redux
import { getCursor } from '../../../../state/selectors/ui/tables';
import { selectAllOrders } from '../../../../gql/selectors/orders';
import { setCursor } from '../../../../state/actions/ui/cursorTables';
import { getCurrentIndexTabFormByTab } from '../../../../state/selectors/ui/tabs';
import { tabsType } from '../../../../state/reducers/ui/tabs';
import { campaignIdTypes } from '../../../../pages/main/workOrders/Campaigns/types';
import { setTake } from '../../../../state/actions/ui/takeTables';
import { loadingData } from '../../../../state/actions/ui/loadingData';
import * as Sentry from '@sentry/react';

export const RelatesCommunicationModal = ({
  onClickClose,
  namingPersistForm,
  ...props
}: ModalProps) => {
  const dispatch = useDispatch();
  const [genericOrdersData, setGenericOrdersData] = useState<dataTable[]>([]);
  const [campaignData, setCampaignData] = useState<dataTable[]>([]);
  const [campaignRowState, setCampaignRowState] = useState<campaignIdTypes>({});
  const [genericOrderRowState, setGenericOrderRowState] =
    useState<boolean>(true);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [allData, setAllData] = useState<any>();

  const selectorCursorGenericOrders = useSelector(
    getCursor(tableIds.GENERIC_ORDER_MODAL),
    isEqual
  );

  const selectorCursorCampaign = useSelector(
    getCursor(tableIds.CAMPAIGN_MODAL),
    isEqual
  );

  const [pageSizeCampaigns, setPageSizeCampaigns] = useState(
    selectorCursorCampaign.take
  );

  const [pageSizeGenericsOrders, setPageSizeGenericsOrders] = useState(
    selectorCursorGenericOrders.take
  );

  const selectorTabType = () => {
    switch (namingPersistForm) {
      case formNaming.NEW_COMMUNICATION_FORM:
        return tabsType.COMMUNICATION;

      case formNaming.NEW_DUPLICATE_COMMUNICATION_FORM:
        return tabsType.DUPLICATE_COMMUNICATION;

      case formNaming.EDIT_COMMUNICATION_FORM:
        return tabsType.EDIT_COMMUNICATION;
    }
  };

  const selectorIndexTab = useSelector(
    getCurrentIndexTabFormByTab(selectorTabType() as string),
    isEqual
  );

  useEffect(() => {
    setPageSizeCampaigns(selectorCursorCampaign.take);
    setPageSizeGenericsOrders(selectorCursorGenericOrders.take);
  }, [selectorCursorCampaign, selectorCursorGenericOrders]);

  const { error, loading, data } =
    selectorIndexTab === 2
      ? useQuery(GET_CAMPAIGNS, {
          variables: {
            input: {
              cursor: selectorCursorCampaign?.cursor,
              take: selectorCursorCampaign?.take,
              orderBy: selectorCursorCampaign?.orderBy,
              concessionId: selectorCursorCampaign?.concessionId,
              fromDate: selectorCursorCampaign?.fromDate,
              toDate: selectorCursorCampaign?.toDate,
              name: selectorCursorCampaign.name,
              state: selectorCursorCampaign.state,
            },
          },
        })
      : useQuery(GET_GENERIC_ORDERS, {
          variables: {
            input: {
              take: selectorCursorGenericOrders.take,
              orderBy: selectorCursorGenericOrders.orderBy,
              cursor: selectorCursorGenericOrders.cursor,
              toDate: selectorCursorGenericOrders.toDate,
              fromDate: selectorCursorGenericOrders.fromDate,
              indexType: selectorCursorGenericOrders.indexType,
              indexExpedition: selectorCursorGenericOrders.indexExpedition,
              classification: selectorCursorGenericOrders.classification,
              capitolId: selectorCursorGenericOrders.capitolId,
              subCapitolId: selectorCursorGenericOrders.subCapitolId,
              concessionId: selectorCursorGenericOrders.concessionId,
            },
          },
        });

  useEffect(() => {
    selectorIndexTab === 2
      ? dispatch(loadingData(tableIds.CAMPAIGN_MODAL, loading))
      : dispatch(loadingData(tableIds.GENERIC_ORDER_MODAL, loading));
  }, [loading]);

  useEffect(() => {
    if (!loading) {
      setAllData(data);
    }
  }, [data]);

  const { refetch } =
    selectorIndexTab === 2
      ? useQuery(GET_CAMPAIGNS, {
          variables: {
            input: {
              cursor: '',
              take: Math.abs(pageSizeCampaigns),
              orderBy: 'desc',
              concessionId: selectorCursorCampaign?.concessionId,
              fromDate: selectorCursorCampaign?.fromDate,
              toDate: selectorCursorCampaign?.toDate,
              name: selectorCursorCampaign.name,
              state: selectorCursorCampaign.state,
            },
          },
        })
      : useQuery(GET_GENERIC_ORDERS, {
          variables: {
            input: {
              take: Math.abs(pageSizeGenericsOrders),
              orderBy: 'desc',
              cursor: '',
              toDate: selectorCursorGenericOrders.toDate,
              fromDate: selectorCursorGenericOrders.fromDate,
              indexType: selectorCursorGenericOrders.indexType,
              indexExpedition: selectorCursorGenericOrders.indexExpedition,
              classification: selectorCursorGenericOrders.classification,
              capitolId: selectorCursorGenericOrders.capitolId,
              subCapitolId: selectorCursorGenericOrders.subCapitolId,
              concessionId: selectorCursorGenericOrders.concessionId,
            },
          },
        });

  // We take methods exposed in InputRadioDefaultTable with useImperativeHandle

  const ref = useRef<imperativeRefPagination>(null);

  const acceptData = useCallback(() => {
    dispatch(closeModal());
    dispatch(selectedRowModalAcceptData(namingPersistForm));
  }, [namingPersistForm]);
  useEffect(() => {
    dispatch(
      selectedRowModalTable(namingPersistForm as string, campaignRowState)
    );
    const tasks = async () => {
      if (
        selectorIndexTab === 1 &&
        allData?.genericOrders?.genericOrders.length > 0
      ) {
        const genericOrders = await selectAllOrders(
          allData.genericOrders.genericOrders
        );
        setGenericOrdersData(genericOrders);
      } else {
        setGenericOrdersData([]);
      }
      if (selectorIndexTab === 2 && allData?.campaigns?.campaigns.length > 0) {
        const campaigns = await selectAllOrders(allData.campaigns.campaigns);
        setCampaignData(campaigns);
      } else {
        setCampaignData([]);
      }
    };
    tasks().catch((err) => {
      Sentry.captureException(err);
      console.log(err);
    });
    if (error) {
      console.warn(error);
    }
  }, [allData, error, campaignRowState]);

  const onReFetchData = useCallback(async () => {
    const { data } = await refetch();
    setAllData(data);
  }, [refetch]);

  const onReFetchPagination = useCallback(async () => {
    return data;
  }, [data]);

  const [dataCapitols, dataConcessions, dataSubCapitols] = [
    useQuery(FETCH_CAPITOLS),
    useQuery(GET_CONCESSIONS),
    useQuery(FETCH_SUB_CAPITOLS),
  ];
  const filters = {
    concessions: dataConcessions?.data?.concessions
      ? dataConcessions.data.concessions
      : [],
  };
  const filtersDataOptions = {
    capitols: dataCapitols?.data?.concessions
      ? dataCapitols.data.concessions
      : [],
    concessions: dataConcessions?.data?.concessions
      ? dataConcessions.data.concessions
      : [],
    subCapitols: dataSubCapitols?.data?.subCapitols
      ? dataSubCapitols.data.subCapitols
      : [],
  };

  const pageInfo =
    selectorIndexTab === 2
      ? data?.campaigns.pageInfo
      : data?.genericOrders.pageInfo;

  const lastCursor =
    selectorIndexTab === 2
      ? data?.campaigns?.pageInfo.lastCursor
      : data?.genericOrders?.pageInfo.lastCursor;

  const totalCount =
    selectorIndexTab === 2
      ? data?.campaigns.totalCount
      : data?.genericOrders.totalCount;

  const combinedData =
    selectorIndexTab === 2
      ? {
          campaigns: campaignData,
          filters,
          totalCount,
          pageInfo,
          loading,
        }
      : {
          genericOrdersData,
          filtersDataOptions,
          totalCount,
          pageInfo,
          loading,
        };

  const fetchGenericOrderID = useCallback(
    async (id: string, take = 8) => {
      const dataSelector = {
        take,
        cursor: id,
        indexType: selectorCursorGenericOrders?.indexType,
        indexExpedition: selectorCursorGenericOrders?.indexExpedition,
        concessionId: selectorCursorGenericOrders?.concessionId,
        fromDate: selectorCursorGenericOrders?.fromDate,
        toDate: selectorCursorGenericOrders?.toDate,
        orderBy: 'desc',
        classification: selectorCursorGenericOrders?.classification,
        capitolId: selectorCursorGenericOrders?.capitolId,
        subCapitolId: selectorCursorGenericOrders?.subCapitolId,
      };

      if (take > 0) {
        const lastGenericOrder =
          genericOrdersData[genericOrdersData.length - 1]
            .genericOrCampaignOrderID;
        if (lastGenericOrder) {
          dispatch(
            setCursor(tableIds.GENERIC_ORDER_MODAL as string, dataSelector)
          );
        }
      } else {
        const firstGenericOrder = genericOrdersData[0].genericOrCampaignOrderID;

        if (firstGenericOrder) {
          dispatch(
            setCursor(tableIds.GENERIC_ORDER_MODAL as string, dataSelector)
          );
        }
      }

      return dataSelector;
    },
    [selectorCursorGenericOrders, genericOrdersData]
  );

  const fetchCampaignID = useCallback(
    async (id: string, take = pageSizeCampaigns) => {
      const dataSelector = {
        take,
        cursor: id,
        concessionId: selectorCursorCampaign?.concessionId,
        fromDate: selectorCursorCampaign?.fromDate,
        toDate: selectorCursorCampaign?.toDate,
        orderBy: 'desc',
        state: selectorCursorCampaign?.state,
        name: selectorCursorCampaign?.name,
      };

      if (take > 0) {
        const lastCampaign =
          campaignData[campaignData.length - 1].genericOrCampaignOrderID;
        if (lastCampaign) {
          dispatch(setCursor(tableIds.CAMPAIGN_MODAL as string, dataSelector));
        }
      } else {
        const firstCampaign = campaignData[0].genericOrCampaignOrderID;

        if (firstCampaign) {
          dispatch(setCursor(tableIds.CAMPAIGN_MODAL as string, dataSelector));
        }
      }

      return dataSelector;
    },
    [selectorCursorCampaign, campaignData]
  );

  const nextPagination = async () => {
    if (
      selectorIndexTab === 1 &&
      genericOrdersData.length >= 1 &&
      pageInfo.hasNextPage
    ) {
      await fetchGenericOrderID(
        genericOrdersData[genericOrdersData.length - 1]
          .genericOrCampaignOrderID as string
      );
      onReFetchPagination();
    }
    if (
      selectorIndexTab === 2 &&
      campaignData.length >= 1 &&
      pageInfo.hasNextPage
    ) {
      await fetchCampaignID(
        campaignData[campaignData.length - 1]
          .genericOrCampaignOrderID as string,
        8
      );
      onReFetchPagination();
    }
  };

  const backPagination = async () => {
    if (
      selectorIndexTab === 1 &&
      genericOrdersData.length >= 1 &&
      pageInfo.hasPreviousPage
    ) {
      await fetchGenericOrderID(
        genericOrdersData[0].genericOrCampaignOrderID as string,
        -8
      );
      onReFetchPagination();
    }
    if (
      selectorIndexTab === 2 &&
      campaignData.length >= 1 &&
      pageInfo.hasPreviousPage
    ) {
      await fetchCampaignID(
        campaignData[0].genericOrCampaignOrderID as string,
        -8
      );
      onReFetchPagination();
    }
  };

  const firstPage = useCallback(() => {
    const dataCursor =
      selectorIndexTab === 2
        ? {
            take: takeModal,
            cursor: '',
            concessionId: selectorCursorCampaign?.concessionId,
            fromDate: selectorCursorCampaign?.fromDate,
            toDate: selectorCursorCampaign?.toDate,
            orderBy: 'desc',
            name: selectorCursorCampaign?.name,
            state: selectorCursorCampaign?.state,
          }
        : {
            take: takeModal,
            cursor: '',
            indexType: selectorCursorGenericOrders?.indexType,
            indexExpedition: selectorCursorGenericOrders?.indexExpedition,
            concessionId: selectorCursorGenericOrders?.concessionId,
            fromDate: selectorCursorGenericOrders?.fromDate,
            toDate: selectorCursorGenericOrders?.toDate,
            classification: selectorCursorGenericOrders?.classification,
            orderBy: 'desc',
            capitolId: selectorCursorGenericOrders?.capitolId,
            subCapitolId: selectorCursorGenericOrders?.subCapitolId,
            state: selectorCursorGenericOrders?.state,
          };
    selectorIndexTab === 2
      ? dispatch(setCursor(tableIds.CAMPAIGN_MODAL as string, dataCursor))
      : dispatch(setCursor(tableIds.GENERIC_ORDER_MODAL as string, dataCursor));
    onReFetchPagination();
  }, [selectorCursorGenericOrders, selectorCursorCampaign]);

  const lastPage = useCallback(async () => {
    const restCount = totalCount % 8;
    const take = restCount === 0 ? 8 : restCount;
    const cursor = lastCursor;
    const dataCursor =
      selectorIndexTab === 2
        ? {
            take,
            cursor,
            concessionId: selectorCursorCampaign?.concessionId,
            fromDate: selectorCursorCampaign?.fromDate,
            toDate: selectorCursorCampaign?.toDate,
            orderBy: 'desc',
            name: selectorCursorCampaign?.name,
            state: selectorCursorCampaign?.state,
          }
        : {
            take,
            cursor,
            indexType: selectorCursorGenericOrders?.indexType,
            indexExpedition: selectorCursorGenericOrders?.indexExpedition,
            concessionId: selectorCursorGenericOrders?.concessionId,
            fromDate: selectorCursorGenericOrders?.fromDate,
            toDate: selectorCursorGenericOrders?.toDate,
            classification: selectorCursorGenericOrders?.classification,
            orderBy: 'desc',
            capitolId: selectorCursorGenericOrders?.capitolId,
            subCapitolId: selectorCursorGenericOrders?.subCapitolId,
            state: selectorCursorGenericOrders?.state,
          };
    if (selectorIndexTab === 2) {
      dispatch(setCursor(tableIds.CAMPAIGN_MODAL as string, dataCursor));
      dispatch(setTake(pageSizeCampaigns));
    } else {
      dispatch(setCursor(tableIds.GENERIC_ORDER_MODAL as string, dataCursor));
      dispatch(setTake(pageSizeGenericsOrders));
    }
    onReFetchPagination();
  }, [
    genericOrdersData,
    selectorCursorGenericOrders,
    campaignData,
    selectorCursorCampaign,
  ]);

  return (
    <ModalContainer onClickClose={onClickClose} fill="#445275">
      <Container {...props}>
        {selectorIndexTab === 2 ? (
          <RelatedCampaignTable
            titleText={'Filtrar Campanyas per:'}
            data={combinedData as dataTypes}
            setRowState={setCampaignRowState}
            onReFetchData={onReFetchData}
            onReFetchPagination={onReFetchPagination}
            tableId={tableIds.CAMPAIGN_MODAL}
            showButtonsPagination={false}
            setPageSize={setPageSizeCampaigns}
          />
        ) : (
          <InputRadioDefaultTable
            data={combinedData as DataTypesGenericOrderModal}
            onReFetchData={onReFetchData}
            onRefetchPagination={onReFetchPagination}
            namingPersistForm={namingPersistForm}
            ref={ref}
            titleText={'Filtrar Ordres de Treball per:'}
            tableId={tableIds.GENERIC_ORDER_MODAL}
            setIsSelectedRow={setGenericOrderRowState}
          />
        )}
        <ButtonContainer>
          <CloseButton
            text="Tornar enrere"
            variant="secondary"
            onClick={onClickClose}
          />
          <PaginationButtonsStyled
            pageCount={2}
            currentPage={0}
            nextPage={() => pageInfo?.hasNextPage && nextPagination()}
            previousPage={() => pageInfo?.hasPreviousPage && backPagination()}
            firstPage={() => pageInfo?.hasPreviousPage && firstPage()}
            lastPage={() => pageInfo?.hasNextPage && lastPage()}
            totalCount={totalCount}
          />
          <MainButtonStyled
            disabled={
              selectorIndexTab === 2
                ? !campaignRowState?.id
                : genericOrderRowState
            }
            text="Relacionar OT"
            onClick={acceptData}
          />
        </ButtonContainer>
      </Container>
    </ModalContainer>
  );
};

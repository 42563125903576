import { UNSPECIFIC } from './utils';

export enum WorkOrderDirection {
  DOWN = 'down',
  TOP = 'top',
  BOTH = 'both',
  UNSPECIFIC = 'sense especificar',
}

export enum Margin {
  UP = 'up',
  BOTH = 'both',
  DOWN = 'down',
  LEFT = 'left',
  RIGHT = 'right',
  UNSPECIFIC = 'sense especificar',
}

type WorkOrderOptions = {
  id?: string;
  name: string;
  value?: string;
};

export type SubCapitolOptions = {
  id: string;
  name: string;
  elementOrdersIds: string[];
};

export type CapitolOptions = {
  id: string;
  name: string;
  subCapitolsIDs: string[];
};

type predefineOrder = {
  name: string;
  id: string;
  capitolId: string;
  subCapitolId: string;
  elementOrderId: string;
  activityId: string;
};

export type AssetsOptions = {
  id: string;
  code: string;
};

type ElementOrdersOptions = {
  id: string;
  name: string;
  subCapitolIDs: string[];
};

export type equipmentTypes = {
  id: string;
  name: string;
};

type GroupTypes = {
  concessions: WorkOrderOptions[];
  groupRoads: WorkOrderOptions[];
  groupTracks: WorkOrderOptions[];
  groupLanes: WorkOrderOptions[];
  classifications: WorkOrderOptions[];
  capitols: CapitolOptions[];
  roads: WorkOrderOptions[];
  subCapitols: SubCapitolOptions[];
  predefinedOrders: predefineOrder[];
  activitys: WorkOrderOptions[];
  assets: AssetsOptions[];
  elementOrders: ElementOrdersOptions[];
  teams: equipmentTypes[];
};

export type WorkOrder = {
  id?: string;
  actionActivity?: string;
  actionElementOrder?: string;
  cotic: string;
  operator: string;
  registerInitDate: string;
  registerEndDate: string;
  predefine: string;
  capitol: string;
  subCapitol: string;
  classification: string;
  team: string;
  concession: string;
  city: string;
  pkInitRoad: string;
  pkEndRoad: string;
  road: string;
  roadDirections: string;
  pkInit: string;
  pkEnd: string;
  track: string;
  direction: 'top' | 'down' | 'both' | typeof UNSPECIFIC;
  margin: 'left' | 'right' | 'both' | typeof UNSPECIFIC;
  lane: string;
  campaign: string;
  description: string;
  isCampaignActive: string;
  intent?: string;
} & GroupTypes;

import React, { useLayoutEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useAsyncValue, useNavigate } from 'react-router-dom';

// Components
import MachineryForm from '../../../../../components/forms/ActionForms/MachineryForm';

// Types
import { MachineryTypesFormValues } from '../../../../../components/forms/ActionForms/MachineryForm/types';
import { coticOTSelected } from '../../../../../state/actions/ui/coticOTSelectedGeneralView';
import { OrderType } from '../../../../../types/orders';

// Constants
import { formActionNaming } from '../../../../../components/forms/ActionForms/constants';

export default function MachineryFormPage(): JSX.Element {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loaderData = useAsyncValue() as MachineryTypesFormValues;

  useLayoutEffect(() => {
    dispatch(
      coticOTSelected(
        OrderType.Action,
        loaderData.breadCrumbsData.orderParentCotic,
        loaderData.breadCrumbsData.orderParentId
      )
    );
  }, []);

  const onFormSubmit = () => {
    navigate('../animals');
  };

  return (
    <MachineryForm
      onFormSubmit={onFormSubmit}
      initialData={loaderData}
      sessionStorageKey={formActionNaming.NEW_MACHINERY_FORM}
    />
  );
}

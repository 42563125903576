// Dependencies
import styled from 'styled-components';

// Components
import { Typography } from '../../../display/Typography';
import { ArrowBottom } from '../../../../assets/icons/ArrowBottom';

// Styles utils
import { colors } from '../../../../styles/colors';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
`;

export const DropDownContainer = styled.div`
  border-radius: 0.5rem;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  gap: 1.2rem;
  width: 100%;

  svg > path {
    fill: ${colors.black};
  }
`;

export const DropDownHeader = styled.div`
  display: flex;
  justify-content: left;
  line-height: 3.8rem;
`;

export const SelectedOption = styled(Typography)`
  font-size: 2.4rem;
`;

export const ArrowDown = styled(ArrowBottom)`
  min-width: 1.3rem;
  height: 1rem;
`;

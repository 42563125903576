// Types
import { WorkOrder } from '../../../../types/workOrder';
import { NamingPersistForm } from '../types';

export type LocationTypes = {
  onFormSubmit: (values: LocationFormValues) => void;
  initialData?: LocationFormValues &
    WorkOrder & { parentExpeditionOrderStatus?: string; state?: string };
  textButton?: string;
} & NamingPersistForm;

export type LocationFormValues = {
  actionConcession: string;
  actionCity: string;
  actionPkInitRoad: string;
  actionPkEndRoad: string;
  actionRoad: string;
  actionPkInit: string;
  actionPkEnd: string;
  actionTrack: string;
  actionDirection: string;
  actionMargin: string;
  actionLane: string;
  actionCoordinates: string;
  previousFormData: FormData;
  breadCrumbsData: {
    orderParentId: string;
    orderParentCotic: string;
  };
};

export enum ActionDirection {
  DOWN = 'down',
  TOP = 'top',
  BOTH = 'both',
  UNSPECIFIC = 'sense especificar',
}

export enum ActionMargin {
  UP = 'up',
  BOTH = 'both',
  DOWN = 'down',
  LEFT = 'left',
  RIGHT = 'right',
  TOP = 'top',
  UNSPECIFIC = 'sense especificar',
}

export type Concessions =
  | {
      [K in string]: string | number | string[];
    }[]
  | undefined;

export interface Coordinates {
  lat: number;
  lng: number;
}

export type GroupTypes = {
  value: string;
  label: string;
};

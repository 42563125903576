import React, { useCallback, useEffect, useMemo, useState } from 'react';

import {
  useReactTable,
  getCoreRowModel,
  flexRender,
  getFilteredRowModel,
  createColumnHelper,
  Row,
} from '@tanstack/react-table';

// Mocks

// Types
import {
  TableProps,
  activeVehiclesColumnNames,
  inactiveVehiclesColumnNames,
  dataTable,
  SearchbarFilterTypes,
} from './types';

// Styled components
import {
  ContainerTable,
  FiltersContainer,
  InactiveVehiclesTableContainer,
  ActiveVehiclesTableContainer,
  SwapVehicleButton,
  SwapVehicleButtonLeft,
  InputRadioStyled,
  TrStyled,
} from './styles';

import {
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
} from '../../../summaryTables/SummaryTablesStyles';
import { Typography } from '../../../../Typography';

import { activeVehiclesMocksData, inactiveVehiclesMocksData } from './mocks';
import SearchBar from '../../../../../inputs/SearchBar';

export const VehiclesManagementTable = ({
  data,
  onAddInactiveVehicle,
  onAddActiveVehicle,
}: TableProps): JSX.Element => {
  const activeVehiclesDataAll = data?.activeVehicles ?? activeVehiclesMocksData;
  const inactiveVehiclesDataAll =
    data?.inactiveVehicles ?? inactiveVehiclesMocksData;

  const [activeVehiclesData, setActiveVehiclesData] = useState(
    activeVehiclesDataAll
  );
  const [inactiveVehiclesData, setInactiveVehiclesData] = useState(
    inactiveVehiclesDataAll
  );

  const [activeVehiclesChecked, setActiveVehiclesChecked] = useState({});
  const [inactiveVehiclesChecked, setInactiveVehiclesChecked] = useState({});

  const [filteredActiveVehicles, setFilteredActiveVehicles] = useState(false);
  const [filteredinactiveVehicles, setFilteredinactiveVehicles] =
    useState(false);

  const activeVehiclesColumnHelper = createColumnHelper<dataTable>();
  const inactiveVehiclesColumnHelper = createColumnHelper<dataTable>();

  useEffect(() => {
    setActiveVehiclesData(activeVehiclesDataAll);
    setInactiveVehiclesData(inactiveVehiclesDataAll);
  }, [data]);

  const activeVehiclesColumns = useMemo(
    () => [
      {
        enableHiding: true,
        id: 'select',
        cell: ({ row }) => (
          <InputRadioStyled
            name="activeRadioInput"
            {...{
              checked: row.getIsSelected(), // Returns whether or not the row is selected.
              disabled: !row.getCanSelect(), // Returns whether or not the row can be selected.
              indeterminate: row.getIsSomeSelected(), //Returns whether or not some of the row's sub rows are selected.
              onChange: row.getToggleSelectedHandler(), //Returns a handler that can be used to toggle the row.
            }}
          />
        ),
      },
      activeVehiclesColumnHelper.accessor((row) => row.plate, {
        id: activeVehiclesColumnNames.plate.id,
        header: () => activeVehiclesColumnNames.plate.label,
        cell: (info) => info.getValue(),
      }),
      activeVehiclesColumnHelper.accessor((row) => row.model, {
        id: activeVehiclesColumnNames.model.id,
        header: () => activeVehiclesColumnNames.model.label,
      }),
      activeVehiclesColumnHelper.accessor((row) => row.category, {
        id: activeVehiclesColumnNames.category.id,
        header: () => activeVehiclesColumnNames.category.label,
      }),
      activeVehiclesColumnHelper.accessor((row) => row.concession, {
        id: activeVehiclesColumnNames.concession.id,
        header: () => activeVehiclesColumnNames.concession.label,
      }),
    ],
    [
      activeVehiclesData,
      inactiveVehiclesData,
      activeVehiclesChecked,
      inactiveVehiclesChecked,
      data,
    ]
  );

  const inactiveVehiclesColumns = useMemo(
    () => [
      {
        enableHiding: true,
        id: 'select',
        cell: ({ row }) => (
          <InputRadioStyled
            name="inactiveRadioInput"
            {...{
              checked: row.getIsSelected(), // Returns whether or not the row is selected.
              disabled: !row.getCanSelect(), // Returns whether or not the row can be selected.
              indeterminate: row.getIsSomeSelected(), //Returns whether or not some of the row's sub rows are selected.
              onChange: row.getToggleSelectedHandler(), //Returns a handler that can be used to toggle the row.
            }}
          />
        ),
      },
      inactiveVehiclesColumnHelper.accessor((row) => row.plate, {
        id: inactiveVehiclesColumnNames.plate.id,
        header: () => inactiveVehiclesColumnNames.plate.label,
        cell: (info) => info.getValue(),
      }),
      inactiveVehiclesColumnHelper.accessor((row) => row.model, {
        id: inactiveVehiclesColumnNames.model.id,
        header: () => inactiveVehiclesColumnNames.model.label,
      }),
      inactiveVehiclesColumnHelper.accessor((row) => row.category, {
        id: inactiveVehiclesColumnNames.category.id,
        header: () => inactiveVehiclesColumnNames.category.label,
      }),
      inactiveVehiclesColumnHelper.accessor((row) => row.concession, {
        id: inactiveVehiclesColumnNames.concession.id,
        header: () => inactiveVehiclesColumnNames.concession.label,
      }),
    ],
    [
      activeVehiclesData,
      inactiveVehiclesData,
      activeVehiclesChecked,
      inactiveVehiclesChecked,
      data,
    ]
  );

  const activeVehiclesSearchFilter = useCallback<SearchbarFilterTypes>(
    (value) => {
      activeVehiclesTable.getColumn('plate')?.setFilterValue(value);
      value === '' && setFilteredActiveVehicles((prev) => !prev);
    },
    [filteredActiveVehicles]
  );

  const inactiveVehiclesSearchFilter = useCallback<SearchbarFilterTypes>(
    (value) => {
      inactiveVehiclesTable.getColumn('plate')?.setFilterValue(value);
      value === '' && setFilteredinactiveVehicles((prev) => !prev);
    },
    [filteredinactiveVehicles]
  );

  const handleRowClick = (row: Row<dataTable>) => {
    row.getToggleSelectedHandler()(row);
  };

  const addActiveVehicle = () => {
    const selectedRow =
      activeVehiclesTable.getSelectedRowModel().rows[0].original;

    onAddActiveVehicle(selectedRow.id);

    activeVehiclesTable.resetRowSelection(true);
  };

  const addInactiveVehicle = () => {
    const selectedRow =
      inactiveVehiclesTable.getSelectedRowModel().rows[0].original;

    onAddInactiveVehicle(selectedRow.id);

    inactiveVehiclesTable.resetRowSelection(true);
  };

  const activeVehiclesTable = useReactTable({
    data: activeVehiclesData,
    columns: activeVehiclesColumns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onRowSelectionChange: setActiveVehiclesChecked,
    state: {
      rowSelection: activeVehiclesChecked,
    },
    enableRowSelection: true,
    enableMultiRowSelection: false,
  });

  const inactiveVehiclesTable = useReactTable({
    data: inactiveVehiclesData,
    columns: inactiveVehiclesColumns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onRowSelectionChange: setInactiveVehiclesChecked,
    state: {
      rowSelection: inactiveVehiclesChecked,
    },
    enableRowSelection: true,
    enableMultiRowSelection: false,
  });

  return (
    <ContainerTable>
      <ActiveVehiclesTableContainer>
        <Typography size="L" variant="semiBold">
          Vehicles actius
        </Typography>
        <FiltersContainer>
          <SearchBar
            variant="form"
            onChangeInput={(e) => activeVehiclesSearchFilter(e.target.value)}
          />
        </FiltersContainer>
        <TableContainer>
          <Table>
            <Thead>
              {activeVehiclesTable.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <Th key={header.id}>
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                    </Th>
                  ))}
                </tr>
              ))}
            </Thead>
            <Tbody>
              {activeVehiclesTable.getRowModel().rows.map((row) => (
                <TrStyled
                  key={row.id}
                  checked={row.getIsSelected()}
                  indeterminate={!activeVehiclesTable.getIsSomeRowsSelected()}
                  onClick={() => handleRowClick(row)}
                >
                  {row.getVisibleCells().map((cell) => (
                    <Td key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </Td>
                  ))}
                </TrStyled>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
        <SwapVehicleButtonLeft onClick={addActiveVehicle}>
          ⮕
        </SwapVehicleButtonLeft>
      </ActiveVehiclesTableContainer>
      <InactiveVehiclesTableContainer>
        <Typography size="L" variant="semiBold">
          Vehicles inactius
        </Typography>
        <FiltersContainer>
          <SearchBar
            variant="form"
            onChangeInput={(e) => inactiveVehiclesSearchFilter(e.target.value)}
          />
        </FiltersContainer>
        <TableContainer>
          <Table>
            <Thead>
              {inactiveVehiclesTable.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <Th key={header.id}>
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                    </Th>
                  ))}
                </tr>
              ))}
            </Thead>
            <Tbody>
              {inactiveVehiclesTable.getRowModel().rows.map((row) => (
                <TrStyled
                  key={row.id}
                  checked={row.getIsSelected()}
                  indeterminate={!inactiveVehiclesTable.getIsSomeRowsSelected()}
                  onClick={() => handleRowClick(row)}
                >
                  {row.getVisibleCells().map((cell) => (
                    <Td key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </Td>
                  ))}
                </TrStyled>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
        <SwapVehicleButton onClick={addInactiveVehicle}>⬅</SwapVehicleButton>
      </InactiveVehiclesTableContainer>
    </ContainerTable>
  );
};

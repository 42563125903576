import styled, { css } from 'styled-components';

// Styles
import { colors } from '../../../styles/colors';
import { percentBaseResponsive, breakPoints } from '../../../styles/spaces';

type checkBoxProps = {
  indeterminate: boolean;
  isFirefox: boolean;
};

export const CheckBoxStyled = styled.input<checkBoxProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  appearance: none;
  cursor: pointer;
  border: 0.1rem solid ${colors.black};
  width: 1.4rem;
  height: 1.4rem;
  border-radius: 0.2rem;
  &:checked {
    background-color: ${colors.garnet};
  }
  &::after {
    display: none;
    content: '';
    position: absolute;
    ${({ indeterminate }) =>
      indeterminate &&
      css`
        display: flex;
        width: 0.7rem;
        height: 0;
        border: solid ${colors.garnet};
        border-width: 0.1rem 0.4rem 0.1rem 0.4rem;
      `}
  }
  &:checked::after {
    display: flex;
    width: 0.5rem;
    height: 0.8rem;
    margin-top: -0.25rem;
    border: solid white;
    border-width: 0 0.2rem 0.2rem 0;
    transform: rotate(45deg);
  }

  @media (max-width: ${breakPoints.landscapeTablet *
    percentBaseResponsive}rem) {
    width: 1.6rem;
    height: 1.6rem;
    &::after {
      ${({ indeterminate }) =>
        indeterminate &&
        css`
          width: 0.4rem;
          border-width: 0.05rem 0.2rem 0.05rem 0.2rem;
        `}
    }
    &:checked::after {
      border-width: 0 0.2rem 0.2rem 0;
    }
  }
`;

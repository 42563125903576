import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

// Styled Components
import {
  Container,
  Label,
  DataContainer,
  RowContainer,
  Title,
  DataText,
  Explore,
  RemoveButton,
  ContainerButton,
} from './styles';

// Actions
import { deselectedRowModalTable } from '../../../state/actions/ui/selectRowTableModal';

// Components
import { SpecialTag } from '../SpecialTag';

// Types
import { SelectionProps } from './types';
import { variant } from '../SpecialTag/types';

export const SelectionOT = ({
  label,
  data,
  openModal,
  namingPersistForm,
  type,
  ...props
}: SelectionProps): JSX.Element => {
  const dispatch = useDispatch();
  const deselectedModalAction = useCallback(() => {
    dispatch(deselectedRowModalTable(namingPersistForm));
  }, [namingPersistForm]);

  return (
    <Container {...props}>
      <Label component="label" variant="regular" size="S">
        {label}
      </Label>
      <DataContainer>
        {data?.isShowedData ? (
          <>
            <ContainerButton>
              <RemoveButton
                variant="delete"
                type="button"
                onClick={deselectedModalAction}
              />
            </ContainerButton>
            <RowContainer>
              <Title variant="regular" size="S" colorText="garnet">
                COTIC
              </Title>
              <DataText variant="regular" size="S">
                {data.data?.cotic.join('.')}
              </DataText>
            </RowContainer>
            <RowContainer>
              <Title variant="regular" size="S" colorText="garnet">
                Capítol
              </Title>
              <DataText variant="regular" size="S">
                {data.data?.capitol}
              </DataText>
            </RowContainer>
            <RowContainer>
              <Title variant="regular" size="S" colorText="garnet">
                Subcapitol
              </Title>
              <DataText variant="regular" size="S">
                {data.data?.subCapitol}
              </DataText>
            </RowContainer>
            {data.data?.tag && (
              <RowContainer>
                <SpecialTag variant={variant.SurveillanceRoute} />
              </RowContainer>
            )}
          </>
        ) : (
          <RowContainer>
            <Explore
              onClick={openModal}
              variant="regular"
              size="S"
              colorText="garnet"
              component="a"
            >
              + Explorar {type}...
            </Explore>
          </RowContainer>
        )}
      </DataContainer>
    </Container>
  );
};

import styled from 'styled-components';

import { colors } from '../../../styles/colors';
import { breakPoints, percentBaseResponsive } from '../../../styles/spaces';

export const InputRadioStyled = styled.input`
  display: flex;
  align-items: center;
  place-content: center;
  border: 0.1rem solid ${colors.black};
  appearance: none;
  width: 1.4rem;
  height: 1.4rem;
  border-radius: 50%;
  cursor: pointer;
  background-color: ${colors.white};
  position: relative;

  &:checked::after {
    content: '';
    width: 1rem;
    height: 1rem;
    background-color: ${colors.garnet};
    border-radius: 50%;
    position: absolute;
  }
  @media (max-width: ${breakPoints.landscapeTablet *
    percentBaseResponsive}rem) {
    width: 1.6rem;
    height: 1.6rem;
    &:checked::after {
      width: 1.2rem;
      height: 1.2rem;
    }
  }
`;

import { ActionFunction, redirect } from 'react-router-dom';

// Sentry
import * as Sentry from '@sentry/react';

// GQL
import { client } from '../../../gql/client';
import { SIGN_IN } from '../../../gql/mutations/auth';

// Type
import { AuthContextType } from '../../../hooks/useAuth';

export const ActionLogin: (
  userData: AuthContextType | null
) => ActionFunction | undefined = (userData) => async (formDataRaw) => {
  const formData = Object.fromEntries(await formDataRaw.request.formData());

  try {
    const {
      data: { signIn },
    } = await client.mutate({
      mutation: SIGN_IN,
      variables: {
        input: {
          email: formData.email,
          password: formData.password,
        },
      },
    });

    userData?.setUser({
      user: {
        id: signIn.user.id,
        email: signIn.user.email,
        name: signIn.user.name,
        surName: signIn.user.surName,
        rolActive: signIn.user.rolActive,
        roles: signIn.user.roles,
        permissions: signIn.user.permissions,
        accessZones: signIn.user.accessZones,
        validated: signIn.user.validated,
        teamId: signIn.user.teamIDs[0],
        concession: signIn.user.concessionIDs[0],
      },
      accessToken: signIn.accessToken,
      refreshToken: signIn.refreshToken,
    });

    Sentry.setUser({
      id: signIn.user.id,
      email: signIn.user.email,
      username: `${signIn.user.name} ${signIn.user.surName}`,
      rolActive: signIn.user.rolActive,
      teamId: signIn.user.teamIDs[0],
    });

    Sentry.setExtras({
      permissions: signIn.user.permissions,
    });

    if (!signIn.user.validated) {
      return redirect('/reset-password');
    }
  } catch (err) {
    Sentry.captureException(err);
    const errorMessage = (err as Error)?.message;
    console.log('Error', err);
    return errorMessage;
  }

  return null;
};

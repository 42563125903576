import styled from 'styled-components';

// Styles utils
import {
  spaces,
  breakPoints,
  percentBaseResponsive,
} from '../../../styles/spaces';

// Components
import { Typography } from '../../display/Typography';

export const DoubleColumnContainer = styled.div`
  display: flex;
  gap: ${spaces.xxxl}rem 10rem;
  flex-wrap: wrap;
  @media (max-width: ${breakPoints.smallDesktop * percentBaseResponsive}rem) {
    flex-direction: column;
  }
`;

export const ContainerDescription = styled.div`
  width: 100%;
  max-width: 96.9rem;
  min-width: 71rem;
  flex: 1;
`;

export const ContainerOT = styled.div`
  width: 100%;
  max-width: 77.6rem;
  flex: 1;
  > div {
    gap: 2.2rem;
  }
  @media (max-width: ${breakPoints.smallDesktop * percentBaseResponsive}rem) {
    max-width: 90rem;
    > div {
      gap: ${spaces.l}rem;
    }
  }
  @media (max-width: ${breakPoints.landscapeTablet *
    percentBaseResponsive}rem) {
    max-width: initial;
  }
`;

export const ErrorMessage = styled(Typography)`
  margin-top: ${spaces.xxs}rem;
`;

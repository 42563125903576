import React, { useCallback, useEffect, useMemo, useState } from 'react';

import {
  useReactTable,
  getCoreRowModel,
  flexRender,
  getFilteredRowModel,
  createColumnHelper,
} from '@tanstack/react-table';

// Mocks

// Types
import {
  TableProps,
  dataTable,
  SearchbarFilterTypes,
  materialsColumnNames,
} from './types';

// Styled components
import {
  ContainerTable,
  FiltersContainer,
  MaterialsTableContainer,
  TrStyled,
} from './styles';

import {
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
} from '../../../summaryTables/SummaryTablesStyles';
import { Typography } from '../../../../Typography';

import { materialsMocksData } from './mocks';
import SearchBar from '../../../../../inputs/SearchBar';

export const MaterialsManagementTable = ({ data }: TableProps): JSX.Element => {
  const materials = data?.materials ?? materialsMocksData;
  const [materialsData, setMaterialsData] = useState(materials);
  const [filteredMaterials, setFilteredMaterials] = useState(false);

  const materialsColumnHelper = createColumnHelper<dataTable>();

  useEffect(() => {
    setMaterialsData(materials);
  }, [data]);

  const materialsColumns = useMemo(
    () => [
      materialsColumnHelper.accessor((row) => row.materialId, {
        id: materialsColumnNames.materialId.id,
        header: () => materialsColumnNames.materialId.label,
        cell: (info) => info.getValue(),
      }),
      materialsColumnHelper.accessor((row) => row.name, {
        id: materialsColumnNames.name.id,
        header: () => materialsColumnNames.name.label,
      }),
      materialsColumnHelper.accessor((row) => row.codification, {
        id: materialsColumnNames.codification.id,
        header: () => materialsColumnNames.codification.label,
      }),
      materialsColumnHelper.accessor((row) => row.subCodification, {
        id: materialsColumnNames.subCodification.id,
        header: () => materialsColumnNames.subCodification.label,
      }),
      materialsColumnHelper.accessor((row) => row.typeOfMeasurement, {
        id: materialsColumnNames.typeOfMeasurement.id,
        header: () => materialsColumnNames.typeOfMeasurement.label,
      }),
    ],
    [materialsData, data]
  );

  const materialsSearchFilter = useCallback<SearchbarFilterTypes>(
    (value) => {
      activeVehiclesTable.getColumn('materialId')?.setFilterValue(value);
      value === '' && setFilteredMaterials((prev) => !prev);
    },
    [filteredMaterials]
  );

  const activeVehiclesTable = useReactTable({
    data: materialsData,
    columns: materialsColumns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    enableRowSelection: true,
    enableMultiRowSelection: false,
  });
  return (
    <ContainerTable>
      <MaterialsTableContainer>
        <Typography size="L" variant="semiBold">
          Materials
        </Typography>
        <FiltersContainer>
          <SearchBar
            variant="form"
            onChangeInput={(e) => materialsSearchFilter(e.target.value)}
          />
        </FiltersContainer>
        <TableContainer>
          <Table>
            <Thead>
              {activeVehiclesTable.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <Th key={header.id}>
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                    </Th>
                  ))}
                </tr>
              ))}
            </Thead>
            <Tbody>
              {activeVehiclesTable.getRowModel().rows.map((row) => (
                <TrStyled
                  key={row.id}
                  checked={row.getIsSelected()}
                  indeterminate={!activeVehiclesTable.getIsSomeRowsSelected()}
                >
                  {row.getVisibleCells().map((cell) => (
                    <Td key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </Td>
                  ))}
                </TrStyled>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </MaterialsTableContainer>
    </ContainerTable>
  );
};

import React, { useCallback, useLayoutEffect } from 'react';
import { useNavigate, useAsyncValue } from 'react-router-dom';
import { useDispatch } from 'react-redux';

// Components Styled
import {
  AttentionAccidentTableStyled,
  Container,
  ContainerContent,
  MainButtonStyled,
  SubContainerContent,
  SummaryContainer,
  TableContainer,
} from './styles';

//Components
import AccidentContentCardResume from '../../../../../../components/display/AccidentContentCardResume';
import { WorkOrder } from '../../../../../../components/display/ShowOT/WorkOrder';

// Types
import { AccidentResume } from '../../../../../../types/accident';
import { VehiclesInvolvedSummary } from '../../../../../../components/display/tables/summaryTables/VehiclesInvolvedSummary';
import { useForm } from 'react-hook-form';
import { Typography } from '../../../../../../components/display/Typography';
import { Order, OrderState, OrderType } from '../../../../../../types/orders';
import { variant } from '../../../../../../components/display/SpecialTag/types';
import { VehiclesInvolvedType } from '../../../../../../components/display/tables/summaryTables/VehiclesInvolvedSummary/types';
import { subCapitolsType } from '../../../../../../components/display/ShowOT/WorkOrder/types';

// Utils
import { ImageData } from '../../../../../../components/display/ResumeLoadImages/types';

// Redux
import { coticOTSelected } from '../../../../../../state/actions/ui/coticOTSelectedGeneralView';
import { InputTextArea } from '../../../../../../components/inputs/InputTextArea';
import { tabs } from '../../../../../../state/actions/ui/tabs';
import { tabsType } from '../../../../../../state/reducers/ui/tabs';
import { groupLanes, groupTracks } from '../../../../../../data/orders';

export default function AccidentSummaryGeneralView(): JSX.Element {
  const loaderData = useAsyncValue() as AccidentResume &
    Order & {
      orderId: string;
      cotic: string;
      vehicles: VehiclesInvolvedType[];
      damages: string;
      stateOrderExpeditionParent?: string;
      margin: string;
      direction: string;
      lane: string;
      track: string;
    };
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { control } = useForm();
  const accidentData = loaderData;

  useLayoutEffect(() => {
    if (!accidentData) navigate('/work-orders/new-order');
    dispatch(
      coticOTSelected(
        OrderType.Accident,
        accidentData.cotic as unknown as string,
        accidentData.orderId
      )
    );
  }, [accidentData]);

  const handleContinueAccident = useCallback(() => {
    dispatch(tabs(tabsType.WORK_ORDERS_GENERAL, 1));
    navigate('/work-orders/accidents');
  }, []);

  return (
    <Container>
      {accidentData && (
        <Container>
          <WorkOrder
            id={accidentData.orderId as string}
            name="Order de treball"
            cotic={accidentData.cotic as string}
            type={accidentData.type as OrderType}
            tag={accidentData.specialTag as variant}
            capitol={accidentData.capitol}
            subCapitol={accidentData.subCapitol}
            concession={accidentData.concession}
            road={accidentData.road}
            pkInit={(accidentData.pkInit as string) || ''}
            pkEnd={(accidentData.pkEnd as string) || ''}
            description={accidentData.description as string}
            date={accidentData.registerInitDate as string}
            modelTypeId={accidentData.id as string}
            capitols={accidentData.capitols}
            subCapitolsValues={
              accidentData.subCapitolsValues as subCapitolsType[]
            }
            concessions={accidentData.concessions}
            roadsValues={accidentData.roadsValues}
            disabled={
              loaderData.state === OrderState.End ||
              loaderData.state === OrderState.Annulled ||
              loaderData.stateOrderExpeditionParent === OrderState.End ||
              loaderData.stateOrderExpeditionParent === OrderState.Annulled
            }
            margin={accidentData.margin}
            lane={accidentData.lane}
            track={accidentData.track}
            direction={accidentData.direction}
            groupLanes={groupLanes}
            groupTracks={groupTracks}
          />

          <ContainerContent>
            <AccidentContentCardResume
              mediumKnowledge={accidentData.mediumKnowledge}
              laneCutting={accidentData.laneCutting}
              policeReport={accidentData.policeReport}
              surveillanceBody={accidentData.surveillanceBody}
              policeReportNumber={accidentData?.policeReportNumber}
              weather={accidentData.weather}
              surfaceCondition={accidentData.surfaceCondition}
              luminosity={accidentData.luminosity}
              conditionsDriving={accidentData.conditionsDriving}
              roadConditionDescription={accidentData.roadConditionDescription}
              images={accidentData.images as ImageData[]}
            />
            <SubContainerContent>
              <SummaryContainer>
                <TableContainer>
                  <Typography component="h3" variant="semiBold">
                    Atenció a l’accident
                  </Typography>
                  <AttentionAccidentTableStyled
                    disabled
                    control={control}
                    defaultValues={accidentData}
                  />
                </TableContainer>
              </SummaryContainer>

              {(accidentData?.vehicles?.length > 0 || accidentData.damages) && (
                <SummaryContainer>
                  {accidentData?.vehicles.length > 0 && (
                    <TableContainer>
                      <Typography component="h3" variant="semiBold">
                        Vehicles implicats
                      </Typography>
                      <VehiclesInvolvedSummary data={accidentData.vehicles} />
                    </TableContainer>
                  )}
                  {accidentData.damages && (
                    <TableContainer>
                      <Typography component="h3" variant="semiBold">
                        Danys
                      </Typography>
                      <InputTextArea
                        label=""
                        disabled
                        value={accidentData.damages}
                      />
                    </TableContainer>
                  )}
                </SummaryContainer>
              )}
              <MainButtonStyled
                type="button"
                text={'Continuar'}
                onClick={handleContinueAccident}
              />
            </SubContainerContent>
          </ContainerContent>
        </Container>
      )}
    </Container>
  );
}

import styled from 'styled-components';

// Components
import { Typography } from '../../../display/Typography';
import { MainButton } from '../../../inputs/buttons/MainButton';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3rem;
  margin-bottom: 0.8rem;
`;

export const Title = styled(Typography)`
  font-size: 2.2rem;
`;

export const ContainerButtons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
`;

export const StyledButton = styled(MainButton)`
  width: 100%;
  justify-content: center;
`;

import styled from 'styled-components';
import { AdvancedImage } from '@cloudinary/react';
import { Typography } from '../../../display/Typography';
import { spaces } from '../../../../styles/spaces';
import { fontWeight } from '../../../../styles/fonts';

export const StyledAdvancedImage = styled(AdvancedImage)`
  max-height: 50vh;
  width: 100%;
  object-fit: contain;
`;

export const InfoContainer = styled.div``;

export const StyledTypography = styled(Typography)`
  font-weight: ${fontWeight.semiBold};
  margin: ${spaces.xs}rem 0;
`;

import { gql } from '@apollo/client';

export const CREATE_NEW_CAMPAIGN = gql`
  mutation CreateCampaign($input: NewCampaign!) {
    createCampaign(input: $input) {
      id
      name
      concessionIDs
      roadIDs
      teamIDs
      track
      direction
      margin
      lane
      pkInitKm
      pkInitMeter
      pkEndKm
      pkEndMeter
      orderIDs
    }
  }
`;

export const UPDATE_CAMPAIGN = gql`
  mutation UpdateCampaign($input: UpdateCampaign!) {
    updateCampaign(input: $input) {
      id
      name
      concessionIDs
      roadIDs
      teamIDs
      track
      direction
      margin
      lane
      pkInitKm
      pkInitMeter
      pkEndKm
      pkEndMeter
      orderIDs
    }
  }
`;

export const UPDATE_CAMPAIGN_RELATIONSHIPS = gql`
  mutation UpdateCampaignRelationships($input: UpdateCampaignRelationships!) {
    updateCampaignRelationships(input: $input) {
      id
      name
      concessionIDs
      roadIDs
      teamIDs
      track
      direction
      margin
      lane
      pkInitKm
      pkInitMeter
      pkInitKm
      pkInitMeter
      orderIDs
    }
  }
`;

import styled, { css } from 'styled-components';

// Types
import { buttonProps } from './types';

// Styles
import {
  breakPoints,
  percentBaseResponsive,
  spaces,
} from '../../../styles/spaces';
import { colors } from '../../../styles/colors';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spaces.xl}rem;
  width: 100%;
`;

export const ContainerTabsButtons = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const Ul = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  max-width: 60rem;

  @media (max-width: ${breakPoints.smallDesktop * percentBaseResponsive}rem) {
    flex-direction: row;
    border: 0.1rem solid ${colors.greyDark};
    border-radius: 1rem;
    max-width: 70rem;
  }
  @media (max-width: ${breakPoints.portraitTablet * percentBaseResponsive}rem) {
    flex-direction: column;
    border: none;
  }
`;

export const Li = styled.li`
  flex: 1;
  white-space: nowrap;
  :last-child > button {
    border: none;
  }
  @media (max-width: ${breakPoints.smallDesktop * percentBaseResponsive}rem) {
  }
`;

export const Button = styled.button<buttonProps>`
  appearance: none;
  background: none;
  border: none;
  padding: 0.8rem ${spaces.m}rem;
  width: 100%;
  border-bottom: 0.1rem solid ${colors.greyLight};
  ${({ isActive }) =>
    isActive &&
    css`
      outline-offset: -0.1rem;
      outline: 0.2rem solid ${colors.garnet};
      border-radius: 1rem;
      border: none;
    `}
  @media (max-width: ${breakPoints.smallDesktop * percentBaseResponsive}rem) {
    border: none;
  }

  @media (max-width: ${breakPoints.portraitTablet * percentBaseResponsive}rem) {
    border-bottom: 0.1rem solid ${colors.greyLight};
  }
`;

export const Section = styled.div`
  width: 100%;
  height: 100%;
`;

export const ContainerUl = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.7rem;
`;

import { gql } from '@apollo/client';

export const GET_CONCESSION_BY_ID = gql`
  query GetConcessionById($id: ID!) {
    concession(id: $id) {
      id
      name
      location
      centerLatitud
      centerLongitud
      roadIDs
      vehicleIDs
    }
  }
`;

export const GET_ROADS = gql`
  query GetRoads {
    roads {
      id
      name
      directions
      pkInitKm
      pkInitMeter
      pkEndKm
      pkEndMeter
      concessionIDs
    }
  }
`;

export const GET_ROAD_BY_ID = gql`
  query GetRoadById($id: ID!) {
    road(id: $id) {
      id
      name
      directions
      pkInitKm
      pkInitMeter
      pkEndKm
      pkEndMeter
      concessionIDs
    }
  }
`;

export const GET_ROADS_BY_IDS = gql`
  query GetRoadsByIds($ids: [ID!]!) {
    readRoads(ids: $ids) @client {
      id
      name
      directions
      pkInitKm
      pkInitMeter
      pkEndKm
      pkEndMeter
      concessionIDs
    }
  }
`;

import { gql } from '@apollo/client';

export const CREATE_NEW_ORDER = gql`
  mutation CreateOrder($input: NewOrder!) {
    createOrder(input: $input) {
      id
      cotic
      indexExpedition
      indexType
      type
      state
      communicationIDs
      userId
      classificationIDs
      capitolIDs
      subCapitolIDs
      elementOrderIDs
      creationDate
      registerInitDate
      registerEndDate
      description
      parentExpeditionOrderIDs
      isTransferred
    }
  }
`;

export const DELETE_ORDER_BY_ID = gql`
  mutation deleteOrderById($id: ID!) {
    deleteOrderById(id: $id) {
      id
      cotic
      indexExpedition
      indexType
      type
      state
      communicationIDs
      userId
      classificationIDs
      capitolIDs
      subCapitolIDs
      elementOrderIDs
      creationDate
      registerInitDate
      registerEndDate
      description
      parentExpeditionOrderIDs
      isTransferred
    }
  }
`;

export const UPDATE_ORDER = gql`
  mutation UpdateOrder($input: UpdateOrder!) {
    updateOrder(input: $input) {
      id
      cotic
      indexExpedition
      indexType
      type
      state
      communicationIDs
      userId
      classificationIDs
      capitolIDs
      subCapitolIDs
      elementOrderIDs
      creationDate
      registerInitDate
      registerEndDate
      description
      parentExpeditionOrderIDs
      parentOrderCommunicationIDs
      isTransferred
    }
  }
`;

export const UPDATE_ORDERS = gql`
  mutation UpdateOrders($input: UpdateOrders!) {
    updateOrders(input: $input) {
      id
      cotic
      indexExpedition
      indexType
      type
      state
      isCommunicationRoot
      communicationIDs
      userId
      classificationIDs
      capitolIDs
      subCapitolIDs
      elementOrderIDs
      creationDate
      registerInitDate
      registerEndDate
      description
      parentExpeditionOrderIDs
    }
  }
`;

export const UPDATE_ORDER_RELATIONSHIPS = gql`
  mutation UpdateOrderRelationships($input: UpdateOrderRelationships!) {
    updateOrderRelationships(input: $input) {
      id
      cotic
      indexExpedition
      indexType
      type
      state
      communicationIDs
      userId
      classificationIDs
      capitolIDs
      subCapitolIDs
      elementOrderIDs
      creationDate
      registerInitDate
      registerEndDate
      description
      parentExpeditionOrderIDs
    }
  }
`;

export const UPDATE_PARENT_ORDER = gql`
  mutation ConnectNewParentOrderById($input: OrderToConnect!) {
    connectNewParentOrderById(input: $input) {
      id
      cotic
      indexExpedition
      indexType
      type
      state
      communicationIDs
      userId
      classificationIDs
      capitolIDs
      subCapitolIDs
      elementOrderIDs
      creationDate
      registerInitDate
      registerEndDate
      description
      parentExpeditionOrderIDs
    }
  }
`;

export const UPDATE_ORDER_COMMUNICATION_SUCCESSOR_RELATIONSHIP = gql`
  mutation UpdateOrderCommunicationSuccessorRelationships(
    $input: UpdateOrderCommunicationSuccessorRelationships!
  ) {
    updateOrderCommunicationSuccessorRelationships(input: $input) {
      id
      cotic
      type
      state
      communicationIDs
      userId
      classificationIDs
      capitolIDs
      subCapitolIDs
      elementOrderIDs
      creationDate
      registerInitDate
      registerInitDate
      description
    }
  }
`;

export const TRANSFER_ACCIDENT = gql`
  mutation TransferAccident($input: AccidentToTransfer!) {
    transferAccident(input: $input) {
      id
      cotic
      indexExpedition
      indexType
      type
      state
      communicationIDs
      userId
      classificationIDs
      capitolIDs
      subCapitolIDs
      elementOrderIDs
      creationDate
      registerInitDate
      registerEndDate
      description
      parentExpeditionOrderIDs
      isTransferred
    }
  }
`;

export const TRANSFER_ACTION = gql`
  mutation TransferAction($input: ActionToTransfer!) {
    transferAction(input: $input) {
      id
      cotic
      indexExpedition
      indexType
      type
      state
      communicationIDs
      userId
      classificationIDs
      capitolIDs
      subCapitolIDs
      elementOrderIDs
      creationDate
      registerInitDate
      registerEndDate
      description
      parentExpeditionOrderIDs
      isTransferred
    }
  }
`;

export const TRANSFER_COMMUNICATION = gql`
  mutation TransferCommunication($input: CommunicationToTransfer!) {
    transferCommunication(input: $input) {
      id
      cotic
      indexExpedition
      indexType
      type
      state
      communicationIDs
      userId
      classificationIDs
      capitolIDs
      subCapitolIDs
      elementOrderIDs
      creationDate
      registerInitDate
      registerEndDate
      description
      parentExpeditionOrderIDs
      isTransferred
    }
  }
`;

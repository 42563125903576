import React, { useCallback } from 'react';
import { useAsyncValue, useRevalidator } from 'react-router-dom';

// Components
import { formAnimalManagementNaming } from '../../../../components/forms/AddAnimalManagementForm/constants';

// Types
import { AnimalManagementLoader } from './types';

// Styles
import { Container, AnimalsManagementSection } from './styles';

// GQL
import { AnimalManagement } from '../../../../components/forms/AddAnimalManagementForm/types';

import { AnimalsManagementTable } from '../../../../components/display/tables/formTables/managementTables/AnimalsManagementTable';
import { AddAnimalManagementForm } from '../../../../components/forms/AddAnimalManagementForm';
import { client } from '../../../../gql/client';
import { CREATE_NEW_ANIMAL } from '../../../../gql/mutations/animals';
import { NEW_ANIMAL } from '../../../../gql/fragments/animals';

export default function AnimalsManagementPage(): JSX.Element {
  const loaderData = useAsyncValue() as AnimalManagementLoader;
  const revalidator = useRevalidator();
  const allAnimalNames = loaderData.data.map((animal) =>
    animal.name
      .toLowerCase()
      .split('')
      .filter((letter) => letter !== ' ')
      .join('')
  );

  const onReFetchData = useCallback(() => {
    revalidator.revalidate();
  }, []);

  const onFormSubmit = async (data: AnimalManagement) => {
    const animalNameParsed = data.name
      .toLowerCase()
      .split('')
      .filter((letter) => letter !== ' ')
      .join('');

    if (allAnimalNames.some((name) => name === animalNameParsed)) {
      return 'L’animal ja existeix.';
    }
    await client.mutate({
      mutation: CREATE_NEW_ANIMAL,
      variables: {
        input: {
          name: data.name,
        },
      },
      update(cache, { data: { createAnimal } }) {
        cache.modify({
          fields: {
            animals(existingAnimals = []) {
              const createAnimalRef = client.writeFragment({
                fragment: NEW_ANIMAL,
                data: createAnimal,
              });

              return [...existingAnimals, createAnimalRef];
            },
          },
        });
      },
    });

    onReFetchData();
  };

  return (
    <Container>
      <AnimalsManagementSection>
        <AddAnimalManagementForm
          initialData={loaderData.cacheData}
          onFormSubmit={onFormSubmit}
          namingPersistForm={
            formAnimalManagementNaming.NEW_ANIMAL_MANAGEMENT_FORM
          }
        />
      </AnimalsManagementSection>
      <AnimalsManagementSection>
        <AnimalsManagementTable
          data={{
            animals: loaderData.data,
          }}
        />
      </AnimalsManagementSection>
    </Container>
  );
}

import {
  ACTION_TABS_PATH,
  ACTION_TABS_PATH_RESET,
} from '../../actions/ui/actionTabs';

export const initialActionTabsState = {
  path: ['operators-and-vehicles'],
};

type actionTabsType = {
  type: string;
  payload: { path: string };
};

export default function actionTabsReducer(
  state = initialActionTabsState,
  { type, payload }: actionTabsType
) {
  switch (type) {
    case ACTION_TABS_PATH:
      return {
        ...state,
        path: [...state.path, payload.path],
      };
    case ACTION_TABS_PATH_RESET:
      return {
        ...initialActionTabsState,
      };
    default:
      return state;
  }
}

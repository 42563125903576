import { defer, LoaderFunction } from 'react-router-dom';

// Sentry
import * as Sentry from '@sentry/react';

// Schema
//import { newCommunicationSchema } from '../../../../../components/forms/NewCommunicationForm/schema';

// Types
import { AuthContextType } from '../../../../../../hooks/useAuth';

// GraphQL
import { client } from '../../../../../../gql/client';
import { selectAccidentByOrderId } from '../../../../../../gql/selectors/accidents';

const loadAccidentSummary = async (
  orderID: string,
  userData: AuthContextType | null
) => {
  try {
    const orderAccidentData = await selectAccidentByOrderId(orderID);

    return {
      ...orderAccidentData,
      operator: `${userData?.user.user.name} ${
        userData?.user.user.surName.split(' ')[0]
      }`,
    };
  } catch (err) {
    Sentry.captureException(err);
    console.log('> Communication Summary General View loading error:', err);
    return false;
  }
};
export const LoaderGeneralViewAccidentSummary: (
  userData: AuthContextType | null
) => LoaderFunction =
  (userData) =>
  async ({ params }) => {
    if (!params.id) return null;
    if (Object.keys(client.cache.extract()).length === 0) return null;

    const loaderToResolve = await loadAccidentSummary(params.id, userData);

    return defer({ data: loaderToResolve });
  };

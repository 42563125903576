import { gql } from '@apollo/client';

export const CREATE_NEW_MATERIAL_UNIT = gql`
  mutation CreateMaterialUnit($input: NewMaterialUnit!) {
    createMaterialUnit(input: $input) {
      id
      unit
      actionIDs
      materialIDs
    }
  }
`;

export const UPDATE_MANY_MATERIALS_UNITS = gql`
  mutation UpdateManyMaterials($input: [UpdateMaterialUnit]) {
    updateManyMaterialUnits(input: $input) {
      id
      unit
      actionIDs
      materialIDs
    }
  }
`;

export const DELETE_MANY_MATERIALS = gql`
  mutation DeleteManyMaterials($ids: [ID!]) {
    deleteManyMaterialUnits(ids: $ids) {
      id
    }
  }
`;

// Dependencies
import styled, { css } from 'styled-components';

// Styles
import { colors } from '../../../../styles/colors';
import {
  breakPoints,
  percentBaseResponsive,
  spaces,
} from '../../../../styles/spaces';

//Components
import { Typography } from '../../../display/Typography';
import { ArrowBottom } from '../../../../assets/icons/ArrowBottom';

// Types
import { InputSizeTypes, TypeDropDown } from './types';
import { fontSize, fontWeight } from '../../../../styles/fonts';

type ContainerProps = {
  typeDropDown: TypeDropDown;
  labelText: boolean;
  inputSize: InputSizeTypes;
};

type HeaderProps = {
  typeDropDown: TypeDropDown;
  isActive: boolean;
  borderError?: boolean;
  isDisabled?: boolean;
};

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  gap: ${spaces.xxxs}rem;
  cursor: pointer;
  width: 100%;
  ${({ inputSize }) =>
    ({
      XXL: css`
        width: 38rem;
      `,
      XL: css`
        width: 35rem;
      `,
      L: css`
        width: 27rem;
      `,
      M: css`
        width: 22rem;
      `,
      S: css`
        width: 17rem;
      `,
      XS: css`
        width: 10rem;
      `,
    }[inputSize])};

  // width: ${(props) => props.typeDropDown === 'Table' && '100%'};
`;

export const Label = styled(Typography)`
  white-space: nowrap;
  font-size: ${fontSize.S};
  @media (max-width: ${breakPoints.landscapeTablet *
    percentBaseResponsive}rem) {
    font-size: ${fontSize.XS};
  }
`;

export const DropDownContainer = styled.div``;

export const DropDownHeader = styled.div<HeaderProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${spaces.xxxs}rem;
  padding: 0 1rem;
  height: 4rem;
  border-radius: 0.5rem;
  background: ${colors.white};
  border: 0.1rem solid ${colors.black};

  ${(props) =>
    props.isActive &&
    css`
      outline: 0.08rem solid ${colors.black};
      ${SelectedOption} {
        text-shadow: 0 0 1px;
      }
    `}

  ${(props) =>
    props.typeDropDown === 'Table' &&
    css`
      border: none;
      outline: none;
      background: initial;
    `};

  ${(props) =>
    props.isDisabled &&
    css`
      background: ${colors.greyLight};
      border: none;
      ${SelectedOption} {
        font-weight: ${fontWeight.medium};
        color: ${colors.greyDark};
      }
    `};

  ${(props) =>
    props.borderError &&
    css`
      border: 0.1rem solid ${colors.lightCoralRed};
    `};

  :focus {
    outline: 0.08rem solid ${colors.black};
  }
`;

export const SelectedOption = styled(Typography)`
  font-size: ${fontSize.S};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  @media (max-width: ${breakPoints.landscapeTablet *
    percentBaseResponsive}rem) {
    font-size: ${fontSize.XS};
  }
`;

export const ArrowDown = styled(ArrowBottom)`
  path {
    fill: ${colors.black};
  }
  vertical-align: middle;
  min-width: 1.3rem;
  min-height: 0.9rem;
`;

import React, { useCallback, useState } from 'react';
import { useAsyncValue, useNavigate, useRevalidator } from 'react-router-dom';
import { useSelector } from 'react-redux';

// Styles
import { Container, ContainerButton } from '../components/MainStyledComponents';

// Types
import {
  tableIds,
  takeBase,
} from '../../../../components/display/tables/types';

// Components
import { ValidationsTable } from '../../../../components/display/tables/ValidationsTable';
import { MainButton } from '../../../../components/inputs/buttons/MainButton';
import { dataTypes } from '../../../../components/display/tables/ValidationsTable/types';

// Constants
import {
  formActionNaming,
  formActionValidationNaming,
} from '../../../../components/forms/ActionForms/constants';

// Utils
import { compressSessionStorage } from '../../../../utils/sessionStorage';

// GQL
import { client } from '../../../../gql/client';
import {
  GET_ACTION_BY_ID,
  FETCH_ACTIONS,
} from '../../../../gql/queries/actions/actions';
import { GET_ORDER_BY_ID } from '../../../../gql/queries/orders/orders';
import { selectActionOrders } from '../../../../gql/selectors/actions';

// Redux
import { actionTabsReset } from '../../../../state/actions/ui/actionTabs';
import { useDispatch } from 'react-redux';
import { getCursor } from '../../../../state/selectors/ui/tables';
import { setCursor } from '../../../../state/actions/ui/cursorTables';
import { externalCompaniesNaming } from '../../../../components/display/tables/formTables/ExternalCompaniesSelectionTable/constants';

export default function ActionsValidation(): JSX.Element {
  const [rowState, setRowState] = useState('');
  const loaderData = useAsyncValue() as dataTypes;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const revalidator = useRevalidator();

  const handleClick = useCallback(async () => {
    const {
      data: { action },
    } = await client.query({
      query: GET_ACTION_BY_ID,
      variables: { id: rowState },
    });

    const {
      data: { order },
    } = await client.query({
      query: GET_ORDER_BY_ID,
      variables: { id: action.orderIDs[0] },
    });
    Object.values({
      ...formActionNaming,
      ...formActionValidationNaming,
      ...externalCompaniesNaming,
    }).forEach((key) => {
      key !== formActionNaming.NEW_ACTION_DATA &&
        sessionStorage.removeItem(key);
    });

    sessionStorage.setItem(
      formActionNaming.NEW_VALIDATION_ACTION_DATA,
      compressSessionStorage(
        JSON.stringify({
          id: rowState,
          orderId: order.id,
          cotic: order.cotic,
          isActionEdited: 'false',
        })
      )
    );

    dispatch(actionTabsReset());
    navigate(`summary/${rowState}`);
  }, [rowState]);

  const dataSelector = useSelector(getCursor(tableIds.VALIDATION_ACTIONS));

  const onReFetchData = useCallback(async () => {
    const { data } = await client.query({
      query: FETCH_ACTIONS,
      variables: {
        input: {
          take: takeBase,
          cursor: '',
          orderBy: 'desc',
          concessionId: dataSelector.concessionId,
          toDate: dataSelector.toDate,
          fromDate: dataSelector.fromDate,
          indexType: dataSelector.indexType,
          indexExpedition: dataSelector.indexExpedition,
          classification: dataSelector.classification,
          capitolId: dataSelector.capitolId,
          subCapitolId: dataSelector.subCapitolId,
          state: dataSelector.state,
        },
      },
      fetchPolicy: 'network-only',
    });

    const setCursorData = {
      take: takeBase,
      cursor: '',
      orderBy: 'desc',
      concessionId: dataSelector?.concessionId,
      toDate: dataSelector?.toDate,
      fromDate: dataSelector?.fromDate,
      indexType: dataSelector?.indexType,
      indexExpedition: dataSelector?.indexExpedition,
      classification: dataSelector?.classification,
      capitolId: dataSelector?.capitolId,
      subCapitolId: dataSelector?.subCapitolId,
      state: dataSelector?.state,
    };

    dispatch(setCursor(tableIds.VALIDATION_ACTIONS, setCursorData));
    await selectActionOrders(data.actions.actions, true);
    revalidator.revalidate();
  }, [dataSelector]);

  const onRefetchPagination = useCallback(async () => {
    revalidator.revalidate();
  }, []);
  return (
    <Container>
      <ValidationsTable
        titleText="Filtrar actuacions per:"
        setRowState={setRowState}
        data={loaderData}
        tableId={tableIds.VALIDATION_ACTIONS}
        onReFetchData={onReFetchData}
        onReFetchPagination={onRefetchPagination}
      />
      <ContainerButton>
        <MainButton
          text="Veure actuació"
          disabled={rowState.length === 0}
          onClick={handleClick}
        />
      </ContainerButton>
    </Container>
  );
}

import { LoaderFunction, defer } from 'react-router-dom';

// Sentry
import * as Sentry from '@sentry/react';

// GQL
import { selectWorkOrderById } from '../../../../../gql/selectors/orders';
import { client } from '../../../../../gql/client';
import { GET_USER_BY_ID } from '../../../../../gql/queries/users';

const loadWorkOrderSummary = async (idOrder: string) => {
  try {
    const workOrderData = await selectWorkOrderById(idOrder);

    const operatorData = await client.query({
      query: GET_USER_BY_ID,
      variables: {
        input: {
          id: workOrderData.userId,
        },
      },
    });

    return {
      ...workOrderData,
      operator: `${operatorData.data.user.name} ${
        operatorData.data.user.surName.split(' ')[0]
      }`,
    };
  } catch (err) {
    Sentry.captureException(err);
    console.log('> Work order Summary loading error:', err);
    return true;
  }
};

export const LoaderOrderSummaryDuplicates: LoaderFunction = async ({
  params,
}) => {
  if (!params.id) return null;

  const loaderToResolve = loadWorkOrderSummary(params.id);
  return defer({ data: loaderToResolve });
};

import React, { useCallback, useEffect, useMemo, useState } from 'react';

import {
  useReactTable,
  getCoreRowModel,
  flexRender,
  getFilteredRowModel,
  createColumnHelper,
} from '@tanstack/react-table';

// Mocks

// Types
import {
  TableProps,
  dataTable,
  SearchbarFilterTypes,
  animalsColumnNames,
} from './types';

// Styled components
import {
  ContainerTable,
  FiltersContainer,
  AnimalsTableContainer,
  TrStyled,
} from './styles';

import {
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
} from '../../../summaryTables/SummaryTablesStyles';
import { Typography } from '../../../../Typography';

import { animalsMocksData } from './mocks';
import SearchBar from '../../../../../inputs/SearchBar';

export const AnimalsManagementTable = ({ data }: TableProps): JSX.Element => {
  const animals = data?.animals ?? animalsMocksData;
  const [animalsData, setAnimalsData] = useState(animals);

  const [filteredAnimals, setFilteredAnimals] = useState(false);

  const animalsColumnHelper = createColumnHelper<dataTable>();

  useEffect(() => {
    setAnimalsData(animals);
  }, [data]);

  const animalColumns = useMemo(
    () => [
      animalsColumnHelper.accessor((row) => row.animalId, {
        id: animalsColumnNames.animalId.id,
        header: () => animalsColumnNames.animalId.label,
        cell: (info) => info.getValue(),
      }),
      animalsColumnHelper.accessor((row) => row.name, {
        id: animalsColumnNames.name.id,
        header: () => animalsColumnNames.name.label,
      }),
    ],
    [animalsData, data]
  );

  const animalsSearchFilter = useCallback<SearchbarFilterTypes>(
    (value) => {
      activeVehiclesTable.getColumn('animalId')?.setFilterValue(value);
      value === '' && setFilteredAnimals((prev) => !prev);
    },
    [filteredAnimals]
  );

  const activeVehiclesTable = useReactTable({
    data: animalsData,
    columns: animalColumns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    enableRowSelection: true,
    enableMultiRowSelection: false,
  });

  return (
    <ContainerTable>
      <AnimalsTableContainer>
        <Typography size="L" variant="semiBold">
          Animals
        </Typography>
        <FiltersContainer>
          <SearchBar
            variant="form"
            onChangeInput={(e) => animalsSearchFilter(e.target.value)}
          />
        </FiltersContainer>
        <TableContainer>
          <Table>
            <Thead>
              {activeVehiclesTable.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <Th key={header.id}>
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                    </Th>
                  ))}
                </tr>
              ))}
            </Thead>
            <Tbody>
              {activeVehiclesTable.getRowModel().rows.map((row) => (
                <TrStyled
                  key={row.id}
                  checked={row.getIsSelected()}
                  indeterminate={!activeVehiclesTable.getIsSomeRowsSelected()}
                >
                  {row.getVisibleCells().map((cell) => (
                    <Td key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </Td>
                  ))}
                </TrStyled>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </AnimalsTableContainer>
    </ContainerTable>
  );
};

export type dataTable = {
  id: string;
  plate: string;
  model: string;
  category: string;
  isActive: boolean;
  isInternal: boolean;
  isTrailer: boolean;
  concession?: string;
};

export type TableProps = {
  data?: {
    activeVehicles: dataTable[];
    inactiveVehicles: dataTable[];
  };
  defaultActiveVehicles?: dataTable[];
  defaultInactiveVehicles?: dataTable[];
  onAddActiveVehicle: (vehicleId: string) => void;
  onAddInactiveVehicle: (vehicleId: string) => void;
};

export type SearchbarFilterTypes = (value: string) => void;

export const activeVehiclesColumnNames = {
  plate: {
    id: 'plate',
    label: 'Matrícula',
  },
  model: {
    id: 'model',
    label: 'Model',
  },
  category: {
    id: 'category',
    label: 'Categoria',
  },
  concession: {
    id: 'concession',
    label: 'Concessió',
  },
};

export const inactiveVehiclesColumnNames = {
  plate: {
    id: 'plate',
    label: 'Matrícula',
  },
  model: {
    id: 'model',
    label: 'Model',
  },
  category: {
    id: 'category',
    label: 'Categoria',
  },
  concession: {
    id: 'concession',
    label: 'Concessió',
  },
};

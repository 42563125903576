import { boolean, object, string } from 'yup';
import {
  CommunicationSendStatus,
  NewCommunication,
} from '../../../types/communication';
import { pkValidation } from '../../../utils/pkValidation';

export const newCommunicationSchema = object<NewCommunication>({
  operator: string().required(),
  registerInitDate: string().required(),
  groupInter: string().required(),
  media: string().required(),
  sendStatus: string()
    .oneOf([CommunicationSendStatus.SENT, CommunicationSendStatus.RECEIVED])
    .required(),
  concession: string().required(),
  city: string().required(),
  pkInitRoad: string().required(),
  pkEndRoad: string().required(),
  road: string().required(),
  track: string().required(),
  lane: string().required(),
  pkInit: string()
    .matches(/^\d+\s?\+\s?\d{3,4}$/)
    .test(function (value) {
      const regex = new RegExp(/^\d+\s?\+\s?\d{3,4}$/);
      return regex.test(value || '') && pkValidation(value || '', this.parent);
    })
    .required(),
  pkEnd: string()
    .test(function (value) {
      const regex = new RegExp(/^\d+\s?\+\s?\d{3,4}$/);
      return regex.test(value || '') && pkValidation(value || '', this.parent);
    })
    .required(),
  description: string().required('* La descripció és un camp obligatori'),
  isValidate: boolean(),
  capitol: string().when('$index', ([index], schema) =>
    index > 0 ? schema.notRequired() : schema.required()
  ),
  subCapitol: string().when('$index', ([index], schema) =>
    index > 0 ? schema.notRequired() : schema.required()
  ),
  classification: string().when('$index', ([index], schema) =>
    index > 0 ? schema.notRequired() : schema.required()
  ),
  selectionOT: object().when(['$index', '$dataOT'], ([index, dataOT], schema) =>
    index > 0 && dataOT
      ? schema.notRequired()
      : index === 0
      ? schema.notRequired()
      : schema.required('Aquest camp és obligatori')
  ),
  specialTag: string(),
});

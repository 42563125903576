import { MaterialsType } from './types';

export const mockData: MaterialsType[] = [
  {
    codiMat: 'M00001',
    material: 'Aglomerat en fred en pols de 25 kg',
    ut: '03',
    um: 'Kg',
  },
  {
    codiMat: 'M00001',
    material: 'Aglomerat en fred en pols de 25 kg',
    ut: '03',
    um: 'Kg',
  },
  {
    codiMat: 'M00001',
    material: 'Aglomerat en fred en pols de 25 kg',
    ut: '03',
    um: 'Kg',
  },
  {
    codiMat: 'M00001',
    material: 'Aglomerat en fred en pols de 25 kg',
    ut: '03',
    um: 'Kg',
  },
  {
    codiMat: 'M00001',
    material: 'Aglomerat en fred en pols de 25 kg',
    ut: '03',
    um: 'Kg',
  },
  {
    codiMat: 'M00001',
    material: 'Aglomerat en fred en pols de 25 kg',
    ut: '03',
    um: 'Kg',
  },
  {
    codiMat: 'M00001',
    material: 'Aglomerat en fred en pols de 25 kg',
    ut: '03',
    um: 'Kg',
  },
  {
    codiMat: 'M00001',
    material: 'Aglomerat en fred en pols de 25 kg',
    ut: '03',
    um: 'Kg',
  },
];

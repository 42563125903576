export type TableProps = {
  data?: MaterialsType[];
};

export type MaterialsType = {
  codiMat: string;
  material: string;
  ut: number | string;
  um: number | string;
};

type columnIDTypes = { [key: string]: { id: string; label: string } };

export const columnNames: columnIDTypes = {
  codiMat: {
    id: 'codiMat',
    label: 'Codi mat.',
  },
  material: {
    id: 'material',
    label: 'Material',
  },
  ut: {
    id: 'ut',
    label: 'Ut.',
  },
  um: {
    id: 'um',
    label: 'Um.',
  },
};

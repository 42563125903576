// Types
import { OrderType } from '../../../../types/orders';
import { dataTable } from './types';
export const mocksTable: dataTable[] = [
  {
    id: 'i4yh534hj5gv346',
    cotic: ['1O', '0000', '1234567', '00'],
    date: 1641809400000,
    hour: 1641809400000,
    capitol: 'Entorns de carretera',
    subCapitol: 'Senyalització',
    concession: 'LL',
    road: 'C-16',
    description: 'Acidente de ciclomotor en  vía asasd',
    state: 'validated',
    type: OrderType.Generic,
  },
  {
    id: '3jk4g5hj3g43789',
    cotic: ['1O', '0000', '2234567', '00'],
    date: 1642291200000,
    hour: 1642291200000,
    capitol: 'Accident',
    subCapitol: 'Animals',
    concession: 'AR',
    road: 'C-17',
    description: 'Acidente de ciclomotor en  vía asasd',
    state: 'open',
    type: OrderType.Communication,
  },
  {
    id: '6wrf8swwgwgr8',
    cotic: ['1R', '0000', '3234567', '00'],
    date: 1646925000000,
    hour: 1646925000000,
    capitol: 'Altres',
    subCapitol: 'Senyalització',
    concession: 'TE',
    road: 'C-16',
    description: 'Acidente de ciclomotor en  vía asasd',
    state: 'partial',
    type: OrderType.SubOrder,
  },
  {
    id: '7ew6g87w6s5g856s',
    cotic: ['1O', '0000', '5234567', '00'],
    date: 1641816600000,
    hour: 1641816600000,
    capitol: 'Entorns de carretera',
    subCapitol: 'Animals',
    concession: 'LL',
    road: 'C-18',
    description: 'Acidente de ciclomotor en  vía asasd',
    state: 'open',
    type: OrderType.Action,
  },
  {
    id: '9w8g79w8gw89g6',
    cotic: ['1O', '0000', '1234567', '00'],
    date: 1641809400000,
    hour: 1641809400000,
    capitol: 'Altres',
    subCapitol: 'Animals',
    concession: 'AR',
    road: 'C-16',
    description: 'Acidente de ciclomotor en  vía asasd',
    state: 'validated',
    type: OrderType.Accident,
  },
  {
    id: 'e9r78gy9ds7gh',
    cotic: ['1O', '0000', '2234567', '00'],
    date: 1642363800000,
    hour: 1642363800000,
    capitol: 'Entorns de carretera',
    subCapitol: 'Senyalització',
    concession: 'TE',
    road: 'C-17',
    description: 'Acidente de ciclomotor en  vía asasd',
    state: 'partial',
    type: OrderType.SurveillanceRoute,
  },
  {
    id: 'a74fa754af89ayfg',
    cotic: ['1R', '0000', '3234567', '00'],
    date: 1646925000000,
    hour: 1646925000000,
    capitol: 'Accident',
    subCapitol: 'Animals',
    concession: 'LL',
    road: 'C-17',

    description: 'Acidente de ciclomotor en  vía asasd',
    state: 'open',
    type: OrderType.Campaign,
  },
  {
    id: '8s65g8as65fh9sg',
    cotic: ['1O', '0000', '5234567', '00'],
    date: 1641816600000,
    hour: 1641816600000,
    capitol: 'Entorns de carretera',
    subCapitol: 'Animals',
    concession: 'AR',
    road: 'C-18',
    description: 'Acidente de ciclomotor en  vía asasd',
    state: 'partial',
    type: OrderType.Generic,
  },
  {
    id: '9s7fg687s5gh',
    cotic: ['1O', '0000', '1234567', '00'],
    date: 1641816610000,
    hour: 1641816610000,
    capitol: 'Accident',
    subCapitol: 'Senyalització',
    concession: 'TE',
    road: 'C-16',
    description: 'Acidente de ciclomotor en  vía asasd',
    state: 'validated',
    type: OrderType.Communication,
  },
  {
    id: '68sd5gv78sad5g7s6g',
    cotic: ['1O', '0000', '2234567', '00'],
    date: 1642363810000,
    hour: 1642363810000,
    capitol: 'Accident',
    subCapitol: 'Animals',
    concession: 'AR',
    road: 'C-16',
    description: 'Acidente de ciclomotor en  vía asasd',
    state: 'validated',
    type: OrderType.SubOrder,
  },
  {
    id: 'sfg457s5g65sg4',
    cotic: ['1O', '0000', '1234567', '00'],
    date: 1641816610000,
    hour: 1641816610000,
    capitol: 'Entorns de carretera',
    subCapitol: 'Senyalització',
    concession: 'TE',
    road: 'C-18',
    description: 'Acidente de ciclomotor en  vía asasd',
    state: 'open',
    type: OrderType.Action,
  },
  {
    id: 's97dg6s87d5g8',
    cotic: ['1O', '0000', '2234567', '00'],
    date: 1642363810000,
    hour: 1642363810000,
    capitol: 'Altres',
    subCapitol: 'Animals',
    concession: 'AR',
    road: 'C-18',
    description: 'Acidente de ciclomotor en  vía asasd',
    state: 'partial',
    type: OrderType.Accident,
  },
  {
    id: 's9f78ds7fhdh58',
    cotic: ['1R', '0000', '3234567', '00'],
    date: 1646925000000,
    hour: 1646925000000,
    capitol: 'Altres',
    subCapitol: 'Senyalització',
    concession: 'LL',
    road: 'C-16',
    description: 'Acidente de ciclomotor en  vía asasd',
    state: 'partial',
    type: OrderType.Accident,
  },
  {
    id: '9sa7d56g78sa5dg',
    cotic: ['1O', '0000', '5234567', '00'],
    date: 1641816600000,
    hour: 1641816600000,
    capitol: 'Altres',
    subCapitol: 'Animals',
    concession: 'AR',
    road: 'C-17',
    description: 'Acidente de ciclomotor en  vía asasd',
    state: 'partial',
    type: OrderType.SurveillanceRoute,
  },
  {
    id: '9s8g6879asdg5',
    cotic: ['1O', '0000', '1234567', '00'],
    date: 1641816610000,
    hour: 1641816610000,
    capitol: 'Altres',
    subCapitol: 'Senyalització',
    concession: 'TE',
    road: 'C-16',
    description: 'Acidente de ciclomotor en  vía asasd',
    state: 'open',
    type: OrderType.Campaign,
  },
  {
    id: '9s7a5g78f5h7868dfh',
    cotic: ['1O', '0000', '1234567', '00'],
    date: 1641816610000,
    hour: 1641816610000,
    capitol: 'Altres',
    subCapitol: 'Animals',
    concession: 'TE',
    road: 'C-17',
    description: 'Acidente de ciclomotor en  vía asasd',
    state: 'partial',
    type: OrderType.Campaign,
  },
  {
    id: '8s75g76sd4g67',
    cotic: ['1O', '0000', '2234567', '00'],
    date: 1642363810000,
    hour: 1642363810000,
    capitol: 'Altres',
    subCapitol: 'Senyalització',
    concession: 'AR',
    road: 'C-18',
    description: 'Acidente de ciclomotor en  vía asasd',
    state: 'annulled',
    type: OrderType.Action,
  },
  {
    id: '864583gik456g3k',
    cotic: ['1O', '0000', '1234567', '00'],
    date: 1641816610000,
    hour: 1641816610000,
    capitol: 'Accident',
    subCapitol: 'Animals',
    concession: 'TE',
    road: 'C-18',
    description: 'Acidente de ciclomotor en  vía asasd',
    state: 'partial',
    type: OrderType.Generic,
  },
  {
    id: '89374i6jk3g6khgjkhg',
    cotic: ['1O', '0000', '2234567', '00'],
    date: 1642363810000,
    hour: 1642363810000,
    capitol: 'Accident',
    subCapitol: 'Senyalització',
    concession: 'AR',
    road: 'C-17',
    description: 'Acidente de ciclomotor en  vía asasd',
    state: 'partial',
    type: OrderType.Generic,
  },
  {
    id: '937645h3kjg6khmbg',
    cotic: ['1R', '0000', '3234567', '00'],
    date: 1646925000000,
    hour: 1646925000000,
    capitol: 'Accident',
    subCapitol: 'Animals',
    concession: 'LL',
    road: 'C-17',
    description: 'Acidente de ciclomotor en  vía asasd',
    state: 'validated',
    type: OrderType.Action,
  },
  {
    id: '89dw57g8ssdgfsg',
    cotic: ['1O', '0000', '5234567', '00'],
    date: 1641816600000,
    hour: 1641816600000,
    capitol: 'Accident',
    subCapitol: 'Senyalització',
    concession: 'AR',
    road: 'C-16',
    description: 'Acidente de ciclomotor en  vía asasd',
    state: 'validated',
    type: OrderType.SurveillanceRoute,
  },
  {
    id: '8364856jhv3gjnvhgf',
    cotic: ['1O', '0000', '1234567', '00'],
    date: 1641816610000,
    hour: 1641816610000,
    capitol: 'Accident',
    subCapitol: 'Animals',
    concession: 'TE',
    road: 'C-17',
    description: 'Acidente de ciclomotor en  vía asasd',
    state: 'validated',
    type: OrderType.SubOrder,
  },
];

import React from 'react';

// Types
import { ButtonProps } from './types';

// Components Styled
import { Container, Text } from './styles';

/**
 * Primary UI component for user interaction
 */
export const MainButton = ({
  variant = 'primary',
  text,
  disabled,
  ...props
}: ButtonProps): JSX.Element => {
  return (
    <Container variant={variant} disabled={disabled} {...props}>
      <Text
        colorText={
          disabled
            ? variant === 'primary'
              ? 'veryClearPink'
              : 'lightDustyRose'
            : variant === 'primary'
            ? 'white'
            : 'garnet'
        }
        size="S"
      >
        {text}
      </Text>
    </Container>
  );
};

export const LOADING_DATA = 'LOADING_DATA';

export function loadingData(id: string, isLoadingData: boolean) {
  return {
    type: LOADING_DATA,
    payload: {
      id,
      isLoadingData,
    },
  };
}

import { gql } from '@apollo/client';

export const GET_ATTENTION_ACCIDENT_BY_ID = gql`
  query GetAttentionAccidentById($id: ID!) {
    attentionAccident(id: $id) {
      id
      noticeConservationCenterDate
      arrivalConservationCenterDate
      departureConservationCenterDate
      noticeSquadBoysDate
      arrivalSquadBoysDate
      departureSquadBoysDate
      noticeRoadTeamDate
      arrivalRoadTeamDate
      departureRoadTeamDate
      noticeControlCenterDate
      arrivalControlCenterDate
      departureControlCenterDate
      noticeAmbulanceCraneAssistanceDate
      arrivalAmbulanceCraneAssistanceDate
      departureAmbulanceCraneAssistanceDate
      otherName
      noticeOtherDate
      arrivalOtherDate
      departureOtherDate
      accidentIDs
    }
  }
`;

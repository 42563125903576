import { gql } from '@apollo/client';

export const FETCH_AUX_MACHINES = gql`
  query FetchAuxMachines {
    auxMachines {
      id
      auxMachineId
      description
      type
      typeOfMeasurement
      auxMachineTypeIDs
      measureTypeIDs
    }
  }
`;

export const GET_AUX_MACHINES = gql`
  query GetAuxMachines {
    readAuxMachines @client {
      id
      auxMachineId
      description
      type
      typeOfMeasurement
      auxMachineTypeIDs
      measureTypeIDs
    }
  }
`;

export const GET_AUX_MACHINE_BY_ID = gql`
  query GetAuxMachineById($id: ID!) {
    auxMachine(id: $id) {
      id
      auxMachineId
      description
      type
      typeOfMeasurement
      auxMachineTypeIDs
      measureTypeIDs
    }
  }
`;

export const GET_AUX_MACHINE_UNIT_BY_ID = gql`
  query GetAuxMachineUnitById($id: ID!) {
    auxMachineUnit(id: $id) {
      id
      unit
      auxMachineIDs
    }
  }
`;

export const FETCH_AUX_MACHINE_TYPES = gql`
  query FetchAuxMachineTypes {
    auxMachineTypes {
      id
      name
    }
  }
`;

export const FETCH_AUX_MACHINE_TYPE_BY_ID = gql`
  query FetchAuxMachineTypeById($id: ID!) {
    auxMachineType(id: $id) {
      id
      name
    }
  }
`;

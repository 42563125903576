import styled, { css } from 'styled-components';

// Colors
import { colors } from '../../../../../../styles/colors';

// Spaces
import {
  breakPoints,
  percentBaseResponsive,
  spaces,
} from '../../../../../../styles/spaces';

// Styled Components
import { Container, Tr } from '../../../summaryTables/SummaryTablesStyles';

// Components
import { InputRadio } from '../../../../../inputs/InputRadio';

// Types

export const ContainerTable = styled(Container)`
  display: flex;
  gap: 9.8rem;
  @media (max-width: ${breakPoints.smallDesktop * percentBaseResponsive}rem) {
    flex-wrap: wrap;
  }
  @media (max-width: ${breakPoints.landscapeTablet *
    percentBaseResponsive}rem) {
    flex-direction: column;
    gap: 3.2rem;
  }
`;

export const ActiveVehiclesTableContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.8rem;
  flex: 1;
  width: 57.2rem;
  max-width: 90.1rem;

  @media (max-width: ${breakPoints.landscapeTablet *
    percentBaseResponsive}rem) {
    width: 100%;
  }
`;

export const InactiveVehiclesTableContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.8rem;
  flex: 1;
  width: 57.2rem;
  max-width: 90.1rem;

  @media (max-width: ${breakPoints.landscapeTablet *
    percentBaseResponsive}rem) {
    width: 100%;
  }
`;

export const FiltersContainer = styled.div`
  display: flex;
  align-items: end;
  gap: ${spaces.m}rem;
  div:last-child {
    flex: 1;
  }
`;

export const TrStyled = styled(Tr)`
  background-color: ${({ checked }) => checked && colors.paleGrey};
  ${({ indeterminate, checked }) =>
    css`
      Td {
        ${!indeterminate && {
          opacity: 0.2,
        }}
        ${checked && {
          opacity: 1,
        }}
      }
    `}
`;

export const InputRadioStyled = styled(InputRadio)``;

export const SwapVehicleButton = styled.button`
  font-size: 2rem;
  color: ${colors.white};
  min-height: 4rem;
  min-width: 4rem;
  width: 4rem;
  border: none;
  border-radius: 5px;
  background-color: ${colors.greyVeryDark};
`;

export const SwapVehicleButtonLeft = styled(SwapVehicleButton)`
  align-self: end;
  @media (max-width: ${breakPoints.smallDesktop * percentBaseResponsive}rem) {
    align-self: flex-start;
  }
`;

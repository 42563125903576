// Dependencies
import React from 'react';
import { useSubmit } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

// Styles
import {
  ArrowBackLink,
  ContainerForm,
  ContainerLogoArrow,
  ContainerOra,
  Infotext,
  LinkPolicy,
  LogoCedinsa,
  OraLogo,
  PasswordButton,
  Title,
} from './styles';

import CedinsaLogo from '../../../assets/logo-cedinsa.svg';
import LeftArrow from '../../../assets/icons/arrow-left.svg';
import LogoOra from '../../../assets/logo.svg';

// Components
import { Typography } from '../../display/Typography';
import InputText from '../../inputs/InputText';

// Media

// Types
import { ResetPasswordFormType } from './types';

// Schema
import { ResetPasswordFormSchema } from './schema';

// Utils
import { createFormDataFromValues } from '../../../utils/forms';

// Media
import { useIsLandscapeTablet } from '../../../hooks/useMediaBreakPoints';

export default function ResetPasswordForm() {
  const isLandscapeTablet = useIsLandscapeTablet();

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<ResetPasswordFormType>({
    mode: 'onTouched',
    reValidateMode: 'onBlur',
    defaultValues: {
      newPassword: '',
      repeatPassword: '',
    },
    resolver: yupResolver(ResetPasswordFormSchema),
  });

  const submit = useSubmit();

  const onFormSubmit = (values: ResetPasswordFormType) => {
    const formData = createFormDataFromValues(values);

    submit(formData, {
      method: 'post',
      action: '/reset-password',
    });
  };

  return (
    <>
      <ContainerLogoArrow>
        <LogoCedinsa src={CedinsaLogo} alt="Logo Cedinsa" />
        <ArrowBackLink to={'/login'}>
          <img src={LeftArrow} alt="Icona tornar enrere" />
        </ArrowBackLink>
      </ContainerLogoArrow>
      <ContainerForm onSubmit={handleSubmit(onFormSubmit)}>
        <Title size="XL" variant="semiBold">
          Cambia contrasenya
        </Title>
        <InputText
          {...register('newPassword')}
          label="Nova Contrasenya"
          labelSize={isLandscapeTablet ? 'XS' : 'S'}
          typeInput="password"
          type="password"
          placeholder="Contrasenya"
          error={errors.newPassword?.message}
        />
        <Infotext>
          Perquè la contrasenya sigui segura deu almenys incloure 12 caràcters
          de longitud combinar lletres majúscules, minúscules, números i
          símbols.
        </Infotext>
        <InputText
          {...register('repeatPassword')}
          label="Repeteix la contrasenya"
          labelSize={isLandscapeTablet ? 'XS' : 'S'}
          typeInput="password"
          type="password"
          placeholder="Contrasenya"
          error={errors.repeatPassword?.message}
        />
        <PasswordButton text="Desar i iniciar sessió" disabled={false} />
        <LinkPolicy to={'#'}>
          <Typography size={isLandscapeTablet ? 'XXXS' : 'XS'}>
            En ingressar estás acceptant les nostres{' '}
            <b>Politiques de Privadesa</b>
          </Typography>
        </LinkPolicy>
      </ContainerForm>
      <ContainerOra>
        <OraLogo src={LogoOra} alt="Logo Ora" />
      </ContainerOra>
    </>
  );
}

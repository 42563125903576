import React, { SyntheticEvent, useCallback, useLayoutEffect } from 'react';
import {
  useNavigate,
  useActionData,
  useAsyncValue,
  useSubmit,
} from 'react-router-dom';
import { format } from 'date-fns';

//Components
import ContentCardResume from '../../../../../components/display/ContentCardResume';

// Types
import { type WorkOrder } from '../../../../../types/workOrder';
import { createFormDataFromValues } from '../../../../../utils/forms';

export default function WorkOrderDuplicateSummary(): JSX.Element {
  const loaderData = useAsyncValue() as WorkOrder;
  const actionData = useActionData() as WorkOrder;
  const navigate = useNavigate();
  const submit = useSubmit();

  const workOrderData = actionData ?? loaderData;
  useLayoutEffect(() => {
    if (!workOrderData) navigate('..');
  }, [workOrderData]);

  const handleEditCommunication = useCallback(() => {
    navigate(
      `../new-order-duplicate${
        workOrderData?.id ? '/' + workOrderData?.id : ''
      }`
    );
  }, [workOrderData?.id]);

  const handleSaveCommunication = async (e: SyntheticEvent) => {
    e.preventDefault();
    const formDataRaw: {
      intent: string;
      id?: string;
    } = {
      intent: 'save-data',
    };
    if (workOrderData?.id) {
      formDataRaw.id = workOrderData?.id;
    }
    const formData = createFormDataFromValues(formDataRaw);
    submit(formData, {
      method: 'POST',
      action: '.',
    });
  };

  return (
    <>
      {workOrderData && (
        <ContentCardResume
          title="Resum de la Ordre de Treball"
          cotic={workOrderData.cotic as unknown as string[]}
          operator={workOrderData.operator}
          data={format(
            new Date(workOrderData.registerInitDate),
            'dd / MM / yyyy'
          )}
          hour={format(new Date(workOrderData.registerInitDate), 'HH:mm')}
          capitol={workOrderData.capitol}
          subCapitol={workOrderData.subCapitol}
          classification={workOrderData.classification}
          team={workOrderData.team}
          concession={workOrderData.concession}
          road={workOrderData.road}
          pkInit={`${workOrderData.pkInit} km`}
          pkEnd={`${workOrderData.pkEnd} km`}
          track={workOrderData.track}
          roadDirections={workOrderData.roadDirections}
          direction={workOrderData.direction}
          city={workOrderData.city}
          lane={workOrderData.lane}
          margin={workOrderData.margin}
          leftButton={'Editar O.T'}
          rightButton={'Gravar Duplicat'}
          description={workOrderData.description}
          onEditCommunication={handleEditCommunication}
          onSaveCommunication={handleSaveCommunication}
        />
      )}
    </>
  );
}

import React from 'react';

type DirectionUpProps = {
  className?: string;
};

export const DirectionUp = ({ className }: DirectionUpProps): JSX.Element => {
  return (
    <svg
      width="17"
      height="22"
      viewBox="0 0 17 22"
      xmlns="http://www.w3.org/2000/svg"
      fill="black"
      className={className}
    >
      <path d="M9.50572 21.25L9.75572 21.25L9.75572 21L9.75572 5.07564L14.0647 9.38464C14.5595 9.87942 15.3617 9.87942 15.8565 9.38464C16.3512 8.88987 16.3512 8.08768 15.8565 7.5929L9.38464 1.12108C8.88987 0.626306 8.08768 0.626306 7.59291 1.12108L1.12108 7.59291C0.626306 8.08768 0.626306 8.88987 1.12108 9.38464C1.61586 9.87942 2.41804 9.87942 2.91282 9.38464L7.22183 5.07564L7.22183 21L7.22183 21.25L7.47183 21.25L9.50572 21.25Z" />
    </svg>
  );
};

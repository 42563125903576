import React, { useCallback, useEffect, useMemo, useState } from 'react';

import {
  useReactTable,
  getCoreRowModel,
  flexRender,
  getFilteredRowModel,
  createColumnHelper,
  Row,
} from '@tanstack/react-table';

// Mocks

// Types
import {
  TableProps,
  activeUsersColumnNames,
  inactiveUsersColumnNames,
  dataTable,
  SearchbarFilterTypes,
} from './types';

// Styled components
import {
  ContainerTable,
  FiltersContainer,
  InactiveUsersTableContainer,
  ActiveUsersTableContainer,
  SwapUserButton,
  SwapUserButtonLeft,
  InputRadioStyled,
  TrStyled,
} from './styles';

import {
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
} from '../../../summaryTables/SummaryTablesStyles';
import { Typography } from '../../../../Typography';

import { activeUsersMocksData, inactiveUsersMocksData } from './mocks';
import SearchBar from '../../../../../inputs/SearchBar';

export const UsersManagementTable = ({
  data,
  onAddInactiveUser,
  onAddActiveUser,
}: TableProps): JSX.Element => {
  const activeUsersDataAll = data?.activeUsers ?? activeUsersMocksData;
  const inactiveUsersDataAll = data?.inactiveUsers ?? inactiveUsersMocksData;

  const [activeUsersData, setActiveUsersData] = useState(activeUsersDataAll);
  const [inactiveUsersData, setInactiveUsersData] =
    useState(inactiveUsersDataAll);

  const [activeUsersChecked, setActiveUsersChecked] = useState({});
  const [inactiveUsersChecked, setInactiveUsersChecked] = useState({});

  const [filteredActiveUsers, setFilteredActiveUsers] = useState(false);
  const [filteredinactiveUsers, setFilteredinactiveUsers] = useState(false);

  const activeUsersColumnHelper = createColumnHelper<dataTable>();
  const inactiveUsersColumnHelper = createColumnHelper<dataTable>();

  useEffect(() => {
    setActiveUsersData(activeUsersDataAll);
    setInactiveUsersData(inactiveUsersDataAll);
  }, [data]);

  const activeUsersColumns = useMemo(
    () => [
      {
        enableHiding: true,
        id: 'select',
        cell: ({ row }) => (
          <InputRadioStyled
            name="activeRadioInput"
            {...{
              checked: row.getIsSelected(), // Returns whether or not the row is selected.
              disabled: !row.getCanSelect(), // Returns whether or not the row can be selected.
              indeterminate: row.getIsSomeSelected(), //Returns whether or not some of the row's sub rows are selected.
              onChange: row.getToggleSelectedHandler(), //Returns a handler that can be used to toggle the row.
            }}
          />
        ),
      },
      activeUsersColumnHelper.accessor((row) => row.name, {
        id: activeUsersColumnNames.name.id,
        header: () => activeUsersColumnNames.name.label,
        cell: (info) => info.getValue(),
      }),
      activeUsersColumnHelper.accessor((row) => row.surName, {
        id: activeUsersColumnNames.surName.id,
        header: () => activeUsersColumnNames.surName.label,
      }),
      activeUsersColumnHelper.accessor((row) => row.email, {
        id: activeUsersColumnNames.email.id,
        header: () => activeUsersColumnNames.email.label,
      }),
      activeUsersColumnHelper.accessor((row) => row.rolActive, {
        id: activeUsersColumnNames.rolActive.id,
        header: () => activeUsersColumnNames.rolActive.label,
      }),
      activeUsersColumnHelper.accessor((row) => row.concession, {
        id: activeUsersColumnNames.concession.id,
        header: () => activeUsersColumnNames.concession.label,
      }),
    ],
    [
      activeUsersData,
      inactiveUsersData,
      activeUsersChecked,
      inactiveUsersChecked,
      data,
    ]
  );

  const inactiveUsersColumns = useMemo(
    () => [
      {
        enableHiding: true,
        id: 'select',
        cell: ({ row }) => (
          <InputRadioStyled
            name="inactiveRadioInput"
            {...{
              checked: row.getIsSelected(), // Returns whether or not the row is selected.
              disabled: !row.getCanSelect(), // Returns whether or not the row can be selected.
              indeterminate: row.getIsSomeSelected(), //Returns whether or not some of the row's sub rows are selected.
              onChange: row.getToggleSelectedHandler(), //Returns a handler that can be used to toggle the row.
            }}
          />
        ),
      },
      inactiveUsersColumnHelper.accessor((row) => row.name, {
        id: inactiveUsersColumnNames.name.id,
        header: () => inactiveUsersColumnNames.name.label,
        cell: (info) => info.getValue(),
      }),
      inactiveUsersColumnHelper.accessor((row) => row.surName, {
        id: inactiveUsersColumnNames.surName.id,
        header: () => inactiveUsersColumnNames.surName.label,
      }),
      inactiveUsersColumnHelper.accessor((row) => row.email, {
        id: inactiveUsersColumnNames.email.id,
        header: () => inactiveUsersColumnNames.email.label,
      }),
      inactiveUsersColumnHelper.accessor((row) => row.rolActive, {
        id: inactiveUsersColumnNames.rolActive.id,
        header: () => inactiveUsersColumnNames.rolActive.label,
      }),
      inactiveUsersColumnHelper.accessor((row) => row.concession, {
        id: inactiveUsersColumnNames.concession.id,
        header: () => inactiveUsersColumnNames.concession.label,
      }),
    ],
    [
      activeUsersData,
      inactiveUsersData,
      activeUsersChecked,
      inactiveUsersChecked,
      data,
    ]
  );

  const activeUsersSearchFilter = useCallback<SearchbarFilterTypes>(
    (value) => {
      activeUsersTable.getColumn('firstName')?.setFilterValue(value);
      value === '' && setFilteredActiveUsers((prev) => !prev);
    },
    [filteredActiveUsers]
  );

  const inactiveUsersSearchFilter = useCallback<SearchbarFilterTypes>(
    (value) => {
      inactiveUsersTable.getColumn('firstName')?.setFilterValue(value);
      value === '' && setFilteredinactiveUsers((prev) => !prev);
    },
    [filteredinactiveUsers]
  );

  const handleRowClick = (row: Row<dataTable>) => {
    row.getToggleSelectedHandler()(row);
  };

  const addActiveUser = () => {
    const selectedRow = activeUsersTable.getSelectedRowModel().rows[0].original;

    onAddActiveUser(selectedRow.email);

    activeUsersTable.resetRowSelection(true);
  };

  const addInactiveUser = () => {
    const selectedRow =
      inactiveUsersTable.getSelectedRowModel().rows[0].original;

    onAddInactiveUser(selectedRow.email);

    inactiveUsersTable.resetRowSelection(true);
  };

  const activeUsersTable = useReactTable({
    data: activeUsersData,
    columns: activeUsersColumns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onRowSelectionChange: setActiveUsersChecked,
    state: {
      rowSelection: activeUsersChecked,
    },
    enableRowSelection: true,
    enableMultiRowSelection: false,
  });

  const inactiveUsersTable = useReactTable({
    data: inactiveUsersData,
    columns: inactiveUsersColumns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onRowSelectionChange: setInactiveUsersChecked,
    state: {
      rowSelection: inactiveUsersChecked,
    },
    enableRowSelection: true,
    enableMultiRowSelection: false,
  });

  return (
    <ContainerTable>
      <ActiveUsersTableContainer>
        <Typography size="L" variant="semiBold">
          Usuaris actius
        </Typography>
        <FiltersContainer>
          <SearchBar
            variant="form"
            onChangeInput={(e) => activeUsersSearchFilter(e.target.value)}
          />
        </FiltersContainer>
        <TableContainer>
          <Table>
            <Thead>
              {activeUsersTable.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <Th key={header.id}>
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                    </Th>
                  ))}
                </tr>
              ))}
            </Thead>
            <Tbody>
              {activeUsersTable.getRowModel().rows.map((row) => (
                <TrStyled
                  key={row.id}
                  checked={row.getIsSelected()}
                  indeterminate={!activeUsersTable.getIsSomeRowsSelected()}
                  onClick={() => handleRowClick(row)}
                >
                  {row.getVisibleCells().map((cell) => (
                    <Td key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </Td>
                  ))}
                </TrStyled>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
        <SwapUserButtonLeft onClick={addActiveUser}>⮕</SwapUserButtonLeft>
      </ActiveUsersTableContainer>
      <InactiveUsersTableContainer>
        <Typography size="L" variant="semiBold">
          Usuaris inactius
        </Typography>
        <FiltersContainer>
          <SearchBar
            variant="form"
            onChangeInput={(e) => inactiveUsersSearchFilter(e.target.value)}
          />
        </FiltersContainer>
        <TableContainer>
          <Table>
            <Thead>
              {inactiveUsersTable.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <Th key={header.id}>
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                    </Th>
                  ))}
                </tr>
              ))}
            </Thead>
            <Tbody>
              {inactiveUsersTable.getRowModel().rows.map((row) => (
                <TrStyled
                  key={row.id}
                  checked={row.getIsSelected()}
                  indeterminate={!inactiveUsersTable.getIsSomeRowsSelected()}
                  onClick={() => handleRowClick(row)}
                >
                  {row.getVisibleCells().map((cell) => (
                    <Td key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </Td>
                  ))}
                </TrStyled>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
        <SwapUserButton onClick={addInactiveUser}>⬅</SwapUserButton>
      </InactiveUsersTableContainer>
    </ContainerTable>
  );
};

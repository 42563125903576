import { defer, LoaderFunction } from 'react-router-dom';
import { store } from '../../../../state/configureStore';

// GQL
import { client } from '../../../../gql/client';
import { FETCH_ACTIONS } from '../../../../gql/queries/actions/actions';
import { selectActionOrders } from '../../../../gql/selectors/actions';
import {
  FETCH_CAPITOLS,
  FETCH_SUB_CAPITOLS,
} from '../../../../gql/queries/orders/predefinedData';
import { GET_CONCESSIONS } from '../../../../gql/queries/orders/orders';

// Types
import { AuthContextType } from '../../../../hooks/useAuth';
import {
  tableIds,
  takeBase,
} from '../../../../components/display/tables/types';

// Redux
import { getCursor } from '../../../../state/selectors/ui/tables';
import { loadingData } from '../../../../state/actions/ui/loadingData';
import { setCursor } from '../../../../state/actions/ui/cursorTables';

export const ActionsValidationLoader: (
  userData: AuthContextType | null
) => LoaderFunction = (userData) => async () => {
  const cursorActions = getCursor(tableIds.VALIDATION_ACTIONS)(
    store.getState()
  );
  const dispatch = store.dispatch;

  if (userData?.user) {
    const readActions = client.readQuery({
      query: FETCH_ACTIONS,
      variables: {
        input: {
          take: cursorActions.take,
          cursor: cursorActions.cursor,
          concessionId: cursorActions.concessionId,
          capitolId: cursorActions.capitolId,
          subCapitolId: cursorActions.subCapitolId,
          orderBy: cursorActions.orderBy,
          toDate: cursorActions.toDate,
          fromDate: cursorActions.fromDate,
          indexType: cursorActions.indexType,
          indexExpedition: cursorActions.indexExpedition,
          classification: cursorActions.classification,
          state: cursorActions.state,
        },
      },
    });

    const totalCount = readActions?.actions?.totalCount;
    const pageInfo = readActions?.actions?.pageInfo;

    const isCacheEmpty = JSON.stringify(readActions) === '{}';

    const actions =
      readActions &&
      !isCacheEmpty &&
      (await selectActionOrders(readActions?.actions?.actions));

    const [
      {
        data: { capitols },
      },
      {
        data: { concessions },
      },
      {
        data: { subCapitols },
      },
    ] = await Promise.all([
      client.query({
        query: FETCH_CAPITOLS,
      }),
      client.query({
        query: GET_CONCESSIONS,
      }),
      client.query({
        query: FETCH_SUB_CAPITOLS,
      }),
    ]);

    const filtersDataOptions = {
      capitols,
      concessions,
      subCapitols,
    };

    const combinedData = {
      actions,
      filtersDataOptions,
      totalCount,
      pageInfo,
    };

    if (!readActions || isCacheEmpty) {
      dispatch(loadingData(tableIds.VALIDATION_ACTIONS, true));
      let data;
      if (isCacheEmpty) {
        data = await client.query({
          query: FETCH_ACTIONS,
          variables: {
            input: {
              take: takeBase,
              cursor: '',
              concessionId: cursorActions.concessionId,
              capitolId: cursorActions.capitolId,
              subCapitolId: cursorActions.subCapitolId,
              orderBy: 'desc',
              toDate: cursorActions.toDate,
              fromDate: cursorActions.fromDate,
              indexType: cursorActions.indexType,
              indexExpedition: cursorActions.indexExpedition,
              classification: cursorActions.classification,
              state: cursorActions.state,
            },
          },
        });

        const setCursorData = {
          take: takeBase,
          cursor: '',
          concessionId: cursorActions.concessionId,
          capitolId: cursorActions.capitolId,
          subCapitolId: cursorActions.subCapitolId,
          orderBy: 'desc',
          toDate: cursorActions.toDate,
          fromDate: cursorActions.fromDate,
          indexType: cursorActions.indexType,
          indexExpedition: cursorActions.indexExpedition,
          classification: cursorActions.classification,
          state: cursorActions.state,
        };

        dispatch(setCursor(tableIds.VALIDATION_ACTIONS, setCursorData));
      } else {
        data = await client.query({
          query: FETCH_ACTIONS,
          variables: {
            input: {
              take: cursorActions.take,
              cursor: cursorActions.cursor,
              concessionId: cursorActions.concessionId,
              capitolId: cursorActions.capitolId,
              subCapitolId: cursorActions.subCapitolId,
              orderBy: cursorActions.orderBy,
              toDate: cursorActions.toDate,
              fromDate: cursorActions.fromDate,
              indexType: cursorActions.indexType,
              indexExpedition: cursorActions.indexExpedition,
              classification: cursorActions.classification,
              state: cursorActions.state,
            },
          },
        });
      }

      const loading = data.loading;
      const actions = await selectActionOrders(data.data.actions.actions);

      const totalCount = data.data.actions.totalCount;

      const pageInfo = data.data.actions.pageInfo;

      const [
        {
          data: { capitols },
        },
        {
          data: { concessions },
        },
        {
          data: { subCapitols },
        },
      ] = await Promise.all([
        client.query({
          query: FETCH_CAPITOLS,
        }),
        client.query({
          query: GET_CONCESSIONS,
        }),
        client.query({
          query: FETCH_SUB_CAPITOLS,
        }),
      ]);

      const filtersDataOptions = {
        capitols,
        concessions,
        subCapitols,
      };

      const combinedData = {
        actions,
        filtersDataOptions,
        totalCount,
        pageInfo,
        loading,
      };

      dispatch(loadingData(tableIds.VALIDATION_ACTIONS, false));

      return defer({ data: combinedData });
    }
    return defer({ data: combinedData });
  }

  return defer({});
};

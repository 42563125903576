// Dependencies
import React, { useCallback } from 'react';
import isEqual from 'lodash.isequal';
import { useDispatch, useSelector } from 'react-redux';

// Types
import { ModalStateType, modalIds } from './types';

// Selectors
import { getCurrentModal } from '../../../state/selectors/ui/modal';

// Actions
import { closeModal } from '../../../state/actions/ui/modal';

// Container
import BaseModal from '../containers/BaseModal';

// Components
import { DownloadHistoricModal } from '../submodals/DownloadHistoricModal';
import { RelatesCommunicationModal } from '../submodals/RelatesCommunicationModal';
import { ChangeCampaignToOT } from '../submodals/ChangeCampaignToOT';
import { deselectedRowModalTable } from '../../../state/actions/ui/selectRowTableModal';
import { AddMediaModal } from '../submodals/AddMediaModal';
import { OpenCameraModal } from '../submodals/OpenCameraModal';

// Types
import { ModalProps as ModalPropsDownloadHistorical } from '../submodals/DownloadHistoricModal/types';
import { Img } from '../../forms/AddBasicInfoAccidentForm/types';
import { AddSketchModal } from '../submodals/AddSketchModal';
import { CustomFile } from '../../forms/ActionForms/ActionEdition/types';
import { ViewFullscreenImage } from '../submodals/ViewFulscreenImage';
import { ChangeStateModal } from '../submodals/ChangeStateModal';
import { TransferSubOrderModal } from '../submodals/TransferSubOrderModal';
import { ConfirmTransferModal } from '../submodals/ConfirmTransferModal';

export default function SuperModal() {
  // Dispatch
  const dispatch = useDispatch();

  // Selectors
  const currentModal: ModalStateType = useSelector(getCurrentModal, isEqual);

  /**
   * @function handleClickClose():
   * @description Close the modal on click close.
   */
  const handleClickClose = useCallback(() => {
    dispatch(closeModal());
    dispatch(
      deselectedRowModalTable(
        currentModal?.data?.namingPersistForm as unknown as string
      )
    );
  }, [dispatch, currentModal]);

  const renderModal = useCallback(() => {
    switch (currentModal.id) {
      case modalIds.DOWNLOAD_HISTORIC:
        return (
          <DownloadHistoricModal
            onClickClose={handleClickClose}
            data={
              currentModal.data as unknown as ModalPropsDownloadHistorical['data']
            }
          />
        );
      case modalIds.RELATES_COMMUNICATION_MODAL:
        return (
          <RelatesCommunicationModal
            onClickClose={handleClickClose}
            namingPersistForm={
              currentModal?.data?.namingPersistForm as unknown as string
            }
          />
        );
      case modalIds.CHANGE_CAMPAIGN_TO_OT_MODAL:
        return (
          <ChangeCampaignToOT
            onClickClose={handleClickClose}
            continueClick={() => {
              const handleClickContinue = currentModal?.data
                ?.handleClickContinue as unknown as () => void;

              handleClickContinue();
              handleClickClose();
            }}
          />
        );

      case modalIds.CHANGE_STATE_MODAL:
        return (
          <ChangeStateModal
            indexExpedition={
              currentModal?.data?.indexExpedition as unknown as string
            }
            title={currentModal?.data?.title as unknown as string}
            titleSaveModal={
              currentModal?.data?.titleSaveModal as unknown as string
            }
            onClickClose={handleClickClose}
            continueClick={() => {
              const handleClickContinue = currentModal?.data
                ?.handleClickContinue as unknown as () => void;

              handleClickContinue();
              handleClickClose();
            }}
            revalidatorFunction={
              currentModal?.data?.revalidatorFunction as unknown as () => void
            }
            orderType={currentModal?.data?.orderType as unknown as string}
            currentState={currentModal?.data?.currentState as unknown as string}
            orderId={currentModal?.data?.orderId as unknown as string}
          />
        );
      case modalIds.ADD_MULTIMEDIA_MODAL:
        return (
          <AddMediaModal
            onClickClose={handleClickClose}
            choosePic={() => {
              const handleChoosePick = currentModal?.data
                ?.handleChoosePick as unknown as () => void;

              handleChoosePick();
            }}
            openCamera={() => {
              const handleOpenCamera = currentModal?.data
                ?.handleOpenCamera as unknown as () => void;

              handleClickClose();
              handleOpenCamera();
            }}
          />
        );
      case modalIds.OPEN_CAMERA_MODAL:
        return (
          <OpenCameraModal
            onClickClose={handleClickClose}
            setImagesState={
              currentModal?.data?.setImagesState as unknown as React.Dispatch<
                React.SetStateAction<Img[]>
              >
            }
            dataBaseNamingCaching={currentModal?.data?.dataBaseNamingCaching}
            type={currentModal?.data?.type}
            setFilesState={
              currentModal?.data?.setFilesState as unknown as React.Dispatch<
                React.SetStateAction<CustomFile[]>
              >
            }
          />
        );
      case modalIds.ADD_SKETCH_MODAL:
        return (
          <AddSketchModal
            onClickClose={handleClickClose}
            onAddSketch={
              currentModal?.data?.handleAddSketch as unknown as () => void
            }
          />
        );
      case modalIds.VIEW_FULLSCREEN_IMAGE:
        return (
          <ViewFullscreenImage
            onClickClose={handleClickClose}
            currentImage={currentModal?.data?.currentImage as unknown as string}
            title={currentModal?.data?.title as unknown as string}
            currentDate={currentModal?.data?.currentDate as unknown as string}
            currentHours={currentModal?.data?.currentHours as unknown as string}
            currentMinutes={
              currentModal?.data?.currentMinutes as unknown as string
            }
            cotic={currentModal?.data?.cotic as unknown as string}
          />
        );
      case modalIds.TRANSFER_SUBORDER_MODAL:
        return (
          <TransferSubOrderModal
            onClickClose={handleClickClose}
            namingPersistForm={
              currentModal?.data?.namingPersistForm as unknown as string
            }
            orderToTransfer={
              currentModal?.data?.orderToTransfer as unknown as {
                id: string;
                type: string;
                orderParent: string;
              }
            }
            currentParentOrder={
              currentModal?.data?.currentParentOrder as unknown as {
                id: string;
                type: string;
              }
            }
            revalidatorFunction={
              currentModal?.data?.revalidatorFunction as unknown as () => void
            }
          />
        );
      case modalIds.CONFIRM_TRANSFER_MODAL:
        return (
          <ConfirmTransferModal
            onClickClose={handleClickClose}
            onTransferOrder={
              currentModal.data?.onTransferOrder as unknown as () => void
            }
            orderType={currentModal.data?.orderType as unknown as string}
            newParentOrderType={
              currentModal.data?.newParentOrderType as unknown as string
            }
            isLoading={currentModal.data?.isLoading as unknown as boolean}
            isSameOrder={currentModal.data?.isSameOrder as unknown as boolean}
          />
        );
      default:
        return null;
    }
  }, [currentModal]);

  return (
    <BaseModal handleClickClose={handleClickClose}>{renderModal()}</BaseModal>
  );
}

import styled from 'styled-components';

// Styles generals
import { colors } from '../../../../styles/colors';
import {
  breakPoints,
  percentBaseResponsive,
  spaces,
} from '../../../../styles/spaces';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: ${colors.verySoftRed};
  padding: ${spaces.xxl}rem;
  border-radius: ${spaces.xxs}rem;
  @media (max-width: ${breakPoints.smallDesktop * percentBaseResponsive}rem) {
    padding: ${spaces.xl}rem;
  }
`;

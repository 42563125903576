//Dependencies
import React, {
  useCallback,
  useMemo,
  useRef,
  useState,
  forwardRef,
  Ref,
  useImperativeHandle,
  useLayoutEffect,
  useEffect,
} from 'react';
import {
  createColumnHelper,
  ColumnFiltersState,
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  flexRender,
  PaginationState,
  getPaginationRowModel,
  VisibilityState,
  Row,
  FilterFnOption,
  getSortedRowModel,
  SortingState,
} from '@tanstack/react-table';
import { format, formatISO, parseISO } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import isEqual from 'lodash.isequal';
import debounce from 'lodash.debounce';

// Styled Components
import {
  ThStyled,
  TdStyled,
  ContainerStyled,
  FiltersContainerStyled,
  SubContainerFiltersStyled,
  TableContainerStyled,
  TrStyled,
  ContainerButtonsStyled,
  ShowFiltersButton,
  ArrowBottomStyled,
  SubContainerHead,
  DropDownInputCapSub,
  ButtonIconBackgroundStyled,
  DropDownVisibilityColumnStyled,
  ContainerLoader,
} from './styles';

import {
  Table,
  Title,
  TextCell,
  InputRadioStyled,
  InputRadioContainer,
  TextHeader,
  Tbody,
} from '../components/MainStyledComponents';

// Components
import InputCalendar from '../../../inputs/Calendar';
import InputCotic from '../../../inputs/InputCotic';
import { SpecialTag } from '../../SpecialTag';
import { ButtonIconBackground } from '../../../inputs/buttons/ButtonIconBackground';

// Types
import {
  tableProps,
  dataTable,
  imperativeRefPagination,
  option,
} from './types';
import { onChangeValue } from '../../../inputs/DropDown/DropDownList/types';
import { ImperativeRefInput } from '../../../inputs/DropDown/DropDownInput/types';
import {
  ImperativeRefCalendarInput,
  onChangeInputType,
} from '../../../inputs/Calendar/types';
import {
  ImperativeRefInputCotic,
  onChangeInputCoticTypes,
} from '../../../inputs/InputCotic/types';
import { columnID, columnNames } from '../components/types';
import { variant } from '../../SpecialTag/types';
import { Order } from '../../../../types/orders';

// Mocks
import { mocksTable } from './mocks';

// Utils
import { coticFilterFunction, dateFilterFunction } from './utils';

// Media
import {
  useIsLandscapeTablet,
  useIsPortraitTablet,
  useIsSmallDesktop,
} from '../../../../hooks/useMediaBreakPoints';

// Action
import { selectedRowModalTable } from '../../../../state/actions/ui/selectRowTableModal';
import DropDownInput from '../../../inputs/DropDown/DropDownInput';

// Redux
import {
  getCursor,
  getIsLoadingData,
} from '../../../../state/selectors/ui/tables';
import { setCursor } from '../../../../state/actions/ui/cursorTables';

// GQL
import { GET_SUB_CAPITOLS_BY_IDS } from '../../../../gql/queries/orders/predefinedData';
import { GET_CLASSIFICATION_BY_ID_OR_COTIC } from '../../../../gql/queries/orders/predefinedData';
import { client } from '../../../../gql/client';
import { Loader } from '../../../inputs/Loader';

const InputRadioDefaultTable = (
  {
    titleText,
    data,
    onReFetchData,
    namingPersistForm,
    onRefetchPagination,
    tableId,
    setIsSelectedRow,
    ...props
  }: tableProps,
  innerRef: Ref<imperativeRefPagination>
): JSX.Element => {
  const dataAll = data?.genericOrdersData ?? mocksTable;
  const isLoadingData = useSelector(getIsLoadingData(tableId), isEqual);
  const globalCursor = useSelector(getCursor(tableId), isEqual);
  const inputListConRef = useRef<ImperativeRefInput>(null);
  const inputCapitolRef = useRef<ImperativeRefInput>(null);
  const inputSubcapitolRef = useRef<ImperativeRefInput>(null);
  const inputDateRef = useRef<ImperativeRefCalendarInput>(null);
  const inputCoticRef = useRef<ImperativeRefInputCotic>(null);

  const isSmallDesktop = useIsSmallDesktop();
  const isLandscapeTablet = useIsLandscapeTablet();
  const isPortraitTablet = useIsPortraitTablet();

  const dispatch = useDispatch();

  const [showFilters, setShowFilters] = useState(false);
  const [rowSelection, setRowSelection] = useState({});
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [selectorCursor, setSelectorCursor] = useState<{
    take: number;
    cursor: string;
    indexType: number | undefined;
    indexExpedition: number | undefined;
    concessionId: string | undefined;
    fromDate: string | undefined;
    toDate: string | undefined;
    classification: string | undefined;
    capitolId: string | undefined;
    subCapitolId: string | undefined;
    state: string | undefined;
  }>(globalCursor);
  const [selectorData, setSelectorData] = useState<{
    indexType: number | undefined;
    indexExpedition: number | undefined;
    concessionId: string | undefined;
    fromDate: string | undefined;
    toDate: string | undefined;
    classification: string | undefined;
    capitolId: string | undefined;
    subCapitolId: string | undefined;
    state: string | undefined;
  }>({
    indexType: undefined,
    indexExpedition: undefined,
    concessionId: undefined,
    fromDate: undefined,
    toDate: undefined,
    classification: undefined,
    capitolId: undefined,
    subCapitolId: undefined,
    state: undefined,
  });
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>(
    isPortraitTablet
      ? showFilters
        ? {
            pageIndex: 0,
            pageSize: 5,
          }
        : {
            pageIndex: 0,
            pageSize: 8,
          }
      : isLandscapeTablet
      ? showFilters
        ? {
            pageIndex: 0,
            pageSize: 5,
          }
        : {
            pageIndex: 0,
            pageSize: 5,
          }
      : {
          pageIndex: 0,
          pageSize: 8,
        }
  );

  const handleClick = useCallback(() => {
    setShowFilters(!showFilters);
  }, [showFilters]);

  useEffect(() => {
    isLandscapeTablet ? setShowFilters(false) : setShowFilters(true);
  }, [isLandscapeTablet]);

  useLayoutEffect(() => {
    if (isPortraitTablet) {
      setPagination(
        showFilters
          ? {
              pageIndex: 0,
              pageSize: 5,
            }
          : {
              pageIndex: 0,
              pageSize: 8,
            }
      );
    } else if (isLandscapeTablet) {
      setPagination(
        showFilters
          ? {
              pageIndex: 0,
              pageSize: 5,
            }
          : {
              pageIndex: 0,
              pageSize: 8,
            }
      );
    } else if (isSmallDesktop) {
      setPagination(
        showFilters
          ? {
              pageIndex: 0,
              pageSize: 8,
            }
          : {
              pageIndex: 0,
              pageSize: 10,
            }
      );
    } else {
      setPagination({
        pageIndex: 0,
        pageSize: 10,
      });
    }
  }, [isPortraitTablet, isLandscapeTablet, isSmallDesktop, showFilters]);

  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>(
    isPortraitTablet
      ? {
          state: false,
          hour: false,
          description: false,
          road: false,
          subCapitol: false,
          pkEnd: false,
        }
      : isLandscapeTablet
      ? {
          road: false,
          subCapitol: false,
          description: false,
        }
      : {}
  );

  useLayoutEffect(() => {
    setColumnVisibility(
      isPortraitTablet
        ? {
            state: false,
            hour: false,
            description: false,
            road: false,
            subCapitol: false,
            pkEnd: false,
          }
        : isLandscapeTablet
        ? {
            road: false,
            subCapitol: false,
            description: false,
          }
        : {}
    );
  }, [isPortraitTablet, isLandscapeTablet]);

  const columnHelper = createColumnHelper<dataTable>();

  const [sorting] = useState<SortingState>([
    { id: 'creationDate', desc: true },
  ]);

  const columns = useMemo(
    () => [
      columnHelper.display({
        id: 'select',
        header: undefined,
        cell: ({ row }) => (
          <InputRadioContainer>
            <InputRadioStyled
              name="Input"
              {...{
                checked: row.getIsSelected(), // Returns whether or not the row is selected.
                disabled: !row.getCanSelect(), // Returns whether or not the row can be selected.
                indeterminate: row.getIsSomeSelected(), //Returns whether or not some of the row's sub rows are selected.
                onChange: row.getToggleSelectedHandler(), //Returns a handler that can be used to toggle the row.
              }}
            />
          </InputRadioContainer>
        ),
      }),
      columnHelper.accessor(columnNames.state.id, {
        header: () =>
          !isPortraitTablet && (
            <TextHeader size={'XS'} colorText={'greyDark'}>
              {columnNames.state.label}
            </TextHeader>
          ),
        cell: (info) => (
          <SpecialTag sizeTag="small" variant={info.getValue() as variant} />
        ),
      }),
      columnHelper.accessor(columnNames.cotic.id, {
        header: () => (
          <TextHeader size={'XS'} colorText={'greyDark'}>
            {columnNames.cotic.label}
          </TextHeader>
        ),
        cell: (info) => (
          <TextCell size={'S'}>
            {(info.getValue() as [string])?.join('.')}
          </TextCell>
        ),
        filterFn: 'coticFilter' as FilterFnOption<Order>,
      }),
      columnHelper.accessor(columnNames.registerInitDate.id, {
        header: () => (
          <TextHeader size={'XS'} colorText={'greyDark'}>
            {columnNames.registerInitDate.label}
          </TextHeader>
        ),
        cell: (info) =>
          info.getValue() && (
            <TextCell size={'S'}>
              {format(
                new Date(info.getValue() as string),
                'dd/MM/yyyy - HH:mm'
              )}
            </TextCell>
          ),
        filterFn: 'dateFilter' as FilterFnOption<dataTable>,
      }),

      columnHelper.accessor(columnNames.capitol.id, {
        header: () => (
          <TextHeader size={'XS'} colorText={'greyDark'}>
            {columnNames.capitol.label}
          </TextHeader>
        ),
        cell: (info) => (
          <TextCell size={'S'}>{info.getValue() as string}</TextCell>
        ),
      }),
      columnHelper.accessor(columnNames.subCapitol.id, {
        header: () => (
          <TextHeader size={'XS'} colorText={'greyDark'}>
            {columnNames.subCapitol.label}
          </TextHeader>
        ),
        cell: (info) => (
          <TextCell size={'S'}>{info.getValue() as string}</TextCell>
        ),
      }),
      columnHelper.accessor(columnNames.concession.id, {
        header: () => (
          <TextHeader size={'XS'} colorText={'greyDark'}>
            {columnNames.concession.label}
          </TextHeader>
        ),
        cell: (info) => (
          <TextCell size={'S'}>{info.getValue() as string}</TextCell>
        ),
      }),
      columnHelper.accessor(columnNames.road.id, {
        header: () => (
          <TextHeader size={'XS'} colorText={'greyDark'}>
            {columnNames.road.label}
          </TextHeader>
        ),
        cell: (info) => (
          <TextCell size={'S'}>{info.getValue() as string}</TextCell>
        ),
      }),
      columnHelper.accessor('pkInit', {
        header: () => (
          <TextHeader size={'XS'} colorText={'greyDark'}>
            {columnNames.pkInit.label}
          </TextHeader>
        ),
        cell: (info) => (
          <TextCell size={'S'}>{`${info.getValue()} km`}</TextCell>
        ),
      }),
      columnHelper.accessor(columnNames.pkEnd.id, {
        header: () => (
          <TextHeader size={'XS'} colorText={'greyDark'}>
            {columnNames.pkEnd.label}
          </TextHeader>
        ),
        cell: (info) => (
          <TextCell size={'S'}>{`${info.getValue()} km`}</TextCell>
        ),
      }),
      columnHelper.accessor(columnNames.description.id, {
        header: () => (
          <TextHeader size={'XS'} colorText={'greyDark'}>
            {columnNames.description.label}
          </TextHeader>
        ),
        cell: (info) => (
          <TextCell size={'S'}>{info.getValue() as string}</TextCell>
        ),
      }),
      columnHelper.accessor(columnNames.creationDate.id, {
        header: () => (
          <TextHeader size={'XS'} colorText={'greyDark'}>
            {columnNames.creationDate.label}
          </TextHeader>
        ),
        cell: (info) =>
          info.getValue() && (
            <TextCell size={'S'}>
              {format(
                new Date(info.getValue() as string),
                'dd/MM/yyyy - HH:mm'
              )}
            </TextCell>
          ),
      }),
    ],
    []
  );

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  const filterFns = {
    coticFilter: coticFilterFunction, //I redeclare the custom function in filterFns so that it can be used
    dateFilter: dateFilterFunction,
  };

  const table = useReactTable({
    data: dataAll,
    columns,
    state: {
      rowSelection,
      columnFilters,
      pagination,
      columnVisibility,
      sorting,
    },
    filterFns,
    enableRowSelection: true, //enable row selection for all rows
    enableMultiRowSelection: false, //disables multiple row selection for all rows in the table
    getSortedRowModel: getSortedRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onPaginationChange: setPagination,
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnFiltersChange: setColumnFilters,
    getPaginationRowModel: getPaginationRowModel(),
  });

  // This methods are to pass currentPage and pageCount values to parent component by props

  // This consts are the functions to expose with useImperativeHandle

  const handlePageIndex = useCallback((i: number) => {
    table.setPageIndex(i);
  }, []);

  const handleNext = useCallback(() => {
    table.nextPage();
  }, []);

  const handlePrevious = useCallback(() => {
    table.previousPage();
  }, []);

  useImperativeHandle(
    innerRef,
    () => {
      return {
        pageIndex: handlePageIndex,
        nextPage: handleNext,
        prevPage: handlePrevious,
      };
    },
    [handlePageIndex, handleNext, handlePrevious]
  );

  //I select the column and we pass the filter with the input values
  const onChangeInputCotic = useCallback<onChangeInputCoticTypes>(
    debounce(
      async (value) => {
        const coticId = value[3] === '' ? undefined : Number(value[3]);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let classification: any;
        if (coticId) {
          classification = await client.query({
            query: GET_CLASSIFICATION_BY_ID_OR_COTIC,
            variables: {
              coticId,
            },
          });
        }

        const indexType = value[1] === '' ? undefined : Number(value[1]);
        const indexExpedition = value[2] === '' ? undefined : Number(value[2]);
        setSelectorData((prevData) => {
          const newData = prevData;
          newData.indexType = indexType;
          newData.indexExpedition = indexExpedition;
          newData.classification = classification?.data?.classification?.id;
          return newData;
        });
      },
      1000,
      {
        leading: false,
        trailing: true,
      }
    ),
    [
      selectorCursor,
      selectorData?.concessionId,
      selectorData?.fromDate,
      selectorData?.toDate,
      selectorData?.capitolId,
      selectorData?.subCapitolId,
    ]
  );

  const onChangeDateInput = useCallback<onChangeInputType>(
    (range) => {
      if (range.to) {
        range.to.setHours(23);
        range.to.setMinutes(59);
        range.to.setSeconds(59);
        range.to.setMilliseconds(999);
      }
      const fromDateIso = formatISO(range.from as Date);
      const toDateIso = formatISO(range.to as Date);
      setSelectorData((prevData) => {
        const newData = prevData;
        newData.fromDate = fromDateIso;
        newData.toDate = toDateIso;
        return newData;
      });
    },
    [
      selectorCursor,
      selectorData?.indexType,
      selectorData?.indexExpedition,
      selectorData?.concessionId,
      selectorData?.classification,
      selectorData?.capitolId,
      selectorData?.subCapitolId,
    ]
  );

  const capitolOptions = useCallback(() => {
    return data?.filtersDataOptions?.capitols.map((capitol) => ({
      label: capitol.name,
      value: capitol.id,
      subCapitolsIDs: capitol.subCapitolsIDs,
    }));
  }, [data?.filtersDataOptions?.capitols]);

  const concessionOptions = useCallback(() => {
    return data?.filtersDataOptions?.concessions.map((concession) => ({
      label: concession.name,
      value: concession.id,
    }));
  }, [data?.filtersDataOptions?.concessions]);

  const initialSubCapitolsOptions = useCallback(() => {
    return data?.filtersDataOptions?.subCapitols?.map(
      (subCapitol: { name: string; id: string }) => ({
        label: subCapitol.name,
        value: subCapitol.id,
      })
    );
  }, [data?.filtersDataOptions?.subCapitols]);

  const [subCapitolOptions, setSubCapitolOptions] = useState(
    initialSubCapitolsOptions
  );

  const onChangeSelectedConcession = useCallback(
    (option: option) => {
      setSelectorData((prevData) => {
        const newData = prevData;
        newData.concessionId = option.value;
        return newData;
      });
    },
    [
      selectorCursor,
      selectorData?.indexType,
      selectorData?.indexExpedition,
      selectorData?.subCapitolId,
      selectorData?.capitolId,
      selectorData?.fromDate,
      selectorData?.toDate,
      selectorData?.classification,
      selectorData?.state,
    ]
  );

  const onChangeSelectedCapitol = useCallback(
    async (option: option) => {
      const {
        data: { readSubCapitols },
      } = await client.query({
        query: GET_SUB_CAPITOLS_BY_IDS,
        variables: {
          ids: option.subCapitolsIDs,
        },
      });
      const sortedSubCapitols = [...readSubCapitols].sort((a, b) =>
        a.name.localeCompare(b.name)
      );
      setSubCapitolOptions(
        sortedSubCapitols?.map((road: { id: string; name: string }) => ({
          value: road.id,
          label: road.name,
        }))
      );
      setSelectorData((prevData) => {
        const newData = prevData;
        newData.capitolId = option.value;
        newData.subCapitolId = undefined;
        return newData;
      });

      inputSubcapitolRef.current?.clearSelected();
    },
    [
      selectorCursor,
      selectorData?.indexType,
      selectorData?.indexExpedition,
      selectorData?.concessionId,
      selectorData?.fromDate,
      selectorData?.toDate,
      selectorData?.classification,
    ]
  );

  const onChangeSelectedSubcapitol = useCallback(
    (option: option) => {
      setSelectorData((prevData) => {
        const newData = prevData;
        newData.subCapitolId = option.value;
        return newData;
      });
    },
    [
      selectorCursor,
      selectorData?.indexType,
      selectorData?.indexExpedition,
      selectorData?.concessionId,
      selectorData?.capitolId,
      selectorData?.fromDate,
      selectorData?.toDate,
      selectorData?.classification,
    ]
  );

  const handleClickRow = useCallback(
    (row: Row<dataTable>) => {
      dispatch(
        selectedRowModalTable(namingPersistForm as string, row.original)
      );

      if (row.getIsSelected()) {
        setIsSelectedRow(true);
      } else {
        setIsSelectedRow(false);
      }
    },
    [namingPersistForm]
  );

  const onClearValue = useCallback(() => {
    inputCapitolRef.current?.clearSelected();
    inputSubcapitolRef.current?.clearSelected();
    inputListConRef.current?.clearSelected();
    inputDateRef.current?.clearSelected();
    inputCoticRef.current?.clearSelected();
    setColumnFilters([]);
    const dataCursor = {
      take: pageSize,
      cursor: '',
      indexType: undefined,
      indexExpedition: undefined,
      subCapitolId: undefined,
      capitolId: undefined,
      concessionId: undefined,
      fromDate: undefined,
      toDate: undefined,
      classification: undefined,
      orderBy: 'desc',
      state: undefined,
    };
    dispatch(setCursor(tableId as string, dataCursor));
    setSelectorCursor((prevData) => {
      const newData = prevData;
      newData.indexType = undefined;
      newData.indexExpedition = undefined;
      newData.subCapitolId = undefined;
      newData.capitolId = undefined;
      newData.concessionId = undefined;
      newData.fromDate = undefined;
      newData.toDate = undefined;
      newData.classification = undefined;
      newData.state = undefined;
      return newData;
    });
    setSelectorData((prevData) => {
      const newData = prevData;
      newData.indexType = undefined;
      newData.indexExpedition = undefined;
      newData.subCapitolId = undefined;
      newData.capitolId = undefined;
      newData.concessionId = undefined;
      newData.fromDate = undefined;
      newData.toDate = undefined;
      newData.classification = undefined;
      newData.state = undefined;
      return newData;
    });
    setSubCapitolOptions(initialSubCapitolsOptions);
    onRefetchPagination();
  }, [selectorData]);

  const hiddenColumns: number = Object.values(
    columnVisibility as object
  ).reduce((count, currentValue) => (!currentValue ? count + 1 : count), 0);

  const commonColumns = Object.values(columnNames).filter((column) =>
    table
      .getAllColumns()
      .map((column) => column.id)
      .includes(column.id)
  );

  const commonLabels = commonColumns.map((column) => column.label);

  const handleSearch = async () => {
    const dataCursor = {
      take: pageSize,
      cursor: selectorCursor?.cursor,
      indexType: selectorData?.indexType ?? selectorCursor?.indexType,
      indexExpedition:
        selectorData?.indexExpedition ?? selectorCursor?.indexExpedition,
      subCapitolId: selectorData?.subCapitolId ?? selectorCursor?.subCapitolId,
      concessionId: selectorData?.concessionId ?? selectorCursor?.concessionId,
      capitolId: selectorData?.capitolId ?? selectorCursor?.capitolId,
      fromDate: selectorData?.fromDate ?? selectorCursor?.fromDate,
      toDate: selectorData?.toDate ?? selectorCursor?.toDate,
      classification:
        selectorData?.classification ?? selectorCursor?.classification,
      orderBy: 'desc',
    };
    dispatch(setCursor(tableId as string, dataCursor));

    onRefetchPagination();
  };

  return (
    <ContainerStyled>
      {isLoadingData && (
        <ContainerLoader>
          <Loader />
        </ContainerLoader>
      )}
      <FiltersContainerStyled isLoading={isLoadingData as boolean}>
        <SubContainerHead>
          <Title component={'h3'} variant={'semiBold'}>
            {titleText}
          </Title>
          {isLandscapeTablet ? (
            <ShowFiltersButton isBlack={showFilters} onClick={handleClick}>
              <ArrowBottomStyled isRotate={showFilters}></ArrowBottomStyled>
            </ShowFiltersButton>
          ) : null}
        </SubContainerHead>
        {showFilters ? (
          <SubContainerFiltersStyled>
            {isLandscapeTablet ? null : (
              <InputCotic
                label="COTIC"
                disabled={isLoadingData}
                onChangeInputCotic={onChangeInputCotic}
                ref={inputCoticRef}
              />
            )}
            <InputCalendar
              ref={inputDateRef}
              label={'Dates filtre entre:'}
              name={'data'}
              onChangeInput={onChangeDateInput}
              dateRange={
                selectorCursor?.fromDate && selectorCursor?.toDate
                  ? {
                      from: parseISO(selectorCursor?.fromDate),
                      to: parseISO(selectorCursor?.toDate),
                    }
                  : undefined
              }
              disabled={isLoadingData}
            />
            <DropDownInput
              inputSize="XS"
              ref={inputListConRef}
              labelText={'Concessió'}
              placeholder={'Selecciona'}
              typeDropDown={'Secondary'}
              options={concessionOptions() as option[]}
              onChangeSelected={onChangeSelectedConcession as onChangeValue}
              isDisabled={isLoadingData}
            />
            <DropDownInputCapSub
              inputSize="S"
              ref={inputCapitolRef}
              labelText={'Capitol'}
              placeholder={'Selecciona'}
              typeDropDown={'Secondary'}
              options={capitolOptions() as option[]}
              onChangeSelected={onChangeSelectedCapitol as onChangeValue}
              isDisabled={isLoadingData}
            />
            {isLandscapeTablet ? null : (
              <DropDownInputCapSub
                inputSize="S"
                ref={inputSubcapitolRef}
                labelText={'Subcapitol'}
                placeholder={'Selecciona'}
                typeDropDown={'Secondary'}
                options={
                  (subCapitolOptions as option[]) ?? initialSubCapitolsOptions()
                }
                onChangeSelected={onChangeSelectedSubcapitol as onChangeValue}
                isDisabled={isLoadingData}
              />
            )}

            <ContainerButtonsStyled>
              <ButtonIconBackgroundStyled
                variant={'delete'}
                onClick={onClearValue}
                disabled={isLoadingData}
              />
              <DropDownVisibilityColumnStyled
                columns={table.getAllColumns().slice(1)}
                columnNames={commonLabels}
                isDisabled={isLoadingData}
              />
              <ButtonIconBackgroundStyled
                variant={'restart'}
                onClick={onReFetchData}
                disabled={isLoadingData}
              />
              <ButtonIconBackground
                variant={'search'}
                onClick={handleSearch}
                disabled={isLoadingData}
              />
            </ContainerButtonsStyled>
          </SubContainerFiltersStyled>
        ) : null}
      </FiltersContainerStyled>
      <TableContainerStyled isLoading={isLoadingData as boolean}>
        <Table {...props}>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <ThStyled key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </ThStyled>
                ))}
              </tr>
            ))}
          </thead>
          <Tbody>
            {table.getRowModel().rows.map((row) => (
              <TrStyled
                key={row.id}
                checked={row.getIsSelected()}
                indeterminate={!table.getIsSomeRowsSelected()}
                onClick={() => {
                  handleClickRow(row);
                  row.getToggleSelectedHandler()(row);
                }}
              >
                {row.getVisibleCells().map((cell) => (
                  <TdStyled
                    column={cell.column.id as columnID}
                    hiddenColumns={hiddenColumns}
                    key={cell.id}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TdStyled>
                ))}
              </TrStyled>
            ))}
          </Tbody>
        </Table>
      </TableContainerStyled>
    </ContainerStyled>
  );
};

export default forwardRef(InputRadioDefaultTable);

import styled, { css } from 'styled-components';

// Colors
import { colors } from '../../../../../styles/colors';

// Spaces
import {
  breakPoints,
  percentBaseResponsive,
  spaces,
} from '../../../../../styles/spaces';

// Styled Components
import { Container, Th } from '../../summaryTables/SummaryTablesStyles';

// Components
import DropDownInput from '../../../../inputs/DropDown/DropDownInput';
import { CheckBox } from '../../../../inputs/CheckBox';

// Types
import { DisabledType, leftTableCellProps, rightTableCellProps } from './types';
import { DropDownHeader } from '../../../../inputs/DropDown/DropDownInput/styles';
import { DropDownListContainer } from '../../../../inputs/DropDown/DropDownList/styles';

export const FiltersContainer = styled.div`
  display: flex;
  align-items: end;
  gap: ${spaces.m}rem;
  div:last-child {
    flex: 1;
  }
`;

export const DropDownFilterStyled = styled(DropDownInput)`
  ${DropDownHeader} {
    height: 4.5rem;
  }
`;

export const OperatorsTableContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.8rem;
  flex: 1;
  width: 57.2rem;
  max-width: 90.1rem;

  @media (max-width: ${breakPoints.landscapeTablet *
    percentBaseResponsive}rem) {
    width: 100%;
  }
`;

export const VehiclesTableContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 2.8rem;
  max-width: 60rem;
  width: 100%;
  @media (max-width: ${breakPoints.smallDesktop * percentBaseResponsive}rem) {
    max-width: initial;
  }
`;

export const ContainerTable = styled(Container)`
  display: flex;
  gap: 9.8rem;
  @media (max-width: ${breakPoints.smallDesktop * percentBaseResponsive}rem) {
    flex-wrap: wrap;
  }
  @media (max-width: ${breakPoints.landscapeTablet *
    percentBaseResponsive}rem) {
    flex-direction: column;
    gap: 3.2rem;
  }
`;

export const ContainerInput = styled.div``;

export const TableLeftTh = styled(Th)<leftTableCellProps>`
  ${({ cell }) =>
    ({
      select: css`
        width: 1rem;
      `,
      firstName: css`
        width: 15rem;
      `,
      lastName: css`
        width: 40rem;
      `,
      concession: css``,
    }[cell])};
`;

export const TableRightTh = styled(Th)<rightTableCellProps>`
  ${({ cell }) =>
    ({
      select: css`
        width: 1rem;
      `,
      plate: css`
        width: 30rem;
        @media (max-width: ${breakPoints.smallDesktop *
          percentBaseResponsive}rem) {
          width: 15rem;
        }
      `,
      driver: css`
        width: 50rem;
        @media (max-width: ${breakPoints.smallDesktop *
          percentBaseResponsive}rem) {
          width: 50rem;
        }
      `,
      remove: css``,
    }[cell])};
`;

export const DropDownInputStyled = styled(DropDownInput)<DisabledType>`
  ${DropDownHeader} {
    padding: 0;
    height: initial;
    :focus {
      outline: none;
    }
    background: ${(isDisabled) => isDisabled && 'none'};
  }
  ${DropDownListContainer} {
    z-index: 1;
  }
  @media (max-width: ${breakPoints.smallDesktop * percentBaseResponsive}rem) {
    width: 100%;
  }
`;

export const CheckBoxStyled = styled(CheckBox)`
  &:checked {
    background-color: ${colors.greyDark};
  }
`;

export const CheckBoxStyledHeader = styled(CheckBox)`
  border-color: ${colors.white};

  &:checked {
    background-color: ${colors.greyDark};
  }
`;

export const Img = styled.img`
  width: 1.5rem;
  height: 2rem;
  cursor: pointer;
`;

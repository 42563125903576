import { defer, LoaderFunction } from 'react-router-dom';

// Sentry
import * as Sentry from '@sentry/react';

// GQL
import { client } from '../../../../gql/client';
import { FETCH_PREDEFINED_ORDERS } from '../../../../gql/queries/orders/predefinedData';
import { GET_SELECTORS_DATA } from '../../../../gql/queries/orders/orders';
import { formNaming } from '../../../../components/forms/NewCommunicationForm/constants';
import {
  FETCH_CAPITOLS,
  FETCH_CLASSIFICATIONS,
} from '../../../../gql/queries/orders/predefinedData';

// Type
import { AuthContextType } from '../../../../hooks/useAuth';

// Data static
import {
  groupLanes,
  groupTracks,
  specialTagType,
} from '../../../../data/orders';

// Compression
import { decompressSessionStorage } from '../../../../utils/sessionStorage';

const loadFormOptions = async (userData: AuthContextType | null) => {
  try {
    if (userData?.user) {
      const [
        { data },
        {
          data: { capitols },
        },
        {
          data: { predefinedOrders },
        },
        {
          data: { classifications },
        },
      ] = await Promise.all([
        client.query({
          query: GET_SELECTORS_DATA,
        }),
        client.query({
          query: FETCH_CAPITOLS,
        }),
        client.query({
          query: FETCH_PREDEFINED_ORDERS,
        }),
        client.query({
          query: FETCH_CLASSIFICATIONS,
        }),
      ]);

      return {
        ...data,
        capitols,
        predefinedOrders,
        classifications,
        groupTracks,
        groupLanes,
        specialTagType,
      };
    }
    return {};
  } catch (err) {
    Sentry.captureException(err);
    console.log('> Communication Summary loading error:', err);
    return true;
  }
};

export const NewCommunicationLoader: (
  userData: AuthContextType | null
) => LoaderFunction | undefined = (userData) => async () => {
  // Pick the cached form values from local storage in case browser was reloaded
  const stringifyForm = sessionStorage.getItem(
    formNaming.NEW_COMMUNICATION_FORM
  );
  const loaderToResolve = await loadFormOptions(userData);
  if (stringifyForm) {
    const sessionStorageData = JSON.parse(
      decompressSessionStorage(stringifyForm)
    );
    const combinedData = {
      team: userData?.user?.user?.teamId,
      ...loaderToResolve,
      ...sessionStorageData,
    };

    return defer({
      data: combinedData,
    });
  }
  const nameOperator = `${userData?.user?.user.name} ${
    userData?.user?.user.surName.split(' ')[0]
  }`;
  const combinedData = {
    team: userData?.user?.user?.teamId,
    ...loaderToResolve,
    operator: nameOperator,
  };

  // Prepare some default values for the form to load properly
  return defer({
    data: combinedData,
  });
};

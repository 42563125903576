// Dependencies
import React, { useImperativeHandle, forwardRef, Ref, useState } from 'react';

// Types
import { searchBarProps, ImperativeSearchBarInput } from './types';

// Style
import { Container, Input, Icon } from './styles';

// Icon
import SearchIcon from '../../../assets/icons/search-icon.svg';

const SearchBar = (
  { variant = 'big', onChangeInput, ...props }: searchBarProps,
  innerRef: Ref<ImperativeSearchBarInput>
): JSX.Element => {
  const [value, setValue] = useState<string>('');
  useImperativeHandle(innerRef, () => ({
    clear: () => {
      setValue('');
    },
  }));

  return (
    <Container variant={variant}>
      <Icon src={SearchIcon} />
      <Input
        {...props}
        placeholder="Buscar"
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
          onChangeInput?.(e);
        }}
      />
    </Container>
  );
};

export default forwardRef(SearchBar);

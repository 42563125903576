// Dependencies
import React from 'react';

// Styles
import { Container, InputSimple, SwitchSimple } from './styles';

// Types
import { type SimpleSwitchProps } from './types';
import { Typography } from '../../../../display/Typography';

const SimpleSwitch = ({
  changeState,
  state,
  disabled,
  variant = 'normal',
}: SimpleSwitchProps) => {
  const handleClick = () => {
    changeState(!state);
  };

  return (
    <Container variant={variant} state={state}>
      <SwitchSimple state={state} variant={variant}>
        <InputSimple
          disabled={disabled}
          type="checkbox"
          checked={state}
          onChange={handleClick}
        />
      </SwitchSimple>
      {variant === 'labeled' && (
        <Typography size="XXS" variant="medium">
          {state ? 'Actiu' : 'Inactiu'}
        </Typography>
      )}
    </Container>
  );
};

export default SimpleSwitch;

import styled from 'styled-components';
import { colors } from '../../../styles/colors';

// Styles
import {
  spaces,
  breakPoints,
  percentBaseResponsive,
} from '../../../styles/spaces';

// Components
import { ResumeLoadImages } from '../ResumeLoadImages';
import { Typography } from '../Typography';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  gap: ${spaces.xxxl}rem;
  padding: ${spaces.xl}rem;
  background-color: ${colors.verySoftRed};
  border-radius: 0.8rem;
  @media (max-width: ${breakPoints.smallDesktop * percentBaseResponsive}rem) {
    flex-direction: row;
    padding: ${spaces.xl}rem;
    width: 100%;
    gap: ${spaces.l}rem;
  }

  @media (max-width: ${breakPoints.portraitTablet * percentBaseResponsive}rem) {
    flex-direction: column;
  }
`;

export const SubContainer = styled.div`
  @media (max-width: ${breakPoints.smallDesktop * percentBaseResponsive}rem) {
    flex: 1;
  }
`;

export const StyledTypography = styled(Typography)`
  margin-bottom: ${spaces.s}rem;
`;

export const ColumnsContainer = styled.div`
  display: flex;
  gap: ${spaces.l}rem;
  @media (max-width: ${breakPoints.smallDesktop * percentBaseResponsive}rem) {
    gap: ${spaces.xxxs}rem;
    flex-direction: column;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spaces.xxxs}rem;
  &:first-of-type {
    width: 100%;
    max-width: 24rem;
  }
  &:last-of-type {
    width: 100%;
  }
`;

export const ImagesList = styled(ResumeLoadImages)`
  border: 0;
`;

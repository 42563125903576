import React, { useCallback, useState } from 'react';
import { useAsyncValue, useNavigate, useRevalidator } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

// Components
import { InputRadioArrowTable } from '../../../../components/display/tables/InputRadioArrowTable';
import { MainButton } from '../../../../components/inputs/buttons/MainButton';

// Styled Components
import { Container, ContainerButton } from '../components/MainStyledComponents';

// Types
import { dataTypes } from '../../../../components/display/tables/InputRadioArrowTable/types';
import { formNaming } from '../../../../components/forms/NewCommunicationForm/constants';
import {
  tableIds,
  takeBase,
} from '../../../../components/display/tables/types';

// GQL
import { client } from '../../../../gql/client';
import { FETCH_COMMUNICATIONS } from '../../../../gql/queries/communication';

// Redux
import { getCursor } from '../../../../state/selectors/ui/tables';
import { selectCommunicationOrders } from '../../../../gql/selectors/communications';
import { setCursor } from '../../../../state/actions/ui/cursorTables';

export default function DuplicateCommunicationPage(): JSX.Element {
  const data = useAsyncValue() as dataTypes;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedRowId, setSelectedRowId] = useState<string>('');
  const revalidator = useRevalidator();

  const selectorCursor = useSelector(
    getCursor(tableIds.DUPLICATE_COMMUNICATION)
  );

  const handleSeeCommunication = useCallback(() => {
    sessionStorage.removeItem(formNaming.NEW_DUPLICATE_COMMUNICATION_FORM);
    navigate(`summary/${selectedRowId}`);
  }, [selectedRowId]);

  const handleDuplicate = useCallback(() => {
    sessionStorage.removeItem(formNaming.NEW_DUPLICATE_COMMUNICATION_FORM);
    navigate(`new-communication-duplicate/${selectedRowId}`);
  }, [selectedRowId]);

  const onReFetchData = useCallback(async () => {
    const communications = await client.query({
      query: FETCH_COMMUNICATIONS,
      variables: {
        input: {
          take: takeBase,
          cursor: '',
          orderBy: 'desc',
          roadId: selectorCursor.roadId,
          concessionId: selectorCursor.concessionId,
          interlocutorGroupId: selectorCursor.interlocutorGroupId,
          fromDate: selectorCursor.fromDate,
          toDate: selectorCursor.toDate,
          indexType: selectorCursor.indexType,
          indexExpedition: selectorCursor.indexExpedition,
          classification: selectorCursor.classification,
        },
      },
      fetchPolicy: 'network-only',
    });

    const setCursorData = {
      take: takeBase,
      cursor: '',
      orderBy: 'desc',
      roadId: selectorCursor?.roadId,
      concessionId: selectorCursor?.concessionId,
      interlocutorGroupId: selectorCursor?.interlocutorGroupId,
      fromDate: selectorCursor?.fromDate,
      toDate: selectorCursor?.toDate,
      indexType: selectorCursor?.indexType,
      indexExpedition: selectorCursor?.indexExpedition,
      classification: selectorCursor?.classification,
    };

    dispatch(setCursor(tableIds.DUPLICATE_COMMUNICATION, setCursorData));

    await selectCommunicationOrders(
      communications.data.communications.communications,
      true
    );

    revalidator.revalidate();
  }, [selectorCursor]);

  const onRefetchPagination = useCallback(async () => {
    revalidator.revalidate();
  }, []);
  return (
    <Container>
      <InputRadioArrowTable
        titleText="Filtrar comunicacions per:"
        data={data}
        setRowState={setSelectedRowId}
        onReFetchData={onReFetchData}
        onReFetchPagination={onRefetchPagination}
        tableId={tableIds.DUPLICATE_COMMUNICATION}
      />
      <ContainerButton>
        <MainButton
          variant="secondary"
          text="Veure comunicació"
          onClick={handleSeeCommunication}
          disabled={selectedRowId.length === 0}
        />
        <MainButton
          text="Duplicar comunicació"
          onClick={handleDuplicate}
          disabled={selectedRowId.length === 0}
        />
      </ContainerButton>
    </Container>
  );
}

import React, { useCallback, useState } from 'react';
import { useAsyncValue, useNavigate, useRevalidator } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// Components Styled
import { Container, ContainerButton } from '../components/MainStyledComponents';

// Components
import { CheckBoxTagTable } from '../../../../components/display/tables/CheckBoxTagTable';
import { MainButton } from '../../../../components/inputs/buttons/MainButton';

// Types
import { OrderType } from '../../../../types/orders';
import {
  tableIds,
  takeBase,
} from '../../../../components/display/tables/types';
import { dataTypes } from '../../../../components/display/tables/CheckBoxTagTable/types';

// Utils
import { modalIds } from '../../../../components/modals/SuperModal/types';
import { openModal } from '../../../../state/actions/ui/modal';

// GQL
import { client } from '../../../../gql/client';
import { GET_ORDER_BY_ID } from '../../../../gql/queries/orders/orders';
import { GET_GENERIC_ORDERS } from '../../../../gql/queries/genericOrders';
import { selectAllOrders } from '../../../../gql/selectors/orders';

// Utils Styles
import { useIsLandscapeTablet } from '../../../../hooks/useMediaBreakPoints';

// Redux
import { getCursor } from '../../../../state/selectors/ui/tables';
import { setCursor } from '../../../../state/actions/ui/cursorTables';
import * as Sentry from '@sentry/react';

export default function SearchAndCreateHistoricalOrderPage(): JSX.Element {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const data = useAsyncValue() as dataTypes;
  const revalidator = useRevalidator();
  const isLandscape = useIsLandscapeTablet();

  const [selectedRows, setSelectedRows] = useState<Array<string | undefined>>(
    []
  );

  const handleSeeWorkOrder = useCallback(() => {
    const tasks = async () => {
      if (selectedRows[0]) {
        const {
          data: { order },
        } = await client.query({
          query: GET_ORDER_BY_ID,
          variables: {
            id: selectedRows[0],
          },
        });

        navigate(`summary/${order.id}`);
      }
    };
    tasks().catch((err) => {
      Sentry.captureException(err);
      console.log(err);
    });
  }, [selectedRows]);

  const dataSelector = useSelector(
    getCursor(tableIds.SEARCH_AND_CREATE_HISTORICAL_WORKORDERS)
  );

  const onReFetchData = useCallback(async () => {
    const { data } = await client.query({
      query: GET_GENERIC_ORDERS,
      variables: {
        input: {
          take: takeBase,
          cursor: '',
          orderBy: 'desc',
          concessionId: dataSelector.concessionId,
          toDate: dataSelector.toDate,
          fromDate: dataSelector.fromDate,
          indexType: dataSelector.indexType,
          indexExpedition: dataSelector.indexExpedition,
          classification: dataSelector.classification,
          capitolId: dataSelector.capitolId,
          subCapitolId: dataSelector.subCapitolId,
          state: dataSelector.state,
        },
      },
      fetchPolicy: 'network-only',
    });

    const setCursorData = {
      take: takeBase,
      cursor: '',
      orderBy: 'desc',
      concessionId: dataSelector?.concessionId,
      toDate: dataSelector?.toDate,
      fromDate: dataSelector?.fromDate,
      indexType: dataSelector?.indexType,
      indexExpedition: dataSelector?.indexExpedition,
      classification: dataSelector?.classification,
      capitolId: dataSelector?.capitolId,
      subCapitolId: dataSelector?.subCapitolId,
      state: dataSelector?.state,
    };

    dispatch(
      setCursor(tableIds.SEARCH_AND_CREATE_HISTORICAL_WORKORDERS, setCursorData)
    );

    await selectAllOrders(data.genericOrders.genericOrders);

    revalidator.revalidate();
  }, []);

  const handleDownloadHistory = useCallback(() => {
    dispatch(
      openModal(modalIds.DOWNLOAD_HISTORIC, {
        type: OrderType.Generic,
        filterPositionColumn: 2,
        selectedRows,
      })
    );
  }, [selectedRows]);

  const onRefetchPagination = useCallback(async () => {
    revalidator.revalidate();
  }, []);

  return (
    <Container>
      <CheckBoxTagTable
        idCss={'historical-' + OrderType.Generic}
        titleText="Filtrar Ordres de Treball per: "
        data={data}
        onReFetchData={onReFetchData}
        rowState={selectedRows}
        setRowState={setSelectedRows}
        onReFetchPagination={onRefetchPagination}
        tableId={tableIds.SEARCH_AND_CREATE_HISTORICAL_WORKORDERS}
      />
      <ContainerButton>
        <MainButton
          variant="secondary"
          text="Veure O.T."
          disabled={selectedRows.length === 0 || selectedRows.length > 1}
          onClick={handleSeeWorkOrder}
        />
        <MainButton
          text="Descarregar l’històric"
          disabled={selectedRows.length === 0 || isLandscape}
          onClick={handleDownloadHistory}
        />
      </ContainerButton>
    </Container>
  );
}

import { ActionFunction } from 'react-router-dom';

// Sentry
import * as Sentry from '@sentry/react';

// GQL
import { formOrderNaming } from '../../../../../components/forms/NewWorkOrderForm/constants';

// Actions
import { passData } from './ActionUtils/passData';
import { saveData } from './ActionUtils/saveData';

export const ActionNewWorkOrderSummary: ActionFunction = async ({
  request,
}) => {
  const formData = await request.formData();
  const intent = formData.get('intent');
  if (intent === 'pass-data') {
    try {
      return passData(formData);
    } catch (err) {
      Sentry.captureException(err);
      console.log('Error:', err);
    }
  }
  if (intent === 'save-data') {
    try {
      const dataFormRaw = sessionStorage.getItem(
        formOrderNaming.NEW_WORK_ORDER_FORM
      );
      return saveData(dataFormRaw);
    } catch (err) {
      Sentry.captureException(err);
      console.log('> CommunicationSummary - error creating:', err);
    }
  }
  return null;
};

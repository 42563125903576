import React from 'react';

import { Control } from 'react-hook-form';

export type AttentioAccidentComunication = {
  noticeConservationCenterDate?: string;
  arrivalConservationCenterDate?: string;
  departureConservationCenterDate?: string;
  noticeControlCenterDate?: string;
  arrivalControlCenterDate?: string;
  departureControlCenterDate?: string;
  noticeSquadBoysDate?: string;
  arrivalSquadBoysDate?: string;
  departureSquadBoysDate?: string;
  noticeAmbulanceCraneAssistanceDate?: string;
  arrivalAmbulanceCraneAssistanceDate?: string;
  departureAmbulanceCraneAssistanceDate?: string;
  noticeRoadTeamDate?: string;
  arrivalRoadTeamDate?: string;
  departureRoadTeamDate?: string;
  noticeOtherDate?: string;
  arrivalOtherDate?: string;
  departureOtherDate?: string;
  otherName?: string;
};

export type TableProps = React.TableHTMLAttributes<HTMLTableElement> & {
  disabled: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<AttentioAccidentComunication | any>;
  defaultValues?: AttentioAccidentComunication;
  accidentDate?: string;
};

export type dataTable = {
  atention: string;
  avis: string;
  arribada: string;
  sortida: string;
};

export const headers = {
  squadCops: {
    id: 'squadCops',
    label: 'Mossos d’Esquadra',
  },
  roadTeam: {
    id: 'roadTeam',
    label: 'Equip vialitat*',
  },

  assistance: {
    id: 'assistance',
    label: 'Ambulància / Grúa / Emergencia',
  },
  otherName: {
    id: 'others',
    label: 'Altres',
  },
};

export type TrHeaderProps = {
  empty: boolean;
};

export type ThInputProps = {
  disabled?: boolean;
};

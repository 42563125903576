import { ActionFunction } from 'react-router-dom';

// Sentry
import * as Sentry from '@sentry/react';

// Store
import { passData } from './ActionUtils/passData';
import { saveData } from './ActionUtils/saveData';

export const ActionEditCampaignSummary: ActionFunction = async ({
  request,
}) => {
  const formData = await request.formData();
  const intent = formData.get('intent');

  if (intent === 'pass-data') {
    try {
      return passData({
        formData,
      });
    } catch (err) {
      Sentry.captureException(err);
      console.log('Error:', err);
    }
  }

  if (intent === 'save-data') {
    try {
      return saveData({
        formData,
      });
    } catch (err) {
      Sentry.captureException(err);
      console.log('> Campaign summary - error updating:', err);
    }
  }
  return null;
};

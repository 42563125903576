import styled from 'styled-components';

import {
  breakPoints,
  percentBaseResponsive,
  spaces,
} from '../../../../styles/spaces';
import { colors } from '../../../../styles/colors';
import { ButtonIconBackground } from '../../../inputs/buttons/ButtonIconBackground';
import {
  CancelImg,
  CheckImg,
} from '../../../inputs/buttons/ButtonIconBackground/styles';

// Components

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ContainerButtons = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 10rem;
  padding: 0 ${spaces.xl}rem;
  border-bottom-left-radius: 0.8rem;
  border-bottom-right-radius: 0.8rem;
  background: ${colors.greyVerySoft};
  box-shadow: 0rem 0.5rem 1rem rgba(0, 0, 0, 0.2);
  @media (max-width: ${breakPoints.portraitTablet * percentBaseResponsive}rem) {
    min-height: 8rem;
    padding: 0 2rem;
  }
`;

export const HiddenCanvas = styled.canvas`
  display: none;
`;

export const PhotoPreview = styled.img`
  width: 100%;
  max-height: 48rem;
  height: calc(100vh - 22rem);
  object-fit: cover;
  border-top-left-radius: 0.8rem;
  border-top-right-radius: 0.8rem;
`;

export const CameraContainer = styled.div`
  display: flex;
  width: 100%;
  max-height: 48rem;
  height: calc(100vh - 22rem);
  background-color: ${colors.black};
  border-top-left-radius: 0.8rem;
  border-top-right-radius: 0.8rem;
`;

export const Camera = styled.video`
  object-fit: cover;
  width: 100%;
  border-top-left-radius: 0.8rem;
  border-top-right-radius: 0.8rem;
`;

const TemplateButton = styled.button`
  border: 0;
  padding: 0;
  background: transparent;
  :hover {
    cursor: pointer;
    opacity: 0.8;
  }
`;

export const ActionButton = styled(TemplateButton)`
  position: absolute;
  right: ${spaces.xl}rem;
  width: 4rem;
  height: 4rem;
  svg {
    width: 100%;
    height: 100%;
  }
  @media (max-width: ${breakPoints.portraitTablet * percentBaseResponsive}rem) {
    right: 2rem;
  }
`;

export const LargeActionButton = styled(TemplateButton)`
  margin: 0 auto;
  width: 7rem;
  height: 7rem;
  box-shadow: 0 0 13px 3px rgba(0, 0, 0, 0.5);
  border-radius: 100%;
  svg {
    width: 100%;
    height: 100%;
  }
  @media (max-width: ${breakPoints.portraitTablet * percentBaseResponsive}rem) {
    width: 6rem;
    height: 6rem;
  }
`;

export const ButtonLeftIconAction = styled(ButtonIconBackground)`
  align-self: initial;
  ${CheckImg} {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

export const ButtonRightIconAction = styled(ButtonIconBackground)`
  align-self: initial;
  ${CancelImg} {
    width: 2.5rem;
    height: 2.5rem;
  }
`;

import React, { useLayoutEffect, useState } from 'react';
import { useAsyncValue, useSubmit } from 'react-router-dom';
import { useDispatch } from 'react-redux';

// Components
import AnimalsForm from '../../../../../components/forms/ActionForms/AnimalsForm';

// Utils
import { createFormDataFromValues } from '../../../../../utils/forms';

// Constants
import { formActionValidationNaming } from '../../../../../components/forms/ActionForms/constants';

// Utils
import { decompressSessionStorage } from '../../../../../utils/sessionStorage';
import { difference } from '../../../../../utils/difference';

// Redux
import { coticOTSelected } from '../../../../../state/actions/ui/coticOTSelectedGeneralView';
import { Typography } from '../../../../../components/display/Typography';

export default function AnimalsValidationFormPage(): JSX.Element {
  const submit = useSubmit();
  const dispatch = useDispatch();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const loaderData = useAsyncValue() as any;
  const [error, setError] = useState<string | null>(null);

  const onFormSubmit = () => {
    const operatorsAndVehiclesData = JSON.parse(
      decompressSessionStorage(
        sessionStorage.getItem(
          formActionValidationNaming.NEW_VALIDATION_OPERATORS_AND_VEHICLE_FORM
        ) as string
      )
    );

    const operatorsLength =
      operatorsAndVehiclesData.internalOperatorsTable.filter(
        (item: null | string) => item
      ).length;

    if (operatorsLength === 0 && !operatorsAndVehiclesData.isExternal) {
      setError('Heu de seleccionar almenys un operador');
    }

    if (operatorsLength !== 0 || operatorsAndVehiclesData.isExternal) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const dataChanged: any = {};

      Object.values(formActionValidationNaming).forEach((key) => {
        const dataFromCacheRaw = sessionStorage.getItem(key);
        if (dataFromCacheRaw) {
          const dataFromCache = JSON.parse(
            decompressSessionStorage(dataFromCacheRaw as string)
          );

          if (dataFromCache && dataFromCache.dataFromServer) {
            const { dataFromServer } = dataFromCache;
            delete dataFromCache.dataFromServer;
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const dataDifference: any = difference(
              dataFromCache,
              dataFromServer
            );

            Object.keys(dataDifference).forEach((key) => {
              if (dataDifference[key]) {
                dataChanged[key] = dataDifference[key];
              }
            });
          }
        }
      });

      const formData = createFormDataFromValues({
        ...dataChanged,
        isExternal: operatorsAndVehiclesData.isExternal,
      });
      submit(formData, {
        method: 'POST',
        action: '.',
      });
    }
  };

  useLayoutEffect(() => {
    dispatch(
      coticOTSelected(
        'actions-validation',
        loaderData.cotic,
        loaderData.orderId
      )
    );
  }, []);

  return (
    <>
      <AnimalsForm
        onFormSubmit={onFormSubmit}
        initialData={loaderData}
        sessionStorageKey={
          formActionValidationNaming.NEW_VALIDATION_ANIMALS_FORM
        }
        updateKey={formActionValidationNaming.DATA_CHANGES}
        textButton="Validar"
      />
      <Typography size="S" colorText={'red'}>
        {error}
      </Typography>
    </>
  );
}

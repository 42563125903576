import styled from 'styled-components';

// Image
import BackgroundImage from '../../../assets/background-login.png';

// Colors
import { colors } from '../../../styles/colors';

// Styles utils
import { breakPoints, percentBaseResponsive } from '../../../styles/spaces';

export const Container = styled.div`
  background: url(${BackgroundImage}) no-repeat center center;
  background-size: cover;
  min-width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const BoxContainer = styled.div`
  width: 73.9rem;
  height: 58.1rem;
  background-color: ${colors.white};
  border-radius: 1.5rem;
  padding: 6.6rem;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  @media (max-width: ${breakPoints.landscapeTablet *
    percentBaseResponsive}rem) {
    width: 60.8rem;
    height: 47.9rem;
    padding: 4.5rem;
    display: flex;
    justify-content: space-between;
  }
  @media (max-width: ${breakPoints.portraitTablet * percentBaseResponsive}rem) {
    width: 39.4rem;
    height: 67rem;
    display: flex;
    justify-content: space-between;
  }
`;

import { object, string } from 'yup';

// Types
import { VehicleManagement } from './types';

export const VehicleManagementSchema = object<VehicleManagement>({
  plate: string().required(),
  model: string().required(),
  category: string().required(),
  concession: string().required(),
});

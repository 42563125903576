import React, { useCallback, useEffect } from 'react';

// Styles
import { Button, Container, Icon, Title } from './styles';

// Types
import { ModalProps } from './types';

//Components
import ModalContainer from '../../containers/ModalContainer';

// Icons
import DownloadIcon from '../../../../assets/icons/download-icon.svg';
import { colors } from '../../../../styles/colors';

// CSV
import { TableToCSV } from '../../../../utils/table-to-csv';

// Graphql
import { client } from '../../../../gql/client';
import { GET_ORDERS_BY_IDS } from '../../../../gql/queries/orders/orders';

// Types
import { Order } from '../../../../types/orders';

export const DownloadHistoricModal = ({
  onClickClose,
  data,
  ...props
}: ModalProps): JSX.Element => {
  let tableToCSV: TableToCSV;
  useEffect(() => {
    const { readOrders } = client.readQuery({
      query: GET_ORDERS_BY_IDS,
      variables: {
        ids: data.selectedRows,
      },
    });
    const cotics = readOrders.map((order: Order) => order.cotic);
    tableToCSV = new TableToCSV(
      '#historical-' + data.type,
      isNaN(data.filterPositionColumn)
        ? {
            filename: `historical-${data.type}.csv`,
            ignoreColumns: [0], //column index (optional)
            filterByIDs: cotics,
          }
        : {
            filename: `historical-${data.type}.csv`,
            ignoreColumns: [0], //column index (optional)
            filterByIDs: cotics,
            filterPositionColumn: data.filterPositionColumn,
          }
    );
  }, [data.selectedRows]);

  const downloadHistoric = useCallback(() => {
    tableToCSV.download();
  }, []);
  return (
    <ModalContainer width={45} onClickClose={onClickClose} fill={colors.black}>
      <Container {...props}>
        <Icon src={DownloadIcon}></Icon>
        <Title size="XL" variant="semiBold">
          Vols descarregar l’històric?
        </Title>
        <Button
          onClick={downloadHistoric}
          text="Descarregar l’històric"
        ></Button>
      </Container>
    </ModalContainer>
  );
};

import React, { useEffect, useState } from 'react';

// Styled Components
import {
  Container,
  ContainerLoader,
  Icon,
  ModalContainerStyled,
  Title,
} from './styles';

// Colors
import { colors } from '../../../../styles/colors';

// Types
import { ModalProps } from './types';

// Components
import { MainButton } from '../../../inputs/buttons/MainButton';

// Icon
import AlertIcon from '../../../../assets/icons/alert-icon.svg';
import { Loader } from '../../../inputs/Loader';
import { typeOptions } from '../../../../utils/order';

export const ConfirmTransferModal = ({
  onClickClose,
  orderType,
  newParentOrderType,
  onTransferOrder,
  isLoading,
  isSameOrder,
  ...props
}: ModalProps): JSX.Element => {
  const [loadingData, setLoadingData] = useState(false);
  const typeOrder = typeOptions.find((type) => orderType === type.value);
  const newOrderParentType = typeOptions.find(
    (type) => newParentOrderType === type.value
  );
  useEffect(() => {
    setLoadingData(isLoading);
  }, [isLoading]);

  return (
    <ModalContainerStyled
      width={45}
      onClickClose={onClickClose}
      fill={colors.black}
    >
      {loadingData && (
        <ContainerLoader>
          <Loader />
        </ContainerLoader>
      )}
      <Icon src={AlertIcon} isLoading={loadingData} />
      <Container {...props} isLoading={loadingData}>
        <Title variant="semiBold">
          {isSameOrder
            ? `No és possible transferir aquest ${typeOrder?.label} a la${' '}
            ${newOrderParentType?.label} ordre en què estava`
            : `Estàs segur que vols transferir aquest ${
                typeOrder?.label
              } a aquesta${' '}
          ${newOrderParentType?.label}`}
        </Title>
        <MainButton
          text="Desar"
          onClick={() => {
            onTransferOrder();
            setLoadingData(true);
          }}
          disabled={loadingData || isSameOrder}
        />
      </Container>
    </ModalContainerStyled>
  );
};

import { tableIds } from '../../../components/display/tables/types';
import { LOADING_DATA } from '../../actions/ui/loadingData';

export const initialLoadingDataState = {
  [tableIds.DUPLICATE_COMMUNICATION]: {
    id: tableIds.DUPLICATE_COMMUNICATION,
    isLoadingData: false,
  },
  [tableIds.WORKORDER_COMMUNICATIONS]: {
    id: tableIds.WORKORDER_COMMUNICATIONS,
    isLoadingData: false,
  },
  [tableIds.SEARCH_AND_CREATE_HISTORICAL_COMMUNICATIONS]: {
    id: tableIds.SEARCH_AND_CREATE_HISTORICAL_COMMUNICATIONS,
    isLoadingData: false,
  },
  [tableIds.WORKORDER_GENERAL_VIEW]: {
    id: tableIds.WORKORDER_GENERAL_VIEW,
    isLoadingData: false,
  },
  [tableIds.DUPLICATE_GENERIC_ORDER]: {
    id: tableIds.DUPLICATE_GENERIC_ORDER,
    isLoadingData: false,
  },
  [tableIds.SEARCH_AND_CREATE_HISTORICAL_WORKORDERS]: {
    id: tableIds.SEARCH_AND_CREATE_HISTORICAL_WORKORDERS,
    isLoadingData: false,
  },
  [tableIds.ACCIDENTS]: {
    id: tableIds.ACCIDENTS,
    isLoadingData: false,
  },
  [tableIds.CAMPAIGNS]: {
    id: tableIds.CAMPAIGNS,
    isLoadingData: false,
  },
  [tableIds.MULTIMEDIA]: {
    id: tableIds.MULTIMEDIA,
    isLoadingData: false,
  },
  [tableIds.GENERIC_ORDER_MODAL]: {
    id: tableIds.GENERIC_ORDER_MODAL,
    isLoadingData: false,
  },
  [tableIds.CAMPAIGN_MODAL]: {
    id: tableIds.CAMPAIGN_MODAL,
    isLoading: false,
  },

  [tableIds.VALIDATION_ACTIONS]: {
    id: tableIds.VALIDATION_ACTIONS,
    isLoadingData: false,
  },
};

type actionType = {
  type: string;
  payload: {
    id: string;
    isLoadingData: boolean;
  };
};

export default function loadingData(
  state = initialLoadingDataState,
  { type, payload }: actionType
) {
  switch (type) {
    case LOADING_DATA:
      return {
        ...state,
        [payload.id]: {
          id: payload.id,
          isLoadingData: payload.isLoadingData,
        },
      };
    default:
      return state;
  }
}

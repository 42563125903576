import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import isEqual from 'lodash.isequal';

// Styled Components
import {
  Container,
  RowContainer,
  Title,
  SmallTitle,
  ColumnsContainer,
  Column,
  RowSubContainerBottom,
  ContainerButtons,
  Tag,
  ColumnTitleContainer,
  RowSubContainerLeft,
  RowSubContainerRight,
  RowContainerLast,
  ColumnsContainerDouble,
  TitleTagContainer,
  InputTextAreaStyled,
  ChangeStateTag,
} from './styles';

// Media
import { useIsLandscapeTablet } from '../../../hooks/useMediaBreakPoints';

// Components
import InputCotic from '../../inputs/InputCotic';
import { Definition } from '../Definition';
import { MainButton } from '../../inputs/buttons/MainButton';

// Types
import { ContentCardResumeProps, marginArrow, directionArrow } from './types';
import { CommunicationSendStatus } from '../../../types/communication';
import { UNSPECIFIC } from '../../../types/utils';

// Action
import { getCurrentIndexTabFormByTab } from '../../../state/selectors/ui/tabs';
import { tabsType } from '../../../state/reducers/ui/tabs';
import { variant } from '../SpecialTag/types';

import { openModal } from '../../../state/actions/ui/modal';
import { modalIds } from '../../modals/SuperModal/types';

export default function ContentCardResume({
  title,
  cotic,
  operator,
  data,
  hour,
  groupInter,
  media,
  sendStatus,
  capitol,
  subCapitol,
  classification,
  team,
  concession,
  road,
  pkInit,
  pkEnd,
  track,
  city,
  roadDirections,
  direction,
  margin,
  lane,
  leftButton,
  rightButton,
  tag,
  tagContent,
  description,
  onEditCommunication,
  onSaveCommunication,
  storedDataOTSelected,
  changeStateTag,
  state,
  revalidatorFunction,
  orderId,
  disabled,
  ...props
}: ContentCardResumeProps): JSX.Element {
  const isLandscape = useIsLandscapeTablet();

  const selectorIndexTabForm = useSelector(
    getCurrentIndexTabFormByTab(tabsType.COMMUNICATION),
    isEqual
  );
  const dispatch = useDispatch();

  const openModalChangeState = () => {
    dispatch(
      openModal(modalIds.CHANGE_STATE_MODAL, {
        title:
          "Vols canviar l'estat de treball i tot allò que estigui associat?",
        titleSaveModal:
          'Estàs segur que vols tancar tot el que estigui associat a aquesta communicació?',
        indexExpedition: Number(cotic[2]),
        revalidatorFunction,
        currentState: state,
        orderId: orderId,
      })
    );
  };

  return (
    <Container {...props}>
      <TitleTagContainer>
        <Title size="L" variant="semiBold">
          {title}
        </Title>
        {tag && <Tag variant={tag} title={tagContent} />}
        {changeStateTag && (
          <ChangeStateTag
            variant={variant.Default}
            title={"Canviar l'estat"}
            onClick={openModalChangeState}
          />
        )}
      </TitleTagContainer>
      <RowContainer>
        <InputCotic
          label="COTIC"
          cotic={
            selectorIndexTabForm === 1 ? storedDataOTSelected?.cotic : cotic
          }
          disabled
          onChangeInputCotic={() => {
            null;
          }}
        />
      </RowContainer>
      <RowContainer>
        <RowSubContainerLeft>
          <ColumnTitleContainer>
            <SmallTitle size={isLandscape ? 'XS' : 'M'} variant="semiBold">
              Informació bàsica i de contacte
            </SmallTitle>
            <ColumnsContainerDouble>
              <Column>
                <Definition title="Operador" value={operator} />
                <Definition title="Data de registre" value={data} />
                <Definition title="Hora de registre" value={hour} />
              </Column>
              <Column>
                {groupInter && (
                  <Definition title="Grup interlocutor" value={groupInter} />
                )}
                {media && <Definition title="Medi" value={media} />}
                {sendStatus !== undefined && (
                  <Definition
                    title="Enviada/Rebuda"
                    value={
                      CommunicationSendStatus.SENT === sendStatus
                        ? 'Enviada'
                        : 'Rebuda'
                    }
                  />
                )}
              </Column>
            </ColumnsContainerDouble>
          </ColumnTitleContainer>
        </RowSubContainerLeft>
        <RowSubContainerRight>
          <ColumnTitleContainer>
            <SmallTitle size={isLandscape ? 'XS' : 'M'} variant="semiBold">
              Ordre de Treball i assignació
            </SmallTitle>

            <Column>
              <Definition
                title="Capitol"
                value={
                  selectorIndexTabForm === 0
                    ? capitol
                    : storedDataOTSelected?.capitol || ''
                }
              />
              <Definition
                title="Subcapitol"
                value={
                  selectorIndexTabForm === 0
                    ? subCapitol
                    : storedDataOTSelected?.subCapitol || ''
                }
              />
              <Definition
                title="Classificació"
                value={
                  selectorIndexTabForm === 0
                    ? classification
                    : storedDataOTSelected?.classification || ''
                }
              />
              {team && selectorIndexTabForm === 0 && (
                <Definition title="Equip" value={team} />
              )}
            </Column>
          </ColumnTitleContainer>
        </RowSubContainerRight>
      </RowContainer>
      <RowSubContainerBottom>
        <ColumnTitleContainer>
          <SmallTitle size={isLandscape ? 'XS' : 'M'} variant="semiBold">
            Descripció de la OT
          </SmallTitle>
          <ColumnsContainer>
            <Column>
              <Definition title="Concessió" value={concession} />
              <Definition title="Carretera" value={road} />
              <Definition title="Pk inicial" value={pkInit} />
              <Definition title="Pk final" value={pkEnd} />
            </Column>
            <Column>
              <Definition title="Tipus de vial" value={track} />
              {direction && direction !== UNSPECIFIC && (
                <Definition
                  title="Sentit"
                  value={`${
                    direction === 'both' || !roadDirections[0]
                      ? city
                      : direction === 'down'
                      ? roadDirections[0]
                      : roadDirections[roadDirections.length - 1]
                  } ${directionArrow[direction]}`}
                />
              )}
              {margin && margin !== UNSPECIFIC && (
                <Definition title="Marge" value={marginArrow[margin]} />
              )}
              <Definition title="Carril" value={lane} />
            </Column>
          </ColumnsContainer>
        </ColumnTitleContainer>
      </RowSubContainerBottom>
      <RowContainerLast>
        <InputTextAreaStyled label="Descripció:" disabled value={description} />
        <ContainerButtons onSubmit={onSaveCommunication}>
          {leftButton && (
            <MainButton
              type="button"
              variant="secondary"
              text={leftButton}
              onClick={onEditCommunication}
              disabled={disabled}
            />
          )}
          {rightButton && <MainButton type="submit" text={rightButton} />}
        </ContainerButtons>
      </RowContainerLast>
    </Container>
  );
}

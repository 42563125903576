import React, { useCallback, useRef, useState } from 'react';
import {
  Controller,
  ControllerProps,
  useForm,
  useWatch,
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Sentry from '@sentry/react';

// Types
import { MaterialManagement, Props } from './types';

// Schema
import { MaterialManagementSchema } from './schema';

// Styles
import { Container, InputsContainer, AddMaterialButton } from './styles';

// Components
import InputText from '../../inputs/InputText';

// Utils
import { useCacheFormInSessionStorage } from '../../../hooks/useCacheForm';
import { Typography } from '../../display/Typography';
import DropDownInput from '../../inputs/DropDown/DropDownInput';
import { OptionSelected } from '../../inputs/DropDown/DropDownList/types';
import { GET_SUBCODIFICATION_BY_ID } from '../../../gql/queries/actions/subCodification';
import { client } from '../../../gql/client';
import { ImperativeRefInput } from '../../inputs/DropDown/DropDownInput/types';

export const AddMaterialManagementForm = ({
  onFormSubmit,
  namingPersistForm,
  initialData,
  data,
}: Props) => {
  const allData = { ...initialData, ...data };
  const {
    handleSubmit,
    control,
    register,
    setValue,
    formState: { errors },
    reset,
  } = useForm<
    MaterialManagement & {
      codifications: {
        id: string;
        name: string;
        subCodificationIDs: string[];
      }[];
      subCodifications: { id: string; name: string }[];
      measureTypes: { id: string; name: string }[];
      materialsData: MaterialManagement[];
    }
  >({
    defaultValues: allData,
    resolver: yupResolver(MaterialManagementSchema),
  });

  const subCodifications = useWatch({
    control,
    name: 'subCodifications',
  });

  const [materialRepeatedError, setMaterialRepeatedError] = useState<
    null | string
  >(null);

  const subCodificationRef = useRef<ImperativeRefInput>(null);
  const codificationRef = useRef<ImperativeRefInput>(null);
  const measureTypesRef = useRef<ImperativeRefInput>(null);

  useCacheFormInSessionStorage(namingPersistForm, control);

  const onSelectCodification = useCallback(
    (
      option: OptionSelected | undefined,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onChange: (...event: any[]) => void
    ) => {
      const tasks = async () => {
        try {
          const subCodificationOptions = await Promise.all(
            option?.data.map(async (subCodificationData: string) => {
              const {
                data: { subCodification },
              } = await client.query({
                query: GET_SUBCODIFICATION_BY_ID,
                variables: {
                  id: subCodificationData,
                },
                fetchPolicy: 'cache-only',
              });
              return subCodification ?? [];
            })
          );
          const sortedElements = [...subCodificationOptions].sort((a, b) =>
            a.name.localeCompare(b.name)
          );
          setValue('subCodifications', sortedElements);
          subCodificationRef.current?.clearSelected();
          setValue('subCodification', '');

          // elementOrdersDropDownRef.current?.clearSelected();
        } catch (err) {
          Sentry.captureException(err);
          console.log(err);
        }

        onChange(option?.value ?? '');
      };
      tasks().catch((err) => {
        Sentry.captureException(err);
        console.log(err);
      });
    },
    []
  );

  const onSubmit = async (data: MaterialManagement) => {
    const errorMessage = await onFormSubmit(data);
    if (errorMessage) {
      setMaterialRepeatedError(errorMessage);
    } else {
      reset({
        name: '',
        codification: '',
        subCodification: '',
        typeOfMeasurement: '',
      });
      codificationRef.current?.clearSelected();
      measureTypesRef.current?.clearSelected();
      subCodificationRef.current?.clearSelected();
      setMaterialRepeatedError(null);
    }
  };
  const renderSubCodification = useCallback<ControllerProps['render']>(
    ({ field: { onChange }, formState: { defaultValues } }) => {
      const options =
        subCodifications?.map((subCodification) => ({
          label: subCodification?.name || '',
          value: subCodification?.id || '',
        })) || [];
      return (
        <DropDownInput
          ref={subCodificationRef}
          inputSize="M"
          onChangeSelected={(option) => onChange(option?.value ?? '')}
          typeDropDown="Default"
          labelText="Subcodificació"
          placeholder="Selecciona"
          defaultValue={defaultValues?.subCodification}
          borderError={!!errors.subCodification?.message}
          options={options}
        />
      );
    },
    [subCodifications]
  );

  return (
    <Container onSubmit={handleSubmit(onSubmit)}>
      <Typography size="L" variant="semiBold">
        Afegir material
      </Typography>

      <InputsContainer>
        <InputText
          {...register('name')}
          label="Nom"
          typeInput={'normal'}
          inputSize="16"
          placeholder="Nom"
          borderError={!!errors.name?.message}
        />
        <Controller
          control={control}
          name="codification"
          render={({ field: { onChange }, formState: { defaultValues } }) => {
            const options =
              data.codifications?.map(
                (codification: {
                  name: string;
                  id: string;
                  subCodificationIDs: string[];
                }) => ({
                  label: codification?.name || '',
                  value: codification?.id || '',
                  data: codification.subCodificationIDs || [],
                })
              ) || [];

            return (
              <DropDownInput
                ref={codificationRef}
                inputSize="M"
                onChangeSelected={(option) =>
                  onSelectCodification(option, onChange)
                }
                typeDropDown="Default"
                labelText="Codificació"
                placeholder="Selecciona"
                defaultValue={defaultValues?.codification}
                borderError={!!errors.codification?.message}
                options={options}
              />
            );
          }}
        />

        <Controller
          control={control}
          name="subCodification"
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          render={renderSubCodification as any}
        />
        <Controller
          control={control}
          name="typeOfMeasurement"
          render={({ field: { onChange }, formState: { defaultValues } }) => {
            const options =
              data.measureTypes?.map((typeOfMeasurement) => ({
                label: typeOfMeasurement?.name || '',
                value: typeOfMeasurement?.id || '',
              })) || [];

            return (
              <DropDownInput
                ref={measureTypesRef}
                inputSize="M"
                onChangeSelected={(option) => onChange(option?.value ?? '')}
                typeDropDown="Default"
                labelText="Tipus de mesura"
                placeholder="Selecciona"
                defaultValue={defaultValues?.typeOfMeasurement}
                borderError={!!errors.typeOfMeasurement?.message}
                options={options}
              />
            );
          }}
        />

        <AddMaterialButton>⮕</AddMaterialButton>
        {materialRepeatedError && (
          <Typography colorText="lightCoralRed" size="XS">
            {materialRepeatedError}
          </Typography>
        )}
      </InputsContainer>
    </Container>
  );
};

import React, { useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

// Types
import { MachineryManagement, Props } from './types';

// Schema
import { MachineryManagementSchema } from './schema';

// Styles
import { AddMachineryButton, Container, InputsContainer } from './styles';

// Components
import InputText from '../../inputs/InputText';
import DropDownInput from '../../inputs/DropDown/DropDownInput';

// Utils
import { useCacheFormInSessionStorage } from '../../../hooks/useCacheForm';
import { Typography } from '../../display/Typography';
import { ImperativeRefInput } from '../../inputs/DropDown/DropDownInput/types';

export const AddMachineryManagementForm = ({
  onFormSubmit,
  namingPersistForm,
  initialData,
  data,
}: Props) => {
  const {
    handleSubmit,
    control,
    register,
    formState: { errors },
    reset,
  } = useForm<MachineryManagement>({
    defaultValues: {
      ...initialData,
    },
    resolver: yupResolver(MachineryManagementSchema),
  });
  useCacheFormInSessionStorage(namingPersistForm, control);

  const [machineryError, setMachineryError] = useState<string | null>(null);

  const auxMachineTypeRef = useRef<ImperativeRefInput>(null);
  const measurementTypeRef = useRef<ImperativeRefInput>(null);

  const onSubmit = async (data: MachineryManagement) => {
    const errorMessage = await onFormSubmit(data);
    if (errorMessage) {
      setMachineryError(errorMessage);
    } else {
      reset({
        name: '',
        type: '',
        typeOfMeasurement: '',
      });
      auxMachineTypeRef.current?.clearSelected();
      measurementTypeRef.current?.clearSelected();
      setMachineryError(null);
    }
  };

  return (
    <Container onSubmit={handleSubmit(onSubmit)}>
      <Typography size="L" variant="semiBold">
        Afegir maquinària
      </Typography>

      <InputsContainer>
        <InputText
          {...register('name')}
          label="Nom"
          typeInput={'normal'}
          inputSize="16"
          placeholder="Nom"
          borderError={!!errors.name?.message}
        />

        <Controller
          control={control}
          name="type"
          render={({ field: { onChange }, formState: { defaultValues } }) => {
            const options =
              data.auxMachineTypes?.map((type) => ({
                label: type?.name || '',
                value: type?.id || '',
              })) || [];

            return (
              <DropDownInput
                ref={auxMachineTypeRef}
                inputSize="M"
                onChangeSelected={(option) => onChange(option?.value ?? '')}
                typeDropDown="Default"
                labelText="Tipus de maquinària"
                placeholder="Selecciona"
                defaultValue={defaultValues?.type}
                borderError={!!errors.type?.message}
                options={options}
              />
            );
          }}
        />

        <Controller
          control={control}
          name="typeOfMeasurement"
          render={({ field: { onChange }, formState: { defaultValues } }) => {
            const options =
              data.measureTypes?.map((typeOfMeasurement) => ({
                label: typeOfMeasurement?.name || '',
                value: typeOfMeasurement?.id || '',
              })) || [];

            return (
              <DropDownInput
                ref={measurementTypeRef}
                inputSize="M"
                onChangeSelected={(option) => onChange(option?.value ?? '')}
                typeDropDown="Default"
                labelText="Tipus de mesura"
                placeholder="Selecciona"
                defaultValue={defaultValues?.typeOfMeasurement}
                borderError={!!errors.typeOfMeasurement?.message}
                options={options}
              />
            );
          }}
        />
        <AddMachineryButton>⮕</AddMachineryButton>
        {machineryError && (
          <Typography colorText="lightCoralRed" size="XS">
            {machineryError}
          </Typography>
        )}
      </InputsContainer>
    </Container>
  );
};

import { gql } from '@apollo/client';

export const FETCH_ACTIVITYS = gql`
  query FetchActivitys {
    activitys {
      id
      name
      actionIDs
    }
  }
`;

export const GET_ACTIVITY_BY_ID = gql`
  query GetActivityById($id: ID!) {
    activity(id: $id) {
      id
      name
      actionIDs
    }
  }
`;

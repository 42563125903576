import { object, string } from 'yup';

// Types
import { MaterialManagement } from './types';

export const MaterialManagementSchema = object<MaterialManagement>({
  name: string().required(),
  codification: string().required(),
  subCodification: string().required(),
  typeOfMeasurement: string().required(),
});

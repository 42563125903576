import React from 'react';
import { useAsyncValue, useSubmit } from 'react-router-dom';

// Components
import AnimalsForm from '../../../../../components/forms/ActionForms/AnimalsForm';

// Types
import { NewAnimal } from '../../../../../components/display/tables/formTables/AnimalSelectionTable/types';

// Utils
import {
  createFormDataFromValues,
  parseValueFromObjectOfObjects,
} from '../../../../../utils/forms';

// Constants
import { formActionNaming } from '../../../../../components/forms/ActionForms/constants';

export default function AnimalsFormPage(): JSX.Element {
  const submit = useSubmit();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const loaderData = useAsyncValue() as any;

  const onFormSubmit = (values: { [key: string]: NewAnimal }) => {
    const parsedValues = parseValueFromObjectOfObjects(values);
    const animalsFormData = createFormDataFromValues(parsedValues);

    submit(animalsFormData, {
      method: 'POST',
      action: '.',
    });
  };

  return (
    <AnimalsForm
      onFormSubmit={onFormSubmit}
      initialData={loaderData}
      sessionStorageKey={formActionNaming.NEW_ANIMALS_FORM}
    />
  );
}

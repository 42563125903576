// Types
import { DataState } from './types';

// Utils
import {
  compressSessionStorage,
  decompressSessionStorage,
} from '../../../utils/sessionStorage';

// FOR ACTION TABLES (MATERIAL< ANIMALS< MACHINES)

export const handleDelete = (
  id: string,
  unitId: string,
  cacheSpecificKey: string,
  updateKey: string
) => {
  const previousChanges = sessionStorage.getItem(updateKey)
    ? JSON.parse(
        decompressSessionStorage(sessionStorage.getItem(updateKey) as string)
      )
    : {};

  const addedData = previousChanges[cacheSpecificKey]?.[DataState.added];
  const updatedData = previousChanges[cacheSpecificKey]?.[DataState.updated];

  if (
    (Array.isArray(addedData) && addedData.some((item) => item.id === id)) ||
    (Array.isArray(updatedData) && updatedData.some((item) => item.id === id))
  ) {
    const indexToDeleteAdded = addedData?.findIndex(
      (item: { id: string }) => item.id === id
    );
    const indexToDeleteUpdated = updatedData?.findIndex(
      (item: { id: string }) => item.id === id
    );

    if (indexToDeleteAdded !== undefined && indexToDeleteAdded !== -1) {
      addedData.splice(indexToDeleteAdded, 1);
    } else if (indexToDeleteUpdated !== -1) {
      updatedData.splice(indexToDeleteAdded, 1);
    }

    sessionStorage.setItem(
      updateKey,
      compressSessionStorage(JSON.stringify(previousChanges))
    );

    return;
  }
  const newItem = {
    id,
    unitId,
    state: DataState.deleted,
  };

  addToDataChanged(newItem, updateKey, cacheSpecificKey);
};

export const handleAdd = (
  id: string,
  cacheSpecificKey: string,
  updateKey: string,
  statusId?: string
) => {
  const item = {
    id,
    state: DataState.added,
    statusId,
  };
  addToDataChanged(item, updateKey, cacheSpecificKey);
};

export const handleUpdate = (
  eventValue: string | number,
  id: string,
  unitId: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  initialData: any,
  cacheSpecificKey: string,
  dataFromServerKey: string,
  updateKey: string,
  statusId?: string
) => {
  const currentValue = eventValue;
  const serverValue = initialData.dataFromServer[dataFromServerKey][id]?.ut;
  const serverValueAnimalStatus =
    initialData.dataFromServer[dataFromServerKey][id]?.statusId;
  const currentCacheData = sessionStorage.getItem(updateKey)
    ? JSON.parse(
        decompressSessionStorage(sessionStorage.getItem(updateKey) as string)
      )
    : {};

  if (
    currentValue &&
    currentCacheData?.[cacheSpecificKey]?.[DataState.added]?.some(
      (item: { id: string }) => item.id === id
    )
  ) {
    const indexToUpdate = currentCacheData[cacheSpecificKey][
      DataState.added
    ].findIndex((item: { id: string }) => item.id === id);

    currentCacheData[cacheSpecificKey][DataState.added][indexToUpdate].unit =
      Number(currentValue);

    sessionStorage.setItem(
      updateKey,
      compressSessionStorage(JSON.stringify(currentCacheData))
    );

    return;
  }

  if (
    serverValue &&
    currentValue === serverValue &&
    currentCacheData?.[cacheSpecificKey]?.[DataState.updated].some(
      (item: { id: string }) => item.id === id
    )
  ) {
    const indexToDelete = currentCacheData[cacheSpecificKey][
      DataState.updated
    ].findIndex((item: { id: string }) => item.id === id);

    currentCacheData?.[cacheSpecificKey]?.[DataState.updated].splice(
      indexToDelete,
      1
    );

    sessionStorage.setItem(
      updateKey,
      compressSessionStorage(JSON.stringify(currentCacheData))
    );

    return;
  }
  if (
    (serverValue && currentValue !== serverValue) ||
    (statusId && serverValueAnimalStatus !== statusId)
  ) {
    if (
      Array.isArray(currentCacheData[cacheSpecificKey]?.[DataState.updated]) &&
      currentCacheData[cacheSpecificKey][DataState.updated].some(
        (item: { id: string }) => item.id === id
      )
    ) {
      const indexToUpdate = currentCacheData[cacheSpecificKey][
        DataState.updated
      ].findIndex((item: { id: string }) => item.id === id);

      currentCacheData[cacheSpecificKey][DataState.updated][
        indexToUpdate
      ].newValue = Number(currentValue);

      sessionStorage.setItem(
        updateKey,
        compressSessionStorage(JSON.stringify(currentCacheData))
      );
    } else {
      const itemUpdated = {
        id,
        unitId,
        state: DataState.updated,
        newValue: Number(currentValue),
        statusId,
      };

      addToDataChanged(itemUpdated, updateKey, cacheSpecificKey);
    }
  }
};

const addToDataChanged = (
  item: {
    id: string;
    state: string;
    unitId?: string;
    newValue?: number;
    statusId?: string;
  },
  sessionStorageUpdateKey: string,
  cacheSpecificKey: string
) => {
  const currentCacheData = sessionStorage.getItem(sessionStorageUpdateKey)
    ? JSON.parse(
        decompressSessionStorage(
          sessionStorage.getItem(sessionStorageUpdateKey) as string
        )
      )
    : {};

  if (item.state === DataState.added) {
    const deletedArray =
      currentCacheData[cacheSpecificKey]?.[DataState.deleted] || [];
    if (deletedArray.includes(item.id)) {
      const filteredDeletedArray = deletedArray.filter(
        (id: string) => id !== item.id
      );
      currentCacheData[cacheSpecificKey][DataState.deleted] =
        filteredDeletedArray;

      sessionStorage.setItem(
        sessionStorageUpdateKey,
        compressSessionStorage(JSON.stringify(currentCacheData))
      );

      return;
    }
  }

  if (item.state === DataState.deleted) {
    Array.isArray(currentCacheData[cacheSpecificKey]?.[DataState.deleted])
      ? currentCacheData[cacheSpecificKey][DataState.deleted].push(item.unitId)
      : (currentCacheData[cacheSpecificKey] = {
          ...currentCacheData[cacheSpecificKey],
          [item.state]: [item.unitId],
        });
  } else {
    Array.isArray(currentCacheData[cacheSpecificKey]?.[item.state])
      ? currentCacheData[cacheSpecificKey][item.state].push(item)
      : (currentCacheData[cacheSpecificKey] = {
          ...currentCacheData[cacheSpecificKey],
          [item.state]: [item],
        });
  }

  sessionStorage.setItem(
    sessionStorageUpdateKey,
    compressSessionStorage(JSON.stringify(currentCacheData))
  );
};

// FOR VEHICLES TABLE (DRIVERS AND VEHICLES)

export const handleAddVehicle = (
  vehicleId: string,
  cacheSpecificKey: string,
  updateKey: string,
  vehicleOccupantId?: string,
  remove?: boolean
) => {
  const currentCacheData = sessionStorage.getItem(updateKey)
    ? JSON.parse(
        decompressSessionStorage(sessionStorage.getItem(updateKey) as string)
      )
    : {};

  const addedItems = currentCacheData[cacheSpecificKey]?.[DataState.added];
  const deletedItems = currentCacheData[cacheSpecificKey]?.[DataState.deleted];

  if (!remove && vehicleOccupantId) {
    currentCacheData[cacheSpecificKey][DataState.deleted] = (
      deletedItems || []
    ).filter(
      (item: { vehicleOccupantId: string }) =>
        item.vehicleOccupantId !== vehicleOccupantId
    );

    sessionStorage.setItem(
      updateKey,
      compressSessionStorage(JSON.stringify(currentCacheData))
    );

    return;
  }

  if (remove && !vehicleOccupantId) {
    currentCacheData[cacheSpecificKey][DataState.added] = addedItems?.filter(
      (items: { vehicleId: string }) => items.vehicleId !== vehicleId
    );

    sessionStorage.setItem(
      updateKey,
      compressSessionStorage(JSON.stringify(currentCacheData))
    );
  }

  if (remove && vehicleOccupantId) {
    const idToDelete = deletedItems?.findIndex(
      (item: { vehicleOccupantId: string }) =>
        item.vehicleOccupantId === vehicleOccupantId
    );

    if (idToDelete !== undefined && idToDelete !== -1) {
      currentCacheData[cacheSpecificKey][DataState.deleted][
        idToDelete
      ].vehicleConfirmed = true;
    } else {
      currentCacheData[cacheSpecificKey] ??= {};
      currentCacheData[cacheSpecificKey][DataState.deleted] ??= [];
      currentCacheData[cacheSpecificKey][DataState.deleted].push({
        vehicleConfirmed: true,
        vehicleOccupantId,
      });
    }

    sessionStorage.setItem(
      updateKey,
      compressSessionStorage(JSON.stringify(currentCacheData))
    );
    return;
  }

  if (vehicleOccupantId && deletedItems) {
    const idToDelete = deletedItems.findIndex(
      (item: { vehicleOccupantId: string }) =>
        item.vehicleOccupantId === vehicleOccupantId
    );

    currentCacheData[cacheSpecificKey][DataState.deleted][
      idToDelete
    ].vehicleId = '';
    sessionStorage.setItem(
      updateKey,
      compressSessionStorage(JSON.stringify(currentCacheData))
    );
    return;
  }

  if (
    addedItems &&
    addedItems.some(
      (item: { vehicleId: string }) => item.vehicleId === vehicleId
    )
  ) {
    return;
  }

  const item = {
    vehicleId,
    state: DataState.added,
  };

  addToVehicleDataChanged(item, updateKey, cacheSpecificKey);
};

export const handleDeleteVehicle = (
  cacheSpecificKey: string,
  vehicleOccupantId: string,
  updateKey: string
) => {
  const currentCacheData = sessionStorage.getItem(updateKey)
    ? JSON.parse(
        decompressSessionStorage(sessionStorage.getItem(updateKey) as string)
      )
    : {};

  const deletedItems = currentCacheData[cacheSpecificKey]?.[DataState.deleted];

  if (
    deletedItems &&
    deletedItems.some((item: string) => item === vehicleOccupantId)
  ) {
    return;
  }

  const itemIndex = deletedItems?.findIndex(
    (item: { vehicleOccupantId: string }) =>
      item.vehicleOccupantId === vehicleOccupantId
  );

  if (itemIndex !== undefined && itemIndex !== -1) {
    currentCacheData[cacheSpecificKey][DataState.deleted][
      itemIndex
    ].driverConfirmed = true;
  } else {
    currentCacheData[cacheSpecificKey] ??= {};
    currentCacheData[cacheSpecificKey][DataState.deleted] ??= [];
    currentCacheData[cacheSpecificKey][DataState.deleted].push({
      driverConfirmed: true,
      vehicleOccupantId,
    });
  }

  sessionStorage.setItem(
    updateKey,
    compressSessionStorage(JSON.stringify(currentCacheData))
  );
  return;
};

export const handleUpdateVehicle = (
  vehicleId: string,
  driverId: string,
  cacheSpecificKey: string,
  updateKey: string,
  vehicleOccupantId?: string,
  remove?: boolean
) => {
  const currentCacheData = sessionStorage.getItem(updateKey)
    ? JSON.parse(
        decompressSessionStorage(sessionStorage.getItem(updateKey) as string)
      )
    : {};

  const updatedItems = currentCacheData[cacheSpecificKey]?.[DataState.updated];
  if (remove) {
    if (updatedItems) {
      currentCacheData[cacheSpecificKey][DataState.updated] =
        updatedItems.filter(
          (items: { vehicleOccupantId: string }) =>
            items.vehicleOccupantId !== vehicleOccupantId
        );
    }

    sessionStorage.setItem(
      updateKey,
      compressSessionStorage(JSON.stringify(currentCacheData))
    );

    return;
  }

  const addedItems = currentCacheData[cacheSpecificKey]?.[DataState.added];
  const indexToUpdateFromAddedArray = addedItems?.findIndex(
    (item: { vehicleId: string }) => item.vehicleId === vehicleId
  );

  if (
    addedItems &&
    indexToUpdateFromAddedArray !== undefined &&
    indexToUpdateFromAddedArray !== -1 &&
    !vehicleOccupantId
  ) {
    currentCacheData[cacheSpecificKey][DataState.added][
      indexToUpdateFromAddedArray
    ].driverId = driverId;

    sessionStorage.setItem(
      updateKey,
      compressSessionStorage(JSON.stringify(currentCacheData))
    );

    return;
  }

  if (!vehicleOccupantId) {
    const itemAdded = {
      vehicleId,
      driverId,
      state: DataState.added,
    };

    addToVehicleDataChanged(itemAdded, updateKey, cacheSpecificKey);

    return;
  }

  const indexToUpdate = updatedItems?.findIndex(
    (item: { vehicleOccupantId: string }) =>
      item.vehicleOccupantId === vehicleOccupantId
  );

  if (indexToUpdate !== undefined && indexToUpdate !== -1) {
    currentCacheData[cacheSpecificKey][DataState.updated][
      indexToUpdate
    ].driverId = driverId;

    sessionStorage.setItem(
      updateKey,
      compressSessionStorage(JSON.stringify(currentCacheData))
    );

    return;
  }

  const item = {
    vehicleId,
    driverId,
    vehicleOccupantId,
    state: DataState.updated,
  };

  addToVehicleDataChanged(item, updateKey, cacheSpecificKey);
};

export const addToVehicleDataChanged = (
  item: {
    vehicleId?: string;
    state: string;
    driverId?: string;
    vehicleOccupantId?: string;
  },
  updateKey: string,
  cacheSpecificKey: string
) => {
  const currentCacheData = sessionStorage.getItem(updateKey)
    ? JSON.parse(
        decompressSessionStorage(sessionStorage.getItem(updateKey) as string)
      )
    : {};

  if (item.state === DataState.added) {
    const deletedItemsArray =
      currentCacheData[cacheSpecificKey]?.[DataState.deleted];

    if (deletedItemsArray && deletedItemsArray.includes(item.vehicleId)) {
      const filteredDeletedArray = deletedItemsArray.filter(
        (id: string) => id !== item.vehicleId
      );

      currentCacheData[cacheSpecificKey][DataState.deleted] =
        filteredDeletedArray;

      sessionStorage.setItem(
        updateKey,
        compressSessionStorage(JSON.stringify(currentCacheData))
      );

      return;
    }
  }

  Array.isArray(currentCacheData[cacheSpecificKey]?.[item.state])
    ? currentCacheData[cacheSpecificKey][item.state].push(item)
    : (currentCacheData[cacheSpecificKey] = {
        ...currentCacheData[cacheSpecificKey],
        [item.state]: [item],
      });

  sessionStorage.setItem(
    updateKey,
    compressSessionStorage(JSON.stringify(currentCacheData))
  );
};

import { gql } from '@apollo/client';

export const CREATE_NEW_ANIMAL_UNIT = gql`
  mutation CreateAnimalUnit($input: NewAnimalUnit!) {
    createAnimalUnit(input: $input) {
      id
      unit
      actionIDs
      animalIDs
    }
  }
`;

export const UPDATE_MANY_ANIMAL_UNITS = gql`
  mutation UpdateManyAnimals($input: [UpdateAnimalUnit]) {
    updateManyAnimalUnits(input: $input) {
      id
      unit
      actionIDs
      animalIDs
      animalStatusIDs
    }
  }
`;

export const DELETE_MANY_ANIMAL_UNITS = gql`
  mutation DeleteManyAnimalUnits($ids: [ID!]) {
    deleteManyAnimalUnits(ids: $ids) {
      id
    }
  }
`;

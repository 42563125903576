import styled from 'styled-components';

// Styles generals
import {
  spaces,
  breakPoints,
  percentBaseResponsive,
} from '../../../../styles/spaces';

import { Container } from '../components/MainStyledComponents';

export const ContainerStyled = styled(Container)`
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  border-radius: ${spaces.s}rem;
`;

export const ContainerTag = styled.div`
  position: absolute;
  top: 4.6rem;
  left: 72.9rem;
  @media (max-width: ${breakPoints.smallDesktop * percentBaseResponsive}rem) {
    top: 0.6rem;
    left: 66.5rem;
  }
  @media (max-width: ${breakPoints.landscapeTablet *
    percentBaseResponsive}rem) {
    top: 0.5rem;
    left: 80.8rem;
  }
  @media (max-width: ${breakPoints.portraitTablet * percentBaseResponsive}rem) {
    top: 6.5rem;
    left: 44.9rem;
  }
`;

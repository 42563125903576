import format from 'date-fns/format';
import {
  compressSessionStorage,
  decompressSessionStorage,
} from './sessionStorage';

// Sentry
import * as Sentry from '@sentry/react';

export function fileToBase64(file: File): Promise<string> {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      const base64String = reader.result as string;
      resolve(base64String);
    };
    reader.onerror = (error) => {
      reject(error);
    };
    reader.readAsDataURL(file);
  });
}

export function getFilesFromSessionStorage(
  sessionStorageKey: string
): File[] | null {
  const filesDataJSON = sessionStorage.getItem(sessionStorageKey);
  if (filesDataJSON) {
    const filesData = JSON.parse(decompressSessionStorage(filesDataJSON)) as {
      name: string;
      content: string;
    }[];
    const files: File[] = filesData.map((fileData) => {
      const binaryString = atob(fileData.content.split(',')[1]);
      const byteArray = new Uint8Array(binaryString.length);
      for (let i = 0; i < binaryString.length; i++) {
        byteArray[i] = binaryString.charCodeAt(i);
      }
      const blob = new Blob([byteArray], {
        type: 'application/octet-stream',
      });
      return new File([blob], fileData.name);
    });
    return files;
  } else {
    return null;
  }
}

export async function saveFilesToSessionStorage(
  files: File[],
  sessionStorageKey: string
) {
  const filesToSave = files.map(async (file) => {
    const base64String = await fileToBase64(file);
    return {
      name: file.name,
      content: base64String,
    };
  });

  const filesData = [];
  for (const res of await Promise.all(filesToSave)) {
    filesData.push(res);
  }

  sessionStorage.setItem(
    sessionStorageKey,
    compressSessionStorage(JSON.stringify(filesData))
  );
}

export async function getSpecificFileFromDirectory(
  directoryName: string,
  fileName: string
): Promise<{ file: File; name: string } | null> {
  try {
    const opfsRoot = await navigator.storage.getDirectory();
    const picturesFolder = await opfsRoot.getDirectoryHandle(directoryName, {
      create: false,
    });

    const fileSystem = await picturesFolder.getFileHandle(fileName);
    const myFile = await fileSystem.getFile();
    return { file: myFile, name: myFile.name };
  } catch (err) {
    Sentry.captureException(err);
    console.log(err);
    return null;
  }
}

export async function saveFilesIntoADirectory(
  directoryName: string,
  filesToSave: File[]
) {
  const opfsRoot = await navigator.storage.getDirectory();
  const picturesFolder = await opfsRoot.getDirectoryHandle(directoryName, {
    create: true,
  });

  const storeFile = async (file: File, folder: FileSystemDirectoryHandle) => {
    const reader = new FileReader();
    return new Promise<void>((resolve, reject) => {
      reader.onload = async () => {
        const fileName = file.name;
        const fileHandle = (await folder.getFileHandle(fileName, {
          create: true,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        })) as any;
        const writable = await fileHandle.createWritable();
        await writable.write(reader.result);
        await writable.close();
        resolve();
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsArrayBuffer(file);
    });
  };

  await Promise.all(
    filesToSave.map(async (file) => {
      try {
        !(await getSpecificFileFromDirectory(directoryName, file.name)) &&
          (await storeFile(file, picturesFolder));
      } catch (err) {
        Sentry.captureException(err);
        console.log(err);
      }
    })
  );
}

export async function getFilesFromDirectory(
  directoryName: string
): Promise<{ file: File; id: string }[]> {
  const opfsRoot = await navigator.storage.getDirectory();
  const picturesFolder = await opfsRoot.getDirectoryHandle(directoryName, {
    create: false,
  });

  const filesSaved = [];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  for await (const [, file] of picturesFolder as unknown as any[]) {
    if (file.kind !== 'directory') {
      const fileToAssign = (await file.getFile()) as File;

      filesSaved.push({ file: fileToAssign, id: fileToAssign.name });
    }
  }

  return filesSaved;
}

export async function deleteFilesFromDirectory(
  directoryName: string,
  fileName: string
) {
  const opfsRoot = await navigator.storage.getDirectory();
  const picturesFolder = await opfsRoot.getDirectoryHandle(directoryName, {
    create: true,
  });

  await picturesFolder.removeEntry(fileName, { recursive: true });
}

export async function deleteWholeDirectory(directoryName: string) {
  const opfsRoot = await navigator.storage.getDirectory();
  const picturesFolder = await opfsRoot.getDirectoryHandle(directoryName, {
    create: true,
  });
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  for await (const [, file] of picturesFolder as unknown as any[]) {
    if (file.kind !== 'directory') {
      const fileToAssign = (await file.getFile()) as File;

      await picturesFolder.removeEntry(fileToAssign.name, { recursive: true });
    }
  }
}

export const generateFileName = (
  type: string,
  dataBaseNamingCaching: string
) => {
  let road = '';
  const roadRaw = sessionStorage.getItem(dataBaseNamingCaching);

  if (roadRaw) {
    road = JSON.parse(
      decompressSessionStorage(
        sessionStorage.getItem(dataBaseNamingCaching) as string
      )
    ).road;
  }

  const date = format(new Date(), 'dd_MM_yy-HH_mm_ss');

  return `${type}_${road ? road + '_' : road}${date}`;
};

export const dataURItoBlob = (dataURI: string) => {
  const byteString = atob(dataURI.split(',')[1]);
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);

  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ab], { type: mimeString });
};

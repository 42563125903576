import React, { useLayoutEffect } from 'react';
import { useAsyncValue, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

// Components
import LocationForm from '../../../../../components/forms/ActionForms/LocationForm';

// Constants
import { formActionNaming } from '../../../../../components/forms/ActionForms/constants';

// Types
import { type LocationFormValues } from '../../../../../components/forms/ActionForms/LocationForm/types';
import { type WorkOrder } from '../../../../../types/workOrder';
import { OrderType } from '../../../../../types/orders';

// Redux
import { coticOTSelected } from '../../../../../state/actions/ui/coticOTSelectedGeneralView';

export default function LocationFormPage(): JSX.Element {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loaderData = useAsyncValue() as LocationFormValues & WorkOrder;

  useLayoutEffect(() => {
    dispatch(
      coticOTSelected(
        OrderType.Action,
        loaderData.breadCrumbsData.orderParentCotic,
        loaderData.breadCrumbsData.orderParentId
      )
    );
  }, []);

  const onFormSubmit = () => {
    navigate('../edition');
  };

  return (
    <LocationForm
      onFormSubmit={onFormSubmit}
      initialData={loaderData}
      namingPersistForm={formActionNaming.NEW_LOCATION_FORM}
    />
  );
}

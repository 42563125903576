import { defer, LoaderFunction } from 'react-router-dom';

// Sentry
import * as Sentry from '@sentry/react';

// Schema
//import { newCommunicationSchema } from '../../../../components/forms/NewCommunicationForm/schema';
import { selectCommunicationOrderByIdOrder } from '../../../../../gql/selectors/communications';

// GraphQL
import { client } from '../../../../../gql/client';

const loadCommunicationSummary = async (idOrder: string) => {
  try {
    const orderCommunicationData = await selectCommunicationOrderByIdOrder(
      idOrder
    );

    return {
      ...orderCommunicationData,
      operator: `${orderCommunicationData?.userOperator.name} ${
        orderCommunicationData?.userOperator.surName.split(' ')[0]
      }`,
    };
  } catch (err) {
    Sentry.captureException(err);
    console.log('> Communication Summary loading error:', err);
    return true;
  }
};

export const LoaderCommunicationDuplicateSummary: LoaderFunction = async ({
  params,
}) => {
  if (!params.id) return null;
  if (Object.keys(client.cache.extract()).length === 0) return null;

  // As this is a Loader that communicates with an API, add the `defer` function to return data asynchronously
  // which combines with the Fallback component wrapping the page to show loaders properly
  const loaderToResolve = loadCommunicationSummary(params.id);
  return defer({ data: loaderToResolve });
};

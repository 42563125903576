import React from 'react';
import { Link } from 'react-router-dom';
import { useMedia } from 'react-use';

// Icons
import OraLOGO from '../../../assets/logo.svg';

// Components Styled
import { Container, Logo, MenuUser, ContainerMenuLogo } from './styles';

// Components
import SearchBar from '../../inputs/SearchBar';
import { HamburguerMenu } from '../../navigation/HamburguerMenu';

// Types
import { MainHeaderProps } from './types';

// Styles utils
import { breakPoints, percentBaseResponsive } from '../../../styles/spaces';

export default function MainHeader({
  variant,
  userName,
  userMail,
  userRole,
}: MainHeaderProps): JSX.Element {
  const isLaptop = useMedia(
    `(max-width: ${breakPoints.smallDesktop * percentBaseResponsive}rem)`
  );

  return (
    <Container>
      <ContainerMenuLogo>
        {isLaptop ? <HamburguerMenu /> : null}
        <Link to="/">
          <Logo src={OraLOGO} alt={'Ora Logo'} />
        </Link>
      </ContainerMenuLogo>
      <SearchBar variant={variant} />
      <MenuUser userName={userName} userMail={userMail} userRole={userRole} />
    </Container>
  );
}

import React from 'react';
import styled, { css } from 'styled-components';

// Styles
import { colors } from '../../../styles/colors';
import {
  breakPoints,
  percentBaseResponsive,
  spaces,
} from '../../../styles/spaces';
import { fontSize, fontWeight } from '../../../styles/fonts';

// Types
import { TypeInput } from './types';

// Components
import { Typography } from '../../display/Typography';
import { VisibilityIcon } from '../../../assets/icons/VisibilityIcon';

type ContainerProps = {
  inputSize?: string;
  typeInput: TypeInput;
};

type InputContainerProps = {
  typeInput: TypeInput;
  disabled: boolean | undefined;
  borderError: boolean | undefined;
  isFocused: boolean;
};
type InputValueProps = { typeInput: TypeInput };

export const Container = styled.div<ContainerProps>`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: ${spaces.xxxs}rem;
  max-width: ${({ inputSize }) => (inputSize ? inputSize : '22')}rem;
  max-width: ${({ typeInput }) =>
    (typeInput === 'email' || typeInput === 'password') && '100%'};
`;

type labelProps = React.LabelHTMLAttributes<HTMLLabelElement>;

export const Label = styled(Typography)<labelProps>`
  @media (max-width: ${breakPoints.landscapeTablet *
    percentBaseResponsive}rem) {
    font-size: ${fontSize.XS};
  }
`;

export const ErrorMessage = styled(Typography)``;

export const InputContainer = styled.div<InputContainerProps>`
  display: flex;
  align-items: center;
  height: 4rem;
  padding: 0 1rem;
  border: ${({ disabled }) =>
    !disabled ? `0.1rem solid ${colors.black}` : 'none'};
  border-radius: 0.5rem;
  border: ${({ borderError }) =>
    borderError && `0.1rem solid ${colors.lightCoralRed}`};
  outline: none;
  ${({ disabled }) =>
    !disabled &&
    css`
      background-color: ${colors.white};
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      background-color: ${colors.greyLight};
    `}
    
    ${({ isFocused }) =>
    isFocused &&
    css`
      outline: 0.1rem solid ${colors.black};
    `}
`;

export const InputValue = styled.input<InputValueProps>`
  display: flex;
  background: transparent;
  border: none;
  outline: none;
  width: 100%;
  padding: 0;

  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: ${colors.greySoftLight};
    opacity: 1; /* Firefox */
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: ${colors.greySoftLight};
  }

  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: ${colors.greySoftLight};
  }

  &::-ms-reveal {
    display: none;
  }

  ${({ disabled }) =>
    !disabled &&
    css`
      font-weight: ${fontWeight.regular};
      color: ${colors.black};
      background-color: ${colors.white};
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      font-weight: ${fontWeight.medium};
      color: ${colors.greyDark};
      background-color: ${colors.greyLight};
    `}


  @media (max-width: ${breakPoints.landscapeTablet *
  percentBaseResponsive}rem) {
    font-size: ${fontSize.XS};
  }
`;

export const ContainerIconEye = styled.div`
  display: flex;
  align-items: center;
`;

export const IconEye = styled(VisibilityIcon)`
  cursor: pointer;

  path {
    fill: ${colors.greySoftLight};
  }
`;

import { defer, LoaderFunction } from 'react-router-dom';

// Sentry
import * as Sentry from '@sentry/react';

// GraphQL
import { selectOrderWithActionBasicInfoById } from '../../../../../gql/selectors/actions';

// Utils
import { decompressSessionStorage } from '../../../../../utils/sessionStorage';

// Constants
import { formActionNaming } from '../../../../../components/forms/ActionForms/constants';

const loadActionDetails = async (orderId: string, actionId: string) => {
  try {
    return await selectOrderWithActionBasicInfoById(orderId, actionId);
  } catch (err) {
    Sentry.captureException(err);
    console.log('> Action order loading error:', err);
    return false;
  }
};

export const LoaderActionPageOrder: LoaderFunction = async () => {
  const stringifyForm = sessionStorage.getItem(
    formActionNaming.NEW_ACTION_DATA
  );
  if (stringifyForm) {
    const sessionStorageData = JSON.parse(
      decompressSessionStorage(stringifyForm)
    );
    const data = await loadActionDetails(
      sessionStorageData.orderId,
      sessionStorageData.actionId
    );
    return defer({ data });
  }

  return defer({ data: {} });
};

import React from 'react';

// Components
import { Typography } from '../components/display/Typography';

export type RouteUIElement = {
  content: React.ReactNode;
  path: string;
  pathAbsolute: string;
};

export const labelToRoutes: { [key: string]: string } = {
  dashboard: 'Dashboard',
  communication: 'Comunicacions',
  communications: 'Comunicacions',
  'duplicate-communication': 'Duplicar comunicació',
  'search-create-historical-communication': 'Buscar i crear històrics',
  'work-orders': 'Ordres de treball',
  'new-order': 'Nova O.T.',
  'duplicate-order': 'Duplicar O.T.',
  'new-order-duplicate': 'Nova O.T.',
  'search-create-historical-order': 'Buscar i crear històrics',
  multimedia: 'Multimedia',
  summary: 'Resum',
  'new-communication-duplicate': 'Nova comunicació',
  campaign: 'Campanyes',
  'new-campaign': 'Nova Campanya',
  'edit-campaign': 'Editar',
  'edit-communication': 'Editar',
  'general-view-route': 'Vista general del recorregut',
  'surveillance-route': 'Recorregut de vigilància',
  'new-route': 'Nou recorregut',
  'search-create-historical-route': 'Buscar i crear històrics',
  reporting: 'Reporting',
  accidents: 'Accidents',
  accident: 'Accident',
  'damages-and-vehicles': 'Danys i vehicles',
  accidentSummary: 'Accident summary',
  'actions-validation': 'Actuaciones',
  'new-action': 'Nova actuació',
  'new-accident': 'Nou accident',
  'basic-info': 'Informació bàsica',
  details: 'Detalls',
  'operators-and-vehicles': 'Operaris i vehicles',
  location: 'Ubicació',
  edition: "Edició d'Act",
  materials: 'Materials',
  machinery: 'Maquinària Aux',
  animals: 'Animals',
  management: 'Gestió',
  users: 'Usuari',
  vehicles: 'Vehicles',
  'auxiliar-machinery': 'Maquinària',
};

export const createRoutes = (routes: string[], data: RouteUIElement[]) =>
  routes.reduce((accumulator, current, index) => {
    accumulator.push({
      content: (
        <Typography size={'S'} colorText={'greyDark'}>
          {labelToRoutes[current]}
        </Typography>
      ),
      path: current,
      pathAbsolute:
        index > 0
          ? '/' + accumulator[index].path + '/' + current
          : accumulator[index].path + current,
    });
    return accumulator;
  }, data);

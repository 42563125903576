// Types
import { dataTable } from './types';

export const mocksTable: dataTable[] = [
  {
    cotic: ['1O', '0000', '1234567', '00'],
    date: 1641809400000,
    hour: 1641809400000,
    capitol: 'Entorns de carretera',
    subCapitol: 'Senyalització',
    concession: 'LL',
    road: 'C-16',
    pkInit: '12+350',
    pkEnd: '12+380',
    description: 'Acidente de ciclomotor en  vía asasd',
    state: 'open',
  },
  {
    cotic: ['1O', '0000', '2234567', '00'],
    date: 1642291200000,
    hour: 1642291200000,
    capitol: 'Accident',
    subCapitol: 'Animals',
    concession: 'AR',
    road: 'C-17',
    pkInit: '15+789',
    pkEnd: '15+001',
    description: 'Acidente de ciclomotor en  vía asasd',
    state: 'partial',
  },
  {
    cotic: ['1R', '0000', '3234567', '00'],
    date: 1646925000000,
    hour: 1646925000000,
    capitol: 'Altres',
    subCapitol: 'Senyalització',
    concession: 'TE',
    road: 'C-16',
    pkInit: '12+345',
    pkEnd: '12+346',
    description: 'Acidente de ciclomotor en  vía asasd',
    state: 'end',
  },
  {
    cotic: ['1O', '0000', '5234567', '00'],
    date: 1641816600000,
    hour: 1641816600000,
    capitol: 'Entorns de carretera',
    subCapitol: 'Animals',
    concession: 'LL',
    road: 'C-18',
    pkInit: '12+344',
    pkEnd: '12+349',
    description: 'Acidente de ciclomotor en  vía asasd',
    state: 'validated',
  },
  {
    cotic: ['1O', '0000', '1234567', '00'],
    date: 1641809400000,
    hour: 1641809400000,
    capitol: 'Altres',
    subCapitol: 'Animals',
    concession: 'AR',
    road: 'C-16',
    pkInit: '12+342',
    pkEnd: '12+383',
    description: 'Acidente de ciclomotor en  vía asasd',
    state: 'validated',
  },
  {
    cotic: ['1O', '0000', '2234567', '00'],
    date: 1642363800000,
    hour: 1642363800000,
    capitol: 'Entorns de carretera',
    subCapitol: 'Senyalització',
    concession: 'TE',
    road: 'C-17',
    pkInit: '22+899',
    pkEnd: '22+900',
    description: 'Acidente de ciclomotor en  vía asasd',
    state: 'open',
  },
  {
    cotic: ['1R', '0000', '3234567', '00'],
    date: 1646925000000,
    hour: 1646925000000,
    capitol: 'Accident',
    subCapitol: 'Animals',
    concession: 'LL',
    road: 'C-17',
    pkInit: '99+871',
    pkEnd: '99+992',
    description: 'Acidente de ciclomotor en  vía asasd',
    state: 'validated',
  },
  {
    cotic: ['1O', '0000', '5234567', '00'],
    date: 1641816600000,
    hour: 1641816600000,
    capitol: 'Entorns de carretera',
    subCapitol: 'Animals',
    concession: 'AR',
    road: 'C-18',
    pkInit: '78+922',
    pkEnd: '79+533',
    description: 'Acidente de ciclomotor en  vía asasd',
    state: 'partial',
  },
  {
    cotic: ['1O', '0000', '1234567', '00'],
    date: 1641816610000,
    hour: 1641816610000,
    capitol: 'Accident',
    subCapitol: 'Senyalització',
    concession: 'TE',
    road: 'C-16',
    pkInit: '98+762',
    pkEnd: '98+853',
    description: 'Acidente de ciclomotor en  vía asasd',
    state: 'validated',
  },
  {
    cotic: ['1O', '0000', '2234567', '00'],
    date: 1642363810000,
    hour: 1642363810000,
    capitol: 'Accident',
    subCapitol: 'Animals',
    concession: 'AR',
    road: 'C-16',
    pkInit: '87+654',
    pkEnd: '98+765',
    description: 'Acidente de ciclomotor en  vía asasd',
    state: 'validated',
  },
  {
    cotic: ['1O', '0000', '1234567', '00'],
    date: 1641816610000,
    hour: 1641816610000,
    capitol: 'Entorns de carretera',
    subCapitol: 'Senyalització',
    concession: 'TE',
    road: 'C-18',
    pkInit: '45+678',
    pkEnd: '56+789',
    description: 'Acidente de ciclomotor en  vía asasd',
    state: 'open',
  },
  {
    cotic: ['1O', '0000', '2234567', '00'],
    date: 1642363810000,
    hour: 1642363810000,
    capitol: 'Altres',
    subCapitol: 'Animals',
    concession: 'AR',
    road: 'C-18',
    pkInit: '35+790',
    pkEnd: '46+800',
    description: 'Acidente de ciclomotor en  vía asasd',
    state: 'partial',
  },
  {
    cotic: ['1R', '0000', '3234567', '00'],
    date: 1646925000000,
    hour: 1646925000000,
    capitol: 'Altres',
    subCapitol: 'Senyalització',
    concession: 'LL',
    road: 'C-16',
    pkInit: '44+777',
    pkEnd: '44+789',
    description: 'Acidente de ciclomotor en  vía asasd',
    state: 'partial',
  },
  {
    cotic: ['1O', '0000', '5234567', '00'],
    date: 1641816600000,
    hour: 1641816600000,
    capitol: 'Altres',
    subCapitol: 'Animals',
    concession: 'AR',
    road: 'C-17',
    pkInit: '11+111',
    pkEnd: '11+116',
    description: 'Acidente de ciclomotor en  vía asasd',
    state: 'partial',
  },
  {
    cotic: ['1O', '0000', '1234567', '00'],
    date: 1641816610000,
    hour: 1641816610000,
    capitol: 'Altres',
    subCapitol: 'Senyalització',
    concession: 'TE',
    road: 'C-16',
    pkInit: '64+312',
    pkEnd: '65+212',
    description: 'Acidente de ciclomotor en  vía asasd',
    state: 'open',
  },
  {
    cotic: ['1O', '0000', '1234567', '00'],
    date: 1641816610000,
    hour: 1641816610000,
    capitol: 'Altres',
    subCapitol: 'Animals',
    concession: 'TE',
    road: 'C-17',
    pkInit: '22+580',
    pkEnd: '25+290',
    description: 'Acidente de ciclomotor en  vía asasd',
    state: 'partial',
  },
  {
    cotic: ['1O', '0000', '2234567', '00'],
    date: 1642363810000,
    hour: 1642363810000,
    capitol: 'Altres',
    subCapitol: 'Senyalització',
    concession: 'AR',
    road: 'C-18',
    pkInit: '87+364',
    pkEnd: '89+346',
    description: 'Acidente de ciclomotor en  vía asasd',
    state: 'partial',
  },
  {
    cotic: ['1O', '0000', '1234567', '00'],
    date: 1641816610000,
    hour: 1641816610000,
    capitol: 'Accident',
    subCapitol: 'Animals',
    concession: 'TE',
    road: 'C-18',
    pkInit: '62+574',
    pkEnd: '87+465',
    description: 'Acidente de ciclomotor en  vía asasd',
    state: 'partial',
  },
  {
    cotic: ['1O', '0000', '2234567', '00'],
    date: 1642363810000,
    hour: 1642363810000,
    capitol: 'Accident',
    subCapitol: 'Senyalització',
    concession: 'AR',
    road: 'C-17',
    pkInit: '78+487',
    pkEnd: '78+500',
    description: 'Acidente de ciclomotor en  vía asasd',
    state: 'partial',
  },
  {
    cotic: ['1R', '0000', '3234567', '00'],
    date: 1646925000000,
    hour: 1646925000000,
    capitol: 'Accident',
    subCapitol: 'Animals',
    concession: 'LL',
    road: 'C-17',
    pkInit: '45+678',
    pkEnd: '34+477',
    description: 'Acidente de ciclomotor en  vía asasd',
    state: 'validated',
  },
  {
    cotic: ['1O', '0000', '5234567', '00'],
    date: 1641816600000,
    hour: 1641816600000,
    capitol: 'Accident',
    subCapitol: 'Senyalització',
    concession: 'AR',
    road: 'C-16',
    pkInit: '43+567',
    pkEnd: '44+000',
    description: 'Acidente de ciclomotor en  vía asasd',
    state: 'validated',
  },
  {
    cotic: ['1O', '0000', '1234567', '00'],
    date: 1641816610000,
    hour: 1641816610000,
    capitol: 'Accident',
    subCapitol: 'Animals',
    concession: 'TE',
    road: 'C-17',
    pkInit: '76+890',
    pkEnd: '87+956',
    description: 'Acidente de ciclomotor en  vía asasd',
    state: 'validated',
  },
];

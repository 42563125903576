import React, { useLayoutEffect } from 'react';
import { useAsyncValue, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

// Components
import LocationForm from '../../../../../components/forms/ActionForms/LocationForm';

// Constants
import { formActionValidationNaming } from '../../../../../components/forms/ActionForms/constants';

// Types
import { type LocationFormValues } from '../../../../../components/forms/ActionForms/LocationForm/types';
import { type WorkOrder } from '../../../../../types/workOrder';
import { OrderActionReferences } from '../types';

// Redux
import { coticOTSelected } from '../../../../../state/actions/ui/coticOTSelectedGeneralView';

export default function LocationActionFormPage(): JSX.Element {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loaderData = useAsyncValue() as LocationFormValues &
    WorkOrder &
    OrderActionReferences & { parentExpeditionOrderStatus?: string };

  const onFormSubmit = () => {
    navigate('../edition');
  };

  useLayoutEffect(() => {
    dispatch(
      coticOTSelected(
        'actions-validation',
        loaderData.cotic,
        loaderData.orderId
      )
    );
  }, []);

  return (
    <LocationForm
      onFormSubmit={onFormSubmit}
      initialData={loaderData}
      namingPersistForm={
        formActionValidationNaming.NEW_VALIDATION_LOCATION_FORM
      }
      textButton="Validar"
    />
  );
}

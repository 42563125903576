// Libraries
import React, { useCallback, useRef, useState } from 'react';

// Types
import { UserMenuProps } from './types';

// Components
import { Typography } from '../Typography';
import UserButton from '../../inputs/buttons/UserButton';

// Components Styled
import {
  BottomArrow,
  ContainerDropdown,
  ContainerUser,
  ContainerUserMenu,
  UserData,
} from './styles';

// Hooks
import { DropDownUserMenu } from '../DropDownUserMenu';
import { useClickAway } from 'react-use';

// Media
import { useIsLandscapeTablet } from '../../../hooks/useMediaBreakPoints';
import { Roles } from '../DropDownUserMenu/types';

export const UserMenu = ({
  userName,
  userMail,
  userRole,
}: UserMenuProps): JSX.Element => {
  const [displayMenuUser, setDisplayMenuUser] = useState(false);
  const ContainerRef = useRef(null);

  const isLandscapeTablet = useIsLandscapeTablet();

  useClickAway(ContainerRef, () => setDisplayMenuUser(false));

  const displayMenu = useCallback(
    () => setDisplayMenuUser(!displayMenuUser),
    [displayMenuUser]
  );

  return (
    <ContainerUserMenu ref={ContainerRef}>
      <ContainerUser onClick={displayMenu}>
        <UserButton initials={userName} />
        {isLandscapeTablet ? null : (
          <UserData>
            <Typography size="M">{userName}</Typography>
            <ContainerDropdown>
              <Typography size="S" colorText={'garnet'}>
                {Roles[userRole]}
              </Typography>
              <BottomArrow />
            </ContainerDropdown>
          </UserData>
        )}
      </ContainerUser>
      {displayMenuUser ? (
        <DropDownUserMenu
          userName={userName}
          userMail={userMail}
          userRole={userRole}
        />
      ) : null}
    </ContainerUserMenu>
  );
};

import { OperatorsType } from './types';

export const mockData: OperatorsType[] = [
  {
    nom: 'Esther',
    cognom: 'Alfreda Pol',
    concession: 'LL',
  },
  {
    nom: 'Carles',
    cognom: 'Jiménez Ruiz',
    concession: 'LL',
  },
  {
    nom: 'Iván',
    cognom: 'Sánchez Gil',
    concession: 'LL',
  },
  {
    nom: 'Esther',
    cognom: 'Alfreda Pol',
    concession: 'LL',
  },
  {
    nom: 'Carles',
    cognom: 'Jiménez Ruiz',
    concession: 'LL',
  },
  {
    nom: 'Iván',
    cognom: 'Sánchez Gil',
    concession: 'LL',
  },
  {
    nom: 'Esther',
    cognom: 'Alfreda Pol',
    concession: 'LL',
  },
];

import React, { useState, useLayoutEffect } from 'react';
import { format } from 'date-fns';
import { CloudinaryImage } from '@cloudinary/url-gen';
import { lazyload, responsive, placeholder } from '@cloudinary/react';

// Types
import { Props } from './types';

// Styled Components
import {
  Container,
  CloudinaryAdvanceImage,
  Text,
  ContainerInfo,
  TextContainer,
  StyledDefinition,
} from './styles';

// Components
import { Loader } from '../../inputs/Loader';
import { useDispatch } from 'react-redux';
import { openModal } from '../../../state/actions/ui/modal';
import { modalIds } from '../../modals/SuperModal/types';

export const Photo = ({
  publicId,
  title,
  date,
  cloud,
  cotic,
}: Props): JSX.Element => {
  const parsedDate = typeof date === 'string' ? new Date(date) : date;
  const currentDate = format(parsedDate, 'dd / MM / yyyy');
  const currentHours = format(parsedDate, 'HH');
  const currentMinutes = format(parsedDate, 'mm');
  const dispatch = useDispatch();

  const [cloudinaryImageSrc, setCloudinaryImageSrc] =
    useState<CloudinaryImage | null>(null);

  useLayoutEffect(() => {
    const currentImage = cloud.image(publicId);
    setCloudinaryImageSrc(currentImage);
  }, []);

  const handleAddImage = () => {
    dispatch(
      openModal(modalIds.VIEW_FULLSCREEN_IMAGE, {
        currentImage: cloudinaryImageSrc,
        title: title,
        currentDate: currentDate,
        currentHours: currentHours,
        currentMinutes: currentMinutes,
        cotic: cotic,
      })
    );
  };

  return (
    <Container onClick={handleAddImage}>
      {cloudinaryImageSrc ? (
        <CloudinaryAdvanceImage
          cldImg={cloudinaryImageSrc}
          plugins={[
            lazyload({
              rootMargin: '5px 5px 5px 5px',
              threshold: 0.15,
            }),
            responsive({
              steps: [479, 639, 1162],
            }),
            placeholder(),
          ]}
        />
      ) : (
        <Loader />
      )}
      <TextContainer>
        <Text colorText="greyDark" size="XS">
          {title}
        </Text>
        <ContainerInfo>
          <StyledDefinition title="Data de creació" value={currentDate} />
          <StyledDefinition
            title="Hora de creació"
            value={`${currentHours}:${currentMinutes}`}
          />
          <StyledDefinition title="COTIC" value={cotic} />
        </ContainerInfo>
      </TextContainer>
    </Container>
  );
};

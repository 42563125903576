export type TableProps = {
  data?: VehiclesType[];
};

export type VehiclesType = {
  mat: string;
  driver: string;
  concession: string;
};

type columnIDTypes = { [key: string]: { id: string; label: string } };

export const columnNames: columnIDTypes = {
  mat: {
    id: 'mat',
    label: 'Matrícula',
  },
  driver: {
    id: 'driver',
    label: 'Conductor',
  },
  concession: {
    id: 'concession',
    label: 'Concessió',
  },
};

// Types
import { OrderType } from '../types/orders';

// Constants
import {
  directoryNaming,
  formAccidentNaming,
} from '../components/forms/AddBasicInfoAccidentForm/constants';
import { formVehicleNaming } from '../components/forms/AddVehicleForm/constants';
import { formActionNaming } from '../components/forms/ActionForms/constants';

// Utils
import { deleteWholeDirectory } from './files';

// Redux
import { actionTabsReset } from '../state/actions/ui/actionTabs';
import { store } from '../state/configureStore';

export const removeNewAccidentStorage = () => {
  Object.values({ ...formAccidentNaming, ...formVehicleNaming }).forEach(
    (key) => {
      sessionStorage.removeItem(key);
    }
  );
  deleteWholeDirectory(directoryNaming.CANVAS);
  deleteWholeDirectory(OrderType.Accident);
};

export const removeNewActionStorage = () => {
  sessionStorage.removeItem(formActionNaming.SELECTED_ACTIONS_FILES);
  sessionStorage.removeItem(formActionNaming.NEW_ACTION_DATA);
  sessionStorage.removeItem(formActionNaming.NEW_OPERATORS_AND_VEHICLE_FORM);
  sessionStorage.removeItem(formActionNaming.NEW_LOCATION_FORM);
  sessionStorage.removeItem(formActionNaming.NEW_EDITION_FORM);
  sessionStorage.removeItem(formActionNaming.NEW_MATERIALS_FORM);
  sessionStorage.removeItem(formActionNaming.NEW_ANIMALS_FORM);
  sessionStorage.removeItem(formActionNaming.NEW_MACHINERY_FORM);
  sessionStorage.removeItem(formActionNaming.DRIVERS);
  deleteWholeDirectory(OrderType.Action);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  store.dispatch(actionTabsReset() as any);
};

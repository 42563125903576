// Dependencies
import styled, { css } from 'styled-components';

// Types
import { variantSearchBar } from './types';

// Colors
import { colors } from '../../../styles/colors';

// Fonts
import { fontSize, fontWeight } from '../../../styles/fonts';

// Styles
import { breakPoints, percentBaseResponsive } from '../../../styles/spaces';

interface ContainerProps {
  variant: variantSearchBar;
}

export const Container = styled.div<ContainerProps>`
  position: relative;
  display: flex;

  ${({ variant }) =>
    ({
      big: css`
        max-width: 189.6rem;
        width: 100%;
        box-shadow: 0.1rem 0.2rem 0.5rem rgba(0, 0, 0, 0.25);
      `,
      small: css`
        max-width: 189.6rem;
        width: 21rem;
        box-shadow: 0.1rem 0.2rem 0.5rem rgba(0, 0, 0, 0.25);
      `,
      form: css`
        max-width: 189.6rem;
        box-shadow: none;
        border: 0.1rem solid ${colors.black};
      `,
      campaign: css`
        border: 0.1rem solid ${colors.black};
      `,
    }[variant])}
  height: ${({ variant }) => (variant === 'campaign' ? '4rem' : '4.5rem')};
  border-radius: 0.5rem;
  background-color: ${colors.white};
  align-items: center;

  @media (max-width: ${breakPoints.landscapeTablet *
    percentBaseResponsive}rem) {
    ${({ variant }) => (variant === 'campaign' ? 'none' : '53.8rem')};
  }
`;

export const Input = styled.input`
  flex: 1;
  height: 100%;
  border-radius: 0.5rem;
  background-color: ${colors.white};
  color: ${colors.black};
  border-style: none;
  font-family: 'Poppins', sans-serif;
  font-size: ${fontSize.M};
  padding-left: 5.2rem;
  font-weight: ${fontWeight.regular};
  width: 100%;

  ::placeholder {
    color: ${colors.greySoftLight};
  }

  :focus {
    outline: 0.08rem solid ${colors.black};
  }

  :focus::placeholder {
    color: transparent;
  }

  @media (max-width: ${breakPoints.landscapeTablet *
    percentBaseResponsive}rem) {
    font-size: ${fontSize.M};
  }
`;

export const Icon = styled.img`
  position: absolute;
  margin-left: 2rem;
  width: 1.6rem;
  height: 1.6rem;
`;

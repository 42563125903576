export type NamingPersistForm = {
  namingPersistForm: string;
};

export type DataChanged = {
  editionData?: {
    id: string;
    state: string;
  }[];
  materialsData?: {
    id: string;
    state: string;
    newValue?: string;
  }[];
};

export enum DataState {
  updated = 'UPDATE',
  deleted = 'DELETE',
  added = 'ADDED',
}

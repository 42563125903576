import React, { useEffect, useState } from 'react';
import { useSubmit } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Reference, StoreObject } from '@apollo/client';
import * as Sentry from '@sentry/react';

// Styled Components
import { ContainerOT, ButtonContainer, Container } from './styles';

// Components
import { WorkOrder } from './WorkOrder';
import { SubWorkOrder } from './SubWorkOrder';
import { ActionOT } from './ActionOT';
import { MainButton } from '../../inputs/buttons/MainButton';

// Types
import { ShowOTTypes } from './types';
import { SpecialTag } from '../SpecialTag';
import { variant } from '../SpecialTag/types';
import { Order, OrderState, OrderType } from '../../../types/orders';

// Utils
import { createFormDataFromValues } from '../../../utils/forms';
import { decompressSessionStorage } from '../../../utils/sessionStorage';
import {
  removeNewAccidentStorage,
  removeNewActionStorage,
} from '../../../utils/resetData';
import { truncateArrayAfterNull } from '../../../utils/mergeArrays';

// Redux
import { coticOTSelected } from '../../../state/actions/ui/coticOTSelectedGeneralView';
import { actionTabsReset } from '../../../state/actions/ui/actionTabs';

// Constants
import { formActionNaming } from '../../forms/ActionForms/constants';
import { groupLanes, groupTracks } from '../../../data/orders';

// GQL
import { client } from '../../../gql/client';
import { DELETE_ACTION_BY_ID } from '../../../gql/mutations/actions';
import { GET_ORDER_BY_ID } from '../../../gql/queries/orders/orders';
import { AccidentOT } from './AccidentOT';
import { CommunicationOT } from './CommunicationOT';

export const ShowOT = ({
  data,
  revalidatorFunction,
}: ShowOTTypes): JSX.Element => {
  const submit = useSubmit();
  const dispatch = useDispatch();

  const orderGeneric = data.find((order) => order.type === 'generic');
  const campaignOrder = data.find((order) => order.type === 'campaign');

  const [isDisabled, setIsDisabled] = useState(
    orderGeneric?.tag !== variant.Open || campaignOrder?.tag !== variant.Open
  );

  useEffect(() => {
    orderGeneric
      ? setIsDisabled(orderGeneric?.tag !== variant.Open)
      : setIsDisabled(campaignOrder?.tag !== variant.Open);
  }, [data]);
  const handleCreate = (type: string) => async () => {
    const dataToMakeRelation = {
      id: data[0].id,
      modelTypeId: data[0].modelTypeId,
      cotic: data[0].cotic,
    };

    dispatch(
      coticOTSelected(
        OrderType[
          (type.charAt(0).toUpperCase() +
            type.slice(1)) as keyof typeof OrderType
        ],
        data[0].cotic,
        data[0].id
      )
    );

    if (type === OrderType.Accident) {
      removeNewAccidentStorage();
    } else if (type === OrderType.Action) {
      const previousData = sessionStorage.getItem(
        formActionNaming.NEW_ACTION_DATA
      )
        ? JSON.parse(
            decompressSessionStorage(
              sessionStorage.getItem(formActionNaming.NEW_ACTION_DATA) as string
            )
          )
        : {};
      if (previousData.actionId) {
        try {
          const {
            data: {
              order: { state },
            },
          } = await client.query({
            query: GET_ORDER_BY_ID,
            variables: {
              id: previousData.orderId,
            },
            fetchPolicy: 'network-only',
          });
          state === OrderState.Partial &&
            (await client.mutate({
              mutation: DELETE_ACTION_BY_ID,
              variables: {
                id: previousData.actionId,
              },
              update(cache) {
                cache.modify({
                  fields: {
                    actions(existingActions = {}, { readField }) {
                      const filterActions = existingActions.actions.filter(
                        (action: StoreObject | Reference) => {
                          const ref = readField('id', action);
                          return ref !== previousData.actionId;
                        }
                      );

                      const truncatedArray =
                        truncateArrayAfterNull(filterActions);

                      return {
                        ...existingActions,
                        actions: truncatedArray,
                        totalCount: existingActions.totalCount - 1,
                        pageInfo: {},
                      };
                    },
                  },
                });

                cache.modify({
                  id: `Order:${previousData.orderParentId}`,
                  fields: {
                    childExpeditionOrderIDs(existingOrders = []) {
                      const cacheOrders = existingOrders.map(
                        (existingOrder: string) =>
                          cache.readQuery({
                            query: GET_ORDER_BY_ID,
                            variables: {
                              id: existingOrder,
                            },
                          })
                      );

                      const deletedOrder = cacheOrders.find(
                        (order: { order: Order }) =>
                          order.order.type == 'action' &&
                          order.order.state === 'partial'
                      );

                      const filterData = existingOrders.filter(
                        (order: string) => order !== deletedOrder?.order.id
                      );

                      return [...filterData];
                    },
                  },
                });
              },
            }));
        } catch (e) {
          Sentry.captureException(e);
          console.log(e);
        }
      }
      removeNewActionStorage();
    }

    dispatch(actionTabsReset());

    const formData = createFormDataFromValues({
      ...dataToMakeRelation,
      intent: 'pass-data',
    });

    submit(formData, {
      method: 'POST',
      action: `new-${type}`,
    });
  };

  return (
    <Container>
      <ButtonContainer>
        <MainButton
          text="Crear Accident"
          variant="secondary"
          onClick={handleCreate(OrderType.Accident)}
          disabled={isDisabled}
        />
        <MainButton
          text="Crear Actuació"
          variant="primary"
          onClick={handleCreate(OrderType.Action)}
          disabled={isDisabled}
        />
        {data[0].type === OrderType.Campaign && (
          <SpecialTag variant={variant.Campaign} title={data[0].name} />
        )}
      </ButtonContainer>
      <ContainerOT>
        {data?.map((order, index) => {
          if (
            order.type === OrderType.Generic ||
            order.type === OrderType.Campaign
          ) {
            return (
              <WorkOrder
                key={index}
                {...order}
                groupTracks={groupTracks}
                groupLanes={groupLanes}
                name={'Ordre de Treball'}
                revalidatorFunction={revalidatorFunction}
              />
            );
          } else if (order.type === OrderType.SubOrder) {
            return (
              <SubWorkOrder
                key={index}
                {...order}
                revalidatorFunction={revalidatorFunction}
              />
            );
          } else if (order.type === OrderType.Action) {
            return (
              <ActionOT
                parentOrder={{
                  id: data[0].id,
                  type: data[0].type,
                }}
                key={index}
                {...order}
                revalidatorFunction={revalidatorFunction}
                state={order?.state}
                stateParentExpeditionOrder={orderGeneric?.state}
              />
            );
          } else if (order.type === OrderType.Accident) {
            return (
              <AccidentOT
                key={index}
                {...order}
                parentOrder={{
                  id: data[0].id,
                  type: data[0].type,
                }}
                revalidatorFunction={revalidatorFunction}
                state={order?.state}
                stateParentExpeditionOrder={orderGeneric?.state}
              />
            );
          } else if (order.type === OrderType.Communication) {
            return (
              <CommunicationOT
                key={index}
                {...order}
                parentOrder={{
                  id: data[0].id,
                  type: data[0].type,
                }}
                revalidatorFunction={revalidatorFunction}
                state={order?.state}
                stateParentExpeditionOrder={orderGeneric?.state}
              />
            );
          }
        })}
      </ContainerOT>
    </Container>
  );
};

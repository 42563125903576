import React, { useLayoutEffect } from 'react';
import { useAsyncValue, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

// Styles
import { Container } from './styles';

// Components
import { GridOfPhotos } from '../../../../../../components/multimedia/GridOfPhotos';

// Types
import { LoaderMultimedia } from './types';

// Redux
import { coticOTSelected } from '../../../../../../state/actions/ui/coticOTSelectedGeneralView';
import { OrderType } from '../../../../../../types/orders';

export const MultimediaGridGeneralView = (): JSX.Element => {
  const loaderData = useAsyncValue() as LoaderMultimedia;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useLayoutEffect(() => {
    if (!loaderData) navigate('..');
    dispatch(
      coticOTSelected(
        OrderType.Multimedia,
        loaderData.images[0].cotic as unknown as string,
        loaderData.images[0].orderIDs[0] as string
      )
    );
  }, [loaderData]);

  return (
    <Container>
      <GridOfPhotos
        cloudName={import.meta.env.VITE_CLOUD_NAME}
        imagesToShow={loaderData.images}
      />
    </Container>
  );
};

import { defer, LoaderFunction } from 'react-router-dom';

// Sentry
import * as Sentry from '@sentry/react';

// GQL
import { client } from '../../../../gql/client';
import { FETCH_VEHICLES } from '../../../../gql/queries/vehicles';
import {
  GET_VEHICLE_CATEGORIES,
  GET_VEHICLE_CATEGORY_BY_ID,
} from '../../../../gql/queries/vehicleCategory';
import { GET_CONCESSIONS } from '../../../../gql/queries/orders/orders';
import { GET_CONCESSION_BY_ID } from '../../../../gql/queries/geoPositions/roads';
// Utils
import { AuthContextType } from '../../../../hooks/useAuth';
import { decompressSessionStorage } from '../../../../utils/sessionStorage';

// Constants
import { formVehicleManagementNaming } from '../../../../components/forms/AddVehicleManagementForm/constants';

const loadTableData = async (userData: AuthContextType | null) => {
  try {
    if (userData?.user) {
      let vehiclesToReturn;

      const {
        data: { vehicles },
      } = await client.query({
        query: FETCH_VEHICLES,
        fetchPolicy: 'cache-only',
      });

      if (!vehicles || vehicles.length === 0) {
        const {
          data: { vehicles },
        } = await client.query({
          query: FETCH_VEHICLES,
        });

        vehiclesToReturn = vehicles;
      } else {
        vehiclesToReturn = vehicles;
      }

      return Promise.all(
        vehiclesToReturn.map(
          async (vehicle: {
            id: string;
            plate: string;
            model: string;
            isActive: boolean;
            isInternal: boolean;
            isTrailer: boolean;
            category: string;
            vehicleCategoryIDs: string[];
            concessionIDs: string[];
          }) => {
            await client.query({
              query: GET_VEHICLE_CATEGORIES,
            });

            const {
              data: { vehicleCategory },
            } = await client.query({
              query: GET_VEHICLE_CATEGORY_BY_ID,
              variables: {
                id: vehicle?.vehicleCategoryIDs[0],
              },
              fetchPolicy: 'cache-only',
            });

            let concessionName;

            if (vehicle?.concessionIDs.length) {
              const {
                data: { concession },
              } = await client.query({
                query: GET_CONCESSION_BY_ID,
                variables: {
                  id: vehicle?.concessionIDs[0],
                },
              });

              concessionName = concession.name;
            }

            return {
              id: vehicle.id,
              plate: vehicle.plate,
              model: vehicle.model,
              isTrailer: vehicle.isTrailer,
              state: vehicle.isActive ? 'active' : 'inactive',
              isActive: vehicle.isActive,
              isInternal: vehicle.isInternal,
              category: vehicleCategory.name,
              concession: concessionName,
            };
          }
        )
      );
    }
    return {};
  } catch (err) {
    Sentry.captureException(err);
    console.log('> User management loading error:', err);
    return true;
  }
};

export const VehiclesManagementLoader: (
  vehicleData: AuthContextType | null
) => LoaderFunction | undefined = (vehicleData) => async () => {
  const data = (await loadTableData(vehicleData)) as [];
  const activeVehicles = data?.filter(
    (vehicle: { isActive: boolean }) => vehicle?.isActive
  );
  const inactiveVehicles = data?.filter(
    (vehicle: { isActive: boolean }) => !vehicle?.isActive
  );
  const {
    data: { vehicleCategories },
  } = await client.query({
    query: GET_VEHICLE_CATEGORIES,
  });

  const {
    data: { concessions },
  } = await client.query({
    query: GET_CONCESSIONS,
  });

  let cacheData =
    sessionStorage.getItem(
      formVehicleManagementNaming.NEW_VEHICLE_MANAGEMENT_FORM
    ) || {};

  if (Object.keys(cacheData).length > 0) {
    cacheData = JSON.parse(decompressSessionStorage(cacheData as string));
  }

  return defer({
    data: {
      activeVehicles,
      inactiveVehicles,
      cacheData,
      vehicleCategories,
      concessions,
    },
  });
};

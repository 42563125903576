import styled from 'styled-components';
import { colors } from '../../../styles/colors';

// Styles
import {
  spaces,
  breakPoints,
  percentBaseResponsive,
} from '../../../styles/spaces';

// Components
import { Typography } from '../Typography';
import { SpecialTag } from '../SpecialTag';
import { fontSize } from '../../../styles/fonts';
import { InputTextArea } from '../../inputs/InputTextArea';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${spaces.xl}rem;
  padding: ${spaces.xxl}rem;
  background-color: ${colors.verySoftRed};
  border-radius: 0.8rem;
  @media (max-width: ${breakPoints.smallDesktop * percentBaseResponsive}rem) {
    padding: ${spaces.xl}rem;
  }
`;

export const TitleTagContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: ${spaces.xl}rem;
  @media (max-width: ${breakPoints.portraitTablet * percentBaseResponsive}rem) {
    flex-direction: column-reverse;
    gap: ${spaces.m}rem;
  }
`;

export const Title = styled(Typography)`
  @media (max-width: ${breakPoints.landscapeTablet *
    percentBaseResponsive}rem) {
    font-size: ${fontSize.M};
  }
`;

export const RowContainer = styled.div`
  display: flex;
  padding-bottom: ${spaces.xl}rem;
  border-bottom: 0.1rem solid ${colors.greyLight};
  @media (max-width: ${breakPoints.portraitTablet * percentBaseResponsive}rem) {
    flex-direction: column;
  }
`;

export const RowContainerLast = styled(RowContainer)`
  justify-content: space-between;
  gap: ${spaces.xl}rem;
  padding: 0;
  border: none;
  @media (max-width: ${breakPoints.landscapeTablet *
    percentBaseResponsive}rem) {
    flex-direction: column;
  }
`;

export const RowSubContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spaces.xl}rem;
  overflow: hidden;
`;

export const RowSubContainerBottom = styled(RowSubContainer)`
  width: 100%;
  max-width: 63rem;
`;

export const RowSubContainerLeft = styled(RowSubContainer)`
  padding: 0 14rem 0 0;
  border-right: 0.1rem solid ${colors.greyLight};
  flex-shrink: 0;
  @media (max-width: ${breakPoints.smallDesktop * percentBaseResponsive}rem) {
    padding: 0 ${spaces.xxxl}rem 0 0;
  }
  @media (max-width: ${breakPoints.portraitTablet * percentBaseResponsive}rem) {
    padding: 0 0 ${spaces.xl}rem 0;
    border-right: none;
    border-bottom: 0.1rem solid ${colors.greyLight};
  }
`;

export const RowSubContainerRight = styled(RowSubContainer)`
  padding: 0 0 0 14rem;
  @media (max-width: ${breakPoints.smallDesktop * percentBaseResponsive}rem) {
    padding: 0 0 0 ${spaces.xxxl}rem;
  }
  @media (max-width: ${breakPoints.portraitTablet * percentBaseResponsive}rem) {
    padding: ${spaces.xl}rem 0 0 0;
  }
`;

export const ColumnTitleContainer = styled.div``;

export const SmallTitle = styled(Typography)`
  margin-bottom: ${spaces.s}rem;
`;

export const ColumnsContainer = styled.div`
  display: flex;
  gap: ${spaces.xl}rem;
  @media (max-width: ${breakPoints.portraitTablet * percentBaseResponsive}rem) {
    flex-direction: column;
    gap: ${spaces.xxs}rem;
  }
`;

export const ColumnsContainerDouble = styled(ColumnsContainer)`
  @media (max-width: ${breakPoints.landscapeTablet *
    percentBaseResponsive}rem) {
    :first-of-type {
      flex-direction: column;
      gap: ${spaces.xxs}rem;
    }
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spaces.xxs}rem;
`;

export const ContainerButtons = styled.form`
  display: flex;
  align-self: end;
  gap: ${spaces.s}rem;
`;

export const Tag = styled(SpecialTag)``;

export const ChangeStateTag = styled(SpecialTag)`
  cursor: pointer;
`;

export const InputTextAreaStyled = styled(InputTextArea)`
  width: 100%;
  max-width: 89.2rem;
  @media (max-width: ${breakPoints.smallDesktop * percentBaseResponsive}rem) {
    max-width: 86.9rem;
  }
`;

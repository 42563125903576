// Dependencies
import React, { useContext } from 'react';
import { Outlet, useLocation, useNavigation } from 'react-router-dom';

// Components Styled
import {
  Container,
  DropDownSelectStyled,
  NavContainer,
  SubContainer,
} from './styles';

// Components
import { Accordion } from '../../../../components/navigation/Accordion';
import { BreadCrumbs } from '../../../../components/navigation/Breadcrumbs';

// Icons
import HomeIcon from '../../../../assets/icons/home.svg';

// Routes utils
import { options } from '../../../../router/definitionGloblaRoutes';
import { createRoutes } from '../../../../router/labelToRoutes';
import { data } from './routes';

// Styles utils

// Custon Hooks
import { useIsSmallDesktop } from '../../../../hooks/useMediaBreakPoints';
import { Loader } from '../../../../components/inputs/Loader';

// Redux
import { AuthContext } from '../../../../hooks/useAuth';
import { Role } from '../../../../types/auth';
import { filterAllowedRoutes } from '../../../../router/utils';
import { AccordionItem } from '../../../../components/navigation/Accordion/types';
import { OptionSelected } from '../../../../components/inputs/DropDown/DropDownList/types';

export default function ManagementRoutesLayout(): JSX.Element {
  const location = useLocation();
  // const dispatch = useDispatch();
  const navigation = useNavigation();
  const isSmallDesktop = useIsSmallDesktop();
  const routes = location.pathname.split('/');
  routes?.shift();
  const userRole: Role | undefined = useContext(AuthContext)?.user?.user
    ?.rolActive as Role | undefined;

  const dataFiltered = userRole ? filterAllowedRoutes(data, userRole) : data;
  const optionsFiltered = userRole
    ? filterAllowedRoutes(options, userRole)
    : options;
  const routesBreadCrumb = createRoutes(routes, [
    {
      content: <img alt="Pagina principal" src={HomeIcon} />,
      path: '/',
      pathAbsolute: '/',
    },
  ]);

  // const onClickAccordion = useCallback(() => {
  //   dispatch(coticOTSelectedReset());
  // }, []);

  return (
    <Container>
      <BreadCrumbs data={routesBreadCrumb} />
      <SubContainer>
        <NavContainer>
          {isSmallDesktop ? null : (
            <>
              <DropDownSelectStyled
                options={optionsFiltered as OptionSelected[]}
                defaultOption={routes[0]}
              />
              <Accordion
                data={dataFiltered as AccordionItem[]}
                // onItemClick={onClickAccordion}
              />
            </>
          )}
        </NavContainer>
        {navigation.state === 'submitting' || navigation.state === 'loading' ? (
          <Loader />
        ) : (
          <Outlet />
        )}
      </SubContainer>
    </Container>
  );
}

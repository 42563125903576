export type TableProps = {
  data?: AnimalsType[];
};

export type AnimalsType = {
  codiAn: string;
  animal: string;
  ut: number | string;
  status: string;
};

type columnIDTypes = { [key: string]: { id: string; label: string } };

export const columnNames: columnIDTypes = {
  codiAn: {
    id: 'codiAn',
    label: 'Codi maq.',
  },
  status: {
    id: 'status',
    label: 'Estat',
  },
  animal: {
    id: 'animal',
    label: 'Màquina',
  },
  ut: {
    id: 'ut',
    label: 'Ut.',
  },
};

import { SET_CURSOR } from '../../actions/ui/cursorTables';

import {
  tableIds,
  takeBase,
  takeModal,
} from '../../../components/display/tables/types';

export const initialCursorState = {
  [tableIds.DUPLICATE_COMMUNICATION]: {
    id: tableIds.DUPLICATE_COMMUNICATION,
    take: takeBase,
    cursor: '',
    roadId: undefined,
    concessionId: undefined,
    interlocutorGroupId: undefined,
    fromDate: undefined,
    toDate: undefined,
    orderBy: 'desc',
    indexType: undefined,
    indexExpedition: undefined,
    classification: undefined,
    state: undefined,
  },
  [tableIds.WORKORDER_COMMUNICATIONS]: {
    id: tableIds.WORKORDER_COMMUNICATIONS,
    take: takeBase,
    cursor: '',
    roadId: undefined,
    concessionId: undefined,
    interlocutorGroupId: undefined,
    fromDate: undefined,
    toDate: undefined,
    orderBy: 'desc',
    indexType: undefined,
    indexExpedition: undefined,
    classification: undefined,
    state: undefined,
  },
  [tableIds.SEARCH_AND_CREATE_HISTORICAL_COMMUNICATIONS]: {
    id: tableIds.SEARCH_AND_CREATE_HISTORICAL_COMMUNICATIONS,
    take: takeBase,
    cursor: '',
    roadId: undefined,
    concessionId: undefined,
    interlocutorGroupId: undefined,
    fromDate: undefined,
    toDate: undefined,
    orderBy: 'desc',
    indexType: undefined,
    indexExpedition: undefined,
    classification: undefined,
  },
  [tableIds.WORKORDER_GENERAL_VIEW]: {
    id: tableIds.WORKORDER_GENERAL_VIEW,
    take: takeBase,
    cursor: '',
    concessionId: undefined,
    capitolId: undefined,
    subCapitolId: undefined,
    orderBy: 'desc',
    toDate: undefined,
    fromDate: undefined,
    indexType: undefined,
    indexExpedition: undefined,
    classification: undefined,
    state: undefined,
  },
  [tableIds.DUPLICATE_GENERIC_ORDER]: {
    id: tableIds.DUPLICATE_GENERIC_ORDER,
    take: takeBase,
    cursor: '',
    concessionId: undefined,
    capitolId: undefined,
    subCapitolId: undefined,
    orderBy: 'desc',
    toDate: undefined,
    fromDate: undefined,
    indexType: undefined,
    indexExpedition: undefined,
    classification: undefined,
    state: undefined,
  },
  [tableIds.SEARCH_AND_CREATE_HISTORICAL_WORKORDERS]: {
    id: tableIds.SEARCH_AND_CREATE_HISTORICAL_WORKORDERS,
    take: takeBase,
    cursor: '',
    concessionId: undefined,
    capitolId: undefined,
    subCapitolId: undefined,
    orderBy: 'desc',
    toDate: undefined,
    fromDate: undefined,
    indexType: undefined,
    indexExpedition: undefined,
    classification: undefined,
    state: undefined,
  },
  [tableIds.ACCIDENTS]: {
    id: tableIds.ACCIDENTS,
    take: takeBase,
    cursor: '',
    concessionId: undefined,
    weatherId: undefined,
    mediumKnowledgeId: undefined,
    orderBy: 'desc',
    toDate: undefined,
    fromDate: undefined,
    indexType: undefined,
    indexExpedition: undefined,
    classification: undefined,
    state: undefined,
  },
  [tableIds.CAMPAIGNS]: {
    id: tableIds.CAMPAIGNS,
    take: takeBase,
    cursor: '',
    orderBy: 'desc',
    name: undefined,
    fromDate: undefined,
    toDate: undefined,
    concessionId: undefined,
    state: undefined,
  },
  [tableIds.MULTIMEDIA]: {
    id: tableIds.MULTIMEDIA,
    take: takeBase,
    cursor: '',
    orderBy: 'desc',
    type: undefined,
    fromDate: undefined,
    toDate: undefined,
    state: undefined,
    capitolId: undefined,
    subCapitolId: undefined,
    indexType: undefined,
    indexExpedition: undefined,
    classification: undefined,
    isMultimedia: true,
  },
  [tableIds.GENERIC_ORDER_MODAL]: {
    id: tableIds.GENERIC_ORDER_MODAL,
    take: takeModal,
    cursor: '',
    orderBy: 'desc',
    concessionId: undefined,
    capitol: undefined,
    subCapitol: undefined,
    fromDate: undefined,
    toDate: undefined,
    indexType: undefined,
    indexExpedition: undefined,
    classification: undefined,
  },
  [tableIds.CAMPAIGN_MODAL]: {
    id: tableIds.CAMPAIGN_MODAL,
    take: takeModal,
    cursor: '',
    orderBy: 'desc',
    name: undefined,
    fromDate: undefined,
    toDate: undefined,
    concessionId: undefined,
    state: undefined,
  },

  [tableIds.VALIDATION_ACTIONS]: {
    id: tableIds.VALIDATION_ACTIONS,
    take: takeBase,
    cursor: '',
    concessionId: undefined,
    capitolId: undefined,
    subCapitolId: undefined,
    orderBy: 'desc',
    toDate: undefined,
    fromDate: undefined,
    indexType: undefined,
    indexExpedition: undefined,
    classification: undefined,
    state: undefined,
  },
};

type actionType = {
  type: string;
  payload: {
    id: string;
    data: {
      take?: number | undefined;
      cursor?: string | undefined;
      roadId?: string | undefined;
      concessionId?: string | undefined;
      interlocutorGroupId?: string | undefined;
      fromDate?: string | undefined;
      toDate?: string | undefined;
      orderBy?: 'desc' | 'asc' | undefined;
      indexType?: number | undefined;
      indexExpedition?: number | undefined;
      classification?: string | undefined;
      capitolId?: string | undefined;
      subCapitolId?: string | undefined;
      state?: string | undefined;
      mediumKnowledgeId?: string | undefined;
      weatherId?: string | undefined;
      type?: string | undefined;
    };
  };
};

export default function cursorTables(
  state = initialCursorState,
  { type, payload }: actionType
) {
  switch (type) {
    case SET_CURSOR:
      return {
        ...state,
        [payload.id]: {
          id: payload.id,
          ...payload.data,
        },
      };
    default:
      return state;
  }
}

import React, { useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useWatch } from 'react-hook-form';
import isEqual from 'lodash.isequal';
import { useLocation } from 'react-router-dom';

//Components
import Tabs from '../../display/Tabs';
import { SelectionOT } from '../../display/SelectionOT';
import DropDownInput from '../../inputs/DropDown/DropDownInput';

// Styled Components
import { ContainerOT, ErrorMessage } from './styles';

// Types
import { FormCreateOTProps } from './types';
import { modalIds } from '../../modals/SuperModal/types';
import { OptionSelected } from '../../inputs/DropDown/DropDownList/types';
import { ImperativeRefInput } from '../../inputs/DropDown/DropDownInput/types';
import { ControllerProps } from 'react-hook-form/dist/types/controller';
import { NewCommunication } from '../../../types/communication';

// Selectors
import { getCurrentRowDataModal } from '../../../state/selectors/ui/modal';

// Actions
import { openModal } from '../../../state/actions/ui/modal';

// Graphql
import { client } from '../../../gql/client';
import {
  GET_CAPITOL_BY_ID,
  GET_SUB_CAPITOL_BY_ID,
  GET_SUB_CAPITOLS_BY_IDS,
} from '../../../gql/queries/orders/predefinedData';

// Constants
import { InputsContainer } from '../components/MainStyled';
import { fetchDefaultData } from '../../../utils/fetchData';
import * as Sentry from '@sentry/react';

export const CreateOT = ({
  control,
  setValue,
  errors,
  namingPersistForm,
  selectorIndexTab,
  namingTabsType,
  isNecessaryTagSpecial = true,
}: FormCreateOTProps) => {
  const location = useLocation();
  const isEditForm = location.pathname
    .split('/')
    .some((path) => path === 'edit-communication');

  const dispatch = useDispatch();

  const capitolDropDownRef = useRef<ImperativeRefInput>(null);
  const subCapitolDropDownRef = useRef<ImperativeRefInput>(null);

  const predefine = useWatch({
    control,
    name: 'predefine',
  });

  const specialTag = useWatch({
    control,
    name: 'specialTag',
  });

  const classification = useWatch({
    control,
    name: 'classification',
  });

  const capitol = useWatch({
    control,
    name: 'capitol',
  });

  const subCapitols = useWatch({
    control,
    name: 'subCapitols',
  });

  const subCapitol = useWatch({
    control,
    name: 'subCapitol',
  });

  const openModalAction = useCallback(() => {
    dispatch(
      openModal(modalIds.RELATES_COMMUNICATION_MODAL, { namingPersistForm })
    );
  }, [namingPersistForm]);

  const selector = useSelector(
    getCurrentRowDataModal(namingPersistForm),
    isEqual
  );

  const onSelectCapitol = useCallback(
    (
      option: OptionSelected | undefined,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onChange: (...event: any[]) => void
    ) => {
      const tasks = async () => {
        const { readSubCapitols } = client.readQuery({
          query: GET_SUB_CAPITOLS_BY_IDS,
          variables: {
            ids: option?.data ?? [],
          },
        });
        if (readSubCapitols.length === 0) {
          await fetchDefaultData();
          const { readSubCapitols } = client.readQuery({
            query: GET_SUB_CAPITOLS_BY_IDS,
            variables: {
              ids: option?.data ?? [],
            },
          });
          const sortedSubCapitols = [...readSubCapitols].sort((a, b) =>
            a.name.localeCompare(b.name)
          );

          setValue('subCapitols', sortedSubCapitols);
        } else {
          const sortedSubCapitols = [...readSubCapitols].sort((a, b) =>
            a.name.localeCompare(b.name)
          );

          setValue('subCapitols', sortedSubCapitols);
        }
        subCapitolDropDownRef.current?.clearSelected();
        setValue('subCapitol', '');
        onChange(option?.value ?? '');
      };

      tasks().catch((err) => {
        Sentry.captureException(err);
        console.log(err);
      });
    },
    []
  );

  const onSelectPredefined = useCallback(
    (
      option: OptionSelected | undefined,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onChange: (...event: any[]) => void
    ) => {
      const tasks = async () => {
        onChange(option?.value ?? '');

        const {
          data: { capitol },
        } = await client.query({
          query: GET_CAPITOL_BY_ID,
          variables: {
            id: option?.data.capitolId,
          },
        });

        const { readSubCapitols } = client.readQuery({
          query: GET_SUB_CAPITOLS_BY_IDS,
          variables: {
            ids: capitol?.subCapitolsIDs ?? [],
          },
        });

        if (readSubCapitols.length === 0) {
          await fetchDefaultData();
          const { readSubCapitols } = client.readQuery({
            query: GET_SUB_CAPITOLS_BY_IDS,
            variables: {
              ids: capitol?.subCapitolsIDs ?? [],
            },
          });

          const sortedSubCapitols = [...readSubCapitols].sort((a, b) =>
            a.name.localeCompare(b.name)
          );
          setValue('subCapitols', sortedSubCapitols);
        } else {
          const sortedSubCapitols = [...readSubCapitols].sort((a, b) =>
            a.name.localeCompare(b.name)
          );
          setValue('subCapitols', sortedSubCapitols);
        }

        capitolDropDownRef.current?.setSelectedOption({
          label: capitol.name,
          value: capitol.id,
          data: capitol.subCapitolsIDs,
        });

        const {
          data: { subCapitol },
        } = await client.query({
          query: GET_SUB_CAPITOL_BY_ID,
          variables: {
            id: option?.data.subCapitolId,
          },
        });

        subCapitolDropDownRef.current?.setSelectedOption({
          label: subCapitol.name,
          value: subCapitol.id,
          data: subCapitol.elementOrdersIds,
        });
      };
      tasks().catch((err) => {
        Sentry.captureException(err);
        console.log(err);
      });
    },
    [capitolDropDownRef, subCapitolDropDownRef]
  );

  const renderSubCapitols = useCallback<
    ControllerProps<NewCommunication, 'subCapitol'>['render']
  >(
    ({ field: { onChange }, formState: { defaultValues } }) => {
      const options =
        subCapitols?.map((subCapitol) => ({
          label: subCapitol?.name || '',
          value: subCapitol?.id || '',
          data: subCapitol?.elementOrdersIds || '',
        })) || [];
      return (
        <DropDownInput
          ref={subCapitolDropDownRef}
          inputSize="L"
          onChangeSelected={(option) => onChange(option?.value ?? '')}
          typeDropDown="Default"
          labelText="Subcapitol"
          placeholder="Selecciona"
          defaultValue={subCapitol || defaultValues?.subCapitol}
          borderError={!!errors.subCapitol}
          options={options}
        />
      );
    },
    [subCapitols, errors]
  );

  const renderCapitols = useCallback<
    ControllerProps<NewCommunication, 'capitol'>['render']
  >(
    ({ field: { onChange }, formState: { defaultValues } }) => {
      const options =
        defaultValues?.capitols?.map((capitol) => ({
          label: capitol?.name || '',
          value: capitol?.id || '',
          data: capitol?.subCapitolsIDs || '',
        })) || [];
      return (
        <DropDownInput
          ref={capitolDropDownRef}
          inputSize="L"
          onChangeSelected={(option) => onSelectCapitol(option, onChange)}
          typeDropDown="Default"
          labelText="Capítol"
          placeholder="Selecciona"
          defaultValue={capitol || defaultValues?.capitol}
          borderError={!!errors.capitol}
          options={options}
        />
      );
    },
    [capitol, errors]
  );

  const tabData = [
    {
      label: isNecessaryTagSpecial
        ? 'Crear nova O.T.'
        : "Dades de l'O.T. de comunicació",
      content: (
        <InputsContainer>
          <Controller
            control={control}
            name="predefine"
            render={({ field: { onChange }, formState: { defaultValues } }) => {
              const options =
                defaultValues?.predefinedOrders?.map((predefinedOrder) => ({
                  label: predefinedOrder?.name || '',
                  value: predefinedOrder?.id || '',
                  data: {
                    capitolId: predefinedOrder?.capitolId || '',
                    subCapitolId: predefinedOrder?.subCapitolId || '',
                    elementOrderId: predefinedOrder?.elementOrderId || '',
                  },
                })) || [];
              return (
                <DropDownInput
                  inputSize="XL"
                  onChangeSelected={(option) =>
                    onSelectPredefined(option, onChange)
                  }
                  borderError={!!errors.predefine}
                  typeDropDown="Default"
                  labelText="Predefinits"
                  placeholder="Selecciona"
                  defaultValue={predefine || defaultValues?.predefine}
                  options={options}
                />
              );
            }}
          />
          {isNecessaryTagSpecial && (
            <Controller
              control={control}
              name="specialTag"
              render={({
                field: { onChange },
                formState: { defaultValues },
              }) => {
                const options =
                  defaultValues?.specialTagType?.map((specialTag) => ({
                    label: specialTag?.name || '',
                    value: specialTag?.value || '',
                  })) || [];
                return (
                  <DropDownInput
                    inputSize="S"
                    onChangeSelected={(option) => onChange(option?.value ?? '')}
                    borderError={!!errors.specialTag}
                    typeDropDown="Default"
                    labelText="Etiqueta especial"
                    placeholder="Selecciona"
                    defaultValue={specialTag || defaultValues?.specialTag}
                    options={options}
                  />
                );
              }}
            />
          )}

          <Controller
            control={control}
            name="classification"
            render={({ field: { onChange }, formState: { defaultValues } }) => {
              const options =
                defaultValues?.classifications?.map((classification) => ({
                  label: classification?.name || '',
                  value: classification?.id || '',
                })) || [];
              return (
                <DropDownInput
                  inputSize="S"
                  onChangeSelected={(option) => onChange(option?.value ?? '')}
                  typeDropDown="Default"
                  labelText="Classificació"
                  placeholder="Selecciona"
                  defaultValue={classification || defaultValues?.classification}
                  borderError={!!errors.classification}
                  options={options}
                />
              );
            }}
          />

          <Controller
            control={control}
            name="capitol"
            render={renderCapitols}
          />

          <Controller
            control={control}
            name="subCapitol"
            render={renderSubCapitols}
          />
        </InputsContainer>
      ),
    },
    {
      label: 'Relacionar amb O.T.',
      content: (
        <>
          <Controller
            control={control}
            name="selectionOT"
            render={({ field: { onChange } }) => (
              <SelectionOT
                label={selector?.data ? '' : 'Selecciona una O.T.'}
                onChange={onChange}
                data={selector}
                openModal={openModalAction}
                namingPersistForm={namingPersistForm}
                type={'OT'}
              />
            )}
          />
          {errors.selectionOT && (
            <ErrorMessage size="XS" colorText="red">
              *Aquest camp és obligatori per relacionar una OT
            </ErrorMessage>
          )}
        </>
      ),
    },
    {
      label: 'Relacionar amb Campanya',
      content: (
        <>
          <Controller
            control={control}
            name="selectionOT"
            render={({ field: { onChange } }) => (
              <SelectionOT
                label={selector?.data ? '' : 'Selecciona una Campanya'}
                onChange={onChange}
                data={selector}
                openModal={openModalAction}
                namingPersistForm={namingPersistForm}
                type={'Campanya'}
              />
            )}
          />
          {errors.selectionOT && (
            <ErrorMessage size="XS" colorText="red">
              *Aquest camp és obligatori per relacionar una OT
            </ErrorMessage>
          )}
        </>
      ),
    },
  ];

  return (
    <ContainerOT>
      <Tabs
        data={tabData}
        typeTab={namingTabsType}
        defaultIndex={selectorIndexTab}
        isFirstRemoved={isEditForm}
      />
    </ContainerOT>
  );
};

// Dependencies
import React, { useState } from 'react';

// Styles
import {
  Container,
  InputDouble,
  Label,
  LeftLabel,
  RightLabel,
  SwitchDouble,
} from './styles';

// Types
import { DoubleSwitchProps } from './types';

const DoubleSwitch = ({
  label,
  isDisabled,
  className,
  onToggle,
  currentState = false,
}: DoubleSwitchProps): JSX.Element => {
  const [toggleDoubleOn, setToggleDoubleOn] = useState(currentState);
  const handleClick = () => {
    isDisabled ? null : setToggleDoubleOn(!toggleDoubleOn);
    onToggle && onToggle();
  };
  return (
    <Container className={className}>
      {label ? <Label size="S">{label}</Label> : null}

      <SwitchDouble defaultChecked={toggleDoubleOn}>
        {toggleDoubleOn ? <LeftLabel>SÍ</LeftLabel> : null}
        <InputDouble
          type="checkbox"
          checked={toggleDoubleOn}
          onChange={handleClick}
        />
        {toggleDoubleOn ? null : <RightLabel>NO</RightLabel>}
      </SwitchDouble>
    </Container>
  );
};

export default DoubleSwitch;

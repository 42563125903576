import { gql } from '@apollo/client';

export const CREATE_NEW_GENERIC_ORDER = gql`
  mutation CreateGenericOrder($input: NewGenericOrder!) {
    createGenericOrder(input: $input) {
      id
      concessionIDs
      roadIDs
      teamIDs
      track
      direction
      margin
      lane
      pkInitKm
      pkInitMeter
      pkEndKm
      pkEndMeter
      orderIDs
    }
  }
`;

export const UPDATE_GENERIC_ORDER = gql`
  mutation UpdateGenericOrder($input: UpdateGenericOrder!) {
    updateGenericOrder(input: $input) {
      id
      concessionIDs
      roadIDs
      teamIDs
      track
      direction
      margin
      lane
      pkInitKm
      pkInitMeter
      pkEndKm
      pkEndMeter
      orderIDs
    }
  }
`;

export const UPDATE_GENERIC_ORDER_RELATIONSHIPS = gql`
  mutation UpdateGenericOrderRelationships(
    $input: UpdateGenericOrderRelationships!
  ) {
    updateGenericOrderRelationships(input: $input) {
      id
      concessionIDs
      roadIDs
      teamIDs
      track
      direction
      margin
      lane
      pkInitKm
      pkInitMeter
      pkEndKm
      pkEndMeter
      orderIDs
    }
  }
`;

import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

// Styles utils
import { colors } from '../../../../../styles/colors';

// Components
import { ArrowBottom } from '../../../../../assets/icons/ArrowBottom';

export const Link = styled(NavLink)`
  display: flex;
  text-decoration: none;
  border-radius: 0.5rem;
  padding: 1rem;

  &.active {
    background-color: ${colors.verySoftRed};
    p {
      text-shadow: 0 0 1px;
    }
  }
`;

export const MenuButton = styled(NavLink)`
  display: flex;
  gap: 1rem;
  text-decoration: none;
  transition: opacity 0.2s ease-out;
  align-items: center;

  p {
    border-radius: 0.5rem;
    width: 100%;
    padding: 1rem;
  }

  &.active {
    p {
      background-color: ${colors.verySoftRed};
      text-shadow: 0 0 1px;
    }
  }
`;

export const Item = styled.li``;

interface ArrowIconProps {
  isActive: boolean;
}

export const ContainerArrowIcon = styled.div`
  svg > path {
    fill: ${colors.garnet};
  }
  ${({ isActive }: ArrowIconProps) => `
    transform: rotate(${isActive ? '-90deg' : '0deg'});
    transition: transform 0.2s;
    `}
`;

interface ListProps {
  isCollapsed: boolean;
  height: number;
}

export const List = styled.ul`
  transition: all 0.2s;
  margin-left: 4rem;

  ${({ isCollapsed, height }: ListProps) => `
    ${
      isCollapsed
        ? `
      height: ${height}rem;
      visibility: visible;
      opacity: 1
    `
        : `
      height: 0rem;
      visibility: hidden;
      opacity: 0;
    `
    }
  `};
`;

export const SubItem = styled.li``;

export const ArrowDown = styled(ArrowBottom)``;

import React from 'react';
import styled from 'styled-components';

// Styles
import { spaces } from '../../../styles/spaces';

// Components
import { Typography } from '../../display/Typography';
import { InputNumber } from '../InputNumber';

type labelProps = React.LabelHTMLAttributes<HTMLLabelElement>;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spaces.xxxs}rem;
`;

export const InputsContainer = styled.div`
  display: flex;
  gap: ${spaces.xxxs}rem;
`;

export const Label = styled(Typography)<labelProps>``;

export const InputValue = styled(InputNumber)``;

import { defer, LoaderFunction } from 'react-router-dom';

// Sentry
import * as Sentry from '@sentry/react';

// GQL
import { client } from '../../../../gql/client';
import { FETCH_USERS } from '../../../../gql/queries/users';
import { GET_CONCESSIONS } from '../../../../gql/queries/orders/orders';
import { GET_CONCESSION_BY_ID } from '../../../../gql/queries/geoPositions/roads';

// Utils
import { AuthContextType } from '../../../../hooks/useAuth';

// Constants
import { formUserManagementNaming } from '../../../../components/forms/AddUserForm/constants';
import { decompressSessionStorage } from '../../../../utils/sessionStorage';

const loadTableData = async (
  userData: AuthContextType | null,
  concessions: { id: string; name: string }[]
) => {
  try {
    const usersToReturnWithConcession = [];
    if (userData?.user) {
      let usersToReturn;
      const {
        data: { users },
      } = await client.query({
        query: FETCH_USERS,
        fetchPolicy: 'cache-only',
      });

      if (!users || users.length === 0) {
        const {
          data: { users },
        } = await client.query({
          query: FETCH_USERS,
        });

        usersToReturn = users;
      } else {
        usersToReturn = users;
      }

      for (const user of usersToReturn) {
        let concession;
        if (user.concessionIDs && user.concessionIDs.length === 1) {
          concession = await client.query({
            query: GET_CONCESSION_BY_ID,
            variables: {
              id: user.concessionIDs[0],
            },
          });
        }
        usersToReturnWithConcession.push({
          id: user.id,
          name: user.name,
          surName: user.surName,
          email: user.email,
          rolActive: user.rolActive,
          concession:
            user.concessionIDs && user.concessionIDs.length > 0
              ? user.concessionIDs.length > 1
                ? concessions.map((concessionInner) => concessionInner.name)
                : [concession?.data.concession.name]
              : [],
          state: user.isActive ? 'active' : 'inactive',
          isActive: user.isActive,
        });
      }
    }
    return usersToReturnWithConcession;
  } catch (err) {
    Sentry.captureException(err);
    console.log('> User management loading error:', err);
    return [];
  }
};

export const UsersManagementLoader: (
  userData: AuthContextType | null
) => LoaderFunction | undefined = (userData) => async () => {
  const {
    data: { concessions },
  } = await client.query({
    query: GET_CONCESSIONS,
  });
  const data = await loadTableData(userData, concessions);

  const activeUsers = data.filter(
    (user: { isActive: boolean }) => user.isActive
  );
  const inactiveUsers = data.filter(
    (user: { isActive: boolean }) => !user.isActive
  );

  let cacheData =
    sessionStorage.getItem(formUserManagementNaming.NEW_USER_MANAGEMENT_FORM) ||
    {};

  if (Object.keys(cacheData).length > 0) {
    cacheData = JSON.parse(decompressSessionStorage(cacheData as string));
  }

  const concessionsWithAll = [
    { id: 'all', name: 'Totes les concessions' },
    ...concessions,
  ];

  return defer({
    data: {
      activeUsers,
      inactiveUsers,
      cacheData: {
        ...cacheData,
        concessions: concessionsWithAll,
      },
    },
  });
};

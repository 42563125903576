import { createStore } from 'redux';
import { composeWithDevTools } from '@redux-devtools/extension';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import reduxReset from 'redux-reset';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// Compressor
import createCompressor from './transform-persists';

// Reducers
import rootReducer from './reducers';

// Actions
import { CLEAN_STATE } from './actions/main';

// eslint-disable-next-line @typescript-eslint/no-explicit-any

const blacklist = ['modal'];
const compressor = createCompressor({ blacklist });

const persistConfig = {
  timeout: null,
  key: 'root',
  storage: storage,
  blacklist,
  transforms: [compressor],
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const persistedReducer = persistReducer(persistConfig, rootReducer);

const composeEnhancer = [reduxReset(CLEAN_STATE)];

export const store = createStore(
  persistedReducer,
  composeWithDevTools(...composeEnhancer)
);
export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

import { useEffect } from 'react';
import { useWatch, type Control } from 'react-hook-form';

// Compression
import { compressSessionStorage } from '../utils/sessionStorage';

export const useCacheFormInSessionStorage = <T extends Record<string, unknown>>(
  formKey: string,
  control: Control<T>
) => {
  // Cache the new communication form on changes to keep them when loading the app again
  const formValues = useWatch({ control });
  useEffect(() => {
    sessionStorage.setItem(
      formKey,
      compressSessionStorage(JSON.stringify(formValues))
    );
  }, [formValues]);
};

// Dependencies
import styled from 'styled-components';

//Styles
import { colors } from '../../../../styles/colors';
import {
  breakPoints,
  percentBaseResponsive,
  spaces,
} from '../../../../styles/spaces';
import { fontSize, fontWeight } from '../../../../styles/fonts';

// Types
import { ContainerProps, ListItemProps } from './types';

export const DropDownListContainer = styled.div`
  z-index: 999;
  position: relative;
`;

export const List = styled.ul<ContainerProps>`
  position: absolute;
  border-radius: 0.5rem;
  top: ${spaces.xxxs}rem;
  color: ${colors.greyVeryDark};
  background-color: ${colors.white};
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  min-width: 100%;
  width: auto;
  max-height: 35rem;
  overflow: auto;
  @media (max-width: ${breakPoints.smallDesktop * percentBaseResponsive}rem) {
    max-height: 18rem;
  }
  @media (max-width: ${breakPoints.landscapeTablet *
    percentBaseResponsive}rem) {
    max-height: 17rem;
  }
`;

export const ListItem = styled.li<ListItemProps>`
  display: flex;
  align-items: center;
  list-style: none;
  font-size: ${fontSize.S};
  padding: 1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${(props) =>
    props.isActive && {
      backgroundColor: colors.greyClear,
      fontWeight: fontWeight.medium,
    }};
  :hover {
    background-color: ${colors.greyClear};
    font-weight: ${fontWeight.medium};
  }
  :focus {
    background-color: ${colors.greyClear};
    font-weight: ${fontWeight.medium};
    outline: none;
  }

  @media (max-width: ${breakPoints.landscapeTablet *
    percentBaseResponsive}rem) {
    font-size: ${fontSize.XS};
  }
`;

import React, { createContext, ReactNode, useMemo } from 'react';

// Types
import { AuthType } from '../types/auth';

import { useSessionStorage } from './useSessionStorage';

export interface AuthContextType {
  user: AuthType;
  setUser: (data: AuthType | null) => void;
}

export const AuthContext = createContext<AuthContextType | null>(null);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useSessionStorage('user');

  const value = useMemo(
    () => ({
      user,
      setUser,
    }),
    [user]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

import React, { useCallback, useEffect, useState } from 'react';

import {
  Container,
  Input,
  ArrowContainer,
  SecondaryContainer,
  ArrowLabel,
  DirectionUpIcon,
  DirectionDownIcon,
  DirectionBothIcon,
  DirectionLeftIcon,
  DirectionRightIcon,
  TextLabel,
  DirectionBothVerticalIcon,
  Text,
  ArrowContainerCustom,
} from './styles';

import { ArrowProps, Direction } from './types';

// Media
import { useIsLandscapeTablet } from '../../../../hooks/useMediaBreakPoints';
import { ErrorMessage } from '../../InputText/styles';
import { UNSPECIFIC } from '../../../../types/utils';

export const ArrowDirectionButton = ({
  directions,
  name = 'direction',
  label,
  separated,
  onChangeDirection,
  defaultValue = '',
  required = false,
  error,
  borderError,
  disabled,
  hasUnspecific = false,
  ...props
}: ArrowProps): JSX.Element => {
  const isLandscapeTablet = useIsLandscapeTablet();

  const [selected, setSelected] = useState<Direction | string>(defaultValue);
  const directionWithOutUnspecific = [...directions];
  if (hasUnspecific) {
    directionWithOutUnspecific.pop();
  }

  const handleChangeArrow: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setSelected(e.target.value);
  };

  const handleClickArrow: React.MouseEventHandler<HTMLInputElement> = (e) => {
    if (selected === e.currentTarget.value) {
      setSelected('');
    }
  };
  useEffect(() => {
    setSelected(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    onChangeDirection?.(selected as Direction);
  }, [selected]);

  return (
    <Container {...props}>
      {typeof label === 'string' && (
        <TextLabel size={isLandscapeTablet ? 'XS' : 'S'} variant="regular">
          {label}
        </TextLabel>
      )}
      <SecondaryContainer>
        {directionWithOutUnspecific.map((dir, index) => {
          const renderIcon = useCallback(() => {
            switch (dir) {
              case 'up':
                return <DirectionUpIcon />;
              case 'top':
                return <DirectionUpIcon />;
              case 'down':
                return <DirectionDownIcon />;
              case 'right':
                return <DirectionRightIcon />;
              case 'left':
                return <DirectionLeftIcon />;
              case UNSPECIFIC:
                return <Text>Sense especificar</Text>;
              case 'both':
                if (name === 'margin' || label === 'Marge') {
                  return <DirectionBothIcon />;
                } else if (name === 'sentit' || label === 'Sentit') {
                  return <DirectionBothVerticalIcon />;
                }
            }
          }, [dir]);

          return (
            <ArrowLabel key={index}>
              {typeof label === 'object' && (
                <TextLabel
                  size={isLandscapeTablet ? 'XS' : 'S'}
                  variant="regular"
                >
                  {label[index]}
                </TextLabel>
              )}

              <ArrowContainer
                hasUnspecific={hasUnspecific}
                separated={separated}
                firstButton={index === 0}
                lastButton={index === directionWithOutUnspecific.length - 1}
                selectedArrow={selected === dir}
                borderError={borderError}
                tabIndex={0}
                onKeyDown={(event) =>
                  event.key === ' ' &&
                  (event.preventDefault(), setSelected(dir))
                }
              >
                <Input
                  type="radio"
                  name={name}
                  onChange={handleChangeArrow}
                  onClick={!required ? handleClickArrow : undefined}
                  checked={selected === dir}
                  value={dir}
                  tabIndex={-1}
                  disabled={disabled}
                />
                {renderIcon()}
              </ArrowContainer>
            </ArrowLabel>
          );
        })}
      </SecondaryContainer>
      {hasUnspecific && (
        <ArrowLabel key={3}>
          {typeof label === 'object' && (
            <TextLabel size={isLandscapeTablet ? 'XS' : 'S'} variant="regular">
              {label[3]}
            </TextLabel>
          )}

          <ArrowContainerCustom
            separated={true}
            firstButton={true}
            lastButton={true}
            selectedArrow={selected === UNSPECIFIC}
            borderError={borderError}
            tabIndex={0}
            onKeyDown={(event) =>
              event.key === ' ' &&
              (event.preventDefault(), setSelected(UNSPECIFIC))
            }
          >
            <Input
              type="radio"
              name={name}
              onChange={handleChangeArrow}
              onClick={!required ? handleClickArrow : undefined}
              checked={selected === UNSPECIFIC}
              value={UNSPECIFIC}
              tabIndex={-1}
              disabled={disabled}
            />
            <Text>Sense especificar</Text>
          </ArrowContainerCustom>
        </ArrowLabel>
      )}

      {error && (
        <ErrorMessage colorText={'lightCoralRed'} size={'XXS'}>
          {error}
        </ErrorMessage>
      )}
    </Container>
  );
};

import { AccordionSubItem } from '../components/navigation/Accordion/types';
import {
  optionsTypes,
  recursiveTypes,
} from '../components/navigation/HamburguerMenu/types';
import { Role } from '../types/auth';

const hasPermission = (route: recursiveTypes, userRole: Role) => {
  return route.permissions && route.permissions?.includes(userRole);
};

export const filterAllowedRoutes = (
  routes: recursiveTypes[],
  userRole: Role
): recursiveTypes[] => {
  const allowedRoutes: recursiveTypes[] = [];
  for (const route of routes) {
    if (hasPermission(route, userRole)) {
      if (route.label && route.value) {
        allowedRoutes.push({
          label: route.label,
          value: route.value,
          data: route.data
            ? (filterAllowedRoutes(
                route.data as recursiveTypes[],
                userRole
              ) as optionsTypes[])
            : [],
        });
      } else if (route.title && (route.path || route.path === '')) {
        allowedRoutes.push({
          title: route.title,
          path: route.path,
          subItems: route.subItems
            ? (filterAllowedRoutes(
                route.subItems as recursiveTypes[],
                userRole
              ) as AccordionSubItem[])
            : [],
        });
      } else if (route.text && (route.path || route.path === '')) {
        allowedRoutes.push({
          text: route.text,
          path: route.path,
        });
      }
    }
  }
  return allowedRoutes;
};

import { ActionFunction } from 'react-router-dom';
import { saveData } from './Action/saveData';

// Sentry
import * as Sentry from '@sentry/react';

export const AnimalsValidationAction: ActionFunction = async ({ request }) => {
  const formData = await request.formData();

  try {
    return saveData(formData);
  } catch (err) {
    Sentry.captureException(err);
    console.log('Error save action:', err);
  }
  return null;
};

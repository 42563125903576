// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getCurrentIndexTabFormByTab = (tab: string) => (state: any) =>
  state.tabs[tab]?.index === undefined ? 0 : state.tabs[tab].index;

export const getCurrentIndexTabFormByTabVertical =
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (tab: string) => (state: any) => {
    const tabIndex = state.tabsVertical[tab]?.index;
    return tabIndex !== undefined ? tabIndex : 0;
  };

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getNewActionTabsState = (state: any) => {
  return state.actionTabs.path;
};

import styled from 'styled-components';

// Styles utils
import { colors } from '../../../styles/colors';

export const LoaderContainer = styled.div`
  width: 5rem;
  height: 5rem;
  position: relative;
  margin: auto;

  &::before {
    content: '';
    width: 5rem;
    height: 0.5rem;
    background-color: ${colors.garnetLight};
    position: absolute;
    top: 6rem;
    left: 0;
    border-radius: 50%;
    animation: shade 0.5s linear infinite;
  }

  &::after {
    content: '';
    width: 100%;
    height: 100%;
    background-color: ${colors.garnet};
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 0.5rem;
    animation: bounce 0.5s linear infinite;
  }
  @keyframes bounce {
    15% {
      border-bottom-right-radius: 0.3rem;
    }
    25% {
      transform: translateY(0.9rem) rotate(22.5deg);
    }
    50% {
      transform: translateY(1.9rem) rotate(45deg);
      border-bottom-right-radius: 4rem;
    }
    75% {
      transform: translateY(0.9rem) rotate(67deg);
    }
    100% {
      transform: translateY(0) rotate(90deg);
    }
  }

  @keyframes shade {
    0%,
    100% {
      transform: scale(1, 1);
    }
    50% {
      transform: scale(1.3, 1);
    }
  }
`;

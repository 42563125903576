// Defs
export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';
export const CLOSED_MODAL = 'CLOSED_MODAL';

export function openModal(id: string, data: unknown = {}) {
  return {
    type: OPEN_MODAL,
    payload: {
      id,
      data,
    },
  };
}

export function closeModal() {
  return {
    type: CLOSE_MODAL,
  };
}

export function closedModal() {
  return {
    type: CLOSED_MODAL,
  };
}

import styled, { createGlobalStyle } from 'styled-components';
import { breakPoints, percentBaseResponsive } from './spaces';
import { fontSize } from './fonts';

export const ReuseComponent = styled.div``;

export const GlobalStyle = createGlobalStyle`
  // Fonts
  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-display: block;
    src: url('/fonts/Poppins-Regular.ttf');
  }

  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-display: block;
    src: url('/fonts/Poppins-Medium.ttf');
  }

  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-display: block;
    src: url('/fonts/Poppins-SemiBold.ttf');
  }

  /**
   * adjust base px to rem and styles root
  */

  :root {
    font-size: 62.5%;
    line-height: 1.5;
    width: 100%;
    display: flex;
    justify-content: center;
    font-family: 'Poppins',sans-serif;
  }
  #root {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  // Media Queries
  // Small desktop screen
  @media only screen and (max-width: ${
    breakPoints.smallDesktop * percentBaseResponsive
  }rem) {

  }

  // Tablet Horizontal or orientation landscape and big devices
  @media only screen and (max-width: ${
    breakPoints.landscapeTablet * percentBaseResponsive
  }rem) {

  }

  // Tablet Vertical or orientation portrait and big devices
  @media only screen and (max-width: ${
    breakPoints.portraitTablet * percentBaseResponsive
  }rem) {

  }

  /*! normalize.css v8.0.1 | Msuerte!IT License | github.com/necolas/normalize.css */
  /**
   * IE10+ doesn't honor \`<meta name="viewport">\` in some cases
   */

  @-ms-viewport {
    width: device-width;
  }

  /**
    * general reset
   */

  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video, main {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }

  /**
    * HTML5 display-role reset for older browsers
   */

  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section,
  main, summary {
    display: block;
  }

  /**
    * inherit box model for all elements
   */

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  /* Document
     ========================================================================== */

  /**
   * 1. Correct the line height in all browsers.
   * 2. Prevent adjustments of font size after orientation changes in iOS.
   * 1. set border-box for inheritance
   * 2. avoid 300ms click delay on touch devices that support the \`touch-action\`
   *    CSS property
   * 3. Prevent adjustments of font size after orientation changes in IE, on
   *    Windows Phone and iOS.
   * 4. Setting @viewport causes scrollbars to overlap content in IE11 and Edge,
   *    so we force a non-overlapping, non-auto-hiding scrollbar to counteract.
   * 5. Change the default tap highlight to be completely transparent in iOS.
   */

  html {
    line-height: 1.5; /* 1 */
    -webkit-text-size-adjust: 100%; /* 2 */
    /* 1 */
    box-sizing: border-box;
    /* 2 */
    touch-action: manipulation;
    /* 3 */
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    /* 4 */
    -ms-overflow-style: scrollbar;
    /* 5 */
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  /* Sections
     ========================================================================== */

  /**
   * Remove the margin in all browsers.
   */

  body {
    margin: 0;
    width: 100%;
  }

  /**
 * Future-proof rule: in browsers that support :focus-visible, suppress the focus outline
 * on elements that programmatically receive focus but wouldn't normally show a visible
 * focus outline. In general, this would mean that the outline is only applied if the
 * interaction that led to the element receiving programmatic focus was a keyboard interaction,
 * or the browser has somehow determined that the user is primarily a keyboard user and/or
 * wants focus outlines to always be presented.
 *
 * See https://developer.mozilla.org/en-US/docs/Web/CSS/:focus-visible
 * and https://developer.paciellogroup.com/blog/2018/03/focus-visible-and-backwards-compatibility/
 */

  [tabindex="-1"]:focus:not(:focus-visible) {
    outline: 0 !important;
  }


  /**
   * Lists
   */

  ol, ul {
    list-style: none;
  }

  /**
  * Quotes
  */

  blockquote, q {
    quotes: none;
  }

  blockquote::before,
  blockquote::after,
  q::before,
  q::after {
    content: '';
    content: none;
  }


  /**
   * Tables
   */

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  caption {
    caption-side: bottom;
  }


  /**
   * Render the main element consistently in IE.
   */

  main {
    display: block;
  }

  /**
    * Correct the font size and margin on h1 elements within section and
    * article contexts in Chrome, Firefox, and Safari.
  */

  h1 {
    font-size: 2rem;
    margin: 0.67rem 0;
  }

  /* Grouping content
    ========================================================================== */

  /**
    * 1. Add the correct box sizing in Firefox.
    * 2. Show the overflow in Edge and IE.
  */

  hr {
    box-sizing: content-box; /* 1 */
    height: 0; /* 1 */
    overflow: visible; /* 2 */
  }

  /**
   * 1. Correct the inheritance and scaling of font size in all browsers.
   * 2. Correct the odd rem font sizing in all browsers.
   */

  pre {
    font-family: monospace, monospace; /* 1 */
    font-size: 1rem; /* 2 */
  }

  /* Text-level semantics
     ========================================================================== */

  /**
   1.Remove the gray background on active links in IE 10.
   2. Remove gaps in links underline in iOS 8+ and Safari 8+.
   */

  a {
    background-color: transparent;
    -webkit-text-decoration-skip: objects;
  }

  /**
   * 1. Remove the bottom border in Chrome 57-
   * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
   */

  abbr[title] {
    border-bottom: none; /* 1 */
    text-decoration: underline; /* 2 */
    text-decoration: underline dotted; /* 2 */
    cursor: help;
    /* 4 */
    text-decoration-skip-ink: none;
  }

  address {
    font-style: normal;
    line-height: inherit;
  }

  /**
   * Add the correct font weight in Chrome, Edge, and Safari.
   */

  b,
  strong {
    font-weight: bolder;
  }

  /**
   * 1. Correct the inheritance and scaling of font size in all browsers.
   * 2. Correct the odd em font sizing in all browsers.
   */
  pre,
  code,
  kbd,
  samp {
    font-family: monospace, monospace; /* 1 */
    font-size: 1rem; /* 2 */
  }

  pre {
    /* 2 */
    overflow: auto;
    /* 3 */
    -ms-overflow-style: scrollbar;
  }

  /**
   * Add the correct font size in all browsers.
   */

  small {
    font-size: 80%;
  }

  /**
   * Prevent sub and sup elements from affecting the line height in
   * all browsers.
   */

  sub,
  sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
  }

  sub {
    bottom: -0.25rem;
  }

  sup {
    top: -0.5rem;
  }

  /**
  * Prevent \`em\` being affected from global reset
  */
  em {
    font-style: italic;
  }


  /* Embedded content
     ========================================================================== */

  /**
   * Remove the border on images inside links in IE 10.
   */

  img {
    border-style: none;
  }

  /* Forms
     ========================================================================== */

  /**
   * 1. Change the font styles in all browsers.
   * 2. Remove the margin in Firefox and Safari.
   */

  button,
  input,
  optgroup,
  select,
  textarea {
    font-family: inherit; /* 1 */
    font-size: ${fontSize.S}; /* 1 */
    line-height: 1.5; /* 1 */
    margin: 0; /* 2 */

  }

  /**
   * Show the overflow in IE.
   * 1. Show the overflow in Edge.
   */

  button,
  input { /* 1 */
    overflow: visible;
  }

  /**
   * Remove the inheritance of text transform in Edge, Firefox, and IE.
   * 1. Remove the inheritance of text transform in Firefox.
   */

  button,
  select { /* 1 */
    text-transform: none;
  }

  /**
   * Correct the inability to style clickable types in iOS and Safari.
   */

  button,
  [type="button"],
  [type="reset"],
  [type="submit"] {
    cursor: pointer;
    -webkit-appearance: button;
  }

  /**
   * Remove the inner border and padding in Firefox.
   */

  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0;
  }


  /**
   * Restore the focus styles unset by the previous rule.
   */

  button:-moz-focusring,
  [type="button"]:-moz-focusring,
  [type="reset"]:-moz-focusring,
  [type="submit"]:-moz-focusring {
    outline: 0.1rem dotted ButtonText;
  }

  /**
   * Suppress the focus outline on elements that cannot be accessed via keyboard.
   * This prevents an unwanted focus outline from appearing around elements that
   * might still respond to pointer events.
   * Credit: https://github.com/suitcss/base
   */

  [tabindex="-1"]:focus {
    outline: 0 !important;
  }


  /**
   * Correct the padding in Firefox.
   */

  fieldset {
    padding: 0.35rem 0.75rem 0.625rem;
  }

  /**
   * 1. Correct the text wrapping in Edge and IE.
   * 2. Correct the color inheritance from \`fieldset\` elements in IE.
   * 3. Remove the padding so developers are not caught out when they zero out
   *    \`fieldset\` elements in all browsers.
   */

  legend {
    box-sizing: border-box; /* 1 */
    color: inherit; /* 2 */
    display: table; /* 1 */
    max-width: 100%; /* 1 */
    padding: 0; /* 3 */
    white-space: normal; /* 1 */
  }

  /**
   * Add the correct vertical alignment in Chrome, Firefox, and Opera.
   */

  progress {
    vertical-align: baseline;
  }

  /**
   * Remove the default vertical scrollbar in IE 10+.
   * Textareas should really only resize vertically so they don't break their
   * (horizontal) containers.
   */

  textarea {
    overflow: auto;
    resize: vertical;
  }

  /**
  * Remove the default margin.
   */
  p {
    margin: unset;
  }
  /**
   * 1. Add the correct box sizing in IE 10.
   * 2. Remove the padding in IE 10.
   */

  [type="checkbox"],
  [type="radio"] {
    box-sizing: border-box; /* 1 */
    padding: 0; /* 2 */
  }

  /**
   * Correct the cursor style of increment and decrement buttons in Chrome.
   */

  [type="number"]::-webkit-inner-spin-button,
  [type="number"]::-webkit-outer-spin-button {
    height: auto;
  }

  /**
   * 1. Correct the odd appearance in Chrome and Safari.
   * 2. Correct the outline style in Safari.
   */

  [type="search"] {
    -webkit-appearance: textfield; /* 1 */
    outline-offset: -0.2rem; /* 2 */
  }

  /**
   * Remove the inner padding in Chrome and Safari on macOS.
   */

  [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
  }

  /**
   * 1. Correct the inability to style clickable types in iOS and Safari.
   * 2. Change font properties to inherit in Safari.
   */

  ::-webkit-file-upload-button {
    -webkit-appearance: button; /* 1 */
    font: inherit; /* 2 */
  }

  /* Interactive
     ========================================================================== */

  /*
   * Add the correct display in Edge, IE 10+, and Firefox.
   */

  details {
    display: block;
  }

  /*
   * Add the correct display in all browsers.
   */

  summary {
    display: list-item;
  }

  /* Misc
     ========================================================================== */

  /**
   * Add the correct display in IE 10+.
   */

  template {
    display: none;
  }

  /**
   * Add the correct display in IE 10.
   */

  [hidden] {
    display: none;
  }
`;

import { defer, LoaderFunction } from 'react-router-dom';

// Types
import { decompressSessionStorage } from '../../../../../utils/sessionStorage';

// Constants
import { formActionNaming } from '../../../../../components/forms/ActionForms/constants';

// GraphQL
import {
  FETCH_AUX_MACHINE_TYPE_BY_ID,
  FETCH_AUX_MACHINE_TYPES,
  FETCH_AUX_MACHINES,
} from '../../../../../gql/queries/actions/auxMachines';
import { client } from '../../../../../gql/client';
import { GET_MEASURE_TYPES_BY_ID } from '../../../../../gql/queries/actions/measureTypes';

export const MachineryLoader: LoaderFunction | undefined = async () => {
  // Stringify data
  const stringifyForm = sessionStorage.getItem(
    formActionNaming.NEW_MACHINERY_FORM
  );

  const breadCrumbsDataStringify = sessionStorage.getItem(
    formActionNaming.NEW_ACTION_DATA
  );

  // Variables to assign
  let sessionStorageData;
  let breadCrumbsData;

  // Variables assignment
  if (stringifyForm) {
    sessionStorageData = JSON.parse(decompressSessionStorage(stringifyForm));
  }

  if (breadCrumbsDataStringify) {
    breadCrumbsData = JSON.parse(
      decompressSessionStorage(breadCrumbsDataStringify)
    );
  }

  const {
    data: { auxMachines: auxMachineUnsorted },
  } = await client.query({
    query: FETCH_AUX_MACHINES,
  });

  await client.query({
    query: FETCH_AUX_MACHINE_TYPES,
  });

  const auxMachinesData = await Promise.all(
    auxMachineUnsorted.map(
      async (machine: {
        id: string;
        auxMachineId: string;
        description: string;
        type: string;
        typeOfMeasurement: string;
        measureTypeIDs: string[];
        auxMachineTypeIDs: string[];
      }) => {
        const [
          {
            data: { measureType },
          },
          {
            data: { auxMachineType },
          },
        ] = await Promise.all([
          client.query({
            query: GET_MEASURE_TYPES_BY_ID,
            variables: {
              id: machine?.measureTypeIDs[0],
            },
          }),
          client.query({
            query: FETCH_AUX_MACHINE_TYPE_BY_ID,
            variables: {
              id: machine?.auxMachineTypeIDs[0],
            },
          }),
        ]);
        return {
          id: machine.id,
          auxMachineId: machine.auxMachineId,
          description: machine.description,
          type: auxMachineType.name,
          typeOfMeasurement: measureType.name,
        };
      }
    )
  );

  const auxMachines = [...auxMachinesData].sort((a, b) =>
    a.auxMachineId.localeCompare(b.auxMachineId)
  );

  const combinedData = {
    ...sessionStorageData,
    machines: auxMachines,
    breadCrumbsData: {
      orderParentId: breadCrumbsData.orderParentId,
      orderParentCotic: breadCrumbsData.orderParentCotic,
    },
  };

  return defer({
    data: combinedData,
  });
};

import styled from 'styled-components';

// Components
import { DropDownSelect } from '../../../../components/inputs/DropDown/DropDownSelect';

// Styles utils
import {
  percentBaseResponsive,
  breakPoints,
  spaces,
} from '../../../../styles/spaces';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spaces.xl}rem;
  @media (max-width: ${breakPoints.smallDesktop * percentBaseResponsive}rem) {
    gap: ${spaces.m}rem;
  }
  @media (max-width: ${breakPoints.landscapeTablet *
    percentBaseResponsive}rem) {
    flex-direction: column;
  }
`;

export const SubContainer = styled.div`
  display: flex;
  gap: ${spaces.xl}rem;
  @media (max-width: ${breakPoints.smallDesktop * percentBaseResponsive}rem) {
    flex-direction: column;
    gap: 0;
  }
`;

export const NavContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 36rem;
  position: relative;
  z-index: 99999;
  gap: ${spaces.m}rem;
`;

export const DropDownSelectStyled = styled(DropDownSelect)``;

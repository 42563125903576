import { VehiclesInvolvedType } from './types';

export const mockData: VehiclesInvolvedType[] = [
  {
    vehicle: 'A',
    plate: '0000 XXX',
    model: 'Nissan Qashqay',
    insurance: 'Maphre',
    policyNumber: '0000000000',
    numberOccupants: '4',
    parentVehicleIDs: [],
    childVehicleIDs: ['2'],
    id: '1',
    childVehicle: [
      {
        id: '',
        plate: '0000 XXX',
        insurance: 'Maphre',
        policyNumber: '0000000000',
      },
    ],
  },
  {
    vehicle: 'A1',
    plate: '0000 XXX',
    model: 'Nissan Qashqay',
    insurance: 'Maphre',
    policyNumber: '0000000000',
    parentVehicleIDs: ['1'],
    childVehicleIDs: [],
    id: '2',
    childVehicle: [
      {
        id: '',
        plate: '0000 XXX',
        insurance: 'Maphre',
        policyNumber: '0000000000',
      },
    ],
  },
  {
    vehicle: 'B',
    plate: '0000 XXX',
    model: 'Nissan Qashqay',
    insurance: 'Maphre',
    policyNumber: '0000000000',
    numberOccupants: '2',
    parentVehicleIDs: [],
    childVehicleIDs: ['4'],
    id: '3',
    childVehicle: [
      {
        id: '',
        plate: '0000 XXX',
        insurance: 'Maphre',
        policyNumber: '0000000000',
      },
    ],
  },
  {
    vehicle: 'B1',
    plate: '0000 XXX',
    model: 'Nissan Qashqay',
    insurance: 'Maphre',
    policyNumber: '0000000000',
    numberOccupants: '1',
    parentVehicleIDs: ['3'],
    childVehicleIDs: [],
    id: '4',
    childVehicle: [
      {
        id: '',
        plate: '0000 XXX',
        insurance: 'Maphre',
        policyNumber: '0000000000',
      },
    ],
  },
];

import React, { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

// Components Styled
import { Container, BodyContainer } from './styles';

// Components
import MainHeader from '../../display/Header';

// Hooks
import { AuthContext } from '../../../hooks/useAuth';

export default function MainLayout(): JSX.Element {
  const userData = useContext(AuthContext);
  if (!userData?.user?.user?.validated) {
    return <Navigate to="/login" replace />;
  }

  const userName = `${userData.user.user.name} ${
    userData.user.user.surName.split(' ')[0]
  } `;

  return (
    <Container>
      <MainHeader
        variant="big"
        userName={userName}
        userMail={userData.user.user.email}
        userRole={userData.user.user.rolActive}
      />
      <BodyContainer>
        <Outlet />
      </BodyContainer>
    </Container>
  );
}

import { LoaderFunction, defer } from 'react-router-dom';
import { AuthContextType } from '../../../../hooks/useAuth';

// GQL
import { client } from '../../../../gql/client';
import { selectAllOrders } from '../../../../gql/selectors/orders';
import { GET_GENERIC_ORDERS } from '../../../../gql/queries/genericOrders';
import {
  FETCH_CAPITOLS,
  FETCH_SUB_CAPITOLS,
} from '../../../../gql/queries/orders/predefinedData';
import { GET_CONCESSIONS } from '../../../../gql/queries/orders/orders';

// Types
import {
  tableIds,
  takeBase,
} from '../../../../components/display/tables/types';

// Redux
import { getCursor } from '../../../../state/selectors/ui/tables';
import { store } from '../../../../state/configureStore';
import { loadingData } from '../../../../state/actions/ui/loadingData';
import { setCursor } from '../../../../state/actions/ui/cursorTables';

export const SearchAndCreateHistoricalWorkOrdersLoader: (
  userData: AuthContextType | null
) => LoaderFunction = (userData) => async () => {
  const cursorGenericOrders = getCursor(
    tableIds.SEARCH_AND_CREATE_HISTORICAL_WORKORDERS
  )(store.getState());
  const dispatch = store.dispatch;
  if (userData?.user) {
    const readGenericOrders = client.readQuery({
      query: GET_GENERIC_ORDERS,
      variables: {
        input: {
          take: cursorGenericOrders.take,
          cursor: cursorGenericOrders.cursor,
          concessionId: cursorGenericOrders.concessionId,
          capitolId: cursorGenericOrders.capitolId,
          subCapitolId: cursorGenericOrders.subCapitolId,
          orderBy: cursorGenericOrders.orderBy,
          toDate: cursorGenericOrders.toDate,
          fromDate: cursorGenericOrders.fromDate,
          indexType: cursorGenericOrders.indexType,
          indexExpedition: cursorGenericOrders.indexExpedition,
          classification: cursorGenericOrders.classification,
          state: cursorGenericOrders.state,
        },
      },
    });

    const pageInfo = readGenericOrders?.genericOrders?.pageInfo;

    const totalCount = readGenericOrders?.genericOrders?.totalCount;
    const isCacheEmpty = JSON.stringify(readGenericOrders) === '{}';

    const genericOrders =
      readGenericOrders &&
      !isCacheEmpty &&
      (await selectAllOrders(readGenericOrders?.genericOrders?.genericOrders));

    const [
      {
        data: { capitols },
      },
      {
        data: { concessions },
      },
      {
        data: { subCapitols },
      },
    ] = await Promise.all([
      client.query({
        query: FETCH_CAPITOLS,
      }),
      client.query({
        query: GET_CONCESSIONS,
      }),
      client.query({
        query: FETCH_SUB_CAPITOLS,
      }),
    ]);

    const filtersDataOptions = {
      capitols,
      concessions,
      subCapitols,
    };

    const combinedData = {
      genericOrders,
      filtersDataOptions,
      totalCount,
      pageInfo,
    };

    if (!readGenericOrders || isCacheEmpty) {
      dispatch(
        loadingData(tableIds.SEARCH_AND_CREATE_HISTORICAL_WORKORDERS, true)
      );
      let data;
      if (isCacheEmpty) {
        data = await client.query({
          query: GET_GENERIC_ORDERS,
          variables: {
            input: {
              take: takeBase,
              cursor: '',
              concessionId: cursorGenericOrders.concessionId,
              capitolId: cursorGenericOrders.capitolId,
              subCapitolId: cursorGenericOrders.subCapitolId,
              orderBy: 'desc',
              toDate: cursorGenericOrders.toDate,
              fromDate: cursorGenericOrders.fromDate,
              indexType: cursorGenericOrders.indexType,
              indexExpedition: cursorGenericOrders.indexExpedition,
              classification: cursorGenericOrders.classification,
              state: cursorGenericOrders.state,
            },
          },
        });
        const setCursorData = {
          take: takeBase,
          cursor: '',
          concessionId: cursorGenericOrders.concessionId,
          capitolId: cursorGenericOrders.capitolId,
          subCapitolId: cursorGenericOrders.subCapitolId,
          orderBy: 'desc',
          toDate: cursorGenericOrders.toDate,
          fromDate: cursorGenericOrders.fromDate,
          indexType: cursorGenericOrders.indexType,
          indexExpedition: cursorGenericOrders.indexExpedition,
          classification: cursorGenericOrders.classification,
          state: cursorGenericOrders.state,
        };

        dispatch(
          setCursor(
            tableIds.SEARCH_AND_CREATE_HISTORICAL_WORKORDERS,
            setCursorData
          )
        );
      } else {
        data = await client.query({
          query: GET_GENERIC_ORDERS,
          variables: {
            input: {
              take: cursorGenericOrders.take,
              cursor: cursorGenericOrders.cursor,
              concessionId: cursorGenericOrders.concessionId,
              capitolId: cursorGenericOrders.capitolId,
              subCapitolId: cursorGenericOrders.subCapitolId,
              orderBy: cursorGenericOrders.orderBy,
              toDate: cursorGenericOrders.toDate,
              fromDate: cursorGenericOrders.fromDate,
              indexType: cursorGenericOrders.indexType,
              indexExpedition: cursorGenericOrders.indexExpedition,
              classification: cursorGenericOrders.classification,
              state: cursorGenericOrders.state,
            },
          },
        });
      }

      const loading = data.loading;
      const pageInfo = data.data.genericOrders.pageInfo;

      const totalCount = data.data.genericOrders.totalCount;

      const genericOrders = await selectAllOrders(
        data.data.genericOrders.genericOrders
      );

      const [
        {
          data: { capitols },
        },
        {
          data: { concessions },
        },
        {
          data: { subCapitols },
        },
      ] = await Promise.all([
        client.query({
          query: FETCH_CAPITOLS,
        }),
        client.query({
          query: GET_CONCESSIONS,
        }),
        client.query({
          query: FETCH_SUB_CAPITOLS,
        }),
      ]);

      const filtersDataOptions = {
        capitols,
        concessions,
        subCapitols,
      };

      const combinedData = {
        genericOrders,
        filtersDataOptions,
        totalCount,
        pageInfo,
        loading,
      };
      dispatch(
        loadingData(tableIds.SEARCH_AND_CREATE_HISTORICAL_WORKORDERS, false)
      );
      return defer({ data: combinedData });
    }

    return defer({ data: combinedData });
  }
  return {};
};

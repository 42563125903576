import React, { useState, useCallback } from 'react';
import { useNavigate, useAsyncValue, useRevalidator } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

// Components Styled
import { Container } from '../components/MainStyledComponents';
import { ContainerButtonStyled, ContainerButtonSmall } from './styles';

// Components
import { MainButton } from '../../../../components/inputs/buttons/MainButton';
import { CampaignTable } from '../../../../components/display/tables/CampaignTable';

// Types
import { formOrderNaming } from '../../../../components/forms/NewWorkOrderForm/constants';
import {
  tableIds,
  takeBase,
} from '../../../../components/display/tables/types';
import { dataTypes } from '../../../../components/display/tables/CampaignTable/types';

// GQL
import { client } from '../../../../gql/client';
import { GET_CAMPAIGNS } from '../../../../gql/queries/campaign';

// Redux
import { getCursor } from '../../../../state/selectors/ui/tables';
import { selectAllOrders } from '../../../../gql/selectors/orders';
import { campaignIdTypes } from './types';
import { setCursor } from '../../../../state/actions/ui/cursorTables';

export default function CampaignsPage(): JSX.Element {
  const data = useAsyncValue() as dataTypes;
  const dispatch = useDispatch();
  const revalidator = useRevalidator();

  const [campaignId, setCampaignId] = useState<campaignIdTypes>({});

  const navigate = useNavigate();

  const handleNewCampaign = () => {
    navigate('new-campaign');
  };

  const handleEditCampaign = () => {
    sessionStorage.removeItem(formOrderNaming.EDIT_CAMPAIGN_FORM);
    navigate(`edit-campaign/${campaignId?.id}`);
  };

  const handleShowCampaign = () => {
    navigate(`${campaignId?.id}`);
  };

  const dataSelector = useSelector(getCursor(tableIds.CAMPAIGNS));

  const onReFetchData = useCallback(async () => {
    const { data } = await client.query({
      query: GET_CAMPAIGNS,
      variables: {
        input: {
          take: takeBase,
          cursor: '',
          orderBy: 'desc',
          name: dataSelector.name,
          fromDate: dataSelector.fromDate,
          toDate: dataSelector.toDate,
          concessionId: dataSelector.concessionId,
          state: dataSelector.state,
        },
      },
      fetchPolicy: 'network-only',
    });

    const setCursorData = {
      take: takeBase,
      cursor: '',
      orderBy: 'desc',
      name: dataSelector?.name,
      fromDate: dataSelector?.fromDate,
      toDate: dataSelector?.toDate,
      concessionId: dataSelector?.concessionId,
      state: dataSelector?.state,
    };

    dispatch(setCursor(tableIds.CAMPAIGNS, setCursorData));
    await selectAllOrders(data.campaigns.campaigns, true);

    revalidator.revalidate();
  }, [dataSelector]);

  const onRefetchPagination = useCallback(async () => {
    revalidator.revalidate();
  }, []);

  return (
    <Container>
      <CampaignTable
        titleText="Filtrar Campanyes per:"
        setRowState={setCampaignId}
        data={data}
        onReFetchData={onReFetchData}
        onReFetchPagination={onRefetchPagination}
        tableId={tableIds.CAMPAIGNS}
      />
      <ContainerButtonStyled>
        <MainButton
          variant="secondary"
          text="Nova campanya"
          onClick={handleNewCampaign}
        />
        <ContainerButtonSmall>
          <MainButton
            variant="secondary"
            text="Editar campanya"
            onClick={handleEditCampaign}
            disabled={!campaignId.id}
          />
          <MainButton
            text="Veure campanya"
            onClick={handleShowCampaign}
            disabled={!campaignId.id}
          />
        </ContainerButtonSmall>
      </ContainerButtonStyled>
    </Container>
  );
}

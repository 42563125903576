import { gql } from '@apollo/client';

export const FETCH_SUBCODIFICATIONS = gql`
  query FetchSubCodifications {
    subCodifications {
      id
      name
      codificationIDs
    }
  }
`;

export const GET_SUBCODIFICATION_BY_ID = gql`
  query GetSubCodificationById($id: ID!) {
    subCodification(id: $id) {
      id
      name
      codificationIDs
      materialIDs
    }
  }
`;

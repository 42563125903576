import { defer, LoaderFunction } from 'react-router-dom';

// Sentry
import * as Sentry from '@sentry/react';

// Utils
import { decompressSessionStorage } from '../../../../../utils/sessionStorage';

// Constants
import {
  formActionNaming,
  formActionValidationNaming,
} from '../../../../../components/forms/ActionForms/constants';

// Selects
import {
  selectAssetsAndActivitys,
  selectEditionValues,
} from '../../../../../gql/selectors/actions';
import { AuthContextType } from '../../../../../hooks/useAuth';
import { client } from '../../../../../gql/client';
import { GET_ORDER_BY_ID } from '../../../../../gql/queries/orders/orders';

const loadFormValues = async (
  userData: AuthContextType | null,
  actionId: string
) => {
  try {
    if (userData?.user) {
      const formValues = await selectEditionValues(actionId);
      return {
        ...formValues,
      };
    }
  } catch (err) {
    Sentry.captureException(err);
    console.log('> Error loading edition data', err);
  }
};

export const EditionValidationLoader: (
  userData: AuthContextType | null
) => LoaderFunction | undefined = (userData) => async () => {
  // Stringify data
  const stringifyForm = sessionStorage.getItem(
    formActionValidationNaming.NEW_VALIDATION_EDITION_FORM
  );

  const stringifyActionData = sessionStorage.getItem(
    formActionNaming.NEW_VALIDATION_ACTION_DATA
  );

  // Variables to assign
  let sessionStorageData;
  let actionData;
  let parentExpeditionOrderStatus;
  let state;
  // Variables assignment
  if (stringifyForm) {
    sessionStorageData = JSON.parse(decompressSessionStorage(stringifyForm));
  }

  if (stringifyActionData) {
    actionData = JSON.parse(decompressSessionStorage(stringifyActionData));
    const {
      data: { order },
    } = await client.query({
      query: GET_ORDER_BY_ID,
      variables: {
        id: actionData.orderId,
      },
    });
    const parentOrder = await client.query({
      query: GET_ORDER_BY_ID,
      variables: {
        id: order.parentExpeditionOrderIDs[0],
      },
    });
    parentExpeditionOrderStatus = parentOrder.data.order.state;
    state = order.state;
  }

  const dataAssetsAndActivitys = await selectAssetsAndActivitys();
  const loaderToResolve = await loadFormValues(userData, actionData.id);
  const combinedData = {
    ...dataAssetsAndActivitys,
    ...loaderToResolve,
    ...sessionStorageData,
    ...actionData,
    parentExpeditionOrderStatus,
    state,
    dataFromServer: { ...loaderToResolve, ...dataAssetsAndActivitys },
  };

  return defer({
    data: combinedData,
  });
};

export type UserType = {
  id: string;
  email: string;
  name: string;
  surName: string;
  rolActive: string;
  roles: string[];
  concession: string;
  permissions: { [key in AccessZone]?: Permission[] };
  accessZones: string[];
  validated: boolean;
  teamId: string;
};

export interface AuthType {
  user: UserType;
  accessToken: string;
  refreshToken: string;
}

export enum Role {
  SuperAdmin = 'super-admin',
  Ceconsa = 'ceconsa',
  RoomTeam = 'room-team',
  Tablet = 'tablet',
  OperatingTeam = 'operating-team',
}

export enum Permission {
  All = 'all',
  View = 'view',
  Create = 'create',
  Edit = 'edit',
  Delete = 'delete',
}

export enum AccessZone {
  Dashboard = 'dashboard',
  Communications = 'communications',
  Historical = 'historical',
  WorkOrders = 'work-orders',
  NewAndDuplicateOT = 'new-and-duplicate-ot',
  Campaign = 'campaign',
  ValidateActions = 'validate-actions',
}

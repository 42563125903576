import { UseFormRegister, UseFormSetValue } from 'react-hook-form';
import React from 'react';
import { MachineryTypesFormValues } from '../../../../forms/ActionForms/MachineryForm/types';

export type CreateInputCell = {
  fieldName: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register: UseFormRegister<any>;
} & React.InputHTMLAttributes<HTMLInputElement>;

export type FormUtilities = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setValue: UseFormSetValue<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register: UseFormRegister<any>;
};

export type dataTable = { id?: string } & {
  auxMachineId?: string;
  auxMachineUnitId?: string;
  description?: string;
  type?: string;
  ut?: string;
  typeOfMeasurement?: string;
};

export type NewMachinery = {
  auxMachineId?: string;
  description?: string;
  ut?: string;
  typeOfMeasurement?: string;
};

export type tableProps = {
  data?: MachineryTypesFormValues & {
    parentExpeditionOrderStatus?: string;
    state?: string;
  };
  defaultValues?: dataTable[];
  onAdd?: (id: string) => void;
  onUpdate: (
    event: string | number,
    id: string,
    unitId: string,
    completeId: string
  ) => void;
  onDelete?: (id: string, unitId: string) => void;
} & FormUtilities;

type columnIDTypes = { [key: string]: { label: string; id: string } };

export const columnNames: columnIDTypes = {
  auxMachineId: {
    id: 'auxMachineId',
    label: 'Codi',
  },
  description: {
    id: 'description',
    label: 'Maquinària',
  },
  type: {
    id: 'type',
    label: 'Tipus de maquinària',
  },
  ut: {
    id: 'ut',
    label: 'Ut.',
  },
  typeOfMeasurement: {
    id: 'typeOfMeasurement',
    label: 'Um.',
  },
};

export type thProps = {
  variant: variantThLeft;
};

export type variantThRight = 'code' | 'machinery' | 'ut' | 'um' | 'remove';

export type variantThLeft = 'code' | 'machinery' | 'machineryType' | 'add';

export type thRightProps = {
  variant: variantThRight;
};

export type onChangeInputTypes = (value: string) => void;

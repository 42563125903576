import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAsyncValue, useNavigate, useRevalidator } from 'react-router-dom';
import * as Sentry from '@sentry/react';

// Components
import { CheckBoxArrowTable } from '../../../../components/display/tables/CheckBoxArrowTable';
import { MainButton } from '../../../../components/inputs/buttons/MainButton';

// Styled Components
import { Container, ContainerButton } from '../components/MainStyledComponents';

// Types
import { dataTypes } from '../../../../components/display/tables/CheckBoxArrowTable/types';
import { OrderType } from '../../../../types/orders';
import {
  tableIds,
  takeBase,
} from '../../../../components/display/tables/types';

// Action and type modal
import { openModal } from '../../../../state/actions/ui/modal';
import { modalIds } from '../../../../components/modals/SuperModal/types';

// Utils Styles
import { useIsLandscapeTablet } from '../../../../hooks/useMediaBreakPoints';

// GQL
import { client } from '../../../../gql/client';
import { FETCH_COMMUNICATIONS } from '../../../../gql/queries/communication';
import { GET_ORDER_BY_ID } from '../../../../gql/queries/orders/orders';

// Redux
import { getCursor } from '../../../../state/selectors/ui/tables';
import { selectCommunicationOrders } from '../../../../gql/selectors/communications';
import { setCursor } from '../../../../state/actions/ui/cursorTables';
import { formNaming } from '../../../../components/forms/NewCommunicationForm/constants';

export default function SearchAndCreateHistoricalCommunicationPage(): JSX.Element {
  const data = useAsyncValue() as dataTypes;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const revalidator = useRevalidator();
  const isLandscape = useIsLandscapeTablet();

  const [selectedRows, setSelectedRows] = useState<Array<string | undefined>>(
    []
  );

  const selectorCursor = useSelector(
    getCursor(tableIds.SEARCH_AND_CREATE_HISTORICAL_COMMUNICATIONS)
  );

  const openModalAction = useCallback(() => {
    dispatch(
      openModal(modalIds.DOWNLOAD_HISTORIC, {
        type: OrderType.Communication,
        selectedRows,
      })
    );
  }, [selectedRows]);

  const onReFetchData = useCallback(async () => {
    const communications = await client.query({
      query: FETCH_COMMUNICATIONS,
      variables: {
        input: {
          take: takeBase,
          cursor: '',
          orderBy: 'desc',
          roadId: selectorCursor.roadId,
          concessionId: selectorCursor.concessionId,
          interlocutorGroupId: selectorCursor.interlocutorGroupId,
          fromDate: selectorCursor.fromDate,
          toDate: selectorCursor.toDate,
          indexType: selectorCursor.indexType,
          indexExpedition: selectorCursor.indexExpedition,
          classification: selectorCursor.classification,
        },
      },
      fetchPolicy: 'network-only',
    });

    const setCursorData = {
      take: takeBase,
      cursor: '',
      orderBy: 'desc',
      roadId: selectorCursor?.roadId,
      concessionId: selectorCursor?.concessionId,
      interlocutorGroupId: selectorCursor?.interlocutorGroupId,
      fromDate: selectorCursor?.fromDate,
      toDate: selectorCursor?.toDate,
      indexType: selectorCursor?.indexType,
      indexExpedition: selectorCursor?.indexExpedition,
      classification: selectorCursor?.classification,
    };

    dispatch(
      setCursor(
        tableIds.SEARCH_AND_CREATE_HISTORICAL_COMMUNICATIONS,
        setCursorData
      )
    );

    await selectCommunicationOrders(
      communications.data.communications.communications,
      true
    );
    revalidator.revalidate();
  }, [selectorCursor]);

  const onRefetchPagination = useCallback(async () => {
    revalidator.revalidate();
  }, []);

  const handleSeeCommunication = useCallback(() => {
    const tasks = async () => {
      if (selectedRows[0]) {
        const {
          data: { order },
        } = await client.query({
          query: GET_ORDER_BY_ID,
          variables: {
            id: selectedRows[0],
          },
        });

        navigate(`summary/${order.id}`);
      }
    };
    tasks().catch((err) => {
      Sentry.captureException(err);
      console.log(err);
    });
  }, [selectedRows]);

  const handleEditCommunication = useCallback(() => {
    sessionStorage.removeItem(formNaming.EDIT_COMMUNICATION_FORM);
    navigate(`edit-communication/${selectedRows[0]}`);
  }, [selectedRows]);

  return (
    <Container>
      <CheckBoxArrowTable
        idCss={'historical-' + OrderType.Communication}
        titleText="Filtrar comunicacions per:"
        data={data}
        setRowState={setSelectedRows}
        onReFetchData={onReFetchData}
        onReFetchPagination={onRefetchPagination}
        rowState={selectedRows}
        tableId={tableIds.SEARCH_AND_CREATE_HISTORICAL_COMMUNICATIONS}
      />
      <ContainerButton>
        <MainButton
          variant="secondary"
          text="Edit comunicació"
          onClick={handleEditCommunication}
          disabled={!(selectedRows.length > 0 && selectedRows.length < 2)}
        />
        <MainButton
          variant="secondary"
          text="Veure comunicació"
          onClick={handleSeeCommunication}
          disabled={!(selectedRows.length > 0 && selectedRows.length < 2)}
        />
        <MainButton
          text="Descarregar l’històric"
          disabled={selectedRows.length === 0 || isLandscape}
          onClick={openModalAction}
        />
      </ContainerButton>
    </Container>
  );
}

import { VehiclesType } from './types';

export const mockData: VehiclesType[] = [
  {
    mat: '2650FGZ',
    driver: 'Alfreda Pol',
    concession: 'C-16',
  },
  {
    mat: '2650FGZ',
    driver: 'Alfreda Pol',
    concession: 'C-16',
  },
  {
    mat: '2650FGZ',
    driver: 'Alfreda Pol',
    concession: 'C-16',
  },
  {
    mat: '2650FGZ',
    driver: 'Alfreda Pol',
    concession: 'C-16',
  },
  {
    mat: '2650FGZ',
    driver: 'Alfreda Pol',
    concession: 'C-16',
  },
];

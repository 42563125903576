import React, { useCallback, useState } from 'react';
import { format } from 'date-fns';
import { SpecialTag } from '../../SpecialTag';

// Types
import { ShowOTAccidentProps } from './types';
import { variant } from '../../SpecialTag/types';
import { OrderState, OrderType } from '../../../../types/orders';
import { modalIds } from '../../../modals/SuperModal/types';

// Styles Components
import {
  ContainerContent,
  ContainerData,
  ContainerDescription,
  ContainerHeader,
  ContainerRefIcon,
  ContainerTitleArrow,
  InputAction,
  InputDescription,
  RefCotic,
  RightArrow,
  TagsContainer,
  TitleDate,
  TitleOrder,
} from '../MainStyledComponents';
import { ContainerOTStyled } from './styles';

// Components
import { DateTime } from '../../../inputs/DateTime';

// Icons
import ArrowRight from '../../../../assets/icons/triangle-right.svg';

// Utils
import { useIsLandscapeTablet } from '../../../../hooks/useMediaBreakPoints';
import { dataTransferNaming } from '../../../forms/ActionForms/constants';

// Redux
import { useDispatch } from 'react-redux';
import { openModal } from '../../../../state/actions/ui/modal';

export const AccidentOT = ({
  cotic,
  tag,
  date,
  capitol,
  capitols,
  subCapitol,
  subCapitolsValues,
  description,
  isTransferred = false,
  id,
  parentOrder,
  revalidatorFunction,
  state,
  stateParentExpeditionOrder,
}: ShowOTAccidentProps): JSX.Element => {
  const dispatch = useDispatch();
  const [showInfo, setShowInfo] = useState(false);
  const isLandscapeTablet = useIsLandscapeTablet();
  const toggleInfo = useCallback(() => setShowInfo(!showInfo), [showInfo]);

  const openModalTransferSubOrder = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    e.stopPropagation();
    dispatch(
      openModal(modalIds.TRANSFER_SUBORDER_MODAL, {
        namingPersistForm: dataTransferNaming.SUB_ORDER_ACCIDENT,
        orderToTransfer: {
          type: OrderType.Accident,
          id,
          orderParent: parentOrder.id,
        },
        currentParentOrder: {
          id: parentOrder.id,
          type: parentOrder.type,
        },
        revalidatorFunction: revalidatorFunction,
      })
    );
  };
  return (
    <ContainerOTStyled>
      <ContainerHeader onClick={toggleInfo}>
        <TitleOrder>
          Accident
          <TitleDate>{` ${format(
            new Date(date),
            'dd/MM/yyyy - HH:mm'
          )}`}</TitleDate>
        </TitleOrder>
        <ContainerTitleArrow>
          <ContainerRefIcon>
            <RefCotic>{cotic}</RefCotic>
            <TagsContainer>
              <SpecialTag
                variant={tag}
                sizeTag={isLandscapeTablet ? 'small' : 'default'}
              />
              <SpecialTag
                variant={isTransferred ? variant.Transferred : variant.Transfer}
                sizeTag={isLandscapeTablet ? 'small' : 'default'}
                onClick={
                  state !== OrderState.Annulled &&
                  stateParentExpeditionOrder !== OrderState.Annulled &&
                  stateParentExpeditionOrder !== OrderState.End &&
                  !isTransferred
                    ? (e) => openModalTransferSubOrder(e)
                    : undefined
                }
              />
            </TagsContainer>
          </ContainerRefIcon>
          <RightArrow
            src={ArrowRight}
            alt="Icona desplegar informació"
            showInfo={showInfo}
          />
        </ContainerTitleArrow>
      </ContainerHeader>
      {showInfo ? (
        <ContainerContent>
          <ContainerData>
            <DateTime
              label="Data de registre:"
              labelHour="Hour:"
              isDisabled={true}
              initialDate={date}
            />

            <InputAction
              label="Capitol"
              typeInput="normal"
              disabled={true}
              value={
                capitols?.find((capitolInter) => capitolInter.id === capitol)
                  ?.name
              }
            />

            <InputAction
              label="Subcapitol"
              typeInput="normal"
              disabled={true}
              value={
                subCapitolsValues?.find(
                  (subCapitolInter) => subCapitolInter.id === subCapitol
                )?.name
              }
            />
          </ContainerData>
          <ContainerDescription>
            <InputDescription
              label="Descripció:"
              disabled={true}
              value={description}
            />
          </ContainerDescription>
        </ContainerContent>
      ) : null}
    </ContainerOTStyled>
  );
};

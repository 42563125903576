import styled from 'styled-components';
import { spaces } from '../../../../styles/spaces';

// Components
import { Typography } from '../../../display/Typography';

import ModalContainer from '../../containers/ModalContainer';
import { ModalContainerTypes } from './types';

export const ModalContainerStyled = styled(ModalContainer)`
  position: relative;
`;

export const Container = styled.div<ModalContainerTypes>`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3rem;
  opacity: ${(props) => (props.isLoading ? 0.6 : 1)};
`;

export const Title = styled(Typography)`
  font-size: 2.6rem;
  text-align: center;
`;

export const Icon = styled.img<ModalContainerTypes>`
  display: flex;
  margin: 0 auto ${spaces.s}rem;
  opacity: ${(props) => (props.isLoading ? 0.6 : 1)};
`;

export const ContainerLoader = styled.div`
  position: absolute;
  top: 40%;
  left: 45%;
  z-index: 1;
`;

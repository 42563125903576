import React, { SyntheticEvent, useCallback, useLayoutEffect } from 'react';
import {
  useNavigate,
  useActionData,
  useAsyncValue,
  useRevalidator,
  useSubmit,
} from 'react-router-dom';
import { format } from 'date-fns';

// Components Styled
import { Container } from './styles';

//Components
import ContentCardResume from '../../../../../components/display/ContentCardResume';

// Types
import { type NewCommunication } from '../../../../../types/communication';
import { createFormDataFromValues } from '../../../../../utils/forms';

export default function CommunicationSummary(): JSX.Element {
  const loaderData = useAsyncValue() as NewCommunication;
  const actionData = useActionData() as NewCommunication;
  const navigate = useNavigate();
  const revalidator = useRevalidator();
  const submit = useSubmit();

  const communicationData = actionData ?? loaderData;

  useLayoutEffect(() => {
    if (!communicationData) navigate('..');
  }, [communicationData]);

  const handleEditCommunication = useCallback(() => {
    // This goes up two levels on the path, this levels /summary/64a2b512dea3b5c92e0a8db9
    if (actionData?.editCommunication) {
      navigate('../', { relative: 'path' });
    } else {
      navigate('../..', { relative: 'path' });
    }
    // this goes up one level in the nesting of routes and leaves you here search-create-historical-communication,
    // if it went up two levels it would leave you in communication, one goes up on the path and the other on the nesting of routes
    // navigate('..' );
  }, []);

  const handleSaveData = async (event: SyntheticEvent) => {
    event.preventDefault();
    const formData = createFormDataFromValues({
      ...actionData.dataDiff,
      intent: 'save-data',
      parentOrderId: actionData.parentOrderId,
    });
    submit(formData, {
      method: 'POST',
      action: '.',
    });
  };

  const refetchData = () => {
    revalidator.revalidate();
  };
  return (
    <Container>
      {communicationData && (
        <ContentCardResume
          title="Resum de la comunicació"
          cotic={communicationData.cotic as string[]}
          operator={communicationData.operator}
          data={format(
            new Date(communicationData.registerInitDate as string),
            'dd / MM / yyyy'
          )}
          hour={format(
            new Date(communicationData.registerInitDate as string),
            'HH:mm'
          )}
          groupInter={communicationData.groupInter}
          media={communicationData.media}
          sendStatus={communicationData.sendStatus}
          capitol={communicationData.capitol}
          subCapitol={communicationData.subCapitol}
          classification={communicationData.classification}
          team={communicationData.team}
          concession={communicationData.concession}
          road={communicationData.road}
          pkInit={`${communicationData.pkInit} km`}
          pkEnd={`${communicationData.pkEnd} km`}
          track={communicationData.track}
          roadDirections={communicationData.roadDirections}
          direction={communicationData.direction}
          city={communicationData.city}
          lane={communicationData.lane}
          margin={communicationData.margin}
          leftButton={'Tornar'}
          rightButton={actionData?.editCommunication ? 'Desar' : undefined}
          description={communicationData.description}
          onEditCommunication={handleEditCommunication}
          onSaveCommunication={handleSaveData}
          storedDataOTSelected={communicationData.storedDataOTSelected}
          changeStateTag={!actionData?.editCommunication ? true : false}
          state={communicationData.state}
          revalidatorFunction={refetchData}
          orderId={loaderData?.parentOrderId?.[0]}
        />
      )}
    </Container>
  );
}

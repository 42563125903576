import { ActionFunction } from 'react-router-dom';

// Sentry
import * as Sentry from '@sentry/react';

// Utils
import {
  compressSessionStorage,
  decompressSessionStorage,
} from '../../../../utils/sessionStorage';

// Constants
import { formAccidentNaming } from '../../../../components/forms/AddBasicInfoAccidentForm/constants';

export const AccidentNewAccident: ActionFunction = async ({ request }) => {
  const formData = await request.formData();
  const intent = formData.get('intent');
  if (intent === 'pass-data') {
    try {
      const dataFormRaw = sessionStorage.getItem(
        formAccidentNaming.NEW_ACCIDENT_FORM
      );
      if (dataFormRaw) {
        const dataForm = JSON.parse(decompressSessionStorage(dataFormRaw));
        sessionStorage.setItem(
          formAccidentNaming.NEW_ACCIDENT_FORM,
          compressSessionStorage(
            JSON.stringify({
              ...dataForm,
              orderParentId: formData.get('id'),
              orderParentCotic: formData.get('cotic'),
            })
          )
        );
        return {
          orderParentId: formData?.get('id') ?? dataForm?.orderParentId,
          orderParentCotic:
            formData?.get('cotic') ?? dataForm?.orderParentCotic,
        };
      } else {
        sessionStorage.setItem(
          formAccidentNaming.NEW_ACCIDENT_FORM,
          compressSessionStorage(
            JSON.stringify({
              orderParentId: formData.get('id'),
              orderParentCotic: formData.get('cotic'),
            })
          )
        );
      }
      return {
        orderParentId: formData.get('id'),
        orderParentCotic: formData.get('cotic'),
      };
    } catch (err) {
      Sentry.captureException(err);
      console.log('> NewAction pass-data - error creating:', err);
    }
  }

  return null;
};

// Dependencies
import React from 'react';

// Components
import { Typography } from '../../../display/Typography';

// Styles
import { AvatarContainer } from './styles';

// Types
import { UserButtonProps } from './types';

const UserButton = ({ initials, className }: UserButtonProps) => {
  const getInitials = (userName: string) => {
    const fullNameArr = userName?.split(' ');
    return `${fullNameArr[0][0].toUpperCase()}${fullNameArr[1][0].toUpperCase()}`;
  };

  return (
    <AvatarContainer className={className}>
      <Typography colorText="white" size="M">
        {getInitials(initials)}
      </Typography>
    </AvatarContainer>
  );
};

export default UserButton;

import React from 'react';

type TakePhotoProps = {
  className?: string;
};

export const TakePhotoIcon = ({ className }: TakePhotoProps): JSX.Element => {
  return (
    <svg
      width="100"
      height="100"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <circle cx="50" cy="50" r="50" fill="#5E5C5C" />
      <circle cx="50" cy="50" r="40" fill="#F6F6F6" />
    </svg>
  );
};

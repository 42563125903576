export const animalsMocksData = [
  {
    id: 'a1',
    animalId: 'M00001',
    name: 'Gat',
  },
  {
    id: 'v2',
    animalId: 'M00002',
    name: 'Vaca',
  },
  {
    id: 'c3',
    animalId: 'M00003',
    name: 'Senglar',
  },
  {
    id: 'd4',
    animalId: 'M00004',
    name: 'Cabrit',
  },
  {
    id: 'c5',
    animalId: 'M00005',
    name: 'Cérvol',
  },
  {
    id: 'v6',
    animalId: 'M00006',
    name: 'Ovella',
  },
  {
    id: 'e7',
    animalId: 'M00007',
    name: 'Porc',
  },
  {
    id: 'w8',
    animalId: 'M00008',
    name: 'Vadell',
  },
];

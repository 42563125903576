import { ActionFunction, redirect } from 'react-router-dom';
import * as Sentry from '@sentry/react';

// Types
import { AuthContextType } from '../../../hooks/useAuth';

// GraphQL Client
import { cacheApolloPersistor, client } from '../../../gql/client';

import { DELETE_ACTION_BY_ID } from '../../../gql/mutations/actions';
import { GET_ORDER_BY_ID } from '../../../gql/queries/orders/orders';
// Types and Const
import { Store } from 'redux';
import { RootState } from '../../../state/configureStore';
import { formNaming as communicationFormNaming } from '../../forms/NewCommunicationForm/constants';
import { formOrderNaming as workOrderFormNaming } from '../../forms/NewWorkOrderForm/constants';
import {
  formAccidentNaming as accidentFormNaming,
  directoryNaming,
} from '../../forms/AddBasicInfoAccidentForm/constants';
import { formVehicleNaming as vehicleFormNaming } from '../../forms/AddVehicleForm/constants';
import {
  formActionNaming as actionFormNaming,
  formActionValidationNaming as actionValidationNaming,
} from '../../forms/ActionForms/constants';

import { OrderState, OrderType } from '../../../types/orders';
// Action
import { cleanState } from '../../../state/actions/main';
import { persistor } from '../../../state/configureStore';

import { getInitialStatePersist } from '../../../state/selectors/persist';
// Utils
import { deleteWholeDirectory } from '../../../utils/files';
import { decompressSessionStorage } from '../../../utils/sessionStorage';
import { formMachineryManagementNaming } from '../../forms/AddMachineryManagementForm/constants';
import { formVehicleManagementNaming } from '../../forms/AddVehicleManagementForm/constants';
import { formMaterialManagementNaming } from '../../forms/AddMaterialManagementForm/constants';
import { formAnimalManagementNaming } from '../../forms/AddAnimalManagementForm/constants';
import { formUserManagementNaming } from '../../forms/AddUserForm/constants';

export const ActionLogout: (
  store: Store<RootState>
) => (userData: AuthContextType | null) => ActionFunction | undefined =
  (store) => (userData) => async () => {
    const actionData = sessionStorage.getItem(actionFormNaming.NEW_ACTION_DATA);
    if (actionData) {
      const dataParsed = JSON.parse(decompressSessionStorage(actionData));
      if (dataParsed.orderId) {
        const {
          data: {
            order: { state },
          },
        } = await client.query({
          query: GET_ORDER_BY_ID,
          variables: {
            id: dataParsed.orderId,
          },
          fetchPolicy: 'network-only',
        });
        try {
          state === OrderState.Partial &&
            (await client.mutate({
              mutation: DELETE_ACTION_BY_ID,
              variables: {
                id: dataParsed.actionId,
              },
            }));
        } catch (e) {
          Sentry.captureException(e);
          console.log(e);
        }
      }
    }

    //TODO: apollo revoke refresh token with black list tokens and remove of database with expire refresh token or other form, that i dont know, now
    const initialState = getInitialStatePersist(store.getState());
    store.dispatch(cleanState(initialState));
    await persistor.purge();
    await client.clearStore();
    await client.cache.reset({ discardWatches: true });
    await cacheApolloPersistor.purge();
    userData?.setUser(null);
    Sentry.setUser(null);

    const formNaming = {
      ...communicationFormNaming,
      ...workOrderFormNaming,
      ...accidentFormNaming,
      ...vehicleFormNaming,
      ...actionFormNaming,
      ...actionValidationNaming,
      ...formMachineryManagementNaming,
      ...formVehicleManagementNaming,
      ...formMaterialManagementNaming,
      ...formAnimalManagementNaming,
      ...formUserManagementNaming,
    };

    Object.values(formNaming).forEach((formName) => {
      sessionStorage.removeItem(formName);
    });

    deleteWholeDirectory(OrderType.Accident);
    deleteWholeDirectory(OrderType.Action);
    deleteWholeDirectory(directoryNaming.CANVAS);

    return redirect('/login');
  };

import { LoaderFunction, defer } from 'react-router-dom';
import { AuthContextType } from '../../../../../hooks/useAuth';

// Redux
import { store } from '../../../../../state/configureStore';
import { getCursor } from '../../../../../state/selectors/ui/tables';

// Types
import {
  tableIds,
  takeBase,
} from '../../../../../components/display/tables/types';

// GQL
import { client } from '../../../../../gql/client';
import { selectWorkOrdersWithMultimedia } from '../../../../../gql/selectors/multimedia';
import {
  FETCH_ORDERS,
  GET_SELECTORS_DATA,
} from '../../../../../gql/queries/orders/orders';
import {
  FETCH_CAPITOLS,
  FETCH_SUB_CAPITOLS,
} from '../../../../../gql/queries/orders/predefinedData';
import { loadingData } from '../../../../../state/actions/ui/loadingData';
import { setCursor } from '../../../../../state/actions/ui/cursorTables';

export const LoaderMultimediaGeneralView: (
  userData: AuthContextType | null
) => LoaderFunction = (userData) => async () => {
  const cursorMultimedia = getCursor(tableIds.MULTIMEDIA)(store.getState());
  const dispatch = store.dispatch;

  const roleActive = userData?.user.user.rolActive;

  if (userData?.user) {
    const readMultimedia = client.readQuery({
      query: FETCH_ORDERS,
      variables: {
        input: {
          take: cursorMultimedia.take,
          isMultimedia: true,
          cursor: cursorMultimedia.cursor,
          orderBy: cursorMultimedia.orderBy,
          fromDate: cursorMultimedia.fromDate,
          toDate: cursorMultimedia.toDate,
          capitolId: cursorMultimedia.capitolId,
          subCapitolId: cursorMultimedia.subCapitolId,
          type: cursorMultimedia.type,
          state: cursorMultimedia.state,
          indexType: cursorMultimedia.indexType,
          indexExpedition: cursorMultimedia.indexExpedition,
          classification: cursorMultimedia.classification,
          roadId: cursorMultimedia.roadId,
        },
      },
    });

    const pageInfo = readMultimedia?.orders?.pageInfo;

    const isCacheEmpty = JSON.stringify(readMultimedia) === '{}';
    const dataOrderWithMultimedia =
      readMultimedia &&
      !isCacheEmpty &&
      (await selectWorkOrdersWithMultimedia(readMultimedia?.orders?.orders));

    const totalCount = readMultimedia?.orders?.totalCount;

    const [
      {
        data: { capitols },
      },
      {
        data: { subCapitols },
      },
      {
        data: { roads },
      },
    ] = await Promise.all([
      client.query({
        query: FETCH_CAPITOLS,
        fetchPolicy: 'cache-first',
      }),
      client.query({
        query: FETCH_SUB_CAPITOLS,
        fetchPolicy: 'cache-first',
      }),
      client.query({
        query: GET_SELECTORS_DATA,
        fetchPolicy: 'cache-first',
      }),
    ]);

    const filtersDataOptions = {
      capitols,
      subCapitols,
      roads,
    };

    const combinedData = {
      totalCount,
      dataOrderWithMultimedia,
      pageInfo,
      filtersDataOptions,
      roleActive,
    };

    if (!readMultimedia || isCacheEmpty) {
      dispatch(loadingData(tableIds.MULTIMEDIA, true));
      let data;
      if (isCacheEmpty) {
        data = await client.query({
          query: FETCH_ORDERS,
          variables: {
            input: {
              take: takeBase,
              isMultimedia: true,
              cursor: '',
              orderBy: 'desc',
              fromDate: cursorMultimedia.fromDate,
              toDate: cursorMultimedia.toDate,
              type: cursorMultimedia.type,
              state: cursorMultimedia.state,
              indexType: cursorMultimedia.indexType,
              indexExpedition: cursorMultimedia.indexExpedition,
              classification: cursorMultimedia.classification,
              capitolId: cursorMultimedia.capitolId,
              roadId: cursorMultimedia.roadId,

              subCapitolId: cursorMultimedia.subCapitolId,
            },
          },
        });

        const setCursorData = {
          take: takeBase,
          isMultimedia: true,
          cursor: '',
          orderBy: 'desc',
          fromDate: cursorMultimedia.fromDate,
          toDate: cursorMultimedia.toDate,
          type: cursorMultimedia.type,
          state: cursorMultimedia.state,
          indexType: cursorMultimedia.indexType,
          indexExpedition: cursorMultimedia.indexExpedition,
          classification: cursorMultimedia.classification,
          capitolId: cursorMultimedia.capitolId,
          subCapitolId: cursorMultimedia.subCapitolId,
          roadId: cursorMultimedia.roadId,
        };

        dispatch(setCursor(tableIds.MULTIMEDIA, setCursorData));
      } else {
        data = await client.query({
          query: FETCH_ORDERS,
          variables: {
            input: {
              take: cursorMultimedia.take,
              isMultimedia: true,
              cursor: cursorMultimedia.cursor,
              orderBy: cursorMultimedia.orderBy,
              fromDate: cursorMultimedia.fromDate,
              toDate: cursorMultimedia.toDate,
              type: cursorMultimedia.type,
              state: cursorMultimedia.state,
              indexType: cursorMultimedia.indexType,
              indexExpedition: cursorMultimedia.indexExpedition,
              classification: cursorMultimedia.classification,
              capitolId: cursorMultimedia.capitolId,
              subCapitolId: cursorMultimedia.subCapitolId,
              roadId: cursorMultimedia.roadId,
            },
          },
        });
      }

      const loading = data.loading;
      const pageInfo = data.data.orders.pageInfo;

      const dataOrderWithMultimedia = await selectWorkOrdersWithMultimedia(
        data.data.orders.orders
      );

      const totalCount = data.data.orders.totalCount;

      const [
        {
          data: { capitols },
        },
        {
          data: { subCapitols },
        },
      ] = await Promise.all([
        client.query({
          query: FETCH_CAPITOLS,
          fetchPolicy: 'cache-first',
        }),
        client.query({
          query: FETCH_SUB_CAPITOLS,
          fetchPolicy: 'cache-first',
        }),
      ]);

      const filtersDataOptions = {
        capitols,
        subCapitols,
        roads,
      };

      const combinedData = {
        totalCount,
        dataOrderWithMultimedia,
        pageInfo,
        filtersDataOptions,
        loading,
        roleActive,
      };
      dispatch(loadingData(tableIds.MULTIMEDIA, false));
      return defer({ data: combinedData });
    }

    return defer({ data: combinedData });
  }
  return {};
};

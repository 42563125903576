import { defer, LoaderFunction } from 'react-router-dom';

// Types
import { decompressSessionStorage } from '../../../../../utils/sessionStorage';

// Constants
import { formActionNaming } from '../../../../../components/forms/ActionForms/constants';

// GraphQL
import { FETCH_MATERIALS } from '../../../../../gql/queries/actions/materials';
import { client } from '../../../../../gql/client';
import {
  FETCH_SUBCODIFICATIONS,
  GET_SUBCODIFICATION_BY_ID,
} from '../../../../../gql/queries/actions/subCodification';
import {
  FETCH_CODIFICATIONS,
  GET_CODIFICATION_BY_ID,
} from '../../../../../gql/queries/actions/codification';
import {
  FETCH_MEASURE_TYPES,
  GET_MEASURE_TYPES_BY_ID,
} from '../../../../../gql/queries/actions/measureTypes';

export const MaterialsLoader: LoaderFunction | undefined = async () => {
  // Stringify data
  const stringifyForm = sessionStorage.getItem(
    formActionNaming.NEW_MATERIALS_FORM
  );

  const breadCrumbsDataStringify = sessionStorage.getItem(
    formActionNaming.NEW_ACTION_DATA
  );

  // Variables to assign
  let sessionStorageData;
  let breadCrumbsData;

  // Variables Assignment
  if (stringifyForm) {
    sessionStorageData = JSON.parse(decompressSessionStorage(stringifyForm));
  }

  if (breadCrumbsDataStringify) {
    breadCrumbsData = JSON.parse(
      decompressSessionStorage(breadCrumbsDataStringify)
    );
  }
  const {
    data: { materials: materialsUnsorted },
  } = await client.query({
    query: FETCH_MATERIALS,
  });

  await Promise.all([
    client.query({
      query: FETCH_MEASURE_TYPES,
    }),
    client.query({
      query: FETCH_CODIFICATIONS,
    }),
    client.query({
      query: FETCH_SUBCODIFICATIONS,
    }),
  ]);

  const materialsData = await Promise.all(
    materialsUnsorted.map(
      async (material: {
        id: string;
        materialId: string;
        name: string;
        typeOfMeasurement: string;
        codification: string;
        subCodification: string;
        measureTypeIDs: string[];
        codificationIDs: string[];
        subCodificationIDs: string[];
      }) => {
        const [
          {
            data: { measureType },
          },
          {
            data: { codification },
          },
          {
            data: { subCodification },
          },
        ] = await Promise.all([
          client.query({
            query: GET_MEASURE_TYPES_BY_ID,
            variables: {
              id: material?.measureTypeIDs[0],
            },
            fetchPolicy: 'cache-only',
          }),
          client.query({
            query: GET_CODIFICATION_BY_ID,
            variables: {
              id: material?.codificationIDs[0],
            },
            fetchPolicy: 'cache-only',
          }),
          client.query({
            query: GET_SUBCODIFICATION_BY_ID,
            variables: {
              id: material?.subCodificationIDs[0],
            },
            fetchPolicy: 'cache-only',
          }),
        ]);

        return {
          id: material.id,
          materialId: material.materialId,
          name: material.name,
          typeOfMeasurement: measureType.name,
          codification: codification.name,
          subCodification: subCodification.name,
        };
      }
    )
  );
  const materials = [...materialsData].sort((a, b) =>
    a.materialId.localeCompare(b.materialId)
  );

  const combinedData = {
    ...sessionStorageData,
    materialsData: materials,
    breadCrumbsData: {
      orderParentId: breadCrumbsData.orderParentId,
      orderParentCotic: breadCrumbsData.orderParentCotic,
    },
  };

  return defer({
    data: combinedData,
  });
};

import React from 'react';

type FiguresIconProps = {
  className?: string;
};

export const Figures = ({ className }: FiguresIconProps): JSX.Element => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <circle
        cx="5.44166"
        cy="6.3987"
        r="5.14773"
        fill="color"
        stroke="color"
        strokeWidth="0.587862"
      />
      <rect
        x="7.33299"
        y="6.37596"
        width="8.37488"
        height="8.37488"
        fill="color"
        stroke="color"
        strokeWidth="0.587862"
      />
    </svg>
  );
};

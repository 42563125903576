import { OptionSelected } from '../../../../inputs/DropDown/DropDownList/types';
import { Control, UseFormGetValues, UseFormSetValue } from 'react-hook-form';

// Types
import {
  type OperatorsAndVehiclesFormValues,
  DriverList,
} from '../../../../forms/ActionForms/OperatorsAndVehicles/types';

export type NewAction = {
  operatorsTable: {
    name: string;
    surName: string;
    concession: string;
    isActive: string;
  }[];
  vehiclesTable:
    | ({
        plate: string;
        driverID: string;
        driver: string;
        isActive: string;
      } | null)[];
};

export type TableProps = {
  data?: OperatorsAndVehiclesFormValues;
  operatorsData?: OperatorsSelectionType[];
  vehiclesData?: VehiclesSelectionType[];
  titles?: string[];
  control: Control<NewAction & OperatorsAndVehiclesFormValues>;
  defaultOperators: OperatorsSelectionType[];
  defaultVehicles: VehiclesSelectionType[];
  defaultDrivers: DriverList;
  tableName: string;
  setDefaultOperators: (operators: OperatorsSelectionType[]) => void;
  setDefaultVehicles: (operators: VehiclesSelectionType[]) => void;
  setValue: UseFormSetValue<OperatorsAndVehiclesFormValues>;
  setCacheDrivers?: (drivers: DriverList) => void;
  isInternal?: boolean;
  getValues: UseFormGetValues<OperatorsAndVehiclesFormValues>;
  concessions: {
    id: string;
    name: string;
    vehicleIDs: string[];
  }[];
};

export type OperatorsSelectionType = {
  id: string;
  name: string;
  surName: string;
  concession: string;
  isActive: string;
};

export type VehiclesSelectionType = {
  driver?: string;
  vehicleID?: string;
  id: string;
  plate: string;
  driverID: string;
  remove?: string;
  color?: string;
  insurance?: string;
  isInternal?: boolean;
  isTrailer?: boolean;
  model?: string;
  policyNumber?: boolean;
  vehicleOccupantID?: string;
  isActive?: string;
  concession: string;
};

type columnIDTypes = { [key: string]: { id: string; label: string } };

export const operatorsColumnNames: columnIDTypes = {
  name: {
    id: 'firstName',
    label: 'Nom',
  },
  surName: {
    id: 'lastName',
    label: 'Cognoms',
  },
  concession: {
    id: 'concession',
    label: 'Concesió',
  },
  isActive: {
    id: 'isActive',
    label: 'isActive',
  },
};

export const vehiclesColumnNames: columnIDTypes = {
  plate: {
    id: 'plate',
    label: 'Matrícula',
  },
  driver: {
    id: 'driver',
    label: 'Conductor',
  },
  remove: {
    id: 'remove',
    label: '',
  },
  isActive: {
    id: 'isActive',
    label: 'isActive',
  },
  concession: {
    id: 'concession',
    label: 'Concessió',
  },
};

export type handleChangeTypes = (value: string) => void;

export type variantLeftTh = 'select' | 'firstName' | 'lastName' | 'concession';
export type variantRightTh = 'select' | 'plate' | 'driver' | 'remove';

export type leftTableCellProps = {
  cell: variantLeftTh;
};

export type rightTableCellProps = {
  cell: variantRightTh;
};

export type ImperativeRefInput = {
  clearSelected: () => void;
  setSelectedOption: (option: OptionSelected | undefined) => void;
} | null;

export type DisabledType = {
  isDisabled?: boolean;
};

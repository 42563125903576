import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';

// Sentry
import * as Sentry from '@sentry/react';

// Components
import { MainButton } from '../../../inputs/buttons/MainButton';
import ModalContainer from '../../containers/ModalContainer';
import { AlertIcon } from '../../../../assets/icons/AlertIcon';
import { Typography } from '../../../display/Typography';

// Styled Components
import {
  Container,
  ContainerText,
  Title,
  Text,
  ContainerInputSection,
  MainButtonStyled,
  ContainerForm,
} from './styles';

// Colors
import { colors } from '../../../../styles/colors';

// Types
import { ModalProps } from './types';
import DropDownInput from '../../../inputs/DropDown/DropDownInput';
import { DateTime } from '../../../inputs/DateTime';
import { Controller, useForm } from 'react-hook-form';
import { OTObject } from '../../../display/ShowOT/WorkOrder/types';
import { client } from '../../../../gql/client';

// GQL
import { UPDATE_ORDER } from '../../../../gql/mutations/orders';
import { OrderState, OrderType } from '../../../../types/orders';

// Schema
import { OTModalSchema } from './schema';
import { useCacheFormInSessionStorage } from '../../../../hooks/useCacheForm';

// Constants
import { OTModalKey } from './constants';
import { decompressSessionStorage } from '../../../../utils/sessionStorage';
import { variant } from '../../../display/SpecialTag/types';
import {
  FETCH_ORDERS,
  GET_ORDER_BY_ID,
} from '../../../../gql/queries/orders/orders';
import { OptionSelected } from '../../../inputs/DropDown/DropDownList/types';

export const ChangeStateModal = ({
  onClickClose,
  title,
  titleSaveModal,
  indexExpedition,
  revalidatorFunction,
  orderType,
  currentState,
  orderId,
  ...props
}: ModalProps): JSX.Element => {
  const cacheData = sessionStorage.getItem(OTModalKey);
  const initialData = cacheData
    ? JSON.parse(decompressSessionStorage(cacheData))
    : {};
  const {
    handleSubmit,
    control,
    formState: { errors, defaultValues },
    getValues,
    setValue,
  } = useForm<OTObject>({
    resolver: yupResolver(OTModalSchema),
    defaultValues: { ...initialData },
  });

  useCacheFormInSessionStorage(OTModalKey, control);

  const [initialModal, setInitialModal] = useState<boolean>(true);
  const [isDisabled, setIsDisabled] = useState<boolean>(
    initialData.state === OrderState.Open
  );
  const [validationError, setValidationError] = useState(false);
  const [textError, setTextError] = useState('');
  const [orders, setOrders] = useState<OTObject[]>([]);

  const options = [
    { label: 'Open', value: 'open' },
    { label: 'Annulled', value: 'annulled' },
    { label: 'Finalitzada', value: 'end' },
  ];

  const accidents = orders.filter((order) => order.type === OrderType.Accident);

  const getOrders = async () => {
    const { data } = await client.query({
      query: FETCH_ORDERS,
      fetchPolicy: 'network-only',
      variables: {
        input: {
          indexExpedition,
        },
      },
    });
    return data;
  };

  const mostRecentRegisterEndDate = useMemo(
    () =>
      orders.reduce((maxDate, order) => {
        if (
          order.type === OrderType.Action &&
          order.state !== OrderState.Partial
        ) {
          const currentDate = new Date(order.registerEndDate as string);
          return currentDate > maxDate ? currentDate : maxDate;
        }
        return maxDate;
      }, new Date(0)),

    [orders]
  );

  const formatRegisterEndDate =
    mostRecentRegisterEndDate.getTime() === 0
      ? new Date().toISOString()
      : mostRecentRegisterEndDate.toISOString();

  const optionsFiltered = useMemo(
    () =>
      options.filter((option) => {
        if (
          orderType === OrderType.Action &&
          currentState !== variant.Annulled
        ) {
          return option.value === variant.Annulled;
        } else if (
          orderType === OrderType.Action &&
          currentState === variant.Annulled
        ) {
          return option.value === variant.Open;
        } else {
          return currentState !== option.value;
        }
      }),
    [currentState]
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const dataOrders = await getOrders();
        setOrders(dataOrders.orders.orders);
        defaultValues?.state === OrderState.End &&
        optionsFiltered.some((option) => option.value === OrderState.End)
          ? setValue('date', formatRegisterEndDate)
          : setValue('date', new Date().toISOString());
      } catch (err) {
        Sentry.captureException(err);
        console.log(err);
      }
    };
    fetchData();
  }, [orders]);

  const onSubmit = useCallback(async () => {
    const formValues = getValues();

    const validation = () => {
      let actions = 0;
      let validatedActions = 0;
      let openOrPartialActions = 0;

      orders.forEach((order) => {
        if (order.type === OrderType.Action) {
          actions += 1;
          if (order.state === OrderState.Validated) {
            validatedActions += 1;
          } else if (
            order.state === OrderState.Open ||
            order.state === OrderState.Partial
          ) {
            openOrPartialActions += 1;
          }
        }
      });
      if (formValues.state === OrderState.End && orders.length === 1) {
        setTextError(
          'No es poden tancar ordres genèriques o campanyes que no tinguin res associat'
        );
        return false;
      }
      if (formValues?.state === 'open') return true;
      if (actions === 0) return true;
      if (openOrPartialActions > 0) {
        setTextError('No es poden quedar actuacions parcials o obertes');
        return false;
      }
      if (formValues.state === 'end' && validatedActions > 0) return true;
      if (formValues.state === 'annulled' && validatedActions === 0)
        return true;

      setTextError('Hi ha actuacions sense revisar');
      return false;
    };

    validation() || orderType === 'action'
      ? setInitialModal(!initialModal)
      : setValidationError(true);
  }, [orders, initialModal, orderType]);

  const sendData = useCallback(async () => {
    const formValues = getValues();
    await client.mutate({
      mutation: UPDATE_ORDER,
      variables: {
        input:
          formValues?.state !== variant.Open
            ? {
                id: orderId,
                state: formValues.state,
                registerEndDate: formValues.date,
              }
            : {
                id: orderId,
                state:
                  orderType === OrderType.Action
                    ? OrderState.Validated
                    : formValues.state,
              },
      },
    });

    for (const accident of accidents) {
      await client.mutate({
        mutation: UPDATE_ORDER,
        variables: {
          input: {
            id: accident.id,
            state: formValues?.state,
          },
        },
      });
    }

    await Promise.all(
      accidents.map((accident) => {
        client.query({
          query: GET_ORDER_BY_ID,
          variables: {
            id: accident.id,
          },
          fetchPolicy: 'network-only',
        });
      })
    );

    sessionStorage.removeItem(OTModalKey);

    revalidatorFunction && revalidatorFunction();
    onClickClose();
  }, [orderId, orderType, revalidatorFunction, onClickClose, orders]);

  const onChangeState = useCallback(
    (option: OptionSelected) => {
      setIsDisabled(option?.value === OrderState.Open);
      option.value === variant.End
        ? setValue('date', formatRegisterEndDate)
        : setValue('date', new Date().toISOString());
    },
    [formatRegisterEndDate]
  );

  return (
    <>
      {orders.length > 0 && (
        <>
          {initialModal ? (
            <ModalContainer
              width={50}
              fill={colors.black}
              onClickClose={onClickClose}
            >
              <ContainerForm onSubmit={handleSubmit(onSubmit)}>
                <AlertIcon />
                <ContainerText>
                  <Title variant="semiBold">{title}</Title>
                </ContainerText>
                <ContainerInputSection>
                  <Text size="S">{`Selecciona l'ﬁestat`}</Text>
                  <Controller
                    control={control}
                    name="state"
                    render={({ field: { onChange } }) => {
                      return (
                        <DropDownInput
                          borderError={!!errors?.state}
                          inputSize="S"
                          typeDropDown="Default"
                          placeholder="Selecciona"
                          options={optionsFiltered}
                          defaultValue={defaultValues?.state}
                          onChangeSelected={(option) => {
                            onChangeState(option as OptionSelected);
                            return onChange(option?.value);
                          }}
                        />
                      );
                    }}
                  />
                  {validationError && (
                    <Typography size="XS" colorText="red">
                      {textError}
                    </Typography>
                  )}
                </ContainerInputSection>
                <ContainerInputSection>
                  <Text size="S">Data i hora del canvi</Text>

                  <Controller
                    control={control}
                    name="date"
                    render={({ field: { onChange, value } }) => {
                      return (
                        <DateTime
                          onChangeDate={(value) => onChange(value)}
                          isDisabled={isDisabled}
                          value={value as string}
                        />
                      );
                    }}
                  />
                </ContainerInputSection>
                <MainButton text="Continuar" type="submit" />
              </ContainerForm>
            </ModalContainer>
          ) : (
            <ModalContainer
              width={50}
              fill={colors.black}
              onClickClose={onClickClose}
            >
              <Container {...props}>
                <ContainerText>
                  <Title variant="semiBold">{titleSaveModal}</Title>
                </ContainerText>
                <MainButtonStyled text="Si" onClick={sendData} />
                <MainButtonStyled
                  text="No"
                  variant="secondary"
                  onClick={() => setInitialModal(!initialModal)}
                />
              </Container>
            </ModalContainer>
          )}
        </>
      )}
    </>
  );
};

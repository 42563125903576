import { client } from '../gql/client';
import { GET_SELECTORS_DATA } from '../gql/queries/orders/orders';
import { FETCH_PREDEFINED_DATA_LISTS } from '../gql/queries/orders/predefinedData';

export const fetchDefaultData = async () => {
  await Promise.all([
    client.query({
      query: GET_SELECTORS_DATA,
    }),

    client.query({
      query: FETCH_PREDEFINED_DATA_LISTS,
    }),
  ]);
};

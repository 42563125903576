import React, { useLayoutEffect } from 'react';
import { useAsyncValue, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

// Components
import OperatorsAndVehicleForm from '../../../../../components/forms/ActionForms/OperatorsAndVehicles';

// Constants
import { formActionNaming } from '../../../../../components/forms/ActionForms/constants';

// Types
import { type OperatorsAndVehiclesFormValues } from '../../../../../components/forms/ActionForms/OperatorsAndVehicles/types';
import { OrderType } from '../../../../../types/orders';

// Redux
import { coticOTSelected } from '../../../../../state/actions/ui/coticOTSelectedGeneralView';

export default function OperatorsAndVehiclesFormPage(): JSX.Element {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loaderData = useAsyncValue() as OperatorsAndVehiclesFormValues;

  const onFormSubmit = async () => {
    navigate('../location');
  };

  useLayoutEffect(() => {
    dispatch(
      coticOTSelected(
        OrderType.Action,
        loaderData.breadCrumbsData.orderParentCotic,
        loaderData.breadCrumbsData.orderParentId
      )
    );
  }, []);

  return (
    <OperatorsAndVehicleForm
      onFormSubmit={onFormSubmit}
      initialData={loaderData}
      namingPersistForm={formActionNaming.NEW_OPERATORS_AND_VEHICLE_FORM}
    />
  );
}

import styled, { css, keyframes } from 'styled-components';

// Dpenedencies
import { NavLink } from 'react-router-dom';

// Styles utils
import { colors } from '../../../styles/colors';
import { spaces } from '../../../styles/spaces';

// Components
import { Accordion } from '../Accordion';

// Types
import { ContainerProps } from './types';
import { fontWeight } from '../../../styles/fonts';

const menuEntry = keyframes`
  from {
    transform: translateX(-39.9rem);
  }
  to {
    transform: translateX(0);
  }
`;

const menuExit = keyframes`
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-39.9rem);
  }
`;

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  width: 39.9rem;
  height: 100vh;
  box-shadow: 0 0.2rem 0.7rem 0.4rem rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 7.9rem;
  left: 0;
  padding: 0;
  background-color: #ffffff;
  z-index: 99999;

  animation: ${({ isOpen }) =>
    isOpen
      ? css`
          ${menuEntry} 0.5s ease-in-out forwards;
        `
      : css`
          ${menuExit} 0.5s ease-in-out forwards;
        `};
`;

export const CloseMenu = styled.button`
  width: 1rem;
  height: 1rem;
  align-self: flex-end;
  margin-right: 3rem;
  margin-top: ${spaces.xl}rem;
  background-color: transparent;
  border: none;

  svg {
    width: 1.6rem;
    height: 1.6rem;
  }

  svg > path {
    fill: ${colors.black};
  }
`;

export const ContainerHeader = styled.div`
  display: flex;
  margin-top: 1.4rem;
  cursor: pointer;
`;

export const ContainerHeaderPrincipal = styled.div`
  display: flex;
  margin-top: 1.4rem;
  margin-left: 2.8rem;
`;

export const ArrowButton = styled.button`
  width: 2.1rem;
  margin-right: 1.4rem;
  margin-left: 6rem;
  border: none;
  background-color: transparent;
`;

export const ContainerSecondary = styled.div`
  margin-left: 1rem;
`;

export const AccordionComponent = styled(Accordion)``;

export const List = styled.ul`
  margin-top: ${spaces.s}rem;
  width: 26.6rem;
  margin-left: 6rem;
`;

export const ListSecondary = styled.ul`
  margin-top: ${spaces.s}rem;
  width: 26.6rem;
  margin-left: 6rem;
`;

export const ListOption = styled.li``;

export const LinkOption = styled(NavLink)`
  display: flex;
  text-decoration: none;
  cursor: pointer;
  width: 100%;
  padding: ${spaces.xxs}rem ${spaces.m}rem;
  padding-left: ${spaces.xxs}rem;
  border-radius: 0.4rem;
  &.active {
    background-color: ${colors.greyVeryLight};
    p {
      font-weight: ${fontWeight.semiBold};
    }
  }
`;

export const HorizontalLine = styled.div`
  border-bottom: 0.1rem solid ${colors.greyLight};
  margin-top: 1.8rem;
  margin-bottom: 1.8rem;
  width: 26.6rem;
`;

export const ContainerTitle = styled.div`
  display: flex;
  width: fit-content;
  cursor: pointer;
`;

export const ButtonBurguerMenu = styled.img`
  fill: ${colors.black};
  width: 2rem;
  height: 2rem;
`;

export const ContainerListElement = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 26.6rem;
  cursor: pointer;
`;

export const ContainerLineSecondary = styled.div`
  margin-left: 6rem;
`;

export const SubItems = styled.li`
  cursor: pointer;
  margin: ${spaces.xxxs}rem 0;
`;

import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAsyncValue, useNavigate, useRevalidator } from 'react-router-dom';

// Styles
import { Container } from './styles';
import { ContainerButton } from '../../components/MainStyledComponents';

//Components
import { InputRadioTagTable } from '../../../../../components/display/tables/InputRadioTagTable';
import { MainButton } from '../../../../../components/inputs/buttons/MainButton';

// Types
import { dataTypes } from '../../../../../components/display/tables/InputRadioTagTable/types';
import {
  tableIds,
  takeBase,
} from '../../../../../components/display/tables/types';
import { OrderType } from '../../../../../types/orders';

// GQL
import { client } from '../../../../../gql/client';
import { GET_GENERIC_ORDERS } from '../../../../../gql/queries/genericOrders';
import { GET_ORDER_BY_ID } from '../../../../../gql/queries/orders/orders';
import { selectAllOrders } from '../../../../../gql/selectors/orders';

// Redux
import { useSelector } from 'react-redux';
import { getCursor } from '../../../../../state/selectors/ui/tables';
import { coticOTSelected } from '../../../../../state/actions/ui/coticOTSelectedGeneralView';
import { setCursor } from '../../../../../state/actions/ui/cursorTables';

export const WorkOrdersPage = () => {
  const data = useAsyncValue() as dataTypes;
  const revalidator = useRevalidator();
  const dispatch = useDispatch();
  const [selectedRowId, setSelectedRowId] = useState<string>('');
  const navigate = useNavigate();
  const handleSeeOrder = useCallback(async () => {
    const {
      data: { order },
    } = await client.query({
      query: GET_ORDER_BY_ID,
      variables: {
        id: selectedRowId,
      },
    });
    dispatch(
      coticOTSelected(
        OrderType.Generic,
        order.cotic as unknown as string,
        selectedRowId
      )
    );
    navigate(`/work-orders/${selectedRowId}`);
  }, [selectedRowId]);

  const dataSelector = useSelector(getCursor(tableIds.WORKORDER_GENERAL_VIEW));

  const onReFetchData = useCallback(async () => {
    const { data } = await client.query({
      query: GET_GENERIC_ORDERS,
      variables: {
        input: {
          take: takeBase,
          cursor: '',
          concessionId: dataSelector.concessionId,
          orderBy: 'desc',
          toDate: dataSelector.toDate,
          fromDate: dataSelector.fromDate,
          indexType: dataSelector.indexType,
          indexExpedition: dataSelector.indexExpedition,
          classification: dataSelector.classification,
          capitolId: dataSelector.capitolId,
          subCapitolId: dataSelector.subCapitolId,
          state: dataSelector.state,
        },
      },
      fetchPolicy: 'network-only',
    });
    const setCursorData = {
      take: takeBase,
      cursor: '',
      orderBy: 'desc',
      concessionId: dataSelector?.concessionId,
      toDate: dataSelector?.toDate,
      fromDate: dataSelector?.fromDate,
      indexType: dataSelector?.indexType,
      indexExpedition: dataSelector?.indexExpedition,
      classification: dataSelector?.classification,
      capitolId: dataSelector?.capitolId,
      subCapitolId: dataSelector?.subCapitolId,
      state: dataSelector?.state,
    };

    dispatch(setCursor(tableIds.WORKORDER_GENERAL_VIEW, setCursorData));
    await selectAllOrders(data.genericOrders.genericOrders, true);

    revalidator.revalidate();
  }, [dataSelector]);
  const onRefetchPagination = useCallback(async () => {
    revalidator.revalidate();
  }, []);

  return (
    <Container>
      <InputRadioTagTable
        titleText="Filtrar Ordres de Treball per:"
        data={data}
        setRowState={setSelectedRowId}
        onReFetchData={onReFetchData}
        onReFetchPagination={onRefetchPagination}
        tableId={tableIds.WORKORDER_GENERAL_VIEW}
      />
      <ContainerButton>
        <MainButton
          text="Veure Ordre de Treball"
          disabled={selectedRowId.length === 0}
          onClick={handleSeeOrder}
        />
      </ContainerButton>
    </Container>
  );
};

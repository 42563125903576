import { redirect } from 'react-router-dom';

// Sentry
import * as Sentry from '@sentry/react';

// Constants
import { formOrderNaming } from '../../../../../../components/forms/NewWorkOrderForm/constants';

// GQL
import { client } from '../../../../../../gql/client';

// Types
import { SaveDataParams } from './types';
import {
  UPDATE_ORDER,
  UPDATE_ORDER_RELATIONSHIPS,
} from '../../../../../../gql/mutations/orders';
import {
  UPDATE_CAMPAIGN,
  UPDATE_CAMPAIGN_RELATIONSHIPS,
} from '../../../../../../gql/mutations/campaign';
import { UNSPECIFIC } from '../../../../../../types/utils';

export const saveData = async ({ formData }: SaveDataParams) => {
  const id = formData.get('id');
  const description = formData.get('description');
  const team = formData.get('team');
  const campaign = formData.get('campaign');
  const lane = formData.get('lane');
  const margin = formData.get('margin');
  const direction = formData.get('direction');
  const track = formData.get('track');
  const road = formData.get('road');
  const pkInit = formData.get('pkInit');
  const pkEnd = formData.get('pkEnd');
  const concession = formData.get('concession');
  const registerInitDate = formData.get('registerInitDate');
  const capitol = formData.get('capitol');
  const subCapitol = formData.get('subCapitol');
  const modelTypeId = formData.get('modelTypeId');

  try {
    const pkInitSplit = pkInit?.toString().replace(/ /g, '').split('+');
    const pkEndSplit = pkEnd?.toString().replace(/ /g, '').split('+');
    await Promise.all([
      registerInitDate || description
        ? client.mutate({
            mutation: UPDATE_ORDER,
            variables: {
              input: {
                id,
                registerInitDate: registerInitDate
                  ? registerInitDate
                  : undefined,
                description: description ? description : undefined,
              },
            },
          })
        : undefined,
      pkInit || pkEnd || campaign || lane || margin || direction
        ? client.mutate({
            mutation: UPDATE_CAMPAIGN,
            variables: {
              input: {
                id: modelTypeId,
                pkInitKm: pkInit ? Number(pkInitSplit?.[0]) : undefined,
                pkInitMeter: pkInit ? Number(pkInitSplit?.[1]) : undefined,
                pkEndKm: pkEnd ? Number(pkEndSplit?.[0]) : undefined,
                pkEndMeter: pkEnd ? Number(pkEndSplit?.[1]) : undefined,
                name: campaign ? campaign : undefined,
                lane: lane ? lane : undefined,
                margin: margin ? margin : UNSPECIFIC,
                direction: direction ? direction : UNSPECIFIC,
                track: track ? track : undefined,
              },
            },
          })
        : undefined,
      concession || road || team
        ? client.mutate({
            mutation: UPDATE_CAMPAIGN_RELATIONSHIPS,
            variables: {
              input: {
                id: modelTypeId,
                concessionId: concession ? concession : undefined,
                roadId: road ? road : undefined,
                teamId: team ? team : undefined,
              },
            },
          })
        : undefined,
      capitol || subCapitol
        ? client.mutate({
            mutation: UPDATE_ORDER_RELATIONSHIPS,
            variables: {
              input: {
                id,
                capitol: capitol ? capitol : undefined,
                subCapitol: subCapitol ? subCapitol : undefined,
              },
            },
          })
        : undefined,
    ]);
  } catch (err) {
    Sentry.captureException(err);
    console.log('> Campaign updated - error updating', err);
  }

  sessionStorage.removeItem(formOrderNaming.EDIT_CAMPAIGN_FORM);
  return redirect(`../../${id}`);
};

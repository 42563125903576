import React, { useContext } from 'react';
import { Outlet, Navigate } from 'react-router-dom';

// Hooks
import { AuthContext } from '../../../hooks/useAuth';

// Components Styled
import { Container } from './styles';

export default function AuthLayout(): JSX.Element {
  const userData = useContext(AuthContext);
  if (userData?.user?.user?.validated) {
    return <Navigate to="/dashboard" replace />;
  }
  return (
    <Container>
      <Outlet />
    </Container>
  );
}

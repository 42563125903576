import { storedDataOTSelectedType } from '../components/display/ContentCardResume/types';
import { UNSPECIFIC } from './utils';

export enum CommunicationSendStatus {
  SENT = 'sent',
  RECEIVED = 'received',
}

export enum CommunicationDirection {
  DOWN = 'down',
  TOP = 'top',
  BOTH = 'both',
}

export enum Margin {
  UP = 'up',
  BOTH = 'both',
  DOWN = 'down',
  LEFT = 'left',
  RIGHT = 'right',
  TOP = 'top',
}

type CommunicationOptions = {
  id: string;
  name: string;
};

export type OrderStaticOptions = {
  id: string;
  name: string;
  value: string;
};

export type CapitolOptions = {
  id: string;
  name: string;
  subCapitolsIDs: string[];
};

export type SubCapitolOptions = {
  id: string;
  name: string;
  elementOrdersIds: string[];
};

type PredefinedOrdersOptions = {
  id: string;
  name: string;
  capitolId: string;
  subCapitolId: string;
  elementOrderId: string;
};

type equipmentType = {
  name: string;
  id: string;
};

type GroupTypes = {
  concessions: CommunicationOptions[];
  capitols: CapitolOptions[];
  subCapitols: SubCapitolOptions[];
  predefinedOrders: PredefinedOrdersOptions[];
  classifications: CommunicationOptions[];
  interlocutorGroups: CommunicationOptions[];
  mediaOfCommunications: CommunicationOptions[];
  roads: CommunicationOptions[];
  groupTracks: OrderStaticOptions[];
  groupLanes: OrderStaticOptions[];
  specialTagType: OrderStaticOptions[];
  teams: equipmentType[];
};

export type dataSelectionOTType = {
  id: string;
  data: {
    capitol: string;
    concession: string;
    cotic: string[];
    road: string;
    date: number;
    description: string;
    classification: string;
    hour: number;
    pkEnd: string;
    pkInit: string;
    state: string;
    subCapitol: string;
  };
  isShowedData: boolean;
};

export type NewCommunication = {
  id?: string;
  cotic: string[];
  parentOrderType: string;
  capitolName: string;
  subCapitolName: string;
  operator: string;
  creationDate?: string;
  registerInitDate: string;
  registerEndDate?: string;
  groupInter: string;
  media: string;
  sendStatus: CommunicationSendStatus;
  concession: string;
  city: string;
  pkInitRoad: string;
  pkEndRoad: string;
  road: string;
  leftSummaryButton?: string;
  track: string;
  roadDirections: string;
  direction?: CommunicationDirection | typeof UNSPECIFIC;
  margin?: 'left' | 'right' | 'both' | typeof UNSPECIFIC;
  lane: string;
  pkInit: string;
  pkEnd: string;
  description: string;
  predefine: string;
  classification: string;
  specialTag: string;
  capitol: string;
  subCapitol: string;
  team?: string;
  intent?: string;
  selectionOT: {
    label: string;
    data: dataSelectionOTType;
    openModal: () => void;
  };
  storedDataOTSelected: storedDataOTSelectedType;
  state: string;
  parentOrderId?: string;
  isCommunicationDuplicate?: boolean;
  editCommunication?: true;
  dataDiff?: any;
} & GroupTypes;

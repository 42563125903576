import React from 'react';

// Styles
import { Container } from './styles';

// Components
import ResetPasswordForm from '../../../components/forms/ResetPasswordForm';

//Layouts
import CredentialsLayout from '../../../components/layouts/CredentialsLayout';

export default function ResetPasswordPage() {
  return (
    <Container>
      <CredentialsLayout>
        <ResetPasswordForm />
      </CredentialsLayout>
    </Container>
  );
}

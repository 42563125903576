import { object, string } from 'yup';
import { LoginFormType } from './types';

export const LoginFormSchema = object<LoginFormType>({
  email: string()
    .email('Ha de ser un email vàlid')
    .required('Aquest camp és requerit'),
  password: string()
    .required('Aquest camp és requerit')
    .matches(/^\S+$/, () => 'Revisa, hi ha un espai en blanc'),
});

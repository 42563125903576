import React, { useLayoutEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useAsyncValue, useNavigate } from 'react-router-dom';

// Components
import EditingActionForm from '../../../../../components/forms/ActionForms/ActionEdition';

// Constants
import { formActionValidationNaming } from '../../../../../components/forms/ActionForms/constants';

// Types
import { type ActionEditionFormValues } from '../../../../../components/forms/ActionForms/ActionEdition/types';
import { type WorkOrder } from '../../../../../types/workOrder';
import { OrderActionReferences } from '../types';

// Redux
import { coticOTSelected } from '../../../../../state/actions/ui/coticOTSelectedGeneralView';

export default function EditionValidationFormPage(): JSX.Element {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loaderData = useAsyncValue() as ActionEditionFormValues &
    WorkOrder &
    OrderActionReferences & { parentExpeditionOrderStatus?: string };

  const onFormSubmit = () => {
    navigate('../materials');
  };

  useLayoutEffect(() => {
    dispatch(
      coticOTSelected(
        'actions-validation',
        loaderData.cotic,
        loaderData.orderId
      )
    );
  }, []);

  return (
    <EditingActionForm
      onFormSubmit={onFormSubmit}
      initialData={loaderData}
      namingPersistForm={formActionValidationNaming.NEW_VALIDATION_EDITION_FORM}
      namingUpdateForm={formActionValidationNaming.DATA_CHANGES}
      textButton="Validar"
    />
  );
}

import { LoaderFunction, defer } from 'react-router-dom';
import { AuthContextType } from '../../../../../hooks/useAuth';

// Sentry
import * as Sentry from '@sentry/react';

// GQL
import { client } from '../../../../../gql/client';
import {
  FETCH_PREDEFINED_ORDERS,
  FETCH_SUB_CAPITOLS,
} from '../../../../../gql/queries/orders/predefinedData';
import { GET_SELECTORS_DATA } from '../../../../../gql/queries/orders/orders';
import {
  FETCH_CAPITOLS,
  FETCH_CLASSIFICATIONS,
} from '../../../../../gql/queries/orders/predefinedData';
import { selectOrderByIdForFillFormData } from '../../../../../gql/selectors/orders';

// Data static
import { groupLanes, groupTracks } from '../../../../../data/orders';

// Compression
import { decompressSessionStorage } from '../../../../../utils/sessionStorage';

// naming
import { formOrderNaming } from '../../../../../components/forms/NewWorkOrderForm/constants';

const loadNewWorkOrderSummary = async (
  idOrder: string,
  userData: AuthContextType | null
) => {
  try {
    const workOrderData = await selectOrderByIdForFillFormData(idOrder);
    return {
      ...workOrderData,
      operator: `${userData?.user.user.name} ${
        userData?.user.user.surName.split(' ')[0]
      }`,
    };
  } catch (err) {
    Sentry.captureException(err);
    console.log('> Work Order Summary loading error:', err);
    return true;
  }
};

const loadFormOptions = async (userData: AuthContextType | null) => {
  try {
    if (userData?.user) {
      const [
        { data },
        {
          data: { capitols },
        },
        {
          data: { classifications },
        },
        {
          data: { predefinedOrders },
        },
        {
          data: { subCapitols },
        },
      ] = await Promise.all([
        client.query({
          query: GET_SELECTORS_DATA,
        }),
        client.query({
          query: FETCH_CAPITOLS,
        }),
        client.query({
          query: FETCH_CLASSIFICATIONS,
        }),
        client.query({
          query: FETCH_PREDEFINED_ORDERS,
        }),
        client.query({
          query: FETCH_SUB_CAPITOLS,
        }),
      ]);

      return {
        ...data,
        capitols,
        classifications,
        groupTracks,
        groupLanes,
        predefinedOrders,
        subCapitols,
      };
    }
  } catch (err) {
    Sentry.captureException(err);
    console.log('> Work Order Summary loading error:', err);
    return true;
  }
};

export const NewWorkOrderDuplicateLoader: (
  userData: AuthContextType | null
) => LoaderFunction | undefined =
  (userData) =>
  async ({ params }) => {
    let dataFromWorkOrderToDuplicate = {};

    if (params.id) {
      dataFromWorkOrderToDuplicate = await loadNewWorkOrderSummary(
        params.id,
        userData
      );
    }

    // Pick the cached form values from local storage in case browser was reloaded
    const stringifyForm = sessionStorage.getItem(
      formOrderNaming.NEW_DUPLICATE_WORK_ORDER_FORM
    );

    const loaderToResolve = await loadFormOptions(userData);

    if (stringifyForm) {
      const sessionStorageData = JSON.parse(
        decompressSessionStorage(stringifyForm)
      );

      const combinedData = {
        ...loaderToResolve,
        ...dataFromWorkOrderToDuplicate,
        ...sessionStorageData,
      };

      return defer({
        data: combinedData,
      });
    }

    const nameOperator = `${userData?.user?.user.name} ${
      userData?.user?.user.surName.split(' ')[0]
    }`;
    const combinedData = {
      ...loaderToResolve,
      ...dataFromWorkOrderToDuplicate,
      operator: nameOperator,
    };

    // Prepare some default values for the form to load properly
    return defer({
      data: combinedData,
    });
  };

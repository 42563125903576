import styled from 'styled-components';

// Colors
import { colors } from '../../../../../styles/colors';

// Spaces
import {
  breakPoints,
  percentBaseResponsive,
} from '../../../../../styles/spaces';

// Types
import { thProps, thRightProps } from './types';
import DropDownInput from '../../../../inputs/DropDown/DropDownInput';
import { DropDownListContainer } from '../../../../inputs/DropDown/DropDownList/styles';
import {
  DropDownHeader,
  SelectedOption,
} from '../../../../inputs/DropDown/DropDownInput/styles';
import { DisabledType } from '../OperatorsSelectionTable/types';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.8rem;
`;

export const ContainerSearchBar = styled.div`
  width: 100%;
  max-width: 36rem;
`;

export const ContainerTables = styled.div`
  display: flex;
  gap: 1.8rem;
  @media (max-width: ${breakPoints.landscapeTablet *
    percentBaseResponsive}rem) {
    flex-direction: column;
    gap: 2.8rem;
  }
`;

export const SubContainer = styled.div`
  max-width: 80rem;
  flex: 1.5;
  overflow-y: auto;
  max-height: 19.1rem;
  background-color: ${colors.greyVerySoft};
  border-bottom: 0.1rem solid ${colors.greyLight};
  border-radius: 0.5rem 0.5rem 0 0;
  @media (max-width: ${breakPoints.landscapeTablet *
    percentBaseResponsive}rem) {
    max-width: initial;
  }
`;

export const SubContainerRight = styled(SubContainer)`
  max-width: 60rem;
  min-height: 19.1rem;
  flex: 1;
  @media (max-width: ${breakPoints.landscapeTablet *
    percentBaseResponsive}rem) {
    max-width: initial;
  }
`;

export const Table = styled.table`
  width: 100%;
  & * {
    white-space: nowrap;
  }
`;

export const TableRight = styled.table`
  width: 100%;
`;

export const Thead = styled.thead`
  color: ${colors.white};
  background: ${colors.greyDark};
  position: sticky;
  top: 0;
  z-index: 99;
`;

export const Tr = styled.tr``;

export const Th = styled.th<thProps>`
  padding: 0.2rem 0 0.2rem 1rem;
  text-align: left;
  &:first-of-type {
    padding-left: 1.4rem;
    min-width: 9rem;
    width: 9rem;
  }

  &:last-of-type {
    padding-right: 1.4rem;
    min-width: 4rem;
    width: 4rem;
    text-align: center;
  }
`;

export const ThRightTable = styled.th<thRightProps>`
  padding: 0.2rem 0 0.2rem 1rem;
  &:first-of-type {
    padding-left: 1.4rem;
    min-width: 9rem;
    width: 9rem;
    text-align: left;
  }

  &:nth-of-type(2) {
    text-align: left;
  }

  &:nth-of-type(3) {
    text-align: left;
  }

  &:nth-of-type(4) {
    min-width: 5rem;
    width: 5rem;
  }

  &:last-of-type {
    padding-right: 1.4rem;
    min-width: 5rem;
    width: 5rem;
  }
`;

export const Td = styled.td<thProps>`
  padding: 0.65rem 0 0.65rem 1rem;
  &:first-of-type {
    padding-left: 1.4rem;
  }

  &:last-of-type {
    padding-right: 1.4rem;
    text-align: center;
  }
`;

export const TdRightTable = styled.td<thRightProps>`
  padding: 0.8rem 0 0.8rem 1rem;
  &:first-of-type {
    padding-left: 1.4rem;
  }

  &:nth-of-type(3) > input {
    text-align: center;
  }

  &:last-of-type {
    padding-right: 1.4rem;
    vertical-align: middle;
    text-align: center;
  }
`;

export const Img = styled.img`
  width: 1.5rem;
  height: 2rem;
  cursor: pointer;
`;

export const Tbody = styled.tbody``;

export const TbodyRight = styled.tbody``;

export const TrBody = styled.tr``;

export const StyledInput = styled.input`
  width: 100%;
  padding: 0;
  border: none;
  appearance: none;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  background: transparent;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  &[type='number'] {
    -moz-appearance: textfield;
  }
  :focus {
    outline: none;
  }
`;

export const Input = styled.input`
  width: 100%;
  padding: 0;
  border: none;
  appearance: none;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: ${colors.black};
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  &[type='number'] {
    -moz-appearance: textfield;
  }
  :focus {
    outline: none;
  }
`;

export const DropDownInputStyled = styled(DropDownInput)<DisabledType>`
  ${DropDownHeader} {
    padding: 0;
    height: initial;
    :focus {
      outline: none;
    }
    background: ${(isDisabled) => isDisabled && 'none'};
  }
  ${DropDownListContainer} {
    z-index: 1;
  }
  @media (max-width: ${breakPoints.smallDesktop * percentBaseResponsive}rem) {
    width: 100%;
  }
  @media (max-width: ${breakPoints.landscapeTablet *
    percentBaseResponsive}rem) {
    ${SelectedOption} {
      font-size: initial;
    }
  }
`;

import { createTransform } from 'redux-persist';
import { TransformConfig } from 'redux-persist/es/createTransform';
import stringify from 'json-stringify-safe';

// Sentry
import * as Sentry from '@sentry/react';

// Compress
import {
  compressSessionStorage,
  decompressSessionStorage,
} from '../utils/sessionStorage';

export default function createTransformCompress(
  config: TransformConfig | undefined
) {
  return createTransform(
    (state) => compressSessionStorage(stringify(state)),
    (state) => {
      if (typeof state !== 'string') {
        if (!import.meta.env.PROD) {
          console.error(
            'redux-persist: expected outbound state to be a string'
          );
        }
        return state;
      }

      try {
        return JSON.parse(decompressSessionStorage(state));
      } catch (err) {
        Sentry.captureException(err);
        if (!import.meta.env.PROD) {
          console.error('redux-persist: error while decompressing state', err);
        }
        return null;
      }
    },
    config
  );
}

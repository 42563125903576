import styled from 'styled-components';

import {
  breakPoints,
  percentBaseResponsive,
  spaces,
} from '../../../styles/spaces';
import { ButtonIconBackground } from '../../inputs/buttons/ButtonIconBackground';
import { Typography } from '../../display/Typography';
import { fontSize } from '../../../styles/fonts';

export const ContainerOperator = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spaces.s}rem;
`;

export const ContainerDescription = styled.div`
  width: 100%;
  max-width: 147.8rem;
`;

export const ContainerWithButton = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const ContainerCampaign = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 35rem;
`;

export const ContainerSwitch = styled.div`
  display: flex;
  align-items: center;
  gap: ${spaces.xxs}rem;
  margin-bottom: ${spaces.xxxs}rem;
`;

export const StyledTypography = styled(Typography)`
  @media (max-width: ${breakPoints.landscapeTablet *
    percentBaseResponsive}rem) {
    font-size: ${fontSize.XS};
  }
`;

export const Editicon = styled(ButtonIconBackground)`
  width: 3.4rem;
  height: 3.4rem;
`;

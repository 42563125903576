// Dependencies
import styled, { css } from 'styled-components';

// Colors
import { colors } from '../../../styles/colors';

// Spaces
import { spaces } from '../../../styles/spaces';

// Types
import { ContainerProps } from './types';

// Components
import { CloseIcon } from '../../../assets/icons/CloseIcon';
import { Typography } from '../Typography';

export const Container = styled.div<ContainerProps>`
  overflow: hidden;
  display: flex;
  align-items: center;
  gap: 1rem;
  border-radius: 100rem;
  width: max-content;
  ${({ variant }) =>
    ({
      campaign: css`
        background-color: ${colors.pink};
      `,
      open: css`
        background-color: ${colors.blue};
      `,
      partial: css`
        background-color: ${colors.gold};
      `,
      end: css`
        background-color: ${colors.greyBoard};
      `,
      validated: css`
        background-color: ${colors.green};
      `,
      surveillanceRoute: css`
        background-color: ${colors.pink};
      `,
      duplicate: css`
        background-color: ${colors.garnet};
      `,
      annulled: css`
        background-color: ${colors.red};
      `,
      cotic: css`
        background-color: ${colors.garnet};
        border: none;
      `,
      default: css`
        background-color: ${colors.garnet};
        border: none;
      `,
      transfer: css`
        background-color: ${colors.garnet};
        border: none;
      `,
      transferred: css`
        background-color: ${colors.forestGreen};
        border: none;
      `,
    }[variant])}
  ${({ sizeTag }) =>
    ({
      default: css`
        height: 3rem;
        padding: 0 2rem;
      `,
      medium: css`
        height: 2.5rem;
        padding: 0 1.1rem;
      `,
      small: css`
        height: 1.8rem;
        padding: 0 ${spaces.s}rem;
      `,
      circle: css`
        height: 0.8rem;
        width: 0.8rem;
        border-radius: 100;
      `,
    }[sizeTag])}
`;

export const Title = styled.span`
  color: ${colors.black};
`;

export const ImageIcon = styled(CloseIcon)`
  width: 1.1rem;
  height: 1.1rem;
  path {
    fill: ${colors.white};
  }
`;

export const Text = styled(Typography)`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

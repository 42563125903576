import {
  ACCEPT_DATA_MODAL_FORM,
  ROW_MODAL_FORM_DESELECTED,
  ROW_MODAL_FORM_SELECTED,
} from '../../actions/ui/selectRowTableModal';

export const initialState = {};

type actionType = {
  type: string;
  payload: { id: string; data: unknown };
};

export default function selectedDataTableModal(
  state = initialState,
  { type, payload }: actionType
) {
  switch (type) {
    case ROW_MODAL_FORM_SELECTED:
      return {
        ...state,
        [payload.id]: {
          id: payload.id,
          data: payload.data,
          isShowedData: false,
        },
      };
    case ACCEPT_DATA_MODAL_FORM:
      return {
        ...state,
        [payload.id]: {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          ...state[payload.id],
          isShowedData: true,
        },
      };
    case ROW_MODAL_FORM_DESELECTED: {
      const newStateUnset = { ...state };
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      delete newStateUnset[payload.id];
      return newStateUnset;
    }
    default:
      return state;
  }
}

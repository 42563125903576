import { gql } from '@apollo/client';

export const GET_POSITION_ROADS = gql`
  query GeoPositionRoads($input: FilterGeoPositionRoad!) {
    geoPositionRoads(input: $input) {
      id
      pkKm
      pkMeter
      latitude
      longitude
      direction
    }
  }
`;

import React from 'react';
import { UseFormSetValue, UseFormRegister } from 'react-hook-form';

export type CreateInputCell = {
  fieldName: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register: UseFormRegister<any>;
} & React.InputHTMLAttributes<HTMLInputElement>;

export type FormUtilities = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setValue: UseFormSetValue<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register: UseFormRegister<any>;
};

export type dataTable = { id?: string } & {
  materialId?: string;
  materialUnitId?: string;
  name?: string;
  codification?: string;
  subCodification?: string;
  ut?: string | number;
  typeOfMeasurement?: string;
};

export type NewMaterial = {
  materialId: string;
  name: string;
  codification: string;
  subCodification: string;
  typeOfMeasurement: string;
  ut: string | number;
};

export type tableProps = {
  data?: dataTable[] & {
    parentExpeditionOrderStatus?: string;
    state?: string;
  };
  defaultValues?: dataTable[];
  onDelete?: (id: string, unitId: string) => void;
  onAdd?: (id: string) => void;
  onUpdate: (e: string, id: string, unitId: string, completeId: string) => void;
} & FormUtilities;

type columnIDTypes = { [key: string]: { label: string; id: string } };

export const columnNames: columnIDTypes = {
  materialId: {
    id: 'materialId',
    label: 'Codi',
  },
  name: {
    id: 'name',
    label: 'Material',
  },
  codification: {
    id: 'codification',
    label: 'Codificació',
  },
  subCodification: {
    id: 'subCodification',
    label: 'Subcodificació',
  },
  ut: {
    id: 'ut',
    label: 'Ut.',
  },
  typeOfMeasurement: {
    id: 'typeOfMeasurement',
    label: 'Um.',
  },
};

export type thProps = {
  variant: variantThLeft;
};

export type variantThLeft =
  | 'materialId'
  | 'name'
  | 'codification'
  | 'subCodification'
  | 'add';

export type variantThRight =
  | 'materialId'
  | 'name'
  | 'ut'
  | 'typeOfMeasurement'
  | 'remove';

export type thRightProps = {
  variant: variantThRight;
};

export type onChangeInputTypes = (value: string) => void;

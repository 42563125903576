import styled from 'styled-components';
import { colors } from '../../../styles/colors';

// Styles
import {
  spaces,
  breakPoints,
  percentBaseResponsive,
} from '../../../styles/spaces';

// Components
import { Typography } from '../Typography';
import { fontSize, fontWeight } from '../../../styles/fonts';
import { Definition } from '../Definition';

export const GeneralContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 2rem;
  @media (max-width: ${breakPoints.smallDesktop * percentBaseResponsive}rem) {
    flex-direction: column;
    gap: ${spaces.xl}rem;
  }
`;

export const FirstGeneralColumn = styled.div`
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
  flex: 1.2;

  & > div:last-child {
    flex-basis: 100%;
  }
  @media (max-width: ${breakPoints.smallDesktop * percentBaseResponsive}rem) {
    & > div:last-child {
      flex-basis: calc(50% - 1rem);
    }
  }
  @media (max-width: ${breakPoints.landscapeTablet *
    percentBaseResponsive}rem) {
    & > div:last-child {
      flex-basis: 100%;
    }
  }
`;

export const SecondGeneralColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  flex: 0.8;
  & > div {
    height: 100%;
  }
`;

export const BasicInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  flex: 1;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spaces.s}rem;
  flex: 1;
  padding: ${spaces.xl}rem;
  background-color: ${colors.verySoftRed};
  border-radius: 0.8rem;
`;

export const MapContainer = styled.div`
  min-height: 40rem;
  min-width: 40rem;
  flex: 1;
  @media (max-width: ${breakPoints.smallDesktop * percentBaseResponsive}rem) {
    flex-basis: calc(50% - 1rem);
  }
  @media (max-width: ${breakPoints.landscapeTablet *
    percentBaseResponsive}rem) {
    flex-basis: 100%;
    min-height: 50rem;
  }
`;

export const TabContainer = styled(Container)`
  height: 100%;
  gap: ${spaces.xl}rem;

  @media (max-width: ${breakPoints.smallDesktop * percentBaseResponsive}rem) {
    flex-basis: calc(50% - 1rem);
  }
`;

export const Title = styled(Typography)`
  @media (max-width: ${breakPoints.landscapeTablet *
    percentBaseResponsive}rem) {
    font-size: ${fontSize.M};
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spaces.xl}rem;
`;

export const ColumnsContainer = styled.div`
  display: flex;
  flex: 1;
  gap: ${spaces.xl}rem ${spaces.xxxl}rem;
  @media (max-width: ${breakPoints.landscapeTablet *
    percentBaseResponsive}rem) {
    flex-wrap: wrap;
  }
`;

export const TitleTableContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spaces.s}rem;
`;

export const StyledColumnsContainer = styled(ColumnsContainer)`
  gap: ${spaces.xl}rem;
  flex-wrap: nowrap;
  @media (max-width: ${breakPoints.portraitTablet * percentBaseResponsive}rem) {
    flex-wrap: wrap;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spaces.xxs}rem;
`;

export const ContainerButtons = styled.form`
  display: flex;
  align-self: end;
  gap: ${spaces.s}rem;
`;

export const StyledDefinition = styled(Definition)`
  & > dt {
    font-weight: ${fontWeight.semiBold};
  }
`;

//Dependencies
import React, {
  useCallback,
  useMemo,
  useRef,
  useState,
  useLayoutEffect,
  useEffect,
} from 'react';
import {
  createColumnHelper,
  ColumnFiltersState,
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  flexRender,
  getPaginationRowModel,
  PaginationState,
  VisibilityState,
  FilterFnOption,
  Row,
  getSortedRowModel,
  SortingState,
} from '@tanstack/react-table';
import { useSelector, useDispatch } from 'react-redux';
import { format, parseISO, formatISO } from 'date-fns';
import isEqual from 'lodash.isequal';
import debounce from 'lodash.debounce';

// Styled Components
import {
  TrStyled,
  TableContainerStyled,
  SubContainerFiltersStyled,
  TdStyled,
  SubContainerHead,
  ShowFiltersButton,
  ArrowBottomStyled,
  DropDownInputStyledBig,
  ContainerButtonStyled,
  ContainerStyled,
  ContainerLoader,
  FiltersWithLoadingStyled,
} from './styles';
import {
  Th,
  Tbody,
  Title,
  Table,
  TextCell,
  TextHeader,
  InputRadioContainer,
  InputRadioStyled,
  InputCalendarStyled,
} from '../components/MainStyledComponents';

// Components
import { PaginationButtons } from '../components/PaginationButtons';
import InputCotic from '../../../inputs/InputCotic';
import { DropDownVisibilityColumn } from '../components/DropDownVisibilityColumn';
import { ButtonIconBackground } from '../../../inputs/buttons/ButtonIconBackground';
import { SpecialTag } from '../../SpecialTag';

// Types
import { columnID, columnNames } from '../components/types';
import { tableProps, dataTable } from './types';
import {
  Options,
  onChangeValue,
} from '../../../inputs/DropDown/DropDownList/types';
import { ImperativeRefInput } from '../../../inputs/DropDown/DropDownInput/types';
import {
  ImperativeRefCalendarInput,
  onChangeInputType,
} from '../../../inputs/Calendar/types';
import {
  ImperativeRefInputCotic,
  onChangeInputCoticTypes,
} from '../../../inputs/InputCotic/types';
import { Order } from '../../../../types/orders';
import { takeBase } from '../types';
import { option } from '../CampaignTable/types';
import { size, variant } from '../../SpecialTag/types';
import { Role } from '../../../../types/auth';
import { OrderState } from '../../../../types/orders';

// Mocks
import { mocksTable } from './mocks';

// Utils
import { coticFilterFunction, dateFilterFunction } from './utils';

// Media
import {
  useIsLandscapeTablet,
  useIsPortraitTablet,
} from '../../../../hooks/useMediaBreakPoints';
import { generateCoticPadding, stateOptions } from '../../../../utils/order';

// Redux
import {
  getCursor,
  getIsLoadingData,
} from '../../../../state/selectors/ui/tables';
import { setCursor } from '../../../../state/actions/ui/cursorTables';
import { setTake } from '../../../../state/actions/ui/takeTables';

// GQL
import { client } from '../../../../gql/client';
import {
  GET_CLASSIFICATION_BY_ID_OR_COTIC,
  GET_SUB_CAPITOLS_BY_IDS,
} from '../../../../gql/queries/orders/predefinedData';
import { Loader } from '../../../inputs/Loader';
import DropDownInput from '../../../inputs/DropDown/DropDownInput';

export const MultimediaTable = ({
  titleText,
  data,
  setRowState,
  onReFetchData,
  onReFetchPagination,
  tableId,
  ...props
}: tableProps): JSX.Element => {
  const dataAll = data?.dataOrderWithMultimedia ?? mocksTable;
  const globalCursor = useSelector(getCursor(tableId as string), isEqual);
  const isLoadingData = useSelector(getIsLoadingData(tableId), isEqual);
  const dispatch = useDispatch();
  const inputListCapitolRef = useRef<ImperativeRefInput>(null);
  const inputListSubcapitolRef = useRef<ImperativeRefInput>(null);
  const inputStateRef = useRef<ImperativeRefInput>(null);
  const inputRoadRef = useRef<ImperativeRefInput>(null);
  const inputDateRef = useRef<ImperativeRefCalendarInput>(null);
  const inputCoticRef = useRef<ImperativeRefInputCotic>(null);
  const isLanscapeTablet = useIsLandscapeTablet();
  const isPortraitTablet = useIsPortraitTablet();

  const [coticDefault, setCoticDefault] = useState(['', '', '', '']);
  const [showFilters, setShowFilters] = useState(false);
  const [rowSelection, setRowSelection] = useState({});
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 15,
  });

  const initialSubCapitolsOptions = data?.filtersDataOptions?.subCapitols?.map(
    (subCapitol: { name: string; id: string }) => ({
      label: subCapitol.name,
      value: subCapitol.id,
    })
  );

  const initialRoadOptions = data?.filtersDataOptions?.roads?.map(
    (road: { name: string; id: string }) => ({
      label: road.name,
      value: road.id,
    })
  );

  const [roadOptions] = useState(initialRoadOptions);

  const [subCapitolOptions, setSubCapitolOptions] = useState(
    initialSubCapitolsOptions
  );

  const [selectorCursor, setSelectorCursor] = useState<{
    take: number;
    cursor: string;
    fromDate: string | undefined;
    toDate: string | undefined;
    capitolId: string | undefined;
    subCapitolId: string | undefined;
    indexType: number | undefined;
    indexExpedition: number | undefined;
    classification: string | undefined;
    state: string | undefined;
    roadId: string | undefined;
  }>(globalCursor);

  const [selectorData, setSelectorData] = useState<{
    fromDate: string | undefined;
    toDate: string | undefined;
    capitolId: string | undefined;
    subCapitolId: string | undefined;
    indexType: number | undefined;
    indexExpedition: number | undefined;
    state: string | undefined;
    roadId: string | undefined;
  }>({
    fromDate: undefined,
    toDate: undefined,
    capitolId: undefined,
    subCapitolId: undefined,
    indexType: undefined,
    indexExpedition: undefined,
    state: undefined,
    roadId: undefined,
  });

  const [sizeTag, setSizeTag] = useState<size>(
    isPortraitTablet ? 'circle' : 'small'
  );

  const roleActive = data.roleActive;

  useEffect(() => {
    if (
      (roleActive === Role.RoomTeam || roleActive === Role.Tablet) &&
      selectorCursor.state === undefined
    ) {
      inputStateRef.current?.setSelectedOption({
        label: 'Open',
        value: OrderState.Open,
      });
    }
  }, [showFilters]);

  const handleClick = useCallback(() => {
    setShowFilters(!showFilters);
  }, [showFilters]);

  useEffect(() => {
    isLanscapeTablet ? setShowFilters(false) : setShowFilters(true);
  }, [isLanscapeTablet]);

  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>(
    isPortraitTablet
      ? {
          description: false,
          state: false,
        }
      : isLanscapeTablet
      ? {
          date: false,
          description: false,
        }
      : {}
  );

  useLayoutEffect(() => {
    setColumnVisibility(
      isPortraitTablet
        ? {
            state: false,
            description: false,
          }
        : isLanscapeTablet
        ? {
            date: false,
            description: false,
          }
        : {}
    );
    setSizeTag(isPortraitTablet ? 'circle' : 'small');
  }, [isPortraitTablet, isLanscapeTablet]);
  const callbackSetCotic = useCallback(async () => {
    let classification;
    if (selectorCursor?.classification) {
      const classificationRaw = await client.query({
        query: GET_CLASSIFICATION_BY_ID_OR_COTIC,
        variables: {
          id: selectorCursor?.classification,
        },
      });
      classification = classificationRaw?.data?.classification?.coticId;
    }
    const cotic = [
      '',
      selectorCursor?.indexType
        ? selectorCursor?.indexType >= 0
          ? generateCoticPadding(selectorCursor?.indexType, 4)
          : ''
        : '',
      selectorCursor?.indexExpedition
        ? selectorCursor?.indexExpedition >= 0
          ? generateCoticPadding(selectorCursor?.indexExpedition, 7)
          : ''
        : '',
      classification ? generateCoticPadding(classification, 2) : '',
    ];
    setCoticDefault(cotic);
  }, [selectorCursor]);

  useEffect(() => {
    callbackSetCotic();
  }, [selectorCursor]);

  const columnHelper = createColumnHelper<dataTable>();

  const [sorting] = useState<SortingState>([
    { id: 'creationDate', desc: true },
  ]);
  const columns = useMemo(
    () => [
      columnHelper.display({
        id: 'select',
        cell: ({ row }) => (
          <InputRadioContainer>
            <InputRadioStyled
              name="inputRadio"
              {...{
                checked: row.getIsSelected(),
                disabled: !row.getCanSelect(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />
          </InputRadioContainer>
        ),
      }),
      columnHelper.accessor(columnNames.state.id, {
        header: () =>
          !isPortraitTablet && (
            <TextHeader size={'XS'} colorText={'greyDark'}>
              {columnNames.state.label}
            </TextHeader>
          ),
        cell: (info) => (
          <SpecialTag sizeTag={sizeTag} variant={info.getValue() as variant} />
        ),
      }),
      columnHelper.accessor(columnNames.cotic.id, {
        header: () => (
          <TextHeader size={'XS'} colorText={'greyDark'}>
            {columnNames.cotic.label}
          </TextHeader>
        ),
        cell: (info) => (
          <TextCell size={'S'}>
            {(info.getValue() as [string])?.join('.')}
          </TextCell>
        ),
        filterFn: 'coticFilter' as FilterFnOption<Order>, //Indicate the filter function to be used for this column
      }),
      columnHelper.accessor(columnNames.registerInitDate.id, {
        header: () => (
          <TextHeader size={'XS'} colorText={'greyDark'}>
            {columnNames.registerInitDate.label}
          </TextHeader>
        ),
        cell: (info) => (
          <TextCell size={'S'}>
            {format(new Date(info.getValue() as string), 'dd/MM/yyyy - HH:mm')}
          </TextCell>
        ),
        filterFn: 'dateFilter' as FilterFnOption<dataTable>,
      }),

      columnHelper.accessor(columnNames.road.id, {
        header: () => (
          <TextHeader size={'XS'} colorText={'greyDark'}>
            {columnNames.road.label}
          </TextHeader>
        ),
        cell: (info) => (
          <TextCell size={'S'}>{info.getValue() as string}</TextCell>
        ),
      }),
      columnHelper.accessor(columnNames.capitol.id, {
        header: () => (
          <TextHeader size={'XS'} colorText={'greyDark'}>
            {columnNames.capitol.label}
          </TextHeader>
        ),
        cell: (info) => (
          <TextCell size={'S'}>{info.getValue() as string}</TextCell>
        ),
      }),
      columnHelper.accessor(columnNames.subCapitol.id, {
        header: () => (
          <TextHeader size={'XS'} colorText={'greyDark'}>
            {columnNames.subCapitol.label}
          </TextHeader>
        ),
        cell: (info) => (
          <TextCell size={'S'}>{info.getValue() as string}</TextCell>
        ),
      }),
      columnHelper.accessor(columnNames.pkInit.id, {
        header: () => (
          <TextHeader size={'XS'} colorText={'greyDark'}>
            {columnNames.pkInit.label}
          </TextHeader>
        ),
        cell: (info) => (
          <TextCell size={'S'}>{`${info.getValue()} km`}</TextCell>
        ),
      }),
      columnHelper.accessor(columnNames.pkEnd.id, {
        header: () => (
          <TextHeader size={'XS'} colorText={'greyDark'}>
            {columnNames.pkEnd.label}
          </TextHeader>
        ),
        cell: (info) => (
          <TextCell size={'S'}>{`${info.getValue()} km`}</TextCell>
        ),
      }),
      columnHelper.accessor(columnNames.description.id, {
        header: () => (
          <TextHeader size={'XS'} colorText={'greyDark'}>
            {columnNames.description.label}
          </TextHeader>
        ),
        cell: (info) => (
          <TextCell size={'S'}>{info.getValue() as string}</TextCell>
        ),
      }),
      columnHelper.accessor(columnNames.creationDate.id, {
        header: () => (
          <TextHeader size={'XS'} colorText={'greyDark'}>
            {columnNames.creationDate.label}
          </TextHeader>
        ),
        cell: (info) => (
          <TextCell size={'S'}>
            {format(new Date(info.getValue() as string), 'dd/MM/yyyy - HH:mm')}
          </TextCell>
        ),
      }),
    ],
    [isPortraitTablet]
  );

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  const filterFns = {
    coticFilter: coticFilterFunction, //I redeclare the custom function in filterFns so that it can be used
    dateFilter: dateFilterFunction,
  };

  const table = useReactTable({
    data: dataAll,
    columns,
    state: {
      rowSelection,
      columnFilters,
      pagination,
      columnVisibility,
      sorting,
    },
    filterFns,
    getSortedRowModel: getSortedRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    enableRowSelection: true, //enable row selection for all rows
    enableMultiRowSelection: false,
    onPaginationChange: setPagination,
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnFiltersChange: setColumnFilters,
    getPaginationRowModel: getPaginationRowModel(),
  });

  const capitolOptions = useCallback(() => {
    return data?.filtersDataOptions?.capitols.map((capitol) => ({
      label: capitol.name,
      value: capitol.id,
      subCapitolsIDs: capitol.subCapitolsIDs,
    }));
  }, [data?.filtersDataOptions?.capitols]);

  const onChangeInputCotic = useCallback<onChangeInputCoticTypes>(
    debounce(
      async (value) => {
        const coticId = value[3] === '' ? undefined : Number(value[3]);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let classification: any;
        if (coticId) {
          classification = await client.query({
            query: GET_CLASSIFICATION_BY_ID_OR_COTIC,
            variables: {
              coticId,
            },
          });
        }

        const indexType = value[1] === '' ? undefined : Number(value[1]);

        const indexExpedition = value[2] === '' ? undefined : Number(value[2]);
        const dataCursor = {
          take: selectorCursor.take,
          cursor: selectorCursor?.cursor,
          isMultimedia: true,
          indexType,
          indexExpedition,
          fromDate: selectorData?.fromDate ?? selectorCursor?.fromDate,
          toDate: selectorData?.toDate ?? selectorCursor?.toDate,
          classification: classification?.data?.classification?.id,
          orderBy: 'desc',
          roadId: selectorData?.roadId ?? selectorCursor?.roadId,
          capitolId: selectorData.capitolId ?? selectorCursor.capitolId,
          subCapitolId:
            selectorData.subCapitolId ?? selectorCursor.subCapitolId,
          state: selectorData.state ?? selectorCursor.state,
        };
        dispatch(setCursor(tableId as string, dataCursor));
        setSelectorData((prevData) => {
          const newData = prevData;
          newData.indexType = indexType;
          newData.indexExpedition = indexExpedition;
          return newData;
        });
      },
      1000,
      {
        leading: false,
        trailing: true,
      }
    ),
    [
      selectorCursor,
      selectorData.fromDate,
      selectorData.toDate,
      selectorData.capitolId,
      selectorData.subCapitolId,
      selectorData?.state,
      selectorData?.roadId,
    ]
  );

  /**
   * *Function for filtering date ranges
   * @param range is a range of dates ({from, to})
   */

  const onChangeDateInput = useCallback<onChangeInputType>(
    (range) => {
      if (range.to) {
        range.to.setHours(23);
        range.to.setMinutes(59);
        range.to.setSeconds(59);
        range.to.setMilliseconds(999);
      }
      const fromDateIso = formatISO(range.from as Date);
      const toDateIso = formatISO(range.to as Date);
      const dataCursor = {
        take: selectorCursor.take,
        cursor: selectorCursor.cursor,
        isMultimedia: true,
        indexType: selectorData.indexType ?? selectorCursor.indexType,
        indexExpedition:
          selectorData.indexExpedition ?? selectorCursor.indexExpedition,
        fromDate: fromDateIso,
        roadId: selectorData?.roadId ?? selectorCursor?.roadId,
        toDate: toDateIso,
        orderBy: 'desc',
        capitolId: selectorData?.capitolId ?? selectorCursor?.capitolId,
        subCapitolId:
          selectorData?.subCapitolId ?? selectorCursor?.subCapitolId,
        state: selectorData.state ?? selectorCursor.state,
      };
      dispatch(setCursor(tableId as string, dataCursor));
      setSelectorData((prevData) => {
        const newData = prevData;
        newData.fromDate = fromDateIso;
        newData.toDate = toDateIso;
        return newData;
      });
    },
    [
      selectorCursor,
      selectorData.indexType,
      selectorData.indexExpedition,
      selectorData.capitolId,
      selectorData.subCapitolId,
      selectorData?.state,
      selectorData?.roadId,
    ]
  );

  const onChangeSelectedCapitolGroup = useCallback(
    async (option: option) => {
      const {
        data: { readSubCapitols },
      } = await client.query({
        query: GET_SUB_CAPITOLS_BY_IDS,
        variables: {
          ids: option.subCapitolsIDs,
        },
        fetchPolicy: 'cache-first',
      });
      const sortedSubCapitols = [...readSubCapitols].sort((a, b) =>
        a.name.localeCompare(b.name)
      );
      setSubCapitolOptions(
        sortedSubCapitols?.map((road: { id: string; name: string }) => ({
          value: road.id,
          label: road.name,
        }))
      );
      const dataCursor = {
        take: selectorCursor.take,
        cursor: selectorCursor?.cursor,
        indexType: selectorData?.indexType ?? selectorCursor?.indexType,
        indexExpedition:
          selectorData?.indexExpedition ?? selectorCursor?.indexExpedition,
        capitolId: option.value,
        subCapitolId: undefined,
        fromDate: selectorData?.fromDate ?? selectorCursor?.fromDate,
        toDate: selectorData?.toDate ?? selectorCursor?.toDate,
        roadId: selectorData?.roadId ?? selectorCursor?.roadId,
        orderBy: 'desc',
        state: selectorData.state ?? selectorCursor.state,
      };
      dispatch(setCursor(tableId as string, dataCursor));
      setSelectorData((prevData) => {
        const newData = prevData;
        newData.capitolId = option.value;
        newData.subCapitolId = undefined;
        return newData;
      });

      inputListSubcapitolRef.current?.clearSelected();
    },
    [
      selectorCursor,
      selectorData?.indexType,
      selectorData?.indexExpedition,
      selectorData?.fromDate,
      selectorData?.toDate,
      selectorData?.capitolId,
      selectorData?.subCapitolId,
      selectorData?.state,
      selectorData?.roadId,
    ]
  );

  const onChangeSelectedSubcapitolGroup = useCallback(
    (option: option) => {
      const dataCursor = {
        take: selectorCursor.take,
        cursor: selectorCursor?.cursor,
        indexType: selectorData?.indexType ?? selectorCursor?.indexType,
        indexExpedition:
          selectorData?.indexExpedition ?? selectorCursor?.indexExpedition,
        subCapitolId: option.value,
        capitolId: selectorData?.capitolId ?? selectorCursor?.capitolId,
        fromDate: selectorData?.fromDate ?? selectorCursor?.fromDate,
        toDate: selectorData?.toDate ?? selectorCursor?.toDate,
        state: selectorData?.state ?? selectorCursor.state,
        roadId: selectorData?.roadId ?? selectorCursor?.roadId,
        orderBy: 'desc',
      };
      dispatch(setCursor(tableId as string, dataCursor));
      setSelectorData((prevData) => {
        const newData = prevData;
        newData.subCapitolId = option.value;
        return newData;
      });
    },
    [
      selectorCursor,
      selectorData?.indexType,
      selectorData?.indexExpedition,
      selectorData?.capitolId,
      selectorData?.fromDate,
      selectorData?.toDate,
      selectorData?.state,
    ]
  );

  const onChangeSelectedState = useCallback(
    (option: option) => {
      const dataCursor = {
        take: selectorCursor.take,
        cursor: selectorCursor?.cursor,
        indexType: selectorData?.indexType ?? selectorCursor?.indexType,
        indexExpedition:
          selectorData?.indexExpedition ?? selectorCursor?.indexExpedition,
        subCapitolId:
          selectorData?.subCapitolId ?? selectorCursor?.subCapitolId,
        capitolId: selectorData?.capitolId ?? selectorCursor?.capitolId,
        fromDate: selectorData?.fromDate ?? selectorCursor?.fromDate,
        toDate: selectorData?.toDate ?? selectorCursor?.toDate,
        orderBy: 'desc',
        state: option.value,
      };
      dispatch(setCursor(tableId as string, dataCursor));
      setSelectorData((prevData) => {
        const newData = prevData;
        newData.state = option.value;
        return newData;
      });
    },
    [
      selectorCursor,
      selectorData?.indexType,
      selectorData?.indexExpedition,
      selectorData?.capitolId,
      selectorData?.subCapitolId,
      selectorData?.fromDate,
      selectorData?.toDate,
      selectorData?.roadId,
    ]
  );

  const onClearValue = useCallback(() => {
    inputDateRef.current?.clearSelected();
    inputCoticRef.current?.clearSelected();
    inputListCapitolRef.current?.clearSelected();
    inputListSubcapitolRef.current?.clearSelected();
    inputStateRef.current?.clearSelected();
    inputRoadRef.current?.clearSelected();
    setColumnFilters([]);
    const dataCursor = {
      take: takeBase,
      cursor: '',
      isMultimedia: true,
      indexType: undefined,
      indexExpedition: undefined,
      fromDate: undefined,
      toDate: undefined,
      orderBy: 'desc',
      capitolId: undefined,
      roadId: undefined,
      subCapitolId: undefined,
      state: undefined,
    };
    dispatch(setCursor(tableId as string, dataCursor));
    setSelectorCursor((prevData) => {
      const newData = prevData;
      newData.indexType = undefined;
      newData.indexExpedition = undefined;
      newData.fromDate = undefined;
      newData.toDate = undefined;
      newData.capitolId = undefined;
      newData.subCapitolId = undefined;
      newData.state = undefined;
      newData.roadId = undefined;
      return newData;
    });
    setSelectorData((prevData) => {
      const newData = prevData;
      newData.indexType = undefined;
      newData.indexExpedition = undefined;
      newData.fromDate = undefined;
      newData.toDate = undefined;
      newData.capitolId = undefined;
      newData.subCapitolId = undefined;
      newData.state = undefined;
      newData.roadId = undefined;
      return newData;
    });
    onReFetchPagination();
  }, [selectorData]);

  const handleClickRow = (row: Row<dataTable>) => {
    const val: boolean = row.getIsSelected();
    if (!val) {
      setRowState(row.original.id || '');
    } else {
      setRowState('');
    }
  };

  const hiddenColumns: number = Object.values(
    columnVisibility as object
  ).reduce((count, currentValue) => (!currentValue ? count + 1 : count), 0);

  const commonColumns = Object.values(columnNames).filter((column) =>
    table
      .getAllColumns()
      .map((column) => column.id)
      .includes(column.id)
  );

  const commonLabels = commonColumns.map((column) => column.label);

  const fetchOrder = useCallback(
    async (id: string, take = takeBase) => {
      const dataSelector = {
        take,
        isMultimedia: true,
        cursor: id,
        indexType: selectorData?.indexType ?? selectorCursor?.indexType,
        indexExpedition:
          selectorData?.indexExpedition ?? selectorCursor?.indexExpedition,
        fromDate: selectorData?.fromDate ?? selectorCursor?.fromDate,
        toDate: selectorData?.toDate ?? selectorCursor?.toDate,
        orderBy: 'desc',
        state: selectorData.state ?? selectorCursor.state,
        roadId: selectorData?.roadId ?? selectorCursor?.roadId,
      };

      if (take > 0) {
        const lastOrder = dataAll[dataAll.length - 1].id;
        if (lastOrder) {
          dispatch(setCursor(tableId as string, dataSelector));
        }
      } else {
        const firstOrder = dataAll[0].id;

        if (firstOrder) {
          dispatch(setCursor(tableId as string, dataSelector));
        }
      }

      return dataSelector;
    },
    [
      selectorCursor,
      selectorData?.indexType,
      selectorData?.indexExpedition,
      selectorData?.fromDate,
      selectorData?.toDate,
      selectorData?.capitolId,
      selectorData?.subCapitolId,
      selectorData?.state,
      selectorData?.roadId,
      dataAll,
    ]
  );

  const onChangeSelectedRoad = useCallback(
    (option: option) => {
      const dataCursor = {
        take: selectorCursor.take,
        cursor: selectorCursor?.cursor,
        indexType: selectorData?.indexType ?? selectorCursor?.indexType,
        indexExpedition:
          selectorData?.indexExpedition ?? selectorCursor?.indexExpedition,
        subCapitolId:
          selectorData?.subCapitolId ?? selectorCursor?.subCapitolId,
        capitolId: selectorData?.capitolId ?? selectorCursor?.capitolId,
        fromDate: selectorData?.fromDate ?? selectorCursor?.fromDate,
        toDate: selectorData?.toDate ?? selectorCursor?.toDate,
        orderBy: 'desc',
        roadId: option.value,
      };
      dispatch(setCursor(tableId, dataCursor));
      setSelectorData((prevData) => {
        const newData = prevData;
        newData.roadId = option.value;
        return newData;
      });
    },
    [
      selectorCursor,
      selectorData?.indexType,
      selectorData?.indexExpedition,
      selectorData?.fromDate,
      selectorData?.toDate,
      selectorData?.capitolId,
      selectorData?.subCapitolId,
      selectorData?.roadId,
      dataAll,
    ]
  );

  const nextPagination = useCallback(async () => {
    if (dataAll.length >= 1 && data?.pageInfo.hasNextPage) {
      await fetchOrder(dataAll[dataAll.length - 1].id as string);
      onReFetchPagination();
    }
  }, [dataAll, data]);

  const backPagination = useCallback(async () => {
    if (dataAll.length >= 1 && data?.pageInfo.hasPreviousPage) {
      await fetchOrder(dataAll[0].id as string, -takeBase);
      onReFetchPagination();
    }
  }, [data, dataAll]);

  const firstPage = useCallback(() => {
    const dataCursor = {
      take: takeBase,
      cursor: '',
      isMultimedia: true,
      indexType: selectorData.indexType ?? selectorCursor.indexType,
      indexExpedition:
        selectorData.indexExpedition ?? selectorCursor.indexExpedition,
      fromDate: selectorData.fromDate ?? selectorCursor.fromDate,
      toDate: selectorData.toDate ?? selectorCursor.toDate,
      state: selectorData.state ?? selectorCursor.state,
      roadId: selectorData?.roadId ?? selectorCursor?.roadId,
    };

    dispatch(setCursor(tableId as string, dataCursor));
    if (data?.pageInfo.hasPreviousPage) {
      onReFetchPagination();
    }
  }, [
    data,
    selectorData.indexType,
    selectorData.indexExpedition,
    selectorData.fromDate,
    selectorData.toDate,
    selectorData.state,
    selectorData.roadId,
    selectorCursor,
  ]);

  const lastPage = useCallback(async () => {
    const take =
      (data?.totalCount as number) % takeBase !== 0
        ? (data?.totalCount as number) % takeBase
        : takeBase;
    const dataCursor = {
      take: take,
      cursor: data?.pageInfo.lastCursor,
      isMultimedia: true,
      indexType: selectorData.indexType ?? selectorCursor.indexType,
      indexExpedition:
        selectorData.indexExpedition ?? selectorCursor.indexExpedition,
      fromDate: selectorData.fromDate ?? selectorCursor.fromDate,
      toDate: selectorData.toDate ?? selectorCursor.toDate,
      state: selectorData.state ?? selectorCursor.state,
      roadId: selectorData?.roadId ?? selectorCursor?.roadId,
    };

    dispatch(setCursor(tableId as string, dataCursor));
    dispatch(setTake(takeBase));
    if (data?.pageInfo.hasNextPage && take > 0) {
      onReFetchPagination();
    }
  }, [
    data,
    selectorCursor,
    selectorData.indexType,
    selectorData.indexExpedition,
    selectorData.fromDate,
    selectorData.toDate,
    selectorData.state,
    selectorData.roadId,
  ]);

  const handleSearch = async () => {
    const dataCursor = {
      take: selectorCursor.take,
      cursor: selectorCursor?.cursor,
      indexType: selectorData?.indexType ?? selectorCursor?.indexType,
      indexExpedition:
        selectorData?.indexExpedition ?? selectorCursor?.indexExpedition,
      subCapitolId: selectorData?.subCapitolId ?? selectorCursor?.subCapitolId,
      capitolId: selectorData?.capitolId ?? selectorCursor?.capitolId,
      fromDate: selectorData?.fromDate ?? selectorCursor?.fromDate,
      toDate: selectorData?.toDate ?? selectorCursor?.toDate,
      orderBy: 'desc',
      state: selectorData.state ?? selectorCursor.state,
      roadId: selectorData?.roadId ?? selectorCursor?.roadId,
    };
    dispatch(setCursor(tableId as string, dataCursor));

    onReFetchPagination?.();
  };

  return (
    <ContainerStyled>
      {isLoadingData && (
        <ContainerLoader>
          <Loader />
        </ContainerLoader>
      )}
      <FiltersWithLoadingStyled isLoading={isLoadingData}>
        <SubContainerHead>
          <Title component={'h2'} variant={'semiBold'} size={'M'}>
            {titleText}
          </Title>
          {isLanscapeTablet && (
            <ShowFiltersButton isBlack={showFilters} onClick={handleClick}>
              <ArrowBottomStyled isRotate={showFilters} />
            </ShowFiltersButton>
          )}
        </SubContainerHead>
        {showFilters && (
          <SubContainerFiltersStyled>
            <InputCotic
              label="COTIC"
              disabled={isLoadingData}
              onChangeInputCotic={onChangeInputCotic}
              ref={inputCoticRef}
              cotic={coticDefault}
            />
            <InputCalendarStyled
              ref={inputDateRef}
              label={'Dates filtre entre:'}
              name={'data'}
              onChangeInput={onChangeDateInput}
              dateRange={
                selectorCursor?.fromDate && selectorCursor?.toDate
                  ? {
                      from: parseISO(selectorCursor?.fromDate),
                      to: parseISO(selectorCursor?.toDate),
                    }
                  : undefined
              }
              disabled={isLoadingData}
            />

            <DropDownInputStyledBig
              inputSize="L"
              ref={inputListCapitolRef}
              labelText={'Capitol'}
              placeholder={'Selecciona'}
              typeDropDown={'Default'}
              defaultValue={selectorCursor?.capitolId ?? ''}
              options={capitolOptions() as Options}
              onChangeSelected={onChangeSelectedCapitolGroup as onChangeValue}
              isDisabled={isLoadingData}
            />

            <DropDownInputStyledBig
              inputSize="L"
              ref={inputListSubcapitolRef}
              labelText={'Subcapitol'}
              placeholder={'Selecciona'}
              typeDropDown={'Default'}
              defaultValue={selectorCursor?.subCapitolId ?? ''}
              options={subCapitolOptions as Options}
              onChangeSelected={
                onChangeSelectedSubcapitolGroup as onChangeValue
              }
              isDisabled={isLoadingData}
            />

            <DropDownInput
              inputSize="S"
              ref={inputStateRef}
              labelText={'Estat'}
              placeholder={'Selecciona'}
              typeDropDown={'Secondary'}
              defaultValue={selectorCursor?.state ?? ''}
              options={stateOptions}
              onChangeSelected={onChangeSelectedState as onChangeValue}
              isDisabled={isLoadingData}
            />

            <DropDownInputStyledBig
              inputSize="XS"
              ref={inputRoadRef}
              labelText={'Carretera'}
              placeholder={'Selecciona'}
              typeDropDown={'Secondary'}
              defaultValue={selectorCursor?.roadId ?? ''}
              options={roadOptions as Options}
              onChangeSelected={onChangeSelectedRoad as onChangeValue}
              isDisabled={isLoadingData}
            />

            <ContainerButtonStyled>
              <ButtonIconBackground
                variant={'delete'}
                onClick={onClearValue}
                disabled={isLoadingData}
              />
              <DropDownVisibilityColumn
                columnNames={commonLabels}
                columns={table.getAllColumns().slice(1)}
                isDisabled={isLoadingData}
              />
              <ButtonIconBackground
                variant={'restart'}
                onClick={onReFetchData}
                disabled={isLoadingData}
              />
              <ButtonIconBackground
                variant={'search'}
                onClick={handleSearch}
                disabled={isLoadingData}
              />
            </ContainerButtonStyled>
          </SubContainerFiltersStyled>
        )}
      </FiltersWithLoadingStyled>
      <TableContainerStyled isLoading={isLoadingData}>
        <Table {...props}>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <Th key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </Th>
                ))}
              </tr>
            ))}
          </thead>
          <Tbody>
            {table.getRowModel().rows.map((row) => {
              return (
                <TrStyled
                  checked={row.getIsSelected()}
                  indeterminate={!table.getIsSomeRowsSelected()}
                  key={row.id}
                  onClick={() => {
                    !isLoadingData && handleClickRow(row);
                    !isLoadingData && row.getToggleSelectedHandler()(row);
                  }}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TdStyled
                      column={cell.column.id as columnID}
                      hiddenColumns={hiddenColumns}
                      key={cell.id}
                    >
                      <>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </>
                    </TdStyled>
                  ))}
                </TrStyled>
              );
            })}
          </Tbody>
        </Table>
      </TableContainerStyled>
      <PaginationButtons
        pageCount={2}
        currentPage={0}
        totalCount={data?.totalCount}
        nextPage={nextPagination}
        previousPage={backPagination}
        firstPage={firstPage}
        lastPage={lastPage}
        isDisabled={isLoadingData}
      />
    </ContainerStyled>
  );
};

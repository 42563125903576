import { combineReducers } from 'redux';

import modal from './ui/modal';

import selectedDataTableModal from './ui/selectedDataModal';

import tabs from './ui/tabs';

import tabsVertical from './ui/tabsVertical';

import coticOTSelected from './ui/coticOTSelectedGeneralView';

import actionTabs from './ui/actionTabs';

import cursorTables from './ui/cursorTables';

import takeTables from './ui/takeTables';

import loadingData from './ui/loadingData';

export default combineReducers({
  modal,
  selectedDataTableModal,
  tabs,
  tabsVertical,
  coticOTSelected,
  actionTabs,
  cursorTables,
  takeTables,
  loadingData,
});

import { object, string } from 'yup';

// Types
import { MachineryManagement } from './types';

export const MachineryManagementSchema = object<MachineryManagement>({
  name: string().required(),
  type: string().required(),
  typeOfMeasurement: string().required(),
});

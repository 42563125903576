import { gql } from '@apollo/client';

export const FETCH_ACTIONS = gql`
  query FetchActions($input: FilterActionList!) {
    actions(input: $input) {
      actions {
        id
        typeOfHour
        minutesTotals
        isValidate
        concessionIDs
        roadIDs
        teamIDs
        pkInitKm
        pkInitMeter
        pkEndKm
        pkEndMeter
        track
        direction
        margin
        lane
        laneCutting
        assetSwitch
        userIDs
        geoPositionActionIDs
        vehicleOccupantIDs
        activityIDs
        assetIDs
        materialUnitIDs
        auxMachineUnitIDs
        animalUnitIDs
        orderIDs
        # externalInitDate
        # externalEndDate
        # externalTypeOfHour
        # externalMinutesTotals
        externalWorksIDs
      }
      totalCount
      pageInfo {
        lastCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

export const GET_ACTION_BY_ID = gql`
  query getActionById($id: ID!) {
    action(id: $id) {
      id
      typeOfHour
      minutesTotals
      isValidate
      concessionIDs
      roadIDs
      teamIDs
      pkInitKm
      pkInitMeter
      pkEndKm
      pkEndMeter
      track
      direction
      margin
      lane
      laneCutting
      assetSwitch
      userIDs
      geoPositionActionIDs
      vehicleOccupantIDs
      activityIDs
      assetIDs
      materialUnitIDs
      auxMachineUnitIDs
      animalUnitIDs
      orderIDs
      descriptionValidated
      externalWorksIDs
    }
  }
`;

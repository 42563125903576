// Types
import {
  DataFromServerType,
  roadsType,
  subCapitolsType,
} from '../components/display/ShowOT/WorkOrder/types';

type state = 'open' | 'partial' | 'end' | 'validated' | 'annulled';

type capitol = 'Accident' | 'Entorns de carretera' | 'Altres';

type subCapitol = 'Animals' | 'Senyalització';

export interface Order {
  orderIDs?: string;
  id?: string;
  cotic?: string[];
  name?: string;
  specialTag?: string;
  date?: string | number;
  hour?: string | number;
  creationDate?: string | number;
  registerInitDate?: string | number;
  registerEndDate?: string | number;
  groupInter?: string;
  media?: string;
  sendStatus?: string;
  concession?: string;
  pkInit?: string;
  pkEnd?: string;
  description?: string;
  road?: string;
  capitol?: capitol;
  subCapitol?: subCapitol;
  state?: state;
  type?: string;
  capitols?: DataFromServerType[];
  subCapitolsValues?: subCapitolsType[];
  concessions?: DataFromServerType[];
  roadsValues?: roadsType[];
  communicationID?: string;
  genericOrCampaignOrderID?: string;
  actionID?: string;
}

export enum OrderType {
  Generic = 'generic',
  Communication = 'communication',
  SubOrder = 'subOrder',
  Action = 'action',
  Accident = 'accident',
  SurveillanceRoute = 'surveillanceRoute',
  Campaign = 'campaign',
  Multimedia = 'multimedia',
}

export enum OrderTypeCotic {
  Communication = '1O',
  Generic = '2O',
  Action = '3O',
  Accident = '4O',
  SurveillanceRoute = '0R',
  Campaign = '0C',
  Multimedia = 'multimedia',
}

export enum OrderState {
  Open = 'open',
  Partial = 'partial',
  Validated = 'validated',
  Annulled = 'annulled',
  End = 'end',
}

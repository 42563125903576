import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

// Types
import { AnimalManagement, Props } from './types';

// Schema
import { AnimalManagementSchema } from './schema';

// Styles
import { Container, InputsContainer, AddAnimalButton } from './styles';

// Components
import InputText from '../../inputs/InputText';

// Utils
import { useCacheFormInSessionStorage } from '../../../hooks/useCacheForm';
import { Typography } from '../../display/Typography';

export const AddAnimalManagementForm = ({
  onFormSubmit,
  namingPersistForm,
  initialData,
}: Props) => {
  const {
    handleSubmit,
    control,
    register,
    formState: { errors },
    reset,
  } = useForm<AnimalManagement>({
    defaultValues: {
      ...initialData,
    },
    resolver: yupResolver(AnimalManagementSchema),
  });

  useCacheFormInSessionStorage(namingPersistForm, control);

  const [animalErrorMessage, setAnimalErrorMessage] = useState<string | null>(
    null
  );

  const onSubmit = async (data: AnimalManagement) => {
    const errorMessage = await onFormSubmit(data);
    if (errorMessage) {
      setAnimalErrorMessage(errorMessage);
    } else {
      reset({
        name: '',
      });
      setAnimalErrorMessage(null);
    }
  };

  return (
    <Container onSubmit={handleSubmit(onSubmit)}>
      <Typography size="L" variant="semiBold">
        Afegir animal
      </Typography>

      <InputsContainer>
        <InputText
          {...register('name')}
          label="Nom"
          typeInput={'normal'}
          inputSize="16"
          placeholder="Nom"
          borderError={!!errors.name?.message}
        />
        <AddAnimalButton>⮕</AddAnimalButton>
        {animalErrorMessage && (
          <Typography colorText="lightCoralRed" size="XS">
            {animalErrorMessage}
          </Typography>
        )}
      </InputsContainer>
    </Container>
  );
};

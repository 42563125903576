import styled from 'styled-components';
import { AdvancedImage } from '@cloudinary/react';

// Styles
import { colors } from '../../../styles/colors';
import { spaces } from '../../../styles/spaces';
import { fontSize } from '../../../styles/fonts';

// Components
import { Typography } from '../../display/Typography';
import { Definition } from '../../display/Definition';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 0.4rem;
  cursor: pointer;
  position: relative;
  box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.15);
`;

export const CloseIcon = styled.button`
  display: none;
  position: absolute;
  background: transparent;
  top: 2rem;
  right: 2rem;
  border: 0;
  padding: 0;
  z-index: 999;

  img {
    width: 2.5rem;
    height: 2.5rem;
  }
`;

export const CloudinaryAdvanceImage = styled(AdvancedImage)`
  width: 100%;
  height: 20rem;
  object-fit: cover;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spaces.xxs}rem;
  padding: ${spaces.xxs}rem ${spaces.xs}rem;
  background-color: ${colors.verySoftRed};
`;

export const Text = styled(Typography)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ContainerInfo = styled.div`
  display: none;
`;

export const StyledDefinition = styled(Definition)`
  & * {
    font-size: ${fontSize.XS};
  }
`;

import styled from 'styled-components';

// Components
import { Typography } from '../../../display/Typography';
import { spaces } from '../../../../styles/spaces';
import { MainButton } from '../../../inputs/buttons/MainButton';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3rem;
  padding: 3.5rem 6.35rem;
`;

export const ContainerForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3rem;
  padding: 3.5rem 6.35rem;
`;

export const ContainerText = styled.div``;

export const Text = styled(Typography)`
  text-align: center;
`;

export const Title = styled(Text)`
  font-size: 2.4rem;
  margin-bottom: ${spaces.xxs}rem;
`;

export const ContainerInputSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${spaces.xxxs}rem;
`;

export const MainButtonStyled = styled(MainButton)`
  width: 13.4rem;
  justify-content: center;
`;

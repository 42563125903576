import React, { useCallback } from 'react';

// Types
import { InputNumberProps, onChangeType } from './types';

// Components Styled
import { InputContainer, InputValue } from './styles';

/**
 * Primary UI component for user interaction
 */
export const InputNumber = ({
  maxLength = 2,
  className,
  ...props
}: InputNumberProps): JSX.Element => {
  const max = '9';
  const onChange = useCallback<onChangeType>((e) => {
    const input = e.target;
    if (input.value.length > input.maxLength) {
      input.value = input.value.slice(0, input.maxLength);
    }
  }, []);
  return (
    <InputContainer
      className={className}
      disabled={props.disabled}
      maxLength={maxLength}
    >
      <InputValue
        type={'number'}
        min={0}
        max={max.repeat(maxLength)}
        maxLength={maxLength}
        onChange={onChange}
        {...props}
      />
    </InputContainer>
  );
};

import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

// Utils
import { useCacheFormInSessionStorage } from '../../../hooks/useCacheForm';

// Types
import { Props } from './types';
import { Accident } from '../../../types/accident';

// Components
import { BasicInfo } from './BasicInfo';
import { StateOfTheRoad } from './StateOfTheRoad';

// Styles
import {
  ContainerContent,
  SubContainerLeft,
  SubContainerRight,
  StyledPagination,
} from './styles';
import { AccidentAttention } from './AccidentAttention';
import { ButtonsContainer, ContainerForm } from '../components/MainStyled';

// Schema
import { AccidentSchema } from './schema';
import { useIsPortraitTablet } from '../../../hooks/useMediaBreakPoints';
import { MainButton } from '../../inputs/buttons/MainButton';

const AddBasicInfoAccidentForm = ({
  onFormSubmit,
  initialData,
  namingPersistForm,
  onReturn,
}: Props) => {
  const [isAReportState, setIsAReportState] = useState<boolean>(
    initialData?.policeReport as boolean
  );
  const [isLoadingFiles, setIsLoadingFiles] = useState<boolean>(false);

  const {
    handleSubmit,
    register,
    control,
    formState: { errors, defaultValues },
    setValue,
  } = useForm<Accident>({
    defaultValues: { ...initialData },
    resolver: yupResolver(AccidentSchema),
    context: { isAReportState },
  });

  const isPortraitTablet = useIsPortraitTablet();

  useCacheFormInSessionStorage<Accident>(namingPersistForm, control);
  return (
    <ContainerForm onSubmit={handleSubmit(onFormSubmit)}>
      <ContainerContent>
        <SubContainerLeft>
          <BasicInfo
            control={control}
            register={register}
            errors={errors}
            defaultValues={defaultValues}
            setValue={setValue}
            isAReportState={isAReportState}
            setIsAReportState={setIsAReportState}
          />
          <StateOfTheRoad
            control={control}
            register={register}
            errors={errors}
          />
        </SubContainerLeft>
        <SubContainerRight>
          <AccidentAttention
            control={control}
            setValue={setValue}
            errors={errors}
            defaultValues={defaultValues}
            accidentDate={initialData?.registerInitDate as string}
            setIsLoadingFiles={setIsLoadingFiles}
            isLoadingFiles={isLoadingFiles}
          />
        </SubContainerRight>
        {isPortraitTablet ? null : (
          <StyledPagination colorText="greySoftLight">2/3</StyledPagination>
        )}
      </ContainerContent>

      <ButtonsContainer>
        <MainButton
          text="Tornar"
          type="button"
          variant="secondary"
          onClick={onReturn}
          disabled={isLoadingFiles}
        />
        <MainButton text="Continuar" type="submit" disabled={isLoadingFiles} />
      </ButtonsContainer>
    </ContainerForm>
  );
};

export default AddBasicInfoAccidentForm;

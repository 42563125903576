export type columnID =
  | 'cotic'
  | 'date'
  | 'hour'
  | 'groupInter'
  | 'media'
  | 'sendStatus'
  | 'concession'
  | 'pkInit'
  | 'pkEnd'
  | 'description'
  | 'road'
  | 'capitol'
  | 'subCapitol'
  | 'state'
  | 'select'
  | 'name'
  | 'weather'
  | 'mediumKnowledge';

export type cellProps = {
  hiddenColumns: number;
  isFigure?: boolean;
  column: columnID;
};

export type trProps = {
  checked: boolean;
  indeterminate?: boolean;
};

type columnIDTypes = { [key: string]: { label: string; id: string } };

export const columnNames: columnIDTypes = {
  state: {
    label: 'Estat',
    id: 'state',
  },
  cotic: {
    label: 'COTIC',
    id: 'cotic',
  },
  type: {
    label: 'Tipo',
    id: 'type',
  },
  registerInitDate: {
    label: `Data de registre`,
    id: 'registerInitDate',
  },
  creationDate: {
    label: 'Data del sistema',
    id: 'creationDate',
  },
  name: {
    label: 'Nom de la campanya',
    id: 'name',
  },
  capitol: {
    label: 'Capitol',
    id: 'capitol',
  },
  subCapitol: {
    label: 'Subcapitol',
    id: 'subCapitol',
  },
  groupInter: {
    label: 'Grup Inter.',
    id: 'groupInter',
  },
  media: {
    label: 'Media',
    id: 'media',
  },
  sendStatus: {
    label: 'Env/Reb',
    id: 'sendStatus',
  },
  mediumKnowledge: {
    label: 'Medi',
    id: 'mediumKnowledge',
  },
  weather: {
    label: 'Temps',
    id: 'weather',
  },
  concession: {
    label: 'CON.',
    id: 'concession',
  },
  road: {
    label: 'Crt.',
    id: 'road',
  },
  pkInit: {
    label: 'Pk inicial',
    id: 'pkInit',
  },
  pkEnd: {
    label: 'Pk final',
    id: 'pkEnd',
  },
  description: {
    label: 'Descripció',
    id: 'description',
  },
};

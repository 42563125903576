import { defer, LoaderFunction } from 'react-router-dom';

// Sentry
import * as Sentry from '@sentry/react';

// GraphQL
import { selectOrderWithActionBasicInfoById } from '../../../../../gql/selectors/actions';

// Utils
import { decompressSessionStorage } from '../../../../../utils/sessionStorage';

// Constants
import { formActionNaming } from '../../../../../components/forms/ActionForms/constants';

// GQL
import { client } from '../../../../../gql/client';
import { GET_ACTION_BY_ID } from '../../../../../gql/queries/actions/actions';

const loadActionDetails = async (actionId: string) => {
  try {
    const {
      data: { action },
    } = await client.query({
      query: GET_ACTION_BY_ID,
      variables: {
        id: actionId,
      },
    });
    return await selectOrderWithActionBasicInfoById(
      action.orderIDs[0],
      actionId
    );
  } catch (err) {
    Sentry.captureException(err);
    console.log('> Action order loading error:', err);
    return false;
  }
};

export const LoaderActionValidationPageOrder: LoaderFunction = async () => {
  const stringifyForm = sessionStorage.getItem(
    formActionNaming.NEW_VALIDATION_ACTION_DATA
  );
  if (stringifyForm) {
    const sessionStorageData = JSON.parse(
      decompressSessionStorage(stringifyForm)
    );
    const data = await loadActionDetails(sessionStorageData.id);
    return defer({ data });
  }

  return defer({ data: {} });
};

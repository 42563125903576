export const operatorsMockData = [
  {
    id: '1',
    name: 'Esther',
    surName: 'Alfreda Pol',
    concession: 'LL',
    isActive: 'true',
  },
  {
    id: '2',
    name: 'Carles ',
    surName: 'Juménez Ruiz',
    concession: 'LL',
    isActive: 'true',
  },
  {
    id: '3',
    name: 'Iván ',
    surName: 'Sánchez Gil',
    concession: 'LL',
    isActive: 'false',
  },
  {
    id: '4',
    name: 'Carlos',
    surName: 'Puig Alcalá',
    concession: 'LL',
    isActive: 'false',
  },
];

export const vehiclesMockData = [
  {
    id: '1',
    plate: 'M102KLA',
    driverID: '',
    concession: '',
  },
  {
    id: '2',
    plate: 'l10239A',
    driverID: '',
    concession: '',
  },
  {
    id: '3',
    plate: '9A0SD92',
    driverID: '',
    concession: '',
  },
  {
    id: '4',
    plate: '0291034',
    driverID: '',
    concession: '',
  },
];

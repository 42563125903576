import styled from 'styled-components';

import { colors } from '../../../../styles/colors';

import { spaces } from '../../../../styles/spaces';

import { ArrowContainerProps } from './types';

import { DirectionUp } from '../../../../assets/icons/DirectionUp';
import { DirectionDown } from '../../../../assets/icons/DirectionDown';
import { DirectionBoth } from '../../../../assets/icons/DirectionBoth';
import { DirectionLeft } from '../../../../assets/icons/DirectionLeft';
import { DirectionRight } from '../../../../assets/icons/DirectionRight';
import { Typography } from '../../../display/Typography';
import { DirectionBothVertical } from '../../../../assets/icons/DirectionBothVertical';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  //gap: ${spaces.xxxs}rem;
`;

export const SecondaryContainer = styled.div`
  display: flex;
`;

export const ArrowLabel = styled.label`
  display: flex;
  flex-direction: column;
  gap: ${spaces.xxxs}rem;
`;

export const ArrowContainer = styled.div<ArrowContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0.1rem solid
    ${({ borderError }) => (borderError ? colors.lightCoralRed : colors.black)};
  cursor: pointer;
  border-radius: 0.5rem;
  width: 5.8rem;
  height: 4rem;
  background-color: ${colors.white};
  ${(props) =>
    props.separated &&
    !props.lastButton && {
      marginRight: '2.5rem',
    }}
  ${(props) =>
    props.firstButton &&
    !props.separated && {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      borderBottomLeftRadius: 0,
    }}
     ${(props) =>
    props.lastButton &&
    !props.separated && {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    }}
     ${(props) =>
    !props.firstButton &&
    !props.lastButton &&
    !props.separated && {
      borderRadius: 0,
      borderLeftWidth: 0,
      borderRightWidth: 0,
    }}
  ${(props) =>
    props.firstButton &&
    !props.separated &&
    props.hasUnspecific && {
      borderBottomLeftRadius: 0,
    }}
  ${(props) =>
    props.lastButton &&
    !props.separated &&
    props.hasUnspecific && {
      borderBottomRightRadius: 0,
    }}
    ${(props) =>
    props.selectedArrow && {
      backgroundColor: colors.black,
    }}
    :focus {
    outline: 0.1rem solid ${colors.black};
  }
`;

export const ArrowContainerCustom = styled(ArrowContainer)`
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  width: 100%;
  text-align: center;
`;

export const Input = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
`;

export const DirectionUpIcon = styled(DirectionUp)`
  input[type='radio']:checked + & {
    fill: ${colors.white};
  }
`;

export const DirectionDownIcon = styled(DirectionDown)`
  input[type='radio']:checked + & {
    fill: ${colors.white};
  }
`;

export const Text = styled(Typography)`
  width: 100%;
  font-size: 1.4rem;

  input[type='radio']:checked + & {
    color: white;
  }
`;

export const DirectionRightIcon = styled(DirectionRight)`
  input[type='radio']:checked + & {
    fill: ${colors.white};
  }
`;

export const DirectionLeftIcon = styled(DirectionLeft)`
  input[type='radio']:checked + & {
    fill: ${colors.white};
  }
`;

export const DirectionBothIcon = styled(DirectionBoth)`
  input[type='radio']:checked + & {
    fill: ${colors.white};
  }
`;

export const DirectionBothVerticalIcon = styled(DirectionBothVertical)`
  input[type='radio']:checked + & {
    fill: ${colors.white};
  }
`;

export const TextLabel = styled(Typography)``;

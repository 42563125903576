import React, { useCallback, useMemo, useState, useLayoutEffect } from 'react';
import {
  createColumnHelper,
  useReactTable,
  getCoreRowModel,
  flexRender,
} from '@tanstack/react-table';

// Components

// Icons
import EditIcon from '../../../../../assets/icons/edit-icon.svg';
import RemoveIcon from '../../../../../assets/icons/remove-icon.svg';
import DisplayIcon from '../../../../../assets/icons/arrow-bottom-grey.svg';
import RetraitIcon from '../../../../../assets/icons/arrow-right-icon.svg';
import CheckIcon from '../../../../../assets/icons/check-grey-icon.svg';

// Styled Components
import {
  Container,
  Table,
  Th,
  Td,
  Tr,
  Tbody,
  Thead,
  TextHeader,
  Cell,
  TextCell,
  InputCell,
  TheadTrailer,
  ContainerTrailerHeader,
  Icon,
  IconContainer,
  IconsContainer,
  HeaderVehiclesContainer,
  ThVehicles,
  TrBody,
  StyledDoubleSwitch,
  DamagesTextArea,
  TdTextArea,
  DamagesCell,
} from './styles';

// Types
import { tableProps, dataTable, AccidentInput } from './types';

// Hooks
import {
  useIsLandscapeTablet,
  useIsPortraitTablet,
} from '../../../../../hooks/useMediaBreakPoints';

// Utils
import { decompressSessionStorage } from '../../../../../utils/sessionStorage';

// Constants
import { formVehicleNaming } from '../../../../forms/AddVehicleForm/constants';

const generateInputName = (input: AccidentInput, letter: string) => {
  return `vehicles[${letter}].${input}` as AccidentInput;
};

export const VehiclesInvolvedTable = ({
  letter,
  removeTable,
  register,
  setValue,
  letters,
  ...props
}: tableProps): JSX.Element => {
  const [displayTable, setDisplayTable] = useState(true);
  const [displayTrailer, setDisplayTrailer] = useState(false);
  const [editable, setEditable] = useState(true);

  const setInitialTrailerState = () => {
    const vehicleCache = sessionStorage.getItem(
      formVehicleNaming.NEW_VEHICLE_FORM
    )
      ? JSON.parse(
          decompressSessionStorage(
            sessionStorage.getItem(formVehicleNaming.NEW_VEHICLE_FORM) as string
          )
        )
      : {};

    if (
      vehicleCache?.vehicles &&
      vehicleCache.vehicles[letter]?.isTrailerActive
    ) {
      return vehicleCache.vehicles[letter].isTrailerActive === 'true';
    }

    return false;
  };

  useLayoutEffect(() => {
    setDisplayTrailer(setInitialTrailerState());
  }, []);

  const handleTrailer = () => {
    const newValue = displayTrailer ? 'false' : 'true';

    if (displayTrailer) {
      setValue(generateInputName(AccidentInput.RegistTrailer, letter), '');
      setValue(generateInputName(AccidentInput.PolicyTrailer, letter), '');
      setValue(generateInputName(AccidentInput.InsurerTrailer, letter), '');
    }

    setValue(
      generateInputName(AccidentInput.IsTrailerActive, letter),
      newValue
    );
    setDisplayTrailer(!displayTrailer);
  };

  const handleDisplayTable = useCallback(
    () => setDisplayTable((prevDisplayTable) => !prevDisplayTable),
    [displayTable]
  );

  const handleEdit = useCallback(() => {
    if (editable === true && displayTable === true) {
      setEditable(false);
    } else {
      setEditable(true);
      setDisplayTable(true);
    }
  }, [editable]);

  const isLandscapeTablet = useIsLandscapeTablet();
  const isPortraitTablet = useIsPortraitTablet();

  const columnHelper = createColumnHelper<dataTable>();

  const [columns] = useState(
    isPortraitTablet
      ? [
          columnHelper.accessor((row) => row.column1, {
            id: 'coumn1',
            header: undefined,
          }),
        ]
      : isLandscapeTablet
      ? [
          columnHelper.accessor((row) => row.column1, {
            id: 'coumn1',
            header: undefined,
          }),
          columnHelper.accessor((row) => row.column2, {
            id: 'coumn2',
            header: undefined,
          }),
        ]
      : [
          columnHelper.accessor((row) => row.column1, {
            id: 'coumn1',
            header: undefined,
          }),
          columnHelper.accessor((row) => row.column2, {
            id: 'coumn2',
            header: undefined,
          }),
          columnHelper.accessor((row) => row.column2, {
            id: 'coumn3',
            header: undefined,
          }),
        ]
  );

  const columnsVehicle = useMemo(() => {
    return [
      columnHelper.group({
        id: 'vehicles',
        header: () => {
          return (
            <HeaderVehiclesContainer>
              <TextHeader colorText="white" size="S" variant="semiBold">
                {`Vehicle ${letter}`}
              </TextHeader>
              <IconsContainer>
                <IconContainer onClick={handleEdit}>
                  {editable ? (
                    <Icon src={CheckIcon} />
                  ) : (
                    <Icon src={EditIcon} />
                  )}
                </IconContainer>
                <IconContainer onClick={() => removeTable()}>
                  <Icon src={RemoveIcon} />
                </IconContainer>
                {!editable && (
                  <IconContainer>
                    <Icon
                      onClick={handleDisplayTable}
                      src={displayTable ? DisplayIcon : RetraitIcon}
                    />
                  </IconContainer>
                )}
              </IconsContainer>
            </HeaderVehiclesContainer>
          );
        },
        columns: columns,
      }),
    ];
  }, [editable, displayTable, columns, letters]);

  const columnsTrailer = useMemo(
    () => [
      columnHelper.group({
        id: 'trailer',
        header: () => (
          <ContainerTrailerHeader>
            <TextHeader colorText="white" size="XS" variant="medium">
              {`Remolc ${letter}1`}
            </TextHeader>
            {displayTable && (
              <StyledDoubleSwitch
                onToggle={handleTrailer}
                currentState={displayTrailer}
              />
            )}
          </ContainerTrailerHeader>
        ),
        columns: columns,
      }),
    ],
    [displayTable, columns, displayTrailer]
  );

  const tableVehicles = useReactTable({
    data: [],
    columns: columnsVehicle,
    getCoreRowModel: getCoreRowModel(),
  });

  const tableTrailer = useReactTable({
    data: [],
    columns: columnsTrailer,
    getCoreRowModel: getCoreRowModel(),
  });

  const renderCell = (cell: AccidentInput) => {
    let inputName: AccidentInput;
    switch (cell) {
      case AccidentInput.RegistVehicle:
        inputName = generateInputName(AccidentInput.RegistVehicle, letter);
        return (
          <Td>
            <Cell>
              <TextCell size="S" variant="medium">
                Matrícula
              </TextCell>
              <InputCell
                {...register(inputName)}
                disabled={!editable}
                type={'text'}
                placeholder={'0000 XXX'}
              />
            </Cell>
          </Td>
        );
      case AccidentInput.ColorVehicle:
        inputName = generateInputName(AccidentInput.ColorVehicle, letter);
        return (
          <Td>
            <Cell>
              <TextCell size="S" variant="medium">
                Color
              </TextCell>
              <InputCell
                {...register(inputName)}
                disabled={!editable}
                type={'text'}
                placeholder={'Ex: Blanc'}
              />
            </Cell>
          </Td>
        );
      case AccidentInput.ModelVehicle:
        inputName = generateInputName(AccidentInput.ModelVehicle, letter);
        return (
          <Td>
            <Cell>
              <TextCell size="S" variant="medium">
                Model
              </TextCell>{' '}
              <InputCell
                {...register(inputName)}
                disabled={!editable}
                type={'text'}
                placeholder={'Marca, model'}
              />
            </Cell>
          </Td>
        );
      case AccidentInput.InsurerVehicle:
        inputName = generateInputName(AccidentInput.InsurerVehicle, letter);
        return (
          <Td>
            <Cell>
              <TextCell size="S" variant="medium">
                Asseguradora
              </TextCell>
              <InputCell
                {...register(inputName)}
                disabled={!editable}
                type={'text'}
                placeholder={'Nom'}
              />
            </Cell>
          </Td>
        );
      case AccidentInput.PolicyVehicle:
        inputName = generateInputName(AccidentInput.PolicyVehicle, letter);
        return (
          <Td>
            <Cell>
              <TextCell size="S" variant="medium">
                Nùm. Pòlissa
              </TextCell>
              <InputCell
                {...register(inputName)}
                disabled={!editable}
                type={'text'}
                placeholder={'00000000'}
              />
            </Cell>
          </Td>
        );
      case AccidentInput.OccupantsVehicle:
        inputName = generateInputName(AccidentInput.OccupantsVehicle, letter);
        return (
          <Td>
            <Cell>
              <TextCell size="S" variant="medium">
                Núm. Ocupants
              </TextCell>
              <InputCell
                {...register(inputName)}
                disabled={!editable}
                type={'number'}
                placeholder={'00000000'}
                onKeyDown={(e) => {
                  const validate =
                    !isNaN(parseInt(e.key)) ||
                    e.key === 'Backspace' ||
                    e.key === 'Enter' ||
                    e.key === 'Tab';
                  if (!validate) {
                    e.preventDefault();
                  }
                }}
              />
            </Cell>
          </Td>
        );
      case AccidentInput.RegistTrailer:
        inputName = generateInputName(AccidentInput.RegistTrailer, letter);
        return (
          <Td>
            <Cell>
              <TextCell size="S" variant="medium">
                Matrícula
              </TextCell>
              <InputCell
                {...register(inputName)}
                disabled={!editable}
                type={'text'}
                placeholder={'0000 XXX'}
              />
            </Cell>
          </Td>
        );
      case AccidentInput.InsurerTrailer:
        inputName = generateInputName(AccidentInput.InsurerTrailer, letter);
        return (
          <Td>
            <Cell>
              <TextCell size="S" variant="medium">
                Asseguradora
              </TextCell>
              <InputCell
                {...register(inputName)}
                disabled={!editable}
                type={'text'}
                placeholder={'Nom'}
              />
            </Cell>
          </Td>
        );
      case AccidentInput.PolicyTrailer:
        inputName = generateInputName(AccidentInput.PolicyTrailer, letter);
        return (
          <Td>
            <Cell>
              <TextCell size="S" variant="medium">
                Nùm. Pòlissa
              </TextCell>{' '}
              <InputCell
                {...register(inputName)}
                disabled={!editable}
                type={'text'}
                placeholder={'00000000'}
              />
            </Cell>
          </Td>
        );
      case AccidentInput.Damages:
        inputName = generateInputName(AccidentInput.Damages, letter);
        return (
          <TdTextArea colSpan={3}>
            <DamagesCell>
              <TextCell size="S" variant="medium">
                Danys
              </TextCell>{' '}
              <DamagesTextArea
                {...register(inputName)}
                placeholder="Danys al vehicle"
              />
            </DamagesCell>
          </TdTextArea>
        );
    }
  };

  return (
    <Container>
      <Table {...props}>
        <Thead>
          {tableVehicles.getHeaderGroups().map((headerGroup) => (
            <Tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <ThVehicles key={header.id} colSpan={100}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </ThVehicles>
              ))}
            </Tr>
          ))}
        </Thead>

        <Tbody show={displayTable}>
          {/* Fila 1 */}
          <TrBody>
            {renderCell(AccidentInput.RegistVehicle)}
            {!isLandscapeTablet && renderCell(AccidentInput.InsurerVehicle)}
            {!isPortraitTablet && renderCell(AccidentInput.ColorVehicle)}
          </TrBody>
          {/* Fila 2 */}
          <TrBody>
            {!isPortraitTablet && renderCell(AccidentInput.ModelVehicle)}
            {!isLandscapeTablet && renderCell(AccidentInput.OccupantsVehicle)}
            {!isLandscapeTablet && renderCell(AccidentInput.PolicyVehicle)}
            {isLandscapeTablet &&
              !isPortraitTablet &&
              renderCell(AccidentInput.InsurerVehicle)}
            {isPortraitTablet && renderCell(AccidentInput.ColorVehicle)}
          </TrBody>
          {/* Fila 3 */}
          {isLandscapeTablet && (
            <TrBody>
              {!isPortraitTablet && (
                <>
                  {renderCell(AccidentInput.OccupantsVehicle)}
                  {renderCell(AccidentInput.PolicyVehicle)}
                </>
              )}
              {isPortraitTablet && renderCell(AccidentInput.ModelVehicle)}
            </TrBody>
          )}
          {/* Fila 4 */}
          {isPortraitTablet && (
            <TrBody>{renderCell(AccidentInput.InsurerVehicle)}</TrBody>
          )}
          {/* Fila 5 */}
          {isPortraitTablet && (
            <TrBody>{renderCell(AccidentInput.PolicyVehicle)}</TrBody>
          )}
          {isPortraitTablet && (
            <TrBody>{renderCell(AccidentInput.OccupantsVehicle)}</TrBody>
          )}
        </Tbody>

        <TheadTrailer>
          {tableTrailer.getHeaderGroups().map((headerGroup) => (
            <Tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <Th key={header.id} colSpan={100}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </Th>
              ))}
            </Tr>
          ))}
        </TheadTrailer>
        {displayTrailer && (
          <Tbody show={displayTable}>
            <TrBody>
              {renderCell(AccidentInput.RegistTrailer)}
              {!isPortraitTablet && renderCell(AccidentInput.InsurerTrailer)}
              {!isLandscapeTablet && renderCell(AccidentInput.PolicyTrailer)}
            </TrBody>
            <TrBody>
              {isLandscapeTablet &&
                !isPortraitTablet &&
                renderCell(AccidentInput.PolicyTrailer)}
              {isPortraitTablet && renderCell(AccidentInput.InsurerTrailer)}
            </TrBody>
            {isPortraitTablet && (
              <TrBody>{renderCell(AccidentInput.PolicyTrailer)}</TrBody>
            )}
          </Tbody>
        )}
      </Table>
    </Container>
  );
};

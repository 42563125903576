import styled, { css } from 'styled-components';

// Styles
import { colors } from '../../../../styles/colors';

// Styled Components
import {
  TextCell,
  Tr,
  TableContainer,
  FiltersContainer,
  Container,
} from '../components/MainStyledComponents';

// Icons
import { ArrowBottom } from '../../../../assets/icons/ArrowBottom';

// Types
import {
  ArrowBottomStyledProps,
  ContainersLoadingProps,
  ShowFiltersButtonProps,
} from './types';

export const ContainerStyled = styled(Container)`
  position: relative;
`;

export const TableContainerStyled = styled(
  TableContainer
)<ContainersLoadingProps>`
  opacity: ${(props) => (props.isLoading ? 0.6 : 1)};
`;

export const FiltersWithLoadingStyled = styled(
  FiltersContainer
)<ContainersLoadingProps>`
  opacity: ${(props) => (props.isLoading ? 0.6 : 1)};
`;

export const ContainerLoader = styled.div`
  position: absolute;
  top: 30%;
  left: 50%;
  z-index: 1;
`;
export const SubContainerHead = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ShowFiltersButton = styled.button<ShowFiltersButtonProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  height: 2.3rem;
  border-radius: 0.3rem;
  transition: background 300ms;
  background: ${(props) => (props.isBlack ? colors.black : colors.garnet)};
`;

export const ArrowBottomStyled = styled(ArrowBottom)<ArrowBottomStyledProps>`
  transition: transform 300ms;
  transform: ${(props) => (props.isRotate ? null : 'rotate(-90deg)')};
  width: 1rem;
  height: 1rem;
  path {
    fill: white;
  }
`;

export const TrStyled = styled(Tr)`
  background-color: ${({ checked }) => checked && colors.lightRed};
  ${({ indeterminate, checked }) =>
    css`
      Td {
        ${!indeterminate && {
          opacity: 0.2,
        }}
        ${checked && {
          opacity: 1,
        }}
      }
    `}
`;

export const TextCellStyled = styled(TextCell)`
  color: ${colors.black};
`;

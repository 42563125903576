// GQL
import { client } from '../client';
import { REGENERATE_ACCESS_TOKEN } from '../mutations/auth';
export const refreshToken = async (refreshToken: string) => {
  const refreshResolverResponse = await client.mutate({
    mutation: REGENERATE_ACCESS_TOKEN,
    variables: { refreshToken },
  });

  return refreshResolverResponse.data?.regenerateAccessToken;
};

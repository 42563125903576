import { defer, LoaderFunction } from 'react-router-dom';

// Sentry
import * as Sentry from '@sentry/react';

// GQL
import { client } from '../../../../gql/client';
import { GET_ROAD_BY_ID } from '../../../../gql/queries/geoPositions/roads';
import { FETCH_PREDEFINED_OPTIONS_ACCIDENTS } from '../../../../gql/queries/accidents/predefinedData';

// Utils
import { formAccidentNaming } from '../../../../components/forms/AddBasicInfoAccidentForm/constants';
import { AuthContextType } from '../../../../hooks/useAuth';
import { decompressSessionStorage } from '../../../../utils/sessionStorage';

// Types
import { type Accident } from '../../../../types/accident';

const loadFormOptions = async (userData: AuthContextType | null) => {
  try {
    if (userData?.user) {
      const { data } = await client.query({
        query: FETCH_PREDEFINED_OPTIONS_ACCIDENTS,
      });

      return {
        ...data,
      };
    }
    return {};
  } catch (err) {
    Sentry.captureException(err);
    console.log('> Accident Summary loading error:', err);
    return true;
  }
};

export const AddBasicInfoAccidentLoader: (
  userData: AuthContextType | null
) => LoaderFunction | undefined = (userData) => async () => {
  const stringifyForm = sessionStorage.getItem(
    formAccidentNaming.ADD_BASIC_INFO_ACCIDENT_FORM
  );

  const stringifyAccidentForm = sessionStorage.getItem(
    formAccidentNaming.NEW_ACCIDENT_FORM
  );

  let road,
    accidentData: Accident,
    orderParentCotic,
    orderParentId,
    registerInitDate;
  const loaderToResolve = await loadFormOptions(userData);
  if (stringifyAccidentForm) {
    accidentData = JSON.parse(decompressSessionStorage(stringifyAccidentForm));

    orderParentCotic = accidentData.orderParentCotic;
    orderParentId = accidentData.orderParentId;
    registerInitDate = accidentData.registerInitDate;

    const roadId = accidentData?.road;
    try {
      road = await client.query({
        query: GET_ROAD_BY_ID,
        variables: {
          id: roadId,
        },
      });
      road = road.data.road.name;
    } catch (err) {
      Sentry.captureException(err);
      console.log('> Accident error - Error getting road');
    }
  }

  if (stringifyForm && userData && orderParentCotic && orderParentId) {
    const sessionStorageData = JSON.parse(
      decompressSessionStorage(stringifyForm)
    );

    const combinedData: Accident = {
      ...sessionStorageData,
      orderParentCotic,
      orderParentId,
      road,
      registerInitDate,
      ...loaderToResolve,
    };

    return defer({
      data: combinedData,
    });
  }

  if (!stringifyForm && !stringifyAccidentForm) {
    return defer({
      data: null,
    });
  }

  return defer({
    data: {
      registerInitDate,
      road,
      ...loaderToResolve,
    },
  });
};

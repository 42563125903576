import { gql } from '@apollo/client';

export const FETCH_ANIMALS = gql`
  query FetchAnimals {
    animals {
      id
      animalId
      name
    }
  }
`;

export const GET_ANIMALS = gql`
  query GetAnimals {
    readAnimals @client {
      id
      animalId
      name
    }
  }
`;

export const GET_ANIMAL_BY_ID = gql`
  query GetAnimalById($id: ID!) {
    animal(id: $id) {
      id
      animalId
      name
    }
  }
`;

export const GET_ANIMAL_UNIT_BY_ID = gql`
  query GetAnimalUnitById($id: ID!) {
    animalUnit(id: $id) {
      id
      unit
      animalIDs
      animalStatusIDs
    }
  }
`;

export const GET_ANIMAL_STATUS_BY_ID = gql`
  query GetAnimalStatusById($id: ID!) {
    animalStatus(id: $id) {
      id
      code
      description
      animalUnitIDs
    }
  }
`;

export const FETCH_ANIMAL_STATUS = gql`
  query FetchAnimalStatus {
    animalAllStatus {
      id
      code
      description
      animalUnitIDs
    }
  }
`;

import React from 'react';

type SelectionProps = {
  className?: string;
};

export const Selection = ({ className }: SelectionProps): JSX.Element => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M0.5 13.5H13.5V0.5H0.5V13.5Z"
        stroke="color"
        strokeDasharray="2 2"
      />
    </svg>
  );
};

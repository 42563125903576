// Actions

import {
  INDEX_TAB_FORM_VERTICAL,
  INDEX_TAB_FORM_RESET_VERTICAL,
} from '../../actions/ui/tabsVertical';

export const initialTabsVerticalState = {
  communication: {
    index: 0,
  },
  workOrdersGeneral: {
    index: 0,
  },
  action: {
    index: 0,
  },
  surveillanceRoute: {
    index: 0,
  },
};

export const tabsTypeVertical = {
  COMMUNICATION: 'communication',
  WORK_ORDERS_GENERAL: 'workOrdersGeneral',
  WORK_ORDERS_ACTION: 'workOrdersAction',
  SURVEILLANCE_ROUTE: 'surveillanceRoute',
};

type actionType = {
  type: string;
  payload: { type: string; index: number };
};

export default function tabsVertical(
  state = initialTabsVerticalState,
  { type, payload }: actionType
) {
  switch (type) {
    case INDEX_TAB_FORM_VERTICAL:
      return {
        ...state,
        [payload.type]: {
          // eslint-disable-next-line
          // @ts-ignore
          ...state[payload.type],
          index: payload.index,
        },
      };
    case INDEX_TAB_FORM_RESET_VERTICAL:
      return {
        ...state,
        [payload.type]: {
          // eslint-disable-next-line
          // @ts-ignore
          ...initialTabsVerticalState[payload.type],
        },
      };
    default:
      return state;
  }
}

import { options as OptionRouterBase } from '../../../router/definitionGloblaRoutes';
import { data as DataComunication } from '../../../pages/main/comunications/CommunicationsLayout/routes';
import { data as DataWorkOrders } from '../../../pages/main/workOrders/WorkOrdersLayout/routes';
import { data as DataManagement } from '../../../pages/main/management/managementRouteLayout/routes';
import { optionsTypes } from './types';

export const options: optionsTypes[] = [...OptionRouterBase];

options[0] = {
  ...options[0],
};

options[1] = {
  ...options[1],
  data: [...DataComunication],
};

options[2] = {
  ...options[2],
  data: [...DataWorkOrders],
};

// options[3] = {
//   ...options[3],
//   data: [...DataSurveillance],
// };

// options[4] = {
//   ...options[4],
//   data: [...DataReporting],
// };

options[3] = {
  ...options[3],
  data: [...DataManagement],
};

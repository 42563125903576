import React from 'react';

type ButtonRightProps = {
  className?: string;
};

export const ButtonRight = ({ className }: ButtonRightProps) => {
  return (
    <svg
      width="7"
      height="12"
      viewBox="0 0 7 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M0.618208 12.0076C0.536957 12.0079 0.456451 11.9922 0.381337 11.9615C0.306222 11.9307 0.237983 11.8856 0.180553 11.8285C0.0654894 11.7129 0.000976563 11.5569 0.000976562 11.3944C0.000976562 11.2318 0.0654894 11.0759 0.180553 10.9602L5.24943 5.93157L0.333854 1.05504C0.217781 0.939885 0.152573 0.783705 0.152573 0.620857C0.152573 0.458008 0.217781 0.301829 0.333854 0.186678C0.449927 0.0715263 0.607358 0.00683594 0.77151 0.00683594C0.935662 0.00683593 1.09309 0.0715263 1.20917 0.186678L6.99758 5.93157L1.06328 11.8187C1.00581 11.8784 0.936738 11.9259 0.860241 11.9584C0.783745 11.9908 0.70141 12.0076 0.618208 12.0076V12.0076Z"
        fill="#ffffff"
      />
    </svg>
  );
};

import React from 'react';

// Components
import { MainButton } from '../../../inputs/buttons/MainButton';
import ModalContainer from '../../containers/ModalContainer';

// Styled Components
import { Container, ContainerText, Title, Text } from './styles';

// Colors
import { colors } from '../../../../styles/colors';

// Types
import { ModalProps } from './types';

export const ChangeCampaignToOT = ({
  onClickClose,
  continueClick,
  ...props
}: ModalProps): JSX.Element => {
  return (
    <ModalContainer width={50} fill={colors.black} onClickClose={onClickClose}>
      <Container {...props}>
        <ContainerText>
          <Title variant="semiBold">
            Si desactives aquesta opció crearàs una O.T. i no una campanya
          </Title>
          <Text>¿Segur que vols continuar?</Text>
        </ContainerText>
        <MainButton text="Continuar" onClick={continueClick} />
      </Container>
    </ModalContainer>
  );
};

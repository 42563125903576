import { gql } from '@apollo/client';

export const FETCH_PREDEFINED_OPTIONS_ACCIDENTS = gql`
  query FetchPredefinedAccidentsOptions {
    conditionsDrivings {
      accidentsIDs
      id
      name
    }

    luminositys {
      accidentsIDs
      id
      name
    }

    mediumKnowledges {
      accidentsIDs
      id
      name
    }

    surfaceConditions {
      name
      id
      accidentsIDs
    }

    surveillanceBodys {
      name
      id
      accidentsIDs
    }

    weathers {
      name
      id
      accidentsIDs
    }
  }
`;

export const FETCH_FILTER_ACCIDENTS = gql`
  query FetchPredefinedAccidentsOptions {
    mediumKnowledges {
      accidentsIDs
      id
      name
    }
    weathers {
      name
      id
      accidentsIDs
    }
  }
`;

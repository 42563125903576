import React from 'react';
import { useAsyncValue, useSubmit } from 'react-router-dom';

// Components Styled
import { Container } from './styles';

// Utils
import { createFormDataFromValues } from '../../../../utils/forms';

// Types and Const
import NewCommunicationForm from '../../../../components/forms/NewCommunicationForm';
import { NewCommunication } from '../../../../types/communication';
import { formNaming } from '../../../../components/forms/NewCommunicationForm/constants';

// Store
import { tabsType } from '../../../../state/reducers/ui/tabs';

export default function NewCommunicationPage(): JSX.Element {
  const loaderData = useAsyncValue() as NewCommunication;
  const submit = useSubmit();

  const onFormSubmit = async (values: NewCommunication) => {
    const formData = createFormDataFromValues(values);
    submit(formData, {
      method: 'POST',
      action: 'summary',
    });
  };

  return (
    <Container>
      <NewCommunicationForm
        initialData={loaderData}
        onFormSubmit={onFormSubmit}
        namingPersistForm={formNaming.NEW_COMMUNICATION_FORM}
        namingTabsType={tabsType.COMMUNICATION}
      />
    </Container>
  );
}

interface DefaultConfig {
  filename: string;
  delimiter: string;
  ignoreColumns: number[];
  filterByIDs: string[];
  filterPositionColumn: number;
}

const defaultConfig: DefaultConfig = {
  filename: 'table.csv',
  delimiter: ',',
  ignoreColumns: [],
  filterByIDs: [],
  filterPositionColumn: 1,
};
export class TableToCSV {
  private options: DefaultConfig;
  private table: HTMLTableElement | null;
  private tableTitles: Array<string | undefined | null> = [];
  private csvRecord: Array<string> = [];
  constructor(tableId: string, options: object = {}) {
    this.options = {
      ...defaultConfig,
      ...options,
    };
    this.table = this.getTableElement(tableId);
    this.init();
  }

  getTableElement(tableId: string): HTMLTableElement | null {
    return document.querySelector(tableId);
  }

  init(): void {
    if (this.table && this.table.tHead) {
      this.tableTitles = this.findTableTitles(this.table.tHead);
      this.csvRecord = this.generateCSVData(this.table.tBodies);
    }
  }

  findTableTitles(
    heads: HTMLTableSectionElement
  ): Array<string | undefined | null> {
    const thead: HTMLTableRowElement | null = heads.rows.item(0);
    const cells: HTMLCollectionOf<HTMLTableHeaderCellElement> | undefined =
      thead?.cells;
    const titles: Array<string | undefined | null> = [];
    if (cells) {
      for (let i = 0; i < cells.length; i++) {
        if (this.options.ignoreColumns.includes(i)) continue;
        titles.push(cells?.item(i)?.textContent);
      }
    }

    return titles;
  }

  generateCSVData(tbody: HTMLCollectionOf<HTMLTableSectionElement>): string[] {
    const rows: HTMLCollectionOf<HTMLTableRowElement> | undefined =
      tbody?.item(0)?.rows;
    const csvRecord: Array<string> = [];
    if (rows) {
      for (let i = 0; i < rows.length; i++) {
        const tr: HTMLCollectionOf<HTMLTableDataCellElement> | undefined =
          rows?.item(i)?.cells;
        const csvRow: Array<string | null | undefined> = [];
        if (
          !this.options.filterByIDs.includes(
            tr?.item(this.options.filterPositionColumn)?.textContent ?? ''
          )
        ) {
          continue;
        }
        if (tr) {
          for (let j = 0; j < tr.length; j++) {
            if (this.options.ignoreColumns.includes(j)) continue;
            csvRow.push(tr?.item(j)?.textContent);
          }
        }

        csvRecord.push(csvRow.join(this.options.delimiter));
      }
    }

    return csvRecord;
  }

  download(): void {
    const data = [
      this.tableTitles.join(this.options.delimiter),
      ...this.csvRecord,
    ].join('\n');
    const csvFile = new Blob([data], { type: 'text/csv' });
    const link = document.createElement('a');
    link.download = this.options.filename;
    link.href = window.URL.createObjectURL(csvFile);
    link.style.display = 'none';
    document.body.appendChild(link);
    link.click();
    window.URL.revokeObjectURL(link.href);
    document.body.removeChild(link);
  }
}

import React, { useState, useRef } from 'react';

// Components
import ModalContainer from '../../containers/ModalContainer';
import { ButtonIconBackground } from '../../../inputs/buttons/ButtonIconBackground';

// Colors
import { colors } from '../../../../styles/colors';

// Types
import { ModalProps } from './types';
import AccidentSketch from '../../../inputs/AccidentSketch';
import { ButtonsContainer, CanvasPreview } from './styles';
import { ImperativeRefSketch } from '../../../inputs/AccidentSketch/types';

// Constants
import { formAccidentNaming } from '../../../forms/AddBasicInfoAccidentForm/constants';

export const AddSketchModal = ({
  onClickClose,
  onAddSketch,
  ...props
}: ModalProps): JSX.Element => {
  const [sketch, setSketch] = useState<string | null>(null);
  const sketchRef = useRef<ImperativeRefSketch>(null);

  const handleSaveDraw = () => {
    const sketchFromSessionStorage = sessionStorage.getItem(
      formAccidentNaming.IMAGE_CANVAS
    );
    setSketch(sketchFromSessionStorage);
  };

  const handleCancel = () => {
    setSketch(null);
    setTimeout(() => {
      sketchRef.current?.getSvg();
    }, 1);
  };

  return (
    <ModalContainer
      width={140}
      onClickClose={onClickClose}
      fill={colors.black}
      title={"Comunicat d'accident"}
      {...props}
    >
      {!sketch ? (
        <AccidentSketch ref={sketchRef} onSaveDraw={handleSaveDraw} />
      ) : (
        <>
          <CanvasPreview src={sketch} alt="Croquis preview" />
          <ButtonsContainer>
            <ButtonIconBackground
              type="button"
              onClick={onAddSketch}
              variant="check"
            />
            <ButtonIconBackground
              type="button"
              onClick={handleCancel}
              variant="edit"
            />
          </ButtonsContainer>
        </>
      )}
    </ModalContainer>
  );
};

import { gql } from '@apollo/client';

export const GET_EXTERNAL_WORK_BY_ID = gql`
  query GetExternalWork($id: ID!) {
    externalWork(id: $id) {
      id
      # workName
      operatorsNumber
      externalCompanyIDs
    }
  }
`;

// GQL
import { client } from '../client';
import {
  GET_CONCESSION_BY_ID,
  GET_ROAD_BY_ID,
} from '../queries/geoPositions/roads';
import {
  GET_CAPITOL_BY_ID,
  GET_CLASSIFICATION_BY_ID_OR_COTIC,
  GET_SUB_CAPITOL_BY_ID,
} from '../queries/orders/predefinedData';
import { GET_TEAM_BY_ID } from '../queries/users';

// Static data
import { groupLanes, groupTracks } from '../../data/orders';

export const selectDataForEditCampaignSummary = async (formData: FormData) => {
  const id = formData.get('id');
  const cotic = formData.get('cotic');
  const operator = formData.get('operator');
  const registerInitDate = formData.get('registerInitDate');
  const sendStatus = formData.get('sendStatus');
  const concessionId = formData.get('concession');
  const roadId = formData.get('road');
  const classificationId = formData.get('classification');
  const capitolId = formData.get('capitol');
  const subCapitolId = formData.get('subCapitol');
  const teamId = formData.get('team');
  let teamData;

  const [
    {
      data: { concession },
    },
    {
      data: { road },
    },
  ] = await Promise.all([
    client.query({
      query: GET_CONCESSION_BY_ID,
      variables: {
        id: concessionId,
      },
    }),
    client.query({
      query: GET_ROAD_BY_ID,
      variables: {
        id: roadId,
      },
    }),
  ]);

  if (teamId) {
    const {
      data: { team },
    } = await client.query({
      query: GET_TEAM_BY_ID,
      variables: {
        id: teamId,
      },
    });
    teamData = team;
  }

  const [
    {
      data: { classification },
    },
    {
      data: { capitol },
    },
    {
      data: { subCapitol },
    },
  ] = await Promise.all([
    client.query({
      query: GET_CLASSIFICATION_BY_ID_OR_COTIC,
      variables: {
        id: classificationId,
      },
    }),
    client.query({
      query: GET_CAPITOL_BY_ID,
      variables: {
        id: capitolId,
      },
    }),
    client.query({
      query: GET_SUB_CAPITOL_BY_ID,
      variables: {
        id: subCapitolId,
      },
    }),
  ]);

  const classificationPass = classification.name;
  const capitolPass = capitol.name;
  const subCapitolPass = subCapitol.name;

  const city = formData.get('city');
  const trackValue = formData.get('track');
  const direction = formData.get('direction');
  const margin = formData.get('margin');
  const laneValue = formData.get('lane');
  const pkInit = formData.get('pkInit');
  const pkEnd = formData.get('pkEnd');
  const description = formData.get('description');
  const specialTag = formData.get('specialTag');
  const campaign = formData.get('campaign');
  const changesCampaign = formData.get('changesCampaign');

  const coticParsed = () => {
    return cotic?.toString().split(',');
  };

  return {
    id,
    cotic: coticParsed(),
    operator,
    registerInitDate,
    sendStatus,
    concession: concession?.name,
    city,
    road: road?.name,
    track: groupTracks.find((track) => track.value === trackValue)?.name,
    direction,
    margin,
    lane: groupLanes.find((lane) => lane.value === laneValue)?.name,
    pkInit,
    pkEnd,
    description,
    specialTag,
    classification: classificationPass,
    capitol: capitolPass,
    subCapitol: subCapitolPass,
    team: teamData?.name,
    campaign: campaign,
    changesCampaign: JSON.parse(changesCampaign as string),
  };
};

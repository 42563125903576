import styled from 'styled-components';
import {
  breakPoints,
  percentBaseResponsive,
  spaces,
} from '../../../../../../styles/spaces';
import { colors } from '../../../../../../styles/colors';
import { AttentionAccidentTable } from '../../../../../../components/display/tables/formTables/AttentionAccidentTable';
import { MainButton } from '../../../../../../components/inputs/buttons/MainButton';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  gap: ${spaces.xl}rem;
  flex: 1;
`;

export const ContainerContent = styled.div`
  display: flex;
  gap: ${spaces.xl}rem;
  @media (max-width: ${breakPoints.smallDesktop * percentBaseResponsive}rem) {
    flex-direction: column;
  }
`;

export const SubContainerContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spaces.xl}rem;
  flex: 1;
`;

export const SummaryContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spaces.xl}rem;
  border-radius: 0.8rem;
  padding: ${spaces.xl}rem;
  background: ${colors.verySoftRed};
`;

export const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spaces.xxs}rem;
`;

export const AttentionAccidentTableStyled = styled(AttentionAccidentTable)`
  max-width: 100%;
`;

export const MainButtonStyled = styled(MainButton)`
  align-self: end;
`;

import React, { useLayoutEffect, useState } from 'react';
import { useAsyncValue, useNavigate, useSubmit } from 'react-router-dom';
import { useDispatch } from 'react-redux';

// Components Styled
import { Container } from './styles';

// Utils
import { createFormDataFromValues } from '../../../../utils/forms';

// Types and Const
import NewWorkOrderForm from '../../../../components/forms/NewWorkOrderForm';
import { type Campaign } from '../../../../types/campaign';
import { formOrderNaming } from '../../../../components/forms/NewWorkOrderForm/constants';
import { UNSPECIFIC } from '../../../../types/utils';

// Store
import { coticOTSelected } from '../../../../state/actions/ui/coticOTSelectedGeneralView';
import { difference } from '../../../../utils/difference';
import {
  compressSessionStorage,
  decompressSessionStorage,
} from '../../../../utils/sessionStorage';

export default function EditCampaignPage(): JSX.Element {
  const loaderData = useAsyncValue() as Campaign;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const submit = useSubmit();

  const [errorMessage, setErrorMessage] = useState<string>('');

  useLayoutEffect(() => {
    if (!loaderData) navigate('/work-orders/campaign');
    if (loaderData.id) {
      dispatch(
        coticOTSelected(
          'edit-campaign',
          (loaderData.cotic as unknown as string[]).join('.'),
          loaderData.id
        )
      );
    }
  }, [loaderData]);

  const onFormSubmit = async (values: Campaign) => {
    if (!values?.direction) {
      values.direction = UNSPECIFIC;
    }

    if (!values?.margin) {
      values.margin = UNSPECIFIC;
    }

    const dataDifference = difference(values, {
      ...loaderData,
      intent: 'pass-data',
    });

    const fullFormData = createFormDataFromValues(values);
    if (Object.keys(dataDifference).length > 0) {
      const formDataOnlyWithChanges = JSON.stringify({
        ...dataDifference,
        id: loaderData.id,
        cotic: loaderData.cotic,
        modelTypeId: loaderData.modelTypeId,
      });

      fullFormData.append('changesCampaign', formDataOnlyWithChanges);

      const existingData = sessionStorage.getItem(
        formOrderNaming.EDIT_CAMPAIGN_FORM
      );

      if (existingData) {
        const parsedData = JSON.parse(decompressSessionStorage(existingData));
        parsedData.changesCampaign = formDataOnlyWithChanges;
        const updatedData = JSON.stringify(parsedData);
        sessionStorage.setItem(
          formOrderNaming.EDIT_CAMPAIGN_FORM,
          compressSessionStorage(updatedData)
        );
      }

      setErrorMessage('');
      submit(fullFormData, {
        method: 'POST',
        action: `summary`,
      });
    } else {
      setErrorMessage(
        'You must make at least one change to the form before proceeding'
      );
    }
  };

  return (
    <Container>
      <NewWorkOrderForm
        initialData={loaderData}
        onFormSubmit={onFormSubmit}
        namingPersistForm={formOrderNaming.EDIT_CAMPAIGN_FORM}
        isCampaign={true}
        errorMessage={errorMessage}
        titleBasicInfo={'Informació bàsica'}
      />
    </Container>
  );
}

// GraphQL
import { client } from '../client';
import { GET_ORDER_BY_ID } from '../queries/orders/orders';
import {
  GET_CONCESSION_BY_ID,
  GET_ROAD_BY_ID,
} from '../queries/geoPositions/roads';
import {
  GET_COMMUNICATION_BY_ID,
  GET_INTERLOCUTOR_GROUP_BY_ID,
  GET_MEDIA_OF_COMMUNICATION_BY_ID,
} from '../queries/communication';
import {
  GET_CAPITOL_BY_ID,
  GET_CLASSIFICATION_BY_ID_OR_COTIC,
  GET_SUB_CAPITOL_BY_ID,
} from '../queries/orders/predefinedData';
import { GET_USER_BY_ID } from '../queries/users';

// Utils
import { groupLanes, groupTracks } from '../../data/orders';
import { pkFormatter } from '../../utils/transform';

// This is for summary data
export const selectCommunicationOrderByIdOrder = async (orderId: string) => {
  const {
    data: { order },
  } = await client.query({
    query: GET_ORDER_BY_ID,
    variables: {
      id: orderId,
    },
  });
  const parentExpeditionOrder = await client.query({
    query: GET_ORDER_BY_ID,
    variables: {
      id: order.parentExpeditionOrderIDs[0],
    },
  });

  const [
    {
      data: { communication },
    },
    {
      data: { capitol },
    },
    {
      data: { subCapitol },
    },
    {
      data: { classification },
    },
    {
      data: { user },
    },
  ] = await Promise.all([
    client.query({
      query: GET_COMMUNICATION_BY_ID,
      variables: {
        id: order.communicationIDs[0],
      },
    }),
    client.query({
      query: GET_CAPITOL_BY_ID,
      variables: {
        id: order.capitolIDs[0],
      },
    }),
    client.query({
      query: GET_SUB_CAPITOL_BY_ID,
      variables: {
        id: order.subCapitolIDs[0],
      },
    }),
    client.query({
      query: GET_CLASSIFICATION_BY_ID_OR_COTIC,
      variables: {
        id: order.classificationIDs[0],
      },
    }),
    client.query({
      query: GET_USER_BY_ID,
      variables: {
        input: {
          id: order.userId,
        },
      },
    }),
  ]);

  const [
    {
      data: { concession },
    },
    {
      data: { road },
    },
    {
      data: { interlocutorGroup },
    },
    {
      data: { mediaOfCommunication },
    },
  ] = await Promise.all([
    client.query({
      query: GET_CONCESSION_BY_ID,
      variables: {
        id: communication.concessionIDs[0],
      },
    }),
    client.query({
      query: GET_ROAD_BY_ID,
      variables: {
        id: communication.roadIDs[0],
      },
    }),
    client.query({
      query: GET_INTERLOCUTOR_GROUP_BY_ID,
      variables: {
        id: communication.interlocutorGroupIDs[0],
      },
    }),
    client.query({
      query: GET_MEDIA_OF_COMMUNICATION_BY_ID,
      variables: {
        id: communication.mediaOfCommunicationIDs[0],
      },
    }),
  ]);
  return {
    id: order.id,
    cotic: order.cotic.split('.'),
    registerInitDate: order.registerInitDate,
    groupInter: interlocutorGroup.name,
    media: mediaOfCommunication.name,
    sendStatus: communication.sendStatus,
    concession: concession.name,
    road: road.name,
    pkInit: pkFormatter(communication.pkInitKm, communication.pkInitMeter),
    pkEnd: pkFormatter(communication.pkEndKm, communication.pkEndMeter),
    description: order.description,
    capitol: capitol.name,
    subCapitol: subCapitol.name,
    classification: classification.name,
    track: groupTracks.find((track) => track.value === communication.track)
      ?.name,
    roadDirections: road.directions,
    direction: communication?.direction,
    margin: communication?.margin,
    lane: groupLanes.find((lane) => lane.value === communication.lane)?.name,
    city: concession.location,
    pkInitRoad: pkFormatter(road.pkInitKm, road.pkInitMeter, true),
    pkEndRoad: pkFormatter(road.pkEndKm, road.pkEndMeter, true),
    state: order.state,
    parentOrderId: order.parentExpeditionOrderIDs,
    userOperator: user,
    stateParentExpeditionOrder: parentExpeditionOrder.data.order.state,
  };
};
export const selectCommunicationOrderByIdOrderWithRelationshipIds = async (
  orderId: string
) => {
  const {
    data: { order },
  } = await client.query({
    query: GET_ORDER_BY_ID,
    variables: {
      id: orderId,
    },
  });

  const {
    data: { order: parentOrder },
  } = await client.query({
    query: GET_ORDER_BY_ID,
    variables: {
      id: order.parentExpeditionOrderIDs[0],
    },
  });

  const {
    data: { communication },
  } = await client.query({
    query: GET_COMMUNICATION_BY_ID,
    variables: {
      id: order.communicationIDs[0],
    },
  });

  const [
    {
      data: { concession },
    },
    {
      data: { road },
    },
  ] = await Promise.all([
    client.query({
      query: GET_CONCESSION_BY_ID,
      variables: {
        id: communication.concessionIDs[0],
      },
    }),
    client.query({
      query: GET_ROAD_BY_ID,
      variables: {
        id: communication.roadIDs[0],
      },
    }),
  ]);

  const {
    data: { capitol },
  } = await client.query({
    query: GET_CAPITOL_BY_ID,
    variables: {
      id: order.capitolIDs[0],
    },
  });

  const {
    data: { subCapitol },
  } = await client.query({
    query: GET_SUB_CAPITOL_BY_ID,
    variables: {
      id: order.subCapitolIDs[0],
    },
  });

  return {
    id: order.id,
    cotic: order.cotic.split('.'),
    registerInitDate: order.registerInitDate,
    groupInter: communication.interlocutorGroupIDs[0],
    media: communication.mediaOfCommunicationIDs[0],
    sendStatus: communication.sendStatus,
    concession: communication.concessionIDs[0],
    road: communication.roadIDs[0],
    pkInit: pkFormatter(communication.pkInitKm, communication.pkInitMeter),
    pkEnd: pkFormatter(communication.pkEndKm, communication.pkEndMeter),
    description: order.description,
    capitol: order.capitolIDs[0],
    subCapitol: order.subCapitolIDs[0],
    classification: order.classificationIDs[0],
    track: communication.track,
    direction: communication?.direction,
    margin: communication?.margin,
    lane: communication.lane,
    city: concession.location,
    pkInitRoad: pkFormatter(road.pkInitKm, road.pkInitMeter, true),
    pkEndRoad: pkFormatter(road.pkEndKm, road.pkEndMeter, true),
    parentOrderType: parentOrder.type,
    parentOrderId: parentOrder.id,
    capitolName: capitol.name,
    subCapitolName: subCapitol.name,
  };
};

// This is for tables data
export const selectCommunicationOrders = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  communications: any[] = [],
  reFetch = false
) => {
  return Promise.all(
    communications.map(async (communication) => {
      const {
        data: { order },
      } = await client.query({
        query: GET_ORDER_BY_ID,
        variables: {
          id: communication.orderIDs[0],
        },
        fetchPolicy: reFetch ? 'network-only' : 'cache-first',
      });
      const [
        {
          data: { concession },
        },
        {
          data: { interlocutorGroup },
        },
        {
          data: { mediaOfCommunication },
        },
        {
          data: { road },
        },
      ] = await Promise.all([
        client.query({
          query: GET_CONCESSION_BY_ID,
          variables: {
            id: communication.concessionIDs[0],
          },
          fetchPolicy: reFetch ? 'network-only' : 'cache-first',
        }),
        client.query({
          query: GET_INTERLOCUTOR_GROUP_BY_ID,
          variables: {
            id: communication.interlocutorGroupIDs[0],
          },
          fetchPolicy: reFetch ? 'network-only' : 'cache-first',
        }),
        client.query({
          query: GET_MEDIA_OF_COMMUNICATION_BY_ID,
          variables: {
            id: communication.mediaOfCommunicationIDs[0],
          },
          fetchPolicy: reFetch ? 'network-only' : 'cache-first',
        }),
        client.query({
          query: GET_ROAD_BY_ID,
          variables: {
            id: communication.roadIDs[0],
          },
          fetchPolicy: reFetch ? 'network-only' : 'cache-first',
        }),
      ]);

      return {
        id: order.id,
        cotic: order.cotic.split('.'),
        registerInitDate: order.registerInitDate,
        creationDate: order.creationDate,
        groupInter: interlocutorGroup.name,
        media: mediaOfCommunication.name,
        sendStatus: communication.sendStatus,
        concession: concession.name,
        road: road.name,
        pkInit: pkFormatter(communication.pkInitKm, communication.pkInitMeter),
        pkEnd: pkFormatter(communication.pkEndKm, communication.pkEndMeter),
        description: order.description,
        communicationID: communication.id,
        state: order.state,
      };
    })
  );
};

// TODO: Review and remove if unnecessary
// This is for tables data
// export const selectCommunicationOrdersWithStatusCapitolSubCapitolAndOnlyRoot =
//   async (
//     // eslint-disable-next-line @typescript-eslint/no-explicit-any
//     communications: any[]
//   ) => {
//     // eslint-disable-next-line @typescript-eslint/no-explicit-any
//     const promisesCommunications: Promise<any>[] = [],
//       communicationsFullFilled: (dataTable & { classification: string })[] = [];

//     const queryCommunication = async (
//       // eslint-disable-next-line @typescript-eslint/no-explicit-any
//       communication: any
//     ) => {
//       const {
//         data: { order },
//       } = await client.query({
//         query: GET_ORDER_BY_ID,
//         variables: {
//           id: communication.orderIDs[0],
//         },
//       });
//       if (order.isCommunicationRoot) {
//         const [
//           {
//             data: { concession },
//           },
//           {
//             data: { road },
//           },
//         ] = await Promise.all([
//           client.query({
//             query: GET_CONCESSION_BY_ID,
//             variables: {
//               id: communication.concessionIDs[0],
//             },
//           }),
//           client.query({
//             query: GET_ROAD_BY_ID,
//             variables: {
//               id: communication.roadIDs[0],
//             },
//           }),
//         ]);

//         const [
//           {
//             data: { capitol },
//           },
//           {
//             data: { subCapitol },
//           },
//           {
//             data: { classification },
//           },
//         ] = await Promise.all([
//           client.query({
//             query: GET_CAPITOL_BY_ID,
//             variables: {
//               id: order.capitolIDs[0],
//             },
//           }),
//           client.query({
//             query: GET_SUB_CAPITOL_BY_ID,
//             variables: {
//               id: order.subCapitolIDs[0],
//             },
//           }),
//           client.query({
//             query: GET_CLASSIFICATION_BY_ID_OR_COTIC,
//             variables: {
//               id: order.classificationIDs[0],
//             },
//           }),
//         ]);

//     communicationsFullFilled.push({
//       id: order.id,
//       specialTag: order?.specialTag ?? '',
//       cotic: order.cotic.split('.'),
//       date: order.registerInitDate,
//       hour: order.registerInitDate,
//       capitol: capitol.name,
//       subCapitol: subCapitol.name,
//       classification: classification.name,
//       state: order.state,
//       concession: concession.name,
//       road: road.name,
//       pkInit: pkFormatter(
//         communication.pkInitKm,
//         communication.pkInitMeter
//       ),
//       pkEnd: pkFormatter(communication.pkEndKm, communication.pkEndMeter),
//       description: order.description,
//     });
//   }
// };

//     for (const communication of communications) {
//       promisesCommunications.push(queryCommunication(communication));
//     }

//     await Promise.all(promisesCommunications);

//     return communicationsFullFilled;
//   };

import { object, string } from 'yup';

// Types
import { Accident } from '../../../types/accident';

export const AccidentSchema = object<Accident>({
  mediumKnowledge: string().required(),
  laneCutting: string().required(),
  policeReport: string().required(),
  surveillanceBody: string().when(
    '$isAReportState',
    ([isAReportState], schema) =>
      isAReportState ? schema.required() : schema.notRequired()
  ),
  policeReportNumber: string().when(
    ['$isAReportState'],
    ([isAReportState], schema) =>
      isAReportState ? schema.required() : schema.notRequired()
  ),
  weather: string().required(),
  surfaceCondition: string().required(),
  luminosity: string().required(),
  conditionsDriving: string().required(),
  roadConditionDescription: string().required(),
  images: string().required('You need add at least one image'),
  noticeRoadTeamDate: string().required(),
  arrivalRoadTeamDate: string().required(),
  departureRoadTeamDate: string().required(),
});

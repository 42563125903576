import React, { Ref, forwardRef } from 'react';
import { isFirefox } from 'react-device-detect';

// Components styled
import { CheckBoxStyled } from './styles';

// Types
import { CheckBoxProps } from './types';

export const CheckBox = forwardRef<HTMLInputElement, CheckBoxProps>(
  ({ ...props }: CheckBoxProps, ref: Ref<HTMLInputElement>) => (
    <CheckBoxStyled
      ref={ref}
      isFirefox={isFirefox}
      type={'checkbox'}
      {...props}
    />
  )
);

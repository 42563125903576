import React from 'react';
import { useDispatch } from 'react-redux';

// Types
import { ShowOTProps } from './types';
import { variant } from '../../SpecialTag/types';
import { OrderState, OrderType } from '../../../../types/orders';
// Styles Components
import {
  ContainerContent,
  ContainerDescription,
  ContainerHeader,
  ContainerOT,
  InputDescription,
  InputPKEnd,
  InputPKInit,
  PkInputsContainer,
  StyledSpecialTag,
} from './styles';

// Redux
import { openModal } from '../../../../state/actions/ui/modal';
import { modalIds } from '../../../modals/SuperModal/types';
import { TitleOrder } from '../MainStyledComponents';

export const SimpleWorkOrder = ({
  name,
  pkInit,
  pkEnd,
  state,
  indexExpedition,
  revalidatorFunction,
  validation,
  registerEndDate,
  orderId,
  parentOrderState,
  orderDescription,
  actionDescription,
}: ShowOTProps): JSX.Element => {
  const dispatch = useDispatch();
  const openModalChangeState = () => {
    parentOrderState === OrderState.Open &&
      dispatch(
        openModal(modalIds.CHANGE_STATE_MODAL, {
          title:
            "Vols canviar l'estat de treball i tot allò que estigui associat?",
          titleSaveModal:
            'Estàs segur que vols tancar tot el que estigui associat a aquesta actuació?',
          indexExpedition,
          revalidatorFunction,
          orderType: OrderType.Action,
          registerEndDate,
          currentState: state,
          orderId: orderId,
        })
      );
  };
  return (
    <ContainerOT>
      <ContainerHeader>
        <TitleOrder>{name}</TitleOrder>
        {validation && (
          <StyledSpecialTag
            variant={state as variant}
            onClick={openModalChangeState}
          />
        )}
      </ContainerHeader>
      <ContainerContent>
        <PkInputsContainer>
          <InputPKInit
            label="Pk Inicial"
            typeInput="normal"
            disabled={true}
            defaultValue={pkInit}
          />
          <InputPKEnd
            label="Pk Final"
            typeInput="normal"
            disabled={true}
            defaultValue={pkEnd}
          />
        </PkInputsContainer>
        <ContainerDescription>
          <InputDescription
            label={"Descripció d'OT"}
            disabled={true}
            defaultValue={orderDescription}
          />
          <InputDescription
            label={'Descripció actuació - parte treball operari'}
            disabled={true}
            defaultValue={actionDescription}
          />
        </ContainerDescription>
      </ContainerContent>
    </ContainerOT>
  );
};

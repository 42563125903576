import { Navigate } from 'react-router-dom';
import React, { ReactNode, useContext } from 'react';
import { AuthContext } from '../hooks/useAuth';
import { Role } from '../types/auth';

export function RolesAuthRoute({
  children,
  roles,
}: {
  children: ReactNode;
  roles: Array<Role>;
}) {
  const userRoles: Array<Role> | undefined = useContext(AuthContext)?.user?.user
    ?.roles as Array<Role> | undefined;

  const canAccess = userRoles?.some((userRole) => roles.includes(userRole));

  if (canAccess) return <>{children}</>;

  return <Navigate to="/login" replace />;
}

import { redirect } from 'react-router-dom';

// Constants
import { formNaming } from '../../../../../../components/forms/NewCommunicationForm/constants';

// GraphQL
import { client } from '../../../../../../gql/client';
import { CREATE_NEW_COMMUNICATION } from '../../../../../../gql/mutations/communication';
import { CREATE_NEW_ORDER } from '../../../../../../gql/mutations/orders';
import { CREATE_NEW_GENERIC_ORDER } from '../../../../../../gql/mutations/generic-order';
import { GET_ORDER_BY_ID } from '../../../../../../gql/queries/orders/orders';
import {
  GET_CAPITOL_BY_ID,
  GET_CLASSIFICATION_BY_ID_OR_COTIC,
  GET_SUB_CAPITOL_BY_ID,
} from '../../../../../../gql/queries/orders/predefinedData';
import { NEW_COMMUNICATION } from '../../../../../../gql/fragments/communications';
import { NEW_GENERIC_ORDER } from '../../../../../../gql/fragments/generic-order';
import { NEW_CAMPAIGN } from '../../../../../../gql/fragments/campaign';
import { CREATE_NEW_CAMPAIGN } from '../../../../../../gql/mutations/campaign';

// State
import { indexTabFormReset } from '../../../../../../state/actions/ui/tabs';
import { deselectedRowModalTable } from '../../../../../../state/actions/ui/selectRowTableModal';

// Types
import { OrderState, OrderType } from '../../../../../../types/orders';
import { SaveDataParams } from './types';
import {
  tableIds,
  takeBase,
} from '../../../../../../components/display/tables/types';

//Utils
import { decompressSessionStorage } from '../../../../../../utils/sessionStorage';
import { tabsType } from '../../../../../../state/reducers/ui/tabs';
import { setCursor } from '../../../../../../state/actions/ui/cursorTables';
import { truncateArrayAfterNull } from '../../../../../../utils/mergeArrays';
import { getCursor } from '../../../../../../state/selectors/ui/tables';
import { NEW_ORDER } from '../../../../../../gql/fragments/orders';

export const saveData = async ({
  dataFormRaw,
  tabSelected,
  storedDataOTSelected,
  store,
}: SaveDataParams) => {
  const dispatch = store.dispatch;
  if (dataFormRaw) {
    const cursorDuplicateCommunication = getCursor(
      tableIds.DUPLICATE_COMMUNICATION
    )(store.getState());
    const cursorSearchAndCreateCommunication = getCursor(
      tableIds.SEARCH_AND_CREATE_HISTORICAL_COMMUNICATIONS
    )(store.getState());
    const cursorWorkOrdersCommunication = getCursor(
      tableIds.WORKORDER_COMMUNICATIONS
    )(store.getState());
    const cursorCampaign = getCursor(tableIds.CAMPAIGNS)(store.getState());
    const cursorCampaignModal = getCursor(tableIds.CAMPAIGN_MODAL)(
      store.getState()
    );
    const cursorGenericOrder = getCursor(tableIds.WORKORDER_GENERAL_VIEW)(
      store.getState()
    );
    const cursorGenericOrderDuplicate = getCursor(
      tableIds.DUPLICATE_GENERIC_ORDER
    )(store.getState());
    const cursorGenericOrderSearchAndCreate = getCursor(
      tableIds.SEARCH_AND_CREATE_HISTORICAL_WORKORDERS
    )(store.getState());
    const cursorGenericOrderModal = getCursor(tableIds.GENERIC_ORDER_MODAL)(
      store.getState()
    );

    const creationDate = new Date().toISOString();

    let orderCommunicationInput, dataOrderParent;
    const dataForm = JSON.parse(decompressSessionStorage(dataFormRaw));
    const pkInitArray = dataForm.pkInit
      .replace(/ /g, '')
      .replace(/ /g, '')
      .split('+');
    const pkEndArray = dataForm.pkEnd
      .replace(/ /g, '')
      .replace(/ /g, '')
      .split('+');
    /// Step 1: Generate data Communication
    if (tabSelected === 0) {
      orderCommunicationInput = {
        type: OrderType.Communication,
        state: OrderState.Open,
        creationDate,
        registerInitDate: dataForm.registerInitDate,
        specialTag: dataForm?.specialTag,
        description: dataForm.description,
        classification: dataForm.classification,
        capitol: dataForm.capitol,
        subCapitol: dataForm.subCapitol,
      };
    }
    if (tabSelected > 0) {
      const {
        data: { order },
      } = await client.query({
        query: GET_ORDER_BY_ID,
        variables: {
          id: storedDataOTSelected?.id,
        },
      });

      const [
        {
          data: { capitol },
        },
        {
          data: { subCapitol },
        },
        {
          data: { classification },
        },
      ] = await Promise.all([
        client.query({
          query: GET_CAPITOL_BY_ID,
          variables: {
            id: order.capitolIDs[0],
          },
        }),
        client.query({
          query: GET_SUB_CAPITOL_BY_ID,
          variables: {
            id: order.subCapitolIDs[0],
          },
        }),
        client.query({
          query: GET_CLASSIFICATION_BY_ID_OR_COTIC,
          variables: {
            id: order.classificationIDs[0],
          },
        }),
      ]);

      orderCommunicationInput = {
        type: OrderType.Communication,
        state: OrderState.Open,
        creationDate,
        registerInitDate: dataForm.registerInitDate,
        specialTag: dataForm?.specialTag,
        description: dataForm.description,
        classification: classification.id,
        capitol: capitol.id,
        subCapitol: subCapitol.id,
        currentExpeditionIndex: order.indexExpedition,
        parentExpeditionOrderID: order.id,
      };
    }
    /// Step 2: Generate campaign or generic order
    if (tabSelected === 0) {
      //DOC: create order parent
      const orderParentInput = {
        type:
          orderCommunicationInput?.specialTag === OrderType.Campaign
            ? OrderType.Campaign
            : OrderType.Generic,
        state: OrderState.Open,
        creationDate,
        registerInitDate: orderCommunicationInput?.registerInitDate,
        specialTag: orderCommunicationInput?.specialTag,
        description: orderCommunicationInput?.description,
        classification: orderCommunicationInput?.classification,
        capitol: orderCommunicationInput?.capitol,
        subCapitol: orderCommunicationInput?.subCapitol,
      };
      dataOrderParent = await client.mutate({
        mutation: CREATE_NEW_ORDER,
        variables: {
          input: orderParentInput,
        },
      });

      if (
        orderCommunicationInput?.specialTag &&
        orderCommunicationInput?.specialTag === OrderType.Campaign
      ) {
        //DOC: create campaign parent
        await client.mutate({
          mutation: CREATE_NEW_CAMPAIGN,
          variables: {
            input: {
              name: `Campanya relacionada amb una comunicacio`,
              concessionId: dataForm.concession,
              roadId: dataForm.road,
              track: dataForm.track,
              lane: dataForm.lane,
              direction: dataForm?.direction,
              margin: dataForm?.margin,
              pkInitKm: Number(pkInitArray[0]),
              pkInitMeter: Number(pkInitArray[1]),
              pkEndKm: Number(pkEndArray[0]),
              pkEndMeter: Number(pkEndArray[1]),
              orderId: dataOrderParent.data.createOrder.id,
              creationDate,
              registerInitDate: dataForm.registerInitDate,
            },
          },
          update(cache, { data: { createCampaign } }) {
            cache.modify({
              fields: {
                campaigns(variables = {}) {
                  const newCampaignRef = cache.writeFragment({
                    data: createCampaign,
                    fragment: NEW_CAMPAIGN,
                  });

                  const truncatedArray = truncateArrayAfterNull(
                    variables.campaigns
                  );

                  const newCampaignArray = [newCampaignRef, ...truncatedArray];

                  newCampaignArray.splice(-2, 1);

                  const setCursorDataCampaigns = {
                    ...cursorCampaign,
                    take: takeBase,
                    cursor: '',
                    orderBy: 'desc',
                  };
                  const setCursorDataCampaignsModal = {
                    ...cursorCampaignModal,
                    take: takeBase,
                    cursor: '',
                    orderBy: 'desc',
                  };

                  dispatch(
                    setCursor(tableIds.CAMPAIGNS, setCursorDataCampaigns)
                  );
                  dispatch(
                    setCursor(
                      tableIds.CAMPAIGN_MODAL,
                      setCursorDataCampaignsModal
                    )
                  );

                  return {
                    ...variables,
                    campaigns: newCampaignArray,
                    totalCount: variables.totalCount + 1,
                    pageInfo: {},
                  };
                },
              },
            });
          },
        });
      } else {
        //DOC: create generic order parent
        await client.mutate({
          mutation: CREATE_NEW_GENERIC_ORDER,
          variables: {
            input: {
              concessionId: dataForm.concession,
              roadId: dataForm.road,
              track: dataForm.track,
              lane: dataForm.lane,
              direction: dataForm?.direction,
              margin: dataForm?.margin,
              pkInitKm: Number(pkInitArray[0]),
              pkInitMeter: Number(pkInitArray[1]),
              pkEndKm: Number(pkEndArray[0]),
              pkEndMeter: Number(pkEndArray[1]),
              orderId: dataOrderParent.data.createOrder.id,
              creationDate,
              registerInitDate: dataForm.registerInitDate,
            },
          },
          update(cache, { data: { createGenericOrder } }) {
            cache.modify({
              fields: {
                genericOrders(variables = {}) {
                  const newGenericOrderRef = cache.writeFragment({
                    data: createGenericOrder,
                    fragment: NEW_GENERIC_ORDER,
                  });

                  const truncatedArray = truncateArrayAfterNull(
                    variables.genericOrders
                  );

                  const newGenericOrderArray = [
                    newGenericOrderRef,
                    ...truncatedArray,
                  ];

                  newGenericOrderArray.splice(-2, 1);

                  const setCursorDataGenericOrder = {
                    ...cursorGenericOrder,
                    take: takeBase,
                    cursor: '',
                    orderBy: 'desc',
                  };
                  const setCursorDataDuplicateGenericOrder = {
                    ...cursorGenericOrderDuplicate,
                    take: takeBase,
                    cursor: '',
                    orderBy: 'desc',
                  };
                  const setCursorDataSearchAndCreateGenericOrder = {
                    ...cursorGenericOrderSearchAndCreate,
                    take: takeBase,
                    cursor: '',
                    orderBy: 'desc',
                  };
                  const setCursorDataGenericOrderModal = {
                    ...cursorGenericOrderModal,
                    take: takeBase,
                    cursor: '',
                    orderBy: 'desc',
                  };

                  dispatch(
                    setCursor(
                      tableIds.WORKORDER_GENERAL_VIEW,
                      setCursorDataGenericOrder
                    )
                  );
                  dispatch(
                    setCursor(
                      tableIds.DUPLICATE_GENERIC_ORDER,
                      setCursorDataDuplicateGenericOrder
                    )
                  );
                  dispatch(
                    setCursor(
                      tableIds.SEARCH_AND_CREATE_HISTORICAL_WORKORDERS,
                      setCursorDataSearchAndCreateGenericOrder
                    )
                  );
                  dispatch(
                    setCursor(
                      tableIds.GENERIC_ORDER_MODAL,
                      setCursorDataGenericOrderModal
                    )
                  );

                  return {
                    ...variables,
                    genericOrders: newGenericOrderArray,
                    totalCount: variables.totalCount + 1,
                    pageInfo: {},
                  };
                },
              },
            });
          },
        });
      }

      // Refetch
      client.query({
        fetchPolicy: 'network-only',
        query: GET_ORDER_BY_ID,
        variables: { id: dataOrderParent.data.createOrder.id },
      });
    }

    if (dataOrderParent?.data?.createOrder?.id) {
      orderCommunicationInput = {
        ...orderCommunicationInput,
        currentExpeditionIndex:
          dataOrderParent.data.createOrder.indexExpedition,
        parentExpeditionOrderID: dataOrderParent.data.createOrder.id,
      };
    }

    /// Step 3: Generate communication
    //DOC: Create order communication child
    const dataOrderCommunication = await client.mutate({
      mutation: CREATE_NEW_ORDER,
      variables: {
        input: {
          ...orderCommunicationInput,
          specialTag: null,
        },
      },

      update(cache, { data: { createOrder } }) {
        cache.modify({
          id: `Order:${storedDataOTSelected.id}`,
          fields: {
            childExpeditionOrderIDs(variables = [], { readField }) {
              const newOrderRef = cache.writeFragment({
                data: createOrder,
                fragment: NEW_ORDER,
              });
              const newId = readField('id', newOrderRef);
              return [...variables, newId];
            },
          },
        });
      },
    });

    //DOC: Create communication child
    await client.mutate({
      mutation: CREATE_NEW_COMMUNICATION,
      variables: {
        input: {
          sendStatus: dataForm.sendStatus,
          interlocutorGroupId: dataForm.groupInter,
          mediaOfCommunicationId: dataForm.media,
          concessionId: dataForm.concession,
          roadId: dataForm.road,
          track: dataForm.track,
          lane: dataForm.lane,
          direction: dataForm?.direction,
          margin: dataForm?.margin,
          pkInitKm: Number(pkInitArray[0]),
          pkInitMeter: Number(pkInitArray[1]),
          pkEndKm: Number(pkEndArray[0]),
          pkEndMeter: Number(pkEndArray[1]),
          orderId: dataOrderCommunication.data.createOrder.id,
          creationDate,
          registerInitDate: dataForm.registerInitDate,
        },
      },

      update(cache, { data: { createCommunication } }) {
        cache.modify({
          fields: {
            // TODO: REPLICATE IN ALL SAVE DATA
            communications(variables = {}) {
              const newCommunicationRef = cache.writeFragment({
                data: createCommunication,
                fragment: NEW_COMMUNICATION,
              });

              const truncatedArray = truncateArrayAfterNull(
                variables.communications
              );

              const newCommunicationArray = [
                newCommunicationRef,
                ...truncatedArray,
              ];

              newCommunicationArray.splice(-2, 1);

              const setCursorDataDuplicateCommunications = {
                ...cursorDuplicateCommunication,
                take: takeBase,
                cursor: '',
                orderBy: 'desc',
              };
              const setCursorDataWorkOrderCommunication = {
                ...cursorWorkOrdersCommunication,
                take: takeBase,
                cursor: '',
                orderBy: 'desc',
              };
              const setCursorDataSearchAndCreateCommunication = {
                ...cursorSearchAndCreateCommunication,
                take: takeBase,
                cursor: '',
                orderBy: 'desc',
              };

              dispatch(
                setCursor(
                  tableIds.DUPLICATE_COMMUNICATION,
                  setCursorDataDuplicateCommunications
                )
              );
              dispatch(
                setCursor(
                  tableIds.WORKORDER_COMMUNICATIONS,
                  setCursorDataWorkOrderCommunication
                )
              );
              dispatch(
                setCursor(
                  tableIds.SEARCH_AND_CREATE_HISTORICAL_COMMUNICATIONS,
                  setCursorDataSearchAndCreateCommunication
                )
              );

              return {
                ...variables,
                communications: newCommunicationArray,
                totalCount: variables.totalCount + 1,
                pageInfo: {},
              };
            },
          },
        });
      },
    });

    // Refetch
    client.query({
      fetchPolicy: 'network-only',
      query: GET_ORDER_BY_ID,
      variables: { id: dataOrderCommunication.data.createOrder.id },
    });
  }

  // Clean the form local storage as it was just saved
  sessionStorage.removeItem(formNaming.NEW_COMMUNICATION_FORM);
  dispatch(deselectedRowModalTable(formNaming.NEW_COMMUNICATION_FORM));
  dispatch(indexTabFormReset(tabsType.COMMUNICATION));
  return redirect('..');
};

import React, { useState, useCallback, useMemo, useEffect } from 'react';

import {
  useReactTable,
  createColumnHelper,
  getCoreRowModel,
  flexRender,
  Row,
  getFilteredRowModel,
} from '@tanstack/react-table';

import { Controller } from 'react-hook-form';

// Styled Components
import {
  Container,
  SubContainer,
  SubContainerRight,
  ContainerTables,
  ContainerSearchBar,
  Table,
  Thead,
  Th,
  ThRightTable,
  Tr,
  TrBody,
  Td,
  TableRight,
  Tbody,
  TbodyRight,
  Img,
  Input,
  TdRightTable,
  StyledInput,
  DropDownInputStyled,
} from './styles';

// Components
import { Typography } from '../../../Typography';
import SearchBar from '../../../../inputs/SearchBar';

//Icons
import ArrowIcon from '../../../../../assets/icons/grey-right-arrow-icon.svg';
import RemoveIcon from '../../../../../assets/icons/remove-file-icon.svg';

// Types
import {
  dataTable,
  tableProps,
  columnNames,
  onChangeInputTypes,
  variantThRight,
  variantThLeft,
} from './types';

// Mocks
import { mockData } from './mocks';
import { OrderState } from '../../../../../types/orders';

export const AnimalSelectionTable = ({
  data,
  animalStatus,
  defaultValues = [],
  setValue,
  register,
  onAdd,
  onUpdate,
  onDelete,
  control,
  ...props
}: tableProps): JSX.Element => {
  const [selectedRows, setSelectedRows] = useState<Row<dataTable>[]>([]);
  const [dataTableRight, setDataTableRight] =
    useState<dataTable[]>(defaultValues);

  const dataTable = data?.animals ? data.animals : mockData;
  const [animalValue, setAnimalValue] = useState<string | number>('');

  const options =
    animalStatus?.map((animal) => ({
      label: animal.description,
      value: animal.id,
    })) || [];

  const columnHelper = createColumnHelper<dataTable>();

  const handleClickRow = useCallback(
    (row: Row<dataTable>) => {
      const newData: dataTable = {
        animalId: row.original.animalId,
        name: row.original.name,
        id: row.original.id,
        statusId: options[0].value,
      };
      const id = row.original.id;
      const setValues = () => {
        setValue(`animalsTable[${id}].animalId`, row.original.animalId);
        setValue(`animalsTable[${id}].name`, row.original.name);
        setValue(`animalsTable[${id}].ut`, '');
        setValue(`animalsTable[${id}].statusId`, options[0].value);
        setValue(`animalsTable[${id}].id`, row.original.id);
      };
      const duplicate =
        selectedRows.some(
          (element) => element.original.id === row.original.id
        ) || dataTableRight.some((element) => element.id === row.original.id);
      !duplicate && setValues();
      !duplicate && setDataTableRight([...dataTableRight, newData]);
      !duplicate && setSelectedRows([...selectedRows, row]);
      !duplicate &&
        onAdd &&
        onAdd(row.original.id as string, row.original.statusId as string);
    },
    [selectedRows, dataTableRight]
  );

  const removeRow = useCallback(
    (row: Row<dataTable>) => {
      setDataTableRight((element) =>
        element.filter((item) => item.id !== row.original.id)
      );
      setSelectedRows((element) =>
        element.filter((item) => item.original.id !== row.original.id)
      );

      setValue(`animalsTable[${row.original.id}]`, undefined);
      onDelete &&
        onDelete(
          row.original.id as string,
          row.original.animalUnitId as string
        );
    },
    [dataTableRight]
  );

  const columnsLeft = useMemo(
    () => [
      columnHelper.accessor(columnNames.animalId.id, {
        header: () => (
          <Typography size="S" colorText="white">
            {columnNames.animalId.label}
          </Typography>
        ),
        cell: (info) => (
          <StyledInput value={info.getValue() as string} readOnly />
        ),
      }),
      columnHelper.accessor(columnNames.name.id, {
        header: () => (
          <Typography colorText="white" size="S">
            {columnNames.name.label}
          </Typography>
        ),
        cell: (info) => (
          <StyledInput value={info.getValue() as string} readOnly />
        ),
        filterFn: 'includesString',
      }),
      columnHelper.accessor('add', {
        header: () => (
          <Typography colorText="white" size="S">
            +
          </Typography>
        ),
        cell: ({ row }) => (
          <Img
            src={ArrowIcon}
            onClick={() =>
              data?.parentExpeditionOrderStatus !== OrderState.Annulled &&
              data?.parentExpeditionOrderStatus !== OrderState.End &&
              data?.state !== OrderState.Annulled &&
              handleClickRow(row)
            }
          />
        ),
      }),
    ],
    [selectedRows]
  );

  const columnsRight = useMemo(
    () => [
      columnHelper.accessor(columnNames.animalId.id, {
        header: () => (
          <Typography size="S" colorText="white">
            {columnNames.animalId.label}
          </Typography>
        ),
        cell: (info) => (
          <Input
            {...register(`animalsTable[${info.row.original.id}].animalId`)}
            disabled={true}
          />
        ),
      }),
      columnHelper.accessor(columnNames.name.id, {
        header: () => (
          <Typography colorText="white" size="S">
            {columnNames.name.label}
          </Typography>
        ),
        cell: (info) => (
          <Input
            {...register(`animalsTable[${info.row.original.id}].name`)}
            disabled={true}
          />
        ),
      }),
      columnHelper.accessor(columnNames.status.id, {
        header: () => (
          <Typography colorText="white" size="S">
            {columnNames.status.label}
          </Typography>
        ),
        cell: (info) => (
          <Controller
            control={control}
            name={`animalsTable[${info.row.original.id}].statusId`}
            render={({ field: { onChange } }) => (
              <DropDownInputStyled
                typeDropDown={'Table'}
                defaultValue={
                  info.row.original.statusId ||
                  defaultValues[info.row.id as unknown as number].statusId
                }
                placeholder="Selecciona"
                onChangeSelected={(option) => {
                  const copy = [...dataTableRight];
                  const itemToChange = copy.findIndex(
                    (item) => item.id === info.row.original.id
                  );
                  copy[itemToChange].statusId = option?.value;
                  setDataTableRight(copy);
                  onUpdate &&
                    onUpdate(
                      animalValue,
                      info.row.original.id as string,
                      info.row.original.animalUnitId as string,
                      `animalsTable[${info.row.original.id}].ut` as string,
                      info.row.original.statusId as string
                    );
                  return onChange(option?.value ?? '');
                }}
                options={options}
                inputSize={'S'}
              />
            )}
          />
        ),
      }),
      columnHelper.accessor(columnNames.ut.id, {
        header: () => (
          <Typography size="S" colorText="white">
            {columnNames.ut.label}
          </Typography>
        ),
        cell: (info) => (
          <Input
            {...register(`animalsTable[${info.row.original.id}].ut`)}
            disabled={
              data?.parentExpeditionOrderStatus === OrderState.Annulled ||
              data?.parentExpeditionOrderStatus === OrderState.End ||
              data?.state === OrderState.Annulled
            }
            onChange={(event) => {
              setAnimalValue(event.target.value);
              return (
                onUpdate &&
                onUpdate(
                  event.target.value,
                  info.row.original.id as string,
                  info.row.original.animalUnitId as string,
                  `animalsTable[${info.row.original.id}].ut` as string,
                  info.row.original.statusId as string
                )
              );
            }}
            type="number"
            onKeyDown={(e) => {
              /[+*/.,\\-]/.test(e.key) && e.preventDefault();
            }}
          />
        ),
      }),
      columnHelper.accessor('remove', {
        header: () => (
          <Typography colorText="white" size="S">
            -
          </Typography>
        ),
        cell: ({ row }) => (
          <Img
            src={RemoveIcon}
            onClick={() =>
              data?.parentExpeditionOrderStatus !== OrderState.Annulled &&
              data?.parentExpeditionOrderStatus !== OrderState.End &&
              data?.state !== OrderState.Annulled &&
              removeRow(row)
            }
          />
        ),
      }),
    ],
    [dataTableRight]
  );

  const table = useReactTable({
    data: dataTable,
    columns: columnsLeft,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });

  const tableRight = useReactTable({
    data: dataTableRight,
    columns: columnsRight,
    getCoreRowModel: getCoreRowModel(),
  });

  useEffect(() => {
    const allLeftRows = table.getCoreRowModel().rows;
    const similarTables = allLeftRows.filter((row) => {
      const code = row.original.animalId;
      const matchingItem = dataTableRight.find((item) => item.id === code);
      if (matchingItem?.animalUnitId) {
        row.original.animalUnitId = matchingItem.animalUnitId;
      }

      return !!matchingItem;
    });

    setSelectedRows(similarTables);
  }, []);

  const onChangeInput = useCallback<onChangeInputTypes>((value) => {
    const animal = table.getColumn(columnNames.name.id);
    animal?.setFilterValue(value);
  }, []);

  return (
    <Container {...props}>
      <ContainerSearchBar>
        <SearchBar
          variant="form"
          onChangeInput={(e) => onChangeInput(e.target.value)}
        />
      </ContainerSearchBar>
      <ContainerTables>
        <SubContainer>
          <Table>
            <Thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <Tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <Th variant={header.id as variantThLeft} key={header.id}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </Th>
                  ))}
                </Tr>
              ))}
            </Thead>
            <Tbody>
              {table.getRowModel().rows.map((row) => (
                <TrBody key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <Td
                      variant={cell.id.slice(2) as variantThLeft}
                      key={cell.id}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </Td>
                  ))}
                </TrBody>
              ))}
            </Tbody>
          </Table>
        </SubContainer>
        <SubContainerRight>
          <TableRight>
            <Thead>
              {tableRight.getHeaderGroups().map((headerGroup) => (
                <Tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <ThRightTable
                      variant={header.id as variantThRight}
                      key={header.id}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </ThRightTable>
                  ))}
                </Tr>
              ))}
            </Thead>
            <TbodyRight>
              {tableRight.getRowModel().rows.map((row) => (
                <TrBody key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <TdRightTable
                      variant={cell.id.slice(2) as variantThRight}
                      key={cell.id}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TdRightTable>
                  ))}
                </TrBody>
              ))}
            </TbodyRight>
          </TableRight>
        </SubContainerRight>
      </ContainerTables>
    </Container>
  );
};

import React, { useState, useRef, useCallback, useEffect } from 'react';
import { format } from 'date-fns';
import { ca } from 'date-fns/locale';
import { useClickAway } from 'react-use';

// Types
import {
  ContainerProps,
  onChangeType,
  handleClickType,
  onBlurType,
} from './types';

// Styled Components
import {
  Container,
  SmallContainer,
  Label,
  InputDate,
  InputTime,
  Calendar,
  ContainerInputs,
  Span,
  InputNumberHours,
  InputNumberMinutes,
  ContainerCalendar,
} from './styles';
import { ErrorMessage } from '../InputText/styles';

export const DateTime = ({
  isDisabled,
  label,
  labelHour,
  onlyHour,
  dateInputName = 'date',
  hourInputName = 'hours',
  minutesInputName = 'minutes',
  onChangeDate,
  initialDate,
  error,
  borderError,
  value,
  ...props
}: ContainerProps) => {
  const today = new Date();

  const [selected, setSelected] = useState<Date | undefined>(
    initialDate ? new Date(initialDate) : undefined
  );

  const [isZero, setIsZero] = useState(false);

  const [valueHours, setHours] = useState<string>(
    selected
      ? selected.getHours().toString().length === 1
        ? '0' + selected.getHours().toString()
        : selected.getHours().toString()
      : ''
  );
  const [valueMinutes, setMinutes] = useState<string>(
    selected
      ? selected.getMinutes().toString().length === 1
        ? '0' + selected.getMinutes().toString()
        : selected.getMinutes().toString()
      : ''
  );
  const [displayCalendar, setDisplayCalendar] = useState(false);
  const [isFocus, setIsFocus] = useState(false);

  const formatValueDate = value
    ? format(new Date(value as string), 'dd/MM/yyyy')
    : undefined;

  const formHours = value ? format(new Date(value as string), 'HH') : undefined;
  const formMinutes = value
    ? format(new Date(value as string), 'mm')
    : undefined;

  const selectedDate = selected && format(selected, 'dd/MM/yyyy');

  const maxLength = 2;

  const inputRef = useRef(null);
  const minuteRef = useRef<HTMLInputElement>(null);
  const hourRef = useRef<HTMLInputElement>(null);

  // Use this function to handle the change of the date with onChangeDate by sending a complete Date object
  const handleDateChange = useCallback(
    (dateCalendar?: Date) => {
      const valueHoursParsed = Number(valueHours);
      const valueMinutesParsed = Number(valueMinutes);
      if (selected instanceof Date) {
        if (dateCalendar) {
          if (valueHoursParsed === 0) {
            dateCalendar.setHours(0);
          } else {
            dateCalendar.setHours(valueHoursParsed || selected.getHours());
          }

          if (valueMinutesParsed === 0) {
            dateCalendar.setMinutes(0);
          } else {
            dateCalendar.setMinutes(
              valueMinutesParsed || selected.getMinutes()
            );
          }
          setSelected(dateCalendar);
          onChangeDate?.(dateCalendar.toISOString());
        } else {
          if (valueHoursParsed === 0) {
            selected.setHours(0);
          } else {
            selected.setHours(valueHoursParsed || selected.getHours());
          }

          if (valueMinutesParsed === 0) {
            selected.setMinutes(0);
          } else {
            selected.setMinutes(valueMinutesParsed || selected.getMinutes());
          }
          setSelected(selected);
          onChangeDate?.(selected.toISOString());
        }
      } else if (dateCalendar) {
        dateCalendar.setHours(valueHoursParsed);
        dateCalendar.setMinutes(valueMinutesParsed);
        setSelected(dateCalendar);
        onChangeDate?.(dateCalendar.toISOString());
      } else {
        onChangeDate?.();
      }
    },
    [valueHours, valueMinutes, selected]
  );

  useEffect(() => {
    handleDateChange();
  }, [selected, valueHours, valueMinutes]);

  const handleClickClose = useCallback(() => setDisplayCalendar(false), []);
  const handleClick = useCallback(
    () => setDisplayCalendar(!displayCalendar),
    []
  );
  const handleClickDayCalendar = useCallback(() => {
    setDisplayCalendar(false);
    hourRef.current?.focus();
    hourRef.current?.select();
  }, []);

  // Function to set the hour in InputTime, prevent values over 23

  const onChangeHours = useCallback<onChangeType>(
    (e) => {
      let newHour = e.target.value;

      newHour === '0' && setIsZero(false);

      if (parseInt(newHour) < 24) {
        if (
          (parseInt(valueHours) === 0 && !isZero) ||
          (parseInt(newHour) > 2 && parseInt(newHour) < 24)
        ) {
          e.target.blur();
          minuteRef.current?.focus();
          minuteRef.current?.select();
        }

        if (newHour.length < maxLength) {
          newHour = newHour.padStart(2, '0');
        } else {
          newHour = newHour.slice(1);
        }

        setHours(newHour);
      }
    },
    [valueHours, isZero]
  );

  // Function to set the minutes in InputTime, prevent values over 59
  const onChangeMinutes = useCallback<onChangeType>((e) => {
    let newMinutes = e.target.value;

    if (parseInt(newMinutes) < 60) {
      if (newMinutes.length < maxLength) {
        newMinutes = newMinutes.padStart(2, '0');
      } else {
        newMinutes = newMinutes.slice(1);
      }

      setMinutes(newMinutes);
    }
  }, []);

  const handleBlurHours = useCallback<onBlurType>(
    (e) => {
      valueHours === '00' && setIsZero(true);
      e.target.value === '' && setHours('00');
    },
    [valueHours, isZero]
  );

  const handleBlurMinutes = useCallback<onBlurType>((e) => {
    e.target.value === '' && setMinutes('00');
  }, []);

  //Function to select numbers in InputNumberHours and InputNumberMinutes

  const handleClickInput = useCallback<handleClickType>((e) => {
    const input = e.target as HTMLInputElement;
    input.select();
  }, []);

  const onCloseCalendar = useCallback(() => {
    setDisplayCalendar(false);
  }, []);
  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === ' ') {
      event.preventDefault();

      setDisplayCalendar(!displayCalendar);
    }
  };

  useClickAway(inputRef, () => setDisplayCalendar(false));
  return (
    <Container ref={inputRef} {...props}>
      <ContainerInputs className="container-inputs">
        {!onlyHour && (
          <>
            <SmallContainer>
              {label && (
                <Label component="label" size="S">
                  {label}
                </Label>
              )}
              <InputDate
                name={dateInputName}
                type="text"
                readOnly
                placeholder="00/00/0000"
                value={formatValueDate ?? (selectedDate || '')}
                onClick={handleClick}
                disabled={isDisabled}
                borderError={borderError}
                onKeyDown={handleKeyDown}
                className={'input-date'}
              />
            </SmallContainer>
            <ContainerCalendar>
              {displayCalendar && (
                <Calendar
                  toDate={today}
                  mode="single"
                  locale={ca}
                  selected={selected}
                  onSelect={setSelected}
                  numberOfMonths={1}
                  onDayClick={handleClickDayCalendar}
                />
              )}
            </ContainerCalendar>
          </>
        )}

        <SmallContainer>
          {labelHour && (
            <Label component="label" size="S">
              {labelHour}
            </Label>
          )}
          <InputTime
            className="input-hour"
            onClick={handleClickClose}
            aria-disabled={isDisabled}
            borderError={borderError}
            isFocus={isFocus}
            onFocus={() => setIsFocus(true)}
            onBlur={() => setIsFocus(false)}
          >
            <InputNumberHours
              name={hourInputName}
              ref={hourRef}
              placeholder="00"
              type={'number'}
              maxLength={maxLength}
              max={23}
              min={0}
              onChange={onChangeHours}
              onClick={handleClickInput}
              onKeyDown={(e) =>
                (e.key === 'Backspace' || e.key === 'Delete') && setHours('')
              }
              onFocus={() => setDisplayCalendar(false)}
              onBlur={handleBlurHours}
              disabled={isDisabled}
              value={formHours ?? valueHours}
              tabIndex={0}
              className={'input-number'}
            />
            <Span>:</Span>
            <InputNumberMinutes
              name={minutesInputName}
              ref={minuteRef}
              placeholder="00"
              type={'number'}
              maxLength={maxLength}
              max={59}
              min={0}
              onChange={onChangeMinutes}
              onClick={handleClickInput}
              onKeyDown={(e) =>
                (e.key === 'Backspace' || e.key === 'Delete') && setMinutes('')
              }
              onBlur={handleBlurMinutes}
              disabled={isDisabled}
              value={formMinutes ?? valueMinutes}
              tabIndex={0}
              className={'input-number'}
            />
          </InputTime>
        </SmallContainer>
      </ContainerInputs>
      <ContainerCalendar>
        {displayCalendar && (
          <Calendar
            toDate={today}
            mode="single"
            locale={ca}
            selected={selected}
            onSelect={handleDateChange}
            numberOfMonths={1}
            onDayClick={handleClickDayCalendar}
            onClose={onCloseCalendar}
          />
        )}
      </ContainerCalendar>
      {error && (
        <ErrorMessage colorText={'lightCoralRed'} size={'XXS'}>
          {error}
        </ErrorMessage>
      )}
    </Container>
  );
};

import styled from 'styled-components';

// Colors
import { colors } from '../../../../../styles/colors';

// Spaces
import {
  breakPoints,
  percentBaseResponsive,
  spaces,
} from '../../../../../styles/spaces';

// Fonts
import { fontSize } from '../../../../../styles/fonts';

// Components
import { DateTime } from '../../../../inputs/DateTime';

// Types
import { TrHeaderProps, ThInputProps } from './types';
import { Typography } from '../../../Typography';

export const Container = styled.div`
  width: 100%;
`;

export const Table = styled.table`
  width: 100%;
`;

export const TrHeader = styled.tr<TrHeaderProps>`
  background-color: ${colors.greyDark};
  height: ${spaces.l}rem;
  ${(props) =>
    props.empty && {
      height: 0,
    }};
  & th {
    width: calc(100% / 6);
  }
`;

export const ThStyled = styled.th<ThInputProps>`
  max-width: 25rem;
  height: ${spaces.xl};
  padding: ${spaces.xxxs}rem ${spaces.xxs}rem;
  font-size: ${fontSize.S};
  background-color: ${colors.white};
  vertical-align: middle;

  @media (max-width: ${breakPoints.landscapeTablet *
    percentBaseResponsive}rem) {
    font-size: ${fontSize.XS};
  }
`;

export const Th = styled.th`
  vertical-align: middle;
`;

export const ContainerInput = styled.div`
  display: flex;
  justify-content: center;
`;

export const ThInput = styled(ThStyled)`
  background-color: ${(props) =>
    props.disabled ? colors.greyLight : colors.verySoftRed};
  padding: ${spaces.xxs}rem ${spaces.xxs}rem;
`;

export const InputDate = styled(DateTime)`
  .container-inputs {
    gap: 0;
    border-radius: 0.5rem;
  }

  .container-inputs:focus-within {
    outline: 0.1rem solid ${colors.black};
  }

  .input-date {
    border: none;
    outline: none;
    height: ${spaces.l}rem;
    max-width: 12rem;
    border-bottom: ${(props) =>
      props.borderError ? '0.1rem solid red' : 'none'};
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }
  .input-hour {
    border: none;
    outline: none;
    height: ${spaces.l}rem;
    border-bottom: ${(props) =>
      props.borderError ? '0.1rem solid red' : 'none'};
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }

  @media (max-width: ${breakPoints.portraitTablet * percentBaseResponsive}rem) {
    span {
      font-size: ${fontSize.XXS};
    }

    .input-hour {
      width: ${spaces.xl};
    }
    .input-date {
      font-size: ${fontSize.XXS};
      max-width: 8.5rem;
      padding-right: 0;
    }
    .input-number {
      font-size: ${fontSize.XXS};
      width: 1.5rem;
    }
  }
`;

export const Tr = styled.tr`
  background-color: ${colors.greyVerySoft};
`;

export const TrStyled = styled.tr`
  background-color: ${colors.verySoftRed};
`;

export const Text = styled(Typography)`
  font-size: ${fontSize.M};
  @media (max-width: ${breakPoints.landscapeTablet *
    percentBaseResponsive}rem) {
    font-size: ${fontSize.S};
  }
  @media (max-width: ${breakPoints.portraitTablet * percentBaseResponsive}rem) {
    font-size: ${fontSize.XS};
  }
`;

export const InputOthers = styled.input`
  border: none;
  outline: none;
  text-align: center;
  font-size: ${fontSize.M};
  background-color: ${colors.white};
  :disabled {
    color: ${colors.black};
  }

  @media (max-width: ${breakPoints.landscapeTablet *
    percentBaseResponsive}rem) {
    font-size: ${fontSize.S};
  }
  @media (max-width: ${breakPoints.portraitTablet * percentBaseResponsive}rem) {
    font-size: ${fontSize.XS};
  }
`;

import React, { useCallback } from 'react';
import { useAsyncValue, useRevalidator } from 'react-router-dom';

// Components
import { AddMachineryManagementForm } from '../../../../components/forms/AddMachineryManagementForm';
import { MachineryManagementTable } from '../../../../components/display/tables/formTables/managementTables/MachineryManagementTable';
import { formMachineryManagementNaming } from '../../../../components/forms/AddMachineryManagementForm/constants';

// Types
import { AuxMachineryManagementLoader } from './types';
import { MachineryManagement } from '../../../../components/forms/AddMachineryManagementForm/types';

// Styles
import { Container, AuxMachineryManagementSection } from './styles';

// GQL
import { client } from '../../../../gql/client';
import { CREATE_NEW_AUX_MACHINERY } from '../../../../gql/mutations/machinery';
import { NEW_AUX_MACHINERY } from '../../../../gql/fragments/machinery';

export default function MachineryManagementPage(): JSX.Element {
  const loaderData = useAsyncValue() as AuxMachineryManagementLoader;
  const revalidator = useRevalidator();
  const allMachineryNames = loaderData.data.auxMachinesData.map((machine) =>
    machine.name
      .toLocaleLowerCase()
      .split('')
      .filter((letter) => letter !== ' ')
      .join('')
  );

  const onReFetchData = useCallback(() => {
    revalidator.revalidate();
  }, []);

  const onFormSubmit = async (data: MachineryManagement) => {
    const parsedName = data.name
      .toLowerCase()
      .split('')
      .filter((letter) => letter !== ' ')
      .join('');

    if (allMachineryNames.some((name) => name === parsedName)) {
      return 'La maquinària ja existeix.';
    }

    await client.mutate({
      mutation: CREATE_NEW_AUX_MACHINERY,
      variables: {
        input: {
          description: data.name,
          auxMachineTypeIDs: data.type,
          measureTypeIDs: data.typeOfMeasurement,
        },
      },
      update(cache, { data: { createAuxMachine } }) {
        cache.modify({
          fields: {
            auxMachines(auxMachines = []) {
              const createAuxMachineRef = cache.writeFragment({
                data: createAuxMachine,
                fragment: NEW_AUX_MACHINERY,
              });
              return [...auxMachines, createAuxMachineRef];
            },
          },
        });
      },
    });
    onReFetchData();
  };

  return (
    <Container>
      <AuxMachineryManagementSection>
        <AddMachineryManagementForm
          initialData={loaderData.cacheData}
          onFormSubmit={onFormSubmit}
          namingPersistForm={
            formMachineryManagementNaming.NEW_AUX_MACHINERY_MANAGEMENT_FORM
          }
          data={loaderData.data}
        />
      </AuxMachineryManagementSection>
      <AuxMachineryManagementSection>
        <MachineryManagementTable
          data={{
            machinery: loaderData.data.auxMachinesData,
          }}
        />
      </AuxMachineryManagementSection>
    </Container>
  );
}

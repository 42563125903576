// GraphQL
import { client } from '../../../../../../gql/client';
import {
  GET_CAPITOL_BY_ID,
  GET_CLASSIFICATION_BY_ID_OR_COTIC,
  GET_SUB_CAPITOL_BY_ID,
} from '../../../../../../gql/queries/orders/predefinedData';
import {
  GET_CONCESSION_BY_ID,
  GET_ROAD_BY_ID,
} from '../../../../../../gql/queries/geoPositions/roads';
import { GET_TEAM_BY_ID } from '../../../../../../gql/queries/users';

// Types
import { groupLanes, groupTracks } from '../../../../../../data/orders';

export const passData = async (formData: FormData) => {
  const operator = formData.get('operator');
  const registerInitDate = formData.get('registerInitDate');
  const concessionId = formData.get('concession');
  const roadId = formData.get('road');
  const teamId = formData.get('team');
  const classificationId = formData.get('classification');
  const capitolId = formData.get('capitol');
  const subCapitolId = formData.get('subCapitol');

  const [
    {
      data: { concession },
    },
    {
      data: { road },
    },
    {
      data: { classification },
    },
    {
      data: { capitol },
    },
    {
      data: { subCapitol },
    },
  ] = await Promise.all([
    client.query({
      query: GET_CONCESSION_BY_ID,
      variables: {
        id: concessionId,
      },
    }),
    client.query({
      query: GET_ROAD_BY_ID,
      variables: {
        id: roadId,
      },
    }),
    client.query({
      query: GET_CLASSIFICATION_BY_ID_OR_COTIC,
      variables: {
        id: classificationId,
      },
    }),
    client.query({
      query: GET_CAPITOL_BY_ID,
      variables: {
        id: capitolId,
      },
    }),
    client.query({
      query: GET_SUB_CAPITOL_BY_ID,
      variables: {
        id: subCapitolId,
      },
    }),
  ]);

  let teamValue = null;

  if (teamId) {
    const {
      data: { team },
    } = await client.query({
      query: GET_TEAM_BY_ID,
      variables: {
        id: teamId,
      },
    });

    teamValue = team;
  }

  const city = formData.get('city');
  const trackValue = formData.get('track');
  const direction = formData.get('direction');
  const margin = formData.get('margin');
  const laneValue = formData.get('lane');
  const pkInit = formData.get('pkInit');
  const pkEnd = formData.get('pkEnd');
  const description = formData.get('description');
  const campaign = formData.get('campaign');
  const isCampaignActive = formData.get('isCampaignActive');
  return {
    operator,
    registerInitDate,
    concession: concession?.name,
    city,
    road: road?.name,
    track: groupTracks.find((track) => track.value === trackValue)?.name,
    roadDirections: road.directions,
    direction,
    margin,
    lane: groupLanes.find((lane) => lane.value === laneValue)?.name,
    pkInit,
    pkEnd,
    description,
    campaign: isCampaignActive === 'true' ? campaign : null,
    classification: classification?.name,
    capitol: capitol?.name,
    subCapitol: subCapitol?.name,
    team: teamValue?.name,
  };
};

import React from 'react';

// Components
import ModalContainer from '../../containers/ModalContainer';
import { MainButton } from '../../../inputs/buttons/MainButton';

// Styled Components
import { Container, ContainerButtons, StyledButton, Title } from './styles';

// Colors
import { colors } from '../../../../styles/colors';

// Types
import { ModalProps } from './types';

export const AddMediaModal = ({
  onClickClose,
  choosePic,
  openCamera,
  ...props
}: ModalProps): JSX.Element => {
  return (
    <ModalContainer width={40} onClickClose={onClickClose} fill={colors.black}>
      <Container {...props}>
        <Title variant="semiBold">Afegir multimèdia</Title>
        <ContainerButtons>
          <StyledButton text="Obrir la càmera" onClick={openCamera} />
          <MainButton
            variant="secondary"
            text="Escollir de la galeria"
            onClick={choosePic}
          />
        </ContainerButtons>
      </Container>
    </ModalContainer>
  );
};

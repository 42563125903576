import styled from 'styled-components';
import { fontSize } from '../../../../styles/fonts';
import { colors } from '../../../../styles/colors';
import { spaces } from '../../../../styles/spaces';

export const Container = styled.div`
  width: 100%;
`;

export const TableContainer = styled.div`
  width: 100%;
  height: 19.2rem;
  overflow: auto;
  background-color: ${colors.greyVerySoft};
`;

export const Table = styled.table`
  width: 100%;
  text-align: left;
  font-size: ${fontSize.S};
`;

export const Thead = styled.thead`
  background: ${colors.greyDark};
  color: ${colors.white};
  position: sticky;
  top: 0;
  z-index: 99;
`;

export const Tbody = styled.tbody`
  background: ${colors.greyVerySoft};
  color: ${colors.black};
`;

interface trProps {
  checked?: boolean;
  indeterminate?: boolean;
}

export const Tr = styled.tr<trProps>`
  position: relative;
`;

export const Th = styled.th`
  padding: 0.2rem 0 0.2rem 2rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;

  :first-of-type {
    border-top-left-radius: 0.5rem;
  }
  :last-of-type {
    border-top-right-radius: 0.5rem;
    padding: 0.2rem 2rem;
  }
`;

export const Td = styled.td`
  padding: ${spaces.xxs}rem 0 ${spaces.xxs}rem 2rem;
  border-bottom: 0.1rem solid ${colors.greyLight};
  white-space: nowrap;
  text-overflow: ellipsis;
  vertical-align: middle;

  :last-of-type {
    padding: ${spaces.xxs}rem 2rem;
    width: 0;
  }
`;

export type dataTable = {
  codification: string;
  id: string;
  materialId: string;
  name: string;
  subCodification: string;
  typeOfMeasurement: string;
};

export type TableProps = {
  data?: {
    materials: dataTable[];
  };
};

export type SearchbarFilterTypes = (value: string) => void;

export const materialsColumnNames = {
  materialId: {
    id: 'materialId',
    label: 'Codi',
  },
  name: {
    id: 'name',
    label: 'Material',
  },
  codification: {
    id: 'codification',
    label: 'Codificació',
  },
  subCodification: {
    id: 'subCodification',
    label: 'Subcodificació',
  },
  typeOfMeasurement: {
    id: 'typeOfMeasurement',
    label: 'Um.',
  },
};

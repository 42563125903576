import styled, { css } from 'styled-components';

// Styles
import { colors } from '../../../../../styles/colors';
import { spaces } from '../../../../../styles/spaces';

// Types
import { ListItemProps, ButtonProps } from './types';

//Components
import { Typography } from '../../../Typography';
import { CheckBox } from '../../../../inputs/CheckBox';
import { ButtonIconBackground } from '../../../../inputs/buttons/ButtonIconBackground';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const ListContainer = styled.div`
  position: relative;
`;

export const List = styled.ul`
  width: 17.1rem;
  max-height: 31.5rem;
  border-radius: 0.5rem;
  padding: 0 1.2rem;
  box-shadow: 0 0.2rem ${spaces.xxs}rem 0rem rgba(0, 0, 0, 0.25);
  background-color: ${colors.white};
  position: absolute;
  top: 0.6rem;
  overflow: auto;
  z-index: 9999;
`;

export const ListItem = styled.li<ListItemProps>`
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: ${spaces.xs}rem 0;

  &:not(:last-of-type) {
    border-bottom: 0.1rem solid ${colors.paleGrey};
  }

  ${({ disabled }) =>
    css`
      ${Text} {
        ${disabled && {
          color: colors.greyLight,
        }}
      }
      ${StyledCheckBox} {
        ${disabled && {
          borderColor: colors.greyLight,
        }}
      }
    `}
`;

export const StyledCheckBox = styled(CheckBox)`
  min-width: 1.3rem;
  min-height: 1.3rem;
`;

export const Text = styled(Typography)`
  flex: 1;
`;

export const Button = styled(ButtonIconBackground)<ButtonProps>`
  background-color: ${({ displayed }) => displayed && colors.black};
`;

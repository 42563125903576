import { client } from '../client';

// Sentry
import * as Sentry from '@sentry/react';

//GQL
import {
  GET_ACCIDENT_BY_ID,
  GET_IMAGE_BY_ID,
} from '../queries/accidents/accidents';
import { GET_ORDER_BY_ID } from '../queries/orders/orders';
import {
  GET_CAPITOL_BY_ID,
  GET_SUB_CAPITOL_BY_ID,
} from '../queries/orders/predefinedData';
import { GET_ACTION_BY_ID } from '../queries/actions/actions';

// Types
import { OrderType } from '../../types/orders';
import { GET_ROAD_BY_ID } from '../queries/geoPositions/roads';

export const selectWorkOrdersWithMultimedia = async (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dataOrders: any[] = []
) => {
  const ordersWithMultimedia = await Promise.all(
    dataOrders.map(async (order) => {
      if (order.imageIDs.length > 0) {
        const orderType = order.type;
        let pkInit;
        let pkEnd;
        let roadValue;

        if (orderType === OrderType.Accident) {
          const {
            data: { accident },
          } = await client.query({
            query: GET_ACCIDENT_BY_ID,
            variables: {
              id: order.accidentIDs[0],
            },
          });

          const {
            data: { road },
          } = await client.query({
            query: GET_ROAD_BY_ID,
            variables: {
              id: accident.roadIDs[0],
            },
          });

          roadValue = road.name;

          pkInit = `${accident.pkInitKm}+${accident.pkInitMeter}`;
          pkEnd = `${accident.pkEndKm}+${accident.pkEndMeter}`;
        }

        if (orderType === OrderType.Action) {
          const {
            data: { action },
          } = await client.query({
            query: GET_ACTION_BY_ID,
            variables: {
              id: order.actionIDs[0],
            },
          });

          const {
            data: { road },
          } = await client.query({
            query: GET_ROAD_BY_ID,
            variables: {
              id: action.roadIDs[0],
            },
          });

          roadValue = road.name;

          pkInit = `${action.pkInitKm}+${action.pkInitMeter}`;
          pkEnd = `${action.pkEndKm}+${action.pkEndMeter}`;
        }

        const [
          {
            data: { capitol },
          },
          {
            data: { subCapitol },
          },
        ] = await Promise.all([
          client.query({
            query: GET_CAPITOL_BY_ID,
            variables: { id: order.capitolIDs[0] },
          }),
          client.query({
            query: GET_SUB_CAPITOL_BY_ID,
            variables: { id: order.subCapitolIDs[0] },
          }),
        ]);
        return {
          id: order.id,
          cotic: order.cotic.split('.'),
          registerInitDate: order.registerInitDate,
          creationDate: order.creationDate,
          capitol: capitol.name,
          subCapitol: subCapitol.name,
          description: order.description,
          imageIDs: order.imageIDs,
          pkInit,
          pkEnd,
          state: order.state,
          road: roadValue,
        };
      }
      return null;
    })
  );

  return ordersWithMultimedia.filter((order) => order !== null);
};

export const selectImagesByOrderIDs = async (idOrder: string) => {
  try {
    const { data } = await client.query({
      query: GET_ORDER_BY_ID,
      variables: {
        id: idOrder,
      },
    });
    const cotic = data.order.cotic;

    const images = await Promise.all(
      data.order.imageIDs.map(async (imageID: string) => {
        const { data } = await client.query({
          query: GET_IMAGE_BY_ID,
          variables: {
            id: imageID,
          },
        });
        return { ...data.image, cotic: cotic };
      })
    );

    return {
      images: images,
    };
  } catch (err) {
    Sentry.captureException(err);
    console.log('> Error getting the multimedia data', err);
  }
};

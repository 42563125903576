export type dataTable = {
  id: string;
  auxMachineId: string;
  name: string;
  type: string;
  typeOfMeasurement: string;
  machineryUnitIDs: string[];
};

export type TableProps = {
  data?: {
    machinery: dataTable[];
  };
};

export type SearchbarFilterTypes = (value: string) => void;

export const machineryColumnNames = {
  auxMachineId: {
    id: 'auxMachineId',
    label: 'Codi',
  },
  name: {
    id: 'name',
    label: 'Maquinària',
  },
  type: {
    id: 'type',
    label: 'Tipus de maquinària',
  },
  typeOfMeasurement: {
    id: 'typeOfMeasurement',
    label: 'Um.',
  },
};

import React, { useRef, useImperativeHandle, forwardRef, Ref } from 'react';
import { FabricJSCanvas, useFabricJSEditor } from 'fabricjs-react';
import { fabric } from 'fabric';

// Components
import { MainButton } from '../buttons/MainButton';
import { SketchTab } from './SketchTab';

// Styled Components
import {
  Container,
  ContainerButton,
  ContainerCanvas,
  SubContainerCanvas,
} from './styles';

// Types
import { SketchProps, ImperativeRefSketch } from './types';

// Colors
import { colors } from '../../../styles/colors';

// Hooks
import { useIsLandscapeTablet } from '../../../hooks/useMediaBreakPoints';

// Constants
import { formAccidentNaming } from '../../forms/AddBasicInfoAccidentForm/constants';

const AccidentSketch = (
  { onSaveDraw, ...props }: SketchProps,
  innerRef: Ref<ImperativeRefSketch>
): JSX.Element => {
  const canvasRef = useRef<HTMLDivElement>(null);
  const { editor, onReady } = useFabricJSEditor({});
  if (editor) {
    editor.canvas.backgroundColor = `${colors.white}`;
  }

  const onAddCircle = () => {
    if (!editor) return;
    const radius = 50;
    editor.canvas.isDrawingMode = false;
    const circle = new fabric.Circle({
      left: ((canvasRef.current?.offsetWidth as number) - radius * 2) / 2,
      top: ((canvasRef.current?.offsetHeight as number) - radius * 2) / 2,
      radius: 50,
      stroke: `${colors.black}`,
      fill: 'transparent',
    });
    editor?.canvas.add(circle);
  };
  const onAddRectangle = () => {
    if (!editor) return;
    editor.canvas.isDrawingMode = false;
    const width = 100;
    const height = 100;
    const rect = new fabric.Rect({
      left: ((canvasRef.current?.offsetWidth as number) - width) / 2,
      top: ((canvasRef.current?.offsetHeight as number) - height) / 2,
      width: width,
      height: height,
      stroke: `${colors.black}`,
      fill: 'transparent',
    });
    editor.canvas.add(rect);
  };

  const setDraw = (isEnable: boolean) => {
    if (!editor) return;
    editor.canvas.isDrawingMode = isEnable;
  };

  const removeDraw = () => {
    if (!editor) return;
    editor.deleteAll();
    editor.canvas.isDrawingMode = false;
  };

  const removeFigure = () => {
    if (!editor) return;
    editor.deleteSelected();
  };

  const onAddText = () => {
    if (!editor) return;
    const text = new fabric.Textbox('Introduzca texto:', {
      fontSize: 40,
      fontFamily: 'sans-serif',
      editable: true,
      top: 25,
      left: 25,
      height: 200,
    });
    editor?.canvas.add(text);
    editor.canvas.isDrawingMode = false;
  };

  const onSaveDrawClick = () => {
    const imageURL = editor?.canvas.toDataURL();
    const svgCanvas = editor?.canvas.toSVG();
    const arrayObjects = JSON.stringify(editor?.canvas._objects);
    sessionStorage.setItem(formAccidentNaming.IMAGE_CANVAS, imageURL as string);
    sessionStorage.setItem(formAccidentNaming.SVG_CANVAS, svgCanvas as string);
    sessionStorage.setItem(
      formAccidentNaming.ARRAY_CANVAS,
      arrayObjects as string
    );
    onSaveDraw && onSaveDraw();
  };

  const getSvg = () => {
    const objectSvg = sessionStorage.getItem(
      formAccidentNaming.SVG_CANVAS
    ) as string;
    const arrayObjects: fabric.Object[] = JSON.parse(
      sessionStorage.getItem(formAccidentNaming.ARRAY_CANVAS) as string
    );
    const textBox = arrayObjects.map((object) => object.text);
    fabric.loadSVGFromString(objectSvg, (objects) => {
      if (editor) {
        editor.canvas._objects.splice(0, editor.canvas._objects.length);
        const newObj = objects.filter((_, index) => index !== 0);
        newObj.forEach((object, index) => {
          if (object.type === 'text') {
            const text = new fabric.Textbox(textBox[index], {
              fontSize: 40,
              fontFamily: 'sans-serif',
              top: object.top,
              left: object.left,
              width: object.width,
            });
            editor?.canvas.add(text);
          } else editor.canvas.add(object);
        });
        editor.canvas.renderAll();
      }
    });
  };

  const isLandscapeTable = useIsLandscapeTablet();

  useImperativeHandle(innerRef, () => ({
    getSvg: getSvg,
  }));

  return (
    <Container {...props}>
      <ContainerCanvas>
        <SubContainerCanvas ref={canvasRef}>
          <FabricJSCanvas className="canvas" onReady={onReady} />
        </SubContainerCanvas>
        {!isLandscapeTable && (
          <SketchTab
            onAddCircle={onAddCircle}
            onAddRectangle={onAddRectangle}
            onAddText={onAddText}
            setDraw={setDraw}
            removeAll={removeDraw}
            removeFigure={removeFigure}
          />
        )}
      </ContainerCanvas>
      <ContainerButton>
        {isLandscapeTable && (
          <SketchTab
            onAddCircle={onAddCircle}
            onAddRectangle={onAddRectangle}
            onAddText={onAddText}
            setDraw={setDraw}
            removeAll={removeDraw}
            removeFigure={removeFigure}
          />
        )}
        <MainButton text="Desar croquis" onClick={onSaveDrawClick} />
      </ContainerButton>
    </Container>
  );
};

export default forwardRef(AccidentSketch);

import styled from 'styled-components';
import {
  breakPoints,
  percentBaseResponsive,
  spaces,
} from '../../../styles/spaces';
import { Typography } from '../../display/Typography';
import { InputTextArea } from '../../inputs/InputTextArea';

export const Container = styled.div`
  display: flex;
`;

export const ContainerContent = styled.div`
  display: flex;
  gap: ${spaces.xl}rem 10%;
  width: 100%;
  position: relative;
  @media (max-width: ${breakPoints.smallDesktop * percentBaseResponsive}rem) {
    flex-wrap: wrap;
  }
`;

export const SubContainerLeft = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spaces.xl}rem;
  max-width: 52.2rem;
`;

export const SubContainerRight = styled.div`
  flex: 1;
  max-width: 90rem;
`;

export const StyledPagination = styled(Typography)`
  position: absolute;
  right: 0;
`;

export const AccidentReportContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StateOfTheRoadContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const InputRow = styled.div`
  display: flex;
  gap: ${spaces.l}rem;
  flex-basis: 100%;
  @media (max-width: ${breakPoints.landscapeTablet *
    percentBaseResponsive}rem) {
    flex-wrap: wrap;
  }
`;

export const StyledInputTextArea = styled(InputTextArea)`
  flex-basis: 100%;
`;

export const ContainerAttention = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spaces.xxxl}rem;
`;

export const ContainerRow = styled.div``;

export const SketchImagesContainer = styled.div`
  display: flex;
  gap: ${spaces.xl}rem;
  flex-wrap: wrap;
  @media (max-width: ${breakPoints.portraitTablet * percentBaseResponsive}rem) {
    flex-direction: column;
  }
`;

export const SketchContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: ${spaces.s}rem;
`;

export const ImagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: ${spaces.s}rem;
`;

export const HiddenInput = styled.input`
  display: none;
`;

export const ContainerInputLoads = styled.div`
  display: flex;
  gap: ${spaces.s}rem;
`;

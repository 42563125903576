import React from 'react';
import { FullscreenImageProps } from './types';
import { Definition } from '../../../display/Definition';
import { StyledAdvancedImage, InfoContainer, StyledTypography } from './styles';
import ModalContainer from '../../containers/ModalContainer';

export const ViewFullscreenImage = ({
  onClickClose,
  currentImage,
  title,
  currentDate,
  currentHours,
  currentMinutes,
  cotic,
}: FullscreenImageProps) => {
  return (
    <ModalContainer fill="black" onClickClose={onClickClose}>
      <StyledAdvancedImage cldImg={currentImage} />
      <InfoContainer>
        <StyledTypography size="S">{title}</StyledTypography>
        <Definition title="Data de creació" value={currentDate} />
        <Definition
          title="Hora de creació"
          value={`${currentHours}:${currentMinutes}`}
        />
        <Definition title="COTIC" value={cotic} />
      </InfoContainer>
    </ModalContainer>
  );
};

import { OrderType, OrderTypeCotic } from '../types/orders';

export const checkTypeOrderCotic = (digits: string) => {
  switch (digits) {
    case OrderTypeCotic.Communication:
      return OrderType.Communication;
    case OrderTypeCotic.Generic:
      return OrderType.Generic;
    case OrderTypeCotic.Action:
      return OrderType.Action;
    case OrderTypeCotic.Accident:
      return OrderType.Accident;
    case OrderTypeCotic.SurveillanceRoute:
      return OrderType.SurveillanceRoute;
    case OrderTypeCotic.Campaign:
      return OrderType.Campaign;
  }
};

export const generateCoticPadding = (
  count: number,
  countLimit: number
): string => {
  let newCount = count.toString();
  const countSize = newCount.length;
  if (countSize < countLimit) {
    newCount = newCount.padStart(countLimit, '0');
  }
  return newCount;
};

export const stateOptions = [
  { label: 'Oberta', value: 'open' },
  { label: 'Validada', value: 'validated' },
  { label: 'Parcial', value: 'partial' },
  { label: 'Anul·lada', value: 'annulled' },
  { label: 'Finalitzada', value: 'end' },
];

export const typeOptions = [
  { label: 'Ordre genérica', value: 'generic' },
  { label: 'Comunicació', value: 'communication' },
  { label: 'Sub ordre', value: 'subOrder' },
  { label: 'Actuació', value: 'action' },
  { label: 'Accident', value: 'accident' },
  { label: 'Recorregut de bigilancia', value: 'surveillanceRoute' },
  { label: 'Campanya', value: 'campaign' },
];

import React, { useState, useCallback, useEffect } from 'react';

// Styled Components
import {
  Container,
  ContainerFileIcon,
  ContainerInput,
  ContainerRemoveIcon,
  Text,
  SmallContainer,
} from './styles';

// Types
import { InputProps, ImageData } from './types';

// Icons
import LoadImageIcon from '../../../assets/icons/load-image-icon.svg';
import RemoveImageIcon from '../../../assets/icons/remove-file-icon.svg';

export const InputLoadImages = ({
  imageData,
  removeItem,
  disabled,
  ...props
}: InputProps): JSX.Element => {
  const [images, setImages] = useState<ImageData[]>([]);

  useEffect(() => {
    setImages(imageData);
  }, [imageData]);

  const removeImage = useCallback(
    (id: string) => {
      setImages((prevImages) =>
        prevImages.filter((element) => element.id !== id)
      );
      if (removeItem) {
        removeItem(id);
      }
    },
    [images]
  );
  return (
    <Container {...props}>
      {images.map((element, index) => {
        return (
          <ContainerInput key={index}>
            <SmallContainer>
              <ContainerFileIcon src={LoadImageIcon} />
              <Text size="S" variant="medium">
                {element.name}
              </Text>
            </SmallContainer>
            {removeItem && (
              <ContainerRemoveIcon
                src={RemoveImageIcon}
                onClick={() => !disabled && removeImage(element.id)}
              />
            )}
          </ContainerInput>
        );
      })}
    </Container>
  );
};

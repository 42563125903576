import { defer, LoaderFunction } from 'react-router-dom';

// Sentry
import * as Sentry from '@sentry/react';

// GQL
import { selectActionById } from '../../../../../gql/selectors/actions';

// Constants
import { formActionNaming } from '../../../../../components/forms/ActionForms/constants';
import { decompressSessionStorage } from '../../../../../utils/sessionStorage';

const loadActionSummary = async (idOrder: string) => {
  try {
    return await selectActionById(idOrder);
  } catch (err) {
    Sentry.captureException(err);
    console.log('> Action Summary loading error', err);
    return false;
  }
};

export const ActionSummaryLoader: LoaderFunction = async ({ params }) => {
  if (!params.id) return null;

  const loaderToResolve = await loadActionSummary(params.id);

  const isActionEdited = sessionStorage.getItem(
    formActionNaming.NEW_VALIDATION_ACTION_DATA
  )
    ? JSON.parse(
        decompressSessionStorage(
          sessionStorage.getItem(
            formActionNaming.NEW_VALIDATION_ACTION_DATA
          ) as string
        )
      )
    : 'true';

  return defer({
    data: { ...loaderToResolve, isActionEdited },
  });
};

import React from 'react';

type RedArrowProps = {
  className?: string;
};

export const RedArrow = ({ className }: RedArrowProps): JSX.Element => {
  return (
    <svg
      width="12"
      height="15"
      viewBox="0 0 12 15"
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.88528 14.7812C6.08526 15.0235 6.4565 15.0235 6.65648 14.7812L11.6967 8.67518C11.9658 8.34909 11.7339 7.85688 11.3111 7.85688L8.61923 7.85688L8.61923 0.965562C8.61923 0.689419 8.39537 0.465562 8.11923 0.465562L4.4225 0.465561C4.14635 0.465561 3.9225 0.689419 3.9225 0.965562L3.92249 7.85688L1.2307 7.85688C0.807874 7.85688 0.575932 8.34909 0.845097 8.67517L5.88528 14.7812Z"
      />
    </svg>
  );
};

import { defer, LoaderFunction } from 'react-router-dom';
import { formOrderNaming } from '../../../../../components/forms/NewWorkOrderForm/constants';
import { selectDataForEditCampaignSummary } from '../../../../../gql/selectors/campaigns';
import { createFormDataFromValues } from '../../../../../utils/forms';

// Sentry
import * as Sentry from '@sentry/react';

// Utils
import { decompressSessionStorage } from '../../../../../utils/sessionStorage';

const loadDataFromCache = async (formData: FormData) => {
  try {
    const campaignEditData = await selectDataForEditCampaignSummary(formData);

    return campaignEditData;
  } catch (err) {
    Sentry.captureException(err);
    console.log('> Error loading data for edit campaign summary', err);
  }
};

export const LoaderCampaignSummary: LoaderFunction = async () => {
  const stringifyData = sessionStorage.getItem(
    formOrderNaming.EDIT_CAMPAIGN_FORM
  );
  if (stringifyData) {
    const dataParsed = JSON.parse(decompressSessionStorage(stringifyData));
    const formData = createFormDataFromValues(dataParsed);
    const loaderToResolve = await loadDataFromCache(formData);

    return defer({ data: loaderToResolve });
  }

  return defer({ data: {} });
};

import { SyntheticEvent } from 'react';

// Types
import { variant } from '../SpecialTag/types';
import { UNSPECIFIC } from '../../../types/utils';

export type storedDataOTSelectedType = {
  capitol: string;
  concession: string;
  cotic: string[];
  road: string;
  date: number;
  description: string;
  hour: number;
  pkEnd: string;
  pkInit: string;
  state: string;
  subCapitol: string;
  classification: string;
};

export type ContentCardResumeProps = {
  title: string;
  cotic: string[];
  operator: string;
  data: string;
  hour: string;
  groupInter?: string;
  media?: string;
  sendStatus?: string | undefined;
  capitol: string;
  subCapitol: string;
  classification: string;
  team?: string;
  concession: string;
  road: string;
  pkInit: string;
  pkEnd: string;
  track: string;
  city: string;
  roadDirections: string;
  direction?: 'top' | 'down' | 'both' | typeof UNSPECIFIC | 'unspecific';
  margin?: 'left' | 'right' | 'both' | typeof UNSPECIFIC | 'unspecific';
  lane: string;
  leftButton?: string;
  rightButton?: string;
  tag?: variant | undefined;
  tagContent?: string;
  description: string;
  onEditCommunication?: () => void;
  onSaveCommunication?: (e: SyntheticEvent) => Promise<void>;
  storedDataOTSelected?: storedDataOTSelectedType;
  changeStateTag?: boolean;
  revalidatorFunction?: () => void;
  state?: string;
  orderId?: string;
  disabled?: boolean;
};

export const marginArrow = {
  left: 'Esquerre (←)',
  right: 'Dret (→)',
  both: 'Tots dos (↔)',
  unspecific: 'Sense especificar',
};

export const directionArrow = {
  top: '(↑)',
  down: '(↓)',
  both: '(↕)',
  unspecific: 'Sense especificar',
};

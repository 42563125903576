export const activeUsersMocksData = [
  {
    id: '1',
    name: 'Esther',
    surName: 'Alfreda Pol',
    email: 'esther@insolite.es',
    rolActive: 'Super admin',
    concession: '1892518752375287356827',
    isActive: true,
  },
  {
    id: '2',
    name: 'Pablo',
    surName: 'García Rodriguez',
    email: 'pablo@insolite.es',
    rolActive: 'Operari',
    concession: '1892518752375287356827',
    isActive: true,
  },
  {
    id: '3',
    name: 'David',
    surName: 'Delgado Lopez',
    email: 'david@insolite.es',
    isActive: true,
    rolActive: 'Operari',
    concession: '1892518752375287356827',
  },
  {
    id: '4',
    name: 'Rubén',
    surName: 'Romero Díaz',
    email: 'rubén@insolite.es',
    rolActive: 'Super admin',
    concession: '1892518752375287356827',
    isActive: true,
  },
];

export const inactiveUsersMocksData = [
  {
    id: '5',
    name: 'Alexis',
    surName: 'Reyes Tenor',
    email: 'alexis@insolite.es',
    rolActive: 'Super admin',
    concession: '1892518752375287356827',
    isActive: false,
  },
  {
    id: '6',
    name: 'Juan',
    surName: 'Ocaña Quero',
    email: 'juan@insolite.es',
    isActive: false,
    rolActive: 'Operari',
    concession: '1892518752375287356827',
  },
  {
    id: '7',
    name: 'Alan',
    surName: 'Solis Paniagua',
    email: 'alan@insolite.es',
    isActive: false,
    rolActive: 'Super admin',
    concession: '1892518752375287356827',
  },
  {
    id: '8',
    name: 'Camila',
    surName: 'Gomez Rodriguez',
    email: 'camila@insolite.es',
    isActive: false,
    rolActive: 'Operari',
    concession: '1892518752375287356827',
  },
];

import React, { useCallback, useRef } from 'react';

import * as Sentry from '@sentry/react';

import { Controller, useWatch } from 'react-hook-form';
//Components
import InputText from '../../inputs/InputText';
import { ArrowRadioGroup } from '../../../pages/main/comunications/NewComunication/styles';

import DropDownInput from '../../inputs/DropDown/DropDownInput';
// Styled Components
import { ErrorMessage, ContainerDescription } from './styles';

import {
  Title,
  LocationInputGroup,
  InputTextAreaStyled,
  InputsContainer,
  PkInputContainer,
} from '../components/MainStyled';
// Types
import { CommunicationDirection, Margin } from '../../../types/communication';
import { FormProps, GroupTypes } from './types';
import { OptionSelected } from '../../inputs/DropDown/DropDownList/types';
import { ImperativeRefInput } from '../../inputs/DropDown/DropDownInput/types';

import { type PartialDirection } from '../../inputs/buttons/ArrowDirectionButton/types';
// GraphQL
import { client } from '../../../gql/client';

import {
  GET_CONCESSION_BY_ID,
  GET_ROAD_BY_ID,
  GET_ROADS_BY_IDS,
} from '../../../gql/queries/geoPositions/roads';
// Utils
import { pkFormatter } from '../../../utils/transform';
import { fetchDefaultData } from '../../../utils/fetchData';

export const Description = ({
  control,
  setValue,
  register,
  errors,
}: FormProps) => {
  const roads = useWatch({
    control,
    name: 'roads',
  });
  const city = useWatch({
    control,
    name: 'city',
  });
  const pkInitRoad = useWatch({
    control,
    name: 'pkInitRoad',
  });
  const pkEndRoad = useWatch({
    control,
    name: 'pkEndRoad',
  });

  const roadDropDownRef = useRef<ImperativeRefInput>(null);
  const onSelectConcession = useCallback(
    (
      option: OptionSelected | undefined,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onChange: (...event: any[]) => void
    ) => {
      const tasks = async () => {
        const {
          data: { concession },
        } = await client.query({
          query: GET_CONCESSION_BY_ID,
          variables: { id: option?.value },
        });

        setValue('city', concession.location);

        const { readRoads } = client.readQuery({
          query: GET_ROADS_BY_IDS,
          variables: { ids: concession.roadIDs ?? [] },
        });
        if (readRoads.length === 0) {
          await fetchDefaultData();
          const { readRoads } = client.readQuery({
            query: GET_ROADS_BY_IDS,
            variables: { ids: concession.roadIDs ?? [] },
          });
          setValue('roads', readRoads);
        } else {
          setValue('roads', readRoads);
        }
        roadDropDownRef.current?.clearSelected();
        setValue('road', '');
        onChange(option?.value ?? '');
      };
      tasks().catch((err) => {
        Sentry.captureException(err);
        console.log(err);
      });
    },
    []
  );

  const onSelectRoad = useCallback(
    (
      option: OptionSelected | undefined,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onChange: (...event: any[]) => void
    ) => {
      const tasks = async () => {
        const {
          data: { road },
        } = await client.query({
          query: GET_ROAD_BY_ID,
          variables: { id: option?.value },
        });

        setValue(
          'pkInitRoad',
          pkFormatter(road.pkInitKm, road.pkInitMeter, true)
        );
        setValue('pkEndRoad', pkFormatter(road.pkEndKm, road.pkEndMeter, true));

        onChange(option?.value ?? '');
      };
      tasks().catch((err) => {
        Sentry.captureException(err);
        console.log(err);
      });
    },
    []
  );

  return (
    <ContainerDescription>
      <Title component="h2" variant="semiBold" size="L">
        Descripció de la comunicació
      </Title>
      <InputsContainer>
        <LocationInputGroup>
          <Controller
            control={control}
            name="concession"
            render={({ field: { onChange }, formState: { defaultValues } }) => {
              const options =
                defaultValues?.concessions?.reduce((acc, curr) => {
                  if (curr?.name !== 'CX') {
                    acc.push({
                      label: curr?.name || '',
                      value: curr?.id || '',
                    });
                  }

                  return acc;
                }, [] as GroupTypes[]) || [];
              return (
                <DropDownInput
                  inputSize="XS"
                  onChangeSelected={(option) =>
                    onSelectConcession(option, onChange)
                  }
                  typeDropDown="Default"
                  labelText="Concessió"
                  placeholder="Selec."
                  defaultValue={defaultValues?.concession}
                  borderError={!!errors.concession}
                  options={options}
                />
              );
            }}
          />
          <InputText
            {...register('city')}
            label="Ubicació"
            typeInput="normal"
            borderError={!city && !!errors.city}
            disabled
          />
        </LocationInputGroup>
        <LocationInputGroup>
          <Controller
            control={control}
            name="road"
            render={({ field: { onChange }, formState: { defaultValues } }) => {
              const options =
                roads?.map((road) => ({
                  label: road?.name || '',
                  value: road?.id || '',
                })) || [];

              return (
                <DropDownInput
                  ref={roadDropDownRef}
                  inputSize="XS"
                  onChangeSelected={(option) => onSelectRoad(option, onChange)}
                  typeDropDown="Default"
                  labelText="Carretera"
                  placeholder="Selec."
                  defaultValue={defaultValues?.road}
                  borderError={!!errors.road}
                  options={options}
                />
              );
            }}
          />
          <InputText
            {...register('pkInitRoad')}
            label="Inici"
            inputSize="11"
            typeInput="normal"
            placeholder="000 + 000"
            borderError={!pkInitRoad && !!errors.pkInitRoad}
            disabled
          />

          <InputText
            {...register('pkEndRoad')}
            label="Final"
            inputSize="11"
            typeInput="normal"
            placeholder="000 + 000"
            borderError={!pkEndRoad && !!errors.pkEndRoad}
            disabled
          />
        </LocationInputGroup>

        <Controller
          control={control}
          name="track"
          render={({ field: { onChange }, formState: { defaultValues } }) => {
            const options =
              defaultValues?.groupTracks?.map((way) => ({
                label: way?.name || '',
                value: way?.value || '',
              })) || [];
            return (
              <DropDownInput
                inputSize="M"
                onChangeSelected={(option) => onChange(option?.value ?? '')}
                typeDropDown="Default"
                labelText="Tipus vial"
                placeholder="Selecciona"
                defaultValue={defaultValues?.track}
                borderError={!!errors.track}
                options={options}
              />
            );
          }}
        />
        <Controller
          control={control}
          name="direction"
          render={({ field: { onChange }, formState: { defaultValues } }) => {
            const defaultStatusValue: PartialDirection = {
              [CommunicationDirection.DOWN]: 'down',
              [CommunicationDirection.TOP]: 'top',
              [CommunicationDirection.BOTH]: 'both',
            };

            const directionOptions: PartialDirection = {
              down: CommunicationDirection.DOWN,
              top: CommunicationDirection.TOP,
              both: CommunicationDirection.BOTH,
            };

            return (
              <ArrowRadioGroup
                directions={['down', 'both', 'top']}
                name="direction"
                label={'Sentit'}
                defaultValue={
                  defaultStatusValue[
                    defaultValues?.direction as CommunicationDirection
                  ]
                }
                borderError={!!errors.direction}
                onChangeDirection={(dir) => {
                  onChange(directionOptions[dir]);
                }}
              />
            );
          }}
        />

        <Controller
          control={control}
          name="margin"
          render={({ field: { onChange }, formState: { defaultValues } }) => {
            const defaultStatusValue: PartialDirection = {
              [Margin.BOTH]: 'both',
              [Margin.LEFT]: 'left',
              [Margin.RIGHT]: 'right',
            };

            const options: PartialDirection = {
              both: Margin.BOTH,
              left: Margin.LEFT,
              right: Margin.RIGHT,
            };

            return (
              <ArrowRadioGroup
                directions={['left', 'both', 'right']}
                name="margin"
                label={'Marge'}
                defaultValue={
                  defaultStatusValue[defaultValues?.margin as Margin]
                }
                borderError={!!errors.margin}
                onChangeDirection={(dir) => {
                  onChange(options[dir]);
                }}
              />
            );
          }}
        />
        <Controller
          control={control}
          name="lane"
          render={({ field: { onChange }, formState: { defaultValues } }) => {
            const options =
              defaultValues?.groupLanes?.map((lane) => ({
                label: lane?.name || '',
                value: lane?.value || '',
              })) || [];
            return (
              <DropDownInput
                inputSize="S"
                onChangeSelected={(option) => onChange(option?.value ?? '')}
                typeDropDown="Default"
                labelText="Carril"
                placeholder="Selecciona"
                defaultValue={defaultValues?.lane}
                borderError={!!errors.lane}
                options={options}
              />
            );
          }}
        />

        <PkInputContainer>
          <InputText
            {...register('pkInit')}
            label="Pk inicial"
            inputSize="16"
            typeInput="normal"
            placeholder="Ex: 90+200"
            borderError={!!errors.pkInit}
          />
          <InputText
            {...register('pkEnd')}
            label="Pk final"
            inputSize="16"
            typeInput="normal"
            placeholder="Ex: 90+200"
            borderError={!!errors.pkEnd}
          />
        </PkInputContainer>

        {(errors.pkInit || errors.pkEnd) && (
          <ErrorMessage size="XS" colorText="red">
            *Aquest camp tiene un formato inadecuado
          </ErrorMessage>
        )}
      </InputsContainer>

      <InputTextAreaStyled
        {...register('description', {})}
        name="description"
        disabled={false}
        label="Descripció"
        placeholder="Text"
        error={errors.description?.message}
        borderError={!!errors.description}
      />
    </ContainerDescription>
  );
};

export const materialsMocksData = [
  {
    id: '1',
    materialId: 'M00001',
    name: 'Aglomerat en fred en pots de 25 Kg',
    typeOfMeasurement: 'UN',
    codification: 'VIAL',
    subCodification: 'JUNTES',
    materialUnitIDs: [],
  },
  {
    id: '2',
    materialId: 'M00002',
    name: 'Aglomerat en fred en pots de 5 Kg',
    typeOfMeasurement: 'UN',
    codification: 'VIAL',
    subCodification: 'JUNTES',
    materialUnitIDs: [],
  },
  {
    id: '3',
    materialId: 'M00003',
    name: 'Aglomerat en fred en pots de 75 Kg',
    typeOfMeasurement: 'UN',
    codification: 'VIAL',
    subCodification: 'JUNTES',
    materialUnitIDs: [],
  },
  {
    id: '4',
    materialId: 'M00004',
    name: 'Aglomerat en fred en pots de 100 Kg',
    typeOfMeasurement: 'UN',
    codification: 'VIAL',
    subCodification: 'JUNTES',
    materialUnitIDs: [],
  },
];

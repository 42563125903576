import React, { useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

// Types
import { Props, VehicleManagement } from './types';

// Schema
import { VehicleManagementSchema } from './schema';

// Styles
import { Container, InputsContainer, AddVehicleButton } from './styles';

// Components
import InputText from '../../inputs/InputText';

// Utils
import { useCacheFormInSessionStorage } from '../../../hooks/useCacheForm';
import { Typography } from '../../display/Typography';
import { ImperativeRefInput } from '../../inputs/DropDown/DropDownInput/types';
import DropDownInput from '../../inputs/DropDown/DropDownInput';

export const AddVehicleManagementForm = ({
  onFormSubmit,
  namingPersistForm,
  initialData,
}: Props) => {
  const {
    handleSubmit,
    control,
    register,
    formState: { errors },
    reset,
  } = useForm<VehicleManagement>({
    defaultValues: {
      ...initialData.cacheData,
      isActive: true,
    },
    resolver: yupResolver(VehicleManagementSchema),
  });
  useCacheFormInSessionStorage(namingPersistForm, control);

  // States
  const [repeatedPlateError, setRepeatedPlateError] = useState<null | string>(
    null
  );

  const vehicleCategoryRef = useRef<ImperativeRefInput>(null);
  const vehicleConcessionRef = useRef<ImperativeRefInput>(null);

  const concessionOptions = initialData.concessions.map((concession) => ({
    label: concession.name,
    value: concession.id,
  }));

  const onSubmit = async (data: VehicleManagement) => {
    const errorMessage = await onFormSubmit(data);
    if (errorMessage) {
      setRepeatedPlateError(errorMessage);
    } else {
      setRepeatedPlateError(null);
      reset({
        plate: '',
        model: '',
        category: '',
      });
      vehicleCategoryRef.current?.clearSelected();
      vehicleConcessionRef.current?.clearSelected();
    }
  };
  return (
    <Container onSubmit={handleSubmit(onSubmit)}>
      <Typography size="L" variant="semiBold">
        Afegir vehicle
      </Typography>

      <InputsContainer>
        <InputText
          {...register('plate')}
          label="Matrícula"
          typeInput={'normal'}
          inputSize="16"
          placeholder="Matrícula"
          borderError={!!errors.plate?.message}
        />
        <InputText
          {...register('model')}
          label="Model"
          typeInput={'normal'}
          placeholder="Model"
          borderError={!!errors.model?.message}
        />
        <Controller
          control={control}
          name="category"
          render={({ field: { onChange }, formState: { defaultValues } }) => {
            const options =
              initialData?.vehicleCategories?.map((category) => ({
                label: category?.name || '',
                value: category?.id || '',
              })) || [];

            return (
              <DropDownInput
                ref={vehicleCategoryRef}
                inputSize="M"
                onChangeSelected={(option) => onChange(option?.value ?? '')}
                typeDropDown="Default"
                labelText="Categoria"
                placeholder="Selecciona"
                defaultValue={defaultValues?.category}
                borderError={!!errors.category?.message}
                options={options}
              />
            );
          }}
        />

        <Controller
          control={control}
          name="concession"
          render={({ field: { onChange }, formState: { defaultValues } }) => {
            return (
              <DropDownInput
                ref={vehicleConcessionRef}
                inputSize="M"
                onChangeSelected={(option) => onChange(option?.value ?? '')}
                typeDropDown="Default"
                labelText="Concessió"
                placeholder="Selecciona"
                defaultValue={defaultValues?.concession}
                borderError={!!errors.concession?.message}
                options={concessionOptions}
              />
            );
          }}
        />
        <AddVehicleButton>⮕</AddVehicleButton>
        {repeatedPlateError && !errors.plate?.message && (
          <Typography colorText="lightCoralRed" size="XS">
            {repeatedPlateError}
          </Typography>
        )}
      </InputsContainer>
    </Container>
  );
};

// Types
import { FilterFn } from '@tanstack/react-table';

//Custom filter function for cotic
/**
 * @param row
 * @param columnId
 * @param filterValue
 * @returns true or false
 */
export const coticFilterFunction: FilterFn<string[]> = (
  row,
  columnId: string,
  filterValue: string[]
) => {
  // rowValue are the values of each row for the column, in this case 'cotic'
  const rowValue: string = row.getValue(columnId);
  // Iterate over each filter value and ensure that the row cell value includes each of them.
  // Checks if the value of the cell of the row includes the current value of the array
  return filterValue.every((val, i) => rowValue[i].includes(val));
  // If all the filter values are in the cell value of the row, the function returns true, which means that the row should be kept in the table.
  // Otherwise, the function returns false, which means that the row should be removed from the table.
};

export const dateFilterFunction: FilterFn<string> = (
  row,
  columnId: string,
  filterValue: [number, number]
) => {
  const [min, max] = filterValue;

  const rowValue = new Date(row.getValue<number>(columnId)).getTime();
  return rowValue >= min && rowValue <= max;
};

import { gql } from '@apollo/client';

export const GET_COMMUNICATION_BY_ID = gql`
  query GetCommunicationById($id: ID!) {
    communication(id: $id) {
      id
      sendStatus
      interlocutorGroupIDs
      mediaOfCommunicationIDs
      concessionIDs
      roadIDs
      track
      direction
      margin
      lane
      pkInitKm
      pkInitMeter
      pkEndKm
      pkEndMeter
      orderIDs
    }
  }
`;

export const FETCH_COMMUNICATIONS = gql`
  query FetchCommunications($input: FilterCommunicationList!) {
    communications(input: $input) {
      communications {
        id
        sendStatus
        interlocutorGroupIDs
        mediaOfCommunicationIDs
        concessionIDs
        roadIDs
        track
        direction
        margin
        lane
        pkInitKm
        pkInitMeter
        pkEndKm
        pkEndMeter
        orderIDs
      }
      totalCount
      pageInfo {
        hasPreviousPage
        hasNextPage
        lastCursor
      }
    }
  }
`;

export const GET_INTERLOCUTOR_GROUP_BY_ID = gql`
  query GetInterlocutorGroupById($id: ID!) {
    interlocutorGroup(id: $id) {
      id
      name
    }
  }
`;

export const GET_MEDIA_OF_COMMUNICATION_BY_ID = gql`
  query GetMediaOfCommunicationById($id: ID!) {
    mediaOfCommunication(id: $id) {
      id
      name
    }
  }
`;

// Defs
export const ROW_MODAL_FORM_SELECTED = 'ROW_MODAL_FORM_SELECTED';
export const ACCEPT_DATA_MODAL_FORM = 'ACCEPT_DATA_MODAL_FORM';
export const ROW_MODAL_FORM_DESELECTED = 'ROW_MODAL_FORM_DESELECTED';

export function selectedRowModalTable(id: string, data: unknown = {}) {
  return {
    type: ROW_MODAL_FORM_SELECTED,
    payload: {
      id,
      data,
    },
  };
}

export function deselectedRowModalTable(id: string) {
  return {
    type: ROW_MODAL_FORM_DESELECTED,
    payload: { id },
  };
}

export function selectedRowModalAcceptData(id: string) {
  return {
    type: ACCEPT_DATA_MODAL_FORM,
    payload: {
      id,
    },
  };
}

import styled from 'styled-components';

// Styles utils
import { colors } from '../../../styles/colors';
import { fontSize, fontWeight } from '../../../styles/fonts';
import {
  breakPoints,
  percentBaseResponsive,
  spaces,
} from '../../../styles/spaces';

// Components
import InputText from '../../inputs/InputText';
import { Label } from '../../inputs/InputText/styles';
import {
  InputContainer,
  InputValue,
  Container as ContainerInputText,
} from '../../inputs/InputText/styles';
import { InputTextArea } from '../../inputs/InputTextArea';
import { Area, Container } from '../../inputs/InputTextArea/styles';
import { Typography } from '../Typography';
import DoubleSwitch from '../../inputs/buttons/SwitchButton/Double';
import { Container as ContainerSwitch } from '../../inputs/buttons/SwitchButton/Double/styles';
import { RightArrowProps } from './types';

// Types

export const ContainerOT = styled.div`
  max-width: 89%;
  width: 100%;
  background-color: ${colors.greyClear};
  border-radius: 8px;
  padding: ${spaces.xl}rem;

  @media (max-width: ${breakPoints.landscapeTablet *
    percentBaseResponsive}rem) {
    max-width: 87%;
    padding: ${spaces.l}rem;
  }
  @media (max-width: ${breakPoints.portraitTablet * percentBaseResponsive}rem) {
    max-width: 100%;
  }
`;

export const ContainerHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${spaces.m}rem;
  cursor: pointer;
`;

export const TitleOrder = styled.h2`
  font-size: ${fontSize.L};
  font-weight: ${fontWeight.semiBold};

  @media (max-width: ${breakPoints.landscapeTablet *
    percentBaseResponsive}rem) {
    font-size: ${fontSize.M};
  }
`;

export const TitleDate = styled.span`
  @media (max-width: ${breakPoints.landscapeTablet *
    percentBaseResponsive}rem) {
    display: block;
    font-size: ${fontSize.S};
  }
`;

export const TagsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${spaces.xs}rem;
`;

export const ContainerRefIcon = styled.div`
  display: flex;
  align-items: center;
  gap: ${spaces.xs}rem;
  @media (max-width: ${breakPoints.landscapeTablet *
    percentBaseResponsive}rem) {
    flex-direction: column;
    gap: ${spaces.xxxs}rem;
  }
`;

export const ContainerTitleArrow = styled.div`
  display: flex;
  align-items: center;
  gap: ${spaces.xs}rem;
`;

export const RefCotic = styled(Typography)`
  font-size: ${fontSize.S};
`;

export const RightArrow = styled.img<RightArrowProps>`
  transform: ${({ showInfo }) => (showInfo ? `rotate(90deg)` : null)};
  width: 1.4rem;
  height: 1rem;
`;

export const ContainerContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  gap: ${spaces.l}rem;
  @media (max-width: ${breakPoints.portraitTablet * percentBaseResponsive}rem) {
    gap: ${spaces.m}rem;
  }
`;

export const DateContainer = styled.div`
  display: flex;
  gap: ${spaces.s}rem;
`;

export const ContainerData = styled.div`
  display: flex;
  margin-top: 3.2rem;
  gap: ${spaces.l}rem;
  @media (max-width: ${breakPoints.landscapeTablet *
    percentBaseResponsive}rem) {
    flex-wrap: wrap;
  }
  @media (max-width: ${breakPoints.portraitTablet * percentBaseResponsive}rem) {
    gap: ${spaces.m}rem;
    margin-top: ${spaces.m}rem;
  }
`;

export const ContainerDescription = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  gap: 2.9rem;

  @media (max-width: ${breakPoints.portraitTablet * percentBaseResponsive}rem) {
    gap: 2.2rem;
  }
`;

export const InputDescription = styled(InputTextArea)`
  &${Container} {
    width: 100%;
    min-height: 8.7rem;
  }
  ${Area} {
    background-color: ${({ disabled }) =>
      disabled ? `${colors.greyLight}` : `${colors.white}`};
    border: ${({ disabled }) => (disabled ? `none` : null)};
    color: ${({ disabled }) =>
      disabled ? `${colors.greyVeryDark}` : `${colors.black}`};
    width: 100%;
    min-height: 8.7rem;

    @media (max-width: ${breakPoints.landscapeTablet *
      percentBaseResponsive}rem) {
      max-width: 74.325rem;
      height: 12.9rem;
      font-size: ${fontSize.XS};
    }
    @media (max-width: ${breakPoints.portraitTablet *
      percentBaseResponsive}rem) {
      max-width: 46.8rem;
      height: 8.7rem;
      font-size: ${fontSize.XS};
    }
  }
`;

export const InputOperaris = styled(InputText)`
  &${ContainerInputText} {
    max-width: 37rem;
  }
  ${InputContainer} {
    max-width: 37rem;
    padding: 0.7rem 1.4rem;
  }

  ${InputValue} {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  @media (max-width: ${breakPoints.landscapeTablet *
    percentBaseResponsive}rem) {
    ${InputValue} {
      max-width: 36rem;
      font-size: ${fontSize.XS};
    }
    ${Label} {
      font-size: ${fontSize.XS};
    }
  }

  @media (max-width: ${breakPoints.portraitTablet * percentBaseResponsive}rem) {
    ${InputValue} {
      max-width: 35rem;
    }
    &${ContainerInputText} {
      max-width: 35rem;
    }
    ${InputContainer} {
      max-width: 35rem;
    }
  }
`;

export const InputAction = styled(InputText)`
  &${ContainerInputText} {
    max-width: 37rem;
  }
  ${InputContainer} {
    max-width: 37rem;
    padding: 0.7rem 1.4rem;
  }
  @media (max-width: ${breakPoints.landscapeTablet *
    percentBaseResponsive}rem) {
    ${InputValue} {
      max-width: 36rem;
      font-size: ${fontSize.XS};
    }
    ${Label} {
      font-size: ${fontSize.XS};
    }
  }

  @media (max-width: ${breakPoints.portraitTablet * percentBaseResponsive}rem) {
    ${InputValue} {
      max-width: 30rem;
    }
    &${ContainerInputText} {
      max-width: 30rem;
    }
    ${InputContainer} {
      max-width: 30rem;
    }
  }
`;

export const SwitchButton = styled(DoubleSwitch)`
  &${ContainerSwitch} {
    min-width: 9.7rem;
  }
  @media (max-width: ${breakPoints.portraitTablet * percentBaseResponsive}rem) {
    &${ContainerSwitch} {
      min-width: 8.5rem;
    }
  }
`;

import styled from 'styled-components';
import { colors } from '../../../../styles/colors';
import { spaces } from '../../../../styles/spaces';

export const Container = styled.div`
  width: 100%;
`;

export const MaterialsManagementSection = styled.div`
  padding: ${spaces.xl}rem;
  border-radius: 1rem;
  background: ${colors.verySoftRed};
  margin-bottom: ${spaces.xl}rem;
  width: 100%;
`;

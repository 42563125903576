// Types
import { CommunicationInputParams } from './types';

export const getCommunicationInput = (
  communication: CommunicationInputParams
) => ({
  sendStatus: communication.sendStatus,
  interlocutorGroupId: communication.interlocutorGroupIDs[0],
  mediaOfCommunicationId: communication.mediaOfCommunicationIDs[0],
  concessionId: communication.concessionIDs[0],
  roadId: communication.roadIDs[0],
  track: communication.track,
  lane: communication.lane,
  direction: communication?.direction,
  margin: communication?.margin,
  pkInitKm: communication.pkInitKm,
  pkInitMeter: communication.pkInitMeter,
  pkEndKm: communication.pkEndKm,
  pkEndMeter: communication.pkEndMeter,
});

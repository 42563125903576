import { redirect } from 'react-router-dom';

// Constants
import { formNaming } from '../../../../../../components/forms/NewCommunicationForm/constants';
import { tabsType } from '../../../../../../state/reducers/ui/tabs';

// GQL
import { client } from '../../../../../../gql/client';
import { CREATE_NEW_COMMUNICATION } from '../../../../../../gql/mutations/communication';
import { CREATE_NEW_ORDER } from '../../../../../../gql/mutations/orders';
import { GET_ORDER_BY_ID } from '../../../../../../gql/queries/orders/orders';
import {
  GET_CAPITOL_BY_ID,
  GET_CLASSIFICATION_BY_ID_OR_COTIC,
  GET_SUB_CAPITOL_BY_ID,
} from '../../../../../../gql/queries/orders/predefinedData';
import { NEW_COMMUNICATION } from '../../../../../../gql/fragments/communications';
import { GET_COMMUNICATION_BY_ID } from '../../../../../../gql/queries/communication';

// Redux
import { indexTabFormReset } from '../../../../../../state/actions/ui/tabs';
import { deselectedRowModalTable } from '../../../../../../state/actions/ui/selectRowTableModal';
import { setCursor } from '../../../../../../state/actions/ui/cursorTables';
import { getCursor } from '../../../../../../state/selectors/ui/tables';

// Types
import { OrderState, OrderType } from '../../../../../../types/orders';
import {
  CommunicationInput,
  SaveDataParams,
  orderCommunicationInputTypes,
} from './types';
import {
  tableIds,
  takeBase,
} from '../../../../../../components/display/tables/types';

// Utils
import { decompressSessionStorage } from '../../../../../../utils/sessionStorage';
import { truncateArrayAfterNull } from '../../../../../../utils/mergeArrays';
import { getCommunicationInput } from './utils';
import { NEW_ORDER } from '../../../../../../gql/fragments/orders';

export const saveData = async ({
  id,
  tabSelected,
  storedDataOTSelected,
  store,
}: SaveDataParams) => {
  let orderCommunicationInput: orderCommunicationInputTypes = {
      type: OrderType.Communication,
      state: OrderState.Open,
      creationDate: '',
      registerInitDate: '',
      specialTag: '',
      description: '',
      classification: '',
      capitol: '',
      subCapitol: '',
      parentExpeditionOrderID: '',
      currentExpeditionIndex: '',
    },
    communicationInput: CommunicationInput = {};
  const dispatch = store.dispatch;
  let dataForm = null;

  const cursorDuplicateCommunication = getCursor(
    tableIds.DUPLICATE_COMMUNICATION
  )(store.getState());
  const cursorSearchAndCreateCommunication = getCursor(
    tableIds.SEARCH_AND_CREATE_HISTORICAL_COMMUNICATIONS
  )(store.getState());
  const cursorWorkOrdersCommunication = getCursor(
    tableIds.WORKORDER_COMMUNICATIONS
  )(store.getState());

  const creationDate = new Date().toISOString();
  /// Step 1: Get data Communication
  const {
    data: { order },
  } = await client.query({
    query: GET_ORDER_BY_ID,
    variables: {
      id: id,
    },
  });
  const dataFormRaw = sessionStorage.getItem(
    formNaming.NEW_DUPLICATE_COMMUNICATION_FORM
  );
  if (dataFormRaw) {
    dataForm = JSON.parse(decompressSessionStorage(dataFormRaw));
  }

  if (tabSelected === 0) {
    orderCommunicationInput = {
      type: OrderType.Communication,
      state: OrderState.Open,
      creationDate,
      registerInitDate: dataForm?.registerInitDate ?? order.registerInitDate,
      specialTag: null,
      description: dataForm?.description ?? order.description,
      classification: dataForm?.classification ?? order.classificationIDs[0],
      capitol: dataForm?.capitol ?? order.capitolIDs[0],
      subCapitol: dataForm?.subCapitol ?? order.subCapitolIDs[0],
      parentExpeditionOrderID: order.parentExpeditionOrderIDs[0],
      currentExpeditionIndex: order.indexExpedition,
    };
  }

  if (tabSelected > 0) {
    const {
      data: { order },
    } = await client.query({
      query: GET_ORDER_BY_ID,
      variables: {
        id: storedDataOTSelected?.id,
      },
    });
    const [
      {
        data: { capitol },
      },
      {
        data: { subCapitol },
      },
      {
        data: { classification },
      },
    ] = await Promise.all([
      client.query({
        query: GET_CAPITOL_BY_ID,
        variables: {
          id: order.capitolIDs[0],
        },
      }),
      client.query({
        query: GET_SUB_CAPITOL_BY_ID,
        variables: {
          id: order.subCapitolIDs[0],
        },
      }),
      client.query({
        query: GET_CLASSIFICATION_BY_ID_OR_COTIC,
        variables: {
          id: order.classificationIDs[0],
        },
      }),
    ]);
    orderCommunicationInput = {
      type: OrderType.Communication,
      state: OrderState.Open,
      creationDate,
      registerInitDate: dataForm?.registerInitDate ?? order.registerInitDate,
      description: dataForm?.description ?? order.description,
      classification: classification.id,
      capitol: capitol.id,
      subCapitol: subCapitol.id,
      parentExpeditionOrderID: order.id,
      currentExpeditionIndex: order.indexExpedition,
    };
  }

  if (!dataForm) {
    const {
      data: { communication },
    } = await client.query({
      query: GET_COMMUNICATION_BY_ID,
      variables: {
        id: order.communicationIDs[0],
      },
    });

    communicationInput = getCommunicationInput(communication);
  } else {
    const pkInitArray = dataForm.pkInit.replace(/ /g, '').split('+');
    const pkEndArray = dataForm.pkEnd.replace(/ /g, '').split('+');

    communicationInput = {
      sendStatus: dataForm.sendStatus,
      creationDate,
      registerInitDate: dataForm?.registerInitDate ?? order.registerInitDate,
      interlocutorGroupId: dataForm.groupInter,
      mediaOfCommunicationId: dataForm.media,
      concessionId: dataForm.concession,
      roadId: dataForm.road,
      track: dataForm.track,
      lane: dataForm.lane,
      direction: dataForm?.direction,
      margin: dataForm?.margin,
      pkInitKm: Number(pkInitArray[0]),
      pkInitMeter: Number(pkInitArray[1]),
      pkEndKm: Number(pkEndArray[0]),
      pkEndMeter: Number(pkEndArray[1]),
    };
  }

  const dataOrderCommunication = await client.mutate({
    mutation: CREATE_NEW_ORDER,
    variables: {
      input: {
        ...orderCommunicationInput,
        specialTag: null,
      },
    },

    update(cache, { data: { createOrder } }) {
      cache.modify({
        id: `Order:${orderCommunicationInput?.parentExpeditionOrderID}`,
        fields: {
          childExpeditionOrderIDs(variables = [], { readField }) {
            const newOrderRef = cache.writeFragment({
              data: createOrder,
              fragment: NEW_ORDER,
            });
            const newId = readField('id', newOrderRef);
            return [...variables, newId];
          },
        },
      });
    },
  });

  communicationInput.orderId = dataOrderCommunication.data.createOrder.id;
  //DOC: Create communication
  await client.mutate({
    mutation: CREATE_NEW_COMMUNICATION,
    variables: {
      input: communicationInput,
    },
    update(cache, { data: { createCommunication } }) {
      cache.modify({
        fields: {
          communications(variables = {}) {
            const newCommunicationRef = cache.writeFragment({
              data: createCommunication,
              fragment: NEW_COMMUNICATION,
            });

            const truncatedArray = truncateArrayAfterNull(
              variables.communications
            );

            const newCommunicationArray = [
              newCommunicationRef,
              ...truncatedArray,
            ];

            newCommunicationArray.splice(-2, 1);

            const setCursorDataDuplicateCommunications = {
              ...cursorDuplicateCommunication,
              take: takeBase,
              cursor: '',
              orderBy: 'desc',
            };
            const setCursorDataWorkOrderCommunication = {
              ...cursorWorkOrdersCommunication,
              take: takeBase,
              cursor: '',
              orderBy: 'desc',
            };
            const setCursorDataSearchAndCreateCommunication = {
              ...cursorSearchAndCreateCommunication,
              take: takeBase,
              cursor: '',
              orderBy: 'desc',
            };

            dispatch(
              setCursor(
                tableIds.DUPLICATE_COMMUNICATION,
                setCursorDataDuplicateCommunications
              )
            );
            dispatch(
              setCursor(
                tableIds.WORKORDER_COMMUNICATIONS,
                setCursorDataWorkOrderCommunication
              )
            );
            dispatch(
              setCursor(
                tableIds.SEARCH_AND_CREATE_HISTORICAL_COMMUNICATIONS,
                setCursorDataSearchAndCreateCommunication
              )
            );

            return {
              ...variables,
              communications: newCommunicationArray,
              totalCount: variables.totalCount + 1,
              pageInfo: {},
            };
          },
        },
      });
    },
  });

  // Refetch
  client.query({
    fetchPolicy: 'network-only',
    query: GET_ORDER_BY_ID,
    variables: { id: dataOrderCommunication.data.createOrder.id },
  });

  // Clean the form local storage as it was just saved
  sessionStorage.removeItem(formNaming.NEW_DUPLICATE_COMMUNICATION_FORM);
  dispatch(
    deselectedRowModalTable(formNaming.NEW_DUPLICATE_COMMUNICATION_FORM)
  );
  dispatch(indexTabFormReset(tabsType.DUPLICATE_COMMUNICATION));
  return redirect('..');
};

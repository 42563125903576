import { useState } from 'react';

// Sentry
import * as Sentry from '@sentry/react';

// Compression
import {
  compressSessionStorage,
  decompressSessionStorage,
} from '../utils/sessionStorage';

export const useSessionStorage = (keyName: string) => {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const value = sessionStorage.getItem(keyName);
      if (value) {
        return JSON.parse(decompressSessionStorage(value));
      }
      return null;
    } catch (err) {
      Sentry.captureException(err);
      console.log(`not support sessionStorage`);
    }
  });

  const setValue = (newValue: unknown) => {
    try {
      if (newValue === null) {
        sessionStorage.removeItem(keyName);
      } else {
        sessionStorage.setItem(
          keyName,
          compressSessionStorage(JSON.stringify(newValue))
        );
      }
    } catch (err) {
      Sentry.captureException(err);
      console.log(`not support sessionStorage`);
    }
    setStoredValue(newValue);
  };

  return [storedValue, setValue];
};

export type DropDownUserMenuProps = {
  userName: string;
  userMail: string;
  userRole: string;
};

export const Roles: { [key: string]: string } = {
  'super-admin': 'Super Admin',
  ceconsa: 'Super Ceconsa',
  'room-team': 'Operador de sala',
  tablet: 'Usuari de Tablet',
  'operating-team': 'Operari de carretera',
};

import { defer, LoaderFunction } from 'react-router-dom';

// Sentry
import * as Sentry from '@sentry/react';

// GQL
import { client } from '../../../../../gql/client';
import {
  FETCH_PREDEFINED_ORDERS,
  FETCH_SUB_CAPITOLS,
} from '../../../../../gql/queries/orders/predefinedData';
import { GET_SELECTORS_DATA } from '../../../../../gql/queries/orders/orders';
import { formNaming } from '../../../../../components/forms/NewCommunicationForm/constants';
import {
  FETCH_CAPITOLS,
  FETCH_CLASSIFICATIONS,
} from '../../../../../gql/queries/orders/predefinedData';

// Type
import { AuthContextType } from '../../../../../hooks/useAuth';

// Data static
import {
  groupLanes,
  groupTracks,
  specialTagType,
} from '../../../../../data/orders';

// Compression
import { decompressSessionStorage } from '../../../../../utils/sessionStorage';
import { selectCommunicationOrderByIdOrderWithRelationshipIds } from '../../../../../gql/selectors/communications';

const loadNewCommunicationSummary = async (
  idOrder: string,
  userData: AuthContextType | null
) => {
  try {
    const orderCommunicationData =
      await selectCommunicationOrderByIdOrderWithRelationshipIds(idOrder);
    return {
      ...orderCommunicationData,
      operator: `${userData?.user.user.name} ${
        userData?.user.user.surName.split(' ')[0]
      }`,
    };
  } catch (err) {
    Sentry.captureException(err);
    console.log('> Communication Summary loading error:', err);
    return true;
  }
};

const loadFormOptions = async (userData: AuthContextType | null) => {
  try {
    if (userData?.user) {
      const [
        { data },
        {
          data: { capitols },
        },
        {
          data: { predefinedOrders },
        },
        {
          data: { classifications },
        },
        {
          data: { subCapitols },
        },
      ] = await Promise.all([
        client.query({
          query: GET_SELECTORS_DATA,
        }),
        client.query({
          query: FETCH_CAPITOLS,
        }),
        client.query({
          query: FETCH_PREDEFINED_ORDERS,
        }),
        client.query({
          query: FETCH_CLASSIFICATIONS,
        }),
        client.query({
          query: FETCH_SUB_CAPITOLS,
        }),
      ]);

      return {
        ...data,
        capitols,
        predefinedOrders,
        classifications,
        groupTracks,
        groupLanes,
        specialTagType,
        subCapitols,
      };
    }
    return {};
  } catch (err) {
    Sentry.captureException(err);
    console.log('> Communication Summary loading error:', err);
    return true;
  }
};

export const NewCommunicationDuplicateLoader: (
  userData: AuthContextType | null
) => LoaderFunction | undefined =
  (userData) =>
  async ({ params }) => {
    let dataFromCommunicationToDuplicate = {};

    if (params.id) {
      dataFromCommunicationToDuplicate = await loadNewCommunicationSummary(
        params.id,
        userData
      );
    }

    // Pick the cached form values from local storage in case browser was reloaded
    const stringifyForm = sessionStorage.getItem(
      formNaming.NEW_DUPLICATE_COMMUNICATION_FORM
    );

    const loaderToResolve = await loadFormOptions(userData);

    if (stringifyForm) {
      const sessionStorageData = JSON.parse(
        decompressSessionStorage(stringifyForm)
      );

      const combinedData = {
        team: userData?.user?.user?.teamId,
        ...loaderToResolve,
        ...dataFromCommunicationToDuplicate,
        ...sessionStorageData,
      };

      return defer({
        data: combinedData,
      });
    }

    const nameOperator = `${userData?.user?.user.name} ${
      userData?.user?.user.surName.split(' ')[0]
    }`;
    const combinedData = {
      team: userData?.user?.user?.teamId,
      ...loaderToResolve,
      ...dataFromCommunicationToDuplicate,
      operator: nameOperator,
      isCommunicationDuplicate: true,
    };

    // Prepare some default values for the form to load properly
    return defer({
      data: combinedData,
    });
  };

import styled from 'styled-components';

// Colors
import { colors } from '../../../styles/colors';

// Components
import { Typography } from '../Typography';

// Spaces
import { spaces } from '../../../styles/spaces';

// Types
import { ContainerProps } from './types';

export const Container = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 27rem;
  border-radius: 0.5rem;
  padding: 0.3rem 1.3rem;
`;

export const ContainerInput = styled.div<ContainerProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 3.5rem;
  margin-bottom: 0.1rem;
  ${(props) =>
    !props.lastElement && {
      borderBottom: `0.1rem solid ${colors.paleDarkGrey}`,
    }}
`;

export const Text = styled(Typography)`
  margin: 0 1rem;
  padding: 1rem 0;
  white-space: nowrap;
  overflow-x: scroll;
`;

export const SmallContainer = styled.div`
  display: flex;
  align-items: center;
  width: 80%;
`;

export const ContainerRemoveIcon = styled.img`
  display: flex;
  width: ${spaces.xs}rem;
  height: 1.75rem;
  cursor: pointer;
`;

export const ContainerFileIcon = styled(ContainerRemoveIcon)`
  margin-top: 0.3rem;
  cursor: auto;
`;

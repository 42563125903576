import React, { useContext } from 'react';

import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route,
  RouterProvider,
} from 'react-router-dom';
import { Router } from '@remix-run/router';
import * as Sentry from '@sentry/react';

// Layouts
import AuthLayout from '../components/layouts/AuthLayout';
import MainLayout from '../components/layouts/MainLayout';
import CommunicationsLayout from '../pages/main/comunications/CommunicationsLayout';
import WorkOrdersLayout from '../pages/main/workOrders/WorkOrdersLayout';
import LayoutResetIndex from '../pages/main/comunications/components/LayoutResetIndex';

// Pages Main
import DashboardPage from '../pages/main/Dashboard';
// import SettingsPage from '../pages/main/Settings';
import NewCommunicationPage from '../pages/main/comunications/NewComunication';
import NewWorkOrderSummary from '../pages/main/workOrders/NewOrder/WorkOrderSummary';
import NewCommunicationSummary from '../pages/main/comunications/NewComunication/CommunicationSummary';
import SearchAndCreateHistoricalCommunicationSummary from '../pages/main/comunications/SearchAndCreateHistoricalCommunication/CommunicationSummary';
import DuplicateCommunicationPage from '../pages/main/comunications/DuplicateComunication';
import NewCommunicationDuplicatePage from '../pages/main/comunications/DuplicateComunication/NewComunication';
import NewCommunicationDuplicateSummaryPage from '../pages/main/comunications/DuplicateComunication/CommunicationSummary';
import SearchAndCreateHistoricalCommunicationPage from '../pages/main/comunications/SearchAndCreateHistoricalCommunication';
import GeneralViewPage from '../pages/main/workOrders/GeneralView';
import NewOrderPage from '../pages/main/workOrders/NewOrder';
import DuplicateOrderPage from '../pages/main/workOrders/DuplicateOrder';
import SearchAndCreateHistoricalOrderPage from '../pages/main/workOrders/SearchAndCreateHistoricalOrder';
import CampaignsPage from '../pages/main/workOrders/Campaigns';
import NewCampaignPage from '../pages/main/workOrders/NewCampaign';
import ActionsValidation from '../pages/main/workOrders/ActionsValidation';
// import SurveillanceRouteLayout from '../pages/main/surveillanceRoute/SurveillanceRouteLayout';
// import GeneralViewRoutePage from '../pages/main/surveillanceRoute/GeneralViewRoute';
// import NewRoutePage from '../pages/main/surveillanceRoute/NewRoute';
// import SearchAndCreateHistoricalRoutePage from '../pages/main/surveillanceRoute/SearchAndCreateHistoricalRoute';
// import ReportingLayout from '../pages/main/reporting/ReportingLayout';
// import TimeManagementPage from '../pages/main/reporting/TimeManagement';
// import DocumentSigningPage from '../pages/main/reporting/DocumentSigning';
import WorkOrderDetailed from '../pages/main/workOrders/WorkOrderDetailed';
import NewOrderDuplicatePage from '../pages/main/workOrders/DuplicateOrder/NewOrderDuplicate';
import WorkOrderDuplicateSummaryPage from '../pages/main/workOrders/DuplicateOrder/WorkOrderSummary';
import EditCampaignPage from '../pages/main/workOrders/EditCampaign';
import CampaignSummary from '../pages/main/workOrders/NewCampaign/CampaignSummary';
import CampaignDetailed from '../pages/main/workOrders/CampaignDetailed';
import SearchAndCreateHistoryWorkOrderSummary from '../pages/main/workOrders/SearchAndCreateHistoricalOrder/WorkOrderSummary';
import EditCampaignSummaryPage from '../pages/main/workOrders/EditCampaign/CampaignSummary';
import AddBasicInfoAccidentPage from '../pages/main/workOrders/AddBasicInfoAccident';
import AddVehiclePage from '../pages/main/workOrders/AddVehicle';
import ActionPage from '../pages/main/workOrders/NewAction/ActionPage';
import AccidentSummaryGeneralView from '../pages/main/workOrders/GeneralView/AccidentsPage/AccidentPageSummary';
import AnimalsFormPage from '../pages/main/workOrders/NewAction/Animals';
import OperatorsAndVehiclesFormPage from '../pages/main/workOrders/NewAction/OperatorsAndVehicles';
import MaterialsFormPage from '../pages/main/workOrders/NewAction/Materials';
import MachineryFormPage from '../pages/main/workOrders/NewAction/Machinery';
import EditionActionFormPage from '../pages/main/workOrders/NewAction/Edition';
import LocationFormPage from '../pages/main/workOrders/NewAction/Location';
import ActionSummaryPage from '../pages/main/workOrders/NewAction/ActionSummary';
import NewActionPage from '../pages/main/workOrders/NewAction';
import OperatorsAndVehiclesFormValidationPage from '../pages/main/workOrders/ActionsValidation/OperatorsAndVehicles';
import NewAccidentPage from '../pages/main/workOrders/NewAccident';
import { WorkOrdersPage } from '../pages/main/workOrders/GeneralView/WorkOrdersPage';
import { AccidentsPage } from '../pages/main/workOrders/GeneralView/AccidentsPage';
import { CommunicationsPage } from '../pages/main/workOrders/GeneralView/CommunicationsPage';
import { MultimediaPage } from '../pages/main/workOrders/GeneralView/MultimediaPage';
import { CommunicationSummaryGeneralView } from '../pages/main/workOrders/GeneralView/CommunicationsPage/CommunicationSummary';
import { MultimediaGridGeneralView } from '../pages/main/workOrders/GeneralView/MultimediaPage/MultimediaGrid';
import ActionValidationPage from '../pages/main/workOrders/ActionsValidation/ActionPage';
import LocationActionFormPage from '../pages/main/workOrders/ActionsValidation/Location';
import EditionValidationFormPage from '../pages/main/workOrders/ActionsValidation/Edition';
import MaterialsValidationFormPage from '../pages/main/workOrders/ActionsValidation/Materials';
import MachineryValidationFormPage from '../pages/main/workOrders/ActionsValidation/Machinery';
import AnimalsValidationFormPage from '../pages/main/workOrders/ActionsValidation/Animals';
import UsersManagementPage from '../pages/main/management/users';
import VehiclesManagementPage from '../pages/main/management/vehicles';
import ManagementRoutesLayout from '../pages/main/management/managementRouteLayout';
import MaterialsManagementPage from '../pages/main/management/materials';
import EditCommunicationPage from '../pages/main/comunications/SearchAndCreateHistoricalCommunication/EditCommunication';

// Pages Auth
import LoginPage from '../pages/auth/Login';
import ResetPasswordPage from '../pages/auth/ResetPassword';

// Roles Auth Guard
import { RolesAuthRoute } from './RolesAuthRoute';

// Pages Error
import ErrorPage from '../pages/utils/Error';

// Loaders
import { LoaderIndexPage } from './loaderIndexPage';
import { LoaderBaseDataMain } from '../components/layouts/MainLayout/loader';
import { NewCommunicationLoader } from '../pages/main/comunications/NewComunication/loader';
import { SearchAndCreateHistoricalLoader } from '../pages/main/comunications/SearchAndCreateHistoricalCommunication/loader';
import { LoaderSearchAndCreateHistoricalCommunicationSummary } from '../pages/main/comunications/SearchAndCreateHistoricalCommunication/CommunicationSummary/loader';
import { LoaderCommunicationDuplicates } from '../pages/main/comunications/DuplicateComunication/loader';
import { NewCommunicationDuplicateLoader } from '../pages/main/comunications/DuplicateComunication/NewComunication/loader';
import { LoaderCommunicationDuplicateSummary } from '../pages/main/comunications/DuplicateComunication/CommunicationSummary/loader';
import { NewWorkOrderLoader } from '../pages/main/workOrders/NewOrder/loader';
import { LoaderWorkOrderDetailed } from '../pages/main/workOrders/WorkOrderDetailed/loader';
import { NewWorkOrderDuplicateLoader } from '../pages/main/workOrders/DuplicateOrder/NewOrderDuplicate/loader';
import { LoaderOrderDuplicates } from '../pages/main/workOrders/DuplicateOrder/loader';
import { LoaderOrderSummaryDuplicates } from '../pages/main/workOrders/DuplicateOrder/WorkOrderSummary/loader';
import { LoaderWorkOrdersGeneralView } from '../pages/main/workOrders/GeneralView/loader';
import { SearchAndCreateHistoricalWorkOrdersLoader } from '../pages/main/workOrders/SearchAndCreateHistoricalOrder/loader';
import { LoaderCampaigns } from '../pages/main/workOrders/Campaigns/loader';
import { EditCampaignLoader } from '../pages/main/workOrders/EditCampaign/loader';
import { LoaderCampaignDetailed } from '../pages/main/workOrders/CampaignDetailed/loader';
import { LoaderSearchAndCreateHistoricalWorkOrderSummary } from '../pages/main/workOrders/SearchAndCreateHistoricalOrder/WorkOrderSummary/loader';
import { AddBasicInfoAccidentLoader } from '../pages/main/workOrders/AddBasicInfoAccident/loader';
import { NewAccidentLoader } from '../pages/main/workOrders/NewAccident/loader';
import { VehicleLoader } from '../pages/main/workOrders/AddVehicle/loader';
import { OperatorsAndVehiclesLoader } from '../pages/main/workOrders/NewAction/OperatorsAndVehicles/loader';
import { NewActionLoader } from '../pages/main/workOrders/NewAction/loader';
import { LocationLoader } from '../pages/main/workOrders/NewAction/Location/loader';
import { BuildingLoader } from '../pages/main/workOrders/NewAction/Edition/loader';
import { MaterialsLoader } from '../pages/main/workOrders/NewAction/Materials/loader';
import { MachineryLoader } from '../pages/main/workOrders/NewAction/Machinery/loader';
import { AnimalsLoader } from '../pages/main/workOrders/NewAction/Animals/loader';
import { ActionSummaryLoader } from '../pages/main/workOrders/NewAction/ActionSummary/loader';
import { LoaderGeneralViewCommunicationSummary } from '../pages/main/workOrders/GeneralView/CommunicationsPage/CommunicationSummary/loader';
import { LoaderActionPageOrder } from '../pages/main/workOrders/NewAction/ActionPage/loader';
import { LoaderCampaignSummary } from '../pages/main/workOrders/EditCampaign/CampaignSummary/loader';
import { ActionsValidationLoader } from '../pages/main/workOrders/ActionsValidation/loader';
import { OperatorsAndVehiclesValidationLoader } from '../pages/main/workOrders/ActionsValidation/OperatorsAndVehicles/loader';
import { LoaderGeneralViewAccidentSummary } from '../pages/main/workOrders/GeneralView/AccidentsPage/AccidentPageSummary/loader';
import { LoaderAccidentGeneralView } from '../pages/main/workOrders/GeneralView/AccidentsPage/loader';
import { LoaderMultimediaGeneralView } from '../pages/main/workOrders/GeneralView/MultimediaPage/loader';
import { LoaderGeneralViewMultimediaGrid } from '../pages/main/workOrders/GeneralView/MultimediaPage/MultimediaGrid/loader';
import { LoaderCommunicationGeneralView } from '../pages/main/workOrders/GeneralView/CommunicationsPage/loader';
import { LocationValidationLoader } from '../pages/main/workOrders/ActionsValidation/Location/loader';
import { EditionValidationLoader } from '../pages/main/workOrders/ActionsValidation/Edition/loader';
import { MaterialsValidationLoader } from '../pages/main/workOrders/ActionsValidation/Materials/loader';
import { MachineryValidationLoader } from '../pages/main/workOrders/ActionsValidation/Machinery/loader';
import { AnimalsValidationLoader } from '../pages/main/workOrders/ActionsValidation/Animals/loader';
import { LoaderActionValidationPageOrder } from '../pages/main/workOrders/ActionsValidation/ActionPage/loader';
import { VehiclesManagementLoader } from '../pages/main/management/vehicles/loader';
import { UsersManagementLoader } from '../pages/main/management/users/loader';
import { MaterialsManagementLoader } from '../pages/main/management/materials/loader';
import { EditCommunicationLoader } from '../pages/main/comunications/SearchAndCreateHistoricalCommunication/EditCommunication/loader';
import { EditCommunicationSummary } from '../pages/main/comunications/SearchAndCreateHistoricalCommunication/CommunicationSummary/action';

// Actions
import { ActionLogin } from '../pages/auth/Login/action';
import { ActionResetPassword } from '../pages/auth/ResetPassword/action';
import { ActionLogout } from '../components/display/UserMenu/action';
import { ActionNewCommunicationSummary } from '../pages/main/comunications/NewComunication/CommunicationSummary/action';
import { ActionCommunicationDuplicateSummary } from '../pages/main/comunications/DuplicateComunication/CommunicationSummary/action';
import { ActionNewWorkOrderSummary } from '../pages/main/workOrders/NewOrder/WorkOrderSummary/action';
import { ActionNewWorkOrderDetailed } from '../pages/main/workOrders/WorkOrderDetailed/action';
import { ActionNewWorkOrderDuplicateSummary } from '../pages/main/workOrders/DuplicateOrder/WorkOrderSummary/action';
import { ActionCampaignSummary } from '../pages/main/workOrders/NewCampaign/CampaignSummary/action';
import { ActionCampaignDetailed } from '../pages/main/workOrders/CampaignDetailed/action';
import { ActionEditCampaignSummary } from '../pages/main/workOrders/EditCampaign/CampaignSummary/action';
import { ActionVehicles } from '../pages/main/workOrders/AddVehicle/Action/action';
import { AnimalsAction } from '../pages/main/workOrders/NewAction/Animals/action';
import { ActionNewAction } from '../pages/main/workOrders/NewAction/action';
import { AnimalsValidationAction } from '../pages/main/workOrders/ActionsValidation/Animals/action';
import { AccidentNewWorkOrderDetailed } from '../pages/main/workOrders/GeneralView/AccidentsPage/AccidentPageSummary/action';

// Context
import { AuthContext } from '../hooks/useAuth';

// Components Utils
import Fallback from '../components/navigation/Fallback';

// Constants and Types
import { formOrderNaming } from '../components/forms/NewWorkOrderForm/constants';
import { layoutTabReset } from '../pages/main/comunications/components/types';
import { AccidentRoute } from '../types/route';

// Redux state
import { store } from '../state/configureStore';
import { AccidentNewAccident } from '../pages/main/workOrders/NewAccident/action';
import { Role } from '../types/auth';

import { AnimalsManagementLoader } from '../pages/main/management/animals/loader';
import AnimalsManagementPage from '../pages/main/management/animals';
import { MachineryManagementLoader } from '../pages/main/management/machinery/loader';
import MachineryManagementPage from '../pages/main/management/machinery';

export let router: Router;
const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

export default function Router() {
  const userData = useContext(AuthContext);

  router = sentryCreateBrowserRouter(
    createRoutesFromElements(
      <Route>
        <Route index path={'/'} loader={LoaderIndexPage} />
        <Route element={<AuthLayout />} errorElement={<ErrorPage />}>
          <Route path="logout" action={ActionLogout(store)(userData)} />
          <Route
            index
            path="login"
            element={<LoginPage />}
            action={ActionLogin(userData)}
          />
          <Route
            path="/reset-password"
            element={<ResetPasswordPage />}
            action={ActionResetPassword(userData)}
          />
        </Route>

        <Route
          element={<MainLayout />}
          loader={LoaderBaseDataMain(userData)}
          errorElement={<ErrorPage />}
        >
          <Route index path={'dashboard'} element={<DashboardPage />} />
          <Route
            path={'communication'}
            element={
              <RolesAuthRoute
                roles={[Role.SuperAdmin, Role.Ceconsa, Role.RoomTeam]}
              >
                <CommunicationsLayout />
              </RolesAuthRoute>
            }
          >
            <Route
              index
              element={
                <Fallback dependsOn="data">
                  <NewCommunicationPage />
                </Fallback>
              }
              loader={NewCommunicationLoader(userData)}
            />
            <Route
              action={ActionNewCommunicationSummary(store)}
              element={<NewCommunicationSummary />}
              path={'summary'}
            />
            <Route
              path={'duplicate-communication'}
              element={
                <LayoutResetIndex
                  resetType={layoutTabReset.ONLY_COMMUNICATION}
                />
              }
            >
              <Route
                index
                loader={LoaderCommunicationDuplicates(userData)}
                element={
                  <Fallback dependsOn="data">
                    <DuplicateCommunicationPage />
                  </Fallback>
                }
              />
              <Route
                action={ActionCommunicationDuplicateSummary(store)}
                element={<NewCommunicationDuplicateSummaryPage />}
                path={'summary'}
              />
              <Route
                action={ActionCommunicationDuplicateSummary(store)}
                loader={LoaderCommunicationDuplicateSummary}
                element={
                  <Fallback dependsOn="data">
                    <NewCommunicationDuplicateSummaryPage />
                  </Fallback>
                }
                path={'summary/:id'}
              />
              <Route
                path={'new-communication-duplicate'}
                element={
                  <Fallback dependsOn="data">
                    <NewCommunicationDuplicatePage />
                  </Fallback>
                }
                loader={NewCommunicationDuplicateLoader(userData)}
              />
              <Route
                path={'new-communication-duplicate/:id'}
                element={
                  <Fallback dependsOn="data">
                    <NewCommunicationDuplicatePage />
                  </Fallback>
                }
                loader={NewCommunicationDuplicateLoader(userData)}
              />
            </Route>
            <Route
              path={'search-create-historical-communication'}
              element={
                <RolesAuthRoute roles={[Role.SuperAdmin, Role.Ceconsa]}>
                  <LayoutResetIndex resetType={layoutTabReset.ALL} />
                </RolesAuthRoute>
              }
            >
              <Route
                loader={SearchAndCreateHistoricalLoader(userData)}
                index
                element={
                  <RolesAuthRoute roles={[Role.SuperAdmin, Role.Ceconsa]}>
                    <Fallback dependsOn="data">
                      <SearchAndCreateHistoricalCommunicationPage />
                    </Fallback>
                  </RolesAuthRoute>
                }
              />
              <Route path={'edit-communication/:id'}>
                <Route
                  element={
                    <RolesAuthRoute roles={[Role.SuperAdmin, Role.Ceconsa]}>
                      <Fallback dependsOn="data">
                        <EditCommunicationPage />
                      </Fallback>
                    </RolesAuthRoute>
                  }
                  index
                  loader={EditCommunicationLoader(userData)}
                />
                <Route
                  action={EditCommunicationSummary(store)}
                  element={<SearchAndCreateHistoricalCommunicationSummary />}
                  path={'summary'}
                />
              </Route>
              <Route
                element={
                  <RolesAuthRoute roles={[Role.SuperAdmin, Role.Ceconsa]}>
                    <SearchAndCreateHistoricalCommunicationSummary />
                  </RolesAuthRoute>
                }
                path={'summary'}
              />
              <Route
                loader={LoaderSearchAndCreateHistoricalCommunicationSummary}
                element={
                  <RolesAuthRoute roles={[Role.SuperAdmin, Role.Ceconsa]}>
                    <Fallback dependsOn="data">
                      <SearchAndCreateHistoricalCommunicationSummary />
                    </Fallback>
                  </RolesAuthRoute>
                }
                path={'summary/:id'}
              />
            </Route>
          </Route>
          <Route path={'work-orders'} element={<WorkOrdersLayout />}>
            <Route
              element={
                <Fallback dependsOn="data">
                  <GeneralViewPage />
                </Fallback>
              }
              loader={LoaderWorkOrdersGeneralView(userData)}
            >
              <Route index element={<WorkOrdersPage />} />
              <Route
                path="accidents"
                element={
                  <Fallback dependsOn="data">
                    <AccidentsPage />
                  </Fallback>
                }
                loader={LoaderAccidentGeneralView(userData)}
              />
              <Route
                path="communications"
                loader={LoaderCommunicationGeneralView(userData)}
                element={
                  <Fallback dependsOn="data">
                    <CommunicationsPage />
                  </Fallback>
                }
              />

              <Route
                path="multimedia"
                element={
                  <Fallback dependsOn="data">
                    <MultimediaPage />
                  </Fallback>
                }
                loader={LoaderMultimediaGeneralView(userData)}
              />
            </Route>
            <Route
              path="accidents/:id"
              loader={LoaderGeneralViewAccidentSummary(userData)}
              action={AccidentNewWorkOrderDetailed}
              element={
                <Fallback dependsOn="data">
                  <AccidentSummaryGeneralView />
                </Fallback>
              }
            />
            <Route
              loader={LoaderGeneralViewCommunicationSummary}
              path="communications/:id"
              element={
                <Fallback dependsOn="data">
                  <CommunicationSummaryGeneralView />
                </Fallback>
              }
            />
            <Route
              loader={LoaderGeneralViewMultimediaGrid(userData)}
              path="multimedia/:id"
              element={
                <Fallback dependsOn="data">
                  <MultimediaGridGeneralView />
                </Fallback>
              }
            />
            <Route path=":id">
              <Route
                index
                action={ActionNewWorkOrderDetailed}
                loader={LoaderWorkOrderDetailed}
                element={
                  <Fallback dependsOn="data">
                    <WorkOrderDetailed />
                  </Fallback>
                }
              />
              <Route
                path={'new-action'}
                element={
                  <Fallback dependsOn="data">
                    <NewActionPage />
                  </Fallback>
                }
                loader={NewActionLoader(userData)}
                action={ActionNewAction}
              />
              <Route path={'new-action'}>
                <Route
                  path={'details'}
                  element={
                    <Fallback dependsOn="data">
                      <ActionPage />
                    </Fallback>
                  }
                  loader={LoaderActionPageOrder}
                >
                  <Route
                    path="*"
                    element={<Navigate to="operators-and-vehicles" replace />}
                    index
                  />
                  <Route
                    path="operators-and-vehicles"
                    element={
                      <Fallback dependsOn="data">
                        <OperatorsAndVehiclesFormPage />
                      </Fallback>
                    }
                    loader={OperatorsAndVehiclesLoader(userData)}
                  />
                  <Route
                    path="location"
                    element={
                      <Fallback dependsOn="data">
                        <LocationFormPage />
                      </Fallback>
                    }
                    loader={LocationLoader}
                  />
                  <Route
                    path="edition"
                    element={
                      <Fallback dependsOn="data">
                        <EditionActionFormPage />
                      </Fallback>
                    }
                    loader={BuildingLoader}
                  />
                  <Route
                    path="materials"
                    element={
                      <Fallback dependsOn="data">
                        <MaterialsFormPage />
                      </Fallback>
                    }
                    loader={MaterialsLoader}
                  />
                  <Route
                    path="machinery"
                    element={
                      <Fallback dependsOn="data">
                        <MachineryFormPage />
                      </Fallback>
                    }
                    loader={MachineryLoader}
                  />
                  <Route
                    path="animals"
                    element={
                      <Fallback dependsOn="data">
                        <AnimalsFormPage />
                      </Fallback>
                    }
                    loader={AnimalsLoader()}
                    action={AnimalsAction}
                  />
                </Route>
                <Route
                  path="summary/:id"
                  element={
                    <Fallback dependsOn="data">
                      <ActionSummaryPage />
                    </Fallback>
                  }
                  loader={ActionSummaryLoader}
                />
              </Route>
              <Route
                path={'new-accident'}
                element={
                  <Fallback dependsOn="data">
                    <NewAccidentPage />
                  </Fallback>
                }
                loader={NewAccidentLoader(userData)}
                action={AccidentNewAccident}
              />
              <Route path={'new-accident'}>
                <Route
                  path="basic-info"
                  element={
                    <Fallback dependsOn="data">
                      <AddBasicInfoAccidentPage />
                    </Fallback>
                  }
                  loader={AddBasicInfoAccidentLoader(userData)}
                />

                <Route
                  path={'damages-and-vehicles'}
                  element={
                    <Fallback dependsOn="data">
                      <AddVehiclePage />
                    </Fallback>
                  }
                  action={ActionVehicles(AccidentRoute.BASE)}
                  loader={VehicleLoader(userData)}
                />
              </Route>
            </Route>
            <Route path={'new-order'}>
              <Route
                index
                element={
                  <RolesAuthRoute
                    roles={[Role.SuperAdmin, Role.Ceconsa, Role.RoomTeam]}
                  >
                    <Fallback dependsOn="data">
                      <NewOrderPage />
                    </Fallback>
                  </RolesAuthRoute>
                }
                loader={NewWorkOrderLoader(
                  userData,
                  formOrderNaming.NEW_WORK_ORDER_FORM
                )}
              />
            </Route>
            <Route
              action={ActionNewWorkOrderSummary}
              element={
                <RolesAuthRoute
                  roles={[Role.SuperAdmin, Role.Ceconsa, Role.RoomTeam]}
                >
                  <NewWorkOrderSummary />
                </RolesAuthRoute>
              }
              path={'summary'}
            />
            <Route path={'duplicate-order'}>
              <Route
                index
                element={
                  <Fallback dependsOn="data">
                    <RolesAuthRoute
                      roles={[Role.SuperAdmin, Role.Ceconsa, Role.RoomTeam]}
                    >
                      <DuplicateOrderPage />
                    </RolesAuthRoute>
                  </Fallback>
                }
                loader={LoaderOrderDuplicates(userData)}
              />
              <Route
                path={'new-order-duplicate'}
                element={
                  <Fallback dependsOn="data">
                    <RolesAuthRoute
                      roles={[Role.SuperAdmin, Role.Ceconsa, Role.RoomTeam]}
                    >
                      <NewOrderDuplicatePage />
                    </RolesAuthRoute>
                  </Fallback>
                }
                loader={NewWorkOrderDuplicateLoader(userData)}
              />
              <Route
                path={'new-order-duplicate/:id'}
                element={
                  <RolesAuthRoute
                    roles={[Role.SuperAdmin, Role.Ceconsa, Role.RoomTeam]}
                  >
                    <Fallback dependsOn="data">
                      <NewOrderDuplicatePage />
                    </Fallback>
                  </RolesAuthRoute>
                }
                loader={NewWorkOrderDuplicateLoader(userData)}
              />
              <Route
                path={'summary'}
                element={
                  <RolesAuthRoute
                    roles={[Role.SuperAdmin, Role.Ceconsa, Role.RoomTeam]}
                  >
                    <WorkOrderDuplicateSummaryPage />
                  </RolesAuthRoute>
                }
                action={ActionNewWorkOrderDuplicateSummary}
              />
              <Route
                path={'summary/:id'}
                element={
                  <RolesAuthRoute
                    roles={[Role.SuperAdmin, Role.Ceconsa, Role.RoomTeam]}
                  >
                    <Fallback dependsOn="data">
                      <WorkOrderDuplicateSummaryPage />
                    </Fallback>
                  </RolesAuthRoute>
                }
                action={ActionNewWorkOrderDuplicateSummary}
                loader={LoaderOrderSummaryDuplicates}
              />
            </Route>
            <Route path={'search-create-historical-order'}>
              <Route
                index
                element={
                  <RolesAuthRoute roles={[Role.SuperAdmin, Role.Ceconsa]}>
                    <Fallback dependsOn="data">
                      <SearchAndCreateHistoricalOrderPage />
                    </Fallback>
                  </RolesAuthRoute>
                }
                loader={SearchAndCreateHistoricalWorkOrdersLoader(userData)}
              />
              <Route
                path={'summary'}
                element={
                  <RolesAuthRoute roles={[Role.SuperAdmin, Role.Ceconsa]}>
                    <SearchAndCreateHistoryWorkOrderSummary />
                  </RolesAuthRoute>
                }
              />
              <Route
                path={'summary/:id'}
                element={
                  <RolesAuthRoute roles={[Role.SuperAdmin, Role.Ceconsa]}>
                    <Fallback dependsOn="data">
                      <SearchAndCreateHistoryWorkOrderSummary />
                    </Fallback>
                  </RolesAuthRoute>
                }
                loader={LoaderSearchAndCreateHistoricalWorkOrderSummary}
              />
            </Route>
            <Route path={'campaign'}>
              <Route
                index
                element={
                  <RolesAuthRoute roles={[Role.SuperAdmin, Role.Ceconsa]}>
                    <Fallback dependsOn="data">
                      <CampaignsPage />
                    </Fallback>
                  </RolesAuthRoute>
                }
                loader={LoaderCampaigns(userData)}
              />
              <Route path=":id">
                <Route
                  index
                  element={
                    <RolesAuthRoute roles={[Role.SuperAdmin, Role.Ceconsa]}>
                      <Fallback dependsOn="data">
                        <CampaignDetailed />
                      </Fallback>
                    </RolesAuthRoute>
                  }
                  action={ActionCampaignDetailed}
                  loader={LoaderCampaignDetailed}
                />
                <Route
                  path={'new-action'}
                  element={
                    <RolesAuthRoute roles={[Role.SuperAdmin, Role.Ceconsa]}>
                      <Fallback dependsOn="data">
                        <NewActionPage />
                      </Fallback>
                    </RolesAuthRoute>
                  }
                  loader={NewActionLoader(userData)}
                  action={ActionNewAction}
                />
                <Route path="new-action">
                  <Route
                    path={'details'}
                    element={
                      <RolesAuthRoute roles={[Role.SuperAdmin, Role.Ceconsa]}>
                        <Fallback dependsOn="data">
                          <ActionPage />
                        </Fallback>
                      </RolesAuthRoute>
                    }
                    loader={LoaderActionPageOrder}
                  >
                    <Route
                      path="*"
                      element={<Navigate to="operators-and-vehicles" replace />}
                      index
                    />
                    <Route
                      path="operators-and-vehicles"
                      element={
                        <RolesAuthRoute roles={[Role.SuperAdmin, Role.Ceconsa]}>
                          <Fallback dependsOn="data">
                            <OperatorsAndVehiclesFormPage />
                          </Fallback>
                        </RolesAuthRoute>
                      }
                      loader={OperatorsAndVehiclesLoader(userData)}
                    />
                    <Route
                      path="location"
                      element={
                        <RolesAuthRoute roles={[Role.SuperAdmin, Role.Ceconsa]}>
                          <Fallback dependsOn="data">
                            <LocationFormPage />
                          </Fallback>
                        </RolesAuthRoute>
                      }
                      loader={LocationLoader}
                    />
                    <Route
                      path="edition"
                      element={
                        <RolesAuthRoute roles={[Role.SuperAdmin, Role.Ceconsa]}>
                          <Fallback dependsOn="data">
                            <EditionActionFormPage />
                          </Fallback>
                        </RolesAuthRoute>
                      }
                      loader={BuildingLoader}
                    />
                    <Route
                      path="materials"
                      element={
                        <RolesAuthRoute roles={[Role.SuperAdmin, Role.Ceconsa]}>
                          <Fallback dependsOn="data">
                            <MaterialsFormPage />
                          </Fallback>
                        </RolesAuthRoute>
                      }
                      loader={MaterialsLoader}
                    />
                    <Route
                      path="machinery"
                      element={
                        <RolesAuthRoute roles={[Role.SuperAdmin, Role.Ceconsa]}>
                          <Fallback dependsOn="data">
                            <MachineryFormPage />
                          </Fallback>
                        </RolesAuthRoute>
                      }
                      loader={MachineryLoader}
                    />
                    <Route
                      path="animals"
                      element={
                        <RolesAuthRoute roles={[Role.SuperAdmin, Role.Ceconsa]}>
                          <Fallback dependsOn="data">
                            <AnimalsFormPage />
                          </Fallback>
                        </RolesAuthRoute>
                      }
                      loader={AnimalsLoader()}
                      action={AnimalsAction}
                    />
                  </Route>
                  <Route
                    path="summary/:id"
                    element={
                      <RolesAuthRoute roles={[Role.SuperAdmin, Role.Ceconsa]}>
                        <Fallback dependsOn="data">
                          <ActionSummaryPage />
                        </Fallback>
                      </RolesAuthRoute>
                    }
                    loader={ActionSummaryLoader}
                  />
                </Route>
                <Route
                  path={'new-accident'}
                  element={
                    <RolesAuthRoute roles={[Role.SuperAdmin, Role.Ceconsa]}>
                      <Fallback dependsOn="data">
                        <NewAccidentPage />
                      </Fallback>
                    </RolesAuthRoute>
                  }
                  loader={NewAccidentLoader(userData)}
                  action={AccidentNewAccident}
                />
                <Route path={'new-accident'}>
                  <Route
                    path="basic-info"
                    element={
                      <RolesAuthRoute roles={[Role.SuperAdmin, Role.Ceconsa]}>
                        <Fallback dependsOn="data">
                          <AddBasicInfoAccidentPage />
                        </Fallback>
                      </RolesAuthRoute>
                    }
                    loader={AddBasicInfoAccidentLoader(userData)}
                  />

                  <Route
                    path={'damages-and-vehicles'}
                    element={
                      <RolesAuthRoute roles={[Role.SuperAdmin, Role.Ceconsa]}>
                        <Fallback dependsOn="data">
                          <AddVehiclePage />
                        </Fallback>
                      </RolesAuthRoute>
                    }
                    action={ActionVehicles(AccidentRoute.CAMPAIGN)}
                    loader={VehicleLoader(userData)}
                  />
                </Route>
              </Route>
              <Route
                path={'new-campaign'}
                element={
                  <RolesAuthRoute roles={[Role.SuperAdmin, Role.Ceconsa]}>
                    <Fallback dependsOn="data">
                      <NewCampaignPage />
                    </Fallback>
                  </RolesAuthRoute>
                }
                loader={NewWorkOrderLoader(
                  userData,
                  formOrderNaming.NEW_CAMPAIGN_FORM
                )}
              />
              <Route path={'edit-campaign'}>
                <Route
                  element={
                    <RolesAuthRoute roles={[Role.SuperAdmin, Role.Ceconsa]}>
                      <Fallback dependsOn="data">
                        <EditCampaignPage />
                      </Fallback>
                    </RolesAuthRoute>
                  }
                  loader={EditCampaignLoader(userData)}
                />
                <Route
                  path={':id'}
                  element={
                    <RolesAuthRoute roles={[Role.SuperAdmin, Role.Ceconsa]}>
                      <Fallback dependsOn="data">
                        <EditCampaignPage />
                      </Fallback>
                    </RolesAuthRoute>
                  }
                  loader={EditCampaignLoader(userData)}
                />
                <Route
                  path={':id/summary'}
                  element={
                    <RolesAuthRoute roles={[Role.SuperAdmin, Role.Ceconsa]}>
                      <Fallback dependsOn="data">
                        <EditCampaignSummaryPage />
                      </Fallback>
                    </RolesAuthRoute>
                  }
                  action={ActionEditCampaignSummary}
                  loader={LoaderCampaignSummary}
                />
              </Route>
              <Route
                path="summary"
                element={
                  <RolesAuthRoute roles={[Role.SuperAdmin, Role.Ceconsa]}>
                    <CampaignSummary />
                  </RolesAuthRoute>
                }
                action={ActionCampaignSummary}
              />
            </Route>
            <Route path={'actions-validation'}>
              <Route
                index
                element={
                  <RolesAuthRoute roles={[Role.SuperAdmin, Role.Ceconsa]}>
                    <Fallback dependsOn="data">
                      <ActionsValidation />
                    </Fallback>
                  </RolesAuthRoute>
                }
                loader={ActionsValidationLoader(userData)}
              />
              <Route
                path={':id'}
                element={
                  <RolesAuthRoute roles={[Role.SuperAdmin, Role.Ceconsa]}>
                    <Fallback dependsOn="data">
                      <ActionValidationPage />
                    </Fallback>
                  </RolesAuthRoute>
                }
                loader={LoaderActionValidationPageOrder}
              >
                <Route
                  path="*"
                  element={<Navigate to="operators-and-vehicles" replace />}
                  index
                />
                <Route
                  path="operators-and-vehicles"
                  element={
                    <RolesAuthRoute roles={[Role.SuperAdmin, Role.Ceconsa]}>
                      <Fallback dependsOn="data">
                        <OperatorsAndVehiclesFormValidationPage />
                      </Fallback>
                    </RolesAuthRoute>
                  }
                  loader={OperatorsAndVehiclesValidationLoader(userData)}
                />
                <Route
                  path="location"
                  element={
                    <RolesAuthRoute roles={[Role.SuperAdmin, Role.Ceconsa]}>
                      <Fallback dependsOn="data">
                        <LocationActionFormPage />
                      </Fallback>
                    </RolesAuthRoute>
                  }
                  loader={LocationValidationLoader(userData)}
                />
                <Route
                  path="edition"
                  element={
                    <RolesAuthRoute roles={[Role.SuperAdmin, Role.Ceconsa]}>
                      <Fallback dependsOn="data">
                        <EditionValidationFormPage />
                      </Fallback>
                    </RolesAuthRoute>
                  }
                  loader={EditionValidationLoader(userData)}
                />
                <Route
                  path="materials"
                  element={
                    <RolesAuthRoute roles={[Role.SuperAdmin, Role.Ceconsa]}>
                      <Fallback dependsOn="data">
                        <MaterialsValidationFormPage />
                      </Fallback>
                    </RolesAuthRoute>
                  }
                  loader={MaterialsValidationLoader(userData)}
                />
                <Route
                  path="machinery"
                  element={
                    <RolesAuthRoute roles={[Role.SuperAdmin, Role.Ceconsa]}>
                      <Fallback dependsOn="data">
                        <MachineryValidationFormPage />
                      </Fallback>
                    </RolesAuthRoute>
                  }
                  loader={MachineryValidationLoader(userData)}
                />
                <Route
                  path="animals"
                  element={
                    <RolesAuthRoute roles={[Role.SuperAdmin, Role.Ceconsa]}>
                      <Fallback dependsOn="data">
                        <AnimalsValidationFormPage />
                      </Fallback>
                    </RolesAuthRoute>
                  }
                  loader={AnimalsValidationLoader(userData)}
                  action={AnimalsValidationAction}
                />
              </Route>
              <Route
                path="summary/:id"
                element={
                  <RolesAuthRoute roles={[Role.SuperAdmin, Role.Ceconsa]}>
                    <Fallback dependsOn="data">
                      <ActionSummaryPage />
                    </Fallback>
                  </RolesAuthRoute>
                }
                loader={ActionSummaryLoader}
              />
            </Route>
          </Route>
          <Route
            path="management"
            element={
              <RolesAuthRoute
                roles={[Role.SuperAdmin, Role.Ceconsa, Role.RoomTeam]}
              >
                <ManagementRoutesLayout />
              </RolesAuthRoute>
            }
          >
            <Route
              loader={UsersManagementLoader(userData)}
              element={
                <Fallback dependsOn="data">
                  <UsersManagementPage />
                </Fallback>
              }
              index
            />
            <Route
              loader={VehiclesManagementLoader(userData)}
              element={
                <Fallback dependsOn="data">
                  <VehiclesManagementPage />
                </Fallback>
              }
              path="vehicles"
            />
            <Route
              loader={MaterialsManagementLoader(userData)}
              element={
                <Fallback dependsOn="data">
                  <MaterialsManagementPage />
                </Fallback>
              }
              path="materials"
            />
            <Route
              loader={MachineryManagementLoader(userData)}
              element={
                <Fallback dependsOn="data">
                  <MachineryManagementPage />
                </Fallback>
              }
              path="auxiliar-machinery"
            />
            <Route
              loader={AnimalsManagementLoader(userData)}
              element={
                <Fallback dependsOn="data">
                  <AnimalsManagementPage />
                </Fallback>
              }
              path="animals"
            />
          </Route>
        </Route>
      </Route>
    )
  );

  return <RouterProvider router={router} />;
}

import React from 'react';

// Styled Components
import {
  Container,
  Column,
  ImagesList,
  SubContainer,
  ColumnsContainer,
  StyledTypography,
} from './styles';

// Components
import { Definition } from '../Definition';

// Types
import { AccidentContentCardResumeProps } from './types';

// Hooks
import { useIsLandscapeTablet } from '../../../hooks/useMediaBreakPoints';
import { InputTextArea } from '../../inputs/InputTextArea';

export default function AccidentContentCardResume({
  mediumKnowledge,
  laneCutting,
  policeReport,
  surveillanceBody,
  policeReportNumber,
  weather,
  surfaceCondition,
  luminosity,
  conditionsDriving,
  roadConditionDescription,
  images,
  ...props
}: AccidentContentCardResumeProps): JSX.Element {
  const isLandscape = useIsLandscapeTablet();

  return (
    <Container {...props}>
      <SubContainer>
        <StyledTypography
          component="h3"
          size={isLandscape ? 'XS' : 'M'}
          variant="semiBold"
        >
          Informació bàsica
        </StyledTypography>
        <ColumnsContainer>
          <Column>
            <Definition title="Medi" value={mediumKnowledge} />
            <Definition
              title="Tall de carril"
              value={laneCutting ? 'Sí' : 'No'}
            />
            <Definition title="Atestat" value={policeReport ? 'Sí' : 'No'} />
            {policeReport && (
              <>
                <Definition
                  title="Òrgan viligància"
                  value={surveillanceBody || ''}
                />
                <Definition
                  title="Nº d’atestat"
                  value={policeReportNumber || ''}
                />
              </>
            )}
          </Column>
          <Column>
            <Definition title="Multimedia" value={''} />
            <ImagesList imageData={images} />
          </Column>
        </ColumnsContainer>
      </SubContainer>
      <SubContainer>
        <StyledTypography
          component="h3"
          size={isLandscape ? 'XS' : 'M'}
          variant="semiBold"
        >
          Estat de la carretera
        </StyledTypography>
        <ColumnsContainer>
          <Column>
            <Definition title="Temps" value={weather} />
            <Definition title="Lluminositat" value={luminosity} />
            <Definition title="Superficie" value={surfaceCondition} />
            <Definition title="Ciruclació" value={conditionsDriving} />
          </Column>
          <Column>
            <InputTextArea
              label="Descripció del Incident:"
              disabled
              value={roadConditionDescription}
            />
          </Column>
        </ColumnsContainer>
      </SubContainer>
    </Container>
  );
}

import { redirect } from 'react-router-dom';

// Constants
import { formOrderNaming } from '../../../../../../components/forms/NewWorkOrderForm/constants';

// GraphQL
import { client } from '../../../../../../gql/client';
import { CREATE_NEW_ORDER } from '../../../../../../gql/mutations/orders';
import { CREATE_NEW_GENERIC_ORDER } from '../../../../../../gql/mutations/generic-order';
import { GET_ORDER_BY_ID } from '../../../../../../gql/queries/orders/orders';
import { NEW_GENERIC_ORDER } from '../../../../../../gql/fragments/generic-order';
import { NEW_CAMPAIGN } from '../../../../../../gql/fragments/campaign';
import { CREATE_NEW_CAMPAIGN } from '../../../../../../gql/mutations/campaign';

// Types
import { OrderState, OrderType } from '../../../../../../types/orders';
import {
  tableIds,
  takeBase,
} from '../../../../../../components/display/tables/types';

//Utils
import { decompressSessionStorage } from '../../../../../../utils/sessionStorage';
import { truncateArrayAfterNull } from '../../../../../../utils/mergeArrays';

// REDUX
import { store } from '../../../../../../state/configureStore';
import { setCursor } from '../../../../../../state/actions/ui/cursorTables';
import { getCursor } from '../../../../../../state/selectors/ui/tables';

export const saveData = async (dataFormRaw: string | null) => {
  let dataOrder;
  const dispatch = store.dispatch;
  // Cursors for campaigns
  const cursorCampaignModal = getCursor(tableIds.CAMPAIGN_MODAL)(
    store.getState()
  );
  const cursorCampaigns = getCursor(tableIds.CAMPAIGNS)(store.getState());

  // Cursors for Work orders
  const cursorWorkOrdersGeneralView = getCursor(
    tableIds.WORKORDER_GENERAL_VIEW
  )(store.getState());
  const cursorDuplicateGenericOrder = getCursor(
    tableIds.DUPLICATE_GENERIC_ORDER
  )(store.getState());
  const cursorSearchAndCreateWorkOrders = getCursor(
    tableIds.SEARCH_AND_CREATE_HISTORICAL_WORKORDERS
  )(store.getState());
  const cursorGenericModal = getCursor(tableIds.GENERIC_ORDER_MODAL)(
    store.getState()
  );

  const creationDate = new Date().toISOString();

  if (dataFormRaw) {
    const dataForm = JSON.parse(decompressSessionStorage(dataFormRaw));
    const pkInitArray = dataForm.pkInit.replace(/ /g, '').split('+');
    const pkEndArray = dataForm.pkEnd.replace(/ /g, '').split('+');

    //DOC: create order
    const orderInput = {
      type:
        dataForm.isCampaignActive !== 'true'
          ? OrderType.Generic
          : OrderType.Campaign,
      state: OrderState.Open,
      creationDate,
      registerInitDate: dataForm.registerInitDate,
      description: dataForm.description,
      classification: dataForm.classification,
      capitol: dataForm.capitol,
      subCapitol: dataForm.subCapitol,
    };
    dataOrder = await client.mutate({
      mutation: CREATE_NEW_ORDER,
      variables: {
        input: orderInput,
      },
    });

    if (dataForm.isCampaignActive !== 'true') {
      //DOC: create generic order
      await client.mutate({
        mutation: CREATE_NEW_GENERIC_ORDER,
        variables: {
          input: {
            concessionId: dataForm.concession,
            roadId: dataForm.road,
            track: dataForm.track,
            lane: dataForm.lane,
            direction: dataForm?.direction,
            margin: dataForm?.margin,
            pkInitKm: Number(pkInitArray[0]),
            pkInitMeter: Number(pkInitArray[1]),
            pkEndKm: Number(pkEndArray[0]),
            pkEndMeter: Number(pkEndArray[1]),
            orderId: dataOrder.data.createOrder.id,
            creationDate,
            registerInitDate: dataForm.registerInitDate,
          },
        },
        update(cache, { data: { createGenericOrder } }) {
          cache.modify({
            fields: {
              genericOrders(variables = {}) {
                const newGenericOrderRef = cache.writeFragment({
                  data: createGenericOrder,
                  fragment: NEW_GENERIC_ORDER,
                });

                const truncatedArray = truncateArrayAfterNull(
                  variables.genericOrders
                );

                const newGenericOrderArray = [
                  newGenericOrderRef,
                  ...truncatedArray,
                ];

                newGenericOrderArray.splice(-2, 1);

                const setCursorWorkOrdersGeneralView = {
                  ...cursorWorkOrdersGeneralView,
                  take: takeBase,
                  cursor: '',
                  orderBy: 'desc',
                };

                const setCurorDuplicateGenericOrder = {
                  ...cursorDuplicateGenericOrder,
                  take: takeBase,
                  cursor: '',
                  orderBy: 'desc',
                };

                const setCursorSearchAndCreateWorkOrders = {
                  ...cursorSearchAndCreateWorkOrders,
                  take: takeBase,
                  cursor: '',
                  orderBy: 'desc',
                };
                const setCursorGenericModal = {
                  ...cursorGenericModal,
                  take: takeBase,
                  cursor: '',
                  orderBy: 'desc',
                };

                if (variables?.genericOrders?.length > 0) {
                  dispatch(
                    setCursor(
                      tableIds.WORKORDER_GENERAL_VIEW,
                      setCursorWorkOrdersGeneralView
                    )
                  );

                  dispatch(
                    setCursor(
                      tableIds.DUPLICATE_GENERIC_ORDER,
                      setCurorDuplicateGenericOrder
                    )
                  );

                  dispatch(
                    setCursor(
                      tableIds.GENERIC_ORDER_MODAL,
                      setCursorGenericModal
                    )
                  );
                  dispatch(
                    setCursor(
                      tableIds.SEARCH_AND_CREATE_HISTORICAL_WORKORDERS,
                      setCursorSearchAndCreateWorkOrders
                    )
                  );
                }

                return {
                  ...variables,
                  genericOrders: newGenericOrderArray,
                  totalCount: variables.totalCount + 1,
                  pageInfo: {},
                };
              },
            },
          });
        },
      });
    } else {
      //DOC: create campaign
      await client.mutate({
        mutation: CREATE_NEW_CAMPAIGN,
        variables: {
          input: {
            name: dataForm.campaign,
            concessionId: dataForm.concession,
            roadId: dataForm.road,
            track: dataForm.track,
            lane: dataForm.lane,
            direction: dataForm?.direction,
            margin: dataForm?.margin,
            pkInitKm: Number(pkInitArray[0]),
            pkInitMeter: Number(pkInitArray[1]),
            pkEndKm: Number(pkEndArray[0]),
            pkEndMeter: Number(pkEndArray[1]),
            orderId: dataOrder.data.createOrder.id,
            creationDate,
            registerInitDate: dataForm.registerInitDate,
          },
        },
        update(cache, { data: { createCampaign } }) {
          cache.modify({
            fields: {
              campaigns(variables = {}) {
                const newCampaignRef = cache.writeFragment({
                  data: createCampaign,
                  fragment: NEW_CAMPAIGN,
                });

                const truncatedArray = truncateArrayAfterNull(
                  variables.campaigns
                );

                const newCampaignArray = [newCampaignRef, ...truncatedArray];

                newCampaignArray.splice(-2, 1);
                const setCursorDataCampaignModal = {
                  ...cursorCampaignModal,
                  take: takeBase,
                  cursor: '',
                  orderBy: 'desc',
                };
                const setCursorDataCampaign = {
                  ...cursorCampaigns,
                  take: takeBase,
                  cursor: '',
                  orderBy: 'desc',
                };

                if (variables?.campaigns?.length > 0) {
                  dispatch(
                    setCursor(
                      tableIds.CAMPAIGN_MODAL,
                      setCursorDataCampaignModal
                    )
                  );
                  dispatch(
                    setCursor(tableIds.CAMPAIGNS, setCursorDataCampaign)
                  );
                }

                return {
                  ...variables,
                  campaigns: newCampaignArray,
                  totalCount: variables.totalCount + 1,
                  pageInfo: {},
                };
              },
            },
          });
        },
      });
    }

    // Refetch in parallel
    await client.query({
      fetchPolicy: 'network-only',
      query: GET_ORDER_BY_ID,
      variables: { id: dataOrder.data.createOrder.id },
    });
  }

  // Clean the form local storage as it was just saved
  sessionStorage.removeItem(formOrderNaming.NEW_WORK_ORDER_FORM);
  return dataOrder?.data?.createOrder.type === OrderType.Generic
    ? redirect(`../${dataOrder?.data.createOrder.id}`)
    : redirect(`../campaign/${dataOrder?.data.createOrder.id}`);
};

import { ActionFunction } from 'react-router-dom';
import { saveData } from './saveData';

// Sentry
import * as Sentry from '@sentry/react';

export const ActionVehicles: (AccidentRoute: string) => ActionFunction =
  (AccidentRoute) =>
  async ({ request }) => {
    const formData = await request.formData();
    try {
      return saveData(formData, AccidentRoute);
    } catch (err) {
      Sentry.captureException(err);
      console.log('Error save Accident:', err);
    }
    return null;
  };

import React, { useCallback, useRef, useState } from 'react';

// Styles Components
import {
  ContainerContent,
  ContainerData,
  ContainerDescription,
  ContainerHeader,
  ContainerOT,
  ContainerRefIcon,
  DateInput,
  DropDownCapitol,
  DropDownConcession,
  InputDescription,
  InputPKEnd,
  InputPKInit,
  RefCotic,
  RightArrow,
  TitleOrder,
  ButtonEdit,
  ButtonSave,
} from './styles';

import {
  Controller,
  ControllerProps,
  SubmitHandler,
  useForm,
  useWatch,
} from 'react-hook-form';

import { isEqual } from 'lodash';
import { useSubmit } from 'react-router-dom';

// Components
import { SpecialTag } from '../../SpecialTag';
import DropDownInput from '../../../inputs/DropDown/DropDownInput';
import { Typography } from '../../Typography';

// Icons
import ArrowRight from '../../../../assets/icons/triangle-right.svg';

// Media
import { useIsLandscapeTablet } from '../../../../hooks/useMediaBreakPoints';
import { options as optionsMock } from '../../../../router/definitionGloblaRoutes';

// Types
import { ShowOTProps } from '../WorkOrder/types';
import { OptionSelected } from '../../../inputs/DropDown/DropDownList/types';
import { OTObject, GroupTypes } from './types';
import { ImperativeRefInput } from '../../../inputs/DropDown/DropDownInput/types';

// GQL
import { GET_SUB_CAPITOLS_BY_IDS } from '../../../../gql/queries/orders/predefinedData';
import { client } from '../../../../gql/client';

// Validations
import { yupResolver } from '@hookform/resolvers/yup';
import { schemaOT } from '../WorkOrder/schema';
import { createFormDataFromValues } from '../../../../utils/forms';
import {
  GET_CONCESSION_BY_ID,
  GET_ROADS_BY_IDS,
} from '../../../../gql/queries/geoPositions/roads';
import * as Sentry from '@sentry/react';

export const SubWorkOrder = ({
  id,
  cotic,
  type,
  tag,
  date,
  capitol,
  subCapitol,
  concession,
  pkInit,
  pkEnd,
  road,
  roadsValues,
  capitols,
  concessions,
  description,
  subCapitolsValues,
  modelTypeId,
}: ShowOTProps): JSX.Element => {
  const [showInfo, setShowInfo] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const toggleInfo = useCallback(() => setShowInfo(!showInfo), [showInfo]);
  const toggleEdit = useCallback(
    () => setIsDisabled(!isDisabled),
    [isDisabled]
  );
  const [errorMessage, setErrorMessage] = useState<string>('');

  const submit = useSubmit();

  const initialData: OTObject = {
    capitols,
    concessions,
    date,
    pkInit,
    pkEnd,
    description,
    capitol,
    concession,
    road,
    roads: roadsValues,
    subCapitol,
    subCapitols: subCapitolsValues,
  };

  const subCapitolDropDownRef = useRef<ImperativeRefInput>(null);

  const isLandscapeTablet = useIsLandscapeTablet();

  const {
    handleSubmit,
    control,
    setValue,
    register,
    formState: { errors },
  } = useForm<OTObject>({
    defaultValues: initialData,
    resolver: yupResolver(schemaOT),
  });

  const roads = useWatch({
    control,
    name: 'roads',
  });

  const subCapitols = useWatch({
    control,
    name: 'subCapitols',
  });

  const watchedFields = useWatch({
    control,
    defaultValue: initialData,
  });

  const onSelectCapitol = useCallback(
    (
      option: OptionSelected | undefined,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onChange: (...event: any[]) => void
    ) => {
      const { readSubCapitols } = client.readQuery({
        query: GET_SUB_CAPITOLS_BY_IDS,
        variables: {
          ids: option?.data ?? [],
        },
      });
      setValue('subCapitols', readSubCapitols);
      onChange(option?.value ?? '');
    },
    []
  );

  const renderSubCapitols = useCallback<
    ControllerProps<OTObject, 'subCapitol'>['render']
  >(
    ({ field: { onChange }, formState: { defaultValues } }) => {
      const options = subCapitols?.map((subCapitol) => ({
        label: subCapitol?.name || '',
        value: subCapitol?.id || '',
        data: subCapitol?.elementOrdersIds || '',
      })) || [...optionsMock];
      return (
        <DropDownInput
          ref={subCapitolDropDownRef}
          inputSize="L"
          onChangeSelected={(option) => onChange(option?.value ?? '')}
          typeDropDown="Default"
          labelText="Subcapitol"
          placeholder="Selecciona"
          defaultValue={defaultValues?.subCapitol}
          options={options}
          isDisabled={isDisabled}
          borderError={!!errors.subCapitol}
        />
      );
    },
    [subCapitols, isDisabled]
  );

  const roadDropDownRef = useRef<ImperativeRefInput>(null);
  const onSelectConcession = useCallback(
    (
      option: OptionSelected | undefined,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onChange: (...event: any[]) => void
    ) => {
      const tasks = async () => {
        const {
          data: { concession },
        } = await client.query({
          query: GET_CONCESSION_BY_ID,
          variables: { id: option?.value },
        });

        const { readRoads } = client.readQuery({
          query: GET_ROADS_BY_IDS,
          variables: { ids: concession.roadIDs ?? [] },
        });

        setValue('roads', readRoads);

        roadDropDownRef.current?.clearSelected();
        setValue('road', '');

        onChange(option?.value ?? '');
      };
      tasks().catch((err) => {
        Sentry.captureException(err);
        console.log(err);
      });
    },
    []
  );

  const onFormSubmit: SubmitHandler<OTObject> = async (data) => {
    if (!isEqual(initialData, watchedFields)) {
      setIsDisabled(true);
      const formData = createFormDataFromValues({
        ...data,
        id,
        cotic,
        modelTypeId,
        type,
      });
      submit(formData, {
        method: 'POST',
        action: '.',
      });
    } else {
      setErrorMessage(
        'You must make at least one change to the form before proceeding'
      );
    }
  };

  return (
    <ContainerOT>
      <ContainerHeader onClick={toggleInfo}>
        <TitleOrder>{'Sub-Ordre de Treball'}</TitleOrder>
        <ContainerRefIcon>
          <RefCotic>{cotic}</RefCotic>
          <SpecialTag
            variant={tag}
            sizeTag={isLandscapeTablet ? 'small' : 'default'}
          />

          <RightArrow
            src={ArrowRight}
            alt="Icona desplegar informació"
            showInfo={showInfo}
          />
        </ContainerRefIcon>
      </ContainerHeader>
      {showInfo && (
        <ContainerContent onSubmit={handleSubmit(onFormSubmit)}>
          <ContainerData>
            <DateInput
              label="Data de registre:"
              labelHour="Hour:"
              isDisabled={isDisabled}
              initialDate={date}
              borderError={!!errors.date}
            />
            <Controller
              control={control}
              name="capitol"
              render={({
                field: { onChange },
                formState: { defaultValues },
              }) => {
                const options = defaultValues?.capitols?.map((capitol) => ({
                  label: capitol?.name || '',
                  value: capitol?.id || '',
                  data: capitol?.subCapitolsIDs || '',
                })) || [...optionsMock];
                return (
                  <DropDownCapitol
                    inputSize={'M'}
                    onChangeSelected={(option) =>
                      onSelectCapitol(option, onChange)
                    }
                    typeDropDown="Default"
                    labelText="Capitol"
                    placeholder="Selecciona"
                    defaultValue={defaultValues?.capitol}
                    options={options}
                    isDisabled={isDisabled}
                    borderError={!!errors.capitol}
                  />
                );
              }}
            />
            <Controller
              control={control}
              name="subCapitol"
              render={renderSubCapitols}
            />
            <Controller
              control={control}
              name="concession"
              render={({
                field: { onChange },
                formState: { defaultValues },
              }) => {
                const options =
                  defaultValues?.concessions?.reduce((acc, curr) => {
                    if (curr?.name !== 'CX') {
                      acc.push({
                        label: curr?.name || '',
                        value: curr?.id || '',
                      });
                    }

                    return acc;
                  }, [] as GroupTypes[]) || [];
                return (
                  <DropDownConcession
                    inputSize={'M'}
                    onChangeSelected={(option) =>
                      onSelectConcession(option, onChange)
                    }
                    typeDropDown="Default"
                    labelText="Concessió"
                    placeholder="Selecciona"
                    defaultValue={defaultValues?.concession}
                    options={options}
                    isDisabled={isDisabled}
                    borderError={!!errors.concession}
                  />
                );
              }}
            />
            <Controller
              control={control}
              name="road"
              render={({
                field: { onChange },
                formState: { defaultValues },
              }) => {
                const options =
                  roads?.map((road) => ({
                    label: road?.name || '',
                    value: road?.id || '',
                  })) || [];

                return (
                  <DropDownInput
                    ref={roadDropDownRef}
                    inputSize="XS"
                    onChangeSelected={(option) => onChange(option?.value ?? '')}
                    typeDropDown="Default"
                    labelText="Carretera"
                    placeholder="Selec."
                    defaultValue={defaultValues?.road}
                    borderError={!!errors.road}
                    options={options}
                    isDisabled={isDisabled}
                  />
                );
              }}
            />
            <InputPKInit
              {...register('pkInit')}
              label="Pk Inicial"
              typeInput="normal"
              disabled={isDisabled}
              defaultValue={pkInit}
              borderError={!!errors.pkInit}
            />
            <InputPKEnd
              {...register('pkEnd')}
              label="Pk Final"
              typeInput="normal"
              disabled={isDisabled}
              defaultValue={pkEnd}
              borderError={!!errors.pkEnd}
            />
          </ContainerData>
          <ContainerDescription>
            <InputDescription
              {...register('description')}
              label="Descripció"
              disabled={isDisabled}
              defaultValue={description}
              onChange={(e) => console.log('description', e.target.value)}
              borderError={!!errors.description}
            />
            {isDisabled ? (
              <ButtonEdit variant={'edit'} onClick={toggleEdit} type="button" />
            ) : (
              <ButtonSave variant={'save'} type="submit" />
            )}
          </ContainerDescription>
          {errorMessage && (
            <Typography colorText="lightCoralRed" size="XS">
              {errorMessage}
            </Typography>
          )}
        </ContainerContent>
      )}
    </ContainerOT>
  );
};

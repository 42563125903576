import React from 'react';
import { format } from 'date-fns';

// Styled Components
import {
  Container,
  SmallContainer,
  ContainerRemoveIcon,
  ContainerFileIcon,
  Text,
} from './styles';

// Types
import { InputLoadFilesProps } from './types';

// Icons
import LoadDocumentIcon from '../../../assets/icons/load-document-icon.svg';
import RemoveFileIcon from '../../../assets/icons/remove-file-icon.svg';

export const InputLoadFiles = ({
  id,
  date,
  road,
  onRemove,
  name,
  ...props
}: InputLoadFilesProps): JSX.Element => {
  const formatDate = format(new Date(date), 'dd/MM/yy - HH:mm:ss');
  return (
    <Container {...props}>
      <SmallContainer>
        <ContainerFileIcon src={LoadDocumentIcon} />
        <Text size="S" variant="medium">
          {`${name} ${road} ${formatDate}`}
        </Text>
      </SmallContainer>
      <ContainerRemoveIcon src={RemoveFileIcon} onClick={() => onRemove(id)} />
    </Container>
  );
};

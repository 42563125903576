import { LoaderFunction } from 'react-router-dom';

// GraphQL
import { client } from '../../../gql/client';
import { FETCH_PREDEFINED_DATA_LISTS } from '../../../gql/queries/orders/predefinedData';
import { GET_SELECTORS_DATA } from '../../../gql/queries/orders/orders';

// Contexts
import { AuthContextType } from '../../../hooks/useAuth';

export const LoaderBaseDataMain: (
  userData: AuthContextType | null
) => LoaderFunction = (userData) => async () => {
  if (userData?.user) {
    Promise.all([
      client.query({
        query: FETCH_PREDEFINED_DATA_LISTS,
      }),
      client.query({
        query: GET_SELECTORS_DATA,
      }),
    ]);
  }

  return null;
};

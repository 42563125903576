export type TableProps = {
  data?: OperatorsType[];
};

export type OperatorsType = {
  company: string;
  work: string;
  operatorsNumber: number | string;
};

type columnIDTypes = { [key: string]: { id: string; label: string } };

export const columnNames: columnIDTypes = {
  company: {
    id: 'company',
    label: 'Empresa',
  },
  work: {
    id: 'work',
    label: 'Treball',
  },
  operatorsNumber: {
    id: 'operatorsNumber',
    label: 'N° OP',
  },
};

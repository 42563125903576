import { redirect } from 'react-router-dom';

// Compression
import { decompressSessionStorage } from '../utils/sessionStorage';

export const LoaderIndexPage = () => {
  const userDataRaw = sessionStorage.getItem('user');
  if (userDataRaw) {
    const userData = JSON.parse(decompressSessionStorage(userDataRaw));
    if (userData?.user?.validated) {
      return redirect('/dashboard');
    }
  }
  return redirect('/login');
};

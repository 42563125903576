import styled from 'styled-components';

// Components
import { Typography } from '../../../display/Typography';
import { spaces } from '../../../../styles/spaces';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3rem;
`;

export const ContainerText = styled.div``;

export const Text = styled(Typography)`
  text-align: center;
`;

export const Title = styled(Text)`
  font-size: 2.4rem;
  margin-bottom: ${spaces.xxs}rem;
`;

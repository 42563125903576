import { defer, LoaderFunction } from 'react-router-dom';

// Constants
import { formActionNaming } from '../../../../../components/forms/ActionForms/constants';

// Utils
import { decompressSessionStorage } from '../../../../../utils/sessionStorage';

// GraphQL
import { client } from '../../../../../gql/client';
import {
  FETCH_ANIMALS,
  FETCH_ANIMAL_STATUS,
} from '../../../../../gql/queries/actions/animals';

export const AnimalsLoader: () => LoaderFunction | undefined =
  () => async () => {
    const stringifyForm = sessionStorage.getItem(
      formActionNaming.NEW_ANIMALS_FORM
    );
    let sessionStorageData;

    const {
      data: { animals: animalsUnsorted },
    } = await client.query({
      query: FETCH_ANIMALS,
    });

    const animals = [...animalsUnsorted].sort((a, b) =>
      a.animalId.localeCompare(b.animalId)
    );

    const {
      data: { animalAllStatus },
    } = await client.query({
      query: FETCH_ANIMAL_STATUS,
    });

    if (stringifyForm) {
      sessionStorageData = JSON.parse(decompressSessionStorage(stringifyForm));
    }

    const combinedData = {
      ...sessionStorageData,
      animals,
      animalAllStatus,
    };

    return defer({
      data: combinedData,
    });
  };

import { defer, LoaderFunction } from 'react-router-dom';

// Sentry
import * as Sentry from '@sentry/react';

// GraphQL
import { selectWorkOrderWithChildren } from '../../../../gql/selectors/orders';

const loadCampaignDetails = async (id: string) => {
  try {
    //TODO: For now only show alone order, if it not break
    return await selectWorkOrderWithChildren(true, id);
  } catch (err) {
    Sentry.captureException(err);
    console.log('> Work order loading error:', err);
    return false;
  }
};

export const LoaderCampaignDetailed: LoaderFunction = async ({ params }) => {
  if (!params.id) return null;

  const data = await loadCampaignDetails(params.id);
  return defer({ data });
};

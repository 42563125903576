import { gql } from '@apollo/client';

export const GET_SELECTORS_DATA = gql`
  query fetchDataBase {
    concessions {
      id
      name
      location
      centerLatitud
      centerLongitud
      roadIDs
    }
    interlocutorGroups {
      id
      name
    }
    mediaOfCommunications {
      id
      name
    }
    roads {
      id
      name
      directions
      pkInitKm
      pkInitMeter
      pkEndKm
      pkEndMeter
      concessionIDs
    }
    teams {
      id
      name
      nameOffice
      concessionIDs
      roadIDs
      userIDs
    }
  }
`;

export const GET_CONCESSIONS = gql`
  query getConcessions {
    concessions {
      id
      name
      location
      centerLatitud
      centerLongitud
      roadIDs
      vehicleIDs
    }
  }
`;

export const FETCH_ORDERS = gql`
  query FetchOrders($input: FilterOrderList!) {
    orders(input: $input) {
      orders {
        id
        cotic
        indexExpedition
        indexType
        type
        state
        communicationIDs
        genericOrdersIDs
        campaignIDs
        userId
        classificationIDs
        capitolIDs
        subCapitolIDs
        elementOrderIDs
        parentExpeditionOrderIDs
        childExpeditionOrderIDs
        creationDate
        registerInitDate
        registerEndDate
        description
        accidentIDs
        actionIDs
        imageIDs
        isTransferred
      }
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        lastCursor
      }
    }
  }
`;

export const GET_ORDER_BY_ID = gql`
  query GetOrderById($id: ID!) {
    order(id: $id) {
      id
      cotic
      indexExpedition
      indexType
      type
      state
      communicationIDs
      genericOrdersIDs
      campaignIDs
      userId
      classificationIDs
      capitolIDs
      subCapitolIDs
      elementOrderIDs
      parentExpeditionOrderIDs
      childExpeditionOrderIDs
      creationDate
      registerInitDate
      registerEndDate
      description
      accidentIDs
      actionIDs
      imageIDs
      isTransferred
    }
  }
`;

export const GET_ORDERS_BY_IDS = gql`
  query GetOrdersByIds($ids: [ID!]!) {
    readOrders(ids: $ids) @client {
      id
      cotic
      indexExpedition
      indexType
      type
      state
      communicationIDs
      genericOrdersIDs
      campaignIDs
      userId
      classificationIDs
      capitolIDs
      subCapitolIDs
      elementOrderIDs
      parentExpeditionOrderIDs
      childExpeditionOrderIDs
      creationDate
      registerInitDate
      registerEndDate
      description
      accidentIDs
      actionIDs
      imageIDs
      isTransferred
    }
  }
`;

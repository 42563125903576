// Types
import { dataTable } from './types';
export const mocksTable: dataTable[] = [
  {
    id: 'i4yh534hj5gv346',
    cotic: ['1O', '0000', '1234567', '00'],
    date: 1641809400000,
    hour: 1641809400000,
    capitol: 'Entorns de carretera',
    subCapitol: 'Senyalització',
    concession: 'LL',
    road: 'C-16',
    pkInit: '12+350',
    pkEnd: '12+380',
    description: 'Acidente de ciclomotor en  vía asasd',
    state: 'validated',
    type: 'order',
  },
  {
    id: '3jk4g5hj3g43789',
    cotic: ['1O', '0000', '2234567', '00'],
    date: 1642291200000,
    hour: 1642291200000,
    capitol: 'Accident',
    subCapitol: 'Animals',
    concession: 'AR',
    road: 'C-17',
    pkInit: '15+789',
    pkEnd: '15+001',
    description: 'Acidente de ciclomotor en  vía asasd',
    state: 'open',
    type: 'order',
  },
  {
    id: '6wrf8swwgwgr8',
    cotic: ['1R', '0000', '3234567', '00'],
    date: 1646925000000,
    hour: 1646925000000,
    capitol: 'Altres',
    subCapitol: 'Senyalització',
    concession: 'TE',
    road: 'C-16',
    pkInit: '12+345',
    pkEnd: '12+346',
    description: 'Acidente de ciclomotor en  vía asasd',
    state: 'partial',
    type: 'order',
  },
  {
    id: '7ew6g87w6s5g856s',
    cotic: ['1O', '0000', '5234567', '00'],
    date: 1641816600000,
    hour: 1641816600000,
    capitol: 'Entorns de carretera',
    subCapitol: 'Animals',
    concession: 'LL',
    road: 'C-18',
    pkInit: '12+344',
    pkEnd: '12+349',
    description: 'Acidente de ciclomotor en  vía asasd',
    state: 'open',
    type: 'order',
  },
  {
    id: '9w8g79w8gw89g6',
    cotic: ['1O', '0000', '1234567', '00'],
    date: 1641809400000,
    hour: 1641809400000,
    capitol: 'Altres',
    subCapitol: 'Animals',
    concession: 'AR',
    road: 'C-16',
    pkInit: '12+234',
    pkEnd: '12+238',
    description: 'Acidente de ciclomotor en  vía asasd',
    state: 'validated',
    type: 'order',
  },
  {
    id: 'e9r78gy9ds7gh',
    cotic: ['1O', '0000', '2234567', '00'],
    date: 1642363800000,
    hour: 1642363800000,
    capitol: 'Entorns de carretera',
    subCapitol: 'Senyalització',
    concession: 'TE',
    road: 'C-17',
    pkInit: '22+899',
    pkEnd: '22+900',
    description: 'Acidente de ciclomotor en  vía asasd',
    state: 'partial',
    type: 'order',
  },
  {
    id: '9s8g79s8g7s9g8',
    cotic: ['1R', '0000', '3234567', '00'],
    date: 1646925000000,
    hour: 1646925000000,
    capitol: 'Altres',
    subCapitol: 'Animals',
    concession: 'LL',
    road: 'C-16',
    pkInit: '12+456',
    pkEnd: '12+457',
    description: 'Acidente de ciclomotor en  vía asasd',
    state: 'open',
    type: 'order',
  },
  {
    id: '9s8g7s9g7s9g8',
    cotic: ['1O', '0000', '5234567', '00'],
    date: 1641816600000,
    hour: 1641816600000,
    capitol: 'Entorns de carretera',
    subCapitol: 'Senyalització',
    concession: 'LL',
    road: 'C-17',
    pkInit: '12+457',
    pkEnd: '12+459',
    description: 'Acidente de ciclomotor en  vía asasd',
    state: 'validated',
    type: 'order',
  },
  {
    id: '9s8g7s9g7s9g8',
    cotic: ['1O', '0000', '1234567', '00'],
    date: 1641809400000,
    hour: 1641809400000,
    capitol: 'Accident',
    subCapitol: 'Animals',
    concession: 'AR',
    road: 'C-18',
    pkInit: '12+456',
    pkEnd: '12+457',
    description: 'Acidente de ciclomotor en  vía asasd',
    state: 'annulled',
    type: 'order',
  },
  {
    id: '9s8g7s9g7s9g8',
    cotic: ['1O', '0000', '2234567', '00'],
    date: 1641809400000,
    hour: 1641809400000,
    capitol: 'Accident',
    subCapitol: 'Animals',
    concession: 'AR',
    road: 'C-18',
    pkInit: '12+456',
    pkEnd: '12+457',
    description: 'Acidente de ciclomotor en  vía asasd',
    state: 'annulled',
    type: 'order',
  },
  {
    id: '9s8g7s9g7s9g8',
    cotic: ['1O', '0000', '3234567', '00'],
    date: 1641809400000,
    hour: 1641809400000,
    capitol: 'Accident',
    subCapitol: 'Animals',
    concession: 'AR',
    road: 'C-18',
    pkInit: '12+456',
    pkEnd: '12+457',
    description: 'Acidente de ciclomotor en  vía asasd',
    state: 'annulled',
    type: 'order',
  },
  {
    id: '9s8g7s9g7s9g8',
    cotic: ['1O', '0000', '5234567', '00'],
    date: 1641809400000,
    hour: 1641809400000,
    capitol: 'Accident',
    subCapitol: 'Animals',
    concession: 'AR',
    road: 'C-18',
    pkInit: '12+456',
    pkEnd: '12+457',
    description: 'Acidente de ciclomotor en  vía asasd',
    state: 'annulled',
    type: 'order',
  },
  {
    id: '9s8g7s9g7s9g8',
    cotic: ['1O', '0000', '1234567', '00'],
    date: 1641809400000,
    hour: 1641809400000,
    capitol: 'Accident',
    subCapitol: 'Animals',
    concession: 'AR',
    road: 'C-18',
    pkInit: '12+456',
    pkEnd: '12+457',
    description: 'Acidente de ciclomotor en  vía asasd',
    state: 'annulled',
    type: 'order',
  },
  {
    id: '9s8g7s9g7s9g8',
    cotic: ['1O', '0000', '2234567', '00'],
    date: 1641809400000,
    hour: 1641809400000,
    capitol: 'Accident',
    subCapitol: 'Animals',
    concession: 'AR',
    road: 'C-18',
    pkInit: '12+456',
    pkEnd: '12+457',
    description: 'Acidente de ciclomotor en  vía asasd',
    state: 'annulled',
    type: 'order',
  },
  {
    id: 'a74fa754af89ayfg',
    cotic: ['1R', '0000', '3234567', '00'],
    date: 1646925000000,
    hour: 1646925000000,
    capitol: 'Accident',
    subCapitol: 'Animals',
    concession: 'LL',
    road: 'C-17',
    pkInit: '99+187',
    pkEnd: '99+299',
    description: 'Acidente de ciclomotor en  vía asasd',
    state: 'open',
    type: 'order',
  },
  {
    id: 'a74fa754af89ayfg',
    cotic: ['1O', '0000', '5234567', '00'],
    date: 1641816600000,
    hour: 1641816600000,
    capitol: 'Accident',
    subCapitol: 'Senyalització',
    concession: 'LL',
    road: 'C-17',
    pkInit: '99+187',
    pkEnd: '99+299',
    description: 'Acidente de ciclomotor en  vía asasd',
    state: 'validated',
    type: 'order',
  },
  {
    id: 'a74fa754af89ayfg',
    cotic: ['1O', '0000', '1234567', '00'],
    date: 1641816600000,
    hour: 1641816600000,
    capitol: 'Accident',
    subCapitol: 'Senyalització',
    concession: 'LL',
    road: 'C-17',
    pkInit: '99+187',
    pkEnd: '99+299',
    description: 'Acidente de ciclomotor en  vía asasd',
    state: 'validated',
    type: 'order',
  },
  {
    id: 'a74fa754af89ayfg',
    cotic: ['1O', '0000', '2234567', '00'],
    date: 1641816600000,
    hour: 1641816600000,
    capitol: 'Accident',
    subCapitol: 'Senyalització',
    concession: 'LL',
    road: 'C-17',
    pkInit: '99+187',
    pkEnd: '99+299',
    description: 'Acidente de ciclomotor en  vía asasd',
    state: 'validated',
    type: 'order',
  },
  {
    id: 'a74fa754af89ayfg',
    cotic: ['1O', '0000', '3234567', '00'],
    date: 1641816600000,
    capitol: 'Entorns de carretera',
    subCapitol: 'Animals',
    concession: 'AR',
    road: 'C-18',
    pkInit: '78+912',
    pkEnd: '79+511',
    description: 'Acidente de ciclomotor en  vía asasd',
    state: 'partial',
    type: 'order',
  },
  {
    id: '8s65g8as65fh9sg',
    cotic: ['1O', '0000', '5234567', '00'],
    date: 1641816600000,
    hour: 1641816600000,
    capitol: 'Entorns de carretera',
    subCapitol: 'Animals',
    concession: 'AR',
    road: 'C-18',
    pkInit: '78+911',
    pkEnd: '79+511',
    description: 'Acidente de ciclomotor en  vía asasd',
    state: 'partial',
    type: 'order',
  },
  {
    id: '9s7fg687s5gh',
    cotic: ['1O', '0000', '1234567', '00'],
    date: 1641816610000,
    hour: 1641816610000,
    capitol: 'Accident',
    subCapitol: 'Senyalització',
    concession: 'TE',
    road: 'C-16',
    pkInit: '98+716',
    pkEnd: '98+185',
    description: 'Acidente de ciclomotor en  vía asasd',
    state: 'validated',
    type: 'order',
  },
  {
    id: '68sd5gv78sad5g7s6g',
    cotic: ['1O', '0000', '2234567', '00'],
    date: 1642363810000,
    hour: 1642363810000,
    capitol: 'Accident',
    subCapitol: 'Animals',
    concession: 'AR',
    road: 'C-16',
    pkInit: '87+654',
    pkEnd: '98+765',
    description: 'Acidente de ciclomotor en  vía asasd',
    state: 'validated',
    type: 'order',
  },
  {
    id: 'sfg457s5g65sg4',
    cotic: ['1O', '0000', '1234567', '00'],
    date: 1641816610000,
    hour: 1641816610000,
    capitol: 'Entorns de carretera',
    subCapitol: 'Senyalització',
    concession: 'TE',
    road: 'C-18',
    pkInit: '45+678',
    pkEnd: '56+789',
    description: 'Acidente de ciclomotor en  vía asasd',
    state: 'open',
    type: 'order',
  },
  {
    id: 's97dg6s87d5g8',
    cotic: ['1O', '0000', '2234567', '00'],
    date: 1642363810000,
    hour: 1642363810000,
    capitol: 'Altres',
    subCapitol: 'Animals',
    concession: 'AR',
    road: 'C-18',
    pkInit: '35+790',
    pkEnd: '46+800',
    description: 'Acidente de ciclomotor en  vía asasd',
    state: 'partial',
    type: 'order',
  },
  {
    id: 's9f78ds7fhdh58',
    cotic: ['1R', '0000', '3234567', '00'],
    date: 1646925000000,
    hour: 1646925000000,
    capitol: 'Altres',
    subCapitol: 'Senyalització',
    concession: 'LL',
    road: 'C-16',
    pkInit: '44+777',
    pkEnd: '44+789',
    description: 'Acidente de ciclomotor en  vía asasd',
    state: 'partial',
    type: 'order',
  },
  {
    id: '9sa7d56g78sa5dg',
    cotic: ['1O', '0000', '5234567', '00'],
    date: 1641816600000,
    hour: 1641816600000,
    capitol: 'Altres',
    subCapitol: 'Animals',
    concession: 'AR',
    road: 'C-17',
    pkInit: '11+111',
    pkEnd: '11+116',
    description: 'Acidente de ciclomotor en  vía asasd',
    state: 'partial',
    type: 'order',
  },
  {
    id: '9s8g6879asdg5',
    cotic: ['1O', '0000', '1234567', '00'],
    date: 1641816610000,
    hour: 1641816610000,
    capitol: 'Altres',
    subCapitol: 'Senyalització',
    concession: 'TE',
    road: 'C-16',
    pkInit: '64+311',
    pkEnd: '65+222',
    description: 'Acidente de ciclomotor en  vía asasd',
    state: 'open',
    type: 'order',
  },
  {
    id: '9s7a5g78f5h7868dfh',
    cotic: ['1O', '0000', '1234567', '00'],
    date: 1641816610000,
    hour: 1641816610000,
    capitol: 'Altres',
    subCapitol: 'Animals',
    concession: 'TE',
    road: 'C-17',
    pkInit: '25+280',
    pkEnd: '25+920',
    description: 'Acidente de ciclomotor en  vía asasd',
    state: 'partial',
    type: 'order',
  },
  {
    id: '8s75g76sd4g67',
    cotic: ['1O', '0000', '2234567', '00'],
    date: 1642363810000,
    hour: 1642363810000,
    capitol: 'Altres',
    subCapitol: 'Senyalització',
    concession: 'AR',
    road: 'C-18',
    pkInit: '87+364',
    pkEnd: '89+346',
    description: 'Acidente de ciclomotor en  vía asasd',
    state: 'annulled',
    type: 'order',
  },
  {
    id: '864583gik456g3k',
    cotic: ['1O', '0000', '1234567', '00'],
    date: 1641816610000,
    hour: 1641816610000,
    capitol: 'Accident',
    subCapitol: 'Animals',
    concession: 'TE',
    road: 'C-18',
    pkInit: '62+574',
    pkEnd: '87+465',
    description: 'Acidente de ciclomotor en  vía asasd',
    state: 'partial',
    type: 'order',
  },
  {
    id: '89374i6jk3g6khgjkhg',
    cotic: ['1O', '0000', '2234567', '00'],
    date: 1642363810000,
    hour: 1642363810000,
    capitol: 'Accident',
    subCapitol: 'Senyalització',
    concession: 'AR',
    road: 'C-17',
    pkInit: '78+487',
    pkEnd: '78+500',
    description: 'Acidente de ciclomotor en  vía asasd',
    state: 'partial',
    type: 'order',
  },
  {
    id: '937645h3kjg6khmbg',
    cotic: ['1R', '0000', '3234567', '00'],
    date: 1646925000000,
    hour: 1646925000000,
    capitol: 'Accident',
    subCapitol: 'Animals',
    concession: 'LL',
    road: 'C-17',
    pkInit: '45+678',
    pkEnd: '34+477',
    description: 'Acidente de ciclomotor en  vía asasd',
    state: 'validated',
    type: 'order',
  },
  {
    id: '89dw57g8ssdgfsg',
    cotic: ['1O', '0000', '5234567', '00'],
    date: 1641816600000,
    hour: 1641816600000,
    capitol: 'Accident',
    subCapitol: 'Senyalització',
    concession: 'AR',
    road: 'C-16',
    pkInit: '43+567',
    pkEnd: '44+000',
    description: 'Acidente de ciclomotor en  vía asasd',
    state: 'validated',
    type: 'order',
  },
  {
    id: '8364856jhv3gjnvhgf',
    cotic: ['1O', '0000', '1234567', '00'],
    date: 1641816610000,
    hour: 1641816610000,
    capitol: 'Accident',
    subCapitol: 'Animals',
    concession: 'TE',
    road: 'C-17',
    pkInit: '76+890',
    pkEnd: '87+956',
    description: 'Acidente de ciclomotor en  vía asasd',
    state: 'validated',
    type: 'order',
  },
];

import { ActionFunction } from 'react-router-dom';

// Sentry
import * as Sentry from '@sentry/react';

// GQL
import { client } from '../../../../../../gql/client';
import {
  UPDATE_ORDER,
  UPDATE_ORDER_RELATIONSHIPS,
} from '../../../../../../gql/mutations/orders';
import {
  UPDATE_ACCIDENT_ORDER,
  UPDATE_ACCIDENT_ORDER_RELATIONSHIPS,
} from '../../../../../../gql/mutations/accidents';

export const AccidentNewWorkOrderDetailed: ActionFunction = async ({
  request,
}) => {
  const formData = await request.formData();
  const pkInit = formData.get('pkInit');
  const pkEnd = formData.get('pkEnd');
  const description = formData.get('description');
  const date = formData.get('date');
  const capitol = formData.get('capitol');
  const subCapitol = formData.get('subCapitol');
  const concession = formData.get('concession');
  const road = formData.get('road');
  const id = formData.get('id');
  const modelTypeId = formData.get('modelTypeId');
  const margin = formData.get('margin');
  const direction = formData.get('direction');
  const lane = formData.get('lane');
  const track = formData.get('track');

  await Promise.all([
    date || description
      ? client.mutate({
          mutation: UPDATE_ORDER,
          variables: {
            input: {
              id,
              registerInitDate: date ? date : undefined,
              description: description ? description : undefined,
            },
          },
        })
      : undefined,
    capitol || subCapitol
      ? client.mutate({
          mutation: UPDATE_ORDER_RELATIONSHIPS,
          variables: {
            input: {
              id,
              capitol: capitol ? capitol : undefined,
              subCapitol: subCapitol ? subCapitol : undefined,
            },
          },
        })
      : undefined,
  ]);
  try {
    const pkInitSplit = pkInit?.toString().replace(/ /g, '').split('+');
    const pkEndSplit = pkEnd?.toString().replace(/ /g, '').split('+');
    await Promise.all([
      pkInit || pkEnd || margin || lane || direction || track
        ? client.mutate({
            mutation: UPDATE_ACCIDENT_ORDER,
            variables: {
              input: {
                id: modelTypeId,
                pkInitKm: pkInit ? Number(pkInitSplit?.[0]) : undefined,
                pkInitMeter: pkInit ? Number(pkInitSplit?.[1]) : undefined,
                pkEndKm: pkEnd ? Number(pkEndSplit?.[0]) : undefined,
                pkEndMeter: pkEnd ? Number(pkEndSplit?.[1]) : undefined,
                track: track ? track : undefined,
                margin: margin ? margin : undefined,
                direction: direction ? direction : undefined,
                lane: lane ? lane : undefined,
              },
            },
          })
        : undefined,
      concession || road
        ? client.mutate({
            mutation: UPDATE_ACCIDENT_ORDER_RELATIONSHIPS,
            variables: {
              input: {
                id: modelTypeId,
                concessionId: concession ? concession : undefined,
                roadId: road ? road : undefined,
              },
            },
          })
        : undefined,
    ]);
    return null;
  } catch (err) {
    Sentry.captureException(err);
    console.log('> Work order updated - error updating:', err);
  }
  return null;
};

import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import isEqual from 'lodash.isequal';

// Styled Components
import {
  Container,
  Title,
  ColumnsContainer,
  Column,
  ContainerButtons,
  GeneralContainer,
  MapContainer,
  BasicInfo,
  SecondGeneralColumn,
  FirstGeneralColumn,
  ContentContainer,
  StyledDefinition,
  TabContainer,
  StyledColumnsContainer,
  TitleTableContainer,
} from './styles';

// Media
import { useIsLandscapeTablet } from '../../../hooks/useMediaBreakPoints';

// Components
import InputCotic from '../../inputs/InputCotic';
import { Definition } from '../Definition';
import { MainButton } from '../../inputs/buttons/MainButton';
import { MaterialsTable } from '../tables/summaryTables/MaterialsTable';
import { AuxiliaryMachineryTable } from '../tables/summaryTables/AuxiliaryMachineryTable';
import { OperatorsTable } from '../tables/summaryTables/OperatorsTable';
import VerticalTabs from '../VerticalTabs';
import { MapComponent } from '../Map';
import { AnimalsTable } from '../tables/summaryTables/AnimalsTable';
import { VehiclesTable } from '../tables/summaryTables/VehiclesTable';

// Types
import {
  ContentCardResumeProps,
  marginArrow,
  directionArrow,
  dataTabs,
} from './types';
import { tabsType } from '../../../state/reducers/ui/tabs';
import { UNSPECIFIC } from '../../../types/utils';

// Selector
import { getCurrentIndexTabFormByTabVertical } from '../../../state/selectors/ui/tabs';
import { Typography } from '../Typography';
import { InputTextArea } from '../../inputs/InputTextArea';
import { ExternalCompaniesTable } from '../tables/summaryTables/ExternalCompanies';
import { ResumeLoadImages } from '../ResumeLoadImages';
const MemorizedMap = memo(MapComponent, isEqual);
export default function ActionContentCardResume({
  isActionEditedBoolean,
  routes,
  cotic,
  date,
  hour,
  capitol,
  subCapitol,
  concession,
  road,
  pkInit,
  pkEnd,
  track,
  city,
  roadDirections,
  direction,
  margin,
  lane,
  leftButton,
  rightButton,
  description,
  isMaterialTableDisplayed,
  isAnimalsTableDisplayed,
  asset,
  assetSwitch,
  activity,
  isMachineryTableDisplayed,
  materialsData = [],
  animalsData = [],
  machineryData = [],
  operators = [],
  vehicles = [],
  startDate,
  startHour,
  endDate,
  endHour,
  totalHours,
  totalOperators,
  element,
  laneCutting,
  images,
  works,
  mapCenter,
  path,
  geolocation,
  onEditAction,
  onContinueAction,
  ...props
}: ContentCardResumeProps): JSX.Element {
  const indexTabVertical = useSelector(
    getCurrentIndexTabFormByTabVertical(tabsType.WORK_ORDERS_ACTION)
  );
  const isLandscape = useIsLandscapeTablet();

  const materialsTableWithTab = isMaterialTableDisplayed && {
    label: 'Materials',
    content: (
      <TabContainer>
        <TitleTableContainer>
          <Typography size={isLandscape ? 'M' : 'L'} variant="semiBold">
            Materials
          </Typography>
          <MaterialsTable data={materialsData} />
        </TitleTableContainer>
      </TabContainer>
    ),
  };
  const machineryTableWithTab = isMachineryTableDisplayed && {
    label: 'Maquinària Aux.',
    content: (
      <TabContainer>
        <TitleTableContainer>
          <Typography size={isLandscape ? 'M' : 'L'} variant="semiBold">
            Maquinària Auxiliar
          </Typography>
          <AuxiliaryMachineryTable data={machineryData} />
        </TitleTableContainer>
      </TabContainer>
    ),
  };

  const animalsTableWithTab = isAnimalsTableDisplayed && {
    label: 'Animals',
    content: (
      <TabContainer>
        <TitleTableContainer>
          <Typography size={isLandscape ? 'M' : 'L'} variant="semiBold">
            Animals
          </Typography>
          <AnimalsTable data={animalsData} />
        </TitleTableContainer>
      </TabContainer>
    ),
  };

  const dataTabs: dataTabs[] = [
    {
      label: 'Operaris i vehicles',
      content: (
        <TabContainer>
          <TitleTableContainer>
            {!works && (
              <Typography size={isLandscape ? 'M' : 'L'} variant="semiBold">
                Operaris
              </Typography>
            )}
            <StyledColumnsContainer>
              {works && works.length > 0 ? (
                <TitleTableContainer>
                  <Typography size={isLandscape ? 'M' : 'L'} variant="semiBold">
                    Empreses externes
                  </Typography>
                  <ExternalCompaniesTable data={works} />
                </TitleTableContainer>
              ) : (
                <OperatorsTable data={operators} />
              )}
              <Column>
                <Definition title="Data d’inici " value={startDate} />
                <Definition title="Hora d’inici " value={startHour} />
                <Definition title="Data final  " value={endDate} />
                <Definition title="Hora final  " value={endHour} />
                <StyledDefinition title="Total d’hores " value={totalHours} />
                <StyledDefinition
                  title="Total d’operaris "
                  value={totalOperators}
                />
              </Column>
            </StyledColumnsContainer>
          </TitleTableContainer>
          {!works && (
            <TitleTableContainer>
              <Typography size={isLandscape ? 'M' : 'L'} variant="semiBold">
                Vehicles
              </Typography>
              <VehiclesTable data={vehicles} />
            </TitleTableContainer>
          )}
        </TabContainer>
      ),
    },
    materialsTableWithTab as dataTabs,
    machineryTableWithTab as dataTabs,
    animalsTableWithTab as dataTabs,
  ].filter((elem) => elem);

  return (
    <GeneralContainer {...props}>
      <FirstGeneralColumn>
        <BasicInfo>
          <Container>
            <Title size="L" variant="semiBold">
              Resum de l’actuació
            </Title>
            <ColumnsContainer>
              <Column>
                <InputCotic
                  label="COTIC"
                  cotic={cotic}
                  disabled
                  onChangeInputCotic={() => {
                    null;
                  }}
                />
              </Column>
              <Column>
                <Typography size={isLandscape ? 'XS' : 'M'} variant="semiBold">
                  Informació bàsica
                </Typography>
                <Definition title="Data de registre" value={date} />
                <Definition title="Hora de registre" value={hour} />
              </Column>
            </ColumnsContainer>
          </Container>
          <Container>
            <Typography size={isLandscape ? 'M' : 'L'} variant="semiBold">
              Descripció de la ubicació
            </Typography>
            <ColumnsContainer>
              <Column>
                <Definition title="Concessió" value={concession} />
                <Definition title="Carretera" value={road} />
                <Definition title="Pk inicial" value={pkInit} />
                <Definition title="Pk final" value={pkEnd} />
              </Column>
              <Column>
                <Definition title="Tipus de vial" value={track} />
                {direction && direction !== UNSPECIFIC && (
                  <Definition
                    title="Sentit"
                    value={`${
                      direction === 'both' || !roadDirections[0]
                        ? city
                        : direction === 'down'
                        ? roadDirections[0]
                        : roadDirections[roadDirections.length - 1]
                    } ${directionArrow[direction]}`}
                  />
                )}
                {margin && margin !== UNSPECIFIC && (
                  <Definition title="Marge" value={marginArrow[margin]} />
                )}
                <Definition title="Carril" value={lane} />
              </Column>
            </ColumnsContainer>
          </Container>
        </BasicInfo>
        {direction && direction !== UNSPECIFIC && mapCenter && (
          <MapContainer>
            <MemorizedMap
              center={mapCenter}
              zoom={15}
              path={path}
              geoloc={geolocation}
              apiKey={import.meta.env.VITE_MAP_API_KEY}
            />
          </MapContainer>
        )}
        <Container>
          <Typography size={isLandscape ? 'M' : 'L'} variant="semiBold">
            {`Descripció de l'actuació`}
          </Typography>
          <ContentContainer>
            <ColumnsContainer>
              <Column>
                <Definition title="Capitol" value={capitol} />
                <Definition title="Subcapitol" value={subCapitol} />
                {asset && assetSwitch && (
                  <Definition title="Actiu" value={asset} />
                )}
                {activity && <Definition title="Acció" value={activity} />}
                <Definition title="Element" value={element} />
                <Definition
                  title="Tall de carril"
                  value={laneCutting ? 'Si' : 'No'}
                />
              </Column>
              {images && images.length > 0 && (
                <Column>
                  <Definition title="Multimedia" value={''} />
                  <ResumeLoadImages imageData={images} />
                </Column>
              )}
            </ColumnsContainer>
            <InputTextArea
              label="Descripció validada:"
              disabled
              value={description}
            />
          </ContentContainer>
        </Container>
      </FirstGeneralColumn>
      <SecondGeneralColumn>
        <VerticalTabs
          defaultIndex={
            indexTabVertical >= dataTabs.length ? 0 : indexTabVertical
          }
          data={dataTabs}
          typeTab={tabsType.WORK_ORDERS_ACTION}
        />
        <ContainerButtons>
          {routes.includes('actions-validation') ? (
            <>
              {rightButton && (
                <MainButton
                  type="button"
                  text={rightButton}
                  onClick={onContinueAction}
                />
              )}
              {leftButton &&
                (!routes.includes('actions-validation') ||
                  !isActionEditedBoolean) && (
                  <MainButton
                    type="button"
                    variant="secondary"
                    text={leftButton}
                    onClick={onEditAction}
                  />
                )}
            </>
          ) : (
            <>
              {leftButton &&
                (!routes.includes('actions-validation') ||
                  !isActionEditedBoolean) && (
                  <MainButton
                    type="button"
                    variant="secondary"
                    text={leftButton}
                    onClick={onEditAction}
                  />
                )}
              {rightButton && (
                <MainButton
                  type="button"
                  text={rightButton}
                  onClick={onContinueAction}
                />
              )}
            </>
          )}
          {/* {leftButton &&
            (!routes.includes('actions-validation') ||
              !isActionEditedBoolean) && (
              <MainButton
                type="button"
                variant="secondary"
                text={leftButton}
                onClick={onEditAction}
              />
            )}
          {rightButton && (
            <MainButton
              type="button"
              text={rightButton}
              onClick={onContinueAction}
            />
          )} */}
        </ContainerButtons>
      </SecondGeneralColumn>
    </GeneralContainer>
  );
}

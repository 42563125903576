import React from 'react';

import { Controller } from 'react-hook-form';

// Components Styled
import { InputsContainer, Title } from '../components/MainStyled';

// Types
import { FormProps } from './types';
import {
  InputRow,
  StateOfTheRoadContainer,
  StyledInputTextArea,
} from './styles';
import { Options } from '../../inputs/DropDown/DropDownList/types';
import DropDownInput from '../../inputs/DropDown/DropDownInput';

export const StateOfTheRoad = ({ register, control, errors }: FormProps) => {
  return (
    <StateOfTheRoadContainer>
      <Title component="h2" variant="semiBold" size="L">
        Estat de la carretera
      </Title>

      <InputsContainer>
        <InputRow>
          <Controller
            control={control}
            name="weather"
            render={({ field: { onChange }, formState: { defaultValues } }) => {
              const options =
                defaultValues?.weathers?.map((weather) => ({
                  label: weather?.name,
                  value: weather?.id,
                })) || [];

              return (
                <DropDownInput
                  inputSize="M"
                  onChangeSelected={(option) => onChange(option?.value ?? '')}
                  typeDropDown="Default"
                  labelText="Temps"
                  placeholder="Selecciona"
                  defaultValue={defaultValues?.weather}
                  borderError={!!errors?.weather}
                  options={options as Options}
                />
              );
            }}
          />

          <Controller
            control={control}
            name="surfaceCondition"
            render={({ field: { onChange }, formState: { defaultValues } }) => {
              const options =
                defaultValues?.surfaceConditions?.map((surfaceCondition) => ({
                  label: surfaceCondition?.name,
                  value: surfaceCondition?.id,
                })) || [];

              return (
                <DropDownInput
                  inputSize="L"
                  onChangeSelected={(option) => onChange(option?.value ?? '')}
                  typeDropDown="Default"
                  labelText="Estat de la superfície"
                  placeholder="Selecciona"
                  defaultValue={defaultValues?.surfaceCondition}
                  borderError={!!errors?.surfaceCondition}
                  options={options as Options}
                />
              );
            }}
          />
        </InputRow>

        <InputRow>
          <Controller
            control={control}
            name="luminosity"
            render={({ field: { onChange }, formState: { defaultValues } }) => {
              const options =
                defaultValues?.luminositys?.map((luminosity) => ({
                  label: luminosity?.name,
                  value: luminosity?.id,
                })) || [];

              return (
                <DropDownInput
                  inputSize="M"
                  onChangeSelected={(option) => onChange(option?.value ?? '')}
                  typeDropDown="Default"
                  labelText="Lluminositat"
                  placeholder="Selecciona"
                  defaultValue={defaultValues?.luminosity}
                  borderError={!!errors?.luminosity}
                  options={options as Options}
                />
              );
            }}
          />

          <Controller
            control={control}
            name="conditionsDriving"
            render={({ field: { onChange }, formState: { defaultValues } }) => {
              const options =
                defaultValues?.conditionsDrivings?.map((conditionsDriving) => ({
                  label: conditionsDriving?.name,
                  value: conditionsDriving?.id,
                })) || [];
              return (
                <DropDownInput
                  inputSize="L"
                  onChangeSelected={(option) => onChange(option?.value ?? '')}
                  typeDropDown="Default"
                  labelText="Condicions circulació (abans)"
                  placeholder="Selecciona"
                  defaultValue={defaultValues?.conditionsDriving}
                  borderError={!!errors?.conditionsDriving}
                  options={options as Options}
                />
              );
            }}
          />
        </InputRow>
        <StyledInputTextArea
          {...register('roadConditionDescription', {})}
          name="roadConditionDescription"
          label="Descripció de l'incident:"
          placeholder="Escriure aqui."
          error=""
          disabled={false}
          borderError={!!errors?.roadConditionDescription}
        />
      </InputsContainer>
    </StateOfTheRoadContainer>
  );
};

import React from 'react';

// Styles
import { Container } from './styles';

// Components
import LoginForm from '../../../components/forms/LoginForm';

//Layouts
import CredentialsLayout from '../../../components/layouts/CredentialsLayout';

export default function LoginPage() {
  return (
    <Container>
      <CredentialsLayout>
        <LoginForm />
      </CredentialsLayout>
    </Container>
  );
}

import React, { useCallback, useState } from 'react';
import { useAsyncValue, useNavigate, useRevalidator } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

// Styles
import { Container } from './styles';
import { ContainerButton } from '../../components/MainStyledComponents';

//Components
import { MainButton } from '../../../../../components/inputs/buttons/MainButton';

// Types
import { MultimediaTable } from '../../../../../components/display/tables/MultimediaTable';
import {
  tableIds,
  takeBase,
} from '../../../../../components/display/tables/types';

// GraphQl
import { client } from '../../../../../gql/client';
import { FETCH_ORDERS } from '../../../../../gql/queries/orders/orders';
import { dataTypes } from '../../../../../components/display/tables/MultimediaTable/types';

// Redux
import { getCursor } from '../../../../../state/selectors/ui/tables';
import { setCursor } from '../../../../../state/actions/ui/cursorTables';

export const MultimediaPage = () => {
  const data = useAsyncValue() as dataTypes;
  const [selectedRowId, setSelectedRowId] = useState<string>('');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const revalidator = useRevalidator();

  const handleSeeOrder = useCallback(() => {
    navigate(`./${selectedRowId}`);
  }, [selectedRowId]);

  const selectorCursor = useSelector(getCursor(tableIds.MULTIMEDIA));

  const onReFetchData = useCallback(async () => {
    await client.query({
      query: FETCH_ORDERS,
      variables: {
        input: {
          take: takeBase,
          isMultimedia: true,
          cursor: '',
          orderBy: 'desc',
          state: selectorCursor.state,
          type: selectorCursor.type,
          fromDate: selectorCursor.fromDate,
          toDate: selectorCursor.toDate,
          indextype: selectorCursor.indexType,
          indexExpedition: selectorCursor.indexExpedition,
          classificaton: selectorCursor.classification,
          roadId: selectorCursor.roadId,
        },
      },
      fetchPolicy: 'network-only',
    });

    const setCursorData = {
      take: takeBase,
      orderBy: 'desc',
      cursor: '',
      state: selectorCursor?.state,
      type: selectorCursor?.type,
      fromDate: selectorCursor?.fromDate,
      toDate: selectorCursor?.toDate,
      indextype: selectorCursor?.indexType,
      indexExpedition: selectorCursor?.indexExpedition,
      classificaton: selectorCursor?.classification,
      roadId: selectorCursor.roadId,
    };

    dispatch(setCursor(tableIds.MULTIMEDIA, setCursorData));

    revalidator.revalidate();
  }, [selectorCursor]);

  const onRefetchPagination = useCallback(async () => {
    revalidator.revalidate();
  }, []);

  return (
    <Container>
      <MultimediaTable
        titleText="Filtrar Multimedia per:"
        data={data}
        setRowState={setSelectedRowId}
        onReFetchData={onReFetchData}
        onReFetchPagination={onRefetchPagination}
        tableId={tableIds.MULTIMEDIA}
      />
      <ContainerButton>
        <MainButton
          text="Veure Ordre de Treball"
          disabled={selectedRowId.length === 0}
          onClick={handleSeeOrder}
        />
      </ContainerButton>
    </Container>
  );
};

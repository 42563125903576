import { ActionFunction } from 'react-router-dom';

// Sentry
import * as Sentry from '@sentry/react';

// Actions
import { saveData } from './saveData';

// Utils
import {
  compressSessionStorage,
  decompressSessionStorage,
} from '../../../../utils/sessionStorage';

// Constants
import { formActionNaming } from '../../../../components/forms/ActionForms/constants';

export const ActionNewAction: ActionFunction = async ({ request }) => {
  const formData = await request.formData();
  const intent = formData.get('intent');
  if (intent === 'pass-data') {
    try {
      const dataFormRaw = sessionStorage.getItem(
        formActionNaming.NEW_ACTION_DATA
      );
      if (dataFormRaw) {
        const dataForm = JSON.parse(decompressSessionStorage(dataFormRaw));
        sessionStorage.setItem(
          formActionNaming.NEW_ACTION_DATA,
          compressSessionStorage(
            JSON.stringify({
              ...dataForm,
              orderParentId: formData.get('id'),
              orderParentCotic: formData.get('cotic'),
            })
          )
        );
        return {
          orderParentId: formData?.get('id') ?? dataForm?.orderParentId,
          orderParentCotic:
            formData?.get('cotic') ?? dataForm?.orderParentCotic,
        };
      } else {
        sessionStorage.setItem(
          formActionNaming.NEW_ACTION_DATA,
          compressSessionStorage(
            JSON.stringify({
              orderParentId: formData.get('id'),
              orderParentCotic: formData.get('cotic'),
            })
          )
        );
      }
      return {
        orderParentId: formData.get('id'),
        orderParentCotic: formData.get('cotic'),
      };
    } catch (err) {
      Sentry.captureException(err);
      console.log('> NewAction pass-data - error creating:', err);
    }
  }
  if (intent === 'save-data') {
    try {
      const orderParentId = formData.get('orderParentId');
      const orderParentCotic = formData.get('orderParentCotic');
      const dataFormRaw = sessionStorage.getItem(
        formActionNaming.NEW_ACTION_DATA
      );
      return saveData(
        dataFormRaw,
        orderParentId,
        orderParentCotic,
        formActionNaming.NEW_ACTION_DATA
      );
    } catch (err) {
      Sentry.captureException(err);
      console.log('> NewAction save-data - error creating:', err);
    }
  }

  return null;
};

import styled from 'styled-components';

import { Pencil } from '../../../assets/icons/Pencil';
import { Figures } from '../../../assets/icons/Figures';
import { Text } from '../../../assets/icons/Text';
import { Eraser } from '../../../assets/icons/Eraser';
import { Circle } from '../../../assets/icons/Circle';
import { Square } from '../../../assets/icons/Square';
import { CloseIcon } from '../../../assets/icons/CloseIcon';
import { Selection } from '../../../assets/icons/Selection';

import { colors } from '../../../styles/colors';

import {
  breakPoints,
  percentBaseResponsive,
  spaces,
} from '../../../styles/spaces';

import { ContainerInputProps } from './types';

export const Container = styled.div`
  width: 100%;
  .canvas {
    height: 100%;
    height: 75rem;
  }
  .lower-canvas {
    border: 0.1rem solid ${colors.black};
    border-radius: 0.5rem;
  }
  @media (max-width: ${breakPoints.smallDesktop * percentBaseResponsive}rem) {
    .canvas {
      height: 53rem;
    }
  }
  @media (max-width: ${breakPoints.landscapeTablet *
    percentBaseResponsive}rem) {
    .canvas {
      height: 36rem;
    }
  }

  @media (max-width: ${breakPoints.portraitTablet * percentBaseResponsive}rem) {
    .canvas {
      height: 60rem;
    }
  }
`;

export const ContainerCanvas = styled.div`
  display: flex;
  gap: ${spaces.xl}rem;

  @media (max-width: ${breakPoints.landscapeTablet *
    percentBaseResponsive}rem) {
    flex-direction: column;
  }
`;

export const SubContainerCanvas = styled.div`
  width: calc(100% - 8rem);
  background-color: ${colors.white};

  @media (max-width: ${breakPoints.landscapeTablet *
    percentBaseResponsive}rem) {
    width: 100%;
  }
`;

export const ContainerButton = styled.div`
  display: flex;
  justify-content: end;
  margin-top: ${spaces.xl}rem;
  @media (max-width: ${breakPoints.landscapeTablet *
    percentBaseResponsive}rem) {
    margin-top: ${spaces.m}rem;
    justify-content: space-between;
  }
`;

export const ContainerTab = styled.div`
  align-self: start;
  display: flex;
  flex-direction: column;
  background-color: ${colors.white};
  border: 0.1rem solid ${colors.black};
  border-radius: 0.5rem;
  @media (max-width: ${breakPoints.landscapeTablet *
    percentBaseResponsive}rem) {
    flex-direction: row;
    align-self: end;
  }
`;

export const InputContainer = styled.div<ContainerInputProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 5.8rem;
  width: 3.8rem;
  cursor: pointer;
  :not(:last-child) {
    border-bottom: 0.06rem solid ${colors.greyLight};
  }
  ${(props) =>
    props.isSelected && {
      backgroundColor: colors.black,
    }}

  @media (max-width: ${breakPoints.landscapeTablet *
  percentBaseResponsive}rem) {
    height: 3.8rem;
    width: 5.8rem;
    :not(:last-child) {
      border-bottom: none;
      border-right: 0.06rem solid ${colors.greyLight};
    }
  }
`;

export const Input = styled.input`
  opacity: 0;
  height: 0;
  width: 0;
`;

export const PencilIcon = styled(Pencil)`
  fill: ${colors.black};
  input[type='radio']:checked + & {
    fill: ${colors.white};
  }
`;

export const FiguresIcon = styled(Figures)`
  fill: ${colors.white};
  stroke: ${colors.black};
  input[type='radio']:checked + & {
    stroke: ${colors.white};
    fill: ${colors.black};
  }
`;

export const TextIcon = styled(Text)`
  fill: ${colors.black};
  input[type='radio']:checked + & {
    fill: ${colors.white};
  }
`;

export const EraserIcon = styled(Eraser)`
  stroke: ${colors.black};
  .right {
    fill: ${colors.black};
  }
  .left {
    fill: ${colors.white};
  }
  input[type='radio']:checked + & {
    stroke: ${colors.white};
    .right {
      fill: ${colors.white};
    }
    .left {
      fill: ${colors.black};
    }
  }
`;

export const FiguresContainer = styled.div`
  position: absolute;
  display: flex;
  background-color: ${colors.white};
  border: 0.1rem solid ${colors.black};
  border-radius: 0.5rem;
  @media (max-width: ${breakPoints.landscapeTablet *
    percentBaseResponsive}rem) {
    flex-direction: column;
  }
`;

export const InputFigureContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3.5rem;
  width: 4.5rem;
  :not(:last-child) {
    border-right: 0.1rem solid ${colors.greyLight};
  }
  :hover {
    background-color: ${colors.black};
    .icon {
      fill: ${colors.black};
      stroke: ${colors.white};
    }
  }
  @media (max-width: ${breakPoints.landscapeTablet *
    percentBaseResponsive}rem) {
    height: 4.5rem;
    width: 3.5rem;
    :not(:last-child) {
      border-right: none;
      border-bottom: 0.1rem solid ${colors.greyLight};
    }
  }
`;

export const CircleIcon = styled(Circle)`
  fill: ${colors.white};
  stroke: ${colors.black};
`;
export const SquareIcon = styled(Square)`
  fill: ${colors.white};
  stroke: ${colors.black};
`;

export const ClearIcon = styled(CloseIcon)`
  path {
    fill: ${colors.black};
  }
`;

export const SelectionIcon = styled(Selection)`
  stroke: ${colors.black};
`;

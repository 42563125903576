import { gql } from '@apollo/client';

export const FETCH_MATERIALS = gql`
  query FetchMaterials {
    materials {
      id
      materialId
      name
      typeOfMeasurement
      codification
      subCodification

      measureTypeIDs
      codificationIDs
      subCodificationIDs
    }
  }
`;

export const GET_MATERIAL_BY_ID = gql`
  query GetMaterialById($id: ID!) {
    material(id: $id) {
      id
      materialId
      name
      typeOfMeasurement
      codification
      subCodification

      measureTypeIDs
      codificationIDs
      subCodificationIDs
    }
  }
`;

export const GET_MATERIAL_UNIT_BY_ID = gql`
  query GetMaterialUnitById($id: ID!) {
    materialUnit(id: $id) {
      id
      unit
      materialIDs
    }
  }
`;

import styled, { css } from 'styled-components';

// Types
import { buttonProps } from './types';

// Styles
import {
  spaces,
  breakPoints,
  percentBaseResponsive,
} from '../../../styles/spaces';
import { colors } from '../../../styles/colors';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spaces.xl}rem;
  width: 100%;
  @media (max-width: ${breakPoints.smallDesktop * percentBaseResponsive}rem) {
    gap: ${spaces.l}rem;
  }
`;

export const ContainerTabsButtons = styled.div`
  display: flex;
`;

export const Ul = styled.ul`
  display: flex;
  border: 0.1rem solid ${colors.greyVeryDark};
  border-radius: 0.8rem;
`;

export const Li = styled.li`
  flex: 1;
  display: ${({ hidden }) => (hidden ? 'none' : 'block')};
`;

export const Button = styled.button<buttonProps>`
  width: 100%;
  padding: 0.7rem ${spaces.m}rem;
  border-radius: 0.8rem;
  white-space: nowrap;
  border: none;
  appearance: none;
  background: none;
  cursor: pointer;

  ${({ isActive }) =>
    isActive &&
    css`
      outline: 0.2rem solid ${colors.garnet};
      outline-offset: -0.1rem;
    `}
  @media (max-width: ${breakPoints.landscapeTablet *
  percentBaseResponsive}rem) {
    min-width: 18rem;
  }
`;

export const ContainerSections = styled.div``;

export const Section = styled.section``;

export const ContainerUl = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.7rem;
`;

import { defer, LoaderFunction } from 'react-router-dom';

// Sentry
import * as Sentry from '@sentry/react';

// GQL
import { client } from '../../../../gql/client';
import { FETCH_MATERIALS } from '../../../../gql/queries/actions/materials';

// Utils
import { AuthContextType } from '../../../../hooks/useAuth';
import { decompressSessionStorage } from '../../../../utils/sessionStorage';

// Constants
import { formMaterialManagementNaming } from '../../../../components/forms/AddMaterialManagementForm/constants';
import {
  FETCH_MEASURE_TYPES,
  GET_MEASURE_TYPES_BY_ID,
} from '../../../../gql/queries/actions/measureTypes';
import {
  FETCH_CODIFICATIONS,
  GET_CODIFICATION_BY_ID,
} from '../../../../gql/queries/actions/codification';
import {
  FETCH_SUBCODIFICATIONS,
  GET_SUBCODIFICATION_BY_ID,
} from '../../../../gql/queries/actions/subCodification';

const loadTableData = async (userData: AuthContextType | null) => {
  try {
    if (userData?.user) {
      let materialsToReturn;
      const {
        data: { materials },
      } = await client.query({
        query: FETCH_MATERIALS,
        fetchPolicy: 'cache-only',
      });
      if (!materials || materials.length === 0) {
        const {
          data: { materials },
        } = await client.query({
          query: FETCH_MATERIALS,
        });
        materialsToReturn = materials;
      } else {
        materialsToReturn = materials;
      }
      const [
        {
          data: { measureTypes },
        },
        {
          data: { codifications },
        },
        {
          data: { subCodifications },
        },
      ] = await Promise.all([
        client.query({
          query: FETCH_MEASURE_TYPES,
        }),
        client.query({
          query: FETCH_CODIFICATIONS,
        }),
        client.query({
          query: FETCH_SUBCODIFICATIONS,
        }),
      ]);

      materialsToReturn = [...materialsToReturn].sort((a, b) =>
        a.name.localeCompare(b.name)
      );

      const materialsData = await Promise.all(
        materialsToReturn.map(
          async (material: {
            id: string;
            materialId: string;
            name: string;
            typeOfMeasurement: string;
            codification: string;
            subCodification: string;
            measureTypeIDs: string[];
            codificationIDs: string[];
            subCodificationIDs: string[];
          }) => {
            const [
              {
                data: { measureType },
              },
              {
                data: { codification },
              },
              {
                data: { subCodification },
              },
            ] = await Promise.all([
              client.query({
                query: GET_MEASURE_TYPES_BY_ID,
                variables: {
                  id: material?.measureTypeIDs[0],
                },
                fetchPolicy: 'cache-only',
              }),
              client.query({
                query: GET_CODIFICATION_BY_ID,
                variables: {
                  id: material?.codificationIDs[0],
                },
                fetchPolicy: 'cache-only',
              }),
              client.query({
                query: GET_SUBCODIFICATION_BY_ID,
                variables: {
                  id: material?.subCodificationIDs[0],
                },
                fetchPolicy: 'cache-only',
              }),
            ]);

            return {
              id: material.id,
              materialId: material.materialId,
              name: material.name,
              typeOfMeasurement: measureType.name,
              codification: codification.name,
              subCodification: subCodification.name,
            };
          }
        )
      );
      return {
        materialsData,
        measureTypes,
        codifications,
        subCodifications,
      };
    }
    return {};
  } catch (err) {
    Sentry.captureException(err);
    console.log('> User management loading error:', err);
    return true;
  }
};

export const MaterialsManagementLoader: (
  materialData: AuthContextType | null
) => LoaderFunction | undefined = (materialData) => async () => {
  const data = await loadTableData(materialData);

  let cacheData =
    sessionStorage.getItem(
      formMaterialManagementNaming.NEW_MATERIAL_MANAGEMENT_FORM
    ) || {};

  if (Object.keys(cacheData).length > 0) {
    cacheData = JSON.parse(decompressSessionStorage(cacheData as string));
  }
  return defer({
    data: {
      data,
      cacheData,
    },
  });
};

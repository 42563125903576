import React from 'react';

type CircularArrowsProps = {
  className?: string;
};

export const CircularArrowsIcon = ({
  className,
}: CircularArrowsProps): JSX.Element => {
  return (
    <svg
      width="58"
      height="48"
      viewBox="0 0 58 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M48.6136 35.7744C48.275 35.8239 47.9295 35.7566 47.6348 35.5834C47.0513 35.3028 46.6029 34.8041 46.3872 34.1959C46.1715 33.5877 46.206 32.9193 46.4832 32.3363C48.1909 28.7587 48.7387 24.7412 48.051 20.8401C47.3633 16.9389 45.474 13.347 42.6447 10.5615C39.7827 7.76305 36.1322 5.89847 32.179 5.21603C28.2258 4.5336 24.1575 5.06566 20.5157 6.74138C19.9216 6.99958 19.2514 7.02494 18.6393 6.81244C18.0272 6.59994 17.5184 6.16525 17.2145 5.59533C16.9537 5.00797 16.9298 4.34331 17.1477 3.73889C17.3657 3.13447 17.8087 2.63654 18.3853 2.34819C22.8938 0.221953 27.9487 -0.478471 32.8694 0.34113C37.79 1.16073 42.3403 3.46105 45.9074 6.9324C49.4262 10.4069 51.7671 14.8882 52.6032 19.7506C53.4393 24.6131 52.7289 29.6141 50.5712 34.0554C50.5645 34.299 50.5075 34.5386 50.4037 34.7593C50.2999 34.98 50.1516 35.1772 49.9679 35.3385C49.7843 35.4997 49.5693 35.6216 49.3363 35.6966C49.1034 35.7716 48.8573 35.7981 48.6136 35.7744Z"
        fill="#5E5C5C"
      />
      <path
        d="M28.9976 48.001C22.5286 48.0043 16.3203 45.4642 11.7242 40.9337C8.20545 37.4592 5.86462 32.9778 5.02852 28.1154C4.19242 23.2529 4.90276 18.252 7.06041 13.8107C7.35373 13.2308 7.86209 12.7871 8.47807 12.5732C9.09404 12.3594 9.76941 12.3922 10.3615 12.6647C10.9484 12.9419 11.4011 13.4392 11.6205 14.0477C11.8399 14.6563 11.8082 15.3265 11.5323 15.9119C9.82465 19.4895 9.27678 23.5069 9.96448 27.4081C10.6522 31.3092 12.5415 34.9011 15.3708 37.6866C18.2328 40.485 21.8834 42.3496 25.8366 43.032C29.7898 43.7145 33.858 43.1824 37.4999 41.5067C38.0939 41.2485 38.7641 41.2231 39.3762 41.4356C39.9883 41.6481 40.4972 42.0829 40.801 42.6528C41.0632 43.2379 41.09 43.9007 40.8757 44.5048C40.6615 45.1089 40.2226 45.6082 39.6494 45.8998C36.2546 47.2319 32.6462 47.9438 28.9976 48.001Z"
        fill="#5E5C5C"
      />
      <path
        d="M48.8062 35.776C48.4779 35.7755 48.1532 35.7074 47.8527 35.5759C47.5521 35.4444 47.2821 35.2524 47.0597 35.0121L41.8201 29.8549C41.5879 29.6322 41.4032 29.3651 41.2771 29.0697C41.1509 28.7743 41.0859 28.4567 41.0859 28.1358C41.0859 27.8148 41.1509 27.4972 41.2771 27.2018C41.4032 26.9065 41.5879 26.6395 41.8201 26.4168C42.2841 25.9572 42.912 25.6992 43.5666 25.6992C44.2212 25.6992 44.8492 25.9572 45.3132 26.4168L49.1517 30.2369L54.3912 27.5628C54.6817 27.412 55.0001 27.3221 55.3267 27.2985C55.6534 27.2749 55.9815 27.3181 56.2908 27.4254C56.6001 27.5328 56.884 27.702 57.1251 27.9228C57.3661 28.1435 57.5592 28.4109 57.6924 28.7088C57.8433 28.995 57.9332 29.3091 57.9565 29.6315C57.9799 29.9539 57.9361 30.2775 57.8281 30.5822C57.72 30.887 57.5499 31.1664 57.3284 31.4027C57.1069 31.6391 56.8388 31.8274 56.5408 31.9559L49.7275 35.394C49.6086 35.5174 49.4654 35.6149 49.3069 35.6807C49.1484 35.7464 48.9779 35.7789 48.8062 35.776Z"
        fill="#5E5C5C"
      />
      <path
        d="M14.4316 18.9673C14.1036 18.9644 13.7796 18.8953 13.4794 18.764C13.1791 18.6326 12.9089 18.4418 12.685 18.2032L8.84651 14.3831L3.60695 17.0572C3.31651 17.2079 2.99814 17.2979 2.67145 17.3215C2.34475 17.3451 2.01665 17.3019 1.70737 17.1945C1.39809 17.0872 1.11418 16.9179 0.873139 16.6972C0.632094 16.4765 0.439023 16.209 0.305819 15.9112C0.155497 15.6238 0.0665067 15.3086 0.0444747 14.9853C0.0224427 14.6621 0.0678332 14.3378 0.177783 14.0328C0.287732 13.7279 0.45986 13.4489 0.683369 13.2133C0.906877 12.9778 1.17695 12.7908 1.47657 12.6641L8.27073 9.22591C8.71449 9.04079 9.2003 8.9786 9.6767 9.04585C10.1531 9.1131 10.6024 9.30731 10.9769 9.60795L16.2165 14.7651C16.4487 14.9878 16.6333 15.2549 16.7595 15.5503C16.8856 15.8456 16.9507 16.1633 16.9507 16.4842C16.9507 16.8051 16.8856 17.1228 16.7595 17.4181C16.6333 17.7135 16.4487 17.9805 16.2165 18.2032C15.7078 18.6235 15.088 18.8888 14.4316 18.9673Z"
        fill="#5E5C5C"
      />
    </svg>
  );
};

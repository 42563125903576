export type ModalStateType = {
  id: string;
  data?: Record<string, never>;
};

export const modalIds = {
  DOWNLOAD_HISTORIC: 'download-historic',
  RELATES_COMMUNICATION_MODAL: 'relates-communication-modal',
  CHANGE_CAMPAIGN_TO_OT_MODAL: 'change-campaign-to-ot-modal',
  ADD_MULTIMEDIA_MODAL: 'add-multimedia-modal',
  OPEN_CAMERA_MODAL: 'open-camera-modal',
  ADD_SKETCH_MODAL: 'add-sketch-modal',
  VIEW_FULLSCREEN_IMAGE: 'view-fullscreen-image',
  CHANGE_STATE_MODAL: 'change-state-modal',
  TRANSFER_SUBORDER_MODAL: 'transfer-suborder-modal',
  CONFIRM_TRANSFER_MODAL: 'confirm-transfer-modal',
};

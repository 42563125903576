import {
  Concessions,
  Coordinates,
} from '../components/forms/ActionForms/LocationForm/types';

export const getLatLon = (idConcession: string, concessions: Concessions) => {
  const concession = concessions?.find(
    (concession) => concession.id === idConcession
  );
  return {
    lng: concession?.centerLongitud,
    lat: concession?.centerLatitud,
  } as Coordinates;
};

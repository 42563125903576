import React from 'react';

type CircleIconProps = {
  className?: string;
};

export const Circle = ({ className }: CircleIconProps): JSX.Element => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 11 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <circle
        cx="5.44166"
        cy="6.3987"
        r="5.14773"
        fill="color"
        stroke="color"
        strokeWidth="0.587862"
      />
    </svg>
  );
};

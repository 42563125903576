import React, { useId, forwardRef, Ref, useState } from 'react';

// Types
import { InputTextProps } from './types';

// Components Styled
import {
  Container,
  InputContainer,
  InputValue,
  Label,
  ErrorMessage,
  IconEye,
  ContainerIconEye,
} from './styles';

/**
 * Primary UI component for user interaction
 */
function InputText(
  {
    label,
    labelSize = 'S',
    error,
    name,
    typeInput = 'normal',
    className,
    borderError,
    type,
    inputSize,
    ...props
  }: InputTextProps,
  ref: Ref<HTMLInputElement>
): JSX.Element {
  const [showPassword, setShowPassword] = useState(false);
  const id = useId();
  const toggleShowPassword = () => setShowPassword(!showPassword);
  const [isFocused, setIsFocused] = useState(false);

  return (
    <Container
      inputSize={inputSize}
      typeInput={typeInput}
      className={className}
      onBlur={() => setIsFocused(false)}
      onFocus={() => setIsFocused(true)}
    >
      {label && (
        <Label
          component={'label'}
          htmlFor={`${id}-${name}`}
          colorText="black"
          size={labelSize}
        >
          {label}
        </Label>
      )}
      <InputContainer
        typeInput={typeInput}
        disabled={props.disabled}
        borderError={borderError}
        isFocused={isFocused}
      >
        <InputValue
          id={`${id}-${name}`}
          name={name}
          typeInput={typeInput}
          ref={ref}
          type={
            type === 'password' ? (showPassword ? 'text' : 'password') : type
          }
          {...props}
        />
        {typeInput === 'password' ? (
          <ContainerIconEye onClick={toggleShowPassword}>
            <IconEye />
          </ContainerIconEye>
        ) : null}
      </InputContainer>
      {error && (
        <ErrorMessage colorText={'lightCoralRed'} size={'XXXS'}>
          {error}
        </ErrorMessage>
      )}
    </Container>
  );
}

export default forwardRef(InputText);

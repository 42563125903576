import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Outlet } from 'react-router-dom';

// Redux
import { indexTabFormReset } from '../../../../state/actions/ui/tabs';
import { tabsType } from '../../../../state/reducers/ui/tabs';

// Types
import { layoutTabReset } from './types';

export default function LayoutResetIndex({
  resetType,
}: {
  resetType: string;
}): JSX.Element {
  const dispatch = useDispatch();

  useEffect(() => {
    switch (resetType) {
      case layoutTabReset.ALL: {
        dispatch(indexTabFormReset(tabsType.COMMUNICATION));
        dispatch(indexTabFormReset(tabsType.DUPLICATE_COMMUNICATION));
        break;
      }
      case layoutTabReset.ONLY_COMMUNICATION: {
        dispatch(indexTabFormReset(tabsType.COMMUNICATION));
        break;
      }
      case layoutTabReset.ONLY_DUPLICATE_COMMUNICATION: {
        dispatch(indexTabFormReset(tabsType.DUPLICATE_COMMUNICATION));
        break;
      }
    }
  }, []);

  return <Outlet />;
}

// Styles
import styled from 'styled-components';
import { colors } from '../../../../../styles/colors';

// Types
import { SwitchSimpleBackgroundProps } from './types';

export const ContainerSimpleBg = styled.label<SwitchSimpleBackgroundProps>`
  background-color: ${(props) =>
    props.activate ? colors.garnetLight : colors.greyLight};
  width: 11.8rem;
  height: 2.9rem;
  border-radius: 4.4rem;
  display: flex;
  justify-content: stretch;
  align-items: center;
  gap: 0.8rem;
  cursor: pointer;
`;

export const SwitchSimpleBg = styled.label`
  display: flex;
  width: 3.4rem;
  height: 1.4rem;
  align-items: center;
  margin-left: 1rem;
  cursor: pointer;
  background-color: ${colors.greyVeryLight};
  border-radius: 3.4rem;
  transition: 0.4s;
  align-items: center;
  width: 3.2rem;
  height: 1.4rem;

  &:before {
    content: '';
    height: 2rem;
    width: 2rem;
    background-color: ${colors.black};
    border-radius: 50%;
    transition: 0.4s;
    margin-left: ${(props) => (props.defaultChecked ? `1.2rem` : 0)};
  }
`;

export const InputSimpleBg = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
`;

import styled from 'styled-components';

// Styles utils
import { colors } from '../../../../styles/colors';
import { fontSize, fontWeight } from '../../../../styles/fonts';
import { breakPoints, percentBaseResponsive } from '../../../../styles/spaces';

// Components
import { DateTime } from '../../../inputs/DateTime';
import InputText from '../../../inputs/InputText';
import {
  InputContainer,
  InputValue,
  Label,
  Container as ContainerInputText,
} from '../../../inputs/InputText/styles';
import { InputTextArea } from '../../../inputs/InputTextArea';
import { Area, Container } from '../../../inputs/InputTextArea/styles';
import { Typography } from '../../Typography';

// Types
import { RightArrowProps } from './types';
import DropDownInput from '../../../inputs/DropDown/DropDownInput';
import { ButtonIconBackground } from '../../../inputs/buttons/ButtonIconBackground';

export const ContainerOT = styled.div`
  max-width: 95%;
  width: 100%;
  background-color: ${colors.verySoftRed};
  border-radius: 8px;
  padding: 4rem;
  @media (max-width: ${breakPoints.landscapeTablet *
    percentBaseResponsive}rem) {
    max-width: 92%;
  }
  @media (max-width: ${breakPoints.portraitTablet * percentBaseResponsive}rem) {
    max-width: 100%;
  }
`;

export const ContainerHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

export const TitleOrder = styled.h2`
  font-size: ${fontSize.L};
  font-weight: ${fontWeight.semiBold};
`;

export const ContainerRefIcon = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5rem;
`;

export const RefCotic = styled(Typography)`
  font-size: ${fontSize.S};
`;

export const RightArrow = styled.img<RightArrowProps>`
  transform: ${({ showInfo }) => (showInfo ? `rotate(90deg)` : null)};
  width: 1.4rem;
  height: 1rem;
`;

export const ContainerContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 3.2rem;

  @media (max-width: ${breakPoints.portraitTablet * percentBaseResponsive}rem) {
    gap: 2.6rem;
  }
`;

export const ContainerData = styled.div`
  display: flex;
  margin-top: 3.2rem;
  gap: 3.2rem;
  @media (max-width: ${breakPoints.landscapeTablet *
    percentBaseResponsive}rem) {
    flex-wrap: wrap;
  }
  @media (max-width: ${breakPoints.portraitTablet * percentBaseResponsive}rem) {
    gap: 2.6rem;
    margin-top: 2.6rem;
  }
`;

export const DateInput = styled(DateTime)`
  label {
    width: 18.1rem;
  }
  input {
    border: ${(prop) => (prop.isDisabled ? `none` : null)};
  }
`;

export const DropDownCapitol = styled(DropDownInput)``;

export const DropDownSubcapitol = styled(DropDownInput)``;

export const DropDownConcession = styled(DropDownInput)``;

export const InputPKInit = styled(InputText)`
  &${ContainerInputText} {
    max-width: 16rem;
  }
  ${InputContainer} {
    max-width: 16rem;
    min-width: 7.5rem;
    padding: 0.7rem 1.4rem;
  }
  &${Label} {
    font-size: ${fontSize.S};
  }

  @media (max-width: ${breakPoints.landscapeTablet *
    percentBaseResponsive}rem) {
    ${InputValue} {
      max-width: 7.1rem;
      font-size: ${fontSize.XS};
    }
    &${Label} {
      font-size: ${fontSize.XS};
    }
  }

  @media (max-width: ${breakPoints.portraitTablet * percentBaseResponsive}rem) {
    ${InputValue} {
      max-width: 20rem;
    }
    &${ContainerInputText} {
      max-width: 20rem;
    }
    ${InputContainer} {
      max-width: 20rem;
    }
  }
`;

export const InputPKEnd = styled(InputText)`
  &${ContainerInputText} {
    max-width: 16rem;
  }
  ${InputContainer} {
    max-width: 16rem;
    min-width: 7.5rem;
    padding: 0.7rem 1.4rem;
  }
  &${Label} {
    font-size: ${fontSize.S};
  }

  @media (max-width: ${breakPoints.landscapeTablet *
    percentBaseResponsive}rem) {
    ${InputValue} {
      width: 7.1rem;
      font-size: ${fontSize.XS};
    }
    &${Label} {
      font-size: ${fontSize.XS};
    }
  }

  @media (max-width: ${breakPoints.portraitTablet * percentBaseResponsive}rem) {
    ${InputValue} {
      max-width: 20rem;
    }
    &${ContainerInputText} {
      max-width: 20rem;
    }
    ${InputContainer} {
      max-width: 20rem;
    }
  }
`;

export const ContainerDescription = styled.div`
  display: flex;
  align-items: flex-end;
  width: 100%;
  gap: 2.9rem;
`;

export const InputDescription = styled(InputTextArea)`
  &${Container} {
    width: 100%;
    min-height: 8.7rem;
  }
  ${Area} {
    background-color: ${({ disabled }) =>
      disabled ? `${colors.greyLight}` : `${colors.white}`};
    border: ${({ disabled }) => (disabled ? `none` : null)};
    color: ${({ disabled }) =>
      disabled ? `${colors.greyVeryDark}` : `${colors.black}`};
    width: 100%;
    min-height: 8.7rem;
    font-size: ${fontSize.S};

    @media (max-width: ${breakPoints.landscapeTablet *
      percentBaseResponsive}rem) {
      min-height: 12.9rem;
      font-size: ${fontSize.XS};
    }
    @media (max-width: ${breakPoints.portraitTablet *
      percentBaseResponsive}rem) {
      height: 8.7rem;
      min-height: 8.7rem;
      font-size: ${fontSize.XS};
    }
  }
`;

export const ButtonSave = styled(ButtonIconBackground)``;
export const ButtonEdit = styled(ButtonIconBackground)``;

import styled from 'styled-components';
import {
  breakPoints,
  percentBaseResponsive,
  spaces,
} from '../../../styles/spaces';
import { fontSize } from '../../../styles/fonts';
import { Typography } from '../../display/Typography';

import { InputTextArea } from '../../inputs/InputTextArea';

export const ContainerForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: ${spaces.xl}rem;
  @media (max-width: ${breakPoints.landscapeTablet *
    percentBaseResponsive}rem) {
    gap: ${spaces.xxl}rem;
  }
`;

export const FieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spaces.xxxl}rem;
`;

export const ContainerBasicInfo = styled.div``;

export const ContainerDescription = styled.div`
  width: 100%;
  max-width: 64%;
  @media (max-width: ${breakPoints.smallDesktop * percentBaseResponsive}rem) {
    max-width: 100%;
  }
`;

export const InputsContainer = styled.div`
  display: flex;
  gap: 3.3rem;
  flex-wrap: wrap;
`;

export const InputsContainerRow = styled.div``;

export const InputsContainerDescription = styled(InputsContainer)`
  @media (max-width: ${breakPoints.smallDesktop * percentBaseResponsive}rem) {
    max-width: 105.1rem;
  }
  @media (max-width: ${breakPoints.landscapeTablet *
    percentBaseResponsive}rem) {
    max-width: 69rem;
  }
`;

export const SubContainerDescription = styled.div`
  width: 100%;
  max-width: 76.5rem;
  @media (max-width: ${breakPoints.smallDesktop * percentBaseResponsive}rem) {
    max-width: none;
  }
  @media (max-width: ${breakPoints.portraitTablet * percentBaseResponsive}rem) {
    max-width: 48rem;
  }
`;

export const Title = styled(Typography)`
  margin-bottom: ${spaces.l}rem;
  @media (max-width: ${breakPoints.landscapeTablet *
    percentBaseResponsive}rem) {
    font-size: ${fontSize.M};
  }
`;

export const LocationInputGroup = styled.div`
  display: flex;
  gap: 1rem;
`;

export const PkInputContainer = styled.div`
  display: flex;
  gap: ${spaces.l}rem;
`;

export const InputTextAreaStyled = styled(InputTextArea)`
  flex-basis: 100%;
  margin-top: ${spaces.l}rem;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  gap: ${spaces.s}rem;
  justify-content: flex-end;
`;

import React from 'react';

type DirectionUpProps = {
  className?: string;
};

export const DirectionDown = ({ className }: DirectionUpProps): JSX.Element => {
  return (
    <svg
      width="17"
      height="22"
      viewBox="0 0 17 22"
      fill="black"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M7.49428 0.75H7.24428V1L7.24428 16.9244L2.93527 12.6154C2.44049 12.1206 1.63831 12.1206 1.14353 12.6154C0.648758 13.1101 0.648757 13.9123 1.14353 14.4071L7.61536 20.8789C8.11013 21.3737 8.91232 21.3737 9.40709 20.8789L15.8789 14.4071C16.3737 13.9123 16.3737 13.1101 15.8789 12.6154C15.3841 12.1206 14.582 12.1206 14.0872 12.6154L9.77817 16.9244V1V0.75H9.52817L7.49428 0.75Z" />
    </svg>
  );
};

import React from 'react';

type PencilIconProps = {
  className?: string;
};

export const Pencil = ({ className }: PencilIconProps): JSX.Element => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M1.30205 13.6981H2.19181L11.1501 4.73988L10.2603 3.85012L1.30205 12.8084V13.6981ZM13.7182 3.87034L11.1298 1.28195L11.9792 0.432638C12.2083 0.203457 12.4914 0.0888672 12.8285 0.0888672C13.1655 0.0888672 13.4486 0.203457 13.6778 0.432638L14.5675 1.3224C14.7967 1.55158 14.9113 1.83468 14.9113 2.17171C14.9113 2.50874 14.7967 2.79185 14.5675 3.02103L13.7182 3.87034ZM12.8689 4.71966L2.67713 14.9114H0.0887451V12.323L10.2805 2.13127L12.8689 4.71966ZM10.7052 4.295L10.2603 3.85012L11.1501 4.73988L10.7052 4.295Z"
        fill="color"
      />
    </svg>
  );
};

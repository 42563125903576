export const ACTION_TABS_PATH = 'ACTION_TABS_PATH';
export const ACTION_TABS_PATH_RESET = 'ACTION_TABS_PATH_RESET';

export function actionTabs(path: string) {
  return { type: ACTION_TABS_PATH, payload: { path } };
}

export function actionTabsReset() {
  return { type: ACTION_TABS_PATH_RESET };
}

import React from 'react';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';
import { PersistGate } from 'redux-persist/integration/react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Global Styles
import { GlobalStyle, ReuseComponent } from './styles/global-styles';

// Context
import { AuthProvider } from './hooks/useAuth';

// Apollo
import { AppApolloProvider } from './gql/client';

// Routes
import Router from './router';

// Store
import { store, persistor } from './state/configureStore';

// Components
import { toastContainerConfig } from './components/toasts/MainToast/utils';
import SuperModal from './components/modals/SuperModal';

// Pages
import ErrorPage from './pages/utils/Error';

export default function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <AuthProvider>
          <AppApolloProvider>
            <Sentry.ErrorBoundary fallback={<ErrorPage />}>
              <GlobalStyle />
              <Router />
              <ReuseComponent>
                <ToastContainer {...toastContainerConfig} />
                <SuperModal />
              </ReuseComponent>
            </Sentry.ErrorBoundary>
          </AppApolloProvider>
        </AuthProvider>
      </PersistGate>
    </Provider>
  );
}

import { UseFormGetValues, UseFormSetValue } from 'react-hook-form';

import { OperatorsAndVehiclesFormValues } from '../../../../forms/ActionForms/OperatorsAndVehicles/types';

export type ExternalCompaniesSelectionType = {
  company?: string;
  work?: string;
  operatorsNumber?: number | string;
  remove?: string;
  id?: string;
  workId?: string;
};

export type ExternalProps = {
  setValue: UseFormSetValue<OperatorsAndVehiclesFormValues>;
  getValues: UseFormGetValues<OperatorsAndVehiclesFormValues>;
  externalCompanies: {
    id: string;
    companyName: string;
  }[];
  defaultValues: ExternalCompaniesSelectionType[];
  setDefaultExternals: (data: ExternalCompaniesSelectionType[]) => void;
};

export const columnNames = {
  company: {
    id: 'company',
    label: 'Empresa',
  },
  work: {
    id: 'work',
    label: 'Treball',
  },
  operatorsNumber: {
    id: 'operatorsNumber',
    label: 'Nº OP',
  },
  remove: {
    id: 'remove',
    label: '',
  },
};

export type cellExternalCompaniesTypes =
  | 'company'
  | 'operatorsNumber'
  | 'remove';
export type CellProps = {
  cell: cellExternalCompaniesTypes;
};

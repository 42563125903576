export type dataTable = {
  id?: string;
  name: string;
  surName: string;
  email: string;
  rolActive: string;
  concession: string;
  isActive: boolean;
};

export type TableProps = {
  data?: {
    activeUsers: dataTable[];
    inactiveUsers: dataTable[];
  };
  defaultActiveUsers?: dataTable[];
  defaultInactiveUsers?: dataTable[];
  onAddActiveUser: (userEmail: string) => void;
  onAddInactiveUser: (userEmail: string) => void;
};

export type SearchbarFilterTypes = (value: string) => void;

export const activeUsersColumnNames = {
  name: {
    id: 'firstName',
    label: 'Nom',
  },
  surName: {
    id: 'surName',
    label: 'Cognoms',
  },
  email: {
    id: 'email',
    label: 'Correu electrònic',
  },
  rolActive: {
    id: 'rolActive',
    label: 'Rol',
  },
  concession: {
    id: 'concession',
    label: 'Concessió',
  },
  state: {
    id: 'State',
    label: 'Estat',
  },
};

export const inactiveUsersColumnNames = {
  name: {
    id: 'firstName',
    label: 'Nom',
  },
  surName: {
    id: 'surName',
    label: 'Cognoms',
  },
  email: {
    id: 'email',
    label: 'Correu electrònic',
  },
  rolActive: {
    id: 'rolActive',
    label: 'Rol',
  },
  concession: {
    id: 'concession',
    label: 'Concessió',
  },
  state: {
    id: 'State',
    label: 'Estat',
  },
};

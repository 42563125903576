import React, { useState, useCallback, useMemo, useEffect } from 'react';

import {
  useReactTable,
  createColumnHelper,
  getCoreRowModel,
  flexRender,
  Row,
  getFilteredRowModel,
} from '@tanstack/react-table';

// Styled Components
import {
  Container,
  SubContainer,
  SubContainerRight,
  ContainerTables,
  ContainerSearchBar,
  Table,
  Thead,
  Th,
  ThRightTable,
  Tr,
  TrBody,
  Td,
  TableRight,
  Tbody,
  TbodyRight,
  Img,
  Input,
  TdRightTable,
  StyledInput,
} from './styles';

// Components
import { Typography } from '../../../Typography';
import SearchBar from '../../../../inputs/SearchBar';

//Icons
import ArrowIcon from '../../../../../assets/icons/grey-right-arrow-icon.svg';
import RemoveIcon from '../../../../../assets/icons/remove-file-icon.svg';

// Types
import {
  dataTable,
  tableProps,
  columnNames,
  onChangeInputTypes,
  variantThRight,
  variantThLeft,
} from './types';

// Mocks
import { mockData } from './mocks';
import { OrderState } from '../../../../../types/orders';

export const MaterialSelectionTable = ({
  data,
  defaultValues = [],
  register,
  setValue,
  onAdd,
  onDelete,
  onUpdate,
  ...props
}: tableProps): JSX.Element => {
  const [selectedRows, setSelectedRows] = useState<Row<dataTable>[]>([]);
  const [dataTableRight, setDataTableRight] =
    useState<dataTable[]>(defaultValues);
  const dataTable = data ? data : mockData;

  const columnHelper = createColumnHelper<dataTable>();
  const handleClickRow = useCallback(
    (row: Row<dataTable>) => {
      const newData: dataTable = {
        codification: row.original.codification,
        name: row.original.name,
        typeOfMeasurement: row.original.typeOfMeasurement,
        id: row.original.id,
        materialId: row.original.materialId,
      };

      const id = row.original.id;

      const setValues = () => {
        setValue(
          `materialsTable[${id}].codification`,
          row.original.codification
        );
        setValue(`materialsTable[${id}].name`, row.original.name);
        setValue(
          `materialsTable[${id}].typeOfMeasurement`,
          row.original.typeOfMeasurement
        );

        setValue(`materialsTable[${id}].ut`, '');
        setValue(`materialsTable[${id}].id`, row.original.id);
        setValue(`materialsTable[${id}].materialId`, row.original.materialId);
      };

      const duplicate =
        selectedRows.some(
          (element) => element.original.id === row.original.id
        ) || dataTableRight.some((element) => element.id === row.original.id);
      !duplicate && setValues();
      !duplicate && setDataTableRight([...dataTableRight, newData]);
      !duplicate && setSelectedRows([...selectedRows, row]);
      onAdd && !duplicate && onAdd(row.original.id as string);
    },
    [dataTableRight, selectedRows]
  );

  const removeRow = useCallback(
    (row: Row<dataTable>) => {
      setDataTableRight((element) =>
        element.filter((item) => item.id !== row.original.id)
      );

      setSelectedRows((element) =>
        element.filter((item) => item.original.id !== row.original.id)
      );

      setValue(`materialsTable[${row.original.id}]`, undefined);
      onDelete &&
        onDelete(
          row.original.id as string,
          row.original.materialUnitId as string
        );
    },
    [dataTableRight]
  );

  const columnsLeft = useMemo(
    () => [
      columnHelper.accessor(columnNames.materialId.id, {
        header: () => (
          <Typography size="S" colorText="white">
            {columnNames.materialId.label}
          </Typography>
        ),
        cell: (info) => (
          <Typography size="S">{info.getValue() as string}</Typography>
        ),
      }),
      columnHelper.accessor(columnNames.name.id, {
        header: () => (
          <Typography colorText="white" size="S">
            {columnNames.name.label}
          </Typography>
        ),
        cell: (info) => (
          <StyledInput value={info.getValue() as string} readOnly />
        ),
        filterFn: 'includesString',
      }),
      columnHelper.accessor(columnNames.codification.id, {
        header: () => (
          <Typography colorText="white" size="S">
            {columnNames.codification.label}
          </Typography>
        ),
        cell: (info) => (
          <Typography size="S">{info.getValue() as string}</Typography>
        ),
      }),
      columnHelper.accessor(columnNames.subCodification.id, {
        header: () => (
          <Typography colorText="white" size="S">
            {columnNames.subCodification.label}
          </Typography>
        ),
        cell: (info) => (
          <Typography size="S">{info.getValue() as string}</Typography>
        ),
      }),
      columnHelper.accessor('add', {
        header: () => (
          <Typography colorText="white" size="S">
            +
          </Typography>
        ),
        cell: ({ row }) => (
          <Img
            src={ArrowIcon}
            onClick={() => {
              return (
                data?.parentExpeditionOrderStatus !== OrderState.Annulled &&
                data?.parentExpeditionOrderStatus !== OrderState.End &&
                data?.state !== OrderState.Annulled &&
                handleClickRow(row)
              );
            }}
          />
        ),
      }),
    ],
    [selectedRows]
  );

  const columnsRight = useMemo(
    () => [
      columnHelper.accessor(columnNames.materialId.id, {
        header: () => (
          <Typography size="S" colorText="white">
            {columnNames.materialId.label}
          </Typography>
        ),
        cell: (info) => (
          <Input
            {...register(`materialsTable[${info.row.original.id}].materialId`)}
            disabled={true}
            type="string"
          ></Input>
        ),
      }),
      columnHelper.accessor(columnNames.name.id, {
        header: () => (
          <Typography colorText="white" size="S">
            {columnNames.name.label}
          </Typography>
        ),
        cell: (info) => (
          <Input
            {...register(`materialsTable[${info.row.original.id}].name`)}
            disabled={true}
            type="string"
          ></Input>
        ),
      }),
      columnHelper.accessor(columnNames.ut.id, {
        header: () => (
          <Typography size="S" colorText="white">
            {columnNames.ut.label}
          </Typography>
        ),
        cell: (info) => (
          <Input
            {...register(`materialsTable[${info.row.original.id}].ut`)}
            disabled={
              data?.parentExpeditionOrderStatus === OrderState.Annulled ||
              data?.parentExpeditionOrderStatus === OrderState.End ||
              data?.state === OrderState.Annulled
            }
            type="number"
            step={'any'}
            onChange={(event) =>
              onUpdate &&
              onUpdate(
                event.target.value,
                info.row.original.id as string,
                info.row.original.materialUnitId as string,
                `materialsTable[${info.row.original.id}].ut` as string
              )
            }
            onKeyDown={(e) => {
              /[+*/,\\-]/.test(e.key) && e.preventDefault();
            }}
          />
        ),
      }),
      columnHelper.accessor(columnNames.typeOfMeasurement.id, {
        header: () => (
          <Typography colorText="white" size="S">
            {columnNames.typeOfMeasurement.label}
          </Typography>
        ),
        cell: (info) => (
          <Input
            {...register(
              `materialsTable[${info.row.original.id}].typeOfMeasurement`
            )}
            disabled={true}
            type="string"
          ></Input>
        ),
      }),
      columnHelper.accessor('remove', {
        header: () => (
          <Typography colorText="white" size="S">
            -
          </Typography>
        ),
        cell: ({ row }) => (
          <Img
            src={RemoveIcon}
            onClick={() => {
              return (
                data?.parentExpeditionOrderStatus !== OrderState.Annulled &&
                data?.parentExpeditionOrderStatus !== OrderState.End &&
                data?.state !== OrderState.Annulled &&
                removeRow(row)
              );
            }}
          />
        ),
      }),
    ],
    [dataTableRight]
  );

  const table = useReactTable({
    data: dataTable,
    columns: columnsLeft,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });

  const tableRight = useReactTable({
    data: dataTableRight,
    columns: columnsRight,
    getCoreRowModel: getCoreRowModel(),
  });

  useEffect(() => {
    const allLeftRows = table.getCoreRowModel().rows;
    const similarTables = allLeftRows.filter((row) => {
      const code = row.original.codification;
      const matchingItem = dataTableRight.find((item) => item.id === code);
      if (matchingItem?.materialUnitId) {
        row.original.materialUnitId = matchingItem.materialUnitId;
      }
      return !!matchingItem;
    });

    setSelectedRows(similarTables);
  }, []);

  const onChangeInput = useCallback<onChangeInputTypes>((value) => {
    const material = table.getColumn(columnNames.name.id);
    material?.setFilterValue(value);
  }, []);

  return (
    <Container {...props}>
      <ContainerSearchBar>
        <SearchBar
          variant="form"
          onChangeInput={(e) => onChangeInput(e.target.value)}
        />
      </ContainerSearchBar>
      <ContainerTables>
        <SubContainer>
          <Table>
            <Thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <Tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <Th variant={header.id as variantThLeft} key={header.id}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </Th>
                  ))}
                </Tr>
              ))}
            </Thead>
            <Tbody>
              {table.getRowModel().rows.map((row) => (
                <TrBody key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <Td
                      variant={cell.id.slice(2) as variantThLeft}
                      key={cell.id}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </Td>
                  ))}
                </TrBody>
              ))}
            </Tbody>
          </Table>
        </SubContainer>
        <SubContainerRight>
          <TableRight>
            <Thead>
              {tableRight.getHeaderGroups().map((headerGroup) => (
                <Tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <ThRightTable
                      variant={header.id as variantThRight}
                      key={header.id}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </ThRightTable>
                  ))}
                </Tr>
              ))}
            </Thead>
            <TbodyRight>
              {tableRight.getRowModel().rows.map((row) => (
                <TrBody key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <TdRightTable
                      variant={cell.id.slice(2) as variantThRight}
                      key={cell.id}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TdRightTable>
                  ))}
                </TrBody>
              ))}
            </TbodyRight>
          </TableRight>
        </SubContainerRight>
      </ContainerTables>
    </Container>
  );
};

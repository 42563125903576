import React, {
  useCallback,
  useState,
  useEffect,
  useRef,
  useMemo,
} from 'react';
import { useClickAway } from 'react-use';

// Styled Components
import {
  Container,
  List,
  ListItem,
  ListContainer,
  Text,
  StyledCheckBox,
} from './styles';

// Components
import { Button } from './styles';

// Types
import { VisibilityProps } from './types';

// Media
import {
  useIsLandscapeTablet,
  useIsPortraitTablet,
} from '../../../../../hooks/useMediaBreakPoints';

export const DropDownVisibilityColumn = ({
  columns,
  columnNames,
  isDisabled,
  ...props
}: VisibilityProps): JSX.Element | null => {
  const [listDisplayed, setListDisplayed] = useState(false);
  const [visibleColumns, setVisibleColumns] = useState(0);

  const handleDisplayList = useCallback(() => {
    setListDisplayed(!listDisplayed);
  }, [listDisplayed]);

  const isLandscapeTablet = useIsLandscapeTablet();
  const isPortraitTablet = useIsPortraitTablet();

  useEffect(() => {
    const countVisibleColumns = () => {
      let count = 0;
      columns.forEach((column) => {
        if (column.getIsVisible()) {
          count++;
        }
      });
      setVisibleColumns(count);
    };
    countVisibleColumns();
  }, [columns]);

  const containerRef = useRef(null);

  useClickAway(containerRef, () => setListDisplayed(false));

  const checkIfShowComponent = useMemo(
    () => (
      <Container ref={containerRef} {...props}>
        <Button
          variant="visibility"
          onClick={handleDisplayList}
          displayed={listDisplayed}
          disabled={isDisabled}
        />
        <ListContainer>
          <List>
            {listDisplayed &&
              columns.map((column, index) => {
                return (
                  <ListItem
                    key={index}
                    onClick={column.getToggleVisibilityHandler()}
                  >
                    <StyledCheckBox
                      indeterminate={false}
                      checked={column.getIsVisible()}
                      onChange={column.getToggleVisibilityHandler()}
                    />
                    <Text
                      size={isLandscapeTablet ? 'XS' : 'S'}
                      variant="regular"
                      colorText={'greyVeryDark'}
                    >
                      {columnNames[index]}
                    </Text>
                  </ListItem>
                );
              })}
          </List>
        </ListContainer>
      </Container>
    ),
    [
      isLandscapeTablet,
      isPortraitTablet,
      containerRef,
      props,
      handleDisplayList,
      columns,
      columnNames,
      listDisplayed,
      visibleColumns,
    ]
  );

  return checkIfShowComponent;
};

import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';

// Utils
import {
  breakPoints,
  percentBaseResponsive,
  spaces,
} from '../../../../../styles/spaces';
import { colors } from '../../../../../styles/colors';
import { fontSize, fontWeight } from '../../../../../styles/fonts';

// Types
import { ButtonTabsPropTypes } from './types';

export const GeneralContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${spaces.xl}rem;
`;

export const TabsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: ${breakPoints.landscapeTablet *
    percentBaseResponsive}rem) {
    flex-direction: column;
    gap: ${spaces.s}rem;
  }
`;

export const TabsHalf = styled.div`
  display: flex;
  width: 100%;
  max-width: 50rem;
  justify-content: space-between;
  :first-of-type {
    border: 0.1rem solid ${colors.greyDark};
    border-right: none;
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
    & > a:first-child {
      flex: 1.4;
    }
  }
  :last-of-type {
    border: 0.1rem solid ${colors.greyDark};
    border-left: none;
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
    & > a:nth-child(2) {
      flex: 1.4;
    }
  }
  @media (max-width: ${breakPoints.landscapeTablet *
    percentBaseResponsive}rem) {
    border-radius: 1rem;
    :first-of-type {
      border-right: 0.1rem solid ${colors.greyDark};
    }

    :last-of-type {
      border-left: 0.1rem solid ${colors.greyDark};
    }
  }
`;

export const ButtonTab = styled(NavLink)<ButtonTabsPropTypes>`
  font-size: ${fontSize.S};
  font-weight: ${fontWeight.semiBold};
  text-decoration: none;
  color: ${colors.greyDark};
  padding: 0.7rem 0;
  flex: 1;
  text-align: center;
  ${({ disabled }) =>
    !disabled &&
    css`
      color: ${colors.black};
      &.active {
        outline: 0.2rem solid ${colors.garnet};
        outline-offset: -0.1rem;
        border-radius: 1rem;
      }
    `};

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: default;
    `};
`;

import React, { SyntheticEvent, useCallback, useLayoutEffect } from 'react';
import {
  useNavigate,
  useActionData,
  useAsyncValue,
  useSubmit,
} from 'react-router-dom';
import { format } from 'date-fns';

// Components Styled
import { Container } from './styles';

//Components
import ContentCardResume from '../../../../../components/display/ContentCardResume';

// Types
import { type WorkOrder } from '../../../../../types/workOrder';
import { createFormDataFromValues } from '../../../../../utils/forms';
import { variant } from '../../../../../components/display/SpecialTag/types';

export default function WorkOrderSummary(): JSX.Element {
  const loaderData = useAsyncValue() as WorkOrder;
  const actionData = useActionData() as WorkOrder;
  const navigate = useNavigate();
  const submit = useSubmit();

  const workOrderData = actionData ?? loaderData;
  useLayoutEffect(() => {
    if (!workOrderData) navigate('/work-orders/new-order');
  }, [workOrderData]);

  const handleEditWorkOrder = useCallback(() => {
    navigate('/work-orders/new-order');
  }, []);

  const handleSaveWorkOrder = async (e: SyntheticEvent) => {
    e.preventDefault();
    const formData = createFormDataFromValues({
      intent: 'save-data',
    });
    submit(formData, {
      method: 'POST',
      action: '.',
    });
  };

  return (
    <Container>
      {workOrderData && (
        <ContentCardResume
          title="Resum de la Ordre de Treball"
          cotic={[]}
          operator={workOrderData.operator}
          data={format(
            new Date(workOrderData.registerInitDate),
            'dd / MM / yyyy'
          )}
          hour={format(new Date(workOrderData.registerInitDate), 'HH:mm')}
          capitol={workOrderData.capitol}
          subCapitol={workOrderData.subCapitol}
          classification={workOrderData.classification}
          team={workOrderData.team}
          concession={workOrderData.concession}
          road={workOrderData.road}
          pkInit={`${workOrderData.pkInit} km`}
          pkEnd={`${workOrderData.pkEnd} km`}
          track={workOrderData.track}
          roadDirections={workOrderData.roadDirections}
          direction={workOrderData.direction}
          city={workOrderData.city}
          lane={workOrderData.lane}
          margin={workOrderData.margin}
          leftButton={'Editar O.T'}
          rightButton={'Desar O.T'}
          description={workOrderData.description}
          onEditCommunication={handleEditWorkOrder}
          onSaveCommunication={handleSaveWorkOrder}
          tag={workOrderData.campaign === null ? undefined : variant.Campaign}
          tagContent={workOrderData.campaign}
        />
      )}
    </Container>
  );
}

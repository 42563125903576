// Dependencies
import React, { useCallback, useRef, useState } from 'react';
import { useClickAway } from 'react-use';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

// Styles
import {
  ArrowButton,
  ButtonBurguerMenu,
  CloseMenu,
  Container,
  ContainerHeader,
  ContainerLineSecondary,
  ContainerListElement,
  ContainerSecondary,
  ContainerTitle,
  HorizontalLine,
  LinkOption,
  List,
  ListOption,
  ListSecondary,
  SubItems,
} from './styles';

// Components
import { Typography } from '../../display/Typography';

//Icons
import ArrowLeft from '../../../assets/icons/arrow-left.svg';
import { CloseIcon } from '../../../assets/icons/CloseIcon';
import BurguerButton from '../../../assets/icons/hamburguer-icon.svg';
import ArrowRight from '../../../assets/icons/arrow-right.svg';

// Routes
import { options as OptionsRoutes } from './definitions';
import { coticOTSelectedReset } from '../../../state/actions/ui/coticOTSelectedGeneralView';
import { rolesAuthRoutesCheck } from '../../../router/rolesAuthRouteCheck';
import { optionsTypes } from './types';

export const HamburguerMenu = (): JSX.Element => {
  const [principalMenu, setPrincipalMenu] = useState(true);
  const [isDisplay, setIsDisplay] = useState(false);
  const [isOpenMenu, setIsOpenMenu] = useState(isDisplay);
  const [defaultOption, setDefaultOption] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Close menu when click outside
  const menuRef = useRef(null);
  useClickAway(menuRef, () => {
    setIsOpenMenu(false);
    setTimeout(() => setIsDisplay(false), 500);
  });

  // SetState to PrincipalMenu
  const toPrincipalMenu = useCallback(() => setPrincipalMenu(true), []);

  // onClick event to ListOption
  const clickOption = useCallback((value: string) => {
    setDefaultOption(value);
  }, []);

  const displayMenu = useCallback(() => {
    setPrincipalMenu(true);
    isDisplay ? setIsOpenMenu(false) : setIsOpenMenu(true);
    isDisplay ? setTimeout(() => setIsDisplay(false), 500) : setIsDisplay(true);
  }, [isDisplay]);

  const closeMenu = useCallback(() => {
    setIsOpenMenu(false);
    dispatch(coticOTSelectedReset());
    setTimeout(() => setIsDisplay(false), 500);
  }, [isDisplay]);

  const optionsAllowed = rolesAuthRoutesCheck(
    OptionsRoutes as optionsTypes[]
  ) as optionsTypes[];
  const data = optionsAllowed.find((option) => option.label === defaultOption);
  const titleElement = data?.label;
  const dataElements = data?.data?.map((element) => element);
  return (
    <>
      <ContainerTitle onClick={displayMenu}>
        <ButtonBurguerMenu src={BurguerButton} alt="Icono Menu" />
      </ContainerTitle>

      {isDisplay ? (
        <Container isOpen={isOpenMenu} ref={menuRef}>
          {principalMenu ? (
            <>
              <CloseMenu onClick={closeMenu}>
                <CloseIcon />
              </CloseMenu>
              <List>
                {optionsAllowed.map((option) => (
                  <ListOption
                    key={option.value}
                    onClick={() => {
                      if (option.value === 'dashboard') {
                        navigate(option.value);
                        closeMenu();
                      } else {
                        clickOption(option.label);
                      }
                    }}
                  >
                    <ContainerListElement
                      onClick={() => {
                        if (option.value === 'dashboard') {
                          setPrincipalMenu(true);
                        } else {
                          setPrincipalMenu(false);
                        }
                      }}
                    >
                      <Typography size={'M'} variant="semiBold">
                        {option.label}
                      </Typography>
                      <img src={ArrowRight} alt="botó anar a submenu" />
                    </ContainerListElement>
                    <HorizontalLine />
                  </ListOption>
                ))}
              </List>
            </>
          ) : (
            <>
              <CloseMenu onClick={closeMenu}>
                <CloseIcon />
              </CloseMenu>
              <ContainerHeader onClick={toPrincipalMenu}>
                <ArrowButton>
                  <img src={ArrowLeft} alt={'botó de tornada'} />
                </ArrowButton>
                <Typography size="M" variant="semiBold">
                  {titleElement}
                </Typography>
              </ContainerHeader>
              <ContainerLineSecondary>
                <HorizontalLine />
              </ContainerLineSecondary>
              <ContainerSecondary>
                <ListSecondary>
                  {dataElements?.map((elements) => (
                    <div key={elements.path}>
                      <SubItems>
                        <LinkOption
                          to={`/${data?.value}/${elements.path}`}
                          onClick={closeMenu}
                          end
                        >
                          <Typography size={'S'} variant="medium">
                            {elements.title}
                          </Typography>
                        </LinkOption>
                      </SubItems>
                      {elements.subItems?.map((subelement) => {
                        return (
                          <div key={subelement.path}>
                            <SubItems>
                              <LinkOption
                                to={`/${data?.value}/${subelement.path}`}
                                onClick={closeMenu}
                              >
                                <Typography size={'S'} variant="medium">
                                  {subelement.text}
                                </Typography>
                              </LinkOption>
                            </SubItems>
                          </div>
                        );
                      })}
                    </div>
                  ))}
                </ListSecondary>
              </ContainerSecondary>
            </>
          )}
        </Container>
      ) : null}
    </>
  );
};

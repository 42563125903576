import React from 'react';
import { useRouteError } from 'react-router-dom';

// Components Styled
import { Container, H1, ContainerText } from './style';

// Components
import { Typography } from '../../../components/display/Typography';

export default function ErrorPage(): JSX.Element {
  const error = useRouteError();
  console.error(error);

  return (
    <Container>
      <H1
        component={'h1'}
        size={'XL'}
        variant={'semiBold'}
        colorText={'greyVeryDark'}
      >
        ¡Ups!
      </H1>
      <ContainerText>
        <Typography size={'L'}>
          ho sento, ha passat un error inesperat.
        </Typography>
        <Typography colorText={'greyDark'}>
          <i>{error.statusText || error.message}</i>
        </Typography>
      </ContainerText>
    </Container>
  );
}

import { gql } from '@apollo/client';

export const CREATE_NEW_COMMUNICATION = gql`
  mutation CreateCommunication($input: NewCommunication!) {
    createCommunication(input: $input) {
      id
      sendStatus
      interlocutorGroupIDs
      mediaOfCommunicationIDs
      concessionIDs
      roadIDs
      track
      direction
      margin
      lane
      pkInitKm
      pkInitMeter
      pkEndKm
      pkEndMeter
      orderIDs
    }
  }
`;

export const UPDATE_COMMUNICATION = gql`
  mutation UpdateCommunication($input: UpdateCommunication!) {
    updateCommunication(input: $input) {
      id
      sendStatus
      interlocutorGroupIDs
      mediaOfCommunicationIDs
      concessionIDs
      roadIDs
      track
      direction
      margin
      lane
      pkInitKm
      pkInitMeter
      pkEndKm
      pkEndMeter
      orderIDs
    }
  }
`;

import styled, { css } from 'styled-components';

// Styles
import { colors } from '../../../../styles/colors';
import {
  breakPoints,
  percentBaseResponsive,
  spaces,
} from '../../../../styles/spaces';

// Components
import {
  Container,
  FiltersContainer,
  SubContainerFilters,
  ContainerButtons,
  TableContainer,
  Th,
  Td,
  Tr,
} from '../components/MainStyledComponents';
import DropDownInput from '../../../inputs/DropDown/DropDownInput';
import {
  Container as DropDownContainerInput,
  DropDownContainer,
} from '../../../inputs/DropDown/DropDownInput/styles';
import { ButtonIconBackground } from '../../../inputs/buttons/ButtonIconBackground';
import { DropDownVisibilityColumn } from '../components/DropDownVisibilityColumn';
import { Button } from '../components/DropDownVisibilityColumn/styles';

// Icons
import { ArrowBottom } from '../../../../assets/icons/ArrowBottom';

// Types
import {
  ArrowBottomStyledProps,
  ContainersLoadingProps,
  ShowFiltersButtonProps,
} from './types';
import { columnNames } from '../components/types';

export const ContainerStyled = styled(Container)`
  width: 100%;
  position: relative;
`;

export const FiltersContainerStyled = styled(
  FiltersContainer
)<ContainersLoadingProps>`
  width: 100%;
  padding: ${spaces.xl}rem;
  opacity: ${(props) => (props.isLoading ? 0.6 : 1)};
  @media (max-width: ${breakPoints.landscapeTablet *
    percentBaseResponsive}rem) {
    padding: ${spaces.m}rem;
  }
`;

export const ContainerLoader = styled.div`
  position: absolute;
  top: 30%;
  left: 50%;
  z-index: 1;
`;

export const SubContainerHead = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ShowFiltersButton = styled.button<ShowFiltersButtonProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  height: 2.3rem;
  border-radius: 0.3rem;
  transition: background 300ms;
  background: ${(props) => (props.isBlack ? colors.black : colors.garnet)};
`;

export const ArrowBottomStyled = styled(ArrowBottom)<ArrowBottomStyledProps>`
  transition: transform 300ms;
  transform: ${(props) => (props.isRotate ? null : 'rotate(-90deg)')};
  width: 1rem;
  height: 1rem;
  path {
    fill: white;
  }
`;

export const SubContainerFiltersStyled = styled(SubContainerFilters)``;

export const DropDownInputCapSub = styled(DropDownInput)`
  @media (max-width: ${breakPoints.landscapeTablet *
    percentBaseResponsive}rem) {
    &${DropDownContainerInput} {
      max-width: 21rem;
    }
    ${DropDownContainer} {
      max-width: 21rem;
    }
  }
`;

export const ContainerButtonsStyled = styled(ContainerButtons)``;

export const ButtonIconBackgroundStyled = styled(ButtonIconBackground)`
  width: 3.4rem;
  height: 3.4rem;
`;

export const DropDownVisibilityColumnStyled = styled(DropDownVisibilityColumn)`
  ${Button} {
    width: 3.4rem;
    height: 3.4rem;
  }
`;

export const TableContainerStyled = styled(
  TableContainer
)<ContainersLoadingProps>`
  padding: ${spaces.m}rem 0;
  overflow-x: auto;
  opacity: ${(props) => (props.isLoading ? 0.6 : 1)};
`;

export const ThStyled = styled(Th)``;

export const TdStyled = styled(Td)`
  @media (max-width: ${breakPoints.smallDesktop * percentBaseResponsive}rem) {
    text-overflow: ellipsis;

    ${(props) =>
      props.column === columnNames.capitol.id && {
        maxWidth: '11.3rem',
      }}
    ${(props) =>
      props.column === columnNames.subCapitol.id && {
        maxWidth: '9.5rem',
      }}
    ${(props) =>
      props.column === columnNames.cotic.id && {
        maxWidth: '10.5rem',
      }}
  }
`;

export const TrStyled = styled(Tr)`
  background-color: ${({ checked }) => checked && colors.lightRed};
  ${({ indeterminate, checked }) =>
    css`
      Td {
        ${!indeterminate && {
          opacity: 0.2,
        }}
        ${checked && {
          opacity: 1,
        }}
      }
    `}
`;

export const tableIds = {
  DUPLICATE_COMMUNICATION: 'duplicate-communication',
  WORKORDER_COMMUNICATIONS: 'workorders-communications',
  SEARCH_AND_CREATE_HISTORICAL_COMMUNICATIONS:
    'search-and-create-historical-communications',
  WORKORDER_GENERAL_VIEW: 'workorders-general-view',
  DUPLICATE_GENERIC_ORDER: 'duplicate-generic-order',
  SEARCH_AND_CREATE_HISTORICAL_WORKORDERS:
    'search-and-create-historical-workorders',
  ACCIDENTS: 'accidents',
  CAMPAIGNS: 'campaigns',
  MULTIMEDIA: 'multimedia',
  GENERIC_ORDER_MODAL: 'generic-order-modal',
  CAMPAIGN_MODAL: 'campaign-modal',
  VALIDATION_ACTIONS: 'validation-actions',
};

export const takeBase = 13;

export const takeModal = 8;

export const takeModalTablet = 5;

export type DataCursorArrowTables =
  | {
      take: number;
      cursor: string;
      orderBy: string;
      roadId?: string | undefined;
      concessionId?: string | undefined;
      interlocutorGroupId?: string | undefined;
      fromDate?: string | undefined;
      toDate?: string | undefined;
      indexType?: number | undefined;
      indexExpedition?: number | undefined;
      classification?: string | undefined;
      state?: string | undefined;
      capitolId?: string | undefined;
      subCapitolId?: string | undefined;
      mediumKnowledgeId?: string | undefined;
      weatherId?: string | undefined;
      name?: string | undefined;
      type?: string | undefined;
    }
  | undefined;

export type pageInfoType = {
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  lastCursor: string;
};

import { Role } from '../types/auth';

export const options = [
  {
    label: 'Dashboard',
    value: 'dashboard',
    permissions: [Role.Ceconsa, Role.Tablet, Role.SuperAdmin, Role.RoomTeam],
  },
  {
    label: 'Comunicacions',
    value: 'communication',
    permissions: [Role.Ceconsa, Role.SuperAdmin, Role.RoomTeam],
  },
  {
    label: 'Ordres de Treball',
    value: 'work-orders',
    permissions: [Role.Ceconsa, Role.SuperAdmin, Role.Tablet, Role.RoomTeam],
  },
  // {
  //   label: 'Recorregut de Vigiláncia',
  //   value: 'surveillance-route',
  // },
  // {
  //   label: 'Reporting',
  //   value: 'reporting',
  // },
  {
    label: 'Gestió',
    value: 'management',
    permissions: [Role.SuperAdmin, Role.Ceconsa],
  },
];

import React, { ReactNode } from 'react';

// Styles
import { Container, BoxContainer } from './styles';

// Components

export default function CredentialsLayout({
  children,
}: {
  children: ReactNode;
}) {
  return (
    <Container>
      <BoxContainer>{children}</BoxContainer>
    </Container>
  );
}

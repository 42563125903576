import React from 'react';

type GreenArrowProps = {
  className?: string;
};

export const GreenArrow = ({ className }: GreenArrowProps): JSX.Element => {
  return (
    <svg
      width="12"
      height="16"
      viewBox="0 0 12 16"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.11472 0.884832C5.91474 0.642557 5.5435 0.642557 5.34352 0.884832L0.303337 6.99084C0.034171 7.31692 0.266113 7.80913 0.688939 7.80913L3.38077 7.80913L3.38077 14.7005C3.38077 14.9766 3.60462 15.2005 3.88077 15.2005L7.5775 15.2005C7.85364 15.2005 8.0775 14.9766 8.0775 14.7005L8.0775 7.80913L10.7693 7.80914C11.1921 7.80914 11.4241 7.31693 11.1549 6.99084L6.11472 0.884832Z"
      />
    </svg>
  );
};

import React, { SyntheticEvent, useCallback, useLayoutEffect } from 'react';
import { useNavigate, useActionData, useSubmit } from 'react-router-dom';
import { format } from 'date-fns';

// Components Styled
import { Container } from './styles';

//Components
import ContentCardResume from '../../../../../components/display/ContentCardResume';

// Types
import { type NewCommunication } from '../../../../../types/communication';
import { createFormDataFromValues } from '../../../../../utils/forms';
import { variant } from '../../../../../components/display/SpecialTag/types';

export default function CommunicationSummary(): JSX.Element {
  const actionData = useActionData() as NewCommunication;
  const navigate = useNavigate();
  const submit = useSubmit();

  useLayoutEffect(() => {
    if (!actionData) navigate('..');
  }, [actionData]);

  const handleEditCommunication = useCallback(() => {
    navigate('..');
  }, []);

  const handleSaveCommunication = async (e: SyntheticEvent) => {
    e.preventDefault();
    const formData = createFormDataFromValues({
      intent: 'save-data',
    });
    submit(formData, {
      method: 'POST',
      action: '.',
    });
  };
  return (
    <Container>
      {actionData && (
        <ContentCardResume
          title="Resum de la comunicació"
          cotic={actionData.cotic as string[]}
          operator={actionData.operator}
          data={format(
            new Date(actionData.registerInitDate as string),
            'dd / MM / yyyy'
          )}
          hour={format(
            new Date(actionData.registerInitDate as string),
            'HH:mm'
          )}
          groupInter={actionData.groupInter}
          media={actionData.media}
          sendStatus={actionData.sendStatus}
          capitol={actionData.capitol}
          subCapitol={actionData.subCapitol}
          classification={actionData.classification}
          team={actionData.team}
          concession={actionData.concession}
          road={actionData.road}
          pkInit={`${actionData.pkInit} km`}
          pkEnd={`${actionData.pkEnd} km`}
          track={actionData.track}
          roadDirections={actionData.roadDirections}
          direction={actionData.direction}
          city={actionData.city}
          lane={actionData.lane}
          margin={actionData.margin}
          leftButton={'Editar comunicació'}
          rightButton={'Desar comunicació'}
          description={actionData.description}
          onEditCommunication={handleEditCommunication}
          onSaveCommunication={handleSaveCommunication}
          storedDataOTSelected={actionData.storedDataOTSelected}
          tag={actionData.specialTag as variant}
        />
      )}
    </Container>
  );
}

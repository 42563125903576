// Styles
import styled, { css } from 'styled-components';
import { colors } from '../../../../../styles/colors';
import { variantTypography } from './types';
import { spaces } from '../../../../../styles/spaces';

interface ContainerProps {
  variant: variantTypography;
  state: boolean;
}

export const Container = styled.div<ContainerProps>`
  ${({ variant, state }) =>
    ({
      normal: css`
        background-color: none;
      `,
      labeled: css`
        display: flex;
        align-items: center;
        gap: ${spaces.xxs}rem;
        padding: 6px 10px;
        border-radius: 50px;
        background-color: ${state ? colors.garnetLight : colors.greyLight};
      `,
    }[variant])}
`;

export const SwitchSimple = styled.label<ContainerProps>`
  ${({ variant, state }) =>
    ({
      normal: css`
        background-color: ${state ? colors.lightDustyRose : colors.greyLight};
      `,
      labeled: css`
        background-color: ${colors.greyVeryLight};
      `,
    }[variant])}

  position: relative;
  display: flex;
  align-items: center;
  border-radius: 3.4rem;
  transition: 0.4s;
  align-items: center;
  width: 3.4rem;
  height: 1.4rem;
  cursor: pointer;

  &:before {
    content: '';
    height: 2rem;
    width: 2rem;
    background-color: ${colors.black};
    border-radius: 50%;
    transition: 0.4s;
    margin-left: ${({ state }) => (state ? `1.4rem` : 0)};
  }
`;

export const InputSimple = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
`;

import React, { useCallback, useLayoutEffect, useState } from 'react';

import { Controller, useForm, useWatch } from 'react-hook-form';
import { differenceInMinutes } from 'date-fns';

// Components
import Tabs from '../../../display/Tabs';
import { OperatorsSelectionTable } from '../../../display/tables/formTables/OperatorsSelectionTable';
import DropDownInput from '../../../inputs/DropDown/DropDownInput';
import { MainButton } from '../../../inputs/buttons/MainButton';
import InputText from '../../../inputs/InputText';
import { DateTime } from '../../../inputs/DateTime';
import { ExternalCompaniesSelectionTable } from '../../../display/tables/formTables/ExternalCompaniesSelectionTable';

// Types
import {
  TimeValue,
  type OperatorsAndVehiclesFormValues,
  type OperatorsAndVehiclesType,
  DriverList,
} from './types';
import {
  OperatorsSelectionType,
  VehiclesSelectionType,
} from '../../../display/tables/formTables/OperatorsSelectionTable/types';
import { ExternalCompaniesSelectionType } from '../../../display/tables/formTables/ExternalCompaniesSelectionTable/types';

// Styles
import { StyledTypography, TimeContainer } from './styles';
import { GeneralContainer, ButtonContainer, ContainerError } from '../styles';

// Utilities
import { useCacheFormInSessionStorage } from '../../../../hooks/useCacheForm';
import { yupResolver } from '@hookform/resolvers/yup';
import { OperatorsAndVehicleSchema } from './schema';
import { Typography } from '../../../display/Typography';
import { tabsType } from '../../../../state/reducers/ui/tabs';

// Constants
import { formActionNaming } from '../constants';
import { OrderState } from '../../../../types/orders';

const OperatorsAndVehicleForm = ({
  onFormSubmit,
  namingPersistForm,
  initialData,
  textButton = 'Continuar',
}: OperatorsAndVehiclesType) => {
  const {
    control,
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    getValues,
  } = useForm<OperatorsAndVehiclesFormValues>({
    defaultValues: {
      ...initialData,
      registerInitDate: initialData?.registerInitDate
        ? initialData?.registerInitDate
        : new Date().toISOString(),
      registerEndDate: initialData?.registerEndDate
        ? initialData?.registerEndDate
        : new Date().toISOString(),
      isExternal: initialData.isExternal,
    },
    resolver: yupResolver(OperatorsAndVehicleSchema),
  });
  useCacheFormInSessionStorage(namingPersistForm, control);

  // States
  const [allOperators, setAllOperators] = useState<OperatorsSelectionType[]>(
    []
  );

  const [operatorError, setOperatorError] = useState<string | null>(null);
  const [timeValue, setTimeValue] = useState<TimeValue>({
    hour: '0',
    minutes: '0',
  });

  const defaultSelectedTab = initialData.isExternal
    ? initialData.isExternal === true
      ? 1
      : 0
    : 0;

  const [selectedTab, setSelectedTab] = useState(defaultSelectedTab ?? 0);

  // Table defaultData
  const internalOperatorsDefaultData = initialData?.internalOperatorsTable
    ? initialData.internalOperatorsTable.filter((operator) => operator)
    : [];
  const vehiclesInitialData = initialData?.vehicleTable
    ? Object.values(initialData.vehicleTable).filter((operator) => operator)
    : [];

  const [defaultInternalOperators, setDefaultInternalOperators] = useState<
    OperatorsSelectionType[]
  >(internalOperatorsDefaultData);

  const [defaultExternals, setDefaultExternals] = useState<
    ExternalCompaniesSelectionType[]
  >(initialData?.externals ?? []);

  const defaultVehiclesObject = initialData.vehicleTable;

  const selectedVehiclesIds = defaultVehiclesObject
    ? Object.keys(defaultVehiclesObject)
    : [];

  const filterDefaultVehicles = initialData.internalOperators.vehicles.filter(
    (vehicle) => selectedVehiclesIds.includes(vehicle.id)
  );

  const [defaultVehiclesSelected, setDefaultVehiclesSelected] = useState<
    VehiclesSelectionType[]
  >(filterDefaultVehicles);

  const [defaultHoursData, setDefaultHoursData] = useState<{
    registerInitDate?: string;
    registerEndDate?: string;
    hoursType?: string;
    totalHours?: string;
  }>({
    registerInitDate: initialData?.registerInitDate,
    registerEndDate: initialData?.registerEndDate,
    hoursType: initialData?.hoursType,
    totalHours: initialData?.totalHours,
  });

  // Watchers
  const startTimeWatcher = useWatch({
    control,
    name: 'registerInitDate',
  });

  const finishTimeWatcher = useWatch({
    control,
    name: 'registerEndDate',
  });

  const internalOperatorsTableWatcher = useWatch({
    control,
    name: 'internalOperatorsTable',
  });

  const internalHoursType = useWatch({
    control,
    name: 'hoursType',
  });

  const externsDataWatcher = useWatch({
    control,
    name: 'externals',
  });

  const vehiclesTableWatcher =
    useWatch({
      control,
      name: 'vehiclesTable',
    }) ?? [];

  useLayoutEffect(() => {
    if (internalOperatorsTableWatcher || externsDataWatcher) {
      const validOperators: OperatorsSelectionType[] = [
        ...(internalOperatorsTableWatcher || []),
      ].filter((operator) => operator);
      const externsOperators = externsDataWatcher?.reduce(
        (accumulator, currentVal) =>
          accumulator + Number(currentVal.operatorsNumber),
        0
      );
      const operatorsNumber =
        selectedTab === 0 ? validOperators.length : externsOperators;
      setAllOperators(validOperators);

      const totalMinutes = calculateHourDifference(
        finishTimeWatcher,
        startTimeWatcher
      );

      const totalHours = Math.floor((totalMinutes * operatorsNumber) / 60)
        .toString()
        .padStart(2, '0');
      const minutesDifference = ((totalMinutes * operatorsNumber) % 60)
        .toString()
        .padStart(2, '0');

      setTimeValue({ hour: totalHours, minutes: minutesDifference });
      setValue('totalHours', `${totalHours}:${minutesDifference}`);
      setOperatorError('');
      setDefaultHoursData({
        registerInitDate: startTimeWatcher,
        registerEndDate: finishTimeWatcher,
        hoursType: internalHoursType,
        totalHours: totalHours,
      });
      selectedTab === 0
        ? setValue('isExternal', false)
        : setValue('isExternal', true);
    }
  }, [
    startTimeWatcher,
    finishTimeWatcher,
    internalOperatorsTableWatcher,
    internalHoursType,
    externsDataWatcher,
    selectedTab,
  ]);

  const setCacheDrivers = (drivers: DriverList) => {
    const cacheDrivers = JSON.stringify(drivers);
    sessionStorage.setItem(formActionNaming.DRIVERS, cacheDrivers);
  };

  const drivers: DriverList =
    JSON.parse(sessionStorage.getItem(formActionNaming.DRIVERS) as string) ??
    vehiclesInitialData;

  const dataTabs = [
    {
      label: 'Ceconsa',
      content: (
        <>
          <OperatorsSelectionTable
            data={initialData}
            operatorsData={initialData?.internalOperators.operators}
            vehiclesData={initialData?.internalOperators.vehicles}
            titles={['Selecció dels operaris', 'Selecció dels vehicles']}
            control={control}
            defaultOperators={defaultInternalOperators}
            defaultVehicles={defaultVehiclesSelected}
            defaultDrivers={drivers}
            tableName="internalOperatorsTable"
            setDefaultOperators={setDefaultInternalOperators}
            setDefaultVehicles={setDefaultVehiclesSelected}
            setValue={setValue}
            setCacheDrivers={setCacheDrivers}
            isInternal={true}
            getValues={getValues}
            concessions={initialData?.concessions || []}
          />
        </>
      ),
    },
    {
      label: 'Externs',
      content: (
        <ExternalCompaniesSelectionTable
          setValue={setValue}
          getValues={getValues}
          defaultValues={defaultExternals}
          externalCompanies={initialData.externalCompanies}
          setDefaultExternals={setDefaultExternals}
        />
      ),
    },
  ];

  const calculateHourDifference = useCallback(
    (startISODate: string, endISODate: string) => {
      const startTime = new Date(startISODate);
      const endTime = new Date(endISODate);

      const minutesDifference = differenceInMinutes(startTime, endTime);

      if (isNaN(minutesDifference)) {
        return 0;
      }

      return minutesDifference;
    },
    [startTimeWatcher, finishTimeWatcher]
  );

  const checkSpecialValues = () => {
    if (selectedTab === 0) {
      const operators = allOperators.length === 0;
      const hours = Number(timeValue.hour) >= 0;
      const vehiclesWithAllData = vehiclesTableWatcher
        ?.filter((vehicle) => vehicle.id || vehicle.driver)
        .every((vehicle) => {
          return (
            'driver' in vehicle &&
            'id' in vehicle &&
            vehicle['driver'] &&
            vehicle['id']
          );
        });

      return !operators && hours && vehiclesWithAllData;
    } else {
      const externsOperators = externsDataWatcher?.reduce(
        (accumulator, currentVal) =>
          accumulator + Number(currentVal.operatorsNumber),
        0
      );
      return externsOperators;
    }
  };

  const checkRepeatedDrivers = () => {
    const driversSet = new Set();
    let repeatedDrivers = false;
    for (const vehicle of vehiclesTableWatcher) {
      if (vehicle.driver) {
        if (driversSet.has(vehicle.driver)) {
          repeatedDrivers = true;
          break;
        }
        driversSet.add(vehicle.driver);
      }
    }
    return repeatedDrivers;
  };

  return (
    <GeneralContainer
      onSubmit={
        !checkRepeatedDrivers()
          ? checkSpecialValues()
            ? handleSubmit(onFormSubmit)
            : (event) => {
                selectedTab === 1
                  ? setOperatorError(
                      "S'ha d'afegir com a mínim un operari extern."
                    )
                  : Number(timeValue.hour) < 0
                  ? setOperatorError('Introdueix una data vàlida')
                  : setOperatorError(
                      "S'ha d'afegir com a mínim un operari intern i proporcionar dades del vehicle."
                    );
                event.preventDefault();
              }
          : (event) => {
              setOperatorError(
                'Només es pot assignar un vehicle a un mateix conductor'
              );

              event.preventDefault();
            }
      }
    >
      <Tabs
        data={dataTabs}
        defaultIndex={selectedTab}
        typeTab={tabsType.WORK_ORDERS_ACTION}
        setSelectedIndexTab={setSelectedTab}
      />

      <StyledTypography component="h3" variant="semiBold" size="L">
        Distribució d’hores
      </StyledTypography>
      <TimeContainer>
        <Controller
          control={control}
          name="registerInitDate"
          render={({ field: { onChange } }) => (
            <DateTime
              label="Data d´inici:"
              labelHour="Hora:"
              onChangeDate={onChange}
              initialDate={defaultHoursData.registerInitDate}
              borderError={!!errors.registerInitDate}
              isDisabled={
                initialData?.parentExpeditionOrderStatus ===
                  OrderState.Annulled ||
                initialData?.parentExpeditionOrderStatus === OrderState.End ||
                initialData?.state === OrderState.Annulled
              }
            />
          )}
        />
        <Controller
          control={control}
          name="registerEndDate"
          render={({ field: { onChange } }) => (
            <DateTime
              label="Data final:"
              labelHour="Hora:"
              onChangeDate={onChange}
              initialDate={defaultHoursData.registerEndDate}
              borderError={!!errors.registerEndDate}
              isDisabled={
                initialData?.parentExpeditionOrderStatus ===
                  OrderState.Annulled ||
                initialData?.parentExpeditionOrderStatus === OrderState.End ||
                initialData?.state === OrderState.Annulled
              }
            />
          )}
        />

        <Controller
          control={control}
          name="hoursType"
          render={({ field: { onChange }, formState: { defaultValues } }) => {
            const options =
              defaultValues?.hoursTypeOptions?.map((hourType) => ({
                label: hourType?.name || '',
                value: hourType?.value || '',
              })) || [];

            return (
              <DropDownInput
                placeholder={'Selecciona'}
                labelText="Tipus d’hores"
                inputSize={'M'}
                typeDropDown={'Default'}
                options={options}
                onChangeSelected={(option) => onChange(option?.value ?? '')}
                defaultValue={defaultHoursData.hoursType}
                borderError={!!errors.hoursType}
                isDisabled={
                  initialData?.parentExpeditionOrderStatus ===
                    OrderState.Annulled ||
                  initialData?.parentExpeditionOrderStatus === OrderState.End ||
                  initialData?.state === OrderState.Annulled
                }
              />
            );
          }}
        />
        <InputText
          {...register('totalHours')}
          label="Total d'hores"
          disabled
          typeInput={'normal'}
          placeholder="00:00"
          inputSize={'14'}
          borderError={!!errors.totalHours}
          defaultValue={defaultHoursData.totalHours}
        />
      </TimeContainer>

      {operatorError && (
        <ContainerError>
          <Typography size="XS" colorText="lightCoralRed">
            {operatorError}
          </Typography>
        </ContainerError>
      )}
      <ButtonContainer>
        <MainButton
          text={
            initialData?.parentExpeditionOrderStatus === OrderState.Annulled ||
            initialData?.parentExpeditionOrderStatus === OrderState.End ||
            initialData?.state === OrderState.Annulled
              ? 'Continuar'
              : textButton
          }
          type="submit"
        />
      </ButtonContainer>
    </GeneralContainer>
  );
};

export default OperatorsAndVehicleForm;

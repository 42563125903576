import React from 'react';

type SquareIconProps = {
  className?: string;
};

export const Square = ({ className }: SquareIconProps): JSX.Element => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 9 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <rect
        x="0.332993"
        y="0.375962"
        width="8.37488"
        height="8.37488"
        fill="color"
        stroke="color"
        strokeWidth="0.587862"
      />
    </svg>
  );
};

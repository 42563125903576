import React, { useId, useCallback, useState, forwardRef, Ref } from 'react';

// Styled Components
import { Container, Label, Area } from './styles';
import { ErrorMessage } from '../InputText/styles';

// Types
import { AreaProps, OnChangeEvent } from './types';

// Media
import { useIsLandscapeTablet } from '../../../hooks/useMediaBreakPoints';

export const InputTextArea = forwardRef(
  (
    {
      label,
      disabled,
      value,
      name,
      error,
      borderError,
      className,
      ...props
    }: AreaProps,
    ref: Ref<HTMLTextAreaElement>
  ): JSX.Element => {
    const id = useId();

    const [textValue, setTextValue] = useState(value);

    const onChangeValue = useCallback((event: OnChangeEvent) => {
      setTextValue(event.target.value);
    }, []);

    const isLandscapeTablet = useIsLandscapeTablet();

    return (
      <Container className={className}>
        {label && (
          <Label
            size={isLandscapeTablet ? 'XS' : 'S'}
            variant="regular"
            component="label"
            htmlFor={`${id}-${name}`}
          >
            {label}
          </Label>
        )}
        <Area
          disabled={disabled}
          id={`${id}-${name}`}
          name={name}
          onChange={onChangeValue}
          spellCheck={false}
          value={textValue}
          ref={ref}
          borderError={borderError}
          {...props}
        />
        {error && (
          <ErrorMessage colorText={'lightCoralRed'} size={'XXS'}>
            {error}
          </ErrorMessage>
        )}
      </Container>
    );
  }
);

import styled from 'styled-components';

import { Typography } from '../../display/Typography';

import { colors } from '../../../styles/colors';
import { fontSize, fontWeight } from '../../../styles/fonts';
import {
  spaces,
  breakPoints,
  percentBaseResponsive,
} from '../../../styles/spaces';

import { LabelProps } from './types';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spaces.xxxs}rem;
`;

export const Label = styled(Typography)<LabelProps>``;

type textArea = {
  borderError: boolean | undefined;
};

export const Area = styled.textarea<textArea>`
  width: 100%;
  resize: none;
  border: solid 0.1rem ${colors.black};
  border-radius: 0.5rem;
  min-height: 14rem;
  font-size: ${fontSize.S};
  padding: ${spaces.xxs}rem 1rem;
  color: ${colors.black};
  background-color: ${colors.white};
  ${(props) =>
    props.disabled && {
      backgroundColor: '#D9D9D9',
      border: 'transparent',
      color: '#757575',
    }}
  border: ${({ borderError }) =>
    borderError && `0.1rem solid ${colors.lightCoralRed}`};

  :focus {
    outline: 0.08rem solid ${colors.black};
    font-weight: ${fontWeight.semiBold};
  }

  ::placeholder {
    color: ${colors.greySoftLight};
    opacity: 1;
  }

  :focus::placeholder {
    opacity: 0;
  }

  @media (max-width: ${percentBaseResponsive *
    breakPoints.landscapeTablet}rem) {
    font-size: ${fontSize.XS};
  }
`;

// Dependencies
import React from 'react';

// Styles
import { ContainerSimpleBg, InputSimpleBg, SwitchSimpleBg } from './styles';

// Components
import { Typography } from '../../../../display/Typography';

// Types
import { SwitchSimpleBackgroundTypes } from './types';

const SimpleBackgroundSwitch = ({
  changeState,
  state,
  disabled,
}: SwitchSimpleBackgroundTypes) => {
  const handleClick = () => {
    changeState(!state);
  };

  return (
    <ContainerSimpleBg activate={state}>
      <SwitchSimpleBg defaultChecked={state}>
        <InputSimpleBg
          type="checkbox"
          checked={state}
          onChange={handleClick}
          disabled={disabled}
        />
      </SwitchSimpleBg>
      <Typography size="XS">{state ? 'Sí hi ha' : 'No hi ha'}</Typography>
    </ContainerSimpleBg>
  );
};

export default SimpleBackgroundSwitch;

import styled from 'styled-components';

// Styles utils
import { spaces } from '../../../styles/spaces';
import { ArrowBottom } from '../../../assets/icons/ArrowBottom';

export const ContainerUserMenu = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  cursor: pointer;
`;

export const ContainerUser = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${spaces.m}rem;
  align-items: center;
`;

export const UserData = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  width: max-content;
`;

export const ContainerDropdown = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.2rem;
`;

export const BottomArrow = styled(ArrowBottom)`
  width: 1.1rem;
  height: 0.846rem;
`;

import { gql } from '@apollo/client';

export const FETCH_CODIFICATIONS = gql`
  query FetchCodifications {
    codifications {
      id
      name
      subCodificationIDs
    }
  }
`;

export const GET_CODIFICATION_BY_ID = gql`
  query GetCodificationById($id: ID!) {
    codification(id: $id) {
      id
      name
      subCodificationIDs
      materialIDs
    }
  }
`;

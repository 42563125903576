import { defer, LoaderFunction } from 'react-router-dom';

// Sentry
import * as Sentry from '@sentry/react';

// Types
import { decompressSessionStorage } from '../../../../../utils/sessionStorage';
import { AuthContextType } from '../../../../../hooks/useAuth';

// Constants
import { formActionNaming } from '../../../../../components/forms/ActionForms/constants';

// Selector
import { selectActiveOperatorsAndVehicles } from '../../../../../gql/selectors/actions';

// Static data
import { hoursType } from '../../../../../data/orders';
import { GET_EXTERNAL_COMPANIES } from '../../../../../gql/queries/actions/externalCompanies';

// GQL
import { client } from '../../../../../gql/client';
import { GET_CONCESSIONS } from '../../../../../gql/queries/orders/orders';

const loadFormOptions = async (userData: AuthContextType | null) => {
  try {
    if (userData?.user) {
      const internalOperators =
        (await selectActiveOperatorsAndVehicles(true)) ?? [];
      const { readExternalCompanies } = client.readQuery({
        query: GET_EXTERNAL_COMPANIES,
      });

      const sortedExternalCompanies = [...readExternalCompanies].sort((a, b) =>
        a.companyName.localeCompare(b.companyName)
      );

      return {
        internalOperators,
        externalCompanies: sortedExternalCompanies,
        hoursTypeOptions: hoursType,
      };
    }
  } catch (err) {
    Sentry.captureException(err);
    console.log('> Operators and vehicles loading error:', err);
    return true;
  }
};

export const OperatorsAndVehiclesLoader: (
  userData: AuthContextType | null
) => LoaderFunction | undefined = (userData) => async () => {
  const stringifyForm = sessionStorage.getItem(
    formActionNaming.NEW_OPERATORS_AND_VEHICLE_FORM
  );
  const {
    data: { concessions },
  } = await client.query({
    query: GET_CONCESSIONS,
  });

  const loaderToResolve = await loadFormOptions(userData);
  const breadCrumbsDataStringify = sessionStorage.getItem(
    formActionNaming.NEW_ACTION_DATA
  );

  let sessionStorageData;
  let breadCrumbsData;

  if (stringifyForm) {
    sessionStorageData = JSON.parse(decompressSessionStorage(stringifyForm));
  }

  if (breadCrumbsDataStringify) {
    breadCrumbsData = JSON.parse(
      decompressSessionStorage(breadCrumbsDataStringify)
    );
  }

  const combinedData = {
    ...sessionStorageData,
    ...(typeof loaderToResolve === 'object' ? loaderToResolve : {}),
    breadCrumbsData: {
      orderParentId: breadCrumbsData.orderParentId,
      orderParentCotic: breadCrumbsData.orderParentCotic,
      concessions: breadCrumbsData.concessions,
      concession: breadCrumbsData.concession,
    },
    concessions,
  };

  return defer({
    data: combinedData,
  });
};

// Dependencies
import React, { useCallback } from 'react';

// Types
import { iconProps } from './types';

// Components Styled
import {
  Container,
  VisibilityImg,
  RestartImg,
  EditImg,
  AddImg,
  DeleteImg,
  SaveImg,
  CheckImg,
  CancelImg,
  DianaImg,
  SearchImg,
} from './styles';

// Icons
import Restart from '../../../../assets/icons/restart.svg';
import Pencil from '../../../../assets/icons/pencil.svg';
import Discket from '../../../../assets/icons/discket.svg';
import Add from '../../../../assets/icons/add-icon.svg';
import Delete from '../../../../assets/icons/remove-icon.svg';
import Check from '../../../../assets/icons/check-grey-icon.svg';
import Cancel from '../../../../assets/icons/CloseIconFilled.svg';
import Diana from '../../../../assets/icons/DianaIconWhite.svg';
import Search from '../../../../assets/icons/search-icon-white.svg';

export const ButtonIconBackground = ({
  variant = 'restart',
  ...props
}: iconProps) => {
  const renderIcon = useCallback(() => {
    switch (variant) {
      case 'restart':
        return <RestartImg src={Restart} alt={'icona de restart data'} />;
      case 'visibility':
        return <VisibilityImg />;
      case 'edit':
        return <EditImg src={Pencil} alt={'icona da llapis'} />;
      case 'save':
        return <SaveImg src={Discket} alt={'icona da disquet'} />;
      case 'add':
        return <AddImg src={Add} alt={"Icona d'afegir element"} />;
      case 'delete':
        return <DeleteImg src={Delete} alt={"Icona per eliminar l'element"} />;
      case 'check':
        return <CheckImg src={Check} alt={'Icona per verificar element'} />;
      case 'cancel':
        return <CancelImg src={Cancel} alt={'Icona per cancel·lar element'} />;
      case 'diana':
        return <DianaImg src={Diana} alt={'Icona per seleccionar Ubicai'} />;
      case 'search':
        return <SearchImg src={Search} alt={'Icona per seleccionar Ubicai'} />;
    }
  }, [variant]);
  return (
    <Container variant={variant} {...props}>
      {renderIcon()}
    </Container>
  );
};

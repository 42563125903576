import React, {
  useState,
  useRef,
  useEffect,
  useLayoutEffect,
  useCallback,
} from 'react';

import imageCompression from 'browser-image-compression';

// Sentry
import * as Sentry from '@sentry/react';

import {
  useForm,
  Controller,
  useWatch,
  ControllerProps,
} from 'react-hook-form';
import { useDispatch } from 'react-redux';

// Components
import { Typography } from '../../../display/Typography';
import { MainButton } from '../../../inputs/buttons/MainButton';
import DropDownInput from '../../../inputs/DropDown/DropDownInput';
import SimpleSwitch from '../../../inputs/buttons/SwitchButton/Simple';
import { InputTextArea } from '../../../inputs/InputTextArea';
import { ButtonIconBackground } from '../../../inputs/buttons/ButtonIconBackground';
import { InputLoadImages } from '../../../inputs/InputLoadImages';
import { Loader } from '../../../inputs/Loader';

// Styles
import { GeneralContainer, ButtonContainer, Row, Title } from '../styles';
import {
  AddImagesButton,
  HiddenInput,
  ImagesContainer,
  InputsContainer,
  NormalContainer,
  RowsContainerLeft,
  RowsContainerRight,
  SwitchContainer,
} from './styles';

// Types
import {
  type ActionEditionTypes,
  type ActionEditionFormValues,
  type Img,
  CustomFile,
} from './types';
import { OrderState, OrderType } from '../../../../types/orders';
import { DataState } from '../types';

import { type WorkOrder } from '../../../../types/workOrder';
import { type OptionSelected } from '../../../inputs/DropDown/DropDownList/types';
import { type ImperativeRefInput } from '../../../display/tables/formTables/OperatorsSelectionTable/types';

// Utils
import { useCacheFormInSessionStorage } from '../../../../hooks/useCacheForm';
import { closeModal, openModal } from '../../../../state/actions/ui/modal';
import { modalIds } from '../../../modals/SuperModal/types';
import {
  deleteFilesFromDirectory,
  getFilesFromSessionStorage,
  saveFilesIntoADirectory,
} from '../../../../utils/files';
import {
  compressSessionStorage,
  decompressSessionStorage,
} from '../../../../utils/sessionStorage';
import { yupResolver } from '@hookform/resolvers/yup';
import { EditingActionSchema } from './schema';
import { useIsLandscapeTablet } from '../../../../hooks/useMediaBreakPoints';

// GQL
import {
  GET_CAPITOL_BY_ID,
  GET_ELEMENT_ORDERS_BY_ID,
  GET_ELEMENT_ORDER_BY_ID,
  GET_SUB_CAPITOLS_BY_IDS,
  GET_SUB_CAPITOL_BY_ID,
} from '../../../../gql/queries/orders/predefinedData';
import { client } from '../../../../gql/client';
import { GET_ACTIVITY_BY_ID } from '../../../../gql/queries/actions/activitys';

// Constants
import { formActionNaming } from '../constants';
import { fetchDefaultData } from '../../../../utils/fetchData';

const EditingActionForm = ({
  onFormSubmit,
  initialData,
  namingPersistForm,
  namingUpdateForm,
  textButton = 'Continuar',
}: ActionEditionTypes) => {
  const initialFiles = getFilesFromSessionStorage(
    formActionNaming.SELECTED_ACTIONS_FILES
  )?.map((file) => ({ file: file, id: file.name }));
  const [switchButtonState, setSwitchButtonState] = useState<boolean>(false);
  const [selectedImages, setSelectedImages] = useState<Img[]>([]);
  const [selectedFiles, setSelectedFiles] = useState<CustomFile[]>(
    initialFiles ? initialFiles : []
  );
  const [isLoadingFiles, setIsLoadingFiles] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();
  const {
    control,
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm<ActionEditionFormValues & WorkOrder>({
    defaultValues: {
      ...initialData,
      editionDescription:
        initialData.editionDescription ?? initialData.description,
      editionAssetSwitch: initialData?.editionAssetSwitch
        ? initialData.editionAssetSwitch
        : 'false',
      editionCapitol: initialData.editionCapitol
        ? initialData.editionCapitol
        : initialData.capitol,
      editionSubCapitol: initialData.editionSubCapitol
        ? initialData.editionSubCapitol
        : initialData.subCapitol,
      editionLaneCutting: initialData.editionLaneCutting
        ? initialData.editionLaneCutting
        : 'false',
      editionElementOrder: initialData.editionElementOrder
        ? initialData.editionElementOrder
        : initialData?.actionElementOrder,
      editionActivity: initialData.editionActivity
        ? initialData.editionActivity
        : initialData?.actionActivity,
    },
    resolver: yupResolver(EditingActionSchema),
  });

  // References
  const capitolDropDownRef = useRef<ImperativeRefInput | null>(null);
  const subCapitolDropDownRef = useRef<ImperativeRefInput | null>(null);
  const elementOrdersDropDownRef = useRef<ImperativeRefInput | null>(null);
  const activitysDropDownRef = useRef<ImperativeRefInput | null>(null);
  // Watchers
  const subCapitols = useWatch({
    control,
    name: 'subCapitols',
  });

  const elementOrders = useWatch({
    control,
    name: 'elementOrders',
  });

  useCacheFormInSessionStorage(namingPersistForm, control);

  useLayoutEffect(() => {
    if (initialData?.editionImages) {
      const parsedArray: Img[] = JSON.parse(initialData?.editionImages);
      setSelectedImages(parsedArray);
    }
    setSwitchButtonState(switchStateValue());
  }, []);

  useEffect(() => {
    const files = selectedFiles.map((file) => file.file);
    saveFilesIntoADirectory(OrderType.Action, files);
  }, [selectedFiles, selectedImages]);

  useEffect(() => {
    const imagesValue =
      selectedImages.length > 0 ? JSON.stringify(selectedImages) : null;
    setValue('editionImages', imagesValue);
  }, [selectedImages]);

  const isLandscapeTablet = useIsLandscapeTablet();

  const onSelectPredefined = useCallback(
    (
      option: OptionSelected | undefined,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onChange: (...event: any[]) => void
    ) => {
      const tasks = async () => {
        onChange(option?.value ?? '');

        const [
          {
            data: { capitol },
          },
          {
            data: { subCapitol },
          },
          {
            data: { elementOrder },
          },
          {
            data: { activity },
          },
        ] = await Promise.all([
          client.query({
            query: GET_CAPITOL_BY_ID,
            variables: {
              id: option?.data.capitolId,
            },
          }),
          client.query({
            query: GET_SUB_CAPITOL_BY_ID,
            variables: {
              id: option?.data.subCapitolId,
            },
          }),
          client.query({
            query: GET_ELEMENT_ORDER_BY_ID,
            variables: {
              id: option?.data.elementOrderId,
            },
          }),
          client.query({
            query: GET_ACTIVITY_BY_ID,
            variables: {
              id: option?.data.activityId,
            },
          }),
        ]);

        const { readSubCapitols } = client.readQuery({
          query: GET_SUB_CAPITOLS_BY_IDS,
          variables: {
            ids: capitol?.subCapitolsIDs ?? [],
          },
        });

        if (readSubCapitols.length === 0) {
          await fetchDefaultData();
          const { readSubCapitols } = client.readQuery({
            query: GET_SUB_CAPITOLS_BY_IDS,
            variables: {
              ids: capitol?.subCapitolsIDs ?? [],
            },
          });
          setValue('subCapitols', readSubCapitols);
        } else {
          setValue('subCapitols', readSubCapitols);
        }

        capitolDropDownRef.current?.setSelectedOption({
          label: capitol.name,
          value: capitol.id,
          data: capitol.subCapitolsIDs,
        });

        subCapitolDropDownRef.current?.setSelectedOption({
          label: subCapitol.name,
          value: subCapitol.id,
          data: subCapitol.elementOrdersIds,
        });

        const { readElementOrders } = client.readQuery({
          query: GET_ELEMENT_ORDERS_BY_ID,
          variables: {
            ids: subCapitol?.elementOrdersIds ?? [],
          },
        });

        setValue('elementOrders', readElementOrders);

        elementOrdersDropDownRef.current?.setSelectedOption({
          label: elementOrder.name,
          value: elementOrder.id,
          data: elementOrder.subCapitolsIDs,
        });

        activitysDropDownRef.current?.setSelectedOption({
          label: activity.name,
          value: activity.id,
        });
      };
      tasks().catch((err) => {
        Sentry.captureException(err);
        console.log(err);
      });
    },
    [
      capitolDropDownRef,
      subCapitolDropDownRef,
      elementOrdersDropDownRef,
      activitysDropDownRef,
    ]
  );

  const onSelectCapitol = useCallback(
    (
      option: OptionSelected | undefined,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onChange: (...event: any[]) => void
    ) => {
      const tasks = async () => {
        try {
          const { readSubCapitols } = client.readQuery({
            query: GET_SUB_CAPITOLS_BY_IDS,
            variables: {
              ids: option?.data ?? [],
            },
          });

          if (readSubCapitols.length === 0) {
            await fetchDefaultData();
            const { readSubCapitols } = client.readQuery({
              query: GET_SUB_CAPITOLS_BY_IDS,
              variables: {
                ids: option?.data ?? [],
              },
            });
            setValue('subCapitols', readSubCapitols);
          } else {
            setValue('subCapitols', readSubCapitols);
          }
          subCapitolDropDownRef.current?.clearSelected();

          elementOrdersDropDownRef.current?.clearSelected();
          setValue('editionSubCapitol', '');
          setValue('editionElementOrder', '');
          setValue('elementOrders', []);
        } catch (err) {
          Sentry.captureException(err);
          console.log(err);
        }
        onChange(option?.value ?? '');
      };
      tasks().catch((err) => {
        Sentry.captureException(err);
        console.log(err);
      });
    },
    []
  );

  const onSelectSubCapitol = useCallback(
    (
      option: OptionSelected | undefined,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onChange: (...event: any[]) => void
    ) => {
      const tasks = async () => {
        try {
          const { readElementOrders } = client.readQuery({
            query: GET_ELEMENT_ORDERS_BY_ID,
            variables: {
              ids: option?.data ?? [],
            },
          });
          if (readElementOrders.length === 0) {
            await fetchDefaultData();
            const { readElementOrders } = client.readQuery({
              query: GET_ELEMENT_ORDERS_BY_ID,
              variables: {
                ids: option?.data ?? [],
              },
            });

            setValue('elementOrders', readElementOrders);
          } else {
            setValue('elementOrders', readElementOrders);
          }
          elementOrdersDropDownRef.current?.clearSelected();
        } catch (err) {
          Sentry.captureException(err);
          console.log(err);
        }
        onChange(option?.value ?? '');
      };

      setValue('editionElementOrder', '');

      tasks().catch((err) => {
        Sentry.captureException(err);
        console.log(err);
      });
    },
    []
  );

  const renderSubCapitols = useCallback<
    ControllerProps<
      ActionEditionFormValues & WorkOrder,
      'editionSubCapitol'
    >['render']
  >(
    ({ field: { onChange }, formState: { defaultValues } }) => {
      const options =
        subCapitols?.map((subCapitol) => ({
          label: subCapitol?.name || '',
          value: subCapitol?.id || '',
          data: subCapitol?.elementOrdersIds || '',
        })) || [];
      return (
        <DropDownInput
          ref={subCapitolDropDownRef}
          inputSize="L"
          onChangeSelected={(option) => onSelectSubCapitol(option, onChange)}
          typeDropDown="Default"
          labelText="Subcapitol"
          placeholder="Selecciona"
          defaultValue={defaultValues?.editionSubCapitol}
          options={options}
          borderError={!!errors.editionSubCapitol}
          isDisabled={
            initialData?.parentExpeditionOrderStatus === OrderState.Annulled ||
            initialData?.parentExpeditionOrderStatus === OrderState.End ||
            initialData?.state === OrderState.Annulled
          }
        />
      );
    },
    [subCapitols]
  );
  const renderElementOrders = useCallback<
    ControllerProps<
      ActionEditionFormValues & WorkOrder,
      'editionElementOrder'
    >['render']
  >(
    ({ field: { onChange }, formState: { defaultValues } }) => {
      const options =
        elementOrders?.map((elementOrder) => ({
          label: elementOrder?.name || '',
          value: elementOrder?.id || '',
          data: elementOrder?.subCapitolIDs || '',
        })) || [];
      return (
        <DropDownInput
          ref={elementOrdersDropDownRef}
          labelText="Element"
          placeholder={'Selecciona'}
          inputSize={'L'}
          options={options}
          typeDropDown={'Default'}
          defaultValue={defaultValues?.editionElementOrder}
          onChangeSelected={(option) => onChange(option?.value ?? '')}
          borderError={!!errors.editionElementOrder}
          isDisabled={
            initialData?.parentExpeditionOrderStatus === OrderState.Annulled ||
            initialData?.parentExpeditionOrderStatus === OrderState.End ||
            initialData?.state === OrderState.Annulled
          }
        />
      );
    },
    [elementOrders, errors]
  );

  const switchStateValue = () => {
    const { editionAssetSwitch } = initialData;

    if (editionAssetSwitch) {
      return editionAssetSwitch === 'true';
    }

    return false;
  };
  const handleChange = () => {
    if (switchButtonState) {
      setSwitchButtonState(false);
      setValue('editionAssetSwitch', 'false');
    } else {
      setSwitchButtonState(true);
      setValue('editionAssetSwitch', 'true');
    }
  };

  const addToDataChanged = (item: { id: string; state: string }) => {
    if (namingUpdateForm) {
      const previousData = sessionStorage.getItem(namingUpdateForm)
        ? JSON.parse(
            decompressSessionStorage(
              sessionStorage.getItem(namingUpdateForm) as string
            )
          )
        : {};

      if (!previousData.editionData) {
        previousData.editionData = {
          [DataState.added]: [],
          [DataState.deleted]: [],
        };
      }

      if (item.state === DataState.deleted) {
        const deletedIndex = previousData.editionData[
          DataState.deleted
        ].findIndex((addedItem: { id: string }) => addedItem.id === item.id);
        if (deletedIndex === -1) {
          if (item.id.match(/^[0-9a-fA-F]{24}$/)) {
            previousData.editionData[DataState.deleted].push(item.id);
          }
          const addedIndex = previousData.editionData[
            DataState.added
          ].findIndex((addedItem: { id: string }) => addedItem.id === item.id);
          if (addedIndex !== -1) {
            previousData.editionData[DataState.added].splice(addedIndex, 1);
          }
        }
      } else {
        const addedIndex = previousData.editionData[DataState.added].findIndex(
          (addedItem: { id: string }) => addedItem.id === item.id
        );
        if (addedIndex === -1) {
          previousData.editionData[item.state].push(item);
        }
      }

      sessionStorage.setItem(
        namingUpdateForm as string,
        compressSessionStorage(JSON.stringify(previousData))
      );
    }
  };

  const handleChoosePick = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target.files;
    setIsLoadingFiles(true);
    if (files) {
      const newImages: Img[] = [];
      const newArrayFiles: CustomFile[] = [];

      for (const file of files) {
        const id = file.name;
        const sizeInMB = file.size / 1024 / 1000;
        let fileToSave = file;
        if (sizeInMB > 4) {
          const blob = await imageCompression(file, {
            maxSizeMB: 4,
          });

          fileToSave = new File([blob], blob.name, {
            type: blob.type,
            lastModified: blob.lastModified,
          });
        }

        const isDuplicate = selectedFiles.some((img) => img.id === id);

        if (!isDuplicate) {
          newImages.push({
            id,
            date: new Date(file.lastModified).toISOString(),
            name: file.name,
          });

          newArrayFiles.push({
            id,
            file: fileToSave,
          });

          if (namingUpdateForm) {
            const itemAdded = {
              id,
              state: DataState.added,
            };
            addToDataChanged(itemAdded);
          }
        }
      }

      setSelectedImages((prevImages) => [...prevImages, ...newImages]);
      setSelectedFiles((prevFiles) => [...prevFiles, ...newArrayFiles]);
    }
    setIsLoadingFiles(false);
    event.target.value = '';
  };
  const handleRemoveImage = (id: string) => {
    setSelectedImages((currentImages) =>
      currentImages.filter((image) => image.id !== id)
    );

    setSelectedFiles((currentFiles) =>
      currentFiles.filter((file) => file.id !== id)
    );

    deleteFilesFromDirectory(OrderType.Action, id);

    if (namingUpdateForm) {
      const itemDeleted = {
        id,
        state: DataState.deleted,
      };
      addToDataChanged(itemDeleted);
    }
  };

  const handleOpenCamera = () => {
    dispatch(
      openModal(modalIds.OPEN_CAMERA_MODAL, {
        setImagesState: setSelectedImages,
        setFilesState: setSelectedFiles,
        type: OrderType.Action.toUpperCase(),
        dataBaseNamingCaching: formActionNaming.NEW_ACTION_DATA,
      })
    );
  };

  const handleOpenModal = () => {
    dispatch(
      openModal(modalIds.ADD_MULTIMEDIA_MODAL, {
        handleOpenCamera: handleOpenCamera,
        handleChoosePick: () => {
          inputRef?.current?.click();
          dispatch(closeModal());
        },
      })
    );
  };

  return (
    <GeneralContainer onSubmit={handleSubmit(onFormSubmit)}>
      <Title component="h3" variant="semiBold" size="L">
        {`Descripció de l'actuació`}
      </Title>
      <InputsContainer>
        <RowsContainerLeft>
          <Row>
            <Controller
              name="editionActivity"
              control={control}
              render={({
                field: { onChange },
                formState: { defaultValues },
              }) => {
                const options =
                  defaultValues?.activitys?.map((activity) => ({
                    label: activity?.name || '',
                    value: activity?.id || '',
                  })) || [];
                return (
                  <DropDownInput
                    ref={activitysDropDownRef}
                    labelText="Acció"
                    placeholder={'Selec'}
                    inputSize={'L'}
                    options={options}
                    typeDropDown={'Default'}
                    onChangeSelected={(option) => onChange(option?.value ?? '')}
                    defaultValue={defaultValues?.editionActivity}
                    borderError={!!errors.editionActivity}
                    isDisabled={
                      initialData?.parentExpeditionOrderStatus ===
                        OrderState.Annulled ||
                      initialData?.parentExpeditionOrderStatus ===
                        OrderState.End ||
                      initialData?.state === OrderState.Annulled
                    }
                  />
                );
              }}
            />
            <Controller
              name="editionPredefine"
              control={control}
              render={({
                field: { onChange },
                formState: { defaultValues },
              }) => {
                const options =
                  defaultValues?.predefinedOrders?.map((predefinedOrder) => ({
                    label: predefinedOrder?.name || '',
                    value: predefinedOrder?.id || '',
                    data: {
                      capitolId: predefinedOrder?.capitolId || '',
                      subCapitolId: predefinedOrder?.subCapitolId || '',
                      elementOrderId: predefinedOrder?.elementOrderId || '',
                      activityId: predefinedOrder?.activityId || '',
                    },
                  })) || [];
                return (
                  <DropDownInput
                    labelText="Predefinits"
                    placeholder={'Selecciona'}
                    inputSize={'XL'}
                    options={options}
                    typeDropDown={'Default'}
                    onChangeSelected={(option) =>
                      onSelectPredefined(option, onChange)
                    }
                    defaultValue={defaultValues?.editionPredefine}
                    borderError={!!errors.editionPredefine}
                    isDisabled={
                      initialData?.parentExpeditionOrderStatus ===
                        OrderState.Annulled ||
                      initialData?.parentExpeditionOrderStatus ===
                        OrderState.End ||
                      initialData?.state === OrderState.Annulled
                    }
                  />
                );
              }}
            />
          </Row>
          <Row>
            <Controller
              name="editionElementOrder"
              control={control}
              render={renderElementOrders}
            />
            <NormalContainer>
              <SwitchContainer>
                <SimpleSwitch
                  changeState={handleChange}
                  state={switchButtonState}
                  disabled={
                    initialData?.parentExpeditionOrderStatus ===
                      OrderState.Annulled ||
                    initialData?.parentExpeditionOrderStatus ===
                      OrderState.End ||
                    initialData?.state === OrderState.Annulled
                  }
                />
                <Typography size={isLandscapeTablet ? 'XS' : 'S'}>
                  Actius
                </Typography>
              </SwitchContainer>
              <Controller
                control={control}
                name="editionAsset"
                render={({
                  field: { onChange },
                  formState: { defaultValues },
                }) => {
                  const options =
                    defaultValues?.assets?.map((asset) => ({
                      label: asset?.code || '',
                      value: asset?.id || '',
                    })) || [];
                  return (
                    <DropDownInput
                      isDisabled={
                        !switchButtonState ||
                        initialData?.parentExpeditionOrderStatus ===
                          OrderState.Annulled ||
                        initialData?.parentExpeditionOrderStatus ===
                          OrderState.End ||
                        initialData?.state === OrderState.Annulled
                      }
                      options={options}
                      placeholder={''}
                      inputSize={'M'}
                      typeDropDown={'Default'}
                      onChangeSelected={(option) =>
                        onChange(option?.value ?? '')
                      }
                      defaultValue={defaultValues?.editionAsset}
                      borderError={!!errors.editionAsset}
                    />
                  );
                }}
              />
            </NormalContainer>
            <Controller
              control={control}
              name="editionLaneCutting"
              render={({
                field: { onChange },
                formState: { defaultValues },
              }) => {
                const options = [
                  {
                    label: 'SI',
                    value: 'true',
                  },
                  {
                    label: 'NO',
                    value: 'false',
                  },
                ];

                return (
                  <DropDownInput
                    inputSize="XS"
                    onChangeSelected={(option) => {
                      if (option?.value === 'true') {
                        return onChange('true');
                      } else {
                        return onChange('false');
                      }
                    }}
                    typeDropDown="Default"
                    labelText="Tall de carril"
                    placeholder="Selecciona"
                    defaultValue={defaultValues?.editionLaneCutting}
                    borderError={!!errors?.editionLaneCutting}
                    options={options}
                    isDisabled={
                      initialData?.parentExpeditionOrderStatus ===
                        OrderState.Annulled ||
                      initialData?.parentExpeditionOrderStatus ===
                        OrderState.End ||
                      initialData?.state === OrderState.Annulled
                    }
                  />
                );
              }}
            />
          </Row>
          <Row>
            <Controller
              name="editionCapitol"
              control={control}
              render={({
                field: { onChange },
                formState: { defaultValues },
              }) => {
                const options =
                  defaultValues?.capitols?.map((capitol) => ({
                    label: capitol?.name || '',
                    value: capitol?.id || '',
                    data: capitol?.subCapitolsIDs || [],
                  })) || [];
                return (
                  <DropDownInput
                    labelText="Capitol"
                    placeholder={'Selecciona'}
                    inputSize={'L'}
                    options={options}
                    typeDropDown={'Default'}
                    onChangeSelected={(option) =>
                      onSelectCapitol(option, onChange)
                    }
                    ref={capitolDropDownRef}
                    defaultValue={defaultValues?.editionCapitol}
                    borderError={!!errors.editionCapitol}
                    isDisabled={
                      initialData?.parentExpeditionOrderStatus ===
                        OrderState.Annulled ||
                      initialData?.parentExpeditionOrderStatus ===
                        OrderState.End ||
                      initialData?.state === OrderState.Annulled
                    }
                  />
                );
              }}
            />
            <Controller
              name="editionSubCapitol"
              control={control}
              render={renderSubCapitols}
            />
          </Row>
        </RowsContainerLeft>
        <RowsContainerRight>
          <InputTextArea
            {...register('editionDescription')}
            disabled={
              initialData?.parentExpeditionOrderStatus ===
                OrderState.Annulled ||
              initialData?.parentExpeditionOrderStatus === OrderState.End ||
              initialData?.state === OrderState.Annulled
            }
            label={'Descripció validada'}
            borderError={!!errors.editionDescription}
          />
          {selectedImages.length === 0 ? (
            !isLoadingFiles ? (
              <AddImagesButton
                text="+ Afegir imatges"
                type="button"
                onClick={handleOpenModal}
                disabled={
                  initialData?.parentExpeditionOrderStatus ===
                    OrderState.Annulled ||
                  initialData?.parentExpeditionOrderStatus === OrderState.End ||
                  initialData?.state === OrderState.Annulled
                }
              />
            ) : (
              <Loader />
            )
          ) : (
            <ImagesContainer>
              <Typography size={isLandscapeTablet ? 'XS' : 'S'}>
                Imatges
              </Typography>
              <Row>
                <InputLoadImages
                  imageData={selectedImages}
                  removeItem={handleRemoveImage}
                  disabled={
                    initialData?.parentExpeditionOrderStatus ===
                      OrderState.Annulled ||
                    initialData?.parentExpeditionOrderStatus ===
                      OrderState.End ||
                    initialData?.state === OrderState.Annulled
                  }
                />
                <ButtonIconBackground
                  variant="add"
                  type="button"
                  onClick={handleOpenModal}
                  disabled={
                    initialData?.parentExpeditionOrderStatus ===
                      OrderState.Annulled ||
                    initialData?.parentExpeditionOrderStatus ===
                      OrderState.End ||
                    initialData?.state === OrderState.Annulled
                  }
                />
              </Row>
            </ImagesContainer>
          )}
          <HiddenInput
            type="file"
            accept="image/*"
            multiple
            ref={inputRef}
            onChange={handleChoosePick}
          />
        </RowsContainerRight>
      </InputsContainer>

      <ButtonContainer>
        <MainButton
          text={
            initialData?.parentExpeditionOrderStatus === OrderState.Annulled ||
            initialData?.parentExpeditionOrderStatus === OrderState.End ||
            initialData?.state === OrderState.Annulled
              ? 'Continuar'
              : textButton
          }
          type="submit"
          disabled={isLoadingFiles}
        />
      </ButtonContainer>
    </GeneralContainer>
  );
};

export default EditingActionForm;

// Libraries
import React from 'react';
import isEqual from 'lodash.isequal';
import { useSelector } from 'react-redux';

// Types
import { BreadCrumbsProps } from './types';

// Components
import { Typography } from '../../display/Typography';

// Components Styled
import { Container, Item, Link, RightButton } from './styles';

// State
import { getCoticOTSelectedGV } from '../../../state/selectors/ui/modal';

// Router
import { RouteUIElement } from '../../../router/labelToRoutes';
import { OrderType } from '../../../types/orders';

export const BreadCrumbs = ({ data = [] }: BreadCrumbsProps): JSX.Element => {
  const coticSelected = useSelector(getCoticOTSelectedGV, isEqual);
  if (data[data.length - 1].path === '') {
    data.pop();
  }

  if (data[data.length - 1].path === 'new-campaign') {
    data[3].pathAbsolute = data[2].pathAbsolute + '/' + data[3].path;
  }

  if (
    data[data.length - 2].path === 'summary' ||
    data[data.length - 2].path === 'new-communication-duplicate' ||
    data[data.length - 2].path === 'new-order-duplicate'
  ) {
    data = [
      ...data,
      (data[3].pathAbsolute = data[2].pathAbsolute + data[4].pathAbsolute),
    ] as RouteUIElement[];
    data.splice(data.length - 2, 2);
  }

  const breadCrumbContent = (
    <Typography size="S" colorText="greyDark">
      {coticSelected.cotic}
    </Typography>
  );

  const campaignContent = (
    <Typography size="S" colorText="greyDark">
      Campaign
    </Typography>
  );

  switch (coticSelected.type) {
    case OrderType.Generic: {
      if (coticSelected.cotic && data.length === 3) {
        data = [
          ...data,
          (data[2].content = breadCrumbContent),
        ] as RouteUIElement[];
        data.pop();
      }
      break;
    }
    case OrderType.Communication: {
      if (coticSelected.cotic && data.length === 4) {
        data = [
          ...data,
          (data[3].content = breadCrumbContent),
          (data[3].pathAbsolute = data[2].pathAbsolute + '/' + data[3].path),
        ] as RouteUIElement[];
        data.splice(data.length - 2, 2);
      }
      break;
    }
    case OrderType.Multimedia: {
      if (coticSelected.cotic && data.length === 4) {
        data = [
          ...data,
          (data[3].content = breadCrumbContent),
          (data[3].pathAbsolute = data[2].pathAbsolute + '/' + data[3].path),
        ] as RouteUIElement[];
        data.splice(data.length - 2, 2);
      }
      break;
    }
    case OrderType.Campaign: {
      if (coticSelected.cotic && data.length === 4) {
        data[3].content = breadCrumbContent;
        data[3].pathAbsolute = data[2].pathAbsolute + '/' + data[3].path;
      }
      break;
    }
    case 'edit-campaign': {
      if (coticSelected.cotic && data.length === 5) {
        data[4].content = breadCrumbContent;
        data[3].pathAbsolute =
          data[2].pathAbsolute + '/' + data[3].path + '/' + data[4].path;
        data[4].pathAbsolute = data[3].pathAbsolute;
      }
      if (coticSelected.cotic && data.length === 6) {
        data[4].content = breadCrumbContent;
        data[3].pathAbsolute =
          data[2].pathAbsolute + '/' + data[3].path + '/' + data[4].path;
        data[4].pathAbsolute = data[3].pathAbsolute;
        data[5].pathAbsolute = data[4].pathAbsolute + '/' + data[5].path;
      }
      break;
    }

    case 'edit-communication': {
      if (coticSelected.cotic && data.length === 5) {
        data[4].content = breadCrumbContent;
        data[3].pathAbsolute =
          data[2].pathAbsolute + '/' + data[3].path + '/' + data[4].path;
        data[4].pathAbsolute = data[3].pathAbsolute;
      }
      if (coticSelected.cotic && data.length === 6) {
        data[4].content = breadCrumbContent;
        data[3].pathAbsolute =
          data[2].pathAbsolute + '/' + data[3].path + '/' + data[4].path;
        data[4].pathAbsolute = data[3].pathAbsolute;
        data[5].pathAbsolute = data[4].pathAbsolute + '/' + data[5].path;
      }
      break;
    }
    case OrderType.Accident: {
      if (
        coticSelected.cotic &&
        data.length === 4 &&
        data.every((item) => item.path !== 'campaign')
      ) {
        data[3].pathAbsolute = data[2].pathAbsolute + '/' + data[3].path;
        if (data[1].path === 'work-orders' && data[2].path === 'accidents') {
          data[3].content = breadCrumbContent;
        } else {
          data[2].content = breadCrumbContent;
        }
      }

      if (
        coticSelected.cotic &&
        data.length === 5 &&
        data.every((item) => item.path !== 'campaign')
      ) {
        data[3].pathAbsolute = data[2].pathAbsolute + '/' + data[3].path;
        data[4].pathAbsolute = data[3].pathAbsolute + '/' + data[4].path;
        data[2].content = breadCrumbContent;
      }

      if (
        coticSelected.cotic &&
        data.length === 5 &&
        data.some((item) => item.path === 'campaign')
      ) {
        data[3].pathAbsolute = data[2].pathAbsolute + '/' + data[3].path;
        data[4].pathAbsolute = data[3].pathAbsolute + '/' + data[4].path;
        data[3].content = breadCrumbContent;
      }
      if (
        coticSelected.cotic &&
        data.length === 6 &&
        data.some((item) => item.path === 'campaign')
      ) {
        data[3].pathAbsolute = data[2].pathAbsolute + '/' + data[3].path;
        data[4].pathAbsolute = data[3].pathAbsolute + '/' + data[4].path;
        data[5].pathAbsolute = data[4].pathAbsolute + '/' + data[5].path;
        data[3].content = breadCrumbContent;
      }
      break;
    }

    case OrderType.Action: {
      if (
        coticSelected.cotic &&
        data.length >= 4 &&
        data.every((item) => item.path !== 'campaign')
      ) {
        data[3].pathAbsolute = data[2].pathAbsolute + '/' + data[3].path;
        data[2].content = breadCrumbContent;
      }

      if (
        coticSelected.cotic &&
        data.length === 5 &&
        data.every((item) => item.path !== 'campaign')
      ) {
        data[4].pathAbsolute =
          data[3].pathAbsolute + '/' + data[4].path + '/' + coticSelected.id;
      }

      if (
        coticSelected.cotic &&
        data.length === 6 &&
        data.every((item) => item.path !== 'campaign')
      ) {
        data[4].pathAbsolute =
          data[3].pathAbsolute + '/details/' + data[4].path;
        data[5].pathAbsolute =
          data[3].pathAbsolute + '/details/' + data[5].path;
      }

      if (coticSelected.cotic && data.length === 7) {
        data[2].content = campaignContent;
        data[3].content = breadCrumbContent;
        data[4].pathAbsolute = data[3].pathAbsolute + '/' + data[4].path;
        data[5].pathAbsolute = data[4].pathAbsolute + '/' + data[5].path;
        data[6].pathAbsolute = data[5].pathAbsolute + '/' + data[6].path;
      }

      // For campaigns and actions
      if (
        coticSelected.cotic &&
        data.length === 5 &&
        data.some((item) => item.path === 'campaign')
      ) {
        data[3].pathAbsolute = data[2].pathAbsolute + '/' + data[3].path;
        data[4].pathAbsolute = data[3].pathAbsolute + '/' + data[4].path;
        data[3].content = breadCrumbContent;
      }

      if (
        coticSelected.cotic &&
        data.length === 6 &&
        data.some((item) => item.path === 'campaign')
      ) {
        data[2].content = campaignContent;
        data[3].content = breadCrumbContent;
        data[3].pathAbsolute = data[2].pathAbsolute + '/' + data[3].path;
        data[4].pathAbsolute = data[3].pathAbsolute + '/' + data[4].path;
        data[5].pathAbsolute =
          data[4].pathAbsolute + '/' + data[5].path + '/' + coticSelected.id;
      }

      if (
        coticSelected.cotic &&
        data.length === 7 &&
        data.some((item) => item.path === 'campaign')
      ) {
        data[3].pathAbsolute = data[2].pathAbsolute + '/' + data[3].path;
      }
      break;
    }
    case 'actions-validation': {
      if (coticSelected.cotic && data.length === 4) {
        data[3].content = breadCrumbContent;
      }

      if (coticSelected.cotic && data.length === 5) {
        data[3].pathAbsolute = data[2].pathAbsolute + '/' + data[3].path;
        data[4].pathAbsolute = data[3].pathAbsolute + '/' + data[4].path;
        data[3].content = breadCrumbContent;
      }
      break;
    }
    default: {
      if (coticSelected.cotic && data.length === 3) {
        data = [
          ...data,
          (data[2].content = breadCrumbContent),
        ] as RouteUIElement[];
        data.pop();
      }
    }
  }

  return (
    <Container>
      {data.map((breadcrumb, index) => {
        return (
          <Item key={index.toString()}>
            <Link to={breadcrumb.pathAbsolute}>{breadcrumb.content}</Link>
            {index !== data.length - 1 && <RightButton />}
          </Item>
        );
      })}
    </Container>
  );
};

import React, { useCallback, useState } from 'react';
import { useAsyncValue, useNavigate, useRevalidator } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

// Components
import { InputRadioArrowTable } from '../../../../../components/display/tables/InputRadioArrowTable';
import { MainButton } from '../../../../../components/inputs/buttons/MainButton';

// Types
import { dataTypes } from '../../../../../components/display/tables/InputRadioArrowTable/types';
import {
  tableIds,
  takeBase,
} from '../../../../../components/display/tables/types';

// Styles
import { Container } from './styles';
import { ContainerButton } from '../../components/MainStyledComponents';

// GQL
import { client } from '../../../../../gql/client';
import { FETCH_COMMUNICATIONS } from '../../../../../gql/queries/communication';

// Redux
import { getCursor } from '../../../../../state/selectors/ui/tables';
import { selectCommunicationOrders } from '../../../../../gql/selectors/communications';
import { setCursor } from '../../../../../state/actions/ui/cursorTables';

export const CommunicationsPage = () => {
  const data = useAsyncValue() as dataTypes;
  const [selectedRowId, setSelectedRowId] = useState<string>('');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const revalidator = useRevalidator();

  const selectorCursor = useSelector(
    getCursor(tableIds.WORKORDER_COMMUNICATIONS)
  );

  const handleSeeCommunication = useCallback(() => {
    navigate(`/work-orders/communications/${selectedRowId}`);
  }, [selectedRowId]);

  const onReFetchData = useCallback(async () => {
    const communications = await client.query({
      query: FETCH_COMMUNICATIONS,
      variables: {
        input: {
          take: takeBase,
          cursor: '',
          orderBy: 'desc',
          roadId: selectorCursor.roadId,
          concessionId: selectorCursor.concessionId,
          interlocutorGroupId: selectorCursor.interlocutorGroupId,
          fromDate: selectorCursor.fromDate,
          toDate: selectorCursor.toDate,
          indexType: selectorCursor.indexType,
          indexExpedition: selectorCursor.indexExpedition,
          classification: selectorCursor.classification,
          state: selectorCursor.state,
        },
      },
      fetchPolicy: 'network-only',
    });

    const setCursorData = {
      take: takeBase,
      cursor: '',
      orderBy: 'desc',
      roadId: selectorCursor?.roadId,
      concessionId: selectorCursor?.concessionId,
      interlocutorGroupId: selectorCursor?.interlocutorGroupId,
      fromDate: selectorCursor?.fromDate,
      toDate: selectorCursor?.toDate,
      indexType: selectorCursor?.indexType,
      indexExpedition: selectorCursor?.indexExpedition,
      classification: selectorCursor?.classification,
      state: selectorCursor?.state,
    };

    dispatch(setCursor(tableIds.WORKORDER_COMMUNICATIONS, setCursorData));
    await selectCommunicationOrders(
      communications.data.communications.communications,
      true
    );

    revalidator.revalidate();
  }, [selectorCursor]);

  const onRefetchPagination = useCallback(async () => {
    revalidator.revalidate();
  }, []);
  return (
    <Container>
      <InputRadioArrowTable
        titleText="Filtrar Comunicacions per:"
        data={data}
        setRowState={setSelectedRowId}
        onReFetchData={onReFetchData}
        onReFetchPagination={onRefetchPagination}
        tableId={tableIds.WORKORDER_COMMUNICATIONS}
      />
      <ContainerButton>
        <MainButton
          text="Veure comunicació"
          onClick={handleSeeCommunication}
          disabled={selectedRowId.length === 0}
        />
      </ContainerButton>
    </Container>
  );
};

import { gql } from '@apollo/client';

export const CREATE_NEW_MATERIAL = gql`
  mutation CreateMaterial($input: NewMaterial!) {
    createMaterial(input: $input) {
      id
      materialId
      name
      measureTypeIDs
      codificationIDs
      subCodificationIDs
    }
  }
`;

// Sentry
import * as Sentry from '@sentry/react';

// GQL
import { client } from '../client';
import { GET_CONCESSIONS, GET_ORDER_BY_ID } from '../queries/orders/orders';
import {
  FETCH_CAPITOLS,
  FETCH_CLASSIFICATIONS,
  FETCH_SUB_CAPITOLS,
  GET_CAPITOL_BY_ID,
  GET_CLASSIFICATION_BY_ID_OR_COTIC,
  GET_SUB_CAPITOL_BY_ID,
} from '../queries/orders/predefinedData';
import { GET_CAMPAIGN_BY_ID } from '../queries/campaign';
import { GET_GENERIC_ORDER_BY_ID } from '../queries/genericOrders';
import {
  GET_CONCESSION_BY_ID,
  GET_ROAD_BY_ID,
  GET_ROADS,
} from '../queries/geoPositions/roads';
import { GET_TEAM_BY_ID, GET_USER_BY_ID } from '../queries/users';

//Types
import { OrderType } from '../../types/orders';
import { OperatorType } from '../../components/display/ShowOT/types';

// Utils
import { groupLanes, groupTracks } from '../../data/orders';
import { pkFormatter } from '../../utils/transform';
import { GET_ACTION_BY_ID } from '../queries/actions/actions';

export const selectOrderByIdForFillFormData = async (
  orderId: string,
  isCampaign = false
) => {
  try {
    let dataFromOrderDependsOnType;
    const {
      data: { order },
    } = await client.query({
      query: GET_ORDER_BY_ID,
      variables: { id: orderId },
    });

    if (isCampaign) {
      const {
        data: { campaign },
      } = await client.query({
        query: GET_CAMPAIGN_BY_ID,
        variables: {
          id: order.campaignIDs[0],
        },
      });
      dataFromOrderDependsOnType = campaign;
    } else {
      const {
        data: { genericOrder },
      } = await client.query({
        query: GET_GENERIC_ORDER_BY_ID,
        variables: {
          id: order.genericOrdersIDs[0],
        },
      });
      dataFromOrderDependsOnType = genericOrder;
    }
    const [
      {
        data: { concession },
      },
      {
        data: { road },
      },
    ] = await Promise.all([
      client.query({
        query: GET_CONCESSION_BY_ID,
        variables: {
          id: dataFromOrderDependsOnType.concessionIDs[0],
        },
      }),
      client.query({
        query: GET_ROAD_BY_ID,
        variables: {
          id: dataFromOrderDependsOnType.roadIDs[0],
        },
      }),
    ]);

    return {
      id: order.id,
      cotic: order.cotic.split('.'),
      registerInitDate: order.registerInitDate,
      pkInit: pkFormatter(
        dataFromOrderDependsOnType.pkInitKm,
        dataFromOrderDependsOnType.pkInitMeter
      ),
      pkEnd: pkFormatter(
        dataFromOrderDependsOnType.pkEndKm,
        dataFromOrderDependsOnType.pkEndMeter
      ),
      description: order.description,
      capitol: order.capitolIDs[0],
      subCapitol: order.subCapitolIDs[0],
      classification: order.classificationIDs[0],
      team: dataFromOrderDependsOnType?.teamIDs[0],
      margin: dataFromOrderDependsOnType.margin,
      track: dataFromOrderDependsOnType.track,
      lane: dataFromOrderDependsOnType.lane,
      concession: concession.id,
      city: concession.location,
      pkInitRoad: pkFormatter(road.pkInitKm, road.pkInitMeter, true),
      pkEndRoad: pkFormatter(road.pkEndKm, road.pkEndMeter, true),
      road: dataFromOrderDependsOnType.roadIDs[0],
      direction: dataFromOrderDependsOnType.direction,
      campaign: isCampaign ? dataFromOrderDependsOnType.name : '',
      modelTypeId: isCampaign ? order.campaignIDs[0] : '',
      userId: order.userId,
    };
  } catch (err) {
    Sentry.captureException(err);
    console.log('> Error getting the work order data', err);
  }
};

export const selectWorkOrderWithChildren = async (
  onlyOrder = true,
  idOrder: string
) => {
  try {
    let orders = [];

    const actions: {
      id: string;
      cotic: string;
      tag: string;
      type: string;
      date: string;
      endDate: string;
      creationDate?: string;
      operators: OperatorType[];
      laneCutting: string;
      capitols: string;
      capitol: string;
      description: string;
      registerInitDate?: string;
      isTransferred: boolean;
      state: string;
    }[] = [];

    const accidents: {
      id: string;
      cotic: string;
      tag: string;
      type: string;
      date: string;
      creationDate: string;
      capitols: string;
      capitol: string;
      subCapitolsValues: string;
      subCapitol: string;
      description: string;
      isTransferred: boolean;
      state: string;
    }[] = [];

    const communications: {
      id: string;
      cotic: string;
      tag: string;
      type: string;
      date: string;
      creationDate: string;
      capitols: string;
      capitol: string;
      subCapitolsValues: string;
      subCapitol: string;
      description: string;
      isTransferred: boolean;
      state: string;
    }[] = [];

    const [
      {
        data: { order },
      },
      {
        data: { capitols },
      },
      {
        data: { concessions },
      },
      {
        data: { roads },
      },
      {
        data: { subCapitols },
      },
      {
        data: { classifications },
      },
    ] = await Promise.all([
      client.query({
        query: GET_ORDER_BY_ID,
        variables: { id: idOrder },
      }),
      client.query({
        query: FETCH_CAPITOLS,
      }),
      client.query({
        query: GET_CONCESSIONS,
      }),
      client.query({
        query: GET_ROADS,
      }),
      client.query({
        query: FETCH_SUB_CAPITOLS,
      }),
      client.query({
        query: FETCH_CLASSIFICATIONS,
      }),
    ]);

    if (onlyOrder) {
      switch (order.type) {
        case OrderType.Generic: {
          const {
            data: { genericOrder },
          } = await client.query({
            query: GET_GENERIC_ORDER_BY_ID,
            variables: {
              id: order.genericOrdersIDs[0],
            },
          });

          const {
            data: { classification },
          } = await client.query({
            query: GET_CLASSIFICATION_BY_ID_OR_COTIC,
            variables: {
              id: order.classificationIDs[0],
            },
          });

          orders.push({
            id: order.id,
            cotic: order.cotic,
            tag: order.state,
            type: order.type,
            capitols: capitols,
            concessions: concessions,
            roadsValues: roads,
            road: genericOrder.roadIDs[0],
            capitol: order.capitolIDs[0],
            subCapitol: order.subCapitolIDs[0],
            subCapitolsValues: subCapitols,
            concession: genericOrder.concessionIDs[0],
            pkInit: pkFormatter(
              genericOrder.pkInitKm,
              genericOrder.pkInitMeter
            ),
            lane: genericOrder.lane,
            direction: genericOrder.direction,
            margin: genericOrder.margin,
            track: genericOrder.track,
            pkEnd: pkFormatter(genericOrder.pkEndKm, genericOrder.pkEndMeter),
            date: order.registerInitDate,
            description: order.description,
            modelTypeId: order.genericOrdersIDs[0],
            indexExpedition: order.indexExpedition,
            state: order.state,
            classification: classification.id,
            classifications: classifications,
          });
          break;
        }
        case OrderType.Campaign: {
          const {
            data: { campaign },
          } = await client.query({
            query: GET_CAMPAIGN_BY_ID,
            variables: {
              id: order.campaignIDs[0],
            },
          });

          const {
            data: { classification },
          } = await client.query({
            query: GET_CLASSIFICATION_BY_ID_OR_COTIC,
            variables: {
              id: order.classificationIDs[0],
            },
          });

          orders.push({
            id: order.id,
            name: campaign.name,
            cotic: order.cotic,
            tag: order.state,
            type: order.type,
            capitols: capitols,
            concessions: concessions,
            roadsValues: roads,
            road: campaign.roadIDs[0],
            capitol: order.capitolIDs[0],
            subCapitol: order.subCapitolIDs[0],
            subCapitolsValues: subCapitols,
            concession: campaign.concessionIDs[0],
            pkInit: pkFormatter(campaign.pkInitKm, campaign.pkInitMeter),
            pkEnd: pkFormatter(campaign.pkEndKm, campaign.pkEndMeter),
            lane: campaign.lane,
            direction: campaign.direction,
            margin: campaign.margin,
            track: campaign.track,
            date: order.registerInitDate,
            description: order.description,
            modelTypeId: order.campaignIDs[0],
            indexExpedition: order.indexExpedition,
            state: order.state,
            classification: classification.id,
            classifications: classifications,
          });
          break;
        }
      }
    }

    await Promise.all(
      order?.childExpeditionOrderIDs.map(async (orderId: string) => {
        const {
          data: { order },
        } = await client.query({
          query: GET_ORDER_BY_ID,
          variables: { id: orderId },
        });
        if (order) {
          switch (order.type) {
            case OrderType.Action: {
              if (order?.actionIDs?.[0]) {
                const {
                  data: { action },
                } = await client.query({
                  query: GET_ACTION_BY_ID,
                  variables: { id: order.actionIDs[0] },
                });
                const users = await Promise.all(
                  action.userIDs.map(async (userId: string) =>
                    client.query({
                      query: GET_USER_BY_ID,
                      variables: {
                        input: {
                          id: userId,
                        },
                      },
                    })
                  )
                );
                actions.push({
                  id: order.id,
                  cotic: order.cotic,
                  tag: order.state,
                  type: order.type,
                  date: order.registerInitDate,
                  endDate: order.registerEndDate,
                  creationDate: order.creationDate,
                  operators: users.map((user) => user.data.user),
                  laneCutting: action.laneCutting,
                  capitols: capitols,
                  capitol: order.capitolIDs[0],
                  description: order.description,
                  isTransferred: order.isTransferred,
                  state: order.state,
                });
              }

              break;
            }
            case OrderType.Accident: {
              accidents.push({
                id: order.id,
                cotic: order.cotic,
                tag: order.state,
                type: order.type,
                date: order.registerInitDate,
                creationDate: order.creationDate,
                capitols: capitols,
                capitol: order.capitolIDs[0],
                subCapitol: order.subCapitolIDs[0],
                subCapitolsValues: subCapitols,
                description: order.description,
                isTransferred: order.isTransferred,
                state: order.state,
              });
              break;
            }
            case OrderType.Communication: {
              communications.push({
                id: order.id,
                cotic: order.cotic,
                tag: order.state,
                type: order.type,
                date: order.registerInitDate,
                creationDate: order.creationDate,
                capitols: capitols,
                capitol: order.capitolIDs[0],
                subCapitol: order.subCapitolIDs[0],
                subCapitolsValues: subCapitols,
                description: order.description,
                isTransferred: order.isTransferred,
                state: order.state,
              });
              break;
            }
          }
        }
      })
    );

    if (
      actions.length > 0 ||
      accidents.length > 0 ||
      communications.length > 0
    ) {
      const joinedOrders = [...actions, ...accidents, ...communications];
      joinedOrders.sort(
        (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
      );
      orders = [...orders, ...joinedOrders];
    }

    return orders;
  } catch (err) {
    Sentry.captureException(err);
    console.log(err);
  }
};

// To fill tables
export const selectAllOrders = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  genericOrCampaignOrders: any[] = [],
  reFetch = false
) => {
  return Promise.all(
    genericOrCampaignOrders.map(async (genericOrCampaignOrder) => {
      const {
        data: { order },
      } = await client.query({
        query: GET_ORDER_BY_ID,
        variables: {
          id: genericOrCampaignOrder.orderIDs[0],
        },
        fetchPolicy: reFetch ? 'network-only' : 'cache-first',
      });

      const [
        {
          data: { capitol },
        },
        {
          data: { subCapitol },
        },
        {
          data: { classification },
        },
        {
          data: { road },
        },
        {
          data: { concession },
        },
      ] = await Promise.all([
        client.query({
          query: GET_CAPITOL_BY_ID,
          variables: {
            id: order.capitolIDs[0],
          },
        }),
        client.query({
          query: GET_SUB_CAPITOL_BY_ID,
          variables: {
            id: order.subCapitolIDs[0],
          },
        }),
        client.query({
          query: GET_CLASSIFICATION_BY_ID_OR_COTIC,
          variables: {
            id: order.classificationIDs[0],
          },
        }),
        client.query({
          query: GET_ROAD_BY_ID,
          variables: {
            id: genericOrCampaignOrder.roadIDs[0],
          },
        }),
        client.query({
          query: GET_CONCESSION_BY_ID,
          variables: {
            id: genericOrCampaignOrder.concessionIDs[0],
          },
        }),
      ]);
      return {
        id: order.id,
        name: genericOrCampaignOrder?.name,
        cotic: order.cotic.split('.'),
        creationDate: order.creationDate,
        registerInitDate: order.registerInitDate,
        capitol: capitol.name,
        subCapitol: subCapitol.name,
        classification: classification.name,
        concession: concession.name,
        road: road.name,
        pkInit: pkFormatter(
          genericOrCampaignOrder.pkInitKm,
          genericOrCampaignOrder.pkInitMeter
        ),
        pkEnd: pkFormatter(
          genericOrCampaignOrder.pkEndKm,
          genericOrCampaignOrder.pkEndMeter
        ),
        state: order.state,
        description: order.description,
        genericOrCampaignOrderID: genericOrCampaignOrder.id,
      };
    })
  );
};

// Summary data
export const selectWorkOrderById = async (
  orderId: string,
  isCampaign = false
) => {
  let dataFromOrderDependsOnType: {
    concessionIDs: string[];
    roadIDs: string[];
    teamIDs?: string[];
    pkInit: number;
    pkInitKm: number;
    pkInitMeter: number;
    pkEnd: number;
    pkEndKm: number;
    pkEndMeter: number;
    track: string;
    direction: string;
    margin: string;
    lane: string;
    name: string;
  };

  const {
    data: { order },
  } = await client.query({
    query: GET_ORDER_BY_ID,
    variables: {
      id: orderId,
    },
  });

  if (isCampaign) {
    const {
      data: { campaign },
    } = await client.query({
      query: GET_CAMPAIGN_BY_ID,
      variables: {
        id: order.campaignIDs[0],
      },
    });
    dataFromOrderDependsOnType = campaign;
  } else {
    const {
      data: { genericOrder },
    } = await client.query({
      query: GET_GENERIC_ORDER_BY_ID,
      variables: {
        id: order.genericOrdersIDs[0],
      },
    });

    dataFromOrderDependsOnType = genericOrder;
  }
  let teamValue = null;
  if (dataFromOrderDependsOnType?.teamIDs?.[0]) {
    const {
      data: { team },
    } = await client.query({
      query: GET_TEAM_BY_ID,
      variables: {
        id: dataFromOrderDependsOnType.teamIDs[0],
      },
    });

    teamValue = team;
  }

  const [
    {
      data: { concession },
    },
    {
      data: { road },
    },
  ] = await Promise.all([
    client.query({
      query: GET_CONCESSION_BY_ID,
      variables: {
        id: dataFromOrderDependsOnType.concessionIDs[0],
      },
    }),
    client.query({
      query: GET_ROAD_BY_ID,
      variables: {
        id: dataFromOrderDependsOnType.roadIDs[0],
      },
    }),
  ]);

  const [
    {
      data: { capitol },
    },
    {
      data: { subCapitol },
    },
    {
      data: { classification },
    },
  ] = await Promise.all([
    client.query({
      query: GET_CAPITOL_BY_ID,
      variables: {
        id: order.capitolIDs[0],
      },
    }),
    client.query({
      query: GET_SUB_CAPITOL_BY_ID,
      variables: {
        id: order.subCapitolIDs[0],
      },
    }),
    client.query({
      query: GET_CLASSIFICATION_BY_ID_OR_COTIC,
      variables: {
        id: order.classificationIDs[0],
      },
    }),
  ]);

  return {
    id: order.id,
    cotic: order.cotic.split('.'),
    registerInitDate: order.registerInitDate,
    concession: concession.name,
    road: road.name,
    pkInit: pkFormatter(
      dataFromOrderDependsOnType.pkInitKm,
      dataFromOrderDependsOnType.pkInitMeter
    ),
    pkEnd: pkFormatter(
      dataFromOrderDependsOnType.pkEndKm,
      dataFromOrderDependsOnType.pkEndMeter
    ),
    description: order.description,
    capitol: capitol.name,
    subCapitol: subCapitol.name,
    classification: classification.name,
    track: groupTracks?.find(
      (staticTrack) => staticTrack.value === dataFromOrderDependsOnType.track
    )?.name,
    roadDirections: road.directions,
    direction: dataFromOrderDependsOnType?.direction,
    margin: dataFromOrderDependsOnType?.margin,
    lane: groupLanes.find(
      (lane) => lane.value === dataFromOrderDependsOnType.lane
    )?.name,
    team: teamValue?.name,
    city: concession.location,
    pkInitRoad: pkFormatter(road.pkInitKm, road.pkInitMeter, true),
    pkEndRoad: pkFormatter(road.pkEndKm, road.pkEndMeter, true),
    campaign: isCampaign && dataFromOrderDependsOnType.name,
    userId: order.userId,
  };
};

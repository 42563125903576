// Dependencies
import styled, { css, keyframes } from 'styled-components';

type BackDropTypes = {
  visible: boolean;
};

type AnimatedTypes = {
  isOpen: boolean;
};

export const Backdrop = styled.div<BackDropTypes>`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100000;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(12px);
  transition: all 150ms linear;
  background: rgba(0, 0, 0, 0.3);
  overflow: auto;
  max-height: 100%;

  ${({ visible }) => `
    opacity: ${visible ? '1' : '0'};
    visibility: ${visible ? 'visible' : 'hidden'};
  `}
`;

const display = keyframes`
  from {
    transform: scale(0.9);
    visibility: hidden;
    opacity: 0;
  }
  to {
    transform: scale(1);
    visibility: visible;
    opacity: 1;
  }
`;

export const AnimatedModal = styled.div<AnimatedTypes>`
  ${(props) =>
    props.isOpen &&
    css`
      animation: ${display} 0.3s ease-in-out;
    `}
`;

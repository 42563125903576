import { gql } from '@apollo/client';

export const FETCH_ASSETS = gql`
  query FetchAssets {
    assets {
      id
      code
      actionIDs
    }
  }
`;

export const GET_ASSET_BY_ID = gql`
  query GetAssetById($id: ID!) {
    asset(id: $id) {
      id
      code
      actionIDs
    }
  }
`;

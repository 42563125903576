// Types
import { dataTable } from './types';

export const mockData: dataTable[] = [
  {
    id: '1',
    auxMachineId: 'M00001',
    description: 'LLoguer d’equip de serra de disc de dia',
    type: 'Maq. auxiliar O.P.',
    typeOfMeasurement: 'Kg',
  },
  {
    id: '2',
    auxMachineId: 'M00002',
    description: 'Màquina retroexcavadora',
    type: 'Maq. auxiliar',
    typeOfMeasurement: 'Kg',
  },
  {
    id: '3',
    auxMachineId: 'M00003',
    description: 'Grua de carretera',
    type: 'Maq. auxiliar O.P.',
    typeOfMeasurement: 'Kg',
  },
  {
    id: '4',
    auxMachineId: 'M00004',
    description: 'Dumper de càrrega frontal',
    type: 'Maq. auxiliar',
    typeOfMeasurement: 'Kg',
  },
  {
    id: '5',
    auxMachineId: 'M00005',
    description: 'Pala cargadora',
    type: 'Maq. auxiliar',
    typeOfMeasurement: 'Kg',
  },
  {
    id: '6',
    auxMachineId: 'M00006',
    description: 'Compactadora de terra',
    type: 'Maq. auxiliar',
    typeOfMeasurement: 'Kg',
  },
  {
    id: '7',
    auxMachineId: 'M00007',
    description: 'Asfaltadora',
    type: 'Maq. auxiliar',
    typeOfMeasurement: 'Kg',
  },
  {
    id: '8',
    auxMachineId: 'M00008',
    description: 'Fresadora de carreteres',
    type: 'Maq. auxiliar',
    typeOfMeasurement: 'Kg',
  },
];

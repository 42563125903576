import React from 'react';

type DirectionBothProps = {
  className?: string;
};

export const DirectionBoth = ({
  className,
}: DirectionBothProps): JSX.Element => {
  return (
    <svg
      width="33"
      height="20"
      viewBox="0 0 33 20"
      fill="black"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M21.25 9.49428L21.25 9.24428L21 9.24428L5.07564 9.24428L9.38464 4.93527C9.87942 4.4405 9.87942 3.63831 9.38464 3.14353C8.88987 2.64876 8.08768 2.64876 7.5929 3.14353L1.12108 9.61536C0.626306 10.1101 0.626306 10.9123 1.12108 11.4071L7.59291 17.8789C8.08768 18.3737 8.88987 18.3737 9.38464 17.8789C9.87942 17.3841 9.87942 16.582 9.38464 16.0872L5.07564 11.7782L21 11.7782L21.25 11.7782L21.25 11.5282L21.25 9.49428Z" />
      <path d="M11.2578 11.5057L11.2578 11.7557L11.5078 11.7557L27.4322 11.7557L23.1232 16.0647C22.6284 16.5595 22.6284 17.3617 23.1232 17.8565C23.6179 18.3512 24.4201 18.3512 24.9149 17.8565L31.3867 11.3846C31.8815 10.8899 31.8815 10.0877 31.3867 9.59291L24.9149 3.12108C24.4201 2.62631 23.6179 2.62631 23.1232 3.12108C22.6284 3.61586 22.6284 4.41805 23.1232 4.91282L27.4322 9.22183L11.5078 9.22183L11.2578 9.22183L11.2578 9.47183L11.2578 11.5057Z" />
    </svg>
  );
};

import { object, string, ref } from 'yup';
import { ResetPasswordFormType } from './types';
import { REGEXP_PASSWORD } from '../../../utils/validations';

export const ResetPasswordFormSchema = object<ResetPasswordFormType>({
  newPassword: string().matches(
    REGEXP_PASSWORD,
    () => 'revisa la contrasenya, no està complint amb el format'
  ),
  repeatPassword: string()
    .matches(
      REGEXP_PASSWORD,
      () => 'revisa la contrasenya, no està complint amb el format'
    )
    .oneOf(
      [ref('newPassword')],
      () => 'la nova contrasenya i la contrasenya repetida han de coincidir'
    ),
});

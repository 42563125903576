/**
 * This Fallback component should be used when a `loader` property is passed to a Router and
 * it's expected to take a lot of time loading, so blank pages are prevented by adding a fallback element.
 * - The `dependsOn` prop is the value of the loader object returned that we want to use in the component wrapped.
 */

import React from 'react';
import { Await, useLoaderData } from 'react-router-dom';

import { Loader } from '../../inputs/Loader';

export default function Fallback({
  children,
  dependsOn,
}: {
  children: React.ReactNode;
  dependsOn: string;
}): JSX.Element {
  const loaderData = useLoaderData() as Record<string, unknown>;

  return (
    // Substitute the fallback with a proper loader or spinner element
    <React.Suspense fallback={<Loader />}>
      <Await resolve={loaderData[dependsOn]}>{children}</Await>
    </React.Suspense>
  );
}

// Dependencies
import React, { useState, useRef, useCallback } from 'react';
import { useClickAway } from 'react-use';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

//Types
import { DropDownProps } from './types';
import {
  ImperativeRefList,
  onChangeValue,
  OptionSelected,
} from '../DropDownList/types';

// Styled Components
import {
  Container,
  DropDownHeader,
  DropDownContainer,
  SelectedOption,
  ArrowDown,
} from './styles';

//Components
import DropDownList from '../DropDownList';

// Redux
import { coticOTSelectedReset } from '../../../../state/actions/ui/coticOTSelectedGeneralView';

export const DropDownSelect = ({
  defaultOption,
  options,
  ...props
}: DropDownProps): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const listRef = useRef<ImperativeRefList>(null);

  const containerRef = useRef<HTMLDivElement>(null);

  useClickAway(containerRef, () => {
    listRef.current?.setHidden();
  });

  const [selectedOption, setSelectedOption] = useState<
    OptionSelected | undefined
  >(options.find((element) => element.value === defaultOption));

  const clickedOption = useCallback(() => {
    listRef.current?.setVisibleDropDown();
  }, []);

  const setSelectedAndCallback = useCallback<onChangeValue>((value) => {
    setSelectedOption(value);
    if (value) {
      dispatch(coticOTSelectedReset());
      navigate('/' + value.value);
    }
  }, []);

  return (
    <Container ref={containerRef} {...props} onClick={clickedOption}>
      <DropDownContainer>
        <DropDownHeader>
          <SelectedOption variant="semiBold">
            {selectedOption?.label}
          </SelectedOption>
        </DropDownHeader>
        <ArrowDown />
      </DropDownContainer>
      <DropDownList
        typeDropDown="Select"
        selectedOption={selectedOption}
        setSelectedAndCallback={setSelectedAndCallback}
        options={options}
        ref={listRef}
      />
    </Container>
  );
};

import styled from 'styled-components';

// Styles generals
import { colors } from '../../../../../styles/colors';
import {
  breakPoints,
  percentBaseResponsive,
  spaces,
} from '../../../../../styles/spaces';
import { ArrowDirectionButton } from '../../../../../components/inputs/buttons/ArrowDirectionButton';

// Components
import { SpecialTag } from '../../../../../components/display/SpecialTag';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spaces.l}rem;
  width: 100%;
  padding: ${spaces.xxl}rem;
  background: ${colors.verySoftRed};
  border-radius: 1rem;
  position: relative;
  @media (max-width: ${breakPoints.landscapeTablet *
    percentBaseResponsive}rem) {
    padding: ${spaces.xl}rem;
  }
`;

export const ArrowRadioGroup = styled(ArrowDirectionButton)``;

export const DuplicateTag = styled(SpecialTag)`
  position: absolute;
  right: ${spaces.xxl}rem;
  cursor: pointer;
  z-index: 1;
  @media (max-width: ${breakPoints.portraitTablet * percentBaseResponsive}rem) {
    position: initial;
  }
`;

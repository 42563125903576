import React from 'react';

type VisibilityIconProps = {
  className?: string;
};

export const VisibilityIcon = ({ className }: VisibilityIconProps) => {
  return (
    <svg
      width="16"
      height="11"
      viewBox="0 0 16 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M7.83984 8.13158C8.60658 8.13158 9.25723 7.87616 9.79179 7.36532C10.3263 6.85449 10.5936 6.23271 10.5936 5.5C10.5936 4.76729 10.3263 4.14551 9.79179 3.63467C9.25723 3.12384 8.60658 2.86842 7.83984 2.86842C7.0731 2.86842 6.42246 3.12384 5.8879 3.63467C5.35334 4.14551 5.08606 4.76729 5.08606 5.5C5.08606 6.23271 5.35334 6.85449 5.8879 7.36532C6.42246 7.87616 7.0731 8.13158 7.83984 8.13158ZM7.83984 6.98607C7.40788 6.98607 7.04071 6.84159 6.73833 6.55263C6.43596 6.26367 6.28477 5.9128 6.28477 5.5C6.28477 5.0872 6.43596 4.73633 6.73833 4.44737C7.04071 4.15841 7.40788 4.01393 7.83984 4.01393C8.27181 4.01393 8.63898 4.15841 8.94135 4.44737C9.24373 4.73633 9.39492 5.0872 9.39492 5.5C9.39492 5.9128 9.24373 6.26367 8.94135 6.55263C8.63898 6.84159 8.27181 6.98607 7.83984 6.98607ZM7.83984 10.5C6.17678 10.5 4.6757 10.0356 3.3366 9.10681C1.99751 8.17802 0.998591 6.97575 0.339844 5.5C0.998591 4.02425 1.99751 2.82198 3.3366 1.89319C4.6757 0.964396 6.17678 0.5 7.83984 0.5C9.50291 0.5 11.004 0.964396 12.3431 1.89319C13.6822 2.82198 14.6811 4.02425 15.3398 5.5C14.6811 6.97575 13.6822 8.17802 12.3431 9.10681C11.004 10.0356 9.50291 10.5 7.83984 10.5ZM7.83984 9.21517C9.13574 9.21517 10.329 8.87719 11.4198 8.20124C12.5105 7.52528 13.342 6.62487 13.9144 5.5C13.342 4.37513 12.5132 3.47472 11.4279 2.79876C10.3425 2.12281 9.14654 1.78483 7.83984 1.78483C6.54395 1.78483 5.35064 2.12281 4.25993 2.79876C3.16922 3.47472 2.33228 4.37513 1.74913 5.5C2.33228 6.62487 3.16652 7.52528 4.25183 8.20124C5.33714 8.87719 6.53315 9.21517 7.83984 9.21517Z"
        fill="white"
      />
    </svg>
  );
};

import { AnimalsType } from './types';

export const mockData: AnimalsType[] = [
  {
    codiAn: 'A00001',
    animal: 'Gos',
    ut: '01',
    status: 'A01',
  },
  {
    codiAn: 'A00001',
    animal: 'Porquet',
    ut: '02',
    status: 'A01',
  },
  {
    codiAn: 'A00001',
    animal: 'Porquet',
    status: 'A01',
    ut: '03',
  },
  {
    codiAn: 'A00001',
    animal: 'Senglar',
    status: 'A01',
    ut: '04',
  },
  {
    codiAn: 'A00001',
    animal: 'Gos',
    ut: '01',
    status: 'A01',
  },
  {
    codiAn: 'A00001',
    animal: 'Porquet',
    ut: '02',
    status: 'A01',
  },
  {
    codiAn: 'A00001',
    animal: 'Porquet',
    ut: '03',
    status: 'A01',
  },
  {
    codiAn: 'A00001',
    animal: 'Senglar',
    ut: '04',
    status: 'A01',
  },
  {
    codiAn: 'A00001',
    animal: 'Gos',
    ut: '01',
    status: 'A01',
  },
  {
    codiAn: 'A00001',
    animal: 'Porquet',
    ut: '02',
    status: 'A01',
  },
  {
    codiAn: 'A00001',
    animal: 'Porquet',
    ut: '03',
    status: 'A01',
  },
  {
    codiAn: 'A00001',
    animal: 'Senglar',
    ut: '04',
    status: 'A01',
  },
];

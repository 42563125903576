// Dependencies
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useClickAway, useEvent } from 'react-use';
import { useDispatch, useSelector } from 'react-redux';
import isEqual from 'lodash.isequal';

// Styled Components
import { AnimatedModal, Backdrop } from './styles';

// Types
import { BackDropContainerProps } from './types';

// Actions
import { closedModal } from '../../../../state/actions/ui/modal';

// Selectors
import { getCurrentModal } from '../../../../state/selectors/ui/modal';

export default function BaseModal({
  children,
  handleClickClose,
}: BackDropContainerProps) {
  // Redux
  const dispatch = useDispatch();
  const currentModal = useSelector(getCurrentModal, isEqual);

  const isOpen = useMemo(
    () => !!(currentModal && currentModal.id),
    [currentModal]
  );

  // Context
  const modalRef = useRef(null);

  /**
   * @function handleKeyDown():
   * @description Catch the keyboard press event.
   */
  const handleKeyDown = useCallback((event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      handleClickClose();
    }
  }, []);

  useEvent('keydown', handleKeyDown);

  useClickAway(modalRef, () => {
    if (isOpen) {
      handleClickClose();
    }
  });

  useEffect(() => {
    return () => {
      dispatch(closedModal());
    };
  }, [isOpen, dispatch]);

  return (
    <Backdrop visible={isOpen}>
      <AnimatedModal ref={modalRef} isOpen={isOpen}>
        {children}
      </AnimatedModal>
    </Backdrop>
  );
}

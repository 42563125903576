import styled from 'styled-components';

export const ContainerMap = styled.div`
  display: flex;
  height: 100%;
  width: 100%;

  @keyframes fadeInAnimation {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const styleMap = {
  animation: 'fadeInAnimation ease 3s',
  animationIterationCount: '1',
  animationFillMode: 'forwards',
  flexGrow: '1',
  height: '100%',
  width: '100%',
  borderRadius: '0.8rem',
};

import { OperatorsType } from './types';

export const mockData: OperatorsType[] = [
  {
    company: 'Esther',
    work: 'Alfreda Pol',
    operatorsNumber: '5',
  },
  {
    company: 'Esther',
    work: 'Alfreda Pol',
    operatorsNumber: '10',
  },
];

import { object, string } from 'yup';
import { LocationFormValues } from './types';
import { pkValidation } from '../../../../utils/pkValidation';

export const LocationSchema = object<LocationFormValues>({
  actionConcession: string().required(),
  actionCity: string().required(),
  actionPkInitRoad: string().required(),
  actionPkEndRoad: string().required(),
  actionRoad: string().required(),
  actionPkInit: string()
    .matches(/^\d+\s?\+\s?\d{3,4}$/)
    .test(function (value) {
      const regex = new RegExp(/^\d+\s?\+\s?\d{3,4}$/);
      return regex.test(value || '') && pkValidation(value || '', this.parent);
    })
    .required(),
  actionPkEnd: string()
    .matches(/^\d+\s?\+\s?\d{3,4}$/)
    .test(function (value) {
      const regex = new RegExp(/^\d+\s?\+\s?\d{3,4}$/);
      return regex.test(value || '') && pkValidation(value || '', this.parent);
    })
    .required(),
  actionTrack: string().required(),
  actionDirection: string().required(),
  actionMargin: string().required(),
  actionCoordinates: string(),
  actionLane: string().required(),
});

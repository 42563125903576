import { defer, LoaderFunction } from 'react-router-dom';

// Sentry
import * as Sentry from '@sentry/react';

// Constants
import {
  formActionNaming,
  formActionValidationNaming,
} from '../../../../../components/forms/ActionForms/constants';

// Utils
import { decompressSessionStorage } from '../../../../../utils/sessionStorage';

// GraphQL
import { client } from '../../../../../gql/client';
import {
  FETCH_ANIMALS,
  FETCH_ANIMAL_STATUS,
} from '../../../../../gql/queries/actions/animals';
import { AuthContextType } from '../../../../../hooks/useAuth';
import { selectAnimalsValue } from '../../../../../gql/selectors/actions';
import { GET_ORDER_BY_ID } from '../../../../../gql/queries/orders/orders';

const loadFormValues = async (
  userData: AuthContextType | null,
  actionId: string
) => {
  if (userData?.user) {
    try {
      const formValues = selectAnimalsValue(actionId);
      return formValues;
    } catch (err) {
      Sentry.captureException(err);
      console.log('> Error loading animals data', err);
    }
  }

  return {};
};

export const AnimalsValidationLoader: (
  userData: AuthContextType | null
) => LoaderFunction | undefined = (userData) => async () => {
  const stringifyForm = sessionStorage.getItem(
    formActionValidationNaming.NEW_VALIDATION_ANIMALS_FORM
  );

  const stringifyActionData = sessionStorage.getItem(
    formActionNaming.NEW_VALIDATION_ACTION_DATA
  );

  let sessionStorageData;
  let actionData;
  let parentExpeditionOrderStatus;
  let state;

  if (stringifyForm) {
    sessionStorageData = JSON.parse(decompressSessionStorage(stringifyForm));
  }

  if (stringifyActionData) {
    actionData = JSON.parse(decompressSessionStorage(stringifyActionData));
    const {
      data: { order },
    } = await client.query({
      query: GET_ORDER_BY_ID,
      variables: {
        id: actionData.orderId,
      },
    });
    const parentOrder = await client.query({
      query: GET_ORDER_BY_ID,
      variables: {
        id: order.parentExpeditionOrderIDs[0],
      },
    });
    parentExpeditionOrderStatus = parentOrder.data.order.state;
    state = order.state;
  }

  const {
    data: { animals: animalsUnsorted },
  } = await client.query({
    query: FETCH_ANIMALS,
  });

  const {
    data: { animalAllStatus },
  } = await client.query({
    query: FETCH_ANIMAL_STATUS,
  });

  const animals = [...animalsUnsorted].sort((a, b) =>
    a.animalId.localeCompare(b.animalId)
  );

  const loaderToResolve = await loadFormValues(userData, actionData.id);

  const combinedData = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    animals: animals.map((animal: any) => ({ ...animal, animalUnitId: null })),
    animalAllStatus,
    ...loaderToResolve,
    ...sessionStorageData,
    ...actionData,
    parentExpeditionOrderStatus,
    state,
    dataFromServer: { ...loaderToResolve, animals, intent: 'pass-data' },
  };

  return defer({
    data: combinedData,
  });
};

import styled from 'styled-components';

// Styles utils
import { colors } from '../../../../styles/colors';
import {
  breakPoints,
  percentBaseResponsive,
  spaces,
} from '../../../../styles/spaces';

// Components
import { DateTime } from '../../../inputs/DateTime';
import { InputTextArea } from '../../../inputs/InputTextArea';
import { Container } from '../../../inputs/InputTextArea/styles';
import { Typography } from '../../Typography';

// Types
import { RightArrowProps } from './types';
import { ButtonIconBackground } from '../../../inputs/buttons/ButtonIconBackground';
import { SpecialTag } from '../../SpecialTag';

export const ContainerOT = styled.div`
  width: 100%;
  padding: ${spaces.xl}rem;
  background-color: ${colors.verySoftRed};
  border-radius: 0.8rem;
  @media (max-width: ${breakPoints.landscapeTablet *
    percentBaseResponsive}rem) {
    padding: ${spaces.l}rem;
  }
`;

export const ContainerHeader = styled.div`
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ContainerRefIcon = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5rem;
`;

export const RefCotic = styled(Typography)``;

export const RightArrow = styled.img<RightArrowProps>`
  transform: ${({ showInfo }) => (showInfo ? `rotate(90deg)` : null)};
  width: 1.4rem;
  height: 1rem;
  cursor: pointer;
`;

export const ContainerContent = styled.form`
  display: flex;
  flex-direction: column;
  gap: 3.2rem;

  @media (max-width: ${breakPoints.portraitTablet * percentBaseResponsive}rem) {
    gap: 2.6rem;
  }
`;

export const ContainerData = styled.div`
  display: flex;
  margin-top: 3.2rem;
  gap: 2.6rem;
  flex-wrap: wrap;
  @media (max-width: ${breakPoints.portraitTablet * percentBaseResponsive}rem) {
    margin-top: 2.6rem;
  }
`;

export const DateInput = styled(DateTime)`
  label {
    white-space: nowrap;
  }
`;

export const ContainerDescription = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 2.9rem;
`;

export const InputDescription = styled(InputTextArea)`
  &${Container} {
    width: 100%;
  }
`;
export const SpecialTagStyled = styled(SpecialTag)`
  cursor: pointer;
`;
export const ButtonEdit = styled(ButtonIconBackground)``;
export const ButtonSave = styled(ButtonIconBackground)``;

export type dataTable = {
  id: string;
  animalId: string;
  name?: string;
};

export type TableProps = {
  data?: {
    animals: dataTable[];
  };
};

export type SearchbarFilterTypes = (value: string) => void;

export const animalsColumnNames = {
  animalId: {
    id: 'animalId',
    label: 'Codi',
  },
  name: {
    id: 'name',
    label: 'Animal',
  },
};

import { gql } from '@apollo/client';

export const FETCH_VEHICLES = gql`
  query FetchVehicles {
    vehicles {
      id
      plate
      model
      color
      insurance
      policyNumber
      isInternal
      isTrailer
      parentVehicleIDs
      childVehicleIDs
      isActive
      category
      vehicleCategoryIDs
      concessionIDs
    }
  }
`;

export const GET_VEHICLE_BY_ID = gql`
  query GetVehicleById($id: ID!, $isTrailer: Boolean) {
    vehicle(id: $id, isTrailer: $isTrailer) {
      id
      plate
      model
      color
      insurance
      policyNumber
      isTrailer
      accidentIDs
      vehicleOccupantIDs
      parentVehicleIDs
      childVehicle {
        id
        plate
        insurance
        policyNumber
        isTrailer
      }
      vehicleCategoryIDs
      isActive
      concessionIDs
    }
  }
`;

export const GET_VEHICLE_OCCUPANT_BY_ID = gql`
  query GetVehicleOccupantById($id: ID!) {
    vehicleOccupant(id: $id) {
      id
      numberOccupants
      userDriverIDs
      actionIDs
      accidentIDs
      vehicleIDs
    }
  }
`;

export const FILTER_VEHICLES = gql`
  query FilterVehicles($input: FilterVehicle!) {
    filterVehicles(input: $input) {
      id
      plate
      model
      color
      insurance
      policyNumber
      isInternal
      isTrailer
      parentVehicleIDs
      childVehicleIDs
      isActive
      concessionIDs
    }
  }
`;

export const GET_VEHICLES = gql`
  query GetVehicles {
    readVehicles @client {
      id
      plate
      model
      color
      insurance
      policyNumber
      isInternal
      isTrailer
      parentVehicleIDs
      childVehicleIDs
      isActive
      category
      vehicleCategoryIDs
      concessionIDs
    }
  }
`;

export const GET_ACTIVE_VEHICLES = gql`
  query GetVehiclesInternals {
    readActiveVehicles @client {
      id
      plate
      model
      color
      insurance
      policyNumber
      isInternal
      isTrailer
      parentVehicleIDs
      childVehicleIDs
      isActive
      concessionIDs
    }
  }
`;

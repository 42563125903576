import React from 'react';

type DirectionBothVerticalProps = {
  className?: string;
};

export const DirectionBothVertical = ({
  className,
}: DirectionBothVerticalProps): JSX.Element => {
  return (
    <svg
      width="17.6"
      height="22"
      viewBox="0 0 20 27"
      fill="black"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M9.49428 16.25L9.24428 16.25L9.24428 16L9.24428 5.07564L4.93527 9.38464C4.4405 9.87942 3.63831 9.87942 3.14353 9.38464C2.64876 8.88987 2.64876 8.08768 3.14353 7.5929L9.61536 1.12108C10.1101 0.626306 10.9123 0.626306 11.4071 1.12108L17.8789 7.59291C18.3737 8.08768 18.3737 8.88987 17.8789 9.38464C17.3841 9.87942 16.582 9.87942 16.0872 9.38464L11.7782 5.07564L11.7782 16L11.7782 16.25L11.5282 16.25L9.49428 16.25Z" />
      <path d="M11.5057 6.2578L11.7557 6.2578L11.7557 6.5078L11.7557 22.4322L16.0647 18.1232C16.5595 17.6284 17.3617 17.6284 17.8565 18.1232C18.3512 18.6179 18.3512 19.4201 17.8565 19.9149L11.3846 26.3867C10.8899 26.8815 10.0877 26.8815 9.59291 26.3867L3.12108 19.9149C2.62631 19.4201 2.62631 18.6179 3.12108 18.1232C3.61586 17.6284 4.41805 17.6284 4.91282 18.1232L9.22183 22.4322L9.22183 6.5078L9.22183 6.2578L9.47183 6.2578L11.5057 6.2578Z" />
    </svg>
  );
};

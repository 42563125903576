// Types
import { ImageData } from '../ResumeLoadImages/types';
import { AnimalsType } from '../tables/summaryTables/AnimalsTable/types';
import { AuxiliaryMachineryType } from '../tables/summaryTables/AuxiliaryMachineryTable/types';
import { MaterialsType } from '../tables/summaryTables/MaterialsTable/types';
import { OperatorsType } from '../tables/summaryTables/OperatorsTable/types';
import { VehiclesType } from '../tables/summaryTables/VehiclesTable/types';
import { UNSPECIFIC } from '../../../types/utils';

export type ContentCardResumeProps = {
  works?: {
    work: string;
    company: string;
    operatorsNumber: string | number;
  }[];
  routes: string[];
  isActionEditedBoolean: boolean;
  cotic: string[];
  date: string;
  hour: string;
  capitol: string;
  subCapitol: string;
  concession: string;
  roadDirections: string;
  road: string;
  pkInit: string;
  pkEnd: string;
  track: string;
  city: string;
  direction?: 'top' | 'down' | 'both' | typeof UNSPECIFIC;
  margin?: 'left' | 'right' | 'both' | typeof UNSPECIFIC;
  lane: string;
  leftButton?: string;
  rightButton?: string;
  description: string;
  asset?: string;
  assetSwitch?: boolean;
  activity?: string;
  isMaterialTableDisplayed: boolean;
  isAnimalsTableDisplayed: boolean;
  isMachineryTableDisplayed: boolean;
  materialsData?: MaterialsType[];
  animalsData?: AnimalsType[];
  machineryData?: AuxiliaryMachineryType[];
  operators: OperatorsType[];
  vehicles: VehiclesType[];
  startDate: string;
  startHour: string;
  endDate: string;
  endHour: string;
  totalHours: string;
  totalOperators: string;
  element: string;
  laneCutting: boolean;
  images?: ImageData[];
  mapCenter: Coordinates;
  path: Coordinates[];
  geolocation?: Coordinates;
  isExternal?: boolean;
  onEditAction?: () => void;
  onContinueAction?: () => void;
};

type Coordinates = {
  lat: number;
  lng: number;
};

export const marginArrow = {
  left: 'Esquerra (←)',
  right: 'Dret (→)',
  both: 'Tots dos (↔)',
  unspecific: 'Sense especificar',
};

export const directionArrow = {
  top: '(↑)',
  down: '(↓)',
  both: '(↕)',
  unspecific: 'Sense especificar',
};
export interface dataTabs {
  label: string;
  content: JSX.Element;
}

import styled, { css } from 'styled-components';

// Styles
import { colors } from '../../../../styles/colors';

// Styled Components
import {
  Tr,
  TableContainer,
  FiltersContainer,
  SubContainerFilters,
  Td,
  DropDownInputStyled,
  ContainerButtons,
  Container,
} from '../components/MainStyledComponents';

// Styles utils
import {
  breakPoints,
  percentBaseResponsive,
  spaces,
} from '../../../../styles/spaces';

// Types
import { ArrowBottomStyledProps, ShowFiltersButtonProps } from './types';
import { columnNames } from '../components/types';

// Icons
import { ArrowBottom } from '../../../../assets/icons/ArrowBottom';
import { ContainersLoadingProps } from '../InputRadioArrowTable/types';
import { Typography } from '../../Typography';
import { fontSize } from '../../../../styles/fonts';

export const ContainerStyled = styled(Container)`
  position: relative;
`;

export const TableContainerStyled = styled(
  TableContainer
)<ContainersLoadingProps>`
  opacity: ${(props) => (props.isLoading ? 0.6 : 1)};
`;

export const FiltersWithLoadingStyled = styled(
  FiltersContainer
)<ContainersLoadingProps>`
  opacity: ${(props) => (props.isLoading ? 0.6 : 1)};
`;

export const ContainerLoader = styled.div`
  position: absolute;
  top: 30%;
  left: 50%;
  z-index: 1;
`;

export const CampaignSearchContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spaces.xxxs}rem;
  width: 100%;
  max-width: 88rem;
  @media (max-width: ${breakPoints.smallDesktop * percentBaseResponsive}rem) {
    max-width: 50rem;
  }
  @media (max-width: ${breakPoints.landscapeTablet *
    percentBaseResponsive}rem) {
    max-width: 100%;
  }
`;

export const SubContainerHead = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledTypography = styled(Typography)`
  @media (max-width: ${breakPoints.landscapeTablet *
    percentBaseResponsive}rem) {
    font-size: ${fontSize.XS};
  }
`;

export const ShowFiltersButton = styled.button<ShowFiltersButtonProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  height: 2.3rem;
  border-radius: 0.3rem;
  transition: background 300ms;
  background: ${(props) => (props.isBlack ? colors.black : colors.garnet)};
`;

export const ArrowBottomStyled = styled(ArrowBottom)<ArrowBottomStyledProps>`
  transition: transform 300ms;
  transform: ${(props) => (props.isRotate ? null : 'rotate(-90deg)')};
  width: 1rem;
  height: 1rem;
  path {
    fill: white;
  }
`;

export const SubContainerFiltersStyled = styled(SubContainerFilters)``;

export const TdStyled = styled(Td)`
  @media (max-width: ${breakPoints.landscapeTablet *
    percentBaseResponsive}rem) {
    text-overflow: ellipsis;

    ${(props) =>
      props.column === columnNames.capitol.id && {
        maxWidth: '11.3rem',
      }}
    ${(props) =>
      props.column === columnNames.pkEnd.id && {
        maxWidth: '10rem',
      }}
      ${(props) =>
      props.column === columnNames.pkInit.id && {
        maxWidth: '10rem',
      }}
  }
`;

export const TrStyled = styled(Tr)`
  background-color: ${({ checked }) => checked && colors.lightRed};
  ${({ indeterminate, checked }) =>
    css`
      Td {
        ${!indeterminate && {
          opacity: 0.2,
        }}
        ${checked && {
          opacity: 1,
        }}
      }
    `}
`;

export const DropDownInputStyledBig = styled(DropDownInputStyled)`
  @media (max-width: ${breakPoints.landscapeTablet *
    percentBaseResponsive}rem) {
    max-width: 21rem;
  }
  @media (max-width: ${breakPoints.portraitTablet * percentBaseResponsive}rem) {
    max-width: 16rem;
  }
`;

export const ContainerButtonStyled = styled(ContainerButtons)``;

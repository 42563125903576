import React, { useCallback, useLayoutEffect } from 'react';
import { useNavigate, useActionData, useAsyncValue } from 'react-router-dom';
import { format } from 'date-fns';

//Components
import ContentCardResume from '../../../../../components/display/ContentCardResume';

// Types
import { type WorkOrder } from '../../../../../types/workOrder';

export default function WorkOrderSummary(): JSX.Element {
  const loaderData = useAsyncValue() as WorkOrder;
  const actionData = useActionData() as WorkOrder;
  const navigate = useNavigate();

  const workOrder = actionData ?? loaderData;
  useLayoutEffect(() => {
    if (!workOrder) navigate('..');
  }, [workOrder]);

  const handleEditWorkOrder = useCallback(() => {
    navigate('../..', { relative: 'path' });
  }, []);

  return (
    <>
      {workOrder && (
        <ContentCardResume
          title="Resum de la Ordre de treball"
          cotic={workOrder.cotic as unknown as string[]}
          operator={workOrder.operator}
          data={format(new Date(workOrder.registerInitDate), 'dd / MM / yyyy')}
          hour={format(new Date(workOrder.registerInitDate), 'HH:mm')}
          capitol={workOrder.capitol}
          subCapitol={workOrder.subCapitol}
          classification={workOrder.classification}
          team={workOrder.team}
          concession={workOrder.concession}
          road={workOrder.road}
          pkInit={`${workOrder.pkInit} km`}
          pkEnd={`${workOrder.pkEnd} km`}
          track={workOrder.track}
          roadDirections={workOrder.roadDirections}
          direction={workOrder.direction}
          city={workOrder.city}
          lane={workOrder.lane}
          margin={workOrder.margin}
          leftButton={'Tornar'}
          description={workOrder.description}
          onEditCommunication={handleEditWorkOrder}
        />
      )}
    </>
  );
}

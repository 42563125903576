import { gql } from '@apollo/client';

export const FETCH_MEASURE_TYPES = gql`
  query FetchMeasureTypes {
    measureTypes {
      id
      name
    }
  }
`;

export const GET_MEASURE_TYPES_BY_ID = gql`
  query GetMeasureTypesById($id: ID!) {
    measureType(id: $id) {
      id
      name
      materialIDs
      vehicleIDs
      auxMachineIDs
    }
  }
`;

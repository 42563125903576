// Types
import { dataTable } from './types';
export const mocksTable: dataTable[] = [
  {
    id: 'hsd78fghs8dg7h',
    cotic: ['1O', '0000', '1234567', '00'],
    date: 1641809400000,
    hour: 1641809400000,
    name: 'Podada d’arbres anual',
    concession: 'LL',
    road: 'C-16',
    description: 'Acidente de ciclomotor en  vía asasd',
    state: 'open',
  },
  {
    id: '7a8df65a67dfg',
    cotic: ['1O', '0000', '1234567', '00'],
    date: 1641809400000,
    hour: 1641809400000,
    name: 'Test',
    concession: 'LL',
    road: 'C-16',
    description: 'Acidente de ciclomotor en  vía asasd',
    state: 'end',
  },
  {
    id: '8s6g5gs67g8dgsdg',
    cotic: ['1O', '0000', '1234567', '00'],
    date: 1641809400000,
    hour: 1641809400000,
    name: 'Acidente ciclomotor',
    concession: 'LL',
    road: 'C-16',
    description: 'Acidente de ciclomotor en  vía asasd',
    state: 'end',
  },
  {
    id: '8s76dg87s6d5g87sdgs',
    cotic: ['1O', '0000', '1234567', '00'],
    date: 1641809400000,
    hour: 1641809400000,
    name: 'Podada d’arbres anual',
    concession: 'LL',
    road: 'C-16',
    description: 'Acidente de ciclomotor en  vía asasd',
    state: 'open',
  },
  {
    id: 'ig6hj34g6jh4g5',
    cotic: ['1O', '0000', '1234567', '00'],
    date: 1641809400000,
    hour: 1641809400000,
    name: 'Podada d’arbres anual',
    concession: 'LL',
    road: 'C-16',
    description: 'Acidente de ciclomotor en  vía asasd',
    state: 'validated',
  },
  {
    id: 'd8f6x5h8x9fv7h6',
    cotic: ['1O', '0000', '1234567', '00'],
    date: 1641809400000,
    hour: 1641809400000,
    name: 'Podada d’arbres anual',
    concession: 'LL',
    road: 'C-16',
    description: 'Acidente de ciclomotor en  vía asasd',
    state: 'validated',
  },
  {
    id: 's786fg5s76dg5s9op',
    cotic: ['1O', '0000', '1234567', '00'],
    date: 1641809400000,
    hour: 1641809400000,
    name: 'Podada d’arbres anual',
    concession: 'LL',
    road: 'C-16',
    description: 'Acidente de ciclomotor en  vía asasd',
    state: 'open',
  },
  {
    id: 's8d7g68d7a6g9as8hyg',
    cotic: ['1O', '0000', '1234567', '00'],
    date: 1641809400000,
    hour: 1641809400000,
    name: 'Entorns de carretera',
    concession: 'LL',
    road: 'C-16',
    description: 'Acidente de ciclomotor en  vía asasd',
    state: 'open',
  },
  {
    id: '96dfsg879s6fg796s',
    cotic: ['1O', '0000', '1234567', '00'],
    date: 1641809400000,
    hour: 1641809400000,
    name: 'Podada d’arbres anual',
    concession: 'LL',
    road: 'C-16',
    description: 'Acidente de ciclomotor en  vía asasd',
    state: 'validated',
  },
  {
    id: '8s75fg87as56d9g87',
    cotic: ['1O', '0000', '1234567', '00'],
    date: 1641809400000,
    hour: 1641809400000,
    name: 'Podada d’arbres anual',
    concession: 'LL',
    road: 'C-16',
    description: 'Acidente de ciclomotor en  vía asasd',
    state: 'end',
  },
  {
    id: '9s6adg879as6g976sadg9',
    cotic: ['1O', '0000', '1234567', '00'],
    date: 1641809400000,
    hour: 1641809400000,
    name: 'Podada d’arbres anual',
    concession: 'LL',
    road: 'C-16',
    description: 'Acidente de ciclomotor en  vía asasd',
    state: 'end',
  },
  {
    id: '9s6g97as6dg89a76sge',
    cotic: ['1O', '0000', '1234567', '00'],
    date: 1641809400000,
    hour: 1641809400000,
    name: 'Podada d’arbres anual',
    concession: 'LL',
    road: 'C-16',
    description: 'Acidente de ciclomotor en  vía asasd',
    state: 'end',
  },
  {
    id: '97568s7ag89ag6wpeo',
    cotic: ['1O', '0000', '1234567', '00'],
    date: 1641809400000,
    hour: 1641809400000,
    name: 'Podada d’arbres anual',
    concession: 'LL',
    road: 'C-16',
    description: 'Acidente de ciclomotor en  vía asasd',
    state: 'open',
  },
  {
    id: '9ad6g789sd5gb9asdgh',
    cotic: ['1O', '0000', '1234567', '00'],
    date: 1641809400000,
    hour: 1641809400000,
    name: 'Podada d’arbres anual',
    concession: 'LL',
    road: 'C-16',
    description: 'Acidente de ciclomotor en  vía asasd',
    state: 'validated',
  },
  {
    id: '9sa7g697as6dg97as',
    cotic: ['1O', '0000', '1234567', '00'],
    date: 1641809400000,
    hour: 1641809400000,
    name: 'Podada d’arbres anual',
    concession: 'LL',
    road: 'C-16',
    description: 'Acidente de ciclomotor en  vía asasd',
    state: 'open',
  },
  {
    id: 'ds0f986ah9fh69dwh',
    cotic: ['1O', '0000', '1234567', '00'],
    date: 1641809400000,
    hour: 1641809400000,
    name: 'Podada d’arbres anual',
    concession: 'LL',
    road: 'C-16',
    description: 'Acidente de ciclomotor en  vía asasd',
    state: 'open',
  },
  {
    id: '0s86dg98as6dg9as677g',
    cotic: ['1O', '0000', '1234567', '00'],
    date: 1641809400000,
    hour: 1641809400000,
    name: 'Podada d’arbres anual',
    concession: 'LL',
    road: 'C-16',
    description: 'Acidente de ciclomotor en  vía asasd',
    state: 'open',
  },
  {
    id: '906dfg9s78a6gh9as7',
    cotic: ['1O', '0000', '1234567', '00'],
    date: 1641809400000,
    hour: 1641809400000,
    name: 'Podada d’arbres anual',
    concession: 'LL',
    road: 'C-16',
    description: 'Acidente de ciclomotor en  vía asasd',
    state: 'end',
  },
  {
    id: '9d8f6h98ads96fh',
    cotic: ['1O', '0000', '1234567', '00'],
    date: 1641809400000,
    hour: 1641809400000,
    name: 'Podada d’arbres anual',
    concession: 'LL',
    road: 'C-16',
    description: 'Acidente de ciclomotor en  vía asasd',
    state: 'end',
  },
  {
    id: 's97ag5s79dgns',
    cotic: ['1O', '0000', '1234567', '00'],
    date: 1641809400000,
    hour: 1641809400000,
    name: 'Podada d’arbres anual',
    concession: 'LL',
    road: 'C-16',
    description: 'Acidente de ciclomotor en  vía asasd',
    state: 'end',
  },
  {
    id: '9as6dg789as6dg9',
    cotic: ['1O', '0000', '1234567', '00'],
    date: 1641809400000,
    hour: 1641809400000,
    name: 'Podada d’arbres anual',
    concession: 'LL',
    road: 'C-16',
    description: 'Acidente de ciclomotor en  vía asasd',
    state: 'end',
  },
  {
    id: 's9adgt69as78dg6b9as',
    cotic: ['1O', '0000', '1234567', '00'],
    date: 1641809400000,
    hour: 1641809400000,
    name: 'Podada d’arbres anual',
    concession: 'LL',
    road: 'C-16',
    description: 'Acidente de ciclomotor en  vía asasd',
    state: 'validated',
  },
];

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ContainerSection = styled.div`
  margin-bottom: 2.375rem;
`;

export const ContainerSubheaderWrapper = styled.div`
  margin-bottom: 0.6875rem;
`;

import React, {
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
  useEffect,
} from 'react';

import {
  useReactTable,
  getCoreRowModel,
  createColumnHelper,
  flexRender,
  getFilteredRowModel,
  Row,
} from '@tanstack/react-table';

import { Controller } from 'react-hook-form';

// Mocks
import { operatorsMockData, vehiclesMockData } from './mocks';

// Types
import {
  OperatorsSelectionType,
  TableProps,
  VehiclesSelectionType,
  handleChangeTypes,
  operatorsColumnNames,
  vehiclesColumnNames,
  variantLeftTh,
  variantRightTh,
  ImperativeRefInput,
  NewAction,
} from './types';
import {
  OptionSelected,
  Options,
} from '../../../../inputs/DropDown/DropDownList/types';
import { DriverList } from '../../../../forms/ActionForms/OperatorsAndVehicles/types';

// Styled components
import {
  OperatorsTableContainer,
  VehiclesTableContainer,
  ContainerTable,
  ContainerInput,
  TableRightTh,
  TableLeftTh,
  DropDownInputStyled,
  CheckBoxStyled,
  Img,
  FiltersContainer,
  DropDownFilterStyled,
} from './styles';

import {
  Table,
  TableContainer,
  Tbody,
  Td,
  Thead,
  Tr,
} from '../../summaryTables/SummaryTablesStyles';
import { Typography } from '../../../Typography';
import { OperatorsAndVehiclesFormValues } from '../../../../forms/ActionForms/OperatorsAndVehicles/types';

// Icons
import RemoveIcon from '../../../../../assets/icons/remove-file-icon.svg';

// Contants
import { formActionNaming } from '../../../../forms/ActionForms/constants';
import { decompressSessionStorage } from '../../../../../utils/sessionStorage';
import { GroupTypes } from '../../../ShowOT/WorkOrder/types';
import SearchBar from '../../../../inputs/SearchBar';
import { OrderState } from '../../../../../types/orders';

export const OperatorsSelectionTable = ({
  data,
  operatorsData,
  vehiclesData,
  titles,
  control,
  defaultOperators = [],
  defaultVehicles = [],
  defaultDrivers = [],
  tableName = '',
  setDefaultOperators,
  setDefaultVehicles,
  setValue,
  setCacheDrivers,
  isInternal,
  getValues,
  concessions,
}: TableProps): JSX.Element => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let getOperatorsAndVehicleCached: any = null;
  const initialData = data?.breadCrumbsData;
  const operatorsDataAll = operatorsData ? operatorsData : operatorsMockData;
  const vehiclesDataAll = vehiclesData ? vehiclesData : vehiclesMockData;

  const [vehiclesDataTable, setVehiclesDataTable] =
    useState<VehiclesSelectionType[]>(vehiclesDataAll);
  const getDriversCache = JSON.parse(
    sessionStorage.getItem(formActionNaming.DRIVERS) as string
  );

  const getOperatorsAndVehicleCachedRaw = sessionStorage.getItem(
    formActionNaming.NEW_OPERATORS_AND_VEHICLE_FORM
  );

  if (getOperatorsAndVehicleCachedRaw) {
    getOperatorsAndVehicleCached = JSON.parse(
      decompressSessionStorage(getOperatorsAndVehicleCachedRaw)
    );
  }

  const [drivers, setDrivers] = useState<DriverList>(
    getDriversCache ?? defaultDrivers
  );

  const [operators, setOperators] =
    useState<OperatorsSelectionType[]>(defaultOperators);

  const filterDefaultVehicles = defaultVehicles.filter((vehicle) => {
    return Object.keys(vehicle).length > 0;
  });

  const [vehicles, setVehicles] = useState<VehiclesSelectionType[]>(
    filterDefaultVehicles
  );

  const [changeOperators, setChangeOperators] = useState(false);
  const [changeVehicles, setChangeVehicles] = useState(false);

  const dropDownRefs = Array(vehiclesData?.length)
    .fill(null)
    .map(() => useRef<ImperativeRefInput>(null));

  const operatorsColumnHelper = createColumnHelper<OperatorsSelectionType>();
  const vehiclesColumnHelper = createColumnHelper<VehiclesSelectionType>();
  const handleSelectedOption = (idDriver: string, idVehicle: string) => {
    setVehiclesDataTable((prevData) =>
      prevData.map((row) => {
        return row.id === idVehicle ? { ...row, driver: idDriver } : row;
      })
    );
  };

  useEffect(() => {
    const operatorsIDs = operators.map((operator) => operator.id);
    const indexDriver = drivers
      .map((driver, index) =>
        driver && !operatorsIDs.includes(driver.driverID) ? index : null
      )
      .filter((index) => index !== null);
    indexDriver.forEach(
      (index) =>
        isInternal &&
        setValue(
          `vehiclesTable[${index}].driverID` as keyof OperatorsAndVehiclesFormValues,
          undefined
        )
    );

    const indexDriverCache =
      getOperatorsAndVehicleCached &&
      getOperatorsAndVehicleCached?.vehiclesTable
        ?.map((driver: { driver: string; id: string }, index: number) =>
          driver && !operatorsIDs.includes(driver.driver) ? index : null
        )
        .filter((index: number) => index !== null);
    indexDriverCache &&
      indexDriverCache.forEach(
        (index: number) =>
          isInternal &&
          setValue(
            `vehiclesTable[${index}].driverID` as keyof OperatorsAndVehiclesFormValues,
            undefined
          )
      );

    setDefaultOperators(operators);

    const updatedDrivers: DriverList = [];
    drivers.forEach(
      (driver) =>
        operatorsIDs.includes(driver.driverID) && updatedDrivers.push(driver)
    );
    isInternal && setDrivers(updatedDrivers);
  }, [operators]);

  useEffect(() => {
    setDefaultVehicles(vehicles);
  }, [vehicles]);
  const handleOperatorsOnChange = (
    row: Row<OperatorsSelectionType>,
    key: string
  ) => {
    !row.getIsSelected()
      ? setOperators((prevOperators) => [...prevOperators, row.original])
      : setOperators((prevOperators) =>
          prevOperators.filter((operator) => operator.id !== row.original.id)
        );
    row.getToggleSelectedHandler()(row);

    !row.getIsSelected()
      ? setValue(
          key as keyof OperatorsAndVehiclesFormValues,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          row.original as any
        )
      : setValue(key as keyof OperatorsAndVehiclesFormValues, undefined);
  };

  const operatorsColumns = useMemo(
    () => [
      {
        enableHiding: true,
        id: 'select',
        cell: ({ row }) => {
          return (
            <Controller
              name={
                `${tableName}.${row.index}` as keyof NewAction &
                  OperatorsAndVehiclesFormValues
              }
              control={control}
              defaultValue={defaultOperators[row.index]}
              render={() => {
                return (
                  <CheckBoxStyled
                    defaultChecked={row.getIsSelected()}
                    indeterminate={!row.getCanSelect()}
                    disabled={
                      data?.parentExpeditionOrderStatus ===
                        OrderState.Annulled ||
                      data?.parentExpeditionOrderStatus === OrderState.End ||
                      data?.state === OrderState.Annulled
                    }
                  />
                );
              }}
            />
          );
        },
      },
      operatorsColumnHelper.accessor((row) => row.name, {
        id: operatorsColumnNames.name.id,
        header: () => operatorsColumnNames.name.label,
        cell: (info) => info.getValue(),
      }),
      operatorsColumnHelper.accessor((row) => row.surName, {
        id: operatorsColumnNames.surName.id,
        header: () => operatorsColumnNames.surName.label,
      }),
      operatorsColumnHelper.accessor((row) => row.concession, {
        id: operatorsColumnNames.concession.id,
        header: () => operatorsColumnNames.concession.label,
        filterFn: 'arrIncludes',
      }),
      operatorsColumnHelper.accessor((row) => row.isActive, {
        id: operatorsColumnNames.isActive.id,
        header: () => operatorsColumnNames.isActive.label,
      }),
    ],
    [drivers, changeOperators]
  );

  const handleOnChange = (row: Row<VehiclesSelectionType>) => {
    !row.getIsSelected()
      ? setVehicles((prevVehicles) => [
          ...prevVehicles,
          row.original.driverID
            ? row.original
            : {
                ...row.original,
                driverID: drivers[row.index]?.driverID as string,
              },
        ])
      : setVehicles((prevVehicles) =>
          prevVehicles
            .filter((vehicle) => vehicle.vehicleID !== row.original.id)
            .filter((vehicle) => vehicle.id !== row.original.id)
        );

    row.getToggleSelectedHandler()(row);

    const id = !row.getIsSelected() ? row.original.id : undefined;
    setValue(
      `vehicleTable[${row.original.id}].vehicleID` as keyof OperatorsAndVehiclesFormValues,
      id
    );
    if (
      getValues().vehicleTable[row.original.id].vehicleID === undefined &&
      getValues().vehicleTable[row.original.id].driverID === undefined
    ) {
      setValue(`vehicleTable[${row.original.id}]` as any, undefined);
    }
  };
  const vehiclesColumns = useMemo(
    () => [
      {
        id: 'select',
        cell: ({ row }) => {
          return (
            <Controller
              name={`vehicleTable[${row.original.id}]` as any}
              control={control}
              defaultValue={vehicles[row.index]}
              render={() => {
                return (
                  <CheckBoxStyled
                    defaultChecked={row.getIsSelected()}
                    indeterminate={!row.getCanSelect()}
                    disabled={
                      data?.parentExpeditionOrderStatus ===
                        OrderState.Annulled ||
                      data?.parentExpeditionOrderStatus === OrderState.End ||
                      data?.state === OrderState.Annulled
                    }
                  />
                );
              }}
            />
          );
        },
      },
      vehiclesColumnHelper.accessor((row) => row.plate, {
        id: vehiclesColumnNames.plate.id,
        header: () => vehiclesColumnNames.plate.label,
      }),
      vehiclesColumnHelper.accessor((row) => row.concession, {
        id: vehiclesColumnNames.concession.id,
        header: () => vehiclesColumnNames.concession.label,
      }),
      vehiclesColumnHelper.accessor((row) => row.driver, {
        id: vehiclesColumnNames.driver.id,
        header: () => vehiclesColumnNames.driver.label,
        cell: ({ row }) => {
          const options: Options = operatorsTable
            .getSelectedRowModel()
            .rows.map((row) => ({
              label: row.original.surName,
              value: row.original.id,
            }));
          let cacheOperatorsSelected;
          const cacheOperatorsSelectedRaw = sessionStorage.getItem(
            formActionNaming.NEW_OPERATORS_AND_VEHICLE_FORM
          );
          if (cacheOperatorsSelectedRaw) {
            cacheOperatorsSelected = JSON.parse(
              decompressSessionStorage(cacheOperatorsSelectedRaw)
            );
          }

          const operatorsSelectedOptions =
            cacheOperatorsSelected?.internalOperatorsTable?.reduce(
              (acc: Options, prev: OperatorsSelectionType) => {
                if (prev !== null) {
                  const operator = {
                    value: prev.id,
                    label: prev.surName,
                  };
                  acc.push(operator);
                }
                return acc;
              },
              []
            );
          const driverFind = drivers.find(
            (driver) => driver.vehicleID === row.original.id
          );

          return (
            <ContainerInput>
              <Controller
                name={`vehicleTable[${row.original.id}]` as any}
                control={control}
                render={() => {
                  return (
                    <DropDownInputStyled
                      isDisabled={
                        data?.parentExpeditionOrderStatus ===
                          OrderState.Annulled ||
                        data?.parentExpeditionOrderStatus === OrderState.End ||
                        data?.state === OrderState.Annulled
                      }
                      defaultValue={driverFind?.driverID}
                      ref={dropDownRefs[row.index]}
                      inputSize="XL"
                      placeholder={'Seleccionar operari'}
                      typeDropDown={'Table'}
                      options={isInternal ? options : operatorsSelectedOptions}
                      handleSelectedOption={(value) => {
                        setDrivers((prevDrivers) => {
                          const newDrivers = [...prevDrivers];
                          let includesIDValue = false;
                          let indexID;
                          newDrivers.forEach((driver, index) => {
                            if (driver.vehicleID === row.original.id) {
                              includesIDValue = true;
                              indexID = index;
                            }
                          });

                          if (!includesIDValue) {
                            newDrivers.push({
                              vehicleID: row.original.id,
                              driverID: value as string,
                            });
                          } else {
                            if (indexID || indexID === 0)
                              newDrivers[indexID].driverID = value as string;
                          }

                          return newDrivers;
                        });

                        setValue(
                          `vehicleTable[${row.original.id}].driverID` as any,
                          value as string
                        );
                        handleSelectedOption(value as string, row.original.id);
                      }}
                    />
                  );
                }}
              />
            </ContainerInput>
          );
        },
      }),
      vehiclesColumnHelper.accessor((row) => row.remove, {
        id: vehiclesColumnNames.remove.id,
        header: () => (
          <Typography colorText="white" size="S">
            -
          </Typography>
        ),
        cell: ({ row }) => (
          <Img
            src={RemoveIcon}
            onClick={(e) => {
              e.stopPropagation();
              dropDownRefs[row.index].current?.clearSelected();

              setDrivers((prevDrivers) => {
                const newDrivers = [...prevDrivers];
                const driverFilter = newDrivers.filter(
                  (driver) => driver.vehicleID !== row.original.id
                );
                return driverFilter;
              });
              setValue(
                `vehicleTable[${row.original.id}].driverID` as keyof OperatorsAndVehiclesFormValues,
                undefined
              );

              if (
                getValues().vehicleTable[row.original.id].vehicleID ===
                  undefined &&
                getValues().vehicleTable[row.original.id].driverID === undefined
              ) {
                setValue(`vehicleTable[${row.original.id}]` as any, undefined);
              }
            }}
          />
        ),
      }),
      vehiclesColumnHelper.accessor((row) => row.isActive, {
        id: vehiclesColumnNames.isActive.id,
        header: () => vehiclesColumnNames.isActive.label,
      }),
    ],
    [drivers, operators, changeVehicles, vehicles]
  );
  const operatorsHandleChange = useCallback<handleChangeTypes>(
    (value) => {
      operatorsTable.getColumn('firstName')?.setFilterValue(value);
      value === '' && setChangeOperators((prev) => !prev);
    },
    [changeOperators]
  );

  const vehiclesHandleChange = useCallback<handleChangeTypes>(
    (value) => {
      vehiclesTable.getColumn('plate')?.setFilterValue(value);
      value === '' && setChangeVehicles((prev) => !prev);
    },
    [changeVehicles]
  );

  const defaultConcession = initialData?.concessions.filter(
    (con) => con.id === initialData?.concession
  )[0].name;

  const concessionForAllOperators = data?.breadCrumbsData?.concessions?.filter(
    (concession) => concession.id === ''
  )?.[0]?.name;

  const options =
    initialData?.concessions?.reduce((acc, curr) => {
      acc.push({
        label: curr?.name || '',
        value: curr?.id || '',
      });

      return acc;
    }, [] as GroupTypes[]) || [];

  const onSelectConcession = useCallback(
    (option: OptionSelected | undefined) => {
      option?.value !== ''
        ? operatorsTable.getColumn('concession')?.setFilterValue(option?.label)
        : operatorsTable.resetColumnFilters();
    },
    []
  );

  const onSelectState = useCallback((option: OptionSelected | undefined) => {
    option?.value === 'true' &&
      operatorsTable.getColumn('isActive')?.setFilterValue(true);
    option?.value === 'false' &&
      operatorsTable.getColumn('isActive')?.setFilterValue(false);
    option?.value === '' && operatorsTable.resetColumnFilters();
  }, []);

  const onSelectStateVehicle = useCallback(
    (option: OptionSelected | undefined) => {
      option?.value === 'true' &&
        vehiclesTable.getColumn('isActive')?.setFilterValue(true);
      option?.value === 'false' &&
        vehiclesTable.getColumn('isActive')?.setFilterValue(false);
      option?.value === '' && vehiclesTable.resetColumnFilters();
    },
    []
  );

  const onSelectVehicleConcession = useCallback(
    (option: OptionSelected | undefined) => {
      if (option?.value === '') {
        vehiclesTable.resetColumnFilters();
      } else {
        const concession = concessions.find(
          (concession) => concession.name === option?.value
        );

        vehiclesTable.getColumn('concession')?.setFilterValue(concession?.name);
      }
    },
    []
  );

  const operatorsTable = useReactTable({
    data: operatorsDataAll,
    columns: operatorsColumns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    initialState: {
      columnVisibility: {
        [operatorsColumnNames.isActive.id]: false,
      },
    },
  });

  const vehiclesTable = useReactTable({
    data: vehiclesDataTable,
    columns: vehiclesColumns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    initialState: {
      columnVisibility: {
        [vehiclesColumnNames.isActive.id]: false,
      },
    },
  });

  useEffect(() => {
    data?.flowData !== 'validation' ||
      (!data.flowData &&
        operatorsTable
          .getColumn('concession')
          ?.setFilterValue(defaultConcession));

    setDrivers(drivers.map((element) => element));
  }, []);

  useEffect(() => {
    setCacheDrivers && setCacheDrivers(drivers);
  }, [drivers]);

  useEffect(() => {
    const operatorsIDs = operators.map((operator) => operator.id);
    const indexDriver = drivers
      .map((driver, index) =>
        driver && !operatorsIDs.includes(driver.driverID) ? index : null
      )
      .filter((index) => index !== null);
    indexDriver.forEach(
      (index) =>
        isInternal &&
        setValue(
          `vehiclesTable[${index}].driver` as keyof OperatorsAndVehiclesFormValues,
          undefined
        )
    );
    setDefaultOperators(operators);

    const updatedDrivers: DriverList = [];
    drivers.forEach(
      (driver) =>
        operatorsIDs.includes(driver.driverID) && updatedDrivers.push(driver)
    );
    isInternal && setDrivers(updatedDrivers);

    setDefaultVehicles(vehicles);
  }, [operators, vehicles]);

  useLayoutEffect(() => {
    operatorsTable.getRowModel().rows.forEach((row) => {
      const defaultOperator = defaultOperators.find(
        (element) => element.id === row.original.id
      );
      if (defaultOperator) {
        const tableValues = getValues().internalOperatorsTable;
        if (tableValues[row.index]?.id !== defaultOperator.id) {
          const tableValuePosition = tableValues.findIndex(
            (element: { id: string }) => element?.id === row.original.id
          );
          setValue(
            `${tableName}.${tableValuePosition}` as keyof NewAction &
              OperatorsAndVehiclesFormValues,
            undefined
          );
          setValue(
            `${tableName}.${row.index}` as keyof NewAction &
              OperatorsAndVehiclesFormValues,
            defaultOperator
          );
        }
      }
      row.toggleSelected(
        defaultOperators.some((element) => element.id === row.original.id)
      );
    });

    vehiclesTable.getRowModel().rows.forEach((row) => {
      row.toggleSelected(
        vehicles.some((element) => element.vehicleID === row.original.id) ||
          vehicles.some((element) => element.id === row.original.id)
      );
    }, []);
  }, []);

  const path = location.pathname.split('/');
  const isValidation = path.includes('actions-validation');

  return (
    <ContainerTable>
      <OperatorsTableContainer>
        <Typography size="L" variant="semiBold">
          {titles && titles[0]}
        </Typography>
        <FiltersContainer>
          <DropDownFilterStyled
            inputSize={'XS'}
            onChangeSelected={(option) => onSelectConcession(option)}
            typeDropDown="Default"
            placeholder="Selec."
            defaultValue={
              data?.flowData === 'validation'
                ? concessionForAllOperators
                : defaultConcession
            }
            options={options}
          />
          {isValidation && (
            <DropDownFilterStyled
              inputSize={'XS'}
              onChangeSelected={(option) => onSelectState(option)}
              typeDropDown="Default"
              placeholder="Selec."
              options={[
                {
                  label: 'Tots',
                  value: '',
                },
                {
                  label: 'Actiu',
                  value: 'true',
                },
                {
                  label: 'Inactiu',
                  value: 'false',
                },
              ]}
            />
          )}
          <SearchBar
            variant="form"
            onChangeInput={(e) => operatorsHandleChange(e.target.value)}
          ></SearchBar>
        </FiltersContainer>
        <TableContainer>
          <Table>
            <Thead>
              {operatorsTable.getHeaderGroups().map((headerGroup) => (
                <Tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <TableLeftTh
                      key={header.id}
                      cell={header.id as variantLeftTh}
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                    </TableLeftTh>
                  ))}
                </Tr>
              ))}
            </Thead>
            <Tbody>
              {operatorsTable.getRowModel().rows.map((row) => (
                <Tr
                  key={row.id}
                  onClick={() => {
                    data?.parentExpeditionOrderStatus !== OrderState.Annulled &&
                      data?.parentExpeditionOrderStatus !== OrderState.End &&
                      data?.state !== OrderState.Annulled &&
                      handleOperatorsOnChange(row, `${tableName}.${row.index}`);
                  }}
                >
                  {row.getVisibleCells().map((cell) => (
                    <Td key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </Td>
                  ))}
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </OperatorsTableContainer>
      <VehiclesTableContainer>
        <Typography size="L" variant="semiBold">
          {titles && titles[1]}
        </Typography>
        <FiltersContainer>
          {isValidation && (
            <DropDownFilterStyled
              inputSize={'XS'}
              onChangeSelected={(option) => onSelectStateVehicle(option)}
              typeDropDown="Default"
              placeholder="Selec."
              options={[
                {
                  label: 'Tots els vehicles',
                  value: '',
                },
                {
                  label: 'Actiu',
                  value: 'true',
                },
                {
                  label: 'Inactiu',
                  value: 'false',
                },
              ]}
            />
          )}
          <DropDownFilterStyled
            inputSize={'XS'}
            onChangeSelected={(option) => onSelectVehicleConcession(option)}
            typeDropDown="Default"
            placeholder="Selec."
            options={[
              {
                label: 'Totes',
                value: '',
              },
              ...concessions.map((concession) => ({
                label: concession.name,
                value: concession.name,
              })),
            ]}
          />
          <SearchBar
            variant="form"
            onChangeInput={(e) => vehiclesHandleChange(e.target.value)}
          ></SearchBar>
        </FiltersContainer>
        <TableContainer>
          <Table>
            <Thead>
              {vehiclesTable.getHeaderGroups().map((headerGroup) => (
                <Tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <TableRightTh
                      cell={header.id as variantRightTh}
                      key={header.id}
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                    </TableRightTh>
                  ))}
                </Tr>
              ))}
            </Thead>
            <Tbody>
              {vehiclesTable.getRowModel().rows.map((row) => (
                <Tr
                  key={row.id}
                  onClick={() => {
                    data?.parentExpeditionOrderStatus !== OrderState.Annulled &&
                      data?.parentExpeditionOrderStatus !== OrderState.End &&
                      data?.state !== OrderState.Annulled &&
                      handleOnChange(row);
                  }}
                >
                  {row.getVisibleCells().map((cell) => (
                    <Td key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </Td>
                  ))}
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </VehiclesTableContainer>
    </ContainerTable>
  );
};

import React, { useLayoutEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

// Styles
import {
  AdviceContainer,
  ButtonContainer,
  ContainerError,
  GeneralContainer,
  TitleContainerWithButton,
} from '../styles';

// Components
import { Typography } from '../../../display/Typography';
import SimpleBackgroundSwitch from '../../../inputs/buttons/SwitchButton/SimpleBackground';
import { MainButton } from '../../../inputs/buttons/MainButton';
import { AnimalSelectionTable } from '../../../display/tables/formTables/AnimalSelectionTable';

// Types
import { type AnimalsFormType } from './types';

// Utilities
import { useCacheFormInSessionStorage } from '../../../../hooks/useCacheForm';
import { dataTable } from '../../../display/tables/formTables/AnimalSelectionTable/types';
import { handleAdd, handleDelete, handleUpdate } from '../formTableUtils';
import {
  compressSessionStorage,
  decompressSessionStorage,
} from '../../../../utils/sessionStorage';
import { DataState } from '../types';
import { OrderState } from '../../../../types/orders';

const AnimalsForm = ({
  onFormSubmit,
  initialData,
  sessionStorageKey,
  updateKey,
  textButton = 'Continuar',
}: AnimalsFormType) => {
  const [switchState, setSwitchState] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [dataFromTables, setDataFromTables] = useState<null | dataTable[]>(
    null
  );

  const { control, handleSubmit, setValue, register, getValues } = useForm({
    defaultValues: {
      ...initialData,
      isAnimalsTableDisplayed: initialData.isAnimalsTableDisplayed
        ? initialData.isAnimalsTableDisplayed
        : 'true',
      intent: 'pass-data',
    },
  });

  const updateTableKey = 'animalsData';

  useCacheFormInSessionStorage(sessionStorageKey, control);

  const switchStateValue = () => {
    const { isAnimalsTableDisplayed } = initialData;

    if (isAnimalsTableDisplayed) {
      return isAnimalsTableDisplayed === 'true';
    }

    return true;
  };

  useLayoutEffect(() => {
    setDataFromTables(
      (Object.values(initialData?.animalsTable ?? {}) ?? []).filter(
        (element) => element
      ) as dataTable[]
    );
    setSwitchState(switchStateValue());
  }, []);

  const handleChange = () => {
    if (switchState) {
      setSwitchState(false);
      setValue('isAnimalsTableDisplayed', 'false');
      setValue('animalsTable', null);
      if (updateKey) {
        const idsToDelete = Object.values(
          initialData?.animalsTable ?? {}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ).map((item: any) => item.animalUnitId);

        const currentCacheData = sessionStorage.getItem(updateKey)
          ? JSON.parse(
              decompressSessionStorage(
                sessionStorage.getItem(updateKey) as string
              )
            )
          : {};

        currentCacheData[updateTableKey] ??= {};
        currentCacheData[updateTableKey][DataState.deleted] = idsToDelete;
        currentCacheData[updateTableKey][DataState.added] = [];
        currentCacheData[updateTableKey][DataState.updated] = [];

        sessionStorage.setItem(
          updateKey,
          compressSessionStorage(JSON.stringify(currentCacheData))
        );
      }
    } else {
      setSwitchState(true);
      setValue('isAnimalsTableDisplayed', 'true');
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const validateAnimalsTable = (tableValues: any[]) => {
    const existAtLeastOneValue = Object.entries(tableValues ?? {}).some(
      ([, element]) => !!element
    );

    const allTheElementsHasUnits = Object.entries(tableValues ?? {}).every(
      ([, element]) =>
        element === undefined ? true : !!element && Number(element.ut) > 0
    );

    !existAtLeastOneValue
      ? setError('Afegiu almenys un element a la taula principal')
      : setError('Si us plau, ompliu tots els camps dels elements afegits');

    return existAtLeastOneValue && allTheElementsHasUnits;
  };

  const checkTableValues = () => {
    if (switchState) {
      const tableValues = getValues('animalsTable');
      return validateAnimalsTable(tableValues);
    }

    return true;
  };

  const handleUpdateItem = (
    eventValue: string | number,
    id: string,
    unitId: string,
    completeId: string,
    statusId: string
  ) => {
    const currentValue = eventValue;

    setValue(completeId, currentValue);

    if (!updateKey) {
      return;
    }

    handleUpdate(
      eventValue,
      id,
      unitId,
      initialData,
      updateTableKey,
      'animalsTable',
      updateKey,
      statusId
    );
  };

  const handleAddItem = (id: string, statusId: string) => {
    if (updateKey) {
      handleAdd(id, updateTableKey, updateKey, statusId);
    }
  };

  const handleDeleteItem = (id: string, unitId: string) => {
    if (!updateKey) {
      return;
    }

    handleDelete(id, unitId, updateTableKey, updateKey);
  };

  return (
    <GeneralContainer
      onSubmit={(e) => {
        if (checkTableValues()) {
          handleSubmit(onFormSubmit)(e);
        } else {
          const tableValues = getValues('animalsTable');
          validateAnimalsTable(tableValues);
          e.preventDefault();
        }
      }}
    >
      <TitleContainerWithButton>
        <Typography component="h3" variant="semiBold" size="L">
          Selecció dels animals
        </Typography>
        <SimpleBackgroundSwitch
          state={switchState}
          changeState={handleChange}
          disabled={
            initialData?.parentExpeditionOrderStatus === OrderState.Annulled ||
            initialData?.parentExpeditionOrderStatus === OrderState.End ||
            initialData?.state === OrderState.Annulled
          }
        />
      </TitleContainerWithButton>

      {switchState && dataFromTables ? (
        <AnimalSelectionTable
          data={initialData}
          control={control}
          animalStatus={initialData.animalAllStatus}
          setValue={setValue}
          register={register}
          defaultValues={dataFromTables as dataTable[]}
          onUpdate={handleUpdateItem}
          onAdd={updateKey ? handleAddItem : undefined}
          onDelete={updateKey ? handleDeleteItem : undefined}
        />
      ) : (
        <AdviceContainer>
          <Typography variant="semiBold" colorText="greyDark">
            No hi ha animals associats a l{`'`}actuació
          </Typography>
        </AdviceContainer>
      )}
      {error && switchState && (
        <ContainerError>
          <Typography component="p" size="S" colorText="lightCoralRed">
            {error}
          </Typography>
        </ContainerError>
      )}
      <ButtonContainer>
        <MainButton
          disabled={
            initialData?.parentExpeditionOrderStatus === OrderState.Annulled ||
            initialData?.parentExpeditionOrderStatus === OrderState.End ||
            initialData?.state === OrderState.Annulled
          }
          text={
            initialData?.parentExpeditionOrderStatus === OrderState.Annulled ||
            initialData?.parentExpeditionOrderStatus === OrderState.End ||
            initialData?.state === OrderState.Annulled
              ? 'Continuar'
              : textButton
          }
          type="submit"
        />
      </ButtonContainer>
    </GeneralContainer>
  );
};

export default AnimalsForm;

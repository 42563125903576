import styled from 'styled-components';

// Colors
import { colors } from '../../../../styles/colors';

export const TabsButton = styled.button`
  background-color: ${colors.garnet};
  border: none;
  border-radius: 50%;
  width: 2.2rem;
  height: 2.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ImgTabsButton = styled.img`
  width: 0.7rem;
  height: 1.2rem;
`;

import { defer, LoaderFunction } from 'react-router-dom';

// Constants
import { formActionNaming } from '../../../../../components/forms/ActionForms/constants';

// Utils
import { decompressSessionStorage } from '../../../../../utils/sessionStorage';

export const LocationLoader: LoaderFunction | undefined = async () => {
  const stringifyForm = sessionStorage.getItem(
    formActionNaming.NEW_LOCATION_FORM
  );

  const stringifyWorkOrderForm = sessionStorage.getItem(
    formActionNaming.NEW_ACTION_DATA
  );

  let parsedWorkOrderData;

  if (stringifyWorkOrderForm) {
    parsedWorkOrderData = JSON.parse(
      decompressSessionStorage(stringifyWorkOrderForm)
    );
  }

  let sessionStorageData;

  if (stringifyForm) {
    sessionStorageData = JSON.parse(decompressSessionStorage(stringifyForm));
  }

  const combinedData = {
    ...parsedWorkOrderData,
    ...sessionStorageData,
    breadCrumbsData: {
      orderParentCotic: parsedWorkOrderData.orderParentCotic,
      orderParentId: parsedWorkOrderData.orderParentId,
    },
  };

  return defer({
    data: combinedData,
  });
};

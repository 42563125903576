import { gql } from '@apollo/client';

export const CREATE_EXTERNAL_WORK = gql`
  mutation createExternalWork($input: NewExternalWork!) {
    createExternalWork(input: $input) {
      id
      # workName
    }
  }
`;

export const UPDATE_EXTERNAL_WORKS = gql`
  mutation updateExternalWork($input: UpdateExternalWork!) {
    updateExternalWork(input: $input) {
      id
      operatorsNumber
    }
  }
`;

export const DELETE_EXTERNAL_WORKS_BY_IDS = gql`
  mutation deleteManyExternalWorks($ids: [ID!]!) {
    deleteManyExternalWorks(ids: $ids) {
      id
      # workName
    }
  }
`;

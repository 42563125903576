import React, { useCallback, useEffect, useState } from 'react';
import { useMedia } from 'react-use';
import { useDispatch } from 'react-redux';

// Components
import { Typography } from '../Typography';
import { ButtonTabs } from '../../inputs/buttons/TabsButton';

// Types
import { dataTabs, tabsProps } from './types';

// Spaces
import { breakPoints, percentBaseResponsive } from '../../../styles/spaces';

// Components styled
import {
  Container,
  Button,
  ContainerSections,
  Section,
  ContainerTabsButtons,
  Li,
  Ul,
  ContainerUl,
} from './styles';

// Actions
import { tabs } from '../../../state/actions/ui/tabs';

export default function Tabs({
  data,
  defaultIndex,
  hasButtons,
  typeTab,
  setSelectedIndexTab,
  isFirstRemoved,
}: tabsProps): JSX.Element {
  const [selectedIndex, setSelectedIndex] = useState<number>(defaultIndex);
  const dispatch = useDispatch();

  const handleClick = useCallback((index: number) => {
    setSelectedIndex(index);
  }, []);

  useEffect(() => {
    dispatch(tabs(typeTab, selectedIndex));
    setSelectedIndexTab && setSelectedIndexTab(selectedIndex);
  }, [selectedIndex]);

  const getNextEnabledIndex = useCallback(
    (currentIndex: number, step: number): number => {
      const nextIndex = (currentIndex + step + data.length) % data.length;
      if (data[nextIndex].isDisabled) {
        return getNextEnabledIndex(nextIndex, step);
      } else {
        return nextIndex;
      }
    },
    []
  );

  const handleMoveRight = useCallback(() => {
    setSelectedIndex((prevIndex) => getNextEnabledIndex(prevIndex, 1));
  }, []);

  const handleMoveLeft = useCallback(() => {
    setSelectedIndex((prevIndex) => getNextEnabledIndex(prevIndex, -1));
  }, []);

  const isPortraiTable = useMedia(
    `(max-width: ${breakPoints.portraitTablet * percentBaseResponsive}rem)`
  );

  const renderTabs = useCallback(
    (item: dataTabs, index: number) => (
      <Li
        key={'tab-title-item-' + index}
        hidden={index === 0 && isFirstRemoved}
      >
        <Button
          isActive={selectedIndex === index}
          type={'button'}
          onClick={() => handleClick(index)}
          disabled={item.isDisabled}
        >
          <Typography
            size={'S'}
            variant={
              selectedIndex === index ||
              (selectedIndex !== index && !item.isDisabled)
                ? 'semiBold'
                : 'medium'
            }
            colorText={
              selectedIndex === index
                ? 'black'
                : item.isDisabled
                ? 'greySoftLight'
                : 'greyVeryDark'
            }
          >
            {item.label}
          </Typography>
        </Button>
      </Li>
    ),
    [selectedIndex]
  );

  return (
    <Container>
      <ContainerTabsButtons>
        {hasButtons ? (
          <ButtonTabs onClick={handleMoveLeft} variant="left" />
        ) : null}
        {isPortraiTable ? (
          <ContainerUl>
            <Ul>
              {data.map((item, index) => index <= 1 && renderTabs(item, index))}
            </Ul>
            {data.length > 2 && (
              <Ul>
                {data.map(
                  (item, index) => index >= 2 && renderTabs(item, index)
                )}
              </Ul>
            )}
          </ContainerUl>
        ) : (
          <Ul>{data.map((item, index) => renderTabs(item, index))}</Ul>
        )}
        {hasButtons ? (
          <ButtonTabs onClick={handleMoveRight} variant="right" />
        ) : null}
      </ContainerTabsButtons>
      <ContainerSections>
        <Section key={'tabPanel-item-' + selectedIndex}>
          {data[selectedIndex]?.content}
        </Section>
      </ContainerSections>
    </Container>
  );
}

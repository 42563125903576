import React, { SyntheticEvent, useCallback, useLayoutEffect } from 'react';
import {
  useNavigate,
  useActionData,
  useAsyncValue,
  useSubmit,
  useRevalidator,
} from 'react-router-dom';
import { format } from 'date-fns';

// Components Styled
import { Container } from './styles';

//Components
import ContentCardResume from '../../../../../components/display/ContentCardResume';

// Types
import { type NewCommunication } from '../../../../../types/communication';
import { createFormDataFromValues } from '../../../../../utils/forms';
import { OrderState } from '../../../../../types/orders';

export default function CommunicationSummary(): JSX.Element {
  const loaderData = useAsyncValue() as NewCommunication & {
    stateParentExpeditionOrder: string;
  };
  const actionData = useActionData() as NewCommunication;
  const navigate = useNavigate();
  const submit = useSubmit();
  const revalidator = useRevalidator();

  const communicationData = actionData ?? loaderData;
  useLayoutEffect(() => {
    if (!communicationData) navigate('..');
  }, [communicationData]);

  const handleEditCommunication = useCallback(() => {
    navigate(
      `../new-communication-duplicate${
        communicationData?.id ? '/' + communicationData?.id : ''
      }`
    );
  }, [communicationData?.id]);

  const handleSaveCommunication = async (e: SyntheticEvent) => {
    e.preventDefault();
    const formDataRaw: {
      intent: string;
      id?: string;
    } = {
      intent: 'save-data',
    };
    if (communicationData?.id) {
      formDataRaw.id = communicationData?.id;
    }
    const formData = createFormDataFromValues(formDataRaw);
    submit(formData, {
      method: 'POST',
      action: '.',
    });
  };

  const onRefetchData = () => {
    revalidator.revalidate();
  };
  return (
    <Container>
      {communicationData && (
        <ContentCardResume
          title="Resum de la comunicació"
          cotic={communicationData.cotic as string[]}
          operator={communicationData.operator}
          data={format(
            new Date(communicationData.registerInitDate as string),
            'dd / MM / yyyy'
          )}
          hour={format(
            new Date(communicationData.registerInitDate as string),
            'HH:mm'
          )}
          groupInter={communicationData.groupInter}
          media={communicationData.media}
          sendStatus={communicationData.sendStatus}
          capitol={communicationData.capitol}
          subCapitol={communicationData.subCapitol}
          classification={communicationData.classification}
          team={communicationData?.team}
          concession={communicationData.concession}
          road={communicationData.road}
          pkInit={`${communicationData.pkInit} km`}
          pkEnd={`${communicationData.pkEnd} km`}
          track={communicationData.track}
          roadDirections={communicationData.roadDirections}
          direction={communicationData?.direction}
          city={communicationData.city}
          lane={communicationData.lane}
          margin={communicationData?.margin}
          leftButton={'Editar comunicació'}
          rightButton={'Gravar Duplicat'}
          description={communicationData.description}
          onEditCommunication={handleEditCommunication}
          onSaveCommunication={handleSaveCommunication}
          storedDataOTSelected={communicationData.storedDataOTSelected}
          changeStateTag={false}
          state={communicationData.state}
          revalidatorFunction={onRefetchData}
          disabled={
            loaderData?.stateParentExpeditionOrder === OrderState.Annulled ||
            loaderData?.stateParentExpeditionOrder === OrderState.End
          }
        />
      )}
    </Container>
  );
}

import React from 'react';

type ArrowBottomProps = {
  className?: string;
};

export const AlertIcon = ({ className }: ArrowBottomProps) => {
  return (
    <svg
      width="57"
      height="56"
      viewBox="0 0 57 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M30.9351 18L30.4888 31.4728H27.1974L26.7232 18H30.9351ZM28.9268 38C28.2201 38 27.6344 37.7861 27.1695 37.3584C26.7232 36.9121 26.5 36.3636 26.5 35.7127C26.5 35.0618 26.7232 34.5225 27.1695 34.0948C27.6344 33.6485 28.2201 33.4254 28.9268 33.4254C29.6148 33.4254 30.182 33.6485 30.6283 34.0948C31.0746 34.5225 31.2978 35.0618 31.2978 35.7127C31.2978 36.3636 31.0746 36.9121 30.6283 37.3584C30.182 37.7861 29.6148 38 28.9268 38Z"
        fill="#721C34"
      />
      <rect
        x="2.5"
        y="2.5"
        width="52"
        height="51"
        rx="25.5"
        stroke="#721C34"
        strokeWidth="5"
      />
    </svg>
  );
};

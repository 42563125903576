import React, { useLayoutEffect } from 'react';

import { useAsyncValue, useNavigate } from 'react-router-dom';

// Components
import EditingActionForm from '../../../../../components/forms/ActionForms/ActionEdition';

// Constants
import { formActionNaming } from '../../../../../components/forms/ActionForms/constants';

// Types
import { type ActionEditionFormValues } from '../../../../../components/forms/ActionForms/ActionEdition/types';
import { type WorkOrder } from '../../../../../types/workOrder';
import { useDispatch } from 'react-redux';
import { coticOTSelected } from '../../../../../state/actions/ui/coticOTSelectedGeneralView';
import { OrderType } from '../../../../../types/orders';

export default function EditionFormPage(): JSX.Element {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loaderData = useAsyncValue() as ActionEditionFormValues & WorkOrder;

  useLayoutEffect(() => {
    dispatch(
      coticOTSelected(
        OrderType.Action,
        loaderData.breadCrumbsData.orderParentCotic,
        loaderData.breadCrumbsData.orderParentId
      )
    );
  }, []);

  const onFormSubmit = () => {
    navigate('../materials');
  };

  return (
    <EditingActionForm
      onFormSubmit={onFormSubmit}
      initialData={loaderData}
      namingPersistForm={formActionNaming.NEW_EDITION_FORM}
    />
  );
}

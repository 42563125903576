import { defer, LoaderFunction } from 'react-router-dom';

// Graphql
import { client } from '../../../../gql/client';
import { FETCH_COMMUNICATIONS } from '../../../../gql/queries/communication';

// Types
import { AuthContextType } from '../../../../hooks/useAuth';

// Selectors
import { selectCommunicationOrders } from '../../../../gql/selectors/communications';
import { store } from '../../../../state/configureStore';
import { getCursor } from '../../../../state/selectors/ui/tables';
import {
  tableIds,
  takeBase,
} from '../../../../components/display/tables/types';
import { GET_SELECTORS_DATA } from '../../../../gql/queries/orders/orders';
import { loadingData } from '../../../../state/actions/ui/loadingData';
import { setCursor } from '../../../../state/actions/ui/cursorTables';

export const SearchAndCreateHistoricalLoader: (
  userData: AuthContextType | null
) => LoaderFunction = (userData) => async () => {
  if (userData?.user) {
    const dispatch = store.dispatch;
    const cursorCommunication = getCursor(
      tableIds.SEARCH_AND_CREATE_HISTORICAL_COMMUNICATIONS
    )(store.getState());

    const readCommunications = client.readQuery({
      query: FETCH_COMMUNICATIONS,
      variables: {
        input: {
          take: cursorCommunication.take,
          cursor: cursorCommunication.cursor,
          orderBy: cursorCommunication.orderBy,
          roadId: cursorCommunication.roadId,
          concessionId: cursorCommunication.concessionId,
          interlocutorGroupId: cursorCommunication.interlocutorGroupId,
          fromDate: cursorCommunication.fromDate,
          toDate: cursorCommunication.toDate,
          indexType: cursorCommunication.indexType,
          indexExpedition: cursorCommunication.indexExpedition,
          classification: cursorCommunication?.classification,
        },
      },
    });

    const totalCount = readCommunications?.communications?.totalCount;
    const pageInfo = readCommunications?.communications?.pageInfo;

    const isCacheEmpty = JSON.stringify(readCommunications) === '{}';

    const ordersWithDataCommunication =
      readCommunications &&
      !isCacheEmpty &&
      (await selectCommunicationOrders(
        readCommunications?.communications?.communications
      ));
    const filtersData = await client.query({
      query: GET_SELECTORS_DATA,
    });

    const combinedData = {
      ordersWithDataCommunication,
      filtersData,
      totalCount,
      pageInfo,
    };
    if (!readCommunications || isCacheEmpty) {
      dispatch(
        loadingData(tableIds.SEARCH_AND_CREATE_HISTORICAL_COMMUNICATIONS, true)
      );
      let communications;
      if (isCacheEmpty) {
        communications = await client.query({
          query: FETCH_COMMUNICATIONS,
          variables: {
            input: {
              take: takeBase,
              cursor: '',
              orderBy: 'desc',
              roadId: cursorCommunication.roadId,
              concessionId: cursorCommunication.concessionId,
              interlocutorGroupId: cursorCommunication.interlocutorGroupId,
              fromDate: cursorCommunication.fromDate,
              toDate: cursorCommunication.toDate,
              indexType: cursorCommunication.indexType,
              indexExpedition: cursorCommunication.indexExpedition,
              classification: cursorCommunication?.classification,
            },
          },
        });

        const setCursorData = {
          take: takeBase,
          cursor: '',
          orderBy: 'desc',
          roadId: cursorCommunication.roadId,
          concessionId: cursorCommunication.concessionId,
          interlocutorGroupId: cursorCommunication.interlocutorGroupId,
          fromDate: cursorCommunication.fromDate,
          toDate: cursorCommunication.toDate,
          indexType: cursorCommunication.indexType,
          indexExpedition: cursorCommunication.indexExpedition,
          classification: cursorCommunication?.classification,
        };

        dispatch(
          setCursor(
            tableIds.SEARCH_AND_CREATE_HISTORICAL_COMMUNICATIONS,
            setCursorData
          )
        );
      } else {
        communications = await client.query({
          query: FETCH_COMMUNICATIONS,
          variables: {
            input: {
              take: cursorCommunication.take,
              cursor: cursorCommunication.cursor,
              orderBy: cursorCommunication.orderBy,
              roadId: cursorCommunication.roadId,
              concessionId: cursorCommunication.concessionId,
              interlocutorGroupId: cursorCommunication.interlocutorGroupId,
              fromDate: cursorCommunication.fromDate,
              toDate: cursorCommunication.toDate,
              indexType: cursorCommunication.indexType,
              indexExpedition: cursorCommunication.indexExpedition,
              classification: cursorCommunication?.classification,
            },
          },
        });
      }

      const totalCount = communications.data.communications.totalCount;
      const pageInfo = communications.data.communications.pageInfo;
      const ordersWithDataCommunication = await selectCommunicationOrders(
        communications.data.communications.communications
      );
      const loading = communications.data.loading;
      const filtersData = await client.query({
        query: GET_SELECTORS_DATA,
      });

      const combinedData = {
        ordersWithDataCommunication,
        filtersData,
        totalCount,
        pageInfo,
        loading,
      };

      dispatch(
        loadingData(tableIds.SEARCH_AND_CREATE_HISTORICAL_COMMUNICATIONS, false)
      );

      return defer({ data: combinedData });
    }
    return defer({ data: combinedData });
  }
  return {};
};

import { object, string } from 'yup';
import { ActionEditionFormValues } from './types';

export const EditingActionSchema = object<ActionEditionFormValues>({
  editionPredefine: string(),
  editionLaneCutting: string(),
  editionElementOrder: string().required(),
  editionCapitol: string().required(),
  editionSubCapitol: string().required(),
  editionDescription: string().required(),
  editionActivity: string().required(),
  editionImages: string().notRequired(),
  editionAsset: string().when(
    ['editionAssetSwitch'],
    ([editionAssetSwitch], schema) => {
      return editionAssetSwitch === 'true'
        ? schema.required()
        : schema.notRequired();
    }
  ),
});

import React, {
  useRef,
  useState,
  useEffect,
  useCallback,
  SetStateAction,
  Dispatch,
} from 'react';
import { useDispatch } from 'react-redux';
import { Control, DeepPartial, FieldErrors } from 'react-hook-form';
import imageCompression from 'browser-image-compression';

// Components Styled
import { Title } from '../components/MainStyled';
import {
  ContainerRow,
  HiddenInput,
  ContainerAttention,
  ContainerInputLoads,
  SketchImagesContainer,
  ImagesContainer,
  SketchContainer,
} from './styles';

//Components
import { AttentionAccidentTable } from '../../display/tables/formTables/AttentionAccidentTable';
import { MainButton } from '../../inputs/buttons/MainButton';
import { InputLoadImages } from '../../inputs/InputLoadImages';
import { Typography } from '../../display/Typography';
import { Loader } from '../../inputs/Loader';

// Redux
import { closeModal, openModal } from '../../../state/actions/ui/modal';
import { modalIds } from '../../modals/SuperModal/types';

// Types
import { type Accident } from '../../../types/accident';
import { type Sketch, type Img, type SetValueType, CustomFile } from './types';
import { ButtonIconBackground } from '../../inputs/buttons/ButtonIconBackground';
import { InputLoadFiles } from '../../inputs/InputLoadFiles';
import { type AttentioAccidentComunication } from '../../display/tables/formTables/AttentionAccidentTable/types';
import { OrderType } from '../../../types/orders';

// Constants
import { formAccidentNaming, directoryNaming } from './constants';

// Files
import { decompressSessionStorage } from '../../../utils/sessionStorage';
import {
  deleteFilesFromDirectory,
  getFilesFromDirectory,
  saveFilesIntoADirectory,
  dataURItoBlob,
  generateFileName,
  deleteWholeDirectory,
} from '../../../utils/files';

export const AccidentAttention = ({
  control,
  setValue,
  errors,
  defaultValues,
  accidentDate,
  setIsLoadingFiles,
  isLoadingFiles,
}: {
  control: Control<Accident>;
  errors: FieldErrors<Accident>;
  defaultValues: Readonly<DeepPartial<Accident>> | undefined;
  accidentDate: string;
  setIsLoadingFiles: Dispatch<SetStateAction<boolean>>;
  isLoadingFiles: boolean;
} & SetValueType) => {
  const dispatch = useDispatch();

  // References
  const inputRef = useRef<HTMLInputElement>(null);

  // States
  const [selectedImages, setSelectedImages] = useState<Img[]>([]);
  const [sketch, setSketch] = useState<Sketch | null>(null);
  const [selectedFiles, setSelectedFiles] = useState<CustomFile[]>([]);

  const getAccidentTableValues = () => {
    const keys: (keyof AttentioAccidentComunication)[] = [
      'noticeConservationCenterDate',
      'arrivalConservationCenterDate',
      'departureConservationCenterDate',
      'noticeControlCenterDate',
      'arrivalControlCenterDate',
      'departureControlCenterDate',
      'noticeSquadBoysDate',
      'arrivalSquadBoysDate',
      'departureSquadBoysDate',
      'noticeAmbulanceCraneAssistanceDate',
      'arrivalAmbulanceCraneAssistanceDate',
      'departureAmbulanceCraneAssistanceDate',
      'noticeRoadTeamDate',
      'arrivalRoadTeamDate',
      'departureRoadTeamDate',
      'noticeOtherDate',
      'arrivalOtherDate',
      'departureOtherDate',
      'otherName',
    ];

    const values = keys.reduce((acc, key) => {
      acc[key] =
        (defaultValues as AttentioAccidentComunication)?.[key] || undefined;
      return acc;
    }, {} as AttentioAccidentComunication);

    return values;
  };

  useEffect(() => {
    const getFiles = async () => {
      const filesFromDirectory = await getFilesFromDirectory(
        OrderType.Accident
      );

      setSelectedFiles(filesFromDirectory);
    };

    getFiles();

    if (defaultValues?.images) {
      const parsedArray: Img[] = JSON.parse(defaultValues?.images as string);
      setSelectedImages(parsedArray);
    }
    if (defaultValues?.sketch) {
      const parsedSketch = JSON.parse(defaultValues.sketch);
      setSketch(parsedSketch);
    }
  }, []);

  useEffect(() => {
    const fieldsToSet = [
      {
        name: 'images',
        value: selectedImages.length > 0 ? selectedImages : null,
      },
      { name: 'sketch', value: sketch },
    ];

    fieldsToSet.forEach((field) => {
      const { name, value } = field;
      setValue(name as keyof Accident, value ? JSON.stringify(value) : null);
    });
  }, [selectedImages, sketch]);

  useEffect(() => {
    const files = selectedFiles.map((file) => file.file);
    saveFilesIntoADirectory(OrderType.Accident, files);
  }, [selectedFiles]);

  const handleAddSketch = useCallback(() => {
    const sketchDataRaw = sessionStorage.getItem(
      formAccidentNaming.IMAGE_CANVAS
    );
    if (sketchDataRaw) {
      const sketchData = decompressSessionStorage(sketchDataRaw);
      const newSketch: Sketch = {
        id: sketchData,
        date: new Date().toISOString(),
        road: defaultValues?.road ?? '',
      };

      setSketch(newSketch);

      const sketchBlob = dataURItoBlob(sketchDataRaw);

      const sketchFile = new File(
        [sketchBlob],
        generateFileName(
          OrderType.Accident.toUpperCase(),
          formAccidentNaming.ADD_BASIC_INFO_ACCIDENT_FORM
        ),
        {
          type: sketchBlob.type,
        }
      );
      saveFilesIntoADirectory(directoryNaming.CANVAS, [sketchFile]);
      sessionStorage.removeItem(formAccidentNaming.IMAGE_CANVAS);
      sessionStorage.removeItem(formAccidentNaming.SVG_CANVAS);
      sessionStorage.removeItem(formAccidentNaming.ARRAY_CANVAS);
    }
  }, []);

  const openSketchModal = () => {
    dispatch(
      openModal(modalIds.ADD_SKETCH_MODAL, {
        handleAddSketch: () => {
          handleAddSketch();
          dispatch(closeModal());
        },
      })
    );
  };

  const handleChoosePick = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const files = event.target.files;
      setIsLoadingFiles(true);
      if (files) {
        const newImages: Img[] = [];
        const newArrayFiles: CustomFile[] = [];

        for (const file of files) {
          const id = file.name;
          const sizeInMB = file.size / 1024 / 1000;
          let fileToSave = file;
          if (sizeInMB > 3) {
            const blob = await imageCompression(file, {
              maxSizeMB: 3,
            });

            fileToSave = new File([blob], blob.name, {
              type: blob.type,
              lastModified: blob.lastModified,
            });
          }

          const isDuplicate = selectedFiles.some((img) => img.id === id);

          if (!isDuplicate) {
            newImages.push({
              id,
              date: new Date(file.lastModified).toISOString(),
              name: file.name,
            });

            newArrayFiles.push({
              id,
              file: fileToSave,
            });
          }
        }

        setSelectedImages((prevImages) => [...prevImages, ...newImages]);
        setSelectedFiles((prevFiles) => [...prevFiles, ...newArrayFiles]);
      }

      setIsLoadingFiles(false);
    },
    [selectedFiles]
  );

  const handleRemoveImage = (id: string) => {
    setSelectedImages((currentImages) =>
      currentImages.filter((image) => image.id !== id)
    );

    setSelectedFiles((currentFiles) =>
      currentFiles.filter((file) => file.id !== id)
    );

    deleteFilesFromDirectory(OrderType.Accident, id);
  };

  const handleOpenCamera = useCallback(() => {
    dispatch(
      openModal(modalIds.OPEN_CAMERA_MODAL, {
        setImagesState: setSelectedImages,
        setFilesState: setSelectedFiles,
        dataBaseNamingCaching: formAccidentNaming.ADD_BASIC_INFO_ACCIDENT_FORM,
        type: OrderType.Accident.toUpperCase(),
      })
    );
  }, []);

  const handleAddImage = () => {
    dispatch(
      openModal(modalIds.ADD_MULTIMEDIA_MODAL, {
        handleOpenCamera: handleOpenCamera,
        handleChoosePick: () => {
          inputRef?.current?.click();
          dispatch(closeModal());
        },
      })
    );
  };

  const handleRemoveSketch = () => {
    setSketch(null);
    deleteWholeDirectory(directoryNaming.CANVAS);
  };

  return (
    <ContainerAttention>
      <ContainerRow>
        <Title component="h3" variant="semiBold" size="L">
          Atenció a l’accident
        </Title>
        <AttentionAccidentTable
          disabled={false}
          control={control}
          defaultValues={getAccidentTableValues()}
          errors={errors}
          accidentDate={accidentDate}
        />
      </ContainerRow>
      <SketchImagesContainer>
        <SketchContainer>
          <Typography component="h3" variant="semiBold" size="L">
            Croquis de l’accident
          </Typography>
          {!sketch ? (
            <MainButton
              text="+ Afegir croquis"
              type="button"
              onClick={openSketchModal}
            />
          ) : (
            <ContainerInputLoads>
              <InputLoadFiles
                name={'Accident'}
                {...sketch}
                onRemove={handleRemoveSketch}
              />
              <ButtonIconBackground
                variant="edit"
                type="button"
                onClick={openSketchModal}
              />
            </ContainerInputLoads>
          )}
        </SketchContainer>
        <ImagesContainer>
          <Typography component="h3" variant="semiBold" size="L">
            Fotografíes de l’accident
          </Typography>
          {selectedImages.length === 0 ? (
            !isLoadingFiles ? (
              <MainButton
                text="+ Afegir imatges"
                type="button"
                onClick={handleAddImage}
              />
            ) : (
              <Loader />
            )
          ) : (
            <ContainerInputLoads>
              <InputLoadImages
                imageData={selectedImages}
                removeItem={handleRemoveImage}
              />
              <ContainerRow>
                <ButtonIconBackground
                  variant="add"
                  type="button"
                  onClick={handleAddImage}
                />
              </ContainerRow>
            </ContainerInputLoads>
          )}
          {!!errors?.images && selectedImages.length === 0 && (
            <Typography colorText="lightCoralRed" size="XS">
              {errors?.images.message}
            </Typography>
          )}
        </ImagesContainer>
        <HiddenInput
          type="file"
          accept="image/*"
          multiple
          ref={inputRef}
          onChange={handleChoosePick}
        />
      </SketchImagesContainer>
    </ContainerAttention>
  );
};

// Dependencies
import React, {
  useState,
  useImperativeHandle,
  useRef,
  forwardRef,
  useCallback,
  Ref,
} from 'react';

// Types
import { DropDownProps, ImperativeRefList, OptionSelected } from './types';

// Components Styled
import { DropDownListContainer, List, ListItem } from './styles';

const DropDownList = (
  {
    defaultVisible,
    options,
    typeDropDown,
    selectedOption,
    setSelectedAndCallback,
    ...props
  }: DropDownProps,
  innerRef: Ref<ImperativeRefList>
): JSX.Element => {
  const listRef = useRef<HTMLDivElement>(null);

  const [visible, setVisible] = useState<boolean>(defaultVisible ?? false);

  const clickOption = useCallback((option: OptionSelected) => {
    setSelectedAndCallback(option);
    setVisible(false);
  }, []);

  const setVisibleOut = useCallback(() => setVisible(!visible), [visible]);

  useImperativeHandle(innerRef, () => ({
    setVisibleDropDown: setVisibleOut,
    setHidden: () => setVisible(false),
  }));

  return (
    <DropDownListContainer ref={listRef} {...props}>
      {visible ? (
        <List role={'select'} typeDropDown={typeDropDown}>
          {options.map((option, index) => (
            <ListItem
              onBlur={() => index === options.length - 1 && setVisible(false)}
              tabIndex={0}
              type={typeDropDown}
              value={option?.value}
              role={'option'}
              isActive={selectedOption?.value === option.value}
              onClick={() => {
                clickOption(option);
              }}
              key={index}
              onKeyDown={(event) => {
                if (event.key === ' ') {
                  event.preventDefault();
                  clickOption(option);
                }
              }}
            >
              {option.label}
            </ListItem>
          ))}
        </List>
      ) : null}
    </DropDownListContainer>
  );
};

export default forwardRef(DropDownList);

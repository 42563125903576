import React from 'react';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import isEqual from 'lodash.isequal';

// Utils
import { useCacheFormInSessionStorage } from '../../../hooks/useCacheForm';
import { newCommunicationSchema } from './schema';

// Types
import { NewCommunication } from '../../../types/communication';
import { Props } from './types';

// Components
import BasicInfo from './BasicInfo';
import { Description } from './Description';
import { CreateOT } from './CreateOT';

// Styles
import { DoubleColumnContainer } from './styles';

// Redux
import { getCurrentIndexTabFormByTab } from '../../../state/selectors/ui/tabs';
import { getCurrentRowDataModal } from '../../../state/selectors/ui/modal';
import {
  ButtonsContainer,
  ContainerForm,
  FieldsContainer,
} from '../components/MainStyled';
import { MainButton } from '../../inputs/buttons/MainButton';

const NewCommunicationForm = ({
  onFormSubmit,
  initialData,
  namingPersistForm,
  namingTabsType,
  isNecessaryTagSpecial = true,
}: Props) => {
  const selectorIndexTab = useSelector(
    getCurrentIndexTabFormByTab(namingTabsType),
    isEqual
  );

  const selectorDataOT = useSelector(
    getCurrentRowDataModal(namingPersistForm),
    isEqual
  );

  const {
    handleSubmit,
    register,
    control,
    setValue,
    formState: { errors },
  } = useForm<NewCommunication>({
    defaultValues: {
      ...initialData,
      intent: 'pass-data',
      registerInitDate:
        namingPersistForm === 'new-duplicate-communication-form'
          ? new Date().toISOString()
          : initialData.registerInitDate,
    },
    resolver: yupResolver(newCommunicationSchema),
    context: {
      index: selectorIndexTab,
      dataOT: selectorDataOT?.data,
      isCommunicationDuplicate: initialData.isCommunicationDuplicate ?? false,
    },
  });

  useCacheFormInSessionStorage<NewCommunication>(namingPersistForm, control);

  return (
    <ContainerForm onSubmit={handleSubmit(onFormSubmit)}>
      <FieldsContainer>
        <BasicInfo control={control} register={register} errors={errors} />

        <DoubleColumnContainer>
          <Description
            control={control}
            setValue={setValue}
            register={register}
            errors={errors}
          />
          <CreateOT
            control={control}
            setValue={setValue}
            register={register}
            errors={errors}
            namingPersistForm={namingPersistForm}
            namingTabsType={namingTabsType}
            selectorIndexTab={selectorIndexTab}
            isNecessaryTagSpecial={isNecessaryTagSpecial}
          />
        </DoubleColumnContainer>
      </FieldsContainer>

      <ButtonsContainer>
        <MainButton text="Continuar" type="submit" />
      </ButtonsContainer>
    </ContainerForm>
  );
};

export default NewCommunicationForm;

import { defer, LoaderFunction } from 'react-router-dom';

// Utils
import { AuthContextType } from '../../../../hooks/useAuth';
import { decompressSessionStorage } from '../../../../utils/sessionStorage';

// Types
import { AddBasicInfoAccidentWithDynamicKeys } from './types';

// Constants
import { formVehicleNaming } from '../../../../components/forms/AddVehicleForm/constants';
import { formAccidentNaming } from '../../../../components/forms/AddBasicInfoAccidentForm/constants';
import { Accident } from '../../../../types/accident';

export const VehicleLoader: (
  userData: AuthContextType | null
) => LoaderFunction | undefined = (userData) => async () => {
  const stringifyForm = sessionStorage.getItem(
    formVehicleNaming.NEW_VEHICLE_FORM
  );

  const stringifyAccidentForm = sessionStorage.getItem(
    formAccidentNaming.NEW_ACCIDENT_FORM
  );

  if (stringifyForm && stringifyAccidentForm && userData) {
    const sessionStorageData: AddBasicInfoAccidentWithDynamicKeys = JSON.parse(
      decompressSessionStorage(stringifyForm)
    );

    const accidentData: Accident = JSON.parse(
      decompressSessionStorage(stringifyAccidentForm)
    );

    const combinedData = {
      ...sessionStorageData,
      orderParentCotic: accidentData.orderParentCotic,
      orderParentId: accidentData.orderParentId,
    };

    return defer({
      data: combinedData,
    });
  }

  if (stringifyAccidentForm && userData) {
    const accidentData: Accident = JSON.parse(
      decompressSessionStorage(stringifyAccidentForm)
    );

    const combinedData = {
      orderParentCotic: accidentData.orderParentCotic,
      orderParentId: accidentData.orderParentId,
    };

    return defer({
      data: combinedData,
    });
  }

  return defer({
    data: null,
  });
};

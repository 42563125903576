import styled from 'styled-components';
import { colors } from '../../../../styles/colors';

// Utils
import { ButtonIconBackground } from '../../../inputs/buttons/ButtonIconBackground';
import {
  breakPoints,
  percentBaseResponsive,
  spaces,
} from '../../../../styles/spaces';
import { Row } from '../styles';

// Components

export const MainContainer = styled.div`
  display: flex;
  gap: ${spaces.m}rem;
  @media (max-width: ${breakPoints.landscapeTablet *
    percentBaseResponsive}rem) {
    flex-direction: column;
  }
`;

export const MapContainer = styled.div`
  width: 100%;
  max-width: 78rem;
  @media (max-width: ${breakPoints.smallDesktop * percentBaseResponsive}rem) {
    max-width: 50rem;
  }

  @media (max-width: ${breakPoints.landscapeTablet *
    percentBaseResponsive}rem) {
    height: 45rem;
    max-width: initial;
  }

  @media (max-width: ${breakPoints.portraitTablet * percentBaseResponsive}rem) {
    height: 50rem;
  }
`;

export const RowStyled = styled(Row)`
  align-items: flex-start;
  @media (max-width: ${breakPoints.portraitTablet * percentBaseResponsive}rem) {
    flex-wrap: wrap;
  }
`;

export const RoadInputsGroup = styled.div`
  display: flex;
  gap: 1rem;
`;

export const GreenButton = styled(ButtonIconBackground)`
  align-self: center;
  background: ${colors.green};
`;

export const BlackButton = styled(ButtonIconBackground)`
  align-self: center;
  background: ${colors.black};
`;

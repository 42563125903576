import styled from 'styled-components';
import { RowsContainer } from '../styles';
import { MainButton } from '../../../inputs/buttons/MainButton';
import {
  breakPoints,
  percentBaseResponsive,
  spaces,
} from '../../../../styles/spaces';

export const InputsContainer = styled.div`
  display: flex;
  gap: ${spaces.l}rem 9.8rem;
  @media (max-width: ${breakPoints.smallDesktop * percentBaseResponsive}rem) {
    flex-wrap: wrap;
  }
  @media (max-width: ${breakPoints.landscapeTablet *
    percentBaseResponsive}rem) {
    flex-direction: column;
    gap: ${spaces.l}rem;
  }
`;

export const SwitchContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${spaces.xxs}rem;
`;

export const RowsContainerLeft = styled(RowsContainer)``;

export const RowsContainerRight = styled(RowsContainer)`
  max-width: 65.4rem;
  min-width: 36.6rem;
  flex: 1 1 0;
`;

export const AddImagesButton = styled(MainButton)`
  align-self: flex-start;
`;

export const ImagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spaces.xxxs}rem;
`;

export const NormalContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spaces.xxxs}rem;
`;

export const HiddenInput = styled.input`
  display: none;
`;

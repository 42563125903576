import React, { useCallback, useLayoutEffect } from 'react';
import { useAsyncValue, useNavigate, useRevalidator } from 'react-router-dom';

// Components
import { ShowOT } from '../../../../components/display/ShowOT/';

// Types
import { OrderDataTypes } from '../../../../components/display/ShowOT/types';
import { useDispatch } from 'react-redux';
import { coticOTSelected } from '../../../../state/actions/ui/coticOTSelectedGeneralView';
import { OrderType } from '../../../../types/orders';

export default function WorkOrderDetailed(): JSX.Element {
  const workOrderData = useAsyncValue() as OrderDataTypes[];
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const revalidator = useRevalidator();

  useLayoutEffect(() => {
    if (!workOrderData) navigate('/work-orders');
    dispatch(
      coticOTSelected(
        workOrderData[0].type === OrderType.Campaign
          ? OrderType.Campaign
          : OrderType.Generic,
        workOrderData[0].cotic as unknown as string,
        workOrderData[0].id
      )
    );
  }, [workOrderData]);

  const onRefetchData = useCallback(() => {
    revalidator.revalidate();
  }, []);

  return (
    <ShowOT data={workOrderData || []} revalidatorFunction={onRefetchData} />
  );
}

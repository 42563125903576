// Dependencies
import React, { useEffect } from 'react';
import { useActionData, useSubmit, useRevalidator } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

// Styles
import {
  ContainerForm,
  ContainerLogo,
  ContainerOra,
  LinkPassword,
  LinkPolicy,
  LogoCedinsa,
  OraLogo,
  InputTextPassword,
  PrincipalButton,
  Title,
  TextInput,
  ContainerLinkPassword,
} from './styles';

import CedinsaLogo from '../../../assets/logo-cedinsa.svg';
import LogoOra from '../../../assets/logo.svg';

// Components
import { Typography } from '../../display/Typography';

// Schemas
import { LoginFormSchema } from './schema';

// Types
import { LoginFormType } from './types';

// Utils
import { createFormDataFromValues } from '../../../utils/forms';

// Media
import { useIsLandscapeTablet } from '../../../hooks/useMediaBreakPoints';

export default function LoginForm() {
  const isLandscapeTablet = useIsLandscapeTablet();
  const errorsAction = useActionData() as null | string;
  const revalidator = useRevalidator();

  const {
    handleSubmit,
    register,
    formState: { errors, touchedFields },
    reset,
  } = useForm<LoginFormType>({
    mode: 'onTouched',
    reValidateMode: 'onBlur',
    defaultValues: {
      email: '',
      password: '',
    },
    resolver: yupResolver(LoginFormSchema),
  });

  const submit = useSubmit();

  useEffect(() => {
    if (
      typeof errorsAction === 'string' &&
      errorsAction.length > 0 &&
      (touchedFields?.email || touchedFields?.password)
    ) {
      revalidator.revalidate();
    }
  }, [errorsAction, touchedFields?.email, touchedFields?.password]);

  const onFormSubmit = (values: LoginFormType) => {
    const formData = createFormDataFromValues(values);

    submit(formData, {
      method: 'post',
      action: '/login',
    });
    reset(values);
  };

  return (
    <>
      <ContainerLogo>
        <LogoCedinsa src={CedinsaLogo} alt="Logo Cedinsa" />
      </ContainerLogo>
      <ContainerForm onSubmit={handleSubmit(onFormSubmit)}>
        <Title variant="semiBold">Iniciar sessió</Title>
        <TextInput
          {...register('email')}
          label="Correu electrònic"
          labelSize={isLandscapeTablet ? 'XS' : 'S'}
          typeInput="email"
          type="text"
          placeholder="nom@email.com"
          autoComplete="email"
          error={errors.email?.message}
        />
        <InputTextPassword
          {...register('password')}
          label="Contrasenya"
          labelSize={isLandscapeTablet ? 'XS' : 'S'}
          typeInput="password"
          type="password"
          placeholder="Contrasenya"
          autoComplete="current-password"
          error={
            (errors?.password?.message
              ? errors?.password?.message
              : errorsAction) as string
          }
        />
        <ContainerLinkPassword>
          <LinkPassword to={'#'}>
            <Typography size={isLandscapeTablet ? 'XXXS' : 'XS'}>
              He oblidat la contrasenya
            </Typography>
          </LinkPassword>
        </ContainerLinkPassword>
        <PrincipalButton type={'submit'} text="Inicia sessió" />
        <LinkPolicy to={'#'}>
          <Typography size={isLandscapeTablet ? 'XXXS' : 'XS'}>
            En ingressar estàs acceptant les nostres
            <b>Polítiques de Privadesa</b>
          </Typography>
        </LinkPolicy>
      </ContainerForm>
      <ContainerOra>
        <OraLogo src={LogoOra} alt="Logo Ora" />
      </ContainerOra>
    </>
  );
}

import React, { useCallback, useEffect, useMemo, useState } from 'react';

import {
  useReactTable,
  getCoreRowModel,
  flexRender,
  getFilteredRowModel,
  createColumnHelper,
} from '@tanstack/react-table';

// Mocks
import { machineryMocksData } from './mocks';

// Types
import {
  TableProps,
  dataTable,
  SearchbarFilterTypes,
  machineryColumnNames,
} from './types';

// Components
import SearchBar from '../../../../../inputs/SearchBar';
import { Typography } from '../../../../Typography';

// Styled components
import {
  ContainerTable,
  FiltersContainer,
  MachineryTableContainer,
  TrStyled,
} from './styles';
import {
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
} from '../../../summaryTables/SummaryTablesStyles';

export const MachineryManagementTable = ({ data }: TableProps): JSX.Element => {
  const machinery = data?.machinery ?? machineryMocksData;
  const [machineryData, setMachineryData] = useState(machinery);

  const [filteredMachinery, setFilteredMachinery] = useState(false);

  const machineryColumnHelper = createColumnHelper<dataTable>();

  useEffect(() => {
    setMachineryData(machinery);
  }, [data]);

  const machineryColumns = useMemo(
    () => [
      machineryColumnHelper.accessor((row) => row.auxMachineId, {
        id: machineryColumnNames.auxMachineId.id,
        header: () => machineryColumnNames.auxMachineId.label,
        cell: (info) => info.getValue(),
      }),
      machineryColumnHelper.accessor((row) => row.name, {
        id: machineryColumnNames.name.id,
        header: () => machineryColumnNames.name.label,
      }),
      machineryColumnHelper.accessor((row) => row.type, {
        id: machineryColumnNames.type.id,
        header: () => machineryColumnNames.type.label,
      }),
      machineryColumnHelper.accessor((row) => row.typeOfMeasurement, {
        id: machineryColumnNames.typeOfMeasurement.id,
        header: () => machineryColumnNames.typeOfMeasurement.label,
      }),
    ],
    [machineryData, data]
  );

  const machinerySearchFilter = useCallback<SearchbarFilterTypes>(
    (value) => {
      machineryTable.getColumn('auxMachineId')?.setFilterValue(value);
      value === '' && setFilteredMachinery((prev) => !prev);
    },
    [filteredMachinery]
  );

  const machineryTable = useReactTable({
    data: machineryData,
    columns: machineryColumns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    enableRowSelection: true,
    enableMultiRowSelection: false,
  });

  return (
    <ContainerTable>
      <MachineryTableContainer>
        <Typography size="L" variant="semiBold">
          Maquinària
        </Typography>
        <FiltersContainer>
          <SearchBar
            variant="form"
            onChangeInput={(e) => machinerySearchFilter(e.target.value)}
          />
        </FiltersContainer>
        <TableContainer>
          <Table>
            <Thead>
              {machineryTable.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <Th key={header.id}>
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                    </Th>
                  ))}
                </tr>
              ))}
            </Thead>
            <Tbody>
              {machineryTable.getRowModel().rows.map((row) => (
                <TrStyled
                  key={row.id}
                  checked={row.getIsSelected()}
                  indeterminate={!machineryTable.getIsSomeRowsSelected()}
                >
                  {row.getVisibleCells().map((cell) => (
                    <Td key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </Td>
                  ))}
                </TrStyled>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </MachineryTableContainer>
    </ContainerTable>
  );
};

import styled from 'styled-components';

// Dependencies
import { Link } from 'react-router-dom';

// Colors
import { colors } from '../../../styles/colors';

// Components
import { Typography } from '../../display/Typography';
import { MainButton } from '../../inputs/buttons/MainButton';

// Styles utils
import { breakPoints, percentBaseResponsive } from '../../../styles/spaces';

export const ContainerLogoArrow = styled.div`
  display: flex;
  flex: 0 0 50%;
  width: 40%;
  justify-content: space-between;

  @media (max-width: ${breakPoints.portraitTablet * percentBaseResponsive}rem) {
    width: 100%;
    flex: initial;
  }
`;

export const LogoCedinsa = styled.img`
  width: 15.254rem;
  height: 4.3rem;
  margin-top: -1.6rem;

  @media (max-width: ${breakPoints.landscapeTablet *
    percentBaseResponsive}rem) {
    width: 11.593rem;
    height: 3.265rem;
    margin-top: -1rem;
  }
  @media (max-width: ${breakPoints.portraitTablet * percentBaseResponsive}rem) {
    width: 11.593rem;
    height: 3.265rem;
    margin-top: 0;
  }
`;

export const ContainerForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 60%;
  flex: 0 0 100%;
  order: 2;

  @media (max-width: ${breakPoints.portraitTablet * percentBaseResponsive}rem) {
    order: 0;
    width: 100%;
    flex: initial;
    margin-top: 5rem;
    margin-bottom: 3.9rem;
  }
`;

export const Title = styled(Typography)`
  font-size: 2.6rem;
  margin-bottom: 3.979rem;

  @media (max-width: ${breakPoints.landscapeTablet *
    percentBaseResponsive}rem) {
    font-size: 2.2rem;
    margin-bottom: 1.9rem;
  }
`;

export const Infotext = styled(Typography)`
  margin-top: 1.2rem;
  margin-bottom: 2.732rem;
  width: 100%;
  text-align: justify;
  font-size: 1.2rem;
  color: ${colors.greySoftLight};
  @media (max-width: ${breakPoints.landscapeTablet *
    percentBaseResponsive}rem) {
    font-size: 1rem;
  }
`;

export const PasswordButton = styled(MainButton)`
  display: flex;
  justify-content: center;
  margin-top: 4.025rem;
  margin-bottom: 1.6rem;
  width: 100%;

  @media (max-width: ${breakPoints.smallDesktop * percentBaseResponsive}rem) {
    margin-top: 4.058rem;
  }
  @media (max-width: ${breakPoints.landscapeTablet *
    percentBaseResponsive}rem) {
    margin-top: 3rem;
    padding: 1.7rem;
  }
`;

export const LinkPolicy = styled(Link)`
  text-decoration: none;
`;

export const ArrowBack = styled.img`
  width: 1.6rem;
  height: 1.2rem;
`;

export const ArrowBackLink = styled(Link)`
  margin-top: 1.45rem;
  margin-right: 2.4rem;

  img {
    width: 1.6rem;
    height: 1.2rem;
  }
`;

export const ContainerOra = styled.div`
  display: flex;
  width: 40%;
  flex: 0 0 50%;

  @media (max-width: ${breakPoints.portraitTablet * percentBaseResponsive}rem) {
    width: 100%;
    flex: initial;
  }
`;

export const OraLogo = styled.img`
  width: 11rem;
  height: 3.236rem;
  align-self: flex-end;

  @media (max-width: ${breakPoints.portraitTablet * percentBaseResponsive}rem) {
    width: 7.353rem;
    height: 2.156rem;
  }
`;

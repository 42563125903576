import React from 'react';
import debounce from 'lodash.debounce';

import {
  ContainerCircles,
  CirclePagination,
  PaginationButton,
  PaginationButtonPrev,
  PaginationButtonNext,
  PaginationContainer,
  Icon,
  IconInit,
  IconEnd,
  CountTotal,
} from './styles';

import { PaginationProps } from './types';

import NextPageIcon from '../../../../../assets/icons/next-page-icon.svg';
import PrevPageIcon from '../../../../../assets/icons/prev-page-icon.svg';

export const PaginationButtons = ({
  currentPage,
  pageCount,
  totalCount,
  pageIndex,
  previousPage,
  nextPage,
  firstPage,
  lastPage,
  className,
  isDisabled,
}: PaginationProps): JSX.Element => {
  // Logic for Circle Values: The value of the second circle is determined
  // by the page number, except for the first and last page, in which case
  // the value of the first and third circle respectively corresponds to
  // the page number.
  // If there are just 1 or 2 pages there are just 1 or 2 circles respectively

  const valueCircle1 =
    pageCount === 2
      ? 1
      : currentPage === 1
      ? 1
      : currentPage === pageCount
      ? currentPage - 2
      : currentPage - 1;
  const valueCircle2 =
    pageCount === 2
      ? 2
      : currentPage == 1
      ? currentPage + 1
      : currentPage === pageCount
      ? currentPage - 1
      : currentPage;
  const valueCircle3 =
    currentPage === 1
      ? currentPage + 2
      : currentPage === pageCount
      ? currentPage
      : currentPage + 1;
  return (
    <PaginationContainer className={className}>
      {pageCount > 1 && (
        <>
          <PaginationButton
            onClick={debounce(firstPage as () => void, 500, {
              leading: false,
              trailing: true,
            })}
            disabled={isDisabled}
          >
            <IconInit src={PrevPageIcon} />
            <Icon src={PrevPageIcon} />
          </PaginationButton>
          <PaginationButtonPrev
            onClick={debounce(previousPage, 500, {
              leading: false,
              trailing: true,
            })}
            disabled={isDisabled}
          >
            <Icon src={PrevPageIcon} />
          </PaginationButtonPrev>
          {currentPage > 0 && (
            <ContainerCircles>
              <CirclePagination
                isSelected={valueCircle1 === currentPage}
                onClick={() => pageIndex && pageIndex(valueCircle1 - 1)}
              >
                {valueCircle1}
              </CirclePagination>
              {pageCount >= 2 && (
                <CirclePagination
                  isSelected={valueCircle2 === currentPage}
                  onClick={() => pageIndex && pageIndex(valueCircle2 - 1)}
                >
                  {valueCircle2}
                </CirclePagination>
              )}
              {pageCount >= 3 && (
                <CirclePagination
                  isSelected={valueCircle3 === currentPage}
                  onClick={() => pageIndex && pageIndex(valueCircle3 - 1)}
                >
                  {valueCircle3}
                </CirclePagination>
              )}
            </ContainerCircles>
          )}
          <CountTotal size={'XS'}>{totalCount}</CountTotal>
          <PaginationButtonNext
            onClick={debounce(nextPage, 500, {
              leading: false,
              trailing: true,
            })}
            disabled={currentPage === pageCount || isDisabled}
          >
            <Icon src={NextPageIcon} />
          </PaginationButtonNext>
          <PaginationButton
            onClick={debounce(lastPage as () => void, 500, {
              leading: false,
              trailing: true,
            })}
            disabled={isDisabled}
          >
            <Icon src={NextPageIcon} />
            <IconEnd src={NextPageIcon} />
          </PaginationButton>
        </>
      )}
    </PaginationContainer>
  );
};

import { LoaderFunction, defer } from 'react-router-dom';
import { AuthContextType } from '../../../../hooks/useAuth';

// GQL
import { client } from '../../../../gql/client';
import { selectAllOrders } from '../../../../gql/selectors/orders';
import { GET_GENERIC_ORDERS } from '../../../../gql/queries/genericOrders';
import {
  FETCH_CAPITOLS,
  FETCH_SUB_CAPITOLS,
} from '../../../../gql/queries/orders/predefinedData';
import { GET_CONCESSIONS } from '../../../../gql/queries/orders/orders';

// Redux
import { getCursor } from '../../../../state/selectors/ui/tables';
import { store } from '../../../../state/configureStore';

// Types
import {
  tableIds,
  takeBase,
} from '../../../../components/display/tables/types';
import { loadingData } from '../../../../state/actions/ui/loadingData';
import { setCursor } from '../../../../state/actions/ui/cursorTables';
export const LoaderOrderDuplicates: (
  userData: AuthContextType | null
) => LoaderFunction = (userData) => async () => {
  const cursorGenericOrder = getCursor(tableIds.DUPLICATE_GENERIC_ORDER)(
    store.getState()
  );
  const dispatch = store.dispatch;

  const roleActive = userData?.user.user.rolActive;

  if (userData?.user) {
    const readWorkOrders = client.readQuery({
      query: GET_GENERIC_ORDERS,
      variables: {
        input: {
          take: cursorGenericOrder.take,
          cursor: cursorGenericOrder.cursor,
          orderBy: cursorGenericOrder.orderBy,
          concessionId: cursorGenericOrder.concessionId,
          fromDate: cursorGenericOrder.fromDate,
          toDate: cursorGenericOrder.toDate,
          indexType: cursorGenericOrder.indexType,
          indexExpedition: cursorGenericOrder.indexExpedition,
          capitolId: cursorGenericOrder.capitolId,
          subCapitolId: cursorGenericOrder.subCapitolId,
          classification: cursorGenericOrder?.classification,
          state: cursorGenericOrder?.state,
        },
      },
    });
    const pageInfo = readWorkOrders?.genericOrders?.pageInfo;

    const totalCount = readWorkOrders?.genericOrders?.totalCount;
    const isCacheEmpty = JSON.stringify(readWorkOrders) === '{}';

    const genericOrders =
      readWorkOrders &&
      !isCacheEmpty &&
      (await selectAllOrders(readWorkOrders?.genericOrders?.genericOrders));

    const [
      {
        data: { capitols },
      },
      {
        data: { concessions },
      },
      {
        data: { subCapitols },
      },
    ] = await Promise.all([
      client.query({
        query: FETCH_CAPITOLS,
      }),
      client.query({
        query: GET_CONCESSIONS,
      }),
      client.query({
        query: FETCH_SUB_CAPITOLS,
      }),
    ]);

    const filtersDataOptions = {
      capitols,
      concessions,
      subCapitols,
    };

    const combinedData = {
      genericOrders,
      filtersDataOptions,
      totalCount,
      pageInfo,
      roleActive,
    };

    if (!readWorkOrders || isCacheEmpty) {
      dispatch(loadingData(tableIds.DUPLICATE_GENERIC_ORDER, true));
      let data;
      if (isCacheEmpty) {
        data = await client.query({
          query: GET_GENERIC_ORDERS,
          variables: {
            input: {
              take: takeBase,
              cursor: '',
              orderBy: 'desc',
              concessionId: cursorGenericOrder.concessionId,
              fromDate: cursorGenericOrder.fromDate,
              toDate: cursorGenericOrder.toDate,
              indexType: cursorGenericOrder.indexType,
              indexExpedition: cursorGenericOrder.indexExpedition,
              capitolId: cursorGenericOrder.capitolId,
              subCapitolId: cursorGenericOrder.subCapitolId,
              classification: cursorGenericOrder?.classification,
              state: cursorGenericOrder?.state,
            },
          },
          fetchPolicy: 'cache-first',
        });

        const setCursorData = {
          take: takeBase,
          cursor: '',
          orderBy: 'desc',
          concessionId: cursorGenericOrder.concessionId,
          fromDate: cursorGenericOrder.fromDate,
          toDate: cursorGenericOrder.toDate,
          indexType: cursorGenericOrder.indexType,
          indexExpedition: cursorGenericOrder.indexExpedition,
          capitolId: cursorGenericOrder.capitolId,
          subCapitolId: cursorGenericOrder.subCapitolId,
          classification: cursorGenericOrder?.classification,
          state: cursorGenericOrder?.state,
        };

        dispatch(setCursor(tableIds.DUPLICATE_GENERIC_ORDER, setCursorData));
      } else {
        data = await client.query({
          query: GET_GENERIC_ORDERS,
          variables: {
            input: {
              take: cursorGenericOrder.take,
              cursor: cursorGenericOrder.cursor,
              orderBy: cursorGenericOrder.orderBy,
              concessionId: cursorGenericOrder.concessionId,
              fromDate: cursorGenericOrder.fromDate,
              toDate: cursorGenericOrder.toDate,
              indexType: cursorGenericOrder.indexType,
              indexExpedition: cursorGenericOrder.indexExpedition,
              capitolId: cursorGenericOrder.capitolId,
              subCapitolId: cursorGenericOrder.subCapitolId,
              classification: cursorGenericOrder?.classification,
              state: cursorGenericOrder?.state,
            },
          },
          fetchPolicy: 'cache-first',
        });
      }

      const loading = data.loading;
      const pageInfo = data.data.genericOrders.pageInfo;

      const totalCount = data.data.genericOrders.totalCount;
      const genericOrders = await selectAllOrders(
        data.data.genericOrders.genericOrders
      );

      const [
        {
          data: { capitols },
        },
        {
          data: { concessions },
        },
        {
          data: { subCapitols },
        },
      ] = await Promise.all([
        client.query({
          query: FETCH_CAPITOLS,
        }),
        client.query({
          query: GET_CONCESSIONS,
        }),
        client.query({
          query: FETCH_SUB_CAPITOLS,
        }),
      ]);

      const filtersDataOptions = {
        capitols,
        concessions,
        subCapitols,
      };

      const combinedData = {
        genericOrders,
        filtersDataOptions,
        totalCount,
        pageInfo,
        loading,
        roleActive,
      };
      dispatch(loadingData(tableIds.DUPLICATE_GENERIC_ORDER, false));
      return defer({ data: combinedData });
    }

    return defer({ data: combinedData });
  }
  return {};
};

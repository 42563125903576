import styled from 'styled-components';
import {
  breakPoints,
  percentBaseResponsive,
  spaces,
} from '../../../styles/spaces';

export const ContainerOT = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  gap: 4rem;
  @media (max-width: ${breakPoints.smallDesktop * percentBaseResponsive}rem) {
    gap: 2.8rem;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  gap: ${spaces.xs}rem;
  margin-bottom: ${spaces.m}rem;
  align-items: center;
`;

export const Container = styled.div`
  width: 100%;
`;

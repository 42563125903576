import styled from 'styled-components';

// Spaces
import { breakPoints, percentBaseResponsive } from '../../../../styles/spaces';

// Components
import { Typography } from '../../../display/Typography';
import { MainButton } from '../../../inputs/buttons/MainButton';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Icon = styled.img`
  width: 7.8rem;
  height: 7.8rem;

  @media (max-width: ${breakPoints.landscapeTablet *
    percentBaseResponsive}rem) {
    width: 6rem;
    height: 6rem;
  }
`;

export const Title = styled(Typography)`
  text-align: center;
  font-size: 2.6rem;
  padding: 3rem 5.9rem;
`;

export const Button = styled(MainButton)`
  padding: 0.75rem 2rem;
  p {
    font-size: 1.8rem;
  }
`;

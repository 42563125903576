import { object, string } from 'yup';
import { OperatorsAndVehiclesFormValues } from './types';

export const OperatorsAndVehicleSchema = object<OperatorsAndVehiclesFormValues>(
  {
    registerInitDate: string().required(),
    registerEndDate: string().required(),
    hoursType: string().required(),
    totalHours: string().required(),
  }
);

// Dependencies
import React, {
  Ref,
  useState,
  useRef,
  useCallback,
  useImperativeHandle,
  forwardRef,
  KeyboardEventHandler,
} from 'react';
import { useClickAway } from 'react-use';

//Types
import { DropDownProps, ImperativeRefInput } from './types';
import {
  onChangeValue,
  ImperativeRefList,
  OptionSelected,
} from '../DropDownList/types';

// Styled Components
import {
  Container,
  DropDownHeader,
  DropDownContainer,
  SelectedOption,
  Label,
  ArrowDown,
} from './styles';

// Components
import DropDownList from '../DropDownList';

// Media
import { ErrorMessage } from '../../InputText/styles';

const DropDownInput = (
  {
    inputSize,
    typeDropDown,
    placeholder,
    labelText,
    options,
    onChangeSelected,
    defaultValue,
    error,
    borderError,
    isDisabled,
    handleSelectedOption,
    optionsChange = false,
    ...props
  }: DropDownProps,
  innerRef: Ref<ImperativeRefInput>
): JSX.Element => {
  const [isActive, setIsActive] = useState(false);
  const [selectedOption, setSelectedOption] = useState<
    OptionSelected | undefined
  >(() =>
    options.find((opt) => {
      if (opt.value === defaultValue) {
        return opt.value === defaultValue;
      }
      if (opt.label === defaultValue) {
        return opt.label === defaultValue;
      }
    })
  );

  const listRef = useRef<ImperativeRefList>(null);

  const containerRef = useRef<HTMLDivElement>(null);

  useClickAway(containerRef, () => {
    listRef.current?.setHidden();
    setIsActive(false);
  });

  const onChangeList = useCallback<onChangeValue>((option) => {
    setSelectedOption(option);
    if (onChangeSelected) {
      onChangeSelected(option);
    }
    if (handleSelectedOption) {
      option && handleSelectedOption(option?.value);
    }
  }, []);

  const clickedOption = useCallback(() => {
    listRef.current?.setVisibleDropDown();
    setIsActive(true);
  }, []);

  useImperativeHandle(innerRef, () => ({
    clearSelected: () => {
      setSelectedOption(undefined);
      setIsActive(false);
    },
    setSelectedOption: (option) => onChangeList(option),
  }));

  const pressFocus: KeyboardEventHandler = useCallback((event) => {
    if (event.key === ' ') {
      event.preventDefault();
      clickedOption();
    }
  }, []);

  const renderSelectedOption = useCallback(
    () => <SelectedOption>{selectedOption?.label}</SelectedOption>,
    [selectedOption]
  );
  const renderSelectedOptionEmpty = useCallback(
    () => (
      <SelectedOption variant="regular" colorText="greySoftLight">
        {placeholder}
      </SelectedOption>
    ),
    []
  );
  return (
    <Container
      inputSize={inputSize}
      labelText={!!labelText}
      typeDropDown={typeDropDown}
      {...props}
    >
      {labelText && <Label colorText="black">{labelText}</Label>}
      <DropDownContainer
        ref={containerRef}
        onClick={(e) => {
          e.stopPropagation();
          isDisabled ? undefined : clickedOption();
        }}
      >
        <DropDownHeader
          typeDropDown={typeDropDown}
          isActive={isActive}
          borderError={borderError}
          tabIndex={0}
          onKeyDownCapture={pressFocus}
          isDisabled={isDisabled}
        >
          {optionsChange
            ? selectedOption &&
              options.find(
                (option) => option.value === selectedOption.value
              ) !== undefined
              ? renderSelectedOption()
              : renderSelectedOptionEmpty()
            : selectedOption
            ? renderSelectedOption()
            : renderSelectedOptionEmpty()}

          <ArrowDown />
        </DropDownHeader>

        {
          <DropDownList
            selectedOption={selectedOption}
            setSelectedAndCallback={onChangeList}
            typeDropDown={typeDropDown}
            options={options}
            ref={listRef}
          />
        }
      </DropDownContainer>
      {error && (
        <ErrorMessage colorText={'lightCoralRed'} size={'XXS'}>
          {error}
        </ErrorMessage>
      )}
    </Container>
  );
};

export default forwardRef(DropDownInput);

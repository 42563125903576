import React, { useLayoutEffect } from 'react';
import {
  useActionData,
  useAsyncValue,
  useNavigate,
  useSubmit,
} from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { SubmitHandler } from 'react-hook-form';

// Components Styled
import { Container } from './styles';

// Components
import NewWorkOrderForm from '../../../../components/forms/NewWorkOrderForm';

// types
import { type WorkOrder } from '../../../../types/workOrder';
import { OrderType } from '../../../../types/orders';
import { ActionDataNewAction } from './types';

// Utils
import { createFormDataFromValues } from '../../../../utils/forms';

// Redux
import { coticOTSelected } from '../../../../state/actions/ui/coticOTSelectedGeneralView';

// Constants
import { formActionNaming } from '../../../../components/forms/ActionForms/constants';

export default function NewActionPage(): JSX.Element {
  const actionData = useActionData() as ActionDataNewAction;
  const loaderData = useAsyncValue() as WorkOrder;
  const dispatch = useDispatch();
  const submit = useSubmit();

  const navigate = useNavigate();

  useLayoutEffect(() => {
    if (!loaderData || !actionData) {
      navigate('../');
    }
    if (actionData) {
      dispatch(
        coticOTSelected(
          OrderType.Action,
          actionData.orderParentCotic,
          actionData.orderParentId
        )
      );
    }
  }, []);

  const onFormSubmit: SubmitHandler<WorkOrder> = async (values) => {
    const formData = createFormDataFromValues({
      ...values,
      intent: 'save-data',
    });
    dispatch(
      coticOTSelected(
        OrderType.Action,
        actionData.orderParentCotic,
        actionData.orderParentId
      )
    );
    submit(formData, {
      method: 'POST',
      action: '.',
    });
  };

  return (
    <Container>
      <NewWorkOrderForm
        onFormSubmit={onFormSubmit}
        initialData={{
          ...loaderData,
        }}
        namingPersistForm={formActionNaming.NEW_ACTION_DATA}
        isAction={true}
        titleBasicInfo={'Informació bàsica'}
      />
    </Container>
  );
}

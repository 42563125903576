import React, { useCallback, useEffect, useRef } from 'react';
import { Controller, ControllerProps, useWatch } from 'react-hook-form';

// Sentry
import * as Sentry from '@sentry/react';

// styled components
import {
  ContainerCampaign,
  ContainerDescription,
  ContainerSwitch,
  StyledTypography,
} from './styles';
import {
  Title,
  LocationInputGroup,
  InputTextAreaStyled,
  InputsContainer,
  PkInputContainer,
} from '../components/MainStyled';

// Components
import DropDownInput from '../../inputs/DropDown/DropDownInput';
import InputText from '../../inputs/InputText';
import { ArrowRadioGroup } from '../../../pages/main/comunications/NewComunication/styles';
import SimpleSwitch from '../../inputs/buttons/SwitchButton/Simple';

// Types
import { FormProps, SetValueType, GroupTypes } from './types';
import {
  Margin,
  WorkOrder,
  WorkOrderDirection,
} from '../../../types/workOrder';
import { ImperativeRefInput } from '../../inputs/DropDown/DropDownInput/types';
import { OptionSelected } from '../../inputs/DropDown/DropDownList/types';
import { type PartialDirection } from '../../inputs/buttons/ArrowDirectionButton/types';

// GQL
import {
  GET_CONCESSION_BY_ID,
  GET_ROAD_BY_ID,
  GET_ROADS_BY_IDS,
} from '../../../gql/queries/geoPositions/roads';
import {
  GET_CAPITOL_BY_ID,
  GET_CLASSIFICATION_BY_ID_OR_COTIC,
  GET_ELEMENT_ORDERS_BY_ID,
  GET_ELEMENT_ORDER_BY_ID,
  GET_SUB_CAPITOLS_BY_IDS,
  GET_SUB_CAPITOL_BY_ID,
} from '../../../gql/queries/orders/predefinedData';
import { GET_ACTIVITY_BY_ID } from '../../../gql/queries/actions/activitys';
import { client } from '../../../gql/client';

// Media
import {
  useIsLandscapeTablet,
  useIsPortraitTablet,
} from '../../../hooks/useMediaBreakPoints';

// Constants
import { formOrderNaming } from './constants';
import { formActionNaming } from '../ActionForms/constants';
import { formAccidentNaming } from '../AddBasicInfoAccidentForm/constants';

// Utils
import { pkFormatter } from '../../../utils/transform';
import { useLocation } from 'react-router-dom';
import { fetchDefaultData } from '../../../utils/fetchData';
import { groupLanes, groupTracks } from '../../../data/orders';
import { UNSPECIFIC } from '../../../types/utils';

export const Description = ({
  register,
  control,
  errors,
  setValue,
  switchState,
  setSwitchState,
  namingPersistForm,
  isAccident,
  isAction,
  autoFillData,
}: FormProps & SetValueType) => {
  // Hooks
  const subCapitolDropDownRef = useRef<ImperativeRefInput>(null);
  const roadDropDownRef = useRef<ImperativeRefInput>(null);
  const capitolDropDownRef = useRef<ImperativeRefInput>(null);
  const elementOrdersDropDownRef = useRef<ImperativeRefInput | null>(null);
  const activitysDropDownRef = useRef<ImperativeRefInput | null>(null);
  const classificationDropDownRef = useRef<ImperativeRefInput | null>(null);
  const concessionDropDownRef = useRef<ImperativeRefInput | null>(null);
  const trackDropDownRef = useRef<ImperativeRefInput | null>(null);
  const laneDropDownRef = useRef<ImperativeRefInput | null>(null);
  const isLandscapeTablet = useIsLandscapeTablet();
  const isPortraitTable = useIsPortraitTablet();
  const isEditForm = namingPersistForm === formOrderNaming.EDIT_CAMPAIGN_FORM;
  const isAnAction = namingPersistForm === formActionNaming.NEW_ACTION_DATA;
  const isAnAccident =
    namingPersistForm === formAccidentNaming.NEW_ACCIDENT_FORM;

  const location = useLocation();

  // Watchers
  const subCapitols = useWatch({
    control,
    name: 'subCapitols',
  });

  const roads = useWatch({
    control,
    name: 'roads',
  });
  const city = useWatch({
    control,
    name: 'city',
  });
  const pkInitRoad = useWatch({
    control,
    name: 'pkInitRoad',
  });
  const pkEndRoad = useWatch({
    control,
    name: 'pkEndRoad',
  });

  const elementOrders = useWatch({
    control,
    name: 'elementOrders',
  });

  const fetchToFillData = useCallback(async () => {
    try {
      const [
        {
          data: { capitol },
        },
        {
          data: { subCapitol },
        },
        {
          data: { classification },
        },
        {
          data: { concession },
        },
        {
          data: { road },
        },
      ] = await Promise.all([
        client.query({
          query: GET_CAPITOL_BY_ID,
          variables: {
            id: autoFillData?.order?.capitolIDs[0],
          },
        }),
        client.query({
          query: GET_SUB_CAPITOL_BY_ID,
          variables: {
            id: autoFillData?.order?.subCapitolIDs[0],
          },
        }),
        client.query({
          query: GET_CLASSIFICATION_BY_ID_OR_COTIC,
          variables: {
            id: autoFillData?.order?.classificationIDs[0],
          },
        }),
        client.query({
          query: GET_CONCESSION_BY_ID,
          variables: {
            id: autoFillData?.genericOrder
              ? autoFillData?.genericOrder?.concessionIDs[0]
              : autoFillData?.campaignData?.concessionIDs[0],
          },
        }),
        client.query({
          query: GET_ROAD_BY_ID,
          variables: {
            id: autoFillData?.genericOrder
              ? autoFillData?.genericOrder?.roadIDs[0]
              : autoFillData?.campaignData?.roadIDs[0],
          },
        }),
      ]);

      autoFillData?.genericOrder
        ? setValue(
            'pkInit',
            `${autoFillData.genericOrder.pkInitKm}+${String(
              autoFillData.genericOrder.pkInitMeter
            ).padStart(3, '0')}`
          )
        : setValue(
            'pkInit',
            `${autoFillData?.campaignData?.pkInitKm}+${String(
              autoFillData?.campaignData?.pkInitMeter
            ).padStart(3, '0')}`
          );

      autoFillData?.genericOrder
        ? setValue(
            'pkEnd',
            `${autoFillData.genericOrder.pkEndKm}+${String(
              autoFillData.genericOrder.pkEndMeter
            ).padStart(3, '0')}`
          )
        : setValue(
            'pkEnd',
            `${autoFillData?.campaignData?.pkEndKm}+${String(
              autoFillData?.campaignData?.pkEndMeter
            ).padStart(3, '0')}`
          );

      autoFillData?.genericOrder?.direction
        ? setValue(
            'direction',
            autoFillData?.genericOrder?.direction as WorkOrderDirection
          )
        : setValue(
            'direction',
            autoFillData?.campaignData?.direction as WorkOrderDirection
          );

      autoFillData?.genericOrder?.margin
        ? setValue('margin', autoFillData?.genericOrder?.margin)
        : setValue(
            'margin',
            autoFillData?.campaignData?.margin as 'left' | 'right' | 'both'
          );

      capitolDropDownRef.current?.setSelectedOption({
        label: capitol.name,
        value: capitol.id,
        data: capitol.subCapitolsIDs,
      });

      subCapitolDropDownRef.current?.setSelectedOption({
        label: subCapitol.name,
        value: subCapitol.id,
        data: subCapitol.elementOrdersIds,
      });

      classificationDropDownRef.current?.setSelectedOption({
        label: classification.name,
        value: classification.id,
      });

      concessionDropDownRef.current?.setSelectedOption({
        label: concession.name,
        value: concession.id,
        data: concession.roadIDs,
      });

      roadDropDownRef.current?.setSelectedOption({
        label: road.name,
        value: road.id,
      });
      autoFillData?.genericOrder
        ? setValue('road', autoFillData.genericOrder.roadIDs[0])
        : setValue('road', autoFillData?.campaignData?.roadIDs[0] as string);

      const findOptionTrack = groupTracks.find((track) =>
        autoFillData?.genericOrder
          ? track.value === autoFillData?.genericOrder?.track
          : track.value === autoFillData?.campaignData?.track
      );
      trackDropDownRef.current?.setSelectedOption({
        label: findOptionTrack?.name as string,
        value: findOptionTrack?.value as string,
      });

      const findOptionLane = groupLanes.find((lane) =>
        autoFillData?.genericOrder
          ? lane.value === autoFillData?.genericOrder?.lane
          : lane.value === autoFillData?.campaignData?.lane
      );
      laneDropDownRef.current?.setSelectedOption({
        label: findOptionLane?.name as string,
        value: findOptionLane?.value as string,
      });
      setValue('description', autoFillData?.order?.description as string);
    } catch (error) {
      Sentry.captureException(error);
      console.error('Error fetching data in NewWorkOrderForm:', error);
    }
  }, [autoFillData]);

  useEffect(() => {
    if (autoFillData?.order) {
      fetchToFillData();
    }
  }, [autoFillData, fetchToFillData]);

  // HandleSelect functions
  const onSelectConcession = useCallback(
    (
      option: OptionSelected | undefined,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onChange: (...event: any[]) => void
    ) => {
      const tasks = async () => {
        const {
          data: { concession },
        } = await client.query({
          query: GET_CONCESSION_BY_ID,
          variables: { id: option?.value },
        });

        setValue('city', concession.location);

        const { readRoads } = client.readQuery({
          query: GET_ROADS_BY_IDS,
          variables: { ids: concession.roadIDs ?? [] },
        });

        if (readRoads.length === 0) {
          await fetchDefaultData();
          const { readRoads } = client.readQuery({
            query: GET_ROADS_BY_IDS,
            variables: { ids: concession.roadIDs ?? [] },
          });
          setValue('roads', readRoads);
        } else {
          setValue('roads', readRoads);
        }
      };
      roadDropDownRef.current?.clearSelected();
      setValue('road', '');
      onChange(option?.value ?? '');
      tasks().catch((err) => {
        Sentry.captureException(err);
        console.log(err);
      });
    },
    []
  );

  const onSelectRoad = useCallback(
    (
      option: OptionSelected | undefined,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onChange: (...event: any[]) => void
    ) => {
      const tasks = async () => {
        const {
          data: { road },
        } = await client.query({
          query: GET_ROAD_BY_ID,
          variables: { id: option?.value },
        });

        setValue(
          'pkInitRoad',
          pkFormatter(road.pkInitKm, road.pkInitMeter, true)
        );
        setValue('pkEndRoad', pkFormatter(road.pkEndKm, road.pkEndMeter, true));

        onChange(option?.value ?? '');
      };
      tasks().catch((err) => {
        Sentry.captureException(err);
        console.log(err);
      });
    },
    []
  );

  const onSelectCapitol = useCallback(
    (
      option: OptionSelected | undefined,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onChange: (...event: any[]) => void
    ) => {
      const tasks = async () => {
        const { readSubCapitols } = client.readQuery({
          query: GET_SUB_CAPITOLS_BY_IDS,
          variables: {
            ids: option?.data ?? [],
          },
        });

        if (readSubCapitols.length === 0) {
          await fetchDefaultData();

          const { readSubCapitols } = client.readQuery({
            query: GET_SUB_CAPITOLS_BY_IDS,
            variables: {
              ids: option?.data ?? [],
            },
          });
          const sortedSubCapitols = [...readSubCapitols].sort((a, b) =>
            a.name.localeCompare(b.name)
          );
          setValue('subCapitols', sortedSubCapitols);
        } else {
          const sortedSubCapitols = [...readSubCapitols].sort((a, b) =>
            a.name.localeCompare(b.name)
          );
          setValue('subCapitols', sortedSubCapitols);
        }
        subCapitolDropDownRef.current?.clearSelected();
        setValue('subCapitol', '');
        setValue('elementOrders', []);
        elementOrdersDropDownRef.current?.clearSelected();
        onChange(option?.value ?? '');
      };
      tasks().catch((err) => {
        Sentry.captureException(err);
        console.log(err);
      });
    },
    []
  );

  const onSelectPredefined = useCallback(
    (
      option: OptionSelected | undefined,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onChange: (...event: any[]) => void
    ) => {
      const tasks = async () => {
        onChange(option?.value ?? '');

        const [
          {
            data: { capitol },
          },
          {
            data: { subCapitol },
          },
        ] = await Promise.all([
          client.query({
            query: GET_CAPITOL_BY_ID,
            variables: {
              id: option?.data.capitolId,
            },
          }),
          client.query({
            query: GET_SUB_CAPITOL_BY_ID,
            variables: {
              id: option?.data.subCapitolId,
            },
          }),
        ]);

        const { readSubCapitols } = client.readQuery({
          query: GET_SUB_CAPITOLS_BY_IDS,
          variables: {
            ids: capitol?.subCapitolsIDs ?? [],
          },
        });

        if (readSubCapitols.length === 0) {
          await fetchDefaultData();
          const { readSubCapitols } = client.readQuery({
            query: GET_SUB_CAPITOLS_BY_IDS,
            variables: {
              ids: capitol?.subCapitolsIDs ?? [],
            },
          });
          const sortedSubCapitols = [...readSubCapitols].sort((a, b) =>
            a.name.localeCompare(b.name)
          );
          setValue('subCapitols', sortedSubCapitols);
        } else {
          const sortedSubCapitols = [...readSubCapitols].sort((a, b) =>
            a.name.localeCompare(b.name)
          );
          setValue('subCapitols', sortedSubCapitols);
        }

        capitolDropDownRef.current?.setSelectedOption({
          label: capitol.name,
          value: capitol.id,
          data: capitol.subCapitolsIDs,
        });

        subCapitolDropDownRef.current?.setSelectedOption({
          label: subCapitol.name,
          value: subCapitol.id,
          data: subCapitol.elementOrdersIds,
        });
        if (isAction) {
          const [
            {
              data: { activity },
            },
            {
              data: { elementOrder },
            },
          ] = await Promise.all([
            client.query({
              query: GET_ACTIVITY_BY_ID,
              variables: {
                id: option?.data.activityId,
              },
            }),
            client.query({
              query: GET_ELEMENT_ORDER_BY_ID,
              variables: {
                id: option?.data.elementOrderId,
              },
            }),
          ]);

          const { readElementOrders } = client.readQuery({
            query: GET_ELEMENT_ORDERS_BY_ID,
            variables: {
              ids: subCapitol?.elementOrdersIds ?? [],
            },
          });
          const sortedElements = [...readElementOrders].sort((a, b) =>
            a.name.localeCompare(b.name)
          );
          setValue('elementOrders', sortedElements);

          elementOrdersDropDownRef.current?.setSelectedOption({
            label: elementOrder.name,
            value: elementOrder.id,
            data: elementOrder.subCapitolsIDs,
          });

          activitysDropDownRef.current?.setSelectedOption({
            label: activity.name,
            value: activity.id,
          });
        }
      };
      tasks().catch((err) => {
        Sentry.captureException(err);
        console.log(err);
      });
    },
    [
      capitolDropDownRef,
      subCapitolDropDownRef,
      elementOrdersDropDownRef,
      activitysDropDownRef,
    ]
  );

  const onSelectSubCapitol = useCallback(
    (
      option: OptionSelected | undefined,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onChange: (...event: any[]) => void
    ) => {
      const tasks = async () => {
        if (isAction) {
          try {
            const { readElementOrders } = client.readQuery({
              query: GET_ELEMENT_ORDERS_BY_ID,
              variables: {
                ids: option?.data ?? [],
              },
            });
            if (readElementOrders.length === 0) {
              await fetchDefaultData();
              const { readElementOrders } = client.readQuery({
                query: GET_ELEMENT_ORDERS_BY_ID,
                variables: {
                  ids: option?.data ?? [],
                },
              });
              const sortedElements = [...readElementOrders].sort((a, b) =>
                a.name.localeCompare(b.name)
              );
              setValue('elementOrders', sortedElements);
            } else {
              const sortedElements = [...readElementOrders].sort((a, b) =>
                a.name.localeCompare(b.name)
              );
              setValue('elementOrders', sortedElements);
            }
            elementOrdersDropDownRef.current?.clearSelected();
          } catch (err) {
            Sentry.captureException(err);
            console.log(err);
          }
        }
        onChange(option?.value ?? '');
      };
      tasks().catch((err) => {
        Sentry.captureException(err);
        console.log(err);
      });
    },
    []
  );

  const inputSizeSupcapitol = isPortraitTable ? 'M' : 'L';

  const renderSubCapitols = useCallback<
    ControllerProps<WorkOrder, 'subCapitol'>['render']
  >(
    ({ field: { onChange }, formState: { defaultValues } }) => {
      const options =
        subCapitols?.map((subCapitol) => ({
          label: subCapitol?.name || '',
          value: subCapitol?.id || '',
          data: subCapitol?.elementOrdersIds || '',
        })) || [];
      return (
        <DropDownInput
          ref={subCapitolDropDownRef}
          inputSize={inputSizeSupcapitol}
          onChangeSelected={(option) => onSelectSubCapitol(option, onChange)}
          typeDropDown="Default"
          labelText="Subcapitol"
          placeholder="Selecciona"
          defaultValue={defaultValues?.subCapitol}
          borderError={!!errors.subCapitol}
          options={options}
        />
      );
    },
    [subCapitols, errors, inputSizeSupcapitol]
  );

  const renderTipusVial = (
    <Controller
      control={control}
      name="track"
      render={({ field: { onChange }, formState: { defaultValues } }) => {
        const options =
          defaultValues?.groupTracks?.map((track) => ({
            label: track?.name || '',
            value: track?.value || '',
          })) || [];
        return (
          <DropDownInput
            ref={trackDropDownRef}
            inputSize={'M'}
            onChangeSelected={(option) => onChange(option?.value ?? '')}
            typeDropDown="Default"
            labelText="Tipus vial"
            placeholder="Selec."
            borderError={!!errors.track}
            defaultValue={defaultValues?.track}
            options={options}
          />
        );
      }}
    />
  );

  const renderElementOrders = useCallback<
    ControllerProps<WorkOrder, 'actionElementOrder'>['render']
  >(
    ({ field: { onChange }, formState: { defaultValues } }) => {
      const options =
        elementOrders?.map((elementOrder) => ({
          label: elementOrder?.name || '',
          value: elementOrder?.id || '',
          data: elementOrder?.subCapitolIDs || '',
        })) || [];

      return (
        <DropDownInput
          ref={elementOrdersDropDownRef}
          labelText="Element"
          placeholder={'Selecciona'}
          inputSize={'L'}
          options={options}
          typeDropDown={'Default'}
          defaultValue={defaultValues?.actionElementOrder}
          onChangeSelected={(option) => onChange(option?.value ?? '')}
          borderError={!!errors.actionElementOrder}
        />
      );
    },
    [elementOrders, errors]
  );

  return (
    <ContainerDescription>
      <Title component="h2" variant="semiBold">
        {location.pathname.split('/').includes('new-action')
          ? 'Descripció'
          : 'Descripció de la ordre de treball'}
      </Title>

      <InputsContainer>
        <Controller
          control={control}
          name="predefine"
          render={({ field: { onChange }, formState: { defaultValues } }) => {
            const options =
              defaultValues?.predefinedOrders?.map((predefinedOrder) => ({
                label: predefinedOrder?.name || '',
                value: predefinedOrder?.id || '',
                data: {
                  capitolId: predefinedOrder?.capitolId || '',
                  subCapitolId: predefinedOrder?.subCapitolId || '',
                  elementOrderId: predefinedOrder?.elementOrderId || '',
                  activityId: predefinedOrder?.activityId || '',
                },
              })) || [];
            return (
              <DropDownInput
                inputSize={'XL'}
                onChangeSelected={(option) =>
                  onSelectPredefined(option, onChange)
                }
                typeDropDown="Default"
                labelText="Predefinits"
                placeholder="Selecciona"
                defaultValue={defaultValues?.predefine}
                borderError={!!errors.predefine}
                options={options}
              />
            );
          }}
        />
        <Controller
          control={control}
          name="capitol"
          render={({ field: { onChange }, formState: { defaultValues } }) => {
            const options =
              defaultValues?.capitols?.map((capitol) => ({
                label: capitol?.name || '',
                value: capitol?.id || '',
                data: capitol?.subCapitolsIDs || '',
              })) || [];
            return (
              <DropDownInput
                ref={capitolDropDownRef}
                inputSize={isPortraitTable ? 'M' : 'L'}
                onChangeSelected={(option) => onSelectCapitol(option, onChange)}
                typeDropDown="Default"
                labelText="Capitol"
                placeholder="Selecciona"
                defaultValue={defaultValues?.capitol}
                borderError={!!errors.capitol}
                options={options}
              />
            );
          }}
        />
        <Controller
          control={control}
          name="subCapitol"
          render={renderSubCapitols}
        />

        {isAction && (
          <>
            <Controller
              control={control}
              name="actionElementOrder"
              render={renderElementOrders}
            />

            <Controller
              name="actionActivity"
              control={control}
              render={({
                field: { onChange },
                formState: { defaultValues },
              }) => {
                const options =
                  defaultValues?.activitys?.map((activity) => ({
                    label: activity?.name || '',
                    value: activity?.id || '',
                  })) || [];
                return (
                  <DropDownInput
                    ref={activitysDropDownRef}
                    labelText="Acció"
                    placeholder={'Selec'}
                    inputSize={'L'}
                    options={options}
                    typeDropDown={'Default'}
                    onChangeSelected={(option) => onChange(option?.value ?? '')}
                    defaultValue={defaultValues?.actionActivity}
                    borderError={!!errors.actionActivity}
                  />
                );
              }}
            />
          </>
        )}
        {isAction || isAccident ? undefined : (
          <Controller
            control={control}
            name="classification"
            render={({ field: { onChange }, formState: { defaultValues } }) => {
              const options =
                defaultValues?.classifications?.map((classification) => ({
                  label: classification?.name || '',
                  value: classification?.id || '',
                })) || [];

              return (
                <DropDownInput
                  ref={classificationDropDownRef}
                  inputSize={'S'}
                  onChangeSelected={(option) => {
                    onChange(option?.value);
                  }}
                  typeDropDown="Default"
                  labelText="Classificació"
                  placeholder="Selecciona"
                  defaultValue={defaultValues?.classification}
                  borderError={!!errors.classification}
                  options={options}
                  isDisabled={isEditForm}
                />
              );
            }}
          />
        )}
        <LocationInputGroup>
          <Controller
            control={control}
            name="concession"
            render={({ field: { onChange }, formState: { defaultValues } }) => {
              const options =
                defaultValues?.concessions?.reduce((acc, curr) => {
                  if (curr?.name !== 'CX') {
                    acc.push({
                      label: curr?.name || '',
                      value: curr?.id || '',
                    });
                  }

                  return acc;
                }, [] as GroupTypes[]) || [];
              return (
                <DropDownInput
                  ref={concessionDropDownRef}
                  inputSize={'XS'}
                  onChangeSelected={(option) =>
                    onSelectConcession(option, onChange)
                  }
                  typeDropDown="Default"
                  labelText="Concessió"
                  placeholder="Selec."
                  borderError={!!errors.concession}
                  defaultValue={defaultValues?.concession}
                  options={options}
                />
              );
            }}
          />

          <InputText
            {...register('city')}
            inputSize="21"
            label="Ubicació"
            typeInput="normal"
            borderError={!city && !!errors.city}
            disabled
          />
          <Controller
            control={control}
            name="road"
            render={({ field: { onChange }, formState: { defaultValues } }) => {
              const options =
                roads?.map((road) => ({
                  label: road?.name || '',
                  value: road?.id || '',
                })) || [];

              return (
                <DropDownInput
                  inputSize={'XS'}
                  ref={roadDropDownRef}
                  onChangeSelected={(option) => {
                    onSelectRoad(option, onChange);
                  }}
                  typeDropDown="Default"
                  labelText="Carretera"
                  placeholder="Selecciona"
                  borderError={!!errors.road}
                  defaultValue={defaultValues?.road}
                  options={options}
                />
              );
            }}
          />

          <InputText
            {...register('pkInitRoad')}
            label="Inici"
            inputSize="12"
            typeInput="normal"
            placeholder="000 + 000"
            borderError={!pkInitRoad && !!errors.pkInitRoad}
            disabled
          />

          <InputText
            {...register('pkEndRoad')}
            label="Final"
            inputSize="12"
            typeInput="normal"
            placeholder="000 + 000"
            borderError={!pkEndRoad && !!errors.pkEndRoad}
            disabled
          />
        </LocationInputGroup>

        {isPortraitTable && renderTipusVial}
        <PkInputContainer>
          <InputText
            {...register('pkInit')}
            label="Pk inicial"
            inputSize={isLandscapeTablet ? '15' : '16'}
            typeInput="normal"
            placeholder="Ex: 90+200"
            borderError={!!errors.pkInit}
          />

          <InputText
            {...register('pkEnd')}
            label="Pk final"
            inputSize={isLandscapeTablet ? '15' : '16'}
            typeInput="normal"
            placeholder="Ex: 90+200"
            borderError={!!errors.pkEnd}
          />
        </PkInputContainer>

        {!isPortraitTable && renderTipusVial}

        <Controller
          control={control}
          name="direction"
          render={({
            field: { onChange, value },
            formState: { defaultValues },
          }) => {
            const defaultStatusValue = {
              [WorkOrderDirection.TOP]: 'top',
              [WorkOrderDirection.DOWN]: 'down',
              [WorkOrderDirection.BOTH]: 'both',
              [WorkOrderDirection.UNSPECIFIC]: UNSPECIFIC,
            };

            const options: PartialDirection = {
              top: WorkOrderDirection.TOP,
              down: WorkOrderDirection.DOWN,
              both: WorkOrderDirection.BOTH,
              [UNSPECIFIC]: UNSPECIFIC,
            };

            return (
              <ArrowRadioGroup
                directions={['down', 'both', 'top', UNSPECIFIC]}
                hasUnspecific
                name="direction"
                label={'Sentit'}
                defaultValue={
                  value
                    ? value
                    : defaultStatusValue[
                        defaultValues?.direction as WorkOrderDirection
                      ]
                }
                borderError={!!errors.direction}
                onChangeDirection={(dir) => {
                  onChange(options[dir]);
                }}
              />
            );
          }}
        />

        <Controller
          control={control}
          name="margin"
          render={({
            field: { onChange, value },
            formState: { defaultValues },
          }) => {
            const defaultStatusValue: PartialDirection = {
              [Margin.LEFT]: 'left',
              [Margin.BOTH]: 'both',
              [Margin.RIGHT]: 'right',
              [Margin.UNSPECIFIC]: UNSPECIFIC,
            };

            const options: PartialDirection = {
              left: Margin.LEFT,
              both: Margin.BOTH,
              right: Margin.RIGHT,
              [UNSPECIFIC]: UNSPECIFIC,
            };

            return (
              <ArrowRadioGroup
                directions={['left', 'both', 'right', UNSPECIFIC]}
                hasUnspecific
                name="margin"
                label={'Marge'}
                defaultValue={
                  value
                    ? value
                    : defaultStatusValue[defaultValues?.margin as Margin]
                }
                borderError={!!errors.margin}
                onChangeDirection={(dir) => {
                  onChange(options[dir]);
                }}
              />
            );
          }}
        />

        <Controller
          control={control}
          name="lane"
          render={({ field: { onChange }, formState: { defaultValues } }) => {
            const options =
              defaultValues?.groupLanes?.map((lane) => ({
                label: lane?.name || '',
                value: lane?.value || '',
              })) || [];
            return (
              <DropDownInput
                ref={laneDropDownRef}
                inputSize={'S'}
                onChangeSelected={(option) => onChange(option?.value)}
                typeDropDown="Default"
                labelText="Carril"
                placeholder="Selecciona"
                borderError={!!errors.lane}
                defaultValue={defaultValues?.lane}
                options={options}
              />
            );
          }}
        />
        {!isAnAction && !isAnAccident && (
          <ContainerCampaign>
            <ContainerSwitch>
              {!isEditForm && (
                <SimpleSwitch
                  disabled={isAccident as boolean}
                  state={switchState as boolean}
                  changeState={setSwitchState as () => boolean}
                />
              )}
              <StyledTypography size="S">Campanya</StyledTypography>
            </ContainerSwitch>
            <InputText
              {...register('campaign')}
              disabled={!switchState}
              inputSize="35"
              typeInput="normal"
              placeholder="Nom"
              borderError={!!errors.campaign}
            />
          </ContainerCampaign>
        )}
      </InputsContainer>
      <InputTextAreaStyled
        {...register('description', {})}
        name="description"
        disabled={false}
        label="Descripció"
        placeholder="Text"
        borderError={!!errors.description}
        error={errors.description?.message}
        value={autoFillData?.order && autoFillData.order.description}
      />
    </ContainerDescription>
  );
};

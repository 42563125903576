import { gql } from '@apollo/client';

export const CREATE_POSITION_ACTION = gql`
  mutation CreatePositionAction($input: NewGeoPositionAction!) {
    createGeoPositionAction(input: $input) {
      id
      pkInitKm
      pkInitMeter
      pkEndKm
      pkEndMeter
      track
      direction
      margin
      lane
      latitude
      longitude
      roadIDs
      concessionIDs
      actionIDs
    }
  }
`;

export const UPDATE_POSITION_ACTION = gql`
  mutation UpdatePositionAction($input: UpdateGeoPositionAction!) {
    updateGeoPositionAction(input: $input) {
      id
      pkInitKm
      pkInitMeter
      pkEndKm
      pkEndMeter
      track
      direction
      margin
      lane
      latitude
      longitude
      roadIDs
      concessionIDs
      actionIDs
    }
  }
`;

import styled from 'styled-components';

import { colors } from '../../../../../styles/colors';
import {
  percentBaseResponsive,
  breakPoints,
  spaces,
} from '../../../../../styles/spaces';

import { CircleProps } from './types';
import { fontSize } from '../../../../../styles/fonts';
import { Typography } from '../../../Typography';

export const PaginationContainer = styled.div`
  padding: ${spaces.xl}rem 0 0;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
`;

export const PaginationButton = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;
  padding: 0;
  white-space: nowrap;
`;

export const PaginationButtonNext = styled(PaginationButton)`
  padding: 0 ${spaces.xxs}rem;
  margin: 0 ${spaces.m}rem;
`;

export const PaginationButtonPrev = styled(PaginationButton)`
  padding: 0 ${spaces.xxs}rem;
  margin: 0 ${spaces.m}rem;
`;

export const ContainerCircles = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2rem;
`;

export const CirclePagination = styled.div<CircleProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.greyLight};
  ${(props) =>
    props.isSelected && {
      backgroundColor: colors.garnet,
    }};
  border-radius: 100%;
  width: 3.4rem;
  height: 3.4rem;
  color: ${colors.white};
  font-size: ${fontSize.XS};
  font-weight: 500;
  cursor: pointer;
  @media (max-width: ${breakPoints.landscapeTablet *
    percentBaseResponsive}rem) {
    width: ${spaces.xl}rem;
    height: ${spaces.xl}rem;
  }
`;

export const Icon = styled.img`
  vertical-align: middle;
  width: 0.7rem;
  height: 1.4rem;
  @media (max-width: ${breakPoints.landscapeTablet *
    percentBaseResponsive}rem) {
    width: 1rem;
    height: 1.8rem;
  }
`;

export const IconInit = styled(Icon)``;

export const IconEnd = styled(Icon)``;

export const CountTotal = styled(Typography)`
  align-self: center;
`;

import { defer, LoaderFunction } from 'react-router-dom';

// Graphql
import { client } from '../../../../../gql/client';
import { FETCH_ACCIDENTS } from '../../../../../gql/queries/accidents/accidents';
import { FETCH_FILTER_ACCIDENTS } from '../../../../../gql/queries/accidents/predefinedData';
import { GET_CONCESSIONS } from '../../../../../gql/queries/orders/orders';
import { selectAccidentOrders } from '../../../../../gql/selectors/accidents';

// Types
import { AuthContextType } from '../../../../../hooks/useAuth';
import {
  tableIds,
  takeBase,
} from '../../../../../components/display/tables/types';

// Redyux
import { store } from '../../../../../state/configureStore';
import { getCursor } from '../../../../../state/selectors/ui/tables';
import { loadingData } from '../../../../../state/actions/ui/loadingData';
import { setCursor } from '../../../../../state/actions/ui/cursorTables';

export const LoaderAccidentGeneralView: (
  userData: AuthContextType | null
) => LoaderFunction = (userData) => async () => {
  if (userData?.user) {
    const cursorAccidents = getCursor(tableIds.ACCIDENTS)(store.getState());
    const dispatch = store.dispatch;

    const roleActive = userData.user.user.rolActive;

    const readAccidents = client.readQuery({
      query: FETCH_ACCIDENTS,
      variables: {
        input: {
          take: cursorAccidents.take,
          cursor: cursorAccidents.cursor,
          concessionId: cursorAccidents.concessionId,
          weatherId: cursorAccidents.weatherId,
          mediumKnowledgeId: cursorAccidents.mediumKnowledgeId,
          orderBy: cursorAccidents.orderBy,
          toDate: cursorAccidents.toDate,
          fromDate: cursorAccidents.fromDate,
          indexType: cursorAccidents.indexType,
          indexExpedition: cursorAccidents.indexExpedition,
          classification: cursorAccidents.classification,
          state: cursorAccidents.state,
        },
      },
    });

    const pageInfo = readAccidents?.accidents?.pageInfo;

    const totalCount = readAccidents?.accidents?.totalCount;

    const [
      {
        data: { concessions },
      },
      {
        data: { weathers, mediumKnowledges },
      },
    ] = await Promise.all([
      client.query({
        query: GET_CONCESSIONS,
      }),
      client.query({
        query: FETCH_FILTER_ACCIDENTS,
      }),
    ]);

    const filters = {
      mediumKnowledges,
      weathers,
      concessions,
    };
    const isCacheEmpty = JSON.stringify(readAccidents) === '{}';

    const accidents =
      readAccidents &&
      !isCacheEmpty &&
      (await selectAccidentOrders(readAccidents?.accidents.accidents));
    const combinedData = {
      accidents,
      totalCount,
      filters,
      pageInfo,
      roleActive,
    };

    if (!readAccidents || isCacheEmpty) {
      dispatch(loadingData(tableIds.ACCIDENTS, true));
      let data;
      if (isCacheEmpty) {
        data = await client.query({
          query: FETCH_ACCIDENTS,
          variables: {
            input: {
              take: takeBase,
              cursor: '',
              concessionId: cursorAccidents.concessionId,
              weatherId: cursorAccidents.weatherId,
              mediumKnowledgeId: cursorAccidents.mediumKnowledgeId,
              orderBy: 'desc',
              toDate: cursorAccidents.toDate,
              fromDate: cursorAccidents.fromDate,
              indexType: cursorAccidents.indexType,
              indexExpedition: cursorAccidents.indexExpedition,
              classification: cursorAccidents.classification,
              state: cursorAccidents.state,
            },
          },
        });
        const setCursorData = {
          take: takeBase,
          cursor: '',
          concessionId: cursorAccidents.concessionId,
          weatherId: cursorAccidents.weatherId,
          mediumKnowledgeId: cursorAccidents.mediumKnowledgeId,
          orderBy: 'desc',
          toDate: cursorAccidents.toDate,
          fromDate: cursorAccidents.fromDate,
          indexType: cursorAccidents.indexType,
          indexExpedition: cursorAccidents.indexExpedition,
          classification: cursorAccidents.classification,
          state: cursorAccidents.state,
        };

        dispatch(setCursor(tableIds.ACCIDENTS, setCursorData));
      } else {
        data = await client.query({
          query: FETCH_ACCIDENTS,
          variables: {
            input: {
              take: cursorAccidents.take,
              cursor: cursorAccidents.cursor,
              concessionId: cursorAccidents.concessionId,
              weatherId: cursorAccidents.weatherId,
              mediumKnowledgeId: cursorAccidents.mediumKnowledgeId,
              orderBy: cursorAccidents.orderBy,
              toDate: cursorAccidents.toDate,
              fromDate: cursorAccidents.fromDate,
              indexType: cursorAccidents.indexType,
              indexExpedition: cursorAccidents.indexExpedition,
              classification: cursorAccidents.classification,
              state: cursorAccidents.state,
            },
          },
          fetchPolicy: 'network-only',
        });
      }

      const loading = data.loading;
      const pageInfo = data.data.accidents.pageInfo;

      const totalCount = data.data.accidents.totalCount;

      const [
        {
          data: { concessions },
        },
        {
          data: { weathers, mediumKnowledges },
        },
      ] = await Promise.all([
        client.query({
          query: GET_CONCESSIONS,
        }),
        client.query({
          query: FETCH_FILTER_ACCIDENTS,
        }),
      ]);

      const filters = {
        mediumKnowledges,
        weathers,
        concessions,
      };

      const accidents = await selectAccidentOrders(
        data.data.accidents.accidents
      );

      const combinedData = {
        accidents,
        totalCount,
        filters,
        pageInfo,
        loading,
        roleActive,
      };
      dispatch(loadingData(tableIds.ACCIDENTS, false));

      return defer({ data: combinedData });
    }

    return defer({ data: combinedData });
  }
  return {};
};

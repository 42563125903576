export type TableProps = {
  data?: AuxiliaryMachineryType[];
};

export type AuxiliaryMachineryType = {
  codiMaq: string;
  maquina: string;
  ut: number | string;
  um: number | string;
};

type columnIDTypes = { [key: string]: { id: string; label: string } };

export const columnNames: columnIDTypes = {
  codiMaq: {
    id: 'codiMaq',
    label: 'Codi maq.',
  },
  maquina: {
    id: 'maquina',
    label: 'Màquina',
  },
  ut: {
    id: 'ut',
    label: 'Ut.',
  },
  um: {
    id: 'um',
    label: 'Um.',
  },
};

// Libraries
import React from 'react';

// Types
import { AccordionProps } from './types';

// Other Components
import { AccordionItem } from './components/AccordionItem';

// Components Styled
import { Container, List } from './styles';

export const Accordion = ({
  data = [],
  onItemClick,
}: AccordionProps): JSX.Element => (
  <Container>
    <List>
      {data.map((item, itemKey) => (
        <AccordionItem
          key={itemKey.toString()}
          items={item?.subItems}
          title={item.title}
          path={item.path}
          onItemClick={onItemClick}
        />
      ))}
    </List>
  </Container>
);

import styled from 'styled-components';

// Utils
import { colors } from '../../../styles/colors';
import {
  breakPoints,
  percentBaseResponsive,
  spaces,
} from '../../../styles/spaces';
import { Typography } from '../../display/Typography';

export const GeneralContainer = styled.form`
  width: 100%;
  padding: ${spaces.xl}rem;
  border-radius: 0.8rem;
  background-color: ${colors.verySoftRed};
`;

export const Title = styled(Typography)`
  margin: 0 0 3.2rem;
`;

export const RowsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spaces.l}rem;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  gap: ${spaces.l}rem;
  @media (max-width: ${breakPoints.landscapeTablet *
    percentBaseResponsive}rem) {
    flex-wrap: wrap;
  }
`;

export const AdviceContainer = styled.div`
  text-align: center;
  padding: ${spaces.s}rem ${spaces.l}rem;
  border: 0.1rem solid ${colors.greyDark};
  width: max-content;
  margin: 0 auto;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: end;
  margin-top: 4rem;
`;

export const TitleContainerWithButton = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${spaces.l}rem;
  flex-wrap: wrap;
  gap: ${spaces.m}rem;
`;

export const ContainerError = styled.div`
  padding-top: ${spaces.s}rem;
`;

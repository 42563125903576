// Dependencies
import styled, { css } from 'styled-components';

// Types
import { sizeTypography, variantTypography, colorTypography } from './types';

// Colors
import { colors } from '../../../styles/colors';

// Fonts
import { fontSize, fontWeight } from '../../../styles/fonts';

interface ContainerProps {
  variant: variantTypography;
  size: sizeTypography;
  colorText: colorTypography;
}

export const Container = styled.p<ContainerProps>`
  font-family: 'Poppins', sans-serif;
  color: ${({ colorText }) => colors[colorText]};
  ${({ variant }) =>
    ({
      regular: css`
        font-weight: ${fontWeight.regular};
      `,
      medium: css`
        font-weight: ${fontWeight.medium};
      `,
      semiBold: css`
        font-weight: ${fontWeight.semiBold};
      `,
    }[variant])}

  ${({ size }) =>
    ({
      XL: css`
        font-size: ${fontSize.XL};
      `,
      L: css`
        font-size: ${fontSize.L};
      `,
      M: css`
        font-size: ${fontSize.M};
      `,
      S: css`
        font-size: ${fontSize.S};
      `,
      XS: css`
        font-size: ${fontSize.XS};
      `,
      XXS: css`
        font-size: ${fontSize.XXS};
      `,
      XXXS: css`
        font-size: ${fontSize.XXXS};
      `,
    }[size])}
`;

import styled from 'styled-components';

// Styles utils
import { colors } from '../../../../styles/colors';
import { fontSize } from '../../../../styles/fonts';
import { breakPoints, percentBaseResponsive } from '../../../../styles/spaces';

// Components
import DropDownList from '../DropDownList';
import { AvatarContainer } from '../../buttons/UserButton/styles';
import { List } from '../DropDownList/styles';
import UserButton from '../../buttons/UserButton';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
`;

export const ContainerHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;

  svg {
    width: 1.1rem;
    height: 0.846rem;
  }
  svg > path {
    fill: ${colors.black};
  }
`;

export const ContainerDropDownTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 0.8rem;
`;

export const ButtonUser = styled(UserButton)`
  &${AvatarContainer} {
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: ${breakPoints.landscapeTablet *
      percentBaseResponsive}rem) {
      width: 3.9rem;
      height: 3.9rem;
      > p {
        font-size: ${fontSize.S};
      }
    }
  }
`;

export const ContainerUser = styled.div`
  line-height: 1.8rem;
`;

export const DropDownGroup = styled(DropDownList)`
  ${List} {
    width: 22.5rem;

    > li {
      font-size: ${fontSize.S};
    }

    @media (max-width: ${breakPoints.landscapeTablet *
      percentBaseResponsive}rem) {
      width: 18.4rem;

      > li {
        font-size: ${fontSize.XXS};
      }
    }
  }
`;

import React, { useRef, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

// Types
import { Props, UserManagement } from './types';

// Schema
import { UserManagementSchema } from './schema';

// Styles
import { Container, InputsContainer, AddUserButton } from './styles';

// Components
import InputText from '../../inputs/InputText';
import DropDownInput from '../../inputs/DropDown/DropDownInput';

// Utils
import { useCacheFormInSessionStorage } from '../../../hooks/useCacheForm';
import { Typography } from '../../display/Typography';
import { ImperativeRefInput } from '../../inputs/DropDown/DropDownInput/types';
import { GroupTypes } from '../../display/ShowOT/WorkOrder/types';

export const AddUserForm = ({
  onFormSubmit,
  namingPersistForm,
  initialData,
}: Props) => {
  const {
    handleSubmit,
    control,
    register,
    formState: { errors },
    reset,
  } = useForm<UserManagement>({
    defaultValues: {
      ...initialData,
      isActive: true,
      isCreated: true,
    },
    resolver: yupResolver(UserManagementSchema),
  });

  useCacheFormInSessionStorage(namingPersistForm, control);

  // Refs
  const roleRef = useRef<ImperativeRefInput>(null);
  const concessionRef = useRef<ImperativeRefInput>(null);

  // States
  const [emailRepeatedError, setEmailRepeatedError] = useState<null | string>(
    null
  );

  const onSubmit = async (data: UserManagement) => {
    const errorMessage = await onFormSubmit(data);
    if (errorMessage) {
      setEmailRepeatedError(errorMessage);
    } else {
      setEmailRepeatedError(null);
      reset({
        name: '',
        surName: '',
        email: '',
        rolActive: '',
        concession: '',
        id: '',
      });
      roleRef.current?.clearSelected();
      concessionRef.current?.clearSelected();
    }
  };

  const conOptions =
    initialData?.concessions?.reduce((acc, curr) => {
      acc.push({
        label: curr?.name || '',
        value: curr?.id || '',
      });

      return acc;
    }, [] as GroupTypes[]) || [];

  const rolOptions = [
    {
      label: 'Super Admin',
      value: 'super-admin',
    },
    {
      label: 'Operador de sala',
      value: 'room-team',
    },
    {
      label: 'Ceconsa',
      value: 'ceconsa',
    },
    {
      label: 'Usuari de Tablet',
      value: 'tablet',
    },
    {
      label: 'Operari de carretera',
      value: 'operating-team',
    },
  ];

  return (
    <Container onSubmit={handleSubmit(onSubmit)}>
      <Typography size="L" variant="semiBold">
        Afegir usuari
      </Typography>

      <InputsContainer>
        <InputText
          {...register('name')}
          label="Nom"
          typeInput={'normal'}
          inputSize="16"
          placeholder="Nom"
          borderError={!!errors.name?.message}
        />
        <InputText
          {...register('surName')}
          label="Cognom"
          typeInput={'normal'}
          placeholder="Cognom"
          borderError={!!errors.surName?.message}
        />
        <InputText
          {...register('email')}
          label="Correu electrònic"
          typeInput={'normal'}
          inputSize="24"
          placeholder="nom@email.com"
          borderError={!!errors.email?.message}
        />
        <Controller
          control={control}
          name="rolActive"
          render={({ field: { onChange }, formState: { defaultValues } }) => {
            return (
              <DropDownInput
                placeholder={'Selecciona'}
                labelText="Rol"
                defaultValue={defaultValues?.rolActive}
                inputSize={'M'}
                typeDropDown={'Default'}
                options={rolOptions}
                onChangeSelected={(option) => onChange(option?.value ?? '')}
                borderError={!!errors.rolActive?.message}
                ref={roleRef}
              />
            );
          }}
        />
        <Controller
          control={control}
          name="concession"
          render={({ field: { onChange }, formState: { defaultValues } }) => {
            return (
              <DropDownInput
                placeholder={'Selecciona'}
                labelText="Concessió"
                defaultValue={defaultValues?.concession}
                inputSize={'M'}
                typeDropDown={'Default'}
                options={conOptions}
                onChangeSelected={(option) => onChange(option?.value ?? '')}
                borderError={!!errors.concession?.message}
                ref={concessionRef}
              />
            );
          }}
        />
        <AddUserButton>⮕</AddUserButton>
        {emailRepeatedError && !errors.email?.message && (
          <Typography colorText="lightCoralRed" size="XS">
            {emailRepeatedError}
          </Typography>
        )}
      </InputsContainer>
    </Container>
  );
};

type ArrayItem = { __ref: string } | null;
type OrderArray = ArrayItem[];

export function findFirstCommonIndex(
  arrToAddElements: ArrayItem[],
  arrayToFindIndex: ArrayItem[]
): number | null {
  // Review
  for (let i = 0; i < arrayToFindIndex.length; i++) {
    const item = arrayToFindIndex[i];
    if (
      item !== null &&
      arrToAddElements.some(
        (order: { __ref: string } | null) =>
          order !== null && order.__ref === item.__ref
      )
    ) {
      return i;
    }
  }

  return -1;
}

export function mergeArrays(
  nullIndex: number,
  existingArray: OrderArray,
  newArray: OrderArray,
  indexOfFirstCoincidenceMerged: number,
  indexOfFirstCoincidenceArrToAdd: number
) {
  if (indexOfFirstCoincidenceArrToAdd > indexOfFirstCoincidenceMerged) {
    for (let i = newArray.length - 1; i >= 0; i--) {
      const item = newArray[i];
      if (
        !existingArray.some(
          (itemExisting) => itemExisting?.__ref === item?.__ref
        )
      ) {
        existingArray.splice(indexOfFirstCoincidenceMerged - i, 0, item);
        existingArray.splice(nullIndex - 1, 1);
      }
    }
  } else {
    for (let i = 0; i < newArray.length; i++) {
      const item = newArray[i];
      if (
        !existingArray.some(
          (itemExisting) => itemExisting?.__ref === item?.__ref
        )
      ) {
        existingArray.splice(indexOfFirstCoincidenceMerged + i, 0, item);
      }
    }
  }
}

export function truncateArrayAfterNull(inputArray: OrderArray) {
  const nullIndex = inputArray.indexOf(null);

  if (nullIndex !== -1) {
    return inputArray.slice(0, nullIndex + 1);
  }

  return inputArray;
}

import { useMedia } from 'react-use';
import { breakPoints, percentBaseResponsive } from '../styles/spaces';

export const useIsSmallDesktop = () =>
  useMedia(
    `(max-width: ${breakPoints.smallDesktop * percentBaseResponsive}rem)`
  );

export const useIsLandscapeTablet = () =>
  useMedia(
    `(max-width: ${breakPoints.landscapeTablet * percentBaseResponsive}rem)`
  );

export const useIsPortraitTablet = () =>
  useMedia(
    `(max-width: ${breakPoints.portraitTablet * percentBaseResponsive}rem)`
  );

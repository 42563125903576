import { defer, LoaderFunction } from 'react-router-dom';

// Sentry
import * as Sentry from '@sentry/react';

// Types
import { decompressSessionStorage } from '../../../../../utils/sessionStorage';
import { AuthContextType } from '../../../../../hooks/useAuth';

// Constants
import {
  formActionNaming,
  formActionValidationNaming,
} from '../../../../../components/forms/ActionForms/constants';

// Selector
import {
  selectActiveOperatorsAndVehicles,
  selectOperatorsAndVehiclesFormValues,
} from '../../../../../gql/selectors/actions';

// Static data
import { hoursType } from '../../../../../data/orders';

// GQL
import { GET_EXTERNAL_COMPANIES } from '../../../../../gql/queries/actions/externalCompanies';
import { client } from '../../../../../gql/client';
import {
  GET_CONCESSIONS,
  GET_ORDER_BY_ID,
} from '../../../../../gql/queries/orders/orders';

const loadFormOptions = async (
  userData: AuthContextType | null,
  actionId: string
) => {
  try {
    if (userData?.user) {
      const internalOperators =
        (await selectActiveOperatorsAndVehicles(false)) ?? [];
      const formValues = await selectOperatorsAndVehiclesFormValues(actionId);
      const { readExternalCompanies } = client.readQuery({
        query: GET_EXTERNAL_COMPANIES,
      });

      const sortedExternalCompanies = [...readExternalCompanies].sort((a, b) =>
        a.companyName.localeCompare(b.companyName)
      );

      return {
        internalOperators,
        externalCompanies: sortedExternalCompanies,
        hoursTypeOptions: hoursType,
        flowData: 'validation',
        ...formValues,
        externals: [...formValues.externalWorks],
      };
    }
  } catch (err) {
    Sentry.captureException(err);
    console.log('> Operators and vehicles loading error:', err);
    return true;
  }
};

export const OperatorsAndVehiclesValidationLoader: (
  userData: AuthContextType | null
) => LoaderFunction | undefined = (userData) => async () => {
  const stringifyForm = sessionStorage.getItem(
    formActionValidationNaming.NEW_VALIDATION_OPERATORS_AND_VEHICLE_FORM
  );

  const stringifyActionData = sessionStorage.getItem(
    formActionNaming.NEW_VALIDATION_ACTION_DATA
  );

  let sessionStorageData;
  let actionData;
  let parentExpeditionOrderStatus;
  let state;

  if (stringifyForm) {
    sessionStorageData = JSON.parse(decompressSessionStorage(stringifyForm));
  }

  if (stringifyActionData) {
    actionData = JSON.parse(decompressSessionStorage(stringifyActionData));
    const {
      data: { order },
    } = await client.query({
      query: GET_ORDER_BY_ID,
      variables: {
        id: actionData.orderId,
      },
    });
    const parentOrder = await client.query({
      query: GET_ORDER_BY_ID,
      variables: {
        id: order.parentExpeditionOrderIDs[0],
      },
    });
    parentExpeditionOrderStatus = parentOrder.data.order.state;
    state = order.state;
  }

  const {
    data: { concessions },
  } = await client.query({
    query: GET_CONCESSIONS,
  });

  const loaderToResolve = (await loadFormOptions(
    userData,
    actionData.id
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  )) as any;
  const externals = [
    ...(sessionStorageData?.externals || []),
    ...(loaderToResolve?.externals || []),
  ];

  const setOfNames = new Set();

  const uniqueCompanies = externals.filter((external) => {
    if (!setOfNames.has(external.company)) {
      setOfNames.add(external.company);
      return external;
    }
  });

  const combinedData = {
    ...(typeof loaderToResolve === 'object' ? loaderToResolve : {}),
    ...sessionStorageData,
    ...actionData,
    parentExpeditionOrderStatus,
    state,
    dataFromServer: loaderToResolve,
    externals: uniqueCompanies,
    concessions,
  };

  return defer({
    data: combinedData,
  });
};

import * as Sentry from '@sentry/react';

// Utils
import { decompressSessionStorage } from '../utils/sessionStorage';

// Types
import { optionsTypes } from '../components/navigation/HamburguerMenu/types';
import { Role } from '../types/auth';
import { filterAllowedRoutes } from './utils';

export function rolesAuthRoutesCheck(routes: optionsTypes[]) {
  try {
    let userData: {
      user: {
        rolActive: Role;
      };
    } = {
      user: {
        rolActive: Role.OperatingTeam,
      },
    };
    const value = sessionStorage.getItem('user');
    if (value) {
      userData = JSON.parse(decompressSessionStorage(value));
    }

    const allowedRoutes = filterAllowedRoutes(routes, userData.user.rolActive);

    return allowedRoutes;
  } catch (err) {
    Sentry.captureException(err);
    console.log(`not support sessionStorage`);
  }
}

import { gql } from '@apollo/client';

export const GET_GENERIC_ORDER_BY_ID = gql`
  query GetGenericOrderById($id: ID!) {
    genericOrder(id: $id) {
      id
      concessionIDs
      roadIDs
      teamIDs
      pkInitKm
      pkInitMeter
      pkEndKm
      pkEndMeter
      track
      direction
      margin
      lane
      orderIDs
    }
  }
`;

export const GET_GENERIC_ORDERS = gql`
  query GetGenericOrders($input: FilterGenericList!) {
    genericOrders(input: $input) {
      genericOrders {
        id
        concessionIDs
        roadIDs
        teamIDs
        pkInitKm
        pkInitMeter
        pkEndKm
        pkEndMeter
        track
        direction
        margin
        lane
        orderIDs
      }
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        lastCursor
      }
    }
  }
`;

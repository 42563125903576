//Dependencies
import React, {
  useCallback,
  useMemo,
  useRef,
  useState,
  useLayoutEffect,
  useEffect,
  ChangeEventHandler,
} from 'react';
import {
  createColumnHelper,
  ColumnFiltersState,
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  flexRender,
  getPaginationRowModel,
  PaginationState,
  VisibilityState,
  FilterFnOption,
  Row,
  getSortedRowModel,
  SortingState,
} from '@tanstack/react-table';
import { format, parseISO, formatISO } from 'date-fns';
import { useSelector, useDispatch } from 'react-redux';
import isEqual from 'lodash.isequal';
import debounce from 'lodash.debounce';

// Styled Components
import {
  TrStyled,
  TableContainerStyled,
  SubContainerFiltersStyled,
  TdStyled,
  SubContainerHead,
  ShowFiltersButton,
  ArrowBottomStyled,
  ContainerButtonStyled,
  CampaignSearchContainer,
  ContainerStyled,
  FiltersWithLoadingStyled,
  ContainerLoader,
  StyledTypography,
} from './styles';
import {
  Th,
  Tbody,
  Title,
  Table,
  TextCell,
  TextHeader,
  InputRadioContainer,
  InputRadioStyled,
  InputCalendarStyled,
} from '../components/MainStyledComponents';

// Components
import { PaginationButtons } from '../components/PaginationButtons';
import { DropDownVisibilityColumn } from '../components/DropDownVisibilityColumn';
import { SpecialTag } from '../../SpecialTag';
import { ButtonIconBackground } from '../../../inputs/buttons/ButtonIconBackground';

// Types
import { columnID, columnNames } from '../components/types';
import { tableProps, dataTable, option } from './types';
import {
  onChangeValue,
  Options,
} from '../../../inputs/DropDown/DropDownList/types';
import { ImperativeRefInput } from '../../../inputs/DropDown/DropDownInput/types';
import {
  ImperativeRefCalendarInput,
  onChangeInputType,
} from '../../../inputs/Calendar/types';
import { variant, size } from '../../SpecialTag/types';
import { Order } from '../../../../types/orders';
import { tableIds, takeBase } from '../types';
import { ImperativeSearchBarInput } from '../../../inputs/SearchBar/types';

// Mocks
import { mocksTable } from './mocks';

// Utils
import { coticFilterFunction, dateFilterFunction } from './utils';

// Media
import {
  useIsLandscapeTablet,
  useIsPortraitTablet,
} from '../../../../hooks/useMediaBreakPoints';
import DropDownInput from '../../../inputs/DropDown/DropDownInput';
import SearchBar from '../../../inputs/SearchBar';
import { stateOptions } from '../../../../utils/order';

// Redux
import { getCursor } from '../../../../state/selectors/ui/tables';
import { setCursor } from '../../../../state/actions/ui/cursorTables';
import { Loader } from '../../../inputs/Loader';
import { setTake } from '../../../../state/actions/ui/takeTables';

export const RelatedCampaignTable = ({
  titleText,
  data,
  setRowState,
  onReFetchData,
  onReFetchPagination,
  tableId,
  showButtonsPagination = true,
  setPageSize,
  ...props
}: tableProps): JSX.Element => {
  const dataAll = data?.campaigns ?? mocksTable;
  const globalCursor = useSelector(getCursor(tableId), isEqual);
  const dispatch = useDispatch();
  const inputListConRef = useRef<ImperativeRefInput>(null);
  const inputStateRef = useRef<ImperativeRefInput>(null);
  const inputDateRef = useRef<ImperativeRefCalendarInput>(null);
  const inputNameRef = useRef<ImperativeSearchBarInput>(null);

  const isLandscapeTablet = useIsLandscapeTablet();
  const isPortraitTablet = useIsPortraitTablet();

  const [isLoading, setIsLoading] = useState(data.loading);
  const [showFilters, setShowFilters] = useState(false);
  const [rowSelection, setRowSelection] = useState({});
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: takeBase,
  });

  const [selectorCursor, setSelectorCursor] = useState<{
    cursor: string;
    take: number;
    indexType: number | undefined;
    indexExpedition: number | undefined;
    concessionId: string | undefined;
    fromDate: string | undefined;
    toDate: string | undefined;
    classification: string | undefined;
    name: string | undefined;
    state: string | undefined;
  }>(globalCursor);
  const [selectorData, setSelectorData] = useState<{
    indexType: number | undefined;
    indexExpedition: number | undefined;
    concessionId: string | undefined;
    fromDate: string | undefined;
    toDate: string | undefined;
    classification: string | undefined;
    name: string | undefined;
    state: string | undefined;
  }>({
    indexType: undefined,
    indexExpedition: undefined,
    concessionId: undefined,
    fromDate: undefined,
    toDate: undefined,
    classification: undefined,
    name: undefined,
    state: undefined,
  });

  const pageSizeModal = isPortraitTablet
    ? showFilters
      ? 5
      : 8
    : isLandscapeTablet
    ? showFilters
      ? 5
      : 7
    : 8;

  const takePagination =
    tableId === tableIds.CAMPAIGNS ? pageSize : pageSizeModal;

  const handleClick = useCallback(() => {
    setShowFilters(!showFilters);
  }, [showFilters]);

  useEffect(() => {
    isLandscapeTablet ? setShowFilters(false) : setShowFilters(true);
  }, [isLandscapeTablet]);

  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>(
    isPortraitTablet
      ? {
          state: false,
          hour: false,
          description: false,
          road: false,
        }
      : isLandscapeTablet
      ? {
          hour: false,
          road: false,
          description: false,
        }
      : {}
  );
  useLayoutEffect(() => {
    setColumnVisibility(
      isPortraitTablet
        ? {
            state: false,
            hour: false,
            description: false,
            road: false,
          }
        : isLandscapeTablet
        ? {
            hour: false,
            description: false,
            road: false,
          }
        : {}
    );
    setPageSize?.(pageSizeModal);
  }, [isPortraitTablet, isLandscapeTablet, pageSizeModal]);

  const [sizeTag, setSizeTag] = useState<size>(
    isPortraitTablet ? 'circle' : 'small'
  );

  useLayoutEffect(() => {
    setSizeTag(isPortraitTablet ? 'circle' : 'small');
  }, [isPortraitTablet]);

  useEffect(() => {
    setIsLoading(data.loading);
  }, [data]);

  const columnHelper = createColumnHelper<dataTable>();

  const [sorting] = useState<SortingState>([
    { id: 'creationDate', desc: true },
  ]);
  const columns = useMemo(
    () => [
      columnHelper.display({
        id: 'select',
        cell: ({ row }) => (
          <InputRadioContainer>
            <InputRadioStyled
              name="inputRadio"
              {...{
                checked: row.getIsSelected(),
                disabled: !row.getCanSelect(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />
          </InputRadioContainer>
        ),
      }),
      columnHelper.accessor(columnNames.state.id, {
        header: () =>
          !isPortraitTablet && (
            <TextHeader size={'XS'} colorText={'greyDark'}>
              {columnNames.state.label}
            </TextHeader>
          ),
        cell: (info) => (
          <SpecialTag sizeTag={sizeTag} variant={info.getValue() as variant} />
        ),
      }),
      columnHelper.accessor(columnNames.cotic.id, {
        header: () => (
          <TextHeader size={'XS'} colorText={'greyDark'}>
            {columnNames.cotic.label}
          </TextHeader>
        ),
        cell: (info) => (
          <TextCell size={'S'}>
            {(info.getValue() as [string])?.join('.')}
          </TextCell>
        ),
        filterFn: 'coticFilter' as FilterFnOption<Order>, //Indicate the filter function to be used for this column
      }),
      columnHelper.accessor(columnNames.registerInitDate.id, {
        header: () => (
          <TextHeader size={'XS'} colorText={'greyDark'}>
            {columnNames.registerInitDate.label}
          </TextHeader>
        ),
        cell: (info) =>
          info.getValue() && (
            <TextCell size={'S'}>
              {format(
                new Date(info.getValue() as string),
                'dd/MM/yyyy - HH:mm'
              )}
            </TextCell>
          ),
        filterFn: 'dateFilter' as FilterFnOption<dataTable>,
      }),

      columnHelper.accessor(columnNames.name.id, {
        header: () => (
          <TextHeader size={'XS'} colorText={'greyDark'}>
            {columnNames.name.label}
          </TextHeader>
        ),
        cell: (info) => (
          <TextCell size={'S'}>{info.getValue() as string}</TextCell>
        ),
        filterFn: 'includesString',
      }),
      columnHelper.accessor(columnNames.concession.id, {
        header: () => (
          <TextHeader size={'XS'} colorText={'greyDark'}>
            {columnNames.concession.label}
          </TextHeader>
        ),
        cell: (info) => (
          <TextCell size={'S'}>{info.getValue() as string}</TextCell>
        ),
      }),
      columnHelper.accessor(columnNames.road.id, {
        header: () => (
          <TextHeader size={'XS'} colorText={'greyDark'}>
            {columnNames.road.label}
          </TextHeader>
        ),
        cell: (info) => (
          <TextCell size={'S'}>{info.getValue() as string}</TextCell>
        ),
      }),
      columnHelper.accessor(columnNames.description.id, {
        header: () => (
          <TextHeader size={'XS'} colorText={'greyDark'}>
            {columnNames.description.label}
          </TextHeader>
        ),
        cell: (info) => (
          <TextCell size={'S'}>{info.getValue() as string}</TextCell>
        ),
      }),
      columnHelper.accessor(columnNames.creationDate.id, {
        header: () => (
          <TextHeader size={'XS'} colorText={'greyDark'}>
            {columnNames.creationDate.label}
          </TextHeader>
        ),
        cell: (info) =>
          info.getValue() && (
            <TextCell size={'S'}>
              {format(
                new Date(info.getValue() as string),
                'dd/MM/yyyy - HH:mm'
              )}
            </TextCell>
          ),
      }),
    ],
    [isPortraitTablet, sizeTag]
  );

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  const filterFns = {
    coticFilter: coticFilterFunction, //I redeclare the custom function in filterFns so that it can be used
    dateFilter: dateFilterFunction,
  };

  const table = useReactTable({
    data: dataAll,
    columns,
    state: {
      rowSelection,
      columnFilters,
      pagination,
      columnVisibility,
      sorting,
    },
    filterFns,
    getSortedRowModel: getSortedRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    enableRowSelection: true, //enable row selection for all rows
    enableMultiRowSelection: false,
    onPaginationChange: setPagination,
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnFiltersChange: setColumnFilters,
    getPaginationRowModel: getPaginationRowModel(),
  });

  const onChangeCampaign: ChangeEventHandler<HTMLInputElement> = useCallback(
    debounce(
      async (event) => {
        setSelectorData((prevData) => {
          const newData = prevData;
          newData.name = event.target.value;
          return newData;
        });
      },
      1000,
      {
        leading: false,
        trailing: true,
      }
    ),
    [
      takePagination,
      selectorCursor,
      selectorData?.concessionId,
      selectorData.toDate,
      selectorData.fromDate,
      selectorData.name,
    ]
  );

  /**
   * *Function for filtering date ranges
   * @param range is a range of dates ({from, to})
   */

  const onChangeDateInput = useCallback<onChangeInputType>(
    (range) => {
      if (range.to) {
        range.to.setHours(23);
        range.to.setMinutes(59);
        range.to.setSeconds(59);
        range.to.setMilliseconds(999);
      }
      const fromDateIso = formatISO(range.from as Date);
      const toDateIso = formatISO(range.to as Date);
      setSelectorData((prevData) => {
        const newData = prevData;
        newData.fromDate = fromDateIso;
        newData.toDate = toDateIso;
        return newData;
      });
    },
    [
      takePagination,
      selectorCursor,
      selectorData?.concessionId,
      selectorData.state,
      selectorData.name,
    ]
  );

  const onChangeSelectedConcession = useCallback(
    (option: option) => {
      setSelectorData((prevData) => {
        const newData = prevData;
        newData.concessionId = option.value;
        return newData;
      });
    },
    [
      takePagination,
      selectorCursor,
      selectorData?.fromDate,
      selectorData?.toDate,
      selectorData.state,
      selectorData.name,
    ]
  );

  const onChangeSelectedState = useCallback(
    async (option: option) => {
      setSelectorData((prevData) => {
        const newData = prevData;
        newData.state = option.value;
        return newData;
      });
    },
    [
      takePagination,
      selectorCursor,
      selectorData.fromDate,
      selectorData.toDate,
      selectorData.concessionId,
      selectorData.name,
    ]
  );

  const onClearValue = useCallback(() => {
    inputListConRef.current?.clearSelected();
    inputDateRef.current?.clearSelected();
    inputStateRef.current?.clearSelected();
    inputNameRef.current?.clear();
    setColumnFilters([]);
    const dataCursor = {
      take: takePagination,
      cursor: '',
      concessionId: undefined,
      fromDate: undefined,
      toDate: undefined,
      orderBy: 'desc',
      state: undefined,
      name: undefined,
    };
    dispatch(setCursor(tableId, dataCursor));
    setSelectorCursor((prevData) => {
      const newData = prevData;
      newData.concessionId = undefined;
      newData.fromDate = undefined;
      newData.toDate = undefined;
      newData.name = undefined;
      newData.state = undefined;
      return newData;
    });
    setSelectorData((prevData) => {
      const newData = prevData;
      newData.concessionId = undefined;
      newData.fromDate = undefined;
      newData.toDate = undefined;
      newData.name = undefined;
      newData.state = undefined;
      return newData;
    });
    onReFetchPagination();
  }, [takePagination]);

  const handleClickRow = (row: Row<dataTable>) => {
    const val: boolean = row.getIsSelected();
    if (!val) {
      setRowState(row.original || {});
    } else {
      setRowState({});
    }
  };

  const hiddenColumns: number = Object.values(
    columnVisibility as object
  ).reduce((count, currentValue) => (!currentValue ? count + 1 : count), 0);

  const commonColumns = Object.values(columnNames).filter((column) =>
    table
      .getAllColumns()
      .map((column) => column.id)
      .includes(column.id)
  );

  const commonLabels = commonColumns.map((column) => column.label);

  const concessionOptions = data?.filters?.concessions?.map((concession) => ({
    label: concession.name,
    value: concession.id,
  }));

  const fetchCampaignID = useCallback(
    async (id: string, take = takePagination) => {
      const dataSelector = {
        take,
        cursor: id,
        concessionId:
          selectorData?.concessionId ?? selectorCursor?.concessionId,
        fromDate: selectorData?.fromDate ?? selectorCursor?.fromDate,
        toDate: selectorData?.toDate ?? selectorCursor?.toDate,
        orderBy: 'desc',
        name: selectorData.name ?? selectorCursor.name,
        state: selectorData.state ?? selectorCursor.state,
      };

      if (take > 0) {
        const lastCampaign =
          dataAll[dataAll.length - 1].genericOrCampaignOrderID;
        if (lastCampaign) {
          dispatch(setCursor(tableId, dataSelector));
        }
      } else {
        const firstAccident = dataAll[0].genericOrCampaignOrderID;
        if (firstAccident) {
          dispatch(setCursor(tableId, dataSelector));
        }
      }

      return dataSelector;
    },
    [
      takePagination,
      selectorCursor,
      selectorData?.concessionId,
      selectorData?.fromDate,
      selectorData?.toDate,
      selectorData.name,
      selectorData.state,
      dataAll,
    ]
  );

  const nextPagination = async () => {
    if (dataAll.length >= 1 && data.pageInfo.hasNextPage) {
      await fetchCampaignID(
        dataAll[dataAll.length - 1].genericOrCampaignOrderID as string
      );
      setIsLoading(true);
      onReFetchPagination();
    }
  };

  const backPagination = async () => {
    if (dataAll.length >= 1 && data.pageInfo.hasPreviousPage) {
      await fetchCampaignID(
        dataAll[0].genericOrCampaignOrderID as string,
        -takePagination
      );
      setIsLoading(true);
      onReFetchPagination();
    }
  };
  const firstPage = useCallback(() => {
    const dataCursor = {
      take: takePagination,
      cursor: '',
      concessionId: selectorData?.concessionId ?? selectorCursor?.concessionId,
      fromDate: selectorData?.fromDate ?? selectorCursor?.fromDate,
      toDate: selectorData?.toDate ?? selectorCursor?.toDate,
      classification:
        selectorData?.classification ?? selectorCursor?.classification,
      orderBy: 'desc',
      name: selectorData.name ?? selectorCursor.name,
      state: selectorData.state ?? selectorCursor.state,
    };
    setIsLoading(true);
    dispatch(setCursor(tableId, dataCursor));
    if (data.pageInfo.hasPreviousPage) {
      onReFetchPagination();
    }
  }, [
    takePagination,
    selectorData.concessionId,
    selectorData.fromDate,
    selectorData.toDate,
    selectorData.name,
    selectorData.state,
    selectorCursor,
  ]);

  const lastPage = useCallback(async () => {
    const take =
      (data?.totalCount as number) % takePagination !== 0
        ? (data?.totalCount as number) % takePagination
        : takePagination;
    const dataCursor = {
      take,
      cursor: data.pageInfo.lastCursor,
      concessionId: selectorData?.concessionId ?? selectorCursor?.concessionId,
      fromDate: selectorData?.fromDate ?? selectorCursor?.fromDate,
      toDate: selectorData?.toDate ?? selectorCursor?.toDate,
      orderBy: 'desc',
      name: selectorData.name ?? selectorCursor.name,
      state: selectorData.state ?? selectorCursor.state,
    };
    setIsLoading(true);
    dispatch(setTake(takePagination));
    dispatch(setCursor(tableId, dataCursor));
    if (data.pageInfo.hasNextPage && take > 0) {
      onReFetchPagination();
    }
  }, [
    data,
    takePagination,
    selectorCursor,
    selectorData.concessionId,
    selectorData.fromDate,
    selectorData.toDate,
    selectorData.name,
    selectorData.state,
  ]);

  const handleSearch = async () => {
    const dataCursor = {
      take: takePagination,
      cursor: selectorCursor?.cursor,
      concessionId: selectorData.concessionId ?? selectorCursor.concessionId,
      fromDate: selectorData?.fromDate ?? selectorCursor?.fromDate,
      toDate: selectorData?.toDate ?? selectorCursor?.toDate,
      name: selectorData.name ?? selectorCursor.name,
      state: selectorData.state ?? selectorCursor.state,
      orderBy: 'desc',
    };
    dispatch(setCursor(tableId, dataCursor));

    setIsLoading(true);
    onReFetchPagination();
  };

  return (
    <ContainerStyled>
      {isLoading && (
        <ContainerLoader>
          <Loader />
        </ContainerLoader>
      )}
      <FiltersWithLoadingStyled isLoading={isLoading}>
        <SubContainerHead>
          <Title component={'h2'} variant={'semiBold'} size={'M'}>
            {titleText}
          </Title>
          {isLandscapeTablet && (
            <ShowFiltersButton isBlack={showFilters} onClick={handleClick}>
              <ArrowBottomStyled isRotate={showFilters} />
            </ShowFiltersButton>
          )}
        </SubContainerHead>
        {showFilters && (
          <SubContainerFiltersStyled>
            <CampaignSearchContainer>
              <StyledTypography size="S">Nom de la campanya</StyledTypography>
              <SearchBar
                variant="campaign"
                onChangeInput={onChangeCampaign}
                ref={inputNameRef}
                disabled={isLoading}
              />
            </CampaignSearchContainer>
            <InputCalendarStyled
              ref={inputDateRef}
              label={'Dates filtre entre:'}
              name={'data'}
              onChangeInput={onChangeDateInput}
              dateRange={
                selectorCursor?.fromDate && selectorCursor?.toDate
                  ? {
                      from: parseISO(selectorCursor?.fromDate),
                      to: parseISO(selectorCursor?.toDate),
                    }
                  : undefined
              }
              disabled={isLoading}
            />
            <DropDownInput
              inputSize="XS"
              ref={inputListConRef}
              labelText={'Concessió'}
              placeholder={'Selecciona'}
              typeDropDown={'Secondary'}
              defaultValue={selectorCursor.concessionId ?? ''}
              options={concessionOptions as Options}
              onChangeSelected={onChangeSelectedConcession as onChangeValue}
              isDisabled={isLoading}
            />
            <DropDownInput
              inputSize="S"
              ref={inputStateRef}
              labelText={'Estat'}
              placeholder={'Selecciona'}
              typeDropDown={'Secondary'}
              defaultValue={selectorCursor.state ?? ''}
              options={stateOptions}
              onChangeSelected={onChangeSelectedState as onChangeValue}
              isDisabled={isLoading}
            />
            <ContainerButtonStyled>
              <ButtonIconBackground
                variant={'delete'}
                onClick={onClearValue}
                disabled={isLoading}
              />
              <DropDownVisibilityColumn
                columnNames={commonLabels}
                columns={table.getAllColumns().slice(1)}
                isDisabled={isLoading}
              />
              <ButtonIconBackground
                variant={'restart'}
                onClick={onReFetchData}
                disabled={isLoading}
              />
              <ButtonIconBackground
                variant={'search'}
                onClick={handleSearch}
                disabled={isLoading}
              />
            </ContainerButtonStyled>
          </SubContainerFiltersStyled>
        )}
      </FiltersWithLoadingStyled>
      <TableContainerStyled isLoading={isLoading}>
        <Table {...props}>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <Th key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </Th>
                ))}
              </tr>
            ))}
          </thead>
          <Tbody>
            {table.getRowModel().rows.map((row) => {
              return (
                <TrStyled
                  checked={row.getIsSelected()}
                  indeterminate={!table.getIsSomeRowsSelected()}
                  key={row.id}
                  onClick={() => {
                    !isLoading && handleClickRow(row);
                    !isLoading && row.getToggleSelectedHandler()(row);
                  }}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TdStyled
                      column={cell.column.id as columnID}
                      hiddenColumns={hiddenColumns}
                      key={cell.id}
                    >
                      <>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </>
                    </TdStyled>
                  ))}
                </TrStyled>
              );
            })}
          </Tbody>
        </Table>
      </TableContainerStyled>
      {showButtonsPagination && (
        <PaginationButtons
          pageCount={2}
          currentPage={0}
          totalCount={data?.totalCount}
          nextPage={nextPagination}
          previousPage={backPagination}
          firstPage={firstPage}
          lastPage={lastPage}
          isDisabled={isLoading}
        />
      )}
    </ContainerStyled>
  );
};

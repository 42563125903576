export const SET_CURSOR = 'SET_CURSOR';

export function setCursor(id: string, data: unknown = {}) {
  return {
    type: SET_CURSOR,
    payload: {
      id,
      data,
    },
  };
}

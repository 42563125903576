import React, { useCallback, useEffect, useLayoutEffect } from 'react';
import {
  Outlet,
  useLocation,
  useAsyncValue,
  useNavigate,
} from 'react-router-dom';

// Redux
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { getNewActionTabsState } from '../../../../../state/selectors/ui/tabs';
import { actionTabs } from '../../../../../state/actions/ui/actionTabs';

// Components
import { SimpleWorkOrder } from '../../../../../components/display/ShowOT/SimpleWorkOrder';

// Styles
import { ButtonTab, TabsHalf, GeneralContainer, TabsContainer } from './styles';

// Types
import { BasicInfoOT } from './types';

export default function ActionPage(): JSX.Element {
  const loaderData = useAsyncValue() as BasicInfoOT;
  const dispatch = useDispatch();
  const selector = useSelector(getNewActionTabsState);
  const location = useLocation();
  const pathNameArray = location.pathname.split('/');
  const pathName = pathNameArray[pathNameArray.length - 1];
  const navigate = useNavigate();
  useLayoutEffect(() => {
    if (!loaderData) {
      navigate('/work-orders');
    }
  }, []);

  useEffect(() => {
    if (!selector.includes(pathName)) {
      dispatch(actionTabs(pathName));
    }
  }, [location]);

  const onClickCheckDisabled = useCallback(
    (path: string) => (e: { preventDefault: () => void }) => {
      if (!selector.includes(path)) e.preventDefault();
    },
    [selector]
  );

  return (
    <GeneralContainer>
      {loaderData && (
        <SimpleWorkOrder
          name={'Informació bàsica de l’actuació'}
          pkInit={loaderData.pkInit}
          pkEnd={loaderData.pkEnd}
          orderDescription={loaderData.orderDescription}
          actionDescription={loaderData.actionDescription}
        />
      )}
      <TabsContainer>
        <TabsHalf>
          <ButtonTab
            to={'operators-and-vehicles'}
            disabled={!selector.includes('operators-and-vehicles')}
          >
            Operaris i vehicles
          </ButtonTab>
          <ButtonTab
            onClick={onClickCheckDisabled('location')}
            to={'location'}
            disabled={!selector.includes('location')}
          >
            Ubicació
          </ButtonTab>
          <ButtonTab
            onClick={onClickCheckDisabled('edition')}
            to={'edition'}
            disabled={!selector.includes('edition')}
          >
            Datos Act
          </ButtonTab>
        </TabsHalf>
        <TabsHalf>
          <ButtonTab
            onClick={onClickCheckDisabled('materials')}
            to={'materials'}
            disabled={!selector.includes('materials')}
          >
            Materials
          </ButtonTab>
          <ButtonTab
            onClick={onClickCheckDisabled('machinery')}
            to={'machinery'}
            disabled={!selector.includes('machinery')}
          >
            Maquinària Aux.
          </ButtonTab>
          <ButtonTab
            onClick={onClickCheckDisabled('animals')}
            to={'animals'}
            disabled={!selector.includes('animals')}
          >
            Animals
          </ButtonTab>
        </TabsHalf>
      </TabsContainer>
      <Outlet />
    </GeneralContainer>
  );
}

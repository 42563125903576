import LZString from 'lz-string';

// Types
import { AuthType } from '../types/auth';

export const decompressSessionStorage = (data: string) =>
  import.meta.env.PROD ? LZString.decompressFromUTF16(data) : data;

export const compressSessionStorage = (data: string) =>
  import.meta.env.PROD ? LZString.compressToUTF16(data) : data;

export const getSessionStorageUser = () => {
  const user = sessionStorage.getItem('user');
  if (user) {
    return JSON.parse(decompressSessionStorage(user));
  }
  return null;
};

export const setSessionStorageUser = (user: AuthType | null) => {
  sessionStorage.setItem('user', compressSessionStorage(JSON.stringify(user)));
};

import React, { useCallback } from 'react';
import { useAsyncValue, useRevalidator } from 'react-router-dom';

// Components
import { UsersManagementTable } from '../../../../components/display/tables/formTables/managementTables/UsersManagementTable';
import { AddUserForm } from '../../../../components/forms/AddUserForm';

// Types
import { UserManagement } from '../../../../components/forms/AddUserForm/types';
import { UserManagementLoader } from './types';

// Styles
import { Container, UserManagementSection } from './styles';

// Constants
import { formUserManagementNaming } from '../../../../components/forms/AddUserForm/constants';

// GQL
import { client } from '../../../../gql/client';
import { SIGN_UP, UPDATE_USER_STATE } from '../../../../gql/mutations/auth';
import { NEW_USER } from '../../../../gql/fragments/users';
import { GET_CONCESSIONS } from '../../../../gql/queries/orders/orders';

export default function UsersManagementPage(): JSX.Element {
  const loaderData = useAsyncValue() as UserManagementLoader;
  const revalidator = useRevalidator();

  const allUsersEmails = [
    ...loaderData.activeUsers,
    ...loaderData.inactiveUsers,
  ].map((user) => user.email);

  const onReFetchData = useCallback(() => {
    revalidator.revalidate();
  }, []);

  const onFormSubmit = async (data: UserManagement) => {
    if (allUsersEmails.some((email) => email === data.email)) {
      return "L'email ja existeix";
    }
    let concessionRelationships;

    if (data.concession === 'all') {
      const {
        data: { concessions },
      } = await client.query({
        query: GET_CONCESSIONS,
      });
      concessionRelationships = concessions.map(
        (concesion: { id: string }) => concesion.id
      );
    } else {
      concessionRelationships = [data.concession];
    }
    await client.mutate({
      mutation: SIGN_UP,
      variables: {
        input: {
          email: data.email,
          name: data.name,
          surName: data.surName,
          rolActive: data.rolActive,
          isActive: true,
          concessionRelationships,
          internal: true,
        },
      },
      update(cache, { data: { signUp } }) {
        cache.modify({
          fields: {
            users(existingUsers = []) {
              const signUpRef = client.writeFragment({
                fragment: NEW_USER,
                data: signUp,
              });

              return [...existingUsers, signUpRef];
            },
          },
        });
      },
    });
    onReFetchData();
  };

  const onAddActiveUser = async (userEmail: string) => {
    await client.mutate({
      mutation: UPDATE_USER_STATE,
      variables: {
        input: {
          email: userEmail,
          isActive: false,
        },
      },
    });

    onReFetchData();
  };

  const onAddInactiveUser = async (userEmail: string) => {
    await client.mutate({
      mutation: UPDATE_USER_STATE,
      variables: {
        input: {
          email: userEmail,
          isActive: true,
        },
      },
    });

    onReFetchData();
  };

  return (
    <Container>
      <UserManagementSection>
        <AddUserForm
          initialData={loaderData.cacheData}
          onFormSubmit={onFormSubmit}
          namingPersistForm={formUserManagementNaming.NEW_USER_MANAGEMENT_FORM}
        />
      </UserManagementSection>
      <UserManagementSection>
        <UsersManagementTable
          onAddActiveUser={onAddActiveUser}
          onAddInactiveUser={onAddInactiveUser}
          data={{
            activeUsers: loaderData.activeUsers,
            inactiveUsers: loaderData.inactiveUsers,
          }}
        />
      </UserManagementSection>
    </Container>
  );
}

// Dependencies
import styled from 'styled-components';

// Styles
import { colors } from '../../../../styles/colors';

export const AvatarContainer = styled.div`
  background-color: ${colors.garnet};
  width: 4.5rem;
  height: 4.5rem;
  border-radius: 50%;
  color: ${colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  border: none;
  min-width: 2.4rem;
  max-width: 5rem;
`;

import React from 'react';
import { useAsyncValue, useNavigate, useSubmit } from 'react-router-dom';

// Components Styled
import { Container, ContainerSpecialTag, DuplicateTag } from './styles';

//Components

// Utils
import { createFormDataFromValues } from '../../../../../utils/forms';

// Types and Const
import { variant } from '../../../../../components/display/SpecialTag/types';
import NewWorkOrderForm from '../../../../../components/forms/NewWorkOrderForm';
import { WorkOrder } from '../../../../../types/workOrder';
import { formOrderNaming } from '../../../../../components/forms/NewWorkOrderForm/constants';

export default function NewOrderDuplicatePage(): JSX.Element {
  const loaderData = useAsyncValue() as WorkOrder;
  const submit = useSubmit();
  const navigate = useNavigate();

  const onFormSubmit = async (values: WorkOrder) => {
    const formData = createFormDataFromValues(values);
    submit(formData, {
      method: 'POST',
      action: '../summary',
    });
  };

  const closeTab = () => {
    sessionStorage.removeItem(formOrderNaming.NEW_DUPLICATE_WORK_ORDER_FORM);
    navigate('..');
  };

  return (
    <Container>
      <ContainerSpecialTag>
        <DuplicateTag variant={variant.Duplicate} onClick={closeTab} />
      </ContainerSpecialTag>
      <NewWorkOrderForm
        initialData={loaderData}
        onFormSubmit={onFormSubmit}
        namingPersistForm={formOrderNaming.NEW_DUPLICATE_WORK_ORDER_FORM}
        titleBasicInfo={'Informació bàsica'}
      />
    </Container>
  );
}

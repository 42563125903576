import React from 'react';

type DirectionRightProps = {
  className?: string;
};

export const DirectionRight = ({
  className,
}: DirectionRightProps): JSX.Element => {
  return (
    <svg
      width="22"
      height="17"
      viewBox="0 0 22 17"
      fill="black"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M12.1232 2.91282L16.4322 7.22183L0.507811 7.22183L0.257811 7.22183L0.257811 7.47183L0.257811 9.50572L0.257811 9.75572L0.507811 9.75572L16.4322 9.75573L12.1232 14.0647C11.6284 14.5595 11.6284 15.3617 12.1232 15.8565C12.6179 16.3512 13.4201 16.3512 13.9149 15.8565L20.3867 9.38465C20.8815 8.88987 20.8815 8.08768 20.3867 7.59291L13.9149 1.12108C13.4201 0.626309 12.6179 0.626308 12.1232 1.12108C11.6284 1.61586 11.6284 2.41805 12.1232 2.91282Z" />
    </svg>
  );
};

import { defer, LoaderFunction } from 'react-router-dom';

// Sentry
import * as Sentry from '@sentry/react';

// GQL
import { client } from '../../../../gql/client';
import {
  FETCH_ANIMALS,
  GET_ANIMALS,
} from '../../../../gql/queries/actions/animals';

// Utils
import { AuthContextType } from '../../../../hooks/useAuth';
import { decompressSessionStorage } from '../../../../utils/sessionStorage';

// Constants
import { formAnimalManagementNaming } from '../../../../components/forms/AddAnimalManagementForm/constants';

const loadTableData = async (userData: AuthContextType | null) => {
  try {
    if (userData?.user) {
      let animalsToReturn;

      const readAnimalsData = client.readQuery({
        query: GET_ANIMALS,
      });

      if (!readAnimalsData || readAnimalsData.readAnimals.length === 0) {
        const {
          data: { animals },
        } = await client.query({
          query: FETCH_ANIMALS,
        });

        animalsToReturn = animals;
      } else {
        animalsToReturn = readAnimalsData.readAnimals;
      }

      animalsToReturn = [...animalsToReturn].sort((a, b) =>
        a.name.localeCompare(b.name)
      );

      return animalsToReturn.map(
        (animal: { id: string; animalId: string; name: string }) => ({
          id: animal.id,
          animalId: animal.animalId,
          name: animal.name,
        })
      );
    }
    return {};
  } catch (err) {
    Sentry.captureException(err);
    console.log('> User management loading error:', err);
    return true;
  }
};

export const AnimalsManagementLoader: (
  animalData: AuthContextType | null
) => LoaderFunction | undefined = (animalData) => async () => {
  const data = await loadTableData(animalData);

  let cacheData =
    sessionStorage.getItem(
      formAnimalManagementNaming.NEW_ANIMAL_MANAGEMENT_FORM
    ) || {};

  if (Object.keys(cacheData).length > 0) {
    cacheData = JSON.parse(decompressSessionStorage(cacheData as string));
  }

  return defer({
    data: {
      data,
      cacheData,
    },
  });
};

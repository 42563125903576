import { defer, LoaderFunction } from 'react-router-dom';

// Sentry
import * as Sentry from '@sentry/react';

// Types
import { AuthContextType } from '../../../../../../hooks/useAuth';

// GraphQL
import { selectImagesByOrderIDs } from '../../../../../../gql/selectors/multimedia';

const loadMultimediaGrid = async (
  idOrder: string,
  userData: AuthContextType | null
) => {
  try {
    const orderMultimediaData = await selectImagesByOrderIDs(idOrder);

    return {
      ...orderMultimediaData,
      operator: `${userData?.user.user.name} ${
        userData?.user.user.surName.split(' ')[0]
      }`,
    };
  } catch (err) {
    Sentry.captureException(err);
    console.log('> Communication Summary General View loading error:', err);
    return false;
  }
};
export const LoaderGeneralViewMultimediaGrid: (
  userData: AuthContextType | null
) => LoaderFunction =
  (userData) =>
  async ({ params }) => {
    if (!params.id) return null;
    // if (Object.keys(client.cache.extract()).length === 0) return null;

    const loaderToResolve = loadMultimediaGrid(params.id, userData);

    return defer({ data: loaderToResolve });
  };

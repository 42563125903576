import { ActionFunction } from 'react-router-dom';
import { Store } from 'redux';

// Sentry
import * as Sentry from '@sentry/react';

// GQL
import { formNaming } from '../../../../../components/forms/NewCommunicationForm/constants';

// Selector
import { getCurrentIndexTabFormByTab } from '../../../../../state/selectors/ui/tabs';
import { selectedOT } from '../../../../../state/selectors/ui/modal';

// Store
import { RootState } from '../../../../../state/configureStore';
import { passData } from './ActionUtils/passData';
import { saveData } from './ActionUtils/saveData';
import { tabsType } from '../../../../../state/reducers/ui/tabs';

export const ActionCommunicationDuplicateSummary: (
  store: Store<RootState>
) => ActionFunction =
  (store) =>
  async ({ request }) => {
    const formData = await request.formData();
    const intent = formData.get('intent');
    const storedDataOTSelected = selectedOT(
      formNaming.NEW_DUPLICATE_COMMUNICATION_FORM
    )(store.getState() as RootState);
    const tabSelected = getCurrentIndexTabFormByTab(
      tabsType.DUPLICATE_COMMUNICATION
    )(store.getState() as RootState);
    if (intent === 'pass-data') {
      try {
        return passData({
          formData,
          tabSelected,
          storedDataOTSelected,
        });
      } catch (err) {
        Sentry.captureException(err);
        console.log('Error:', err);
      }
    }

    if (intent === 'save-data') {
      try {
        const id = formData.get('id');

        return saveData({
          id,
          tabSelected,
          storedDataOTSelected,
          store,
        });
      } catch (err) {
        Sentry.captureException(err);
        console.log('> CommunicationSummary - error creating:', err);
      }
    }
    return null;
  };

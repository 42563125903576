import { defer, LoaderFunction } from 'react-router-dom';

// Sentry
import * as Sentry from '@sentry/react';

// GraphQL
import { selectWorkOrderWithChildren } from '../../../../gql/selectors/orders';

const loadWorkOrderDetails = async (id: string) => {
  try {
    return await selectWorkOrderWithChildren(true, id);
  } catch (err) {
    Sentry.captureException(err);
    console.log('> Work order loading error:', err);
    return false;
  }
};

export const LoaderWorkOrderDetailed: LoaderFunction = async ({ params }) => {
  if (!params.id) return null;

  const data = await loadWorkOrderDetails(params.id);
  return defer({ data });
};

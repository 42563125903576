// For operators

export const findNewIDs = (
  existingIDs: string[],
  newIDs: string[]
): string[] => {
  const existingSet = new Set(existingIDs);

  const newIdArray = newIDs.filter((id) => !existingSet.has(id));

  return newIdArray;
};

export const findRemovedIDs = (
  oldIDs: string[],
  newIDs: string[]
): string[] => {
  const newSet = new Set(newIDs);

  const removedIDs = oldIDs.filter((id) => !newSet.has(id));

  return removedIDs;
};

// For vehicles

export const findNewAndUpdatedVehicles = (
  currentVehicleData: {
    vehicleId: string;
    driverId: string;
    vehicleOccupantId: string;
  }[],
  newVehicleData: {
    vehicleId: string;
    driverId: string;
    vehicleOccupantId: string;
  }[]
) => {
  const existingVehiclesMap = new Map(
    currentVehicleData.map((vehicle) => [vehicle.vehicleId, vehicle])
  );

  const toAdd = [];
  const toUpdate = [];

  for (const newVehicle of newVehicleData) {
    const existingVehicle = existingVehiclesMap.get(newVehicle.vehicleId);

    if (!existingVehicle) {
      toAdd.push(newVehicle);
    } else {
      const hasDriverChange = newVehicle.driverId !== existingVehicle.driverId;

      if (hasDriverChange) {
        toUpdate.push(newVehicle);
      }
    }
  }

  return {
    toAdd,
    toUpdate,
  };
};

export const findRemovedVehicleIds = (
  currentVehicleData: {
    vehicleId: string;
    vehicleOccupantId: string;
  }[],
  newVehicleData: {
    vehicleId: string;
    vehicleOccupantId: string;
  }[]
) => {
  const removedIds = [];

  for (const currentVehicle of currentVehicleData) {
    if (
      !newVehicleData.some(
        (newVehicle) => newVehicle.vehicleId === currentVehicle.vehicleId
      )
    ) {
      removedIds.push(currentVehicle.vehicleOccupantId);
    }
  }

  return removedIds;
};

// For externalWorks

export const findNewAndUpdatedExternalCompanies = (
  currentExternalCompaniesData: {
    externalCompanyIDs: string[];
    id: string;
    operatorsNumber: number;
  }[],
  newExternalCompaniesData: {
    externalCompanyIDs: string[];
    id: string;
    operatorsNumber: string;
    company: string;
  }[]
) => {
  // Create a Map from existing vehicle IDs for efficient lookups
  const existingCompaniesMap = new Map(
    currentExternalCompaniesData.map((company) => [
      company.externalCompanyIDs[0],
      company,
    ])
  );

  const worksToAdd = [];
  const worksToUpdate = [];

  for (const newCompany of newExternalCompaniesData) {
    const existingCompany = existingCompaniesMap.get(
      newCompany.externalCompanyIDs[0]
    );

    // New vehicle (no matching ID in current data)
    if (!existingCompany) {
      worksToAdd.push(newCompany);
    } else {
      // Existing vehicle with potential updates
      const hasDriverChange =
        Number(newCompany.operatorsNumber) !== existingCompany.operatorsNumber;

      if (hasDriverChange) {
        worksToUpdate.push({
          externalWorkId: newCompany.id,
          operatorsNumber: Number(newCompany.operatorsNumber),
        });
      }
    }
  }

  return {
    worksToAdd,
    worksToUpdate,
  };
};

export const findRemovedExternalCompaniesIds = (
  currentExternalCompaniesData: {
    externalCompanyIDs: string[];
    id: string;
    operatorsNumber: number;
    workId: string;
  }[],
  newExternalCompaniesData: {
    externalCompanyIDs: string[];
    id: string;
    operatorsNumber: string;
  }[]
) => {
  const removedIds = [];

  for (const currentCompany of currentExternalCompaniesData) {
    if (
      !newExternalCompaniesData.some(
        (newCompany) =>
          newCompany.externalCompanyIDs[0] ===
          currentCompany.externalCompanyIDs[0]
      )
    ) {
      removedIds.push(currentCompany.id);
    }
  }

  return removedIds;
};

// For animals
export const findNewAndUpdatedAnimals = (
  currentAnimalsData: {
    statusId: string;
    ut: string;
    animalUnitId?: string;
    id: string;
  }[],
  newAnimalsData: {
    animalUnitId: string;
    statusId: string;
    ut: number;
    id: string;
  }[]
) => {
  const existingAnimalsMap = new Map(
    currentAnimalsData.map((animal) => [animal.id, animal])
  );

  const animalsToAdd = [];
  const animalsToUpdate = [];

  for (const newAnimal of newAnimalsData) {
    const existingAnimal = existingAnimalsMap.get(newAnimal.id);

    if (!existingAnimal) {
      animalsToAdd.push(newAnimal);
    } else {
      const hasDriverChange =
        newAnimal.statusId !== existingAnimal.statusId ||
        newAnimal.ut !== Number(existingAnimal.ut);

      if (hasDriverChange) {
        animalsToUpdate.push({
          animalUnitId: newAnimal.animalUnitId,
          newValue: Number(newAnimal.ut),
          animalStatusId: newAnimal.statusId,
        });
      }
    }
  }

  return {
    animalsToAdd,
    animalsToUpdate,
  };
};

export const findRemovedAnimalIds = (
  currentAnimalsData: {
    id: string;
    animalUnitId: string;
  }[],
  newAnimalsData: {
    id: string;
    animalUnitId: string;
  }[]
) => {
  const removedIds = [];

  for (const currentAnimal of currentAnimalsData) {
    if (
      !newAnimalsData.some((newAnimal) => newAnimal.id === currentAnimal.id)
    ) {
      removedIds.push(currentAnimal.animalUnitId);
    }
  }

  return removedIds;
};

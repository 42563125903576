import React from 'react';

import { Controller } from 'react-hook-form';

// Components Styled
import { ArrowRadioGroup } from '../../../pages/main/comunications/NewComunication/styles';
import {
  ContainerBasicInfo,
  InputsContainer,
  Title,
} from '../components/MainStyled';

//Components
import { DateTime } from '../../inputs/DateTime';

// Types
import { CommunicationSendStatus } from '../../../types/communication';
import { FormProps } from './types';
import DropDownInput from '../../inputs/DropDown/DropDownInput';
import InputText from '../../inputs/InputText';

const BasicInfo = ({
  register,
  control,
  errors,
}: Omit<FormProps, 'setValue'>) => {
  return (
    <ContainerBasicInfo>
      <Title component="h2" variant="semiBold" size="L">
        Informació bàsica i de contacte
      </Title>

      <InputsContainer>
        <InputText
          {...register('operator')}
          disabled
          label="Operador"
          typeInput="normal"
          borderError={!!errors.operator}
        />

        <Controller
          control={control}
          name="registerInitDate"
          render={({ field: { onChange }, formState: { defaultValues } }) => {
            return (
              <DateTime
                label="Data de registre:"
                labelHour="Hora:"
                onChangeDate={onChange}
                initialDate={
                  defaultValues?.registerInitDate
                    ? defaultValues?.registerInitDate
                    : new Date()
                }
                borderError={!!errors.registerInitDate}
              />
            );
          }}
        />

        <Controller
          control={control}
          name="groupInter"
          render={({ field: { onChange }, formState: { defaultValues } }) => {
            const options =
              defaultValues?.interlocutorGroups?.map((interlocutor) => ({
                label: interlocutor?.name || '',
                value: interlocutor?.id || '',
              })) || [];

            return (
              <DropDownInput
                inputSize="M"
                onChangeSelected={(option) => onChange(option?.value ?? '')}
                typeDropDown="Default"
                labelText="Grup interlocutor"
                placeholder="Selecciona"
                defaultValue={defaultValues?.groupInter}
                borderError={!!errors.groupInter}
                options={options}
              />
            );
          }}
        />

        <Controller
          control={control}
          name="media"
          render={({ field: { onChange }, formState: { defaultValues } }) => {
            const options =
              defaultValues?.mediaOfCommunications?.map((media) => ({
                label: media?.name || '',
                value: media?.id || '',
              })) || [];

            return (
              <DropDownInput
                inputSize="M"
                onChangeSelected={(option) => onChange(option?.value ?? '')}
                typeDropDown="Default"
                labelText="Medi"
                placeholder="Selecciona"
                defaultValue={defaultValues?.media}
                borderError={!!errors.media}
                options={options}
              />
            );
          }}
        />

        <Controller
          control={control}
          name="sendStatus"
          render={({ field: { onChange }, formState: { defaultValues } }) => {
            const defaultSendStatusValue = {
              [CommunicationSendStatus.SENT]: 'up',
              [CommunicationSendStatus.RECEIVED]: 'down',
            };

            return (
              <ArrowRadioGroup
                directions={['up', 'down']}
                name="enviada-rebuda"
                label={['Enviada', 'Rebuda']}
                separated
                defaultValue={
                  defaultSendStatusValue[
                    defaultValues?.sendStatus as CommunicationSendStatus
                  ]
                }
                required
                borderError={!!errors.sendStatus}
                onChangeDirection={(dir) => {
                  onChange(
                    dir === 'up'
                      ? CommunicationSendStatus.SENT
                      : CommunicationSendStatus.RECEIVED
                  );
                }}
              />
            );
          }}
        />
      </InputsContainer>
    </ContainerBasicInfo>
  );
};

export default BasicInfo;

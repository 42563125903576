import { gql } from '@apollo/client';

export const FETCH_USERS = gql`
  query FetchUsers {
    users {
      id
      email
      name
      surName
      roles
      rolActive
      permissions
      internal
      validated
      teamIDs
      concessionIDs
      actionIDs
      vehicleOccupantDriverIDs
      isActive
    }
  }
`;

export const GET_USERS = gql`
  query GetUsers {
    readUsers @client {
      id
      email
      name
      surName
      roles
      rolActive
      permissions
      internal
      validated
      teamIDs
      concessionIDs
      actionIDs
      vehicleOccupantDriverIDs
      isActive
    }
  }
`;

export const GET_OPERATORS = gql`
  query GetOperators {
    readOperators @client {
      id
      email
      name
      surName
      roles
      permissions
      internal
      validated
      teamIDs
      concessionIDs
      isActive
    }
  }
`;

export const GET_OPERATORS_INTERNALS = gql`
  query GetOperatorsInternals {
    readOperatorsInternals @client {
      id
      email
      name
      surName
      roles
      permissions
      internal
      validated
      teamIDs
      concessionIDs
      isActive
    }
  }
`;

export const GET_ACTIVE_OPERATORS = gql`
  query GetOperatorsInternals {
    readActiveOperators @client {
      id
      email
      name
      surName
      roles
      permissions
      internal
      validated
      teamIDs
      concessionIDs
      isActive
    }
  }
`;

export const GET_OPERATORS_EXTERNALS = gql`
  query GetOperatorsExternals {
    readOperatorsExternals @client {
      id
      email
      name
      surName
      roles
      permissions
      internal
      validated
      teamIDs
      isActive
    }
  }
`;

export const FETCH_TEAMS = gql`
  query FetchTeams {
    teams {
      id
      name
      nameOffice
      concessionIDs
      roadIDs
      userIDs
    }
  }
`;

export const GET_TEAM_BY_ID = gql`
  query GetTeamById($id: ID!) {
    team(id: $id) {
      id
      name
      nameOffice
      concessionIDs
      roadIDs
      userIDs
    }
  }
`;

export const GET_USER_BY_ID = gql`
  query GetUserById($input: FetchUser!) {
    user(input: $input) {
      id
      email
      name
      surName
      roles
      permissions
      internal
      validated
      teamIDs
      concessionIDs
      actionIDs
      vehicleOccupantDriverIDs
      isActive
    }
  }
`;

import React, { useLayoutEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useAsyncValue, useNavigate } from 'react-router-dom';

// Components
import MaterialsForm from '../../../../../components/forms/ActionForms/MaterialsForm';

// Types
import { MaterialFormValues } from '../../../../../components/forms/ActionForms/MaterialsForm/types';
import { OrderType } from '../../../../../types/orders';

// Redux
import { coticOTSelected } from '../../../../../state/actions/ui/coticOTSelectedGeneralView';

// Constants
import { formActionNaming } from '../../../../../components/forms/ActionForms/constants';

export default function MaterialsFormPage(): JSX.Element {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loaderData = useAsyncValue() as MaterialFormValues;

  useLayoutEffect(() => {
    dispatch(
      coticOTSelected(
        OrderType.Action,
        loaderData.breadCrumbsData.orderParentCotic,
        loaderData.breadCrumbsData.orderParentId
      )
    );
  }, []);

  const onFormSubmit = () => {
    navigate('../machinery');
  };

  return (
    <MaterialsForm
      onFormSubmit={onFormSubmit}
      initialData={loaderData}
      sessionStorageKey={formActionNaming.NEW_MATERIALS_FORM}
    />
  );
}

import React, {
  useState,
  useLayoutEffect,
  useEffect,
  useCallback,
} from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch } from 'react-redux';

// Utils
import { useCacheFormInSessionStorage } from '../../../hooks/useCacheForm';
import { newWorkOrderSchema } from './schema';
import { modalIds } from '../../modals/SuperModal/types';
import { openModal } from '../../../state/actions/ui/modal';

// Sentry
import * as Sentry from '@sentry/react';

// Types
import { type WorkOrder } from '../../../types/workOrder';
import { AutoFillData, Props } from './types';

// Components
import { BasicInfo } from './BasicInfo';
import { Description } from './Description';
import { MainButton } from '../../inputs/buttons/MainButton';

// Styles
import { Typography } from '../../display/Typography';
import {
  ButtonsContainer,
  ContainerForm,
  FieldsContainer,
} from '../components/MainStyled';
import { ContainerWithButton } from './styles';
import { client } from '../../../gql/client';
import { GET_ORDER_BY_ID } from '../../../gql/queries/orders/orders';
import { GET_GENERIC_ORDER_BY_ID } from '../../../gql/queries/genericOrders';
import { GET_CAMPAIGN_BY_ID } from '../../../gql/queries/campaign';
import { formAccidentNaming } from '../AddBasicInfoAccidentForm/constants';

const NewWorkOrderForm = ({
  onFormSubmit,
  initialData,
  namingPersistForm,
  isCampaign = false,
  isAction = false,
  isAccident = false,
  errorMessage,
  titleBasicInfo,
  titleDate,
  textDateHour,
}: Props) => {
  const [switchState, setSwitchState] = useState<boolean>(false);

  const [autoFillData, setAutoFillData] = useState({});
  const {
    handleSubmit,
    register,
    control,
    setValue,
    formState: { errors },
  } = useForm<WorkOrder>({
    defaultValues: {
      ...initialData,
      intent: 'pass-data',
    },
    resolver: yupResolver(newWorkOrderSchema),
    context: { switchState: switchState, isAction: isAction },
  });

  useCacheFormInSessionStorage<WorkOrder>(namingPersistForm, control);

  const switchStateValue = () => {
    const { isCampaignActive } = initialData;

    if (isCampaignActive) {
      return isCampaignActive === 'true';
    }

    return isCampaign;
  };

  useLayoutEffect(() => {
    setSwitchState(switchStateValue());
  }, []);

  useEffect(() => {
    isAccident && setSwitchState(false);
  }, [isAccident]);

  const dispatch = useDispatch();
  const handleChange = () => {
    if (switchState) {
      dispatch(
        openModal(modalIds.CHANGE_CAMPAIGN_TO_OT_MODAL, {
          handleClickContinue: () => {
            setSwitchState(false);
            setValue('isCampaignActive', 'false');
            setValue('campaign', '');
          },
        })
      );
    } else {
      setSwitchState(true);
      setValue('isCampaignActive', 'true');
    }
  };

  const fetchDataToAutoFill = useCallback(async () => {
    const order = await client.query({
      query: GET_ORDER_BY_ID,
      variables: {
        id: initialData.orderParentId,
      },
    });

    if (order.data.order.genericOrdersIDs.length > 0) {
      const genericOrderData = await client.query({
        query: GET_GENERIC_ORDER_BY_ID,
        variables: {
          id: order.data.order.genericOrdersIDs[0],
        },
      });

      return {
        order: order.data.order,
        genericOrder: genericOrderData.data.genericOrder,
      };
    }

    if (order.data.order.campaignIDs.length > 0) {
      const campaignData = await client.query({
        query: GET_CAMPAIGN_BY_ID,
        variables: {
          id: order.data.order.campaignIDs[0],
        },
      });
      return {
        order: order.data.order,
        campaignData: campaignData.data.campaign,
      };
    }
  }, [initialData]);

  //TODO: review types
  const autoFillFunction = useCallback(async () => {
    try {
      const result = await fetchDataToAutoFill();
      setAutoFillData(result as AutoFillData);
    } catch (error) {
      Sentry.captureException(error);
      console.log('Error fetching data:', error);
    }
  }, [initialData]);

  return (
    <ContainerForm onSubmit={handleSubmit(onFormSubmit)}>
      <FieldsContainer>
        <ContainerWithButton>
          <BasicInfo
            control={control}
            register={register}
            errors={errors}
            isAction={isAction}
            title={titleBasicInfo}
            titleDate={titleDate}
            textDateHour={textDateHour}
          />
          {(isAction ||
            namingPersistForm === formAccidentNaming.NEW_ACCIDENT_FORM) && (
            <MainButton
              variant="secondary"
              text="Autoremplenar"
              onClick={autoFillFunction}
              type="button"
            />
          )}
        </ContainerWithButton>
        <Description
          control={control}
          setValue={setValue}
          register={register}
          errors={errors}
          switchState={switchState}
          setSwitchState={handleChange}
          namingPersistForm={namingPersistForm}
          isAccident={isAccident}
          isAction={isAction}
          autoFillData={autoFillData}
        />
        {errorMessage && (
          <Typography colorText={'lightCoralRed'} size={'XS'}>
            {errorMessage}
          </Typography>
        )}
      </FieldsContainer>
      <ButtonsContainer>
        <MainButton value={'primary'} text="Continuar" type="submit" />
      </ButtonsContainer>
    </ContainerForm>
  );
};

export default NewWorkOrderForm;

// Libraries
import React, {
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

// Components Styled
import {
  Link,
  MenuButton,
  Item,
  List,
  SubItem,
  ContainerArrowIcon,
  ArrowDown,
} from './styles';

// Types
import { AccordionItemProps } from './types';

// Others Components
import { Typography } from '../../../../display/Typography';
import { coticOTSelectedReset } from '../../../../../state/actions/ui/coticOTSelectedGeneralView';
import { useDispatch } from 'react-redux';

// Styles utils

export const AccordionItem = ({
  title,
  path,
  items = [],
  active = false,
  onItemClick,
}: AccordionItemProps): JSX.Element => {
  const menuRef = useRef<HTMLUListElement>(null);
  const [height, setHeight] = useState<number>(0);
  const dispatch = useDispatch();
  const [isCollapsed, setCollapsed] = useState<boolean>(active);

  const handleClickButton = useCallback(() => {
    dispatch(coticOTSelectedReset());
    if (items?.length) {
      setCollapsed(!isCollapsed);
    }
  }, [isCollapsed]);

  const hasActiveChild = useMemo(
    () => !!menuRef.current?.firstChild,
    [menuRef.current]
  );

  useLayoutEffect(() => {
    if (hasActiveChild) {
      setCollapsed(hasActiveChild);
    }
  }, [hasActiveChild]);

  useLayoutEffect(() => {
    if (menuRef.current?.firstChild instanceof Element) {
      setHeight(
        (menuRef.current?.firstChild?.clientHeight * items.length) / 10
      );
    }
  }, [menuRef.current]);

  return (
    <Item>
      <MenuButton onClick={handleClickButton} to={path} end>
        <ContainerArrowIcon isActive={!isCollapsed}>
          <ArrowDown />
        </ContainerArrowIcon>
        <Typography size="S">{title}</Typography>
      </MenuButton>
      <List ref={menuRef} isCollapsed={isCollapsed} height={height}>
        {items?.length > 0 &&
          items.map((item, index) => (
            <SubItem key={index.toString()}>
              <Link to={item.path} onClick={onItemClick}>
                <Typography size="S">{item.text}</Typography>
              </Link>
            </SubItem>
          ))}
      </List>
    </Item>
  );
};

import { gql } from '@apollo/client';

export const GET_CAMPAIGN_BY_ID = gql`
  query GetCampaignById($id: ID!) {
    campaign(id: $id) {
      id
      name
      concessionIDs
      roadIDs
      teamIDs
      pkInitKm
      pkInitMeter
      pkEndKm
      pkEndMeter
      track
      direction
      margin
      lane
      orderIDs
    }
  }
`;

export const GET_CAMPAIGNS = gql`
  query GetCampaigns($input: FilterCampaignList!) {
    campaigns(input: $input) {
      campaigns {
        id
        name
        concessionIDs
        roadIDs
        teamIDs
        pkInitKm
        pkInitMeter
        pkEndKm
        pkEndMeter
        track
        direction
        margin
        lane
        orderIDs
      }
      totalCount
      pageInfo {
        lastCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

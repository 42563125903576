import styled from 'styled-components';
import { TableContainer } from '../SummaryTablesStyles';
import {
  breakPoints,
  percentBaseResponsive,
} from '../../../../../styles/spaces';

export const TableContainerStyled = styled(TableContainer)`
  min-height: 19.2rem;
  @media (max-width: ${breakPoints.landscapeTablet *
    percentBaseResponsive}rem) {
    min-height: 15.1rem;
  }
`;

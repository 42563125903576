import styled from 'styled-components';

// Styles utils
import {
  percentBaseResponsive,
  breakPoints,
  spaces,
} from '../../../../styles/spaces';
import { PaginationButtons } from '../../../display/tables/components/PaginationButtons';
import { MainButton } from '../../../inputs/buttons/MainButton';

export const Container = styled.div``;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: ${breakPoints.portraitTablet * percentBaseResponsive}rem) {
    flex-wrap: wrap;
    gap: ${spaces.m}rem;
    justify-content: center;
  }
`;

export const MainButtonStyled = styled(MainButton)`
  @media (max-width: ${breakPoints.portraitTablet * percentBaseResponsive}rem) {
    order: 2;
  }
`;

export const CloseButton = styled(MainButton)`
  @media (max-width: ${breakPoints.portraitTablet * percentBaseResponsive}rem) {
    order: 2;
  }
`;

export const PaginationButtonsStyled = styled(PaginationButtons)`
  padding: 0;

  @media (max-width: ${breakPoints.portraitTablet * percentBaseResponsive}rem) {
    width: 100%;
    order: 1;
    justify-content: center;
    align-items: center;
  }
`;

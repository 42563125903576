import { defer, LoaderFunction } from 'react-router-dom';

// Sentry
import * as Sentry from '@sentry/react';

// GQL
import { client } from '../../../../../gql/client';
import {
  FETCH_PREDEFINED_ORDERS,
  FETCH_SUB_CAPITOLS,
} from '../../../../../gql/queries/orders/predefinedData';
import { GET_SELECTORS_DATA } from '../../../../../gql/queries/orders/orders';
import { formNaming } from '../../../../../components/forms/NewCommunicationForm/constants';
import {
  FETCH_CAPITOLS,
  FETCH_CLASSIFICATIONS,
} from '../../../../../gql/queries/orders/predefinedData';

// Type
import { AuthContextType } from '../../../../../hooks/useAuth';

// Data static
import {
  groupLanes,
  groupTracks,
  specialTagType,
} from '../../../../../data/orders';

// Compression
import { decompressSessionStorage } from '../../../../../utils/sessionStorage';
import { selectCommunicationOrderByIdOrderWithRelationshipIds } from '../../../../../gql/selectors/communications';

// Redux
import { store } from '../../../../../state/configureStore';
import { tabs } from '../../../../../state/actions/ui/tabs';
import { tabsType } from '../../../../../state/reducers/ui/tabs';
import { OrderType } from '../../../../../types/orders';

const loadEditCommunicationSummary = async (
  idOrder: string,
  userData: AuthContextType | null
) => {
  try {
    const orderCommunicationData =
      await selectCommunicationOrderByIdOrderWithRelationshipIds(idOrder);
    return {
      ...orderCommunicationData,
      operator: `${userData?.user.user.name} ${
        userData?.user.user.surName.split(' ')[0]
      }`,
    };
  } catch (err) {
    Sentry.captureException(err);
    console.log('> Communication Summary loading error:', err);
    return true;
  }
};

const loadFormOptions = async (userData: AuthContextType | null) => {
  try {
    if (userData?.user) {
      const [
        { data },
        {
          data: { capitols },
        },
        {
          data: { predefinedOrders },
        },
        {
          data: { classifications },
        },
        {
          data: { subCapitols },
        },
      ] = await Promise.all([
        client.query({
          query: GET_SELECTORS_DATA,
        }),
        client.query({
          query: FETCH_CAPITOLS,
        }),
        client.query({
          query: FETCH_PREDEFINED_ORDERS,
        }),
        client.query({
          query: FETCH_CLASSIFICATIONS,
        }),
        client.query({
          query: FETCH_SUB_CAPITOLS,
        }),
      ]);

      return {
        ...data,
        capitols,
        predefinedOrders,
        classifications,
        groupTracks,
        groupLanes,
        specialTagType,
        subCapitols,
      };
    }
    return {};
  } catch (err) {
    Sentry.captureException(err);
    console.log('> Communication Edit summary loading error:', err);
    return true;
  }
};

export const EditCommunicationLoader: (
  userData: AuthContextType | null
) => LoaderFunction | undefined =
  (userData) =>
  async ({ params }) => {
    let dataFromCommunicationToEdit: any;
    if (params.id) {
      dataFromCommunicationToEdit = await loadEditCommunicationSummary(
        params.id,
        userData
      );
    }

    const dispatch = store.dispatch;

    const stringifyForm = sessionStorage.getItem(
      formNaming.EDIT_COMMUNICATION_FORM
    );

    const loaderToResolve = await loadFormOptions(userData);

    let defaultTab = 1;

    if (dataFromCommunicationToEdit?.parentOrderType === OrderType.Campaign) {
      defaultTab = 2;
    }

    dispatch(tabs(tabsType.EDIT_COMMUNICATION, defaultTab));

    if (stringifyForm) {
      const sessionStorageData = JSON.parse(
        decompressSessionStorage(stringifyForm)
      );

      const combinedData = {
        team: userData?.user?.user?.teamId,
        ...loaderToResolve,
        ...dataFromCommunicationToEdit,
        ...sessionStorageData,
      };

      return defer({
        data: combinedData,
      });
    }

    const nameOperator = `${userData?.user?.user.name} ${
      userData?.user?.user.surName.split(' ')[0]
    }`;
    const combinedData = {
      team: userData?.user?.user?.teamId,
      ...loaderToResolve,
      ...dataFromCommunicationToEdit,
      operator: nameOperator,
    };

    return defer({
      data: combinedData,
    });
  };

// Dependencies
import React from 'react';

// Styled Component
import { Container, Title, ImageIcon, Text } from './styles';

// Types
import { tagProps, variant } from './types';

export const SpecialTag = ({
  sizeTag = 'default',
  cotic,
  variant,
  title,
  ...props
}: tagProps): JSX.Element => {
  const tag: { [key in variant]: string | undefined } = {
    campaign:
      sizeTag === 'default'
        ? `Campanya${title ? ':' : ''}`
        : `C${title ? ':' : ''}`,
    open: 'Oberta',
    surveillanceRoute:
      sizeTag === 'default' ? 'Recorregut de vigilància' : 'RV',
    partial: 'Parcial',
    end: 'Finalitzada',
    validated: 'Validada',
    duplicate: 'Duplicant',
    annulled: 'anul·lat',
    cotic: cotic,
    default: title,
    transfer: 'Transferir',
    transferred: 'Transferit',
  };

  return (
    <Container
      sizeTag={sizeTag}
      variant={variant}
      {...props}
      as={variant === 'cotic' ? 'button' : 'div'}
    >
      {sizeTag !== 'circle' && (
        <>
          <Text
            variant={sizeTag === 'default' ? 'regular' : 'medium'}
            size={sizeTag === 'default' ? 'XS' : 'XXXS'}
            colorText={
              variant === 'surveillanceRoute' || variant === 'campaign'
                ? 'purple'
                : 'white'
            }
          >
            {tag[variant]}
            {variant === ('campaign' || 'default') && title && (
              <Title> {title}</Title>
            )}
          </Text>
          {(variant === 'cotic' || variant === 'duplicate') && <ImageIcon />}
        </>
      )}
    </Container>
  );
};

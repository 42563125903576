import React from 'react';

// Styled Components
import {
  Container,
  ContainerFileIcon,
  ContainerInput,
  Text,
  SmallContainer,
} from './styles';

// Types
import { InputProps } from './types';

// Icons
import LoadImageIcon from '../../../assets/icons/load-image-icon.svg';

export const ResumeLoadImages = ({
  imageData,
  ...props
}: InputProps): JSX.Element => {
  return (
    <Container {...props}>
      {imageData.map((element, index) => {
        const isLastElement = index === imageData.length - 1;
        return (
          <ContainerInput lastElement={isLastElement} key={index}>
            <SmallContainer>
              <ContainerFileIcon src={LoadImageIcon} />
              <Text size="S" variant="medium">
                {element.name}
              </Text>
            </SmallContainer>
          </ContainerInput>
        );
      })}
    </Container>
  );
};

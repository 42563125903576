import React, { SyntheticEvent, useCallback, useLayoutEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  useNavigate,
  useActionData,
  useSubmit,
  useAsyncValue,
} from 'react-router-dom';
import { format } from 'date-fns';

//Components
import ContentCardResume from '../../../../../components/display/ContentCardResume';

// Types
import { type Campaign } from '../../../../../types/campaign';
import { createFormDataFromValues } from '../../../../../utils/forms';
import { variant } from '../../../../../components/display/SpecialTag/types';

// Redux
import { coticOTSelected } from '../../../../../state/actions/ui/coticOTSelectedGeneralView';

export default function CampaignSummary(): JSX.Element {
  const actionData = useActionData() as Campaign;
  const loaderData = useAsyncValue() as Campaign;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const submit = useSubmit();

  const campaignData = actionData ?? loaderData;
  useLayoutEffect(() => {
    if (!campaignData) navigate('..');
    if (campaignData.id) {
      dispatch(
        coticOTSelected(
          'edit-campaign',
          (campaignData.cotic as unknown as string[]).join('.'),
          campaignData.id
        )
      );
    }
  }, []);

  const handleEditCampaign = useCallback(() => {
    navigate(`../${campaignData?.id ? campaignData?.id : ''}`);
  }, [campaignData?.id]);

  const handleSaveCampaign = async (e: SyntheticEvent) => {
    e.preventDefault();
    let formDataRaw: {
      intent: string;
      id?: string;
    } = {
      intent: 'save-data',
    };
    if (campaignData?.id) {
      formDataRaw = {
        ...formDataRaw,
        ...(campaignData?.changesCampaign ?? {}),
      };
    }

    const formData = createFormDataFromValues(formDataRaw);
    submit(formData, {
      method: 'POST',
      action: '.',
    });
  };

  return (
    <>
      {campaignData && (
        <ContentCardResume
          title={
            campaignData.campaign
              ? 'Resum de la Campanya'
              : 'Resum de la ordre de treball'
          }
          cotic={campaignData.cotic as unknown as string[]}
          tag={campaignData.campaign ? variant.Campaign : undefined}
          tagContent={campaignData.campaign ? campaignData.campaign : undefined}
          operator={campaignData.operator}
          data={format(
            new Date(campaignData.registerInitDate),
            'dd / MM / yyyy'
          )}
          hour={format(new Date(campaignData.registerInitDate), 'HH:mm')}
          capitol={campaignData.capitol}
          subCapitol={campaignData.subCapitol}
          classification={campaignData.classification}
          team={campaignData.team}
          concession={campaignData.concession}
          road={campaignData.road}
          pkInit={`${campaignData.pkInit} km`}
          pkEnd={`${campaignData.pkEnd} km`}
          track={campaignData.track}
          roadDirections={campaignData.roadDirections}
          direction={campaignData?.direction}
          city={campaignData.city}
          lane={campaignData.lane}
          margin={campaignData?.margin}
          leftButton={'Editar O.T'}
          rightButton={'Desar'}
          description={campaignData.description}
          onEditCommunication={handleEditCampaign}
          onSaveCommunication={handleSaveCampaign}
        />
      )}
    </>
  );
}

import { LoaderFunction, defer } from 'react-router-dom';
import { AuthContextType } from '../../../../hooks/useAuth';
import { store } from '../../../../state/configureStore';

// Types
import {
  tableIds,
  takeBase,
} from '../../../../components/display/tables/types';

// GQL
import { client } from '../../../../gql/client';
import { selectAllOrders } from '../../../../gql/selectors/orders';
import { GET_CAMPAIGNS } from '../../../../gql/queries/campaign';
import { GET_CONCESSIONS } from '../../../../gql/queries/orders/orders';

// Redux
import { getCursor } from '../../../../state/selectors/ui/tables';
import { loadingData } from '../../../../state/actions/ui/loadingData';
import { setCursor } from '../../../../state/actions/ui/cursorTables';

export const LoaderCampaigns: (
  userData: AuthContextType | null
) => LoaderFunction = (userData) => async () => {
  if (userData?.user) {
    const cursorCampaign = getCursor(tableIds.CAMPAIGNS)(store.getState());
    const dispatch = store.dispatch;
    const readCampaigns = client.readQuery({
      query: GET_CAMPAIGNS,
      variables: {
        input: {
          take: cursorCampaign.take,
          cursor: cursorCampaign.cursor,
          orderBy: cursorCampaign.orderBy,
          name: cursorCampaign.name,
          fromDate: cursorCampaign.fromDate,
          toDate: cursorCampaign.toDate,
          concessionId: cursorCampaign.concessionId,
          state: cursorCampaign.state,
        },
      },
    });

    const pageInfo = readCampaigns?.campaigns?.pageInfo;
    const totalCount = readCampaigns?.campaigns?.totalCount;
    const isCacheEmpty = JSON.stringify(readCampaigns) === '{}';
    const campaigns =
      readCampaigns &&
      !isCacheEmpty &&
      (await selectAllOrders(readCampaigns?.campaigns?.campaigns));

    const { data: filters } = await client.query({
      query: GET_CONCESSIONS,
    });

    const combinedData = {
      totalCount,
      campaigns,
      filters,
      pageInfo,
    };

    if (!readCampaigns || isCacheEmpty) {
      dispatch(loadingData(tableIds.CAMPAIGNS, true));
      let data;
      if (isCacheEmpty) {
        data = await client.query({
          query: GET_CAMPAIGNS,
          variables: {
            input: {
              take: takeBase,
              cursor: '',
              orderBy: 'desc',
              name: cursorCampaign.name,
              fromDate: cursorCampaign.fromDate,
              toDate: cursorCampaign.toDate,
              concessionId: cursorCampaign.concessionId,
              state: cursorCampaign.state,
            },
          },
        });

        const setCursorData = {
          take: takeBase,
          cursor: '',
          orderBy: 'desc',
          name: cursorCampaign.name,
          fromDate: cursorCampaign.fromDate,
          toDate: cursorCampaign.toDate,
          concessionId: cursorCampaign.concessionId,
          state: cursorCampaign.state,
        };

        dispatch(setCursor(tableIds.CAMPAIGNS, setCursorData));
      } else {
        data = await client.query({
          query: GET_CAMPAIGNS,
          variables: {
            input: {
              take: cursorCampaign.take,
              cursor: cursorCampaign.cursor,
              orderBy: cursorCampaign.orderBy,
              name: cursorCampaign.name,
              fromDate: cursorCampaign.fromDate,
              toDate: cursorCampaign.toDate,
              concessionId: cursorCampaign.concessionId,
              state: cursorCampaign.state,
            },
          },
        });
      }

      const pageInfo = data?.data.campaigns.pageInfo;
      const totalCount = data?.data.campaigns.totalCount;
      const campaigns = await selectAllOrders(data?.data.campaigns.campaigns);

      const { data: filters } = await client.query({
        query: GET_CONCESSIONS,
      });

      const combinedData = {
        totalCount,
        campaigns,
        filters,
        pageInfo,
      };
      dispatch(loadingData(tableIds.CAMPAIGNS, false));
      return defer({ data: combinedData });
    }

    return defer({ data: combinedData });
  }
  return {};
};

import { gql } from '@apollo/client';

export const FETCH_EXTERNAL_COMPANIES = gql`
  query FetchCompanies {
    externalCompanies {
      id
      companyName
    }
  }
`;

export const GET_EXTERNAL_COMPANY_BY_ID = gql`
  query GetExternalCompanyById($id: ID!) {
    externalCompany(id: $id) {
      id
      companyName
      externalWorkIDs
    }
  }
`;

export const GET_EXTERNAL_COMPANIES = gql`
  query GetExternalCompanies {
    readExternalCompanies @client {
      id
      companyName
    }
  }
`;

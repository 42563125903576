import styled from 'styled-components';

// Spaces
import {
  breakPoints,
  percentBaseResponsive,
} from '../../../../../../styles/spaces';

export const ContainerIcon = styled.div`
  position: absolute;
  cursor: pointer;
  top: 3rem;
  right: 3rem;
  @media (max-width: ${breakPoints.landscapeTablet *
    percentBaseResponsive}rem) {
    top: 2.5rem;
    right: 2rem;
  }
  @media (max-width: ${breakPoints.portraitTablet * percentBaseResponsive}rem) {
    top: 3rem;
    right: 3rem;
  }
`;

export const groupTracks = [
  { id: '0', name: 'Troncal', value: 'trunk' },
  { id: '1', name: 'Rotonda', value: 'roundabout' },
  { id: '2', name: 'Ramal entrada', value: 'input branch' },
  { id: '3', name: 'Ramal sortida', value: 'exit branch' },
  { id: '4', name: 'Via de servei', value: 'service road' },
  { id: '6', name: 'Sense especificar', value: 'sense especificar' },
];

export const groupLanes = [
  { id: '0', name: 'Lent', value: 'slow' },
  { id: '1', name: 'Rápid', value: 'fast' },
  { id: '2', name: 'Tots', value: 'all' },
  { id: '3', name: 'Centrals', value: 'central' },
  { id: '4', name: 'Sense especificar', value: 'sense especificar' },
];

export const specialTagType = [
  { id: '0', name: 'Recorregut de vigilància', value: 'surveillanceRoute' },
  { id: '1', name: 'Campanya', value: 'campaign' },
];

export const hoursType = [
  { id: '0', name: 'Normals', value: 'normal' },
  { id: '1', name: 'Extres normals', value: 'normalExtras' },
  { id: '2', name: 'Extres festius', value: 'holidayExtras' },
  { id: '3', name: 'Extres festius RV', value: 'holidayExtrasRV' },
  { id: '4', name: 'Extres normals VH', value: 'normalExtrasRV' },
  { id: '5', name: 'Extres festius VH', value: 'holidayExtrasVH' },
  { id: '6', name: 'Sense especificar', value: 'sense especificar' },
];

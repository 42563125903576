import { ActionFunction } from 'react-router-dom';
// Sentry
import * as Sentry from '@sentry/react';

// Steps
import { passData } from './ActionUtils/passData';
import { saveData } from './ActionUtils/saveData';

export const ActionNewWorkOrderDuplicateSummary: ActionFunction = async ({
  request,
}) => {
  const formData = await request.formData();
  const intent = formData.get('intent');
  if (intent === 'pass-data') {
    try {
      return passData(formData);
    } catch (err) {
      Sentry.captureException(err);
      console.log('Error:', err);
    }
  }
  if (intent === 'save-data') {
    try {
      const id = formData.get('id');
      return saveData(id);
    } catch (err) {
      Sentry.captureException(err);
      console.log('> WorkOrderSummary - error creating:', err);
    }
  }
  return null;
};

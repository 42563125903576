import styled from 'styled-components';

// Styles generals
import {
  spaces,
  percentBaseResponsive,
  breakPoints,
} from '../../../../styles/spaces';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: calc(100% - 40rem);
  @media (max-width: ${breakPoints.smallDesktop * percentBaseResponsive}rem) {
    width: 100%;
  }
`;

export const ContainerButton = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 3.98rem;
  gap: 1.6rem;
  @media (max-width: ${breakPoints.landscapeTablet *
    percentBaseResponsive}rem) {
    margin-top: ${spaces.s}rem;
  }
  @media (max-width: ${breakPoints.portraitTablet * percentBaseResponsive}rem) {
    margin-top: ${spaces.l}rem;
    justify-content: center;
  }
`;

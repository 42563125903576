// Styles
import styled from 'styled-components';

// Styles utils
import { colors } from '../../../../../styles/colors';
import {
  breakPoints,
  percentBaseResponsive,
} from '../../../../../styles/spaces';
import { fontSize } from '../../../../../styles/fonts';

// Components
import { Typography } from '../../../../display/Typography';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Label = styled(Typography)`
  margin-bottom: 1.5rem;
  @media (max-width: ${breakPoints.landscapeTablet *
    percentBaseResponsive}rem) {
    font-size: ${fontSize.XS};
  }
`;

export const SwitchDouble = styled.label`
  display: flex;
  position: relative;
  align-items: center;
  cursor: pointer;
  background-color: ${colors.greyLight};
  border-radius: 3.4rem;
  transition: 0.4s;
  align-items: center;
  width: 5.4rem;
  height: 2.2rem;

  &:before {
    content: '';
    height: 2.4rem;
    width: 2.4rem;
    background-color: ${colors.greyDark};
    border-radius: 50%;
    transition: 0.3s;
    margin-left: ${(props) => (props.defaultChecked ? `3rem` : 0)};
  }
`;

export const InputDouble = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
`;

export const LeftLabel = styled.span`
  z-index: 1;
  position: absolute;
  top: 0.1rem;
  left: 1rem;
  color: ${colors.greyDark};
  font-size: 1.5rem;
`;

export const RightLabel = styled.span`
  position: absolute;
  top: 0.1rem;
  left: 2.7rem;
  color: ${colors.greyDark};
  font-size: 1.4rem;
`;

import { object, string } from 'yup';

// Types
import { UserManagement } from './types';

export const UserManagementSchema = object<UserManagement>({
  name: string().required(),
  surName: string().required(),
  email: string()
    .email('El correu electrònic ha de ser un correu electrònic vàlid')
    .required('El correu electrònic és un camp obligatori'),
  rolActive: string().required(),
  concession: string().required(),
  state: string(),
});

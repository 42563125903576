import { defer, LoaderFunction } from 'react-router-dom';

// Utils
import { decompressSessionStorage } from '../../../../../utils/sessionStorage';

// Constants
import { formActionNaming } from '../../../../../components/forms/ActionForms/constants';

// Selects
import { selectAssetsAndActivitys } from '../../../../../gql/selectors/actions';

export const BuildingLoader: LoaderFunction | undefined = async () => {
  // Stringify data
  const stringifyForm = sessionStorage.getItem(
    formActionNaming.NEW_EDITION_FORM
  );

  const workOrderStringifyForm = sessionStorage.getItem(
    formActionNaming.NEW_ACTION_DATA
  );

  // Variables to assign
  let sessionStorageData;
  let workOrderData;

  // Variables assignment
  if (stringifyForm) {
    sessionStorageData = JSON.parse(decompressSessionStorage(stringifyForm));
  }

  if (workOrderStringifyForm) {
    workOrderData = JSON.parse(
      decompressSessionStorage(workOrderStringifyForm)
    );
  }

  const dataAssetsAndActivitys = await selectAssetsAndActivitys();

  const combinedData = {
    ...workOrderData,
    ...dataAssetsAndActivitys,
    ...sessionStorageData,
    breadCrumbsData: {
      orderParentId: workOrderData.orderParentId,
      orderParentCotic: workOrderData.orderParentCotic,
    },
  };

  return defer({
    data: combinedData,
  });
};

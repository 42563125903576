// Dependencies
import React, { useEffect, useMemo, useState } from 'react';

import {
  useReactTable,
  getCoreRowModel,
  createColumnHelper,
  flexRender,
} from '@tanstack/react-table';

// Types
import {
  VehiclesInvolvedType,
  TableProps,
  columnNames,
  ChildVehicleType,
} from './types';

// Mocks
import { mockData } from './mocks';

// Styles
import {
  Container,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '../SummaryTablesStyles';

// Styled Components
import { TableContainerStyled } from './styles';

// Hooks
import { useIsPortraitTablet } from '../../../../../hooks/useMediaBreakPoints';

export const VehiclesInvolvedSummary = ({
  data,
  ...props
}: TableProps): JSX.Element => {
  const isPortraitTablet = useIsPortraitTablet();
  const dataAll = data ? data : mockData;

  const [newDataAll, setNewDataAll] = useState<
    (VehiclesInvolvedType | ChildVehicleType)[] | []
  >([]);

  useEffect(() => {
    setNewDataAll(dataAllIterator);
  }, []);
  const dataAllIterator = () => {
    const newDataAll: (VehiclesInvolvedType | ChildVehicleType)[] = [];
    let counter = 0;
    for (let index = 0; index < dataAll.length; index++) {
      const item = dataAll[index];
      if (item !== null) {
        if (
          item.childVehicle.length === 0 &&
          item.parentVehicleIDs.length === 0
        ) {
          const newItem = {
            ...item,
            vehicle: String.fromCharCode(65 + counter),
          };

          if (!newItem.model) {
            newItem.model = '-';
          }

          newDataAll.push(newItem);
          counter++;
        }
        if (item.childVehicle.length > 0) {
          const newItem = {
            ...item,
            vehicle: String.fromCharCode(65 + counter),
          };
          if (!newItem.model) {
            newItem.model = '-';
          }
          newDataAll.push(newItem);
          const childVehicle: ChildVehicleType = {
            ...item?.childVehicle?.[0],
            vehicle: `${newItem.vehicle}1`,
          };

          newDataAll.push(childVehicle);
          counter++;
        }
      }
    }
    return newDataAll;
  };

  const columnHelper = createColumnHelper<
    VehiclesInvolvedType | ChildVehicleType
  >();

  const columns = useMemo(
    () => [
      columnHelper.accessor((row) => row.vehicle, {
        id: columnNames.vehicle.id,
        header: () => (isPortraitTablet ? 'V' : columnNames.vehicle.label),
      }),
      columnHelper.accessor((row) => row.plate, {
        id: columnNames.licensePlate.id,
        header: () => columnNames.licensePlate.label,
      }),
      columnHelper.accessor((row) => row.model, {
        id: columnNames.model.id,
        header: () => columnNames.model.label,
        cell: (info) => (!info.getValue() ? 'Trailer' : info.getValue()),
      }),
      columnHelper.accessor((row) => row.insurance, {
        id: columnNames.cia.id,
        header: () => columnNames.cia.label,
      }),
      columnHelper.accessor((row) => row.policyNumber, {
        id: columnNames.policy.id,
        header: () => columnNames.policy.label,
      }),
      columnHelper.accessor((row) => row.numberOccupants, {
        id: columnNames.numberOccupants.id,
        header: () =>
          isPortraitTablet ? 'P' : columnNames.numberOccupants.label,
        cell: (info) => (!info.getValue() ? '-' : info.getValue()),
      }),
    ],
    []
  );

  const table = useReactTable({
    data: newDataAll,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <Container>
      <TableContainerStyled>
        <Table {...props}>
          <Thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <Th key={header.id}>
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {table.getRowModel().rows.map((row) => (
              <Tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <Td key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Td>
                ))}
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainerStyled>
    </Container>
  );
};

import styled, { css } from 'styled-components';

// Types
import { variant } from './types';

// Components
import { Typography } from '../../../display/Typography';

// Styles utils
import { breakPoints, percentBaseResponsive } from '../../../../styles/spaces';
import { colors } from '../../../../styles/colors';
import { fontWeight } from '../../../../styles/fonts';

interface ContainerProps {
  variant: variant;
}
export const Text = styled(Typography)`
  white-space: nowrap;
`;

export const Container = styled.button<ContainerProps>`
  border: 0;
  border-radius: 0.5rem;
  height: 4rem;
  padding: 1rem 2rem;
  transition-duration: 0ms;
  display: flex;
  align-items: center;
  ${({ variant }) =>
    ({
      primary: css`
        background-color: ${colors.garnet};
      `,
      secondary: css`
        background-color: transparent;
        outline: 0.1rem solid ${colors.garnet};
      `,
    }[variant])}
  &:hover:not(:disabled):not(:focus-visible) {
    ${({ variant }) =>
      ({
        primary: css`
          background-color: ${colors.darkBurgundyRed};
        `,
        secondary: css`
          ${Text} {
            color: ${colors.veryDarkBurgundyRed};
            font-weight: ${fontWeight.semiBold};
          }
          outline: 0.1rem solid ${colors.veryDarkBurgundyRed};
          max-width: 19.641rem;
          padding: 1rem 1.87rem;
          @media (max-width: ${breakPoints.landscapeTablet *
            percentBaseResponsive}rem) {
            padding: 1rem 1.05rem;
          }
        `,
      }[variant])}
  }
  &:active:not(:disabled):not(:focus-visible) {
    ${({ variant }) =>
      ({
        primary: css``,
        secondary: css`
          ${Text} {
            color: ${colors.white};
          }
          outline: none;
        `,
      }[variant])}
    background-color: ${colors.black};
    border: none;
  }
  &:focus-visible {
    ${({ variant }) =>
      ({
        primary: css`
          outline: 0.3rem solid ${colors.deepRedWine};
        `,
        secondary: css`
          outline: 0.3rem solid ${colors.darkGrey};
        `,
      }[variant])}
  }
  &:disabled {
    cursor: auto;
    ${({ variant }) =>
      ({
        primary: css`
          background-color: ${colors.lightDustyRose};
        `,
        secondary: css`
          outline-color: ${colors.lightDustyRose};
        `,
      }[variant])};
  }
  @media (max-width: ${breakPoints.landscapeTablet *
    percentBaseResponsive}rem) {
    padding-left: 1.2rem;
    padding-right: 1.2rem;
  }
`;

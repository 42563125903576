// Dependencies
import styled from 'styled-components';

// Styles
import {
  breakPoints,
  percentBaseResponsive,
  spaces,
} from '../../../styles/spaces';

// Components
import { UserMenu } from '../UserMenu';

export const Container = styled.header`
  display: flex;
  padding: ${spaces.s}rem ${spaces.xl + 0.8}rem;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 0.2rem 0.4rem rgba(0, 0, 0, 0.24);
  gap: ${spaces.xxxl + 1.2}rem;
  background-color: #f6f6f6;
  max-height: 7.7rem;
  position: relative;
  @media (max-width: ${breakPoints.landscapeTablet *
    percentBaseResponsive}rem) {
    padding: ${spaces.s}rem ${spaces.l}rem;
  }
  @media (max-width: ${breakPoints.portraitTablet * percentBaseResponsive}rem) {
    gap: ${spaces.m + 0.3}rem;
  }
`;

export const ContainerMenuLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${spaces.s + 0.3}rem;
`;

export const Logo = styled.img`
  width: 9rem;
  height: 2.6rem;
  vertical-align: middle;
`;

export const MenuUser = styled(UserMenu)`
  width: max-content;
`;

export const IconBurguerMenu = styled.img`
  width: 2rem;
  height: 2rem;
`;

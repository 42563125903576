import styled from 'styled-components';
import { ContainerForm } from '../components/MainStyled';
import {
  breakPoints,
  percentBaseResponsive,
  spaces,
} from '../../../styles/spaces';
import { colors } from '../../../styles/colors';
import { InputTextArea } from '../../inputs/InputTextArea';

export const Container = styled(ContainerForm)`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${spaces.xxl}rem;
`;

export const DamagesContainer = styled.div``;

export const DamagesHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 0 ${spaces.m}rem;
`;

export const StyledInputTextArea = styled(InputTextArea)`
  max-width: 133rem;
`;

export const VehiclesContainer = styled.div``;

export const ContainerContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${spaces.xl}rem;
`;

export const ContainerTitlePage = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 0 ${spaces.m}rem;
`;

export const SwitchPaginationContainer = styled.div`
  display: flex;
  gap: ${spaces.m}rem;
`;

export const ContainerComponent = styled.div`
  display: flex;
  align-items: start;
  gap: ${spaces.xl}rem;
  @media (max-width: ${breakPoints.smallDesktop * percentBaseResponsive}rem) {
    flex-direction: column;
  }
`;

export const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spaces.xl}rem;
`;

export const AdviceContainer = styled.div`
  text-align: center;
  padding: ${spaces.s}rem ${spaces.l}rem;
  border: 0.1rem solid ${colors.greyDark};
  width: max-content;
  margin: 0 auto;
`;

export const ContainerTables = styled.div`
  width: 100%;
  max-width: 133rem;
  display: flex;
  flex-direction: column;
  gap: ${spaces.l}rem;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  gap: ${spaces.s}rem;
  justify-content: flex-end;
`;

import { gql } from '@apollo/client';

export const CREATE_NEW_ANIMAL = gql`
  mutation CreateAnimal($input: NewAnimal!) {
    createAnimal(input: $input) {
      id
      animalId
      name
    }
  }
`;

import styled, { css } from 'styled-components';

// Colors
import { colors } from '../../../../../../styles/colors';

// Spaces
import {
  breakPoints,
  percentBaseResponsive,
  spaces,
} from '../../../../../../styles/spaces';

// Styled Components
import { Container, Tr } from '../../../summaryTables/SummaryTablesStyles';

// Types

export const ContainerTable = styled(Container)`
  display: flex;
  gap: 9.8rem;
  @media (max-width: ${breakPoints.smallDesktop * percentBaseResponsive}rem) {
    flex-wrap: wrap;
  }
  @media (max-width: ${breakPoints.landscapeTablet *
    percentBaseResponsive}rem) {
    flex-direction: column;
    gap: 3.2rem;
  }
`;

export const AnimalsTableContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 2.8rem;
  max-width: 92rem;
  width: 100%;
`;

export const FiltersContainer = styled.div`
  display: flex;
  align-items: end;
  gap: ${spaces.m}rem;
  div:last-child {
    flex: 1;
  }
`;

export const TrStyled = styled(Tr)`
  background-color: ${({ checked }) => checked && colors.paleGrey};
  ${({ indeterminate, checked }) =>
    css`
      Td {
        ${!indeterminate && {
          opacity: 0.2,
        }}
        ${checked && {
          opacity: 1,
        }}
      }
    `}
`;

import styled from 'styled-components';

import { Typography } from '../Typography';

import { colors } from '../../../styles/colors';
import { spaces } from '../../../styles/spaces';

import { ButtonIconBackground } from '../../inputs/buttons/ButtonIconBackground';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spaces.xxxs}rem;
`;

export const Label = styled(Typography)``;

export const DataContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spaces.m}rem;
  width: 100%;
  background-color: ${colors.lightRose};
  border-radius: 0.5rem;
  padding: 1.5rem 3rem 3rem 3rem;
`;

export const RowContainer = styled.div``;

export const Title = styled(Typography)`
  border-bottom: solid 0.1rem ${colors.garnet};
  padding-bottom: ${spaces.xxs}rem;
`;

export const Explore = styled(Typography)`
  border-bottom: solid 0.1rem ${colors.greyLight};
  padding-bottom: ${spaces.xxs}rem;
  display: flex;
  cursor: pointer;
`;

export const DataText = styled(Typography)`
  padding-top: ${spaces.xxs}rem;
`;
export const ContainerButton = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: -5rem;
  z-index: 1;
`;
export const RemoveButton = styled(ButtonIconBackground)`
  width: 3.5rem;
  height: 3.5rem;
`;

import {
  COTIC_OT_SELECTED_GENERAL_VIEW,
  COTIC_OT_SELECTED_GENERAL_VIEW_RESET,
} from '../../actions/ui/coticOTSelectedGeneralView';

export const initialState = {
  type: null,
  cotic: null,
  id: null,
};

type actionType = {
  type: string;
  payload: { type: string; cotic: string; id: string };
};

export default function coticOTSelected(
  state = initialState,
  { type, payload }: actionType
) {
  switch (type) {
    case COTIC_OT_SELECTED_GENERAL_VIEW:
      return {
        ...state,
        type: payload.type,
        cotic: payload.cotic,
        id: payload.id,
      };
    case COTIC_OT_SELECTED_GENERAL_VIEW_RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}

import React, { useLayoutEffect } from 'react';
import { useActionData, useAsyncValue, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { SubmitHandler } from 'react-hook-form';

// Components Styled
import { Container } from '../NewAction/styles';

// Components
import NewWorkOrderForm from '../../../../components/forms/NewWorkOrderForm';

// types
import { formAccidentNaming } from '../../../../components/forms/AddBasicInfoAccidentForm/constants';
import { WorkOrder } from '../../../../types/workOrder';
import { coticOTSelected } from '../../../../state/actions/ui/coticOTSelectedGeneralView';
import { OrderType } from '../../../../types/orders';
import { AccidentDataNewAccident } from '../../../../types/accident';

export default function NewAccidentPage(): JSX.Element {
  const accidentData = useActionData() as AccidentDataNewAccident;
  const loaderData = useAsyncValue() as WorkOrder;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useLayoutEffect(() => {
    if (!loaderData || !accidentData) {
      navigate('../');
    }
    if (accidentData) {
      dispatch(
        coticOTSelected(
          OrderType.Accident,
          accidentData.orderParentCotic,
          accidentData.orderParentId
        )
      );
    }
  }, []);
  const onFormSubmit: SubmitHandler<WorkOrder> = async () => {
    navigate('basic-info');
  };
  return (
    <Container>
      <NewWorkOrderForm
        onFormSubmit={onFormSubmit}
        initialData={{
          ...loaderData,
        }}
        namingPersistForm={formAccidentNaming.NEW_ACCIDENT_FORM}
        titleBasicInfo={'Informació bàsica parte accident'}
        titleDate={'Data Accident:'}
        textDateHour={'Hora Accident:'}
        isAccident={true}
      />
    </Container>
  );
}

// Types
import { dataTable } from './types';

export const mockData: dataTable[] = [
  {
    id: '1',
    materialId: 'M00001',
    name: 'Aglomerat en fred en pols de 25 kg',
    codification: 'VIAL',
    subCodification: 'Senyalització',
    typeOfMeasurement: 'Kg',
  },
  {
    id: '2',
    materialId: 'M00002',
    name: 'Filferro',
    codification: 'VIAL',
    subCodification: 'Senyalització',
    typeOfMeasurement: 'Kg',
  },
  {
    id: '3',
    materialId: 'M00003',
    name: 'Tap de claveguera',
    codification: 'VIAL',
    subCodification: 'Senyalització',
    typeOfMeasurement: 'Kg',
  },
  {
    id: '4',
    materialId: 'M00004',
    name: 'Tolva de càrrega',
    codification: 'VIAL',
    subCodification: 'Senyalització',
    typeOfMeasurement: 'Kg',
  },
  {
    id: '5',
    materialId: 'M00005',
    name: 'Aglomerat en fred en pols de 25 kg',
    codification: 'VIAL',
    subCodification: 'Senyalització',
    typeOfMeasurement: 'Kg',
  },
  {
    id: '6',
    materialId: 'M00006',
    name: 'Filferro',
    codification: 'VIAL',
    subCodification: 'Senyalització',
    typeOfMeasurement: 'Kg',
  },
  {
    id: '7',
    materialId: 'M00007',
    name: 'Tap de claveguera',
    codification: 'VIAL',
    subCodification: 'Senyalització',
    typeOfMeasurement: 'Kg',
  },
  {
    id: '8',
    materialId: 'M00008',
    name: 'Tolva de càrrega',
    codification: 'VIAL',
    subCodification: 'Senyalització',
    typeOfMeasurement: 'Kg',
  },
];

export const COTIC_OT_SELECTED_GENERAL_VIEW = 'COTIC_OT_SELECTED_GENERAL_VIEW';
export const COTIC_OT_SELECTED_GENERAL_VIEW_RESET =
  'COTIC_OT_SELECTED_GENERAL_VIEW_RESET';

export function coticOTSelected(type: string, cotic: string, id: string) {
  return {
    type: COTIC_OT_SELECTED_GENERAL_VIEW,
    payload: {
      type,
      cotic,
      id,
    },
  };
}

export function coticOTSelectedReset() {
  return {
    type: COTIC_OT_SELECTED_GENERAL_VIEW_RESET,
  };
}

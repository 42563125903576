import React, { useCallback, useState, useRef, memo, useEffect } from 'react';
import { useForm, Controller, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import isEqual from 'lodash.isequal';
import debounce from 'lodash.debounce';
import * as Sentry from '@sentry/react';

// Components
import { MainButton } from '../../../inputs/buttons/MainButton';
import DropDownInput from '../../../inputs/DropDown/DropDownInput';
import InputText from '../../../inputs/InputText';
import { ArrowRadioGroup } from '../../../../pages/main/comunications/NewComunication/styles';
import { MapComponent } from '../../../display/Map';

// Styles
import {
  BlackButton,
  GreenButton,
  MainContainer,
  MapContainer,
  RoadInputsGroup,
  RowStyled,
} from './styles';
import {
  ContainerError,
  GeneralContainer,
  ButtonContainer,
  RowsContainer,
  Title,
} from '../styles';

// Types
import {
  type LocationTypes,
  ActionDirection,
  ActionMargin,
  type LocationFormValues,
  Coordinates,
  Concessions,
  GroupTypes,
} from './types';

import { type PartialDirection } from '../../../inputs/buttons/ArrowDirectionButton/types';
import { type WorkOrder } from '../../../../types/workOrder';
import { type OptionSelected } from '../../../inputs/DropDown/DropDownList/types';
import { ImperativeRefInput } from '../../../inputs/DropDown/DropDownInput/types';

// Utils
import { useCacheFormInSessionStorage } from '../../../../hooks/useCacheForm';
import { getLatLon } from '../../../../utils/location';
import { pkFormatter } from '../../../../utils/transform';

//  GQL
import { client } from '../../../../gql/client';
import {
  GET_CONCESSION_BY_ID,
  GET_ROAD_BY_ID,
  GET_ROADS_BY_IDS,
} from '../../../../gql/queries/geoPositions/roads';
import { GET_POSITION_ROADS } from '../../../../gql/queries/geoPositions/geoPositionRoads';

// Schema Yups
import { LocationSchema } from './schema';
import { Typography } from '../../../display/Typography';
import { UNSPECIFIC } from '../../../../types/utils';
import { pkValidation } from '../../../../utils/pkValidation';
import { fetchDefaultData } from '../../../../utils/fetchData';
import { OrderState } from '../../../../types/orders';

const MemorizedMap = memo(MapComponent, isEqual);

const LocationForm = ({
  onFormSubmit,
  namingPersistForm,
  initialData,
  textButton = 'Continuar',
}: LocationTypes) => {
  let pksAndData:
    | {
        pkInit: string;
        pkEnd: string;
        direction: string;
        road: string;
      }
    | undefined;
  const [mapError, setMapError] = useState<string | null>(null);
  const [centerCoordinates, setCenterCoordinates] =
    useState<Coordinates | null>(null);
  const [coordinates, setCoordinates] = useState<Coordinates | null>(null);
  const [path, setPath] = useState<Coordinates[] | []>([]);
  const [displayMap, setDisplayMap] = useState(false);

  const {
    control,
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<LocationFormValues & WorkOrder>({
    defaultValues: {
      actionCity:
        ((initialData?.concessions as Concessions)?.find(
          (concession) => concession?.id === initialData?.concession
        )?.location as string) ?? '',
      actionPkInitRoad: initialData?.pkInitRoad ?? '',
      actionPkEndRoad: initialData?.pkEndRoad ?? '',
      actionPkInit: initialData?.pkInit ?? '',
      actionPkEnd: initialData?.pkEnd ?? '',
      actionConcession: initialData?.concession ?? '',
      actionTrack: initialData?.actionTrack
        ? initialData.actionTrack
        : initialData?.track,
      actionLane: initialData?.actionLane
        ? initialData.actionLane
        : initialData?.lane,
      actionRoad: initialData?.actionRoad
        ? initialData?.actionRoad
        : initialData?.road,
      actionDirection: initialData?.actionDirection
        ? initialData?.actionDirection
        : initialData?.direction,
      actionMargin: initialData?.actionMargin
        ? initialData?.actionMargin
        : initialData?.margin,
      ...initialData,
    },
    resolver: yupResolver(LocationSchema),
  });

  const roadDropdownInputRef = useRef<ImperativeRefInput | null>(null);

  useCacheFormInSessionStorage(namingPersistForm, control);

  // Watchers
  const roads = useWatch({
    control,
    name: 'roads',
  });

  const actionPkInit = useWatch({
    control,
    name: 'actionPkInit',
  });

  const actionPkEnd = useWatch({
    control,
    name: 'actionPkEnd',
  });

  const actionPkInitRoad = useWatch({
    control,
    name: 'actionPkInitRoad',
  });

  const actionPkEndRoad = useWatch({
    control,
    name: 'actionPkEndRoad',
  });

  const actionDirection = useWatch({
    control,
    name: 'actionDirection',
  });

  const actionRoad = useWatch({
    control,
    name: 'actionRoad',
  });

  const actionCoordinates = useWatch({
    control,
    name: 'actionCoordinates',
  });

  const checkGeoPositionRoad = useCallback(
    debounce(
      ({
        pkInit,
        pkEnd,
        direction,
        road,
      }: {
        pkInit: string | undefined;
        pkEnd: string | undefined;
        direction: string | undefined;
        road: string | undefined;
      }) => {
        if (
          !!pkInit &&
          !!pkEnd &&
          !!direction &&
          direction !== UNSPECIFIC &&
          !!road
        ) {
          const tasks = async () => {
            const pkInitRaw = pkInit?.replace(/ /g, '').split('+');
            const pkEndRaw = pkEnd?.replace(/ /g, '').split('+');
            const {
              data: { geoPositionRoads },
            } = await client.query({
              query: GET_POSITION_ROADS,
              variables: {
                input: {
                  pkInitKm: Number(pkInitRaw[0]),
                  pkInitMeter: Number(pkInitRaw[1]),
                  pkEndKm: Number(pkEndRaw[0]),
                  pkEndMeter: Number(pkEndRaw[1]),
                  roadId: road,
                  direction: direction === 'both' ? 'top' : direction,
                },
              },
            });
            if (geoPositionRoads.length > 0) {
              const indexPositionCenter =
                Math.round(geoPositionRoads.length / 2) - 1;
              const geoPositionsRoadsMapped = geoPositionRoads.map(
                (
                  position: { latitude: number; longitude: number },
                  index: number
                ) => {
                  if (index === indexPositionCenter) {
                    setCenterCoordinates({
                      lat: position.latitude,
                      lng: position.longitude,
                    });
                  }
                  return {
                    lat: position.latitude,
                    lng: position.longitude,
                  };
                }
              );
              setPath(geoPositionsRoadsMapped);
            }
          };
          tasks().catch((err) => {
            Sentry.captureException(err);
            console.log(err);
          });
        }
      },
      1000
    ),
    []
  );

  useEffect(() => {
    const invalidRoads = roads.filter(
      (road) =>
        road.name === 'Fora Concessió' || road.name === 'Sala de Control'
    );

    const pkParents = {
      pkInitRoad: actionPkInitRoad,
      pkEndRoad: actionPkEndRoad,
    };

    if (
      !invalidRoads.map((road) => road.id).includes(actionRoad) &&
      actionRoad &&
      actionPkInit &&
      actionPkEnd &&
      actionDirection &&
      actionDirection !== UNSPECIFIC &&
      actionPkInit.match(/^\d+\s?\+\s?\d{3,4}$/) &&
      actionPkEnd.match(/^\d+\s?\+\s?\d{3,4}$/) &&
      pkValidation(actionPkInit, pkParents) &&
      pkValidation(actionPkEnd, pkParents)
    ) {
      pksAndData =
        !!actionPkInit &&
        !!actionPkEnd &&
        !!actionDirection &&
        actionDirection !== UNSPECIFIC &&
        !!actionRoad
          ? {
              pkInit: actionPkInit,
              pkEnd: actionPkEnd,
              direction: actionDirection,
              road: actionRoad,
            }
          : !!initialData?.pkInit &&
            !!initialData?.pkEnd &&
            !!initialData?.direction &&
            initialData?.direction !== UNSPECIFIC &&
            !!initialData?.road
          ? {
              pkInit: initialData?.pkInit,
              pkEnd: initialData?.pkEnd,
              direction: initialData?.direction,
              road: initialData?.road,
            }
          : {
              pkInit: '61+100',
              pkEnd: '69+100',
              direction: 'down',
              road: '6463453c0f1a3c516162ef3f',
            };
      checkGeoPositionRoad(pksAndData);
      setDisplayMap(true);
      setMapError('');
    } else {
      setDisplayMap(false);
      setMapError(
        'El mapa només es pintarà si el pk inicial, el pk final, la carretera i el sentit són correctes o si geolocalitzes la teva posició'
      );
    }
  }, [actionRoad, actionDirection, actionPkInit, actionPkEnd]);

  const onSelectConcession = useCallback(
    (
      option: OptionSelected | undefined,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onChange: (...event: any[]) => void
    ) => {
      const tasks = async () => {
        const {
          data: { concession },
        } = await client.query({
          query: GET_CONCESSION_BY_ID,
          variables: { id: option?.value },
        });

        setCenterCoordinates(
          getLatLon(concession.id, initialData?.concessions)
        );

        setValue('actionCity', concession.location);

        const { readRoads } = client.readQuery({
          query: GET_ROADS_BY_IDS,
          variables: { ids: concession.roadIDs ?? [] },
        });
        if (readRoads.length === 0) {
          await fetchDefaultData();
          const { readRoads } = client.readQuery({
            query: GET_ROADS_BY_IDS,
            variables: { ids: concession.roadIDs ?? [] },
          });
          setValue('roads', readRoads);
        } else {
          setValue('roads', readRoads);
        }

        roadDropdownInputRef.current?.clearSelected();
        setValue('actionRoad', '');

        onChange(option?.value ?? '');
      };

      tasks().catch((err) => {
        Sentry.captureException(err);
        console.log(err);
      });
    },
    []
  );

  const onSelectRoad = useCallback(
    (
      option: OptionSelected | undefined,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onChange: (...event: any[]) => void
    ) => {
      const tasks = async () => {
        const {
          data: { road },
        } = await client.query({
          query: GET_ROAD_BY_ID,
          variables: { id: option?.value },
        });

        setValue(
          'actionPkInitRoad',
          pkFormatter(road.pkInitKm, road.pkInitMeter, true)
        );
        setValue(
          'actionPkEndRoad',
          pkFormatter(road.pkEndKm, road.pkEndMeter, true)
        );

        onChange(option?.value ?? '');
      };
      tasks().catch((err) => {
        Sentry.captureException(err);
        console.log(err);
      });
    },
    []
  );

  const handleGetLocation = useCallback(() => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          const coordinates = { latitude, longitude };

          setCoordinates(() => {
            const coordinatesUpdated = {
              lat: coordinates.latitude,
              lng: coordinates.longitude,
            };
            setValue('actionCoordinates', JSON.stringify(coordinatesUpdated));

            return coordinatesUpdated;
          });
          setCenterCoordinates({
            lat: coordinates.latitude,
            lng: coordinates.longitude,
          });
        },
        (error) => {
          console.error('Error getting location:', error);
        },
        { enableHighAccuracy: true }
      );
    } else {
      console.error('Geolocation is not available in this browser.');
    }
  }, []);

  useEffect(() => {
    if (actionCoordinates) {
      navigator.geolocation.getCurrentPosition((position) => {
        const { latitude, longitude } = position.coords;
        const coordinates = { latitude, longitude };

        setCoordinates(() => {
          const coordinatesUpdated = {
            lat: coordinates.latitude,
            lng: coordinates.longitude,
          };
          setValue('actionCoordinates', JSON.stringify(coordinatesUpdated));

          return coordinatesUpdated;
        });
      });
    }
  }, []);

  return (
    <MainContainer>
      {centerCoordinates && displayMap && (
        <MapContainer>
          <MemorizedMap
            center={centerCoordinates}
            zoom={15}
            path={path}
            geoloc={coordinates}
            apiKey={import.meta.env.VITE_MAP_API_KEY}
          />
        </MapContainer>
      )}
      <GeneralContainer onSubmit={handleSubmit(onFormSubmit)}>
        <Title component="h3" variant="semiBold" size="L">
          Detalls de l’ubicació
        </Title>
        <RowsContainer>
          <RowStyled>
            <Controller
              control={control}
              name="actionConcession"
              render={({
                field: { onChange },
                formState: { defaultValues },
              }) => {
                const options =
                  defaultValues?.concessions?.reduce((acc, curr) => {
                    if (curr?.name !== 'CX') {
                      acc.push({
                        label: curr?.name || '',
                        value: curr?.id || '',
                      });
                    }

                    return acc;
                  }, [] as GroupTypes[]) || [];
                return (
                  <DropDownInput
                    labelText="Concessió"
                    placeholder={'Selec'}
                    inputSize={'XS'}
                    options={options}
                    onChangeSelected={(option) =>
                      onSelectConcession(option, onChange)
                    }
                    typeDropDown={'Default'}
                    defaultValue={defaultValues?.actionConcession}
                    borderError={!!errors.actionConcession}
                    isDisabled={
                      initialData?.parentExpeditionOrderStatus ===
                        OrderState.Annulled ||
                      initialData?.parentExpeditionOrderStatus ===
                        OrderState.End ||
                      initialData?.state === OrderState.Annulled
                    }
                  />
                );
              }}
            />
            <InputText
              {...register('actionCity')}
              typeInput={'normal'}
              disabled
              label="Ubicacio"
              borderError={!!errors.actionCity}
            />

            <RoadInputsGroup>
              <Controller
                name="actionRoad"
                control={control}
                render={({
                  field: { onChange },
                  formState: { defaultValues },
                }) => {
                  const options =
                    roads?.map((road) => ({
                      label: road?.name || '',
                      value: road?.id || '',
                    })) || [];
                  return (
                    <DropDownInput
                      labelText="Carretera"
                      placeholder={'Selec.'}
                      inputSize={'XS'}
                      options={options}
                      onChangeSelected={(option) =>
                        onSelectRoad(option, onChange)
                      }
                      typeDropDown={'Default'}
                      defaultValue={defaultValues?.actionRoad}
                      ref={roadDropdownInputRef}
                      borderError={!!errors.actionRoad}
                      isDisabled={
                        initialData?.parentExpeditionOrderStatus ===
                          OrderState.Annulled ||
                        initialData?.parentExpeditionOrderStatus ===
                          OrderState.End ||
                        initialData?.state === OrderState.Annulled
                      }
                    />
                  );
                }}
              />
              <InputText
                {...register('actionPkInitRoad')}
                typeInput={'normal'}
                disabled
                placeholder="000 + 000"
                label="Inici"
                inputSize="11"
                borderError={!!errors.actionPkInitRoad}
              />
              <InputText
                {...register('actionPkEndRoad')}
                typeInput={'normal'}
                disabled
                placeholder="000 + 000"
                label="Final"
                inputSize="11"
                borderError={!!errors.actionPkEndRoad}
              />
            </RoadInputsGroup>
          </RowStyled>
          <RowStyled>
            <InputText
              {...register('actionPkInit')}
              typeInput={'normal'}
              label="Pk Inicial"
              placeholder="Ex: 90+200"
              inputSize="16"
              borderError={!!errors.actionPkInit}
              disabled={
                initialData?.parentExpeditionOrderStatus ===
                  OrderState.Annulled ||
                initialData?.parentExpeditionOrderStatus === OrderState.End ||
                initialData?.state === OrderState.Annulled
              }
            />
            <InputText
              {...register('actionPkEnd')}
              typeInput={'normal'}
              label="Pk Final"
              placeholder="Ex: 90+200"
              inputSize="16"
              borderError={!!errors.actionPkEnd}
              disabled={
                initialData?.parentExpeditionOrderStatus ===
                  OrderState.Annulled ||
                initialData?.parentExpeditionOrderStatus === OrderState.End ||
                initialData?.state === OrderState.Annulled
              }
            />
            <Controller
              name="actionTrack"
              control={control}
              render={({
                field: { onChange },
                formState: { defaultValues },
              }) => {
                const options =
                  defaultValues?.groupTracks?.map((track) => ({
                    label: track?.name || '',
                    value: track?.value || '',
                  })) || [];

                return (
                  <DropDownInput
                    labelText="Tipus vial"
                    placeholder={'Selecciona.'}
                    inputSize={'M'}
                    options={options}
                    typeDropDown={'Default'}
                    onChangeSelected={(option) => onChange(option?.value ?? '')}
                    defaultValue={defaultValues?.actionTrack}
                    borderError={!!errors.actionTrack}
                    isDisabled={
                      initialData?.parentExpeditionOrderStatus ===
                        OrderState.Annulled ||
                      initialData?.parentExpeditionOrderStatus ===
                        OrderState.End ||
                      initialData?.state === OrderState.Annulled
                    }
                  />
                );
              }}
            />
          </RowStyled>
          <RowStyled>
            <Controller
              control={control}
              name="actionDirection"
              render={({
                field: { onChange },
                formState: { defaultValues },
              }) => {
                const defaultStatusValue: PartialDirection = {
                  [ActionDirection.DOWN]: 'down',
                  [ActionDirection.TOP]: 'top',
                  [ActionDirection.BOTH]: 'both',
                  [ActionDirection.UNSPECIFIC]: UNSPECIFIC,
                };

                const directionOptions: PartialDirection = {
                  down: ActionDirection.DOWN,
                  top: ActionDirection.TOP,
                  both: ActionDirection.BOTH,
                  [UNSPECIFIC]: UNSPECIFIC,
                };
                return (
                  <ArrowRadioGroup
                    directions={['down', 'both', 'top', UNSPECIFIC]}
                    hasUnspecific
                    name="direction"
                    label={'Sentit'}
                    defaultValue={
                      defaultStatusValue[
                        defaultValues?.actionDirection ||
                        defaultValues?.actionDirection === UNSPECIFIC
                          ? (defaultValues?.actionDirection as ActionDirection)
                          : (defaultValues?.direction as ActionDirection)
                      ]
                    }
                    onChangeDirection={(dir) => {
                      dir ? onChange(directionOptions[dir]) : onChange('');
                    }}
                    borderError={!!errors.actionDirection}
                    disabled={
                      initialData?.parentExpeditionOrderStatus ===
                        OrderState.Annulled ||
                      initialData?.parentExpeditionOrderStatus ===
                        OrderState.End ||
                      initialData?.state === OrderState.Annulled
                    }
                  />
                );
              }}
            />

            <Controller
              control={control}
              name="actionMargin"
              render={({
                field: { onChange },
                formState: { defaultValues },
              }) => {
                const defaultStatusValue: PartialDirection = {
                  [ActionMargin.BOTH]: 'both',
                  [ActionMargin.LEFT]: 'left',
                  [ActionMargin.RIGHT]: 'right',
                  [ActionMargin.UNSPECIFIC]: UNSPECIFIC,
                };

                const options: PartialDirection = {
                  both: ActionMargin.BOTH,
                  left: ActionMargin.LEFT,
                  right: ActionMargin.RIGHT,
                  [UNSPECIFIC]: ActionMargin.UNSPECIFIC,
                };

                return (
                  <ArrowRadioGroup
                    directions={['left', 'both', 'right', UNSPECIFIC]}
                    hasUnspecific
                    name="margin"
                    label={'Marge'}
                    defaultValue={
                      defaultStatusValue[
                        defaultValues?.actionMargin ||
                        defaultValues?.actionMargin === UNSPECIFIC
                          ? (defaultValues?.actionMargin as ActionMargin)
                          : (defaultValues?.margin as ActionMargin)
                      ]
                    }
                    onChangeDirection={(dir) => {
                      dir ? onChange(options[dir]) : onChange('');
                    }}
                    borderError={!!errors.actionMargin}
                    disabled={
                      initialData?.parentExpeditionOrderStatus ===
                        OrderState.Annulled ||
                      initialData?.parentExpeditionOrderStatus ===
                        OrderState.End ||
                      initialData?.state === OrderState.Annulled
                    }
                  />
                );
              }}
            />
            <Controller
              name="actionLane"
              control={control}
              render={({
                field: { onChange },
                formState: { defaultValues },
              }) => {
                const options =
                  defaultValues?.groupLanes?.map((lane) => ({
                    label: lane?.name || '',
                    value: lane?.value || '',
                  })) || [];
                return (
                  <DropDownInput
                    labelText="Carril"
                    placeholder={'Selecciona.'}
                    inputSize={'S'}
                    options={options}
                    typeDropDown={'Default'}
                    onChangeSelected={(option) => onChange(option?.value ?? '')}
                    defaultValue={defaultValues?.actionLane}
                    borderError={!!errors.actionLane}
                    isDisabled={
                      initialData?.parentExpeditionOrderStatus ===
                        OrderState.Annulled ||
                      initialData?.parentExpeditionOrderStatus ===
                        OrderState.End ||
                      initialData?.state === OrderState.Annulled
                    }
                  />
                );
              }}
            />
            {actionCoordinates ? (
              <GreenButton
                type={'button'}
                variant="diana"
                onClick={handleGetLocation}
                disabled={
                  initialData?.parentExpeditionOrderStatus ===
                    OrderState.Annulled ||
                  initialData?.parentExpeditionOrderStatus === OrderState.End ||
                  initialData?.state === OrderState.Annulled
                }
              />
            ) : (
              <BlackButton
                type={'button'}
                variant="diana"
                onClick={handleGetLocation}
                disabled={
                  initialData?.parentExpeditionOrderStatus ===
                    OrderState.Annulled ||
                  initialData?.parentExpeditionOrderStatus === OrderState.End ||
                  initialData?.state === OrderState.Annulled
                }
              />
            )}
          </RowStyled>
        </RowsContainer>
        {mapError && (
          <ContainerError>
            <Typography size="XS" colorText="lightCoralRed">
              {mapError}
            </Typography>
          </ContainerError>
        )}
        <ButtonContainer>
          <MainButton
            text={
              initialData?.parentExpeditionOrderStatus ===
                OrderState.Annulled ||
              initialData?.parentExpeditionOrderStatus === OrderState.End ||
              initialData?.state === OrderState.Annulled
                ? 'Continuar'
                : textButton
            }
            type="submit"
          />
        </ButtonContainer>
      </GeneralContainer>
    </MainContainer>
  );
};

export default LocationForm;

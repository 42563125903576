import React from 'react';

type EraserIconProps = {
  className?: string;
};

export const Eraser = ({ className }: EraserIconProps): JSX.Element => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <rect
        className="left"
        x="9.9375"
        y="0.454743"
        width="7.93388"
        height="13.4121"
        transform="rotate(45 9.9375 0.454743)"
        fill="white"
        stroke="color"
        strokeWidth="0.587862"
      />
      <rect
        className="right"
        x="9.9375"
        y="0.454743"
        width="7.93388"
        height="6.71649"
        transform="rotate(45 9.9375 0.454743)"
        fill="color"
        stroke="color"
        strokeWidth="0.587862"
      />
    </svg>
  );
};

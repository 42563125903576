// Sentry
import * as Sentry from '@sentry/react';

// GQL
import { client } from '../client';
import { GET_CONCESSIONS, GET_ORDER_BY_ID } from '../queries/orders/orders';
import {
  FETCH_TEAMS,
  FETCH_USERS,
  GET_ACTIVE_OPERATORS,
  GET_OPERATORS,
  GET_TEAM_BY_ID,
} from '../queries/users';
import { FETCH_VEHICLES, GET_ACTIVE_VEHICLES } from '../queries/vehicles';
import {
  FETCH_ACTIVITYS,
  GET_ACTIVITY_BY_ID,
} from '../queries/actions/activitys';
import { FETCH_ASSETS, GET_ASSET_BY_ID } from '../queries/actions/assets';
import { GET_ACTION_BY_ID } from '../queries/actions/actions';
import {
  GET_CONCESSION_BY_ID,
  GET_ROADS_BY_IDS,
  GET_ROAD_BY_ID,
} from '../queries/geoPositions/roads';
import {
  GET_MATERIAL_BY_ID,
  GET_MATERIAL_UNIT_BY_ID,
} from '../queries/actions/materials';
import {
  GET_AUX_MACHINE_BY_ID,
  GET_AUX_MACHINE_UNIT_BY_ID,
} from '../queries/actions/auxMachines';
import {
  GET_ANIMAL_BY_ID,
  GET_ANIMAL_STATUS_BY_ID,
  GET_ANIMAL_UNIT_BY_ID,
} from '../queries/actions/animals';
import {
  FETCH_CAPITOLS,
  FETCH_PREDEFINED_ORDERS,
  GET_CAPITOL_BY_ID,
  GET_ELEMENT_ORDERS_BY_ID,
  GET_ELEMENT_ORDER_BY_ID,
  GET_SUB_CAPITOLS_BY_IDS,
  GET_SUB_CAPITOL_BY_ID,
} from '../queries/orders/predefinedData';
import { groupLanes, groupTracks } from '../../data/orders';
import { GET_IMAGE_BY_ID } from '../queries/accidents/accidents';
import { GET_POSITION_ROADS } from '../queries/geoPositions/geoPositionRoads';
import { GET_POSITION_ACTIONS } from '../queries/geoPositions/geoPositionActions';
import {
  GET_VEHICLE_OCCUPANT_BY_ID,
  GET_VEHICLE_BY_ID,
} from '../queries/vehicles';
import { GET_USER_BY_ID } from '../queries/users';
import { GET_EXTERNAL_WORK_BY_ID } from '../queries/actions/externalWorks';
import { GET_EXTERNAL_COMPANY_BY_ID } from '../queries/actions/externalCompanies';

// Utils
import { pkFormatter } from '../../utils/transform';

// Types
import { ImageData } from '../../components/inputs/InputLoadImages/types';
import { MaterialsType } from '../../components/display/tables/summaryTables/MaterialsTable/types';
import { MachineryTypes } from '../../components/forms/ActionForms/MachineryForm/types';

export const selectActiveOperatorsAndVehicles = async (
  onlyActives: boolean
) => {
  try {
    const [
      {
        data: { concessions },
      },
      {
        data: { vehicles: vehiclesUnsorted },
      },
    ] = await Promise.all([
      client.query({
        query: GET_CONCESSIONS,
      }),
      client.query({
        query: FETCH_VEHICLES,
      }),
      client.query({
        query: FETCH_TEAMS,
      }),
      client.query({
        query: FETCH_USERS,
      }),
    ]);

    let operators;
    let vehiclesData = vehiclesUnsorted;

    if (onlyActives) {
      const { readActiveOperators } = client.readQuery({
        query: GET_ACTIVE_OPERATORS,
      });

      const { readActiveVehicles } = client.readQuery({
        query: GET_ACTIVE_VEHICLES,
      });

      vehiclesData = readActiveVehicles;
      operators = readActiveOperators;
    } else {
      const { readOperators } = client.readQuery({
        query: GET_OPERATORS,
      });
      operators = readOperators;
    }
    const operatorsWithConcession = await Promise.all(
      operators.map(
        async (operator: {
          teamIDs: string[];
          concessionIDs: string[];
          email: string;
          id: string;
        }) => {
          let concessionInnerName;
          if (operator.teamIDs[0]) {
            const {
              data: { team },
            } = await client.query({
              query: GET_TEAM_BY_ID,
              variables: {
                id: operator.teamIDs[0],
              },
            });

            concessionInnerName = [
              concessions.find(
                (concession: { id: string }) =>
                  team.concessionIDs[0] === concession.id
              ).name,
            ];
          } else {
            if (operator.concessionIDs && operator.concessionIDs.length > 1) {
              concessionInnerName = concessions.map(
                (concessionInner: { name: string }) => concessionInner.name
              );
            } else {
              const {
                data: { concession },
              } = await client.query({
                query: GET_CONCESSION_BY_ID,
                variables: {
                  id: operator.concessionIDs[0],
                },
              });
              concessionInnerName = [concession.name];
            }
          }

          return { concession: concessionInnerName, ...operator };
        }
      )
    );
    operatorsWithConcession.sort((a, b) => a.name.localeCompare(b.name));
    let vehicles = [...vehiclesData].sort((a, b) =>
      a.plate.localeCompare(b.plate)
    );
    vehicles = await Promise.all(
      vehicles.map(async (vehicle: { concessionIDs: string[] }) => {
        let concessionName;

        if (vehicle?.concessionIDs.length) {
          const {
            data: { concession },
          } = await client.query({
            query: GET_CONCESSION_BY_ID,
            variables: {
              id: vehicle.concessionIDs[0],
            },
          });

          concessionName = concession.name;
        }

        return {
          ...vehicle,
          concession: concessionName,
        };
      })
    );

    return {
      vehicles,
      operators: operatorsWithConcession,
    };
  } catch (err) {
    Sentry.captureException(err);
    console.log('> Error getting the user data', err);
  }
};

export const selectAssetsAndActivitys = async () => {
  try {
    const [
      {
        data: { assets },
      },
      {
        data: { activitys },
      },
    ] = await Promise.all([
      client.query({
        query: FETCH_ASSETS,
      }),
      client.query({
        query: FETCH_ACTIVITYS,
      }),
    ]);
    return {
      activitys,
      assets,
    };
  } catch (err) {
    Sentry.captureException(err);
    console.log('> Error getting the assets and activitys data', err);
  }
};

export const selectActivitys = async () => {
  try {
    const {
      data: { activitys },
    } = await client.query({
      query: FETCH_ACTIVITYS,
    });

    return activitys;
  } catch (err) {
    Sentry.captureException(err);
    console.log('Error getting the activitys', err);
  }
};

export const selectOrderWithActionBasicInfoById = async (
  orderId: string,
  actionId: string
) => {
  try {
    const [
      {
        data: { order },
      },
      {
        data: { action },
      },
    ] = await Promise.all([
      client.query({
        query: GET_ORDER_BY_ID,
        variables: { id: orderId },
      }),
      client.query({
        query: GET_ACTION_BY_ID,
        variables: { id: actionId },
      }),
    ]);

    const {
      data: { order: parentOrder },
    } = await client.query({
      query: GET_ORDER_BY_ID,
      variables: {
        id: order.parentExpeditionOrderIDs[0],
      },
    });

    return {
      pkInit: pkFormatter(action.pkInitKm, action.pkInitMeter),
      pkEnd: pkFormatter(action.pkEndKm, action.pkEndMeter),
      actionDescription: action.descriptionValidated,
      orderDescription: parentOrder.description,
      state: order.state,
      indexExpedition: order.indexExpedition,
      registerEndDate: order.registerEndDate,
      orderId,
      parentOrderState: parentOrder.state,
    };
  } catch (err) {
    Sentry.captureException(err);
    console.log(err);
  }
};

export const selectActionById = async (actionId: string) => {
  try {
    const {
      data: { action },
    } = await client.query({
      query: GET_ACTION_BY_ID,
      variables: {
        id: actionId,
      },
    });
    const {
      data: { order },
    } = await client.query({
      query: GET_ORDER_BY_ID,
      variables: {
        id: action.orderIDs[0],
      },
    });

    const [
      {
        data: { concession },
      },
      {
        data: { concessions },
      },
      {
        data: { capitol },
      },
      {
        data: { subCapitol },
      },
      {
        data: { road },
      },
      {
        data: { elementOrder },
      },
    ] = await Promise.all([
      client.query({
        query: GET_CONCESSION_BY_ID,
        variables: {
          id: action.concessionIDs[0],
        },
      }),

      client.query({
        query: GET_CONCESSIONS,
      }),

      client.query({
        query: GET_CAPITOL_BY_ID,
        variables: {
          id: order.capitolIDs[0],
        },
      }),
      client.query({
        query: GET_SUB_CAPITOL_BY_ID,
        variables: {
          id: order.subCapitolIDs[0],
        },
      }),
      client.query({
        query: GET_ROAD_BY_ID,
        variables: {
          id: action.roadIDs[0],
        },
      }),
      client.query({
        query: GET_ELEMENT_ORDER_BY_ID,
        variables: {
          id: order.elementOrderIDs[0],
        },
      }),
    ]);

    let geoPositionData;

    if (action.direction) {
      const {
        data: { geoPositionRoads },
      } = await client.query({
        query: GET_POSITION_ROADS,
        variables: {
          input: {
            pkInitKm: action.pkInitKm,
            pkInitMeter: action.pkInitMeter,
            pkEndKm: action.pkEndKm,
            pkEndMeter: action.pkEndMeter,
            roadId: action.roadIDs[0],
            direction: action.direction,
          },
        },
      });

      geoPositionData = geoPositionRoads;
    }

    let centerMap;
    let path;

    if (geoPositionData && geoPositionData.length > 0) {
      const indexPositionCenter = Math.round(geoPositionData.length / 2) - 1;
      const centerPosition = geoPositionData[indexPositionCenter];

      centerMap = {
        lat: centerPosition.latitude,
        lng: centerPosition.longitude,
      };

      path = geoPositionData.map(
        (position: { latitude: number; longitude: number }) => ({
          lat: position.latitude,
          lng: position.longitude,
        })
      );
    }

    // Optional Fields
    let animals, activityValue, assetValue, machineryValue, materialsValue;
    let geolocationValues;
    const images: ImageData[] = [];

    if (action.geoPositionActionIDs.length > 0) {
      const {
        data: { geoPositionAction },
      } = await client.query({
        query: GET_POSITION_ACTIONS,
        variables: {
          id: action.geoPositionActionIDs[0],
        },
      });

      geolocationValues = geoPositionAction;
      if (!centerMap || (!centerMap?.lat && !centerMap?.lng)) {
        centerMap =
          geolocationValues &&
          geolocationValues.latitude &&
          geolocationValues.longitude
            ? {
                lat: geolocationValues.latitude,
                lng: geolocationValues.longitude,
              }
            : null;
      }
    }

    if (order.imageIDs.length > 0) {
      await Promise.all(
        order.imageIDs.map(async (imageId: string) => {
          const {
            data: { image },
          } = await client.query({
            query: GET_IMAGE_BY_ID,
            variables: {
              id: imageId,
            },
          });

          images.push(image);
        })
      );
    }

    if (action.animalUnitIDs.length > 0) {
      animals = await Promise.all(
        action.animalUnitIDs.map(async (animalUnitId: string) => {
          const {
            data: { animalUnit },
          } = await client.query({
            query: GET_ANIMAL_UNIT_BY_ID,
            variables: {
              id: animalUnitId,
            },
          });

          const {
            data: { animal },
          } = await client.query({
            query: GET_ANIMAL_BY_ID,
            variables: {
              id: animalUnit.animalIDs[0],
            },
          });

          let status = '-';
          if (animalUnit.animalStatusIDs.length > 0) {
            const {
              data: { animalStatus },
            } = await client.query({
              query: GET_ANIMAL_STATUS_BY_ID,
              variables: {
                id: animalUnit.animalStatusIDs[0],
              },
            });

            status = animalStatus.description;
          }

          return {
            codiAn: animal.animalId,
            animal: animal.name,
            ut: animalUnit.unit,
            status,
          };
        })
      );
    }

    if (action.activityIDs.length > 0) {
      const {
        data: { activity },
      } = await client.query({
        query: GET_ACTIVITY_BY_ID,
        variables: {
          id: action.activityIDs[0],
        },
      });

      activityValue = activity;
    }

    if (action.assetIDs.length > 0) {
      const {
        data: { asset },
      } = await client.query({
        query: GET_ASSET_BY_ID,
        variables: {
          id: action.assetIDs[0],
        },
      });

      assetValue = asset;
    }

    let works;
    let externalOperatorsCount = 0;
    if (action.externalWorksIDs.length > 0) {
      works = await Promise.all(
        action.externalWorksIDs.map(async (externalWorkId: string) => {
          const {
            data: { externalWork },
          } = await client.query({
            query: GET_EXTERNAL_WORK_BY_ID,
            variables: {
              id: externalWorkId,
            },
          });

          const {
            data: { externalCompany },
          } = await client.query({
            query: GET_EXTERNAL_COMPANY_BY_ID,
            variables: {
              id: externalWork.externalCompanyIDs[0],
            },
          });

          externalOperatorsCount += externalWork.operatorsNumber;

          return {
            company: externalCompany.companyName,
            operatorsNumber: externalWork.operatorsNumber,
          };
        })
      );
    }

    if (action.auxMachineUnitIDs.length > 0) {
      machineryValue = await Promise.all(
        action.auxMachineUnitIDs.map(async (auxMachineId: string) => {
          const {
            data: { auxMachineUnit },
          } = await client.query({
            query: GET_AUX_MACHINE_UNIT_BY_ID,
            variables: {
              id: auxMachineId,
            },
          });

          const {
            data: { auxMachine },
          } = await client.query({
            query: GET_AUX_MACHINE_BY_ID,
            variables: {
              id: auxMachineUnit.auxMachineIDs[0],
            },
          });

          return {
            codiMaq: auxMachine.auxMachineId,
            maquina: auxMachine.description,
            um: auxMachine.typeOfMeasurement,
            ut: auxMachineUnit.unit,
          };
        })
      );
    }

    if (action.materialUnitIDs.length > 0) {
      materialsValue = await Promise.all(
        action.materialUnitIDs.map(async (materialUnitId: string) => {
          const {
            data: { materialUnit },
          } = await client.query({
            query: GET_MATERIAL_UNIT_BY_ID,
            variables: {
              id: materialUnitId,
            },
          });

          const {
            data: { material },
          } = await client.query({
            query: GET_MATERIAL_BY_ID,
            variables: {
              id: materialUnit.materialIDs[0],
            },
          });

          return {
            codiMat: material.materialId,
            material: material.name,
            ut: materialUnit.unit,
            um: material.typeOfMeasurement,
          };
        })
      );
    }

    const operators = await Promise.all(
      action.userIDs.map(async (userID: string) => {
        const {
          data: { user },
        } = await client.query({
          query: GET_USER_BY_ID,
          variables: {
            input: {
              id: userID,
            },
          },
        });

        let concessionInnerName;
        if (user.teamIDs[0]) {
          const {
            data: { team },
          } = await client.query({
            query: GET_TEAM_BY_ID,
            variables: {
              id: user.teamIDs[0],
            },
          });

          concessionInnerName = concessions.find(
            (concession: { id: string }) =>
              team.concessionIDs[0] === concession.id
          ).name;
        } else {
          if (user.concessionIDs && user.concessionIDs.length > 1) {
            concessionInnerName = concessions.map(
              (concessionInner: { name: string }) => concessionInner.name
            );
          } else {
            const {
              data: { concession },
            } = await client.query({
              query: GET_CONCESSION_BY_ID,
              variables: {
                id: user.concessionIDs[0],
              },
            });
            concessionInnerName = concession.name;
          }
        }

        return {
          nom: user.name,
          cognom: user.surName,
          concession: concessionInnerName,
        };
      })
    );

    const vehicles = await Promise.all(
      action.vehicleOccupantIDs.map(async (vehicleOccupantId: string) => {
        const {
          data: { vehicleOccupant },
        } = await client.query({
          query: GET_VEHICLE_OCCUPANT_BY_ID,
          variables: {
            id: vehicleOccupantId,
          },
        });

        const {
          data: { vehicle },
        } = await client.query({
          query: GET_VEHICLE_BY_ID,
          variables: {
            id: vehicleOccupant.vehicleIDs[0],
          },
        });

        const {
          data: { user },
        } = await client.query({
          query: GET_USER_BY_ID,
          variables: {
            input: {
              id: vehicleOccupant.userDriverIDs[0],
            },
          },
        });

        let concessionName;

        if (vehicle?.concessionIDs.length) {
          const {
            data: { concession },
          } = await client.query({
            query: GET_CONCESSION_BY_ID,
            variables: {
              id: vehicle.concessionIDs[0],
            },
          });

          concessionName = concession.name;
        }

        return {
          mat: vehicle.plate,
          driver: `${user.name} ${user.surName}`,
          concession: concessionName,
        };
      })
    );

    return {
      id: action.id,
      cotic: order.cotic.split('.'),
      date: order.registerInitDate,
      concession: concession.name,
      pkInit: pkFormatter(action.pkInitKm, action.pkInitMeter, true),
      pkEnd: pkFormatter(action.pkEndKm, action.pkEndMeter, true),
      track: groupTracks.find((track) => track.value === action.track)?.name,
      direction: action.direction,
      margin: action.margin,
      lane: groupLanes.find((lane) => lane.value === action.lane)?.name,
      capitol: capitol.name,
      subCapitol: subCapitol.name,
      description: action.descriptionValidated,
      animals,
      activityValue: activityValue ? activityValue.name : undefined,
      assetValue: assetValue ? assetValue.code : undefined,
      assetSwitch: action.assetSwitch,
      action,
      materialsValue,
      machineryValue,
      road: road.name,
      roadDirections: road.directions,
      city: concession.location,
      registerInitDate: order.registerInitDate,
      registerEndDate: order.registerEndDate,
      minutesTotals: action.minutesTotals,
      elementOrder: elementOrder.name,
      images: images,
      centerMap: centerMap,
      path: path,
      operators,
      vehicles,
      works,
      isExternal: action.isExternal,
      geolocation:
        geolocationValues &&
        geolocationValues.latitude &&
        geolocationValues.longitude
          ? {
              lat: geolocationValues.latitude,
              lng: geolocationValues.longitude,
            }
          : null,
      externalOperatorsCount,
    };
  } catch (err) {
    Sentry.captureException(err);
    console.log(err);
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const selectActionOrders = (actions: any[] = [], reFetch = false) => {
  return Promise.all(
    actions.map(async (action) => {
      const {
        data: { order },
      } = await client.query({
        query: GET_ORDER_BY_ID,
        variables: {
          id: action.orderIDs[0],
        },
        fetchPolicy: reFetch ? 'network-only' : 'cache-first',
      });

      const {
        data: { capitol },
      } = await client.query({
        query: GET_CAPITOL_BY_ID,
        variables: {
          id: order.capitolIDs[0],
        },
        fetchPolicy: reFetch ? 'network-only' : 'cache-first',
      });

      const {
        data: { subCapitol },
      } = await client.query({
        query: GET_SUB_CAPITOL_BY_ID,
        variables: {
          id: order.subCapitolIDs[0],
        },
        fetchPolicy: reFetch ? 'network-only' : 'cache-first',
      });

      const {
        data: { concession },
      } = await client.query({
        query: GET_CONCESSION_BY_ID,
        variables: {
          id: action.concessionIDs[0],
        },
        fetchPolicy: reFetch ? 'network-only' : 'cache-first',
      });

      const {
        data: { road },
      } = await client.query({
        query: GET_ROAD_BY_ID,
        variables: {
          id: action.roadIDs[0],
        },
        fetchPolicy: reFetch ? 'network-only' : 'cache-first',
      });

      return {
        id: order.id,
        cotic: order.cotic.split('.'),
        registerInitDate: order.registerInitDate,
        creationDate: order.creationDate,
        capitol: capitol.name,
        subCapitol: subCapitol.name,
        pkInit: pkFormatter(action.pkInitKm, action.pkInitMeter),
        pkEnd: pkFormatter(action.pkEndKm, action.pkEndMeter),
        concession: concession.name,
        description: order.description,
        state: order.state,
        type: order.type,
        road: road.name,
        actionID: action.id,
      };
    })
  );
};

/* SELECTOR FOR ACTION VALIDATION */

export const selectOperatorsAndVehiclesFormValues = async (
  actionId: string
) => {
  const {
    data: { action },
  } = await client.query({
    query: GET_ACTION_BY_ID,
    variables: {
      id: actionId,
    },
  });
  const {
    data: { order },
  } = await client.query({
    query: GET_ORDER_BY_ID,
    variables: {
      id: action.orderIDs[0],
    },
  });

  const {
    data: { concessions },
  } = await client.query({
    query: GET_CONCESSIONS,
  });

  const allOperators = await Promise.all(
    action.userIDs.map(async (userID: string) => {
      let concessionInnerName;
      const {
        data: { user },
      } = await client.query({
        query: GET_USER_BY_ID,
        variables: {
          input: {
            id: userID,
          },
        },
      });

      if (user.teamIDs[0]) {
        const {
          data: { team },
        } = await client.query({
          query: GET_TEAM_BY_ID,
          variables: {
            id: user.teamIDs[0],
          },
        });

        const {
          data: { concession },
        } = await client.query({
          query: GET_CONCESSION_BY_ID,
          variables: {
            id: team.concessionIDs[0],
          },
        });
        concessionInnerName = [concession.name];
      } else {
        if (user.concessionIDs && user.concessionIDs.length > 1) {
          concessionInnerName = concessions.map(
            (concessionInner: { name: string }) => concessionInner.name
          );
        } else {
          const {
            data: { concession },
          } = await client.query({
            query: GET_CONCESSION_BY_ID,
            variables: {
              id: user.concessionIDs[0],
            },
          });
          concessionInnerName = [concession.name];
        }
      }

      return {
        ...user,
        concession: concessionInnerName,
      };
    })
  );

  const externalOperatorsTable = allOperators.filter(
    (operator) => !operator.internal
  );
  const internalOperatorsTable = allOperators.filter(
    (operator) => operator.internal
  );

  const vehicleTableRaw = await Promise.all(
    action.vehicleOccupantIDs.map(async (vehicleOccupantId: string) => {
      const {
        data: { vehicleOccupant },
      } = await client.query({
        query: GET_VEHICLE_OCCUPANT_BY_ID,
        variables: {
          id: vehicleOccupantId,
        },
      });

      const {
        data: { vehicle },
      } = await client.query({
        query: GET_VEHICLE_BY_ID,
        variables: {
          id: vehicleOccupant.vehicleIDs[0],
        },
      });

      const {
        data: { user },
      } = await client.query({
        query: GET_USER_BY_ID,
        variables: {
          input: {
            id: vehicleOccupant.userDriverIDs[0],
          },
        },
      });

      return {
        vehicleID: vehicle.id,
        driverID: user.id,
        vehicleOccupantID: vehicleOccupant.id,
      };
    })
  );

  const vehicleTable: any = {};

  vehicleTableRaw.forEach((vehicle) => {
    vehicleTable[vehicle.vehicleID] = vehicle;
  });

  const concessionWithUndefined = [
    { id: '', name: 'Tots els operaris' },
    ...concessions,
  ];

  const externalWorks = await Promise.all(
    action.externalWorksIDs.map(async (workId: string) => {
      const {
        data: { externalWork },
      } = await client.query({
        query: GET_EXTERNAL_WORK_BY_ID,
        variables: {
          id: workId,
        },
      });

      const {
        data: { externalCompany },
      } = await client.query({
        query: GET_EXTERNAL_COMPANY_BY_ID,
        variables: {
          id: externalWork.externalCompanyIDs[0],
        },
      });

      return {
        ...externalWork,
        company: externalCompany.companyName,
        // work: externalWork.workName,
        workId,
      };
    })
  );
  return {
    hoursType: action.typeOfHour,
    registerInitDate: order.registerInitDate,
    registerEndDate: order.registerEndDate,
    totalHours: `${Math.floor(action.minutesTotals / 60)
      .toString()
      .padStart(2, '0')}:${(action.minutesTotals % 60)
      .toString()
      .padStart(2, '0')}`,
    vehicleTable,
    externalOperatorsTable,
    internalOperatorsTable,
    breadCrumbsData: {
      concession: action.concessionIDs[0],
      concessions: concessionWithUndefined,
    },
    isExternal: externalWorks.length > 0,
    // externalInitDate: action.externalInitDate,
    // externalEndDate: action.externalEndDate,
    // externalTypeOfHour: action.externalTypeOfHour,
    // externalMinutesTotals: action.externalMinutesTotals,
    externalWorks,
  };
};

export const selectLocationValues = async (actionId: string) => {
  const {
    data: { action },
  } = await client.query({
    query: GET_ACTION_BY_ID,
    variables: {
      id: actionId,
    },
  });

  const [
    {
      data: { concession },
    },
    {
      data: { concessions },
    },
    {
      data: { road },
    },
  ] = await Promise.all([
    client.query({
      query: GET_CONCESSION_BY_ID,
      variables: {
        id: action.concessionIDs[0],
      },
    }),
    client.query({
      query: GET_CONCESSIONS,
    }),
    client.query({
      query: GET_ROAD_BY_ID,
      variables: {
        id: action.roadIDs[0],
      },
    }),
  ]);

  const {
    data: { readRoads },
  } = await client.query({
    query: GET_ROADS_BY_IDS,
    variables: {
      ids: concession.roadIDs,
    },
  });

  return {
    actionConcession: concession.id,
    concessions,
    actionCity: concession.location,
    actionPkInitRoad: pkFormatter(road.pkInitKm, road.pkInitMeter, true),
    actionPkEndRoad: pkFormatter(road.pkEndKm, road.pkEndMeter, true),
    actionRoad: road.id,
    roads: readRoads,
    actionPkInit: pkFormatter(action.pkInitKm, action.pkInitMeter, true),
    actionPkEnd: pkFormatter(action.pkEndKm, action.pkEndMeter, true),
    groupTracks,
    actionTrack: action.track,
    actionDirection: action.direction ?? undefined,
    actionMargin: action.margin ?? undefined,
    groupLanes,
    actionLane: action.lane,
  };
};

export const selectEditionValues = async (actionId: string) => {
  const images: ImageData[] = [];

  const {
    data: { action },
  } = await client.query({
    query: GET_ACTION_BY_ID,
    variables: {
      id: actionId,
    },
  });

  const {
    data: { order },
  } = await client.query({
    query: GET_ORDER_BY_ID,
    variables: {
      id: action.orderIDs[0],
    },
  });

  if (order.imageIDs.length > 0) {
    await Promise.all(
      order.imageIDs.map(async (imageId: string) => {
        const {
          data: { image },
        } = await client.query({
          query: GET_IMAGE_BY_ID,
          variables: {
            id: imageId,
          },
        });

        images.push({
          id: image.id,
          date: image.creatingDate,
          name: image.name,
        });
      })
    );
  }

  const [
    {
      data: { activity },
    },
    {
      data: { capitol },
    },
    {
      data: { subCapitol },
    },
    {
      data: { elementOrder },
    },
    {
      data: { capitols },
    },
    {
      data: { predefinedOrders },
    },
  ] = await Promise.all([
    client.query({
      query: GET_ACTIVITY_BY_ID,
      variables: {
        id: action.activityIDs[0],
      },
    }),
    client.query({
      query: GET_CAPITOL_BY_ID,
      variables: {
        id: order.capitolIDs[0],
      },
    }),
    client.query({
      query: GET_SUB_CAPITOL_BY_ID,
      variables: {
        id: order.subCapitolIDs[0],
      },
    }),
    // If the order is a partial action, order.elementOrderIDs
    // is an empty array, so return undefined
    order.elementOrderIDs.length > 0
      ? client.query({
          query: GET_ELEMENT_ORDER_BY_ID,
          variables: {
            id: order.elementOrderIDs[0],
          },
        })
      : Promise.resolve({ data: { elementOrder: undefined } }),
    client.query({
      query: FETCH_CAPITOLS,
    }),
    client.query({
      query: FETCH_PREDEFINED_ORDERS,
    }),
  ]);
  const {
    data: { readSubCapitols },
  } = await client.query({
    query: GET_SUB_CAPITOLS_BY_IDS,
    variables: {
      ids: capitol.subCapitolsIDs,
    },
  });

  const {
    data: { readElementOrders },
  } = await client.query({
    query: GET_ELEMENT_ORDERS_BY_ID,
    variables: {
      ids: subCapitol.elementOrdersIds,
    },
  });

  let assetValue;

  if (action.assetIDs.length > 0) {
    assetValue = await client.query({
      query: GET_ASSET_BY_ID,
      variables: {
        id: action.assetIDs[0],
      },
    });
  }

  return {
    description: action.descriptionValidated
      ? action.descriptionValidated
      : order.description,
    editionDescription: action.descriptionValidated ?? '',
    editionAssetSwitch: action.assetSwitch ? 'true' : 'false',
    editionAsset: assetValue ? assetValue.data.asset.id : '',
    editionCapitol: capitol.id,
    editionSubCapitol: subCapitol.id,
    editionActivity: activity.id,
    editionElementOrder: elementOrder?.id,
    editionLaneCutting: action.laneCutting ? 'true' : 'false',
    capitols,
    predefinedOrders,
    subCapitols: readSubCapitols,
    elementOrders: readElementOrders,
    editionImages: JSON.stringify(images),
  };
};

export const selectMaterialsValue = async (actionId: string) => {
  const {
    data: { action },
  } = await client.query({
    query: GET_ACTION_BY_ID,
    variables: {
      id: actionId,
    },
  });

  const materialsTable: { [key: string]: MaterialsType } = {};

  if (action.materialUnitIDs.length > 0) {
    for (const materialUnitId of action.materialUnitIDs) {
      const {
        data: { materialUnit },
      } = await client.query({
        query: GET_MATERIAL_UNIT_BY_ID,
        variables: {
          id: materialUnitId,
        },
      });
      const {
        data: { material },
      } = await client.query({
        query: GET_MATERIAL_BY_ID,
        variables: {
          id: materialUnit.materialIDs[0],
        },
      });

      const materialId = material.id;

      materialsTable[materialId] = {
        ...material,
        materialUnitId: materialUnit.id,
        ut: `${materialUnit.unit}`,
      };
    }
  }

  return {
    isMaterialTableDisplayed:
      Object.keys(materialsTable).length > 0 ? 'true' : 'false',
    materialsTable,
  };
};

export const selectMachineriesValue = async (actionId: string) => {
  const {
    data: { action },
  } = await client.query({
    query: GET_ACTION_BY_ID,
    variables: {
      id: actionId,
    },
  });

  const machineryTable: { [key: string]: MachineryTypes } = {};

  if (action.auxMachineUnitIDs.length > 0) {
    for (const auxMachineUnitId of action.auxMachineUnitIDs) {
      const {
        data: { auxMachineUnit },
      } = await client.query({
        query: GET_AUX_MACHINE_UNIT_BY_ID,
        variables: {
          id: auxMachineUnitId,
        },
      });

      const {
        data: { auxMachine },
      } = await client.query({
        query: GET_AUX_MACHINE_BY_ID,
        variables: {
          id: auxMachineUnit.auxMachineIDs[0],
        },
      });

      const machineryId = auxMachine.id;

      machineryTable[machineryId] = {
        ...auxMachine,
        auxMachineUnitId: auxMachineUnit.id,
        ut: `${auxMachineUnit.unit}`,
      };
    }
  }

  return {
    isMachineryTableDisplayed:
      Object.keys(machineryTable).length > 0 ? 'true' : 'false',
    machineryTable,
  };
};

export const selectAnimalsValue = async (actionId: string) => {
  const {
    data: { action },
  } = await client.query({
    query: GET_ACTION_BY_ID,
    variables: {
      id: actionId,
    },
  });

  const animalsTable: { [key: string]: MachineryTypes } = {};

  if (action.animalUnitIDs.length > 0) {
    for (const animalUnitId of action.animalUnitIDs) {
      const {
        data: { animalUnit },
      } = await client.query({
        query: GET_ANIMAL_UNIT_BY_ID,
        variables: {
          id: animalUnitId,
        },
      });

      const {
        data: { animal },
      } = await client.query({
        query: GET_ANIMAL_BY_ID,
        variables: {
          id: animalUnit.animalIDs[0],
        },
      });

      const animalId = animal.id;

      animalsTable[animalId] = {
        ...animal,
        animalUnitId: animalUnit.id,
        ut: `${animalUnit.unit}`,
        statusId: animalUnit.animalStatusIDs[0] || '',
      };
    }
  }

  return {
    isAnimalsTableDisplayed:
      Object.keys(animalsTable).length > 0 ? 'true' : 'false',
    animalsTable,
  };
};

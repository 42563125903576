import React, { useRef } from 'react';

import { Controller } from 'react-hook-form';

// Components Styled
import { InputsContainer, Title } from '../components/MainStyled';

import { AccidentReportContainer, InputRow } from './styles';

//Components
import InputText from '../../inputs/InputText';

// Types
import DropDownInput from '../../inputs/DropDown/DropDownInput';
import { FormProps, ReportState, SetValueType } from './types';
import { ImperativeRefInput } from '../../inputs/DropDown/DropDownInput/types';
import { Options } from '../../inputs/DropDown/DropDownList/types';

export const BasicInfo = ({
  register,
  control,
  errors,
  defaultValues,
  setValue,
  isAReportState,
  setIsAReportState,
}: FormProps & SetValueType & ReportState) => {
  const surveillanceBodyRef = useRef<ImperativeRefInput>(null);

  const defaultValuesLaneCutting = () => {
    switch (defaultValues?.laneCutting) {
      case true:
        return 'yes';
      case false:
        return 'no';
      default:
        undefined;
    }
  };

  const defaultValuesPoliceReport = () => {
    switch (defaultValues?.policeReport) {
      case true:
        return 'yes';
      case false:
        return 'no';
      default:
        undefined;
    }
  };

  return (
    <AccidentReportContainer>
      <Title component="h2" variant="semiBold" size="L">
        Comunicat d’accident
      </Title>
      <InputsContainer>
        <InputRow>
          <Controller
            control={control}
            name="mediumKnowledge"
            render={({ field: { onChange }, formState: { defaultValues } }) => {
              const options =
                defaultValues?.mediumKnowledges?.map((medium) => ({
                  label: medium?.name,
                  value: medium?.id,
                })) || [];

              return (
                <DropDownInput
                  inputSize="M"
                  onChangeSelected={(option) => onChange(option?.value || '')}
                  typeDropDown="Default"
                  labelText="Medi de coneixement"
                  placeholder="Selecciona"
                  defaultValue={defaultValues?.mediumKnowledge}
                  borderError={!!errors?.mediumKnowledge}
                  options={options as Options}
                />
              );
            }}
          />

          <Controller
            control={control}
            name="laneCutting"
            render={({ field: { onChange } }) => {
              const options = [
                {
                  label: 'SI',
                  value: 'yes',
                },
                {
                  label: 'NO',
                  value: 'no',
                },
              ];

              return (
                <DropDownInput
                  inputSize="XS"
                  onChangeSelected={(option) => {
                    if (option?.value === 'yes') {
                      return onChange(true);
                    } else {
                      return onChange(false);
                    }
                  }}
                  typeDropDown="Default"
                  labelText="Tall de carril"
                  placeholder="Selecciona"
                  defaultValue={defaultValuesLaneCutting()}
                  borderError={!!errors?.laneCutting}
                  options={options}
                />
              );
            }}
          />
        </InputRow>

        <InputRow>
          <Controller
            control={control}
            name="policeReport"
            render={({ field: { onChange } }) => {
              const options = [
                {
                  label: 'SI',
                  value: 'yes',
                },
                {
                  label: 'NO',
                  value: 'no',
                },
              ];

              return (
                <DropDownInput
                  inputSize="XS"
                  onChangeSelected={(option) => {
                    if (option?.value === 'yes') {
                      setIsAReportState(true);
                      onChange(true);
                    } else {
                      surveillanceBodyRef.current?.clearSelected();
                      setValue('policeReportNumber', '');
                      setValue('surveillanceBody', '');
                      setIsAReportState(false);
                      onChange(false);
                    }
                  }}
                  typeDropDown="Default"
                  labelText="Atestat"
                  placeholder="Selecciona"
                  defaultValue={defaultValuesPoliceReport()}
                  borderError={!!errors?.policeReport}
                  options={options}
                />
              );
            }}
          />

          <Controller
            control={control}
            name="surveillanceBody"
            render={({ field: { onChange }, formState: { defaultValues } }) => {
              const options = defaultValues?.surveillanceBodys?.map(
                (surveillanceBody) => ({
                  label: surveillanceBody?.name,
                  value: surveillanceBody?.id,
                })
              );

              return (
                <DropDownInput
                  ref={surveillanceBodyRef}
                  inputSize="S"
                  onChangeSelected={(option) => onChange(option?.value ?? '')}
                  typeDropDown="Default"
                  labelText="Òrgan de vigilància"
                  placeholder="Selecciona"
                  defaultValue={defaultValues?.surveillanceBody}
                  borderError={!!errors.surveillanceBody}
                  options={options as Options}
                  isDisabled={!isAReportState}
                />
              );
            }}
          />

          <InputText
            inputSize="16"
            {...register('policeReportNumber')}
            label="N° d'atestat policial"
            typeInput="normal"
            borderError={!!errors.policeReportNumber}
            disabled={!isAReportState}
          />
        </InputRow>
      </InputsContainer>
    </AccidentReportContainer>
  );
};

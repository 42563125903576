import { gql } from '@apollo/client';

export const GET_VEHICLE_CATEGORIES = gql`
  query GetVehicleCategories {
    vehicleCategories {
      id
      name
      vehicleIDs
    }
  }
`;

export const GET_VEHICLE_CATEGORY_BY_ID = gql`
  query GetVehicleCategoryById($id: ID!) {
    vehicleCategory(id: $id) {
      id
      name
      vehicleIDs
    }
  }
`;

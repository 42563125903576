export type TableProps = {
  data?: OperatorsType[];
};

export type OperatorsType = {
  nom: string;
  cognom: string;
  concession: number | string;
};

type columnIDTypes = { [key: string]: { id: string; label: string } };

export const columnNames: columnIDTypes = {
  nom: {
    id: 'nom',
    label: 'Nom',
  },
  cognom: {
    id: 'cognom',
    label: 'Cognom',
  },
  concession: {
    id: 'concession',
    label: 'Con.',
  },
};

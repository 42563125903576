import React, { SyntheticEvent, useCallback, useLayoutEffect } from 'react';
import {
  useNavigate,
  useActionData,
  useAsyncValue,
  useSubmit,
} from 'react-router-dom';
import { format } from 'date-fns';

// Components Styled
import { Container } from './styles';

//Components
import ContentCardResume from '../../../../../components/display/ContentCardResume';

// Types
import { type Campaign } from '../../../../../types/campaign';
import { createFormDataFromValues } from '../../../../../utils/forms';
import { variant } from '../../../../../components/display/SpecialTag/types';

export default function CampaignSummary(): JSX.Element {
  const loaderData = useAsyncValue() as Campaign;
  const actionData = useActionData() as Campaign;
  const navigate = useNavigate();
  const submit = useSubmit();

  const campaignData = actionData ?? loaderData;

  useLayoutEffect(() => {
    if (!campaignData) navigate('/work-orders/campaign/new-campaign');
  }, [campaignData]);

  const handleEditWorkOrder = useCallback(() => {
    if (loaderData) {
      navigate('/work-orders/campaign/edit-campaign');
    } else {
      navigate(-1);
    }
  }, []);

  const handleSaveWorkOrder = async (e: SyntheticEvent) => {
    e.preventDefault();
    const formData = createFormDataFromValues({
      intent: 'save-data',
    });
    submit(formData, {
      method: 'POST',
      action: '.',
    });
  };
  return (
    <Container>
      {campaignData && (
        <ContentCardResume
          title={
            campaignData.campaign
              ? 'Resum de la Campanya'
              : 'Resum de la Ordre de Treball'
          }
          cotic={
            campaignData.cotic ? (campaignData.cotic as string).split(',') : []
          }
          operator={campaignData.operator}
          data={format(
            new Date(campaignData.registerInitDate),
            'dd / MM / yyyy'
          )}
          hour={format(new Date(campaignData.registerInitDate), 'HH:mm')}
          capitol={campaignData.capitol}
          subCapitol={campaignData.subCapitol}
          classification={campaignData.classification}
          team={campaignData.team}
          concession={campaignData.concession}
          road={campaignData.road}
          pkInit={`${campaignData.pkInit} km`}
          pkEnd={`${campaignData.pkEnd} km`}
          track={campaignData.track}
          roadDirections={campaignData.roadDirections}
          direction={campaignData.direction}
          city={campaignData.city}
          lane={campaignData.lane}
          margin={campaignData.margin}
          leftButton={'Editar O.T'}
          rightButton={'Desar'}
          description={campaignData.description}
          onEditCommunication={handleEditWorkOrder}
          onSaveCommunication={handleSaveWorkOrder}
          tag={campaignData.campaign ? variant['Campaign'] : undefined}
          tagContent={campaignData.campaign && campaignData.campaign}
        />
      )}
    </Container>
  );
}

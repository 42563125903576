import React from 'react';

import { Container, Title, Value } from './styles';

import { definitonProps } from './types';

// Media
import { useIsLandscapeTablet } from '../../../hooks/useMediaBreakPoints';

export const Definition = ({
  title,
  value,
  ...props
}: definitonProps): JSX.Element => {
  const isLandscapeTablet = useIsLandscapeTablet();

  return (
    <Container {...props}>
      <Title size={isLandscapeTablet ? 'XS' : 'S'} component="dt">
        {title}:
      </Title>
      <Value
        size={isLandscapeTablet ? 'XS' : 'S'}
        variant="semiBold"
        component="dd"
      >
        {value}
      </Value>
    </Container>
  );
};

import React from 'react';

type DirectionLeftProps = {
  className?: string;
};

export const DirectionLeft = ({
  className,
}: DirectionLeftProps): JSX.Element => {
  return (
    <svg
      width="22"
      height="17"
      viewBox="0 0 22 17"
      fill="black"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M9.38464 14.0872L5.07563 9.77818L21 9.77817L21.25 9.77817L21.25 9.52817L21.25 7.49428L21.25 7.24428L21 7.24428L5.07564 7.24428L9.38464 2.93527C9.87942 2.4405 9.87942 1.63831 9.38464 1.14353C8.88987 0.648758 8.08768 0.648758 7.5929 1.14353L1.12108 7.61536C0.626306 8.11013 0.626306 8.91232 1.12108 9.4071L7.59291 15.8789C8.08768 16.3737 8.88987 16.3737 9.38464 15.8789C9.87942 15.3841 9.87942 14.582 9.38464 14.0872Z" />
    </svg>
  );
};

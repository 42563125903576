import { ActionFunction } from 'react-router-dom';

// Sentry
import * as Sentry from '@sentry/react';

// GQL
import { client } from '../../../gql/client';
import { VALIDATE_USER } from '../../../gql/mutations/auth';

// Type
import { AuthContextType } from '../../../hooks/useAuth';

export const ActionResetPassword: (
  userData: AuthContextType | null
) => ActionFunction | undefined = (userData) => async (formDataRaw) => {
  const formData = Object.fromEntries(await formDataRaw.request.formData());

  try {
    const {
      data: { validateUser },
    } = await client.mutate({
      mutation: VALIDATE_USER,
      variables: {
        input: {
          id: userData?.user?.user?.id,
          newPassword: formData.newPassword,
        },
      },
    });

    userData?.setUser({
      ...userData?.user,
      user: {
        ...userData?.user?.user,
        validated: validateUser.validated,
      },
    });
  } catch (err) {
    Sentry.captureException(err);
    console.log('Error', err);
  }

  return null;
};

export type SketchProps = {
  onSaveDraw: () => void;
};

export type SketchTabProps = {
  setDraw: (enable: boolean) => void;
  removeAll: () => void;
  removeFigure: () => void;
  onAddCircle: () => void;
  onAddText: () => void;
  onAddRectangle: () => void;
};

export type ButtonsTab = 'pencil' | 'figures' | 'text' | 'eraser';

export type ContainerInputProps = {
  isSelected?: boolean;
};

export enum ButtonVariant {
  PENCIL = 'pencil',
  FIGURES = 'figures',
  TEXT = 'text',
  ERASER = 'eraser',
}

export type ImperativeRefSketch = {
  getSvg: () => void;
};

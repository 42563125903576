import React from 'react';
import { useLocation } from 'react-router-dom';

import { Controller } from 'react-hook-form';

// Components Styled
import { ContainerOperator } from './styles';
import {
  ContainerBasicInfo,
  InputsContainer,
  Title,
} from '../components/MainStyled';

//Components
import { DateTime } from '../../inputs/DateTime';

// Types
import { FormProps } from './types';
import { Typography } from '../../display/Typography';
import InputText from '../../inputs/InputText';

export const BasicInfo = ({
  register,
  control,
  errors,
  isAction,
  title,
  titleDate = 'Data de registre:',
  textDateHour = 'Hora:',
}: FormProps) => {
  const location = useLocation();
  const locationNewOrder = location.pathname !== '/work-orders/new-order';
  return (
    <ContainerBasicInfo>
      <Title component="h2" variant="semiBold">
        {title}
      </Title>
      <InputsContainer>
        <ContainerOperator>
          <InputText
            {...register('operator')}
            inputSize={'26'}
            disabled
            label="Operador"
            typeInput="normal"
            borderError={!!errors.operator}
          />
          {locationNewOrder && (
            <Typography size="XXS">
              Darrera Modificació: 00/00/0000 o les 00:00
            </Typography>
          )}
        </ContainerOperator>
        {!isAction && (
          <Controller
            control={control}
            name="registerInitDate"
            render={({ field: { onChange }, formState: { defaultValues } }) => (
              <DateTime
                label={titleDate}
                labelHour={textDateHour}
                onChangeDate={onChange}
                initialDate={
                  defaultValues?.registerInitDate
                    ? defaultValues?.registerInitDate
                    : new Date()
                }
                borderError={!!errors.registerInitDate}
              />
            )}
          />
        )}
      </InputsContainer>
    </ContainerBasicInfo>
  );
};

import { gql } from '@apollo/client';

export const CREATE_NEW_AUX_MACHINE_UNIT = gql`
  mutation CreateAuxMachineUnit($input: NewAuxMachineUnit!) {
    createAuxMachineUnit(input: $input) {
      id
      unit
      actionIDs
      auxMachineIDs
    }
  }
`;

export const UPDATE_MANY_AUX_MACHINE_UNITS = gql`
  mutation UpdateManyMaterials($input: [UpdateAuxMachineUnit]) {
    updateManyAuxMachineUnits(input: $input) {
      id
      unit
      actionIDs
      auxMachineIDs
    }
  }
`;

export const DELETE_MANY_AUX_MACHINE_UNITS = gql`
  mutation DeleteManyMachineUnits($ids: [ID!]) {
    deleteManyAuxMachineUnits(ids: $ids) {
      id
    }
  }
`;

// Dependencies
import styled from 'styled-components';

// Types
import { ContainerProps } from './types';

//Colors
import { colors } from '../../../../styles/colors';

// Styles
import { breakPoints, percentBaseResponsive } from '../../../../styles/spaces';
import { VisibilityIcon } from '../../../../assets/icons/VisibilityIcon';

export const Container = styled.button<ContainerProps>`
  background-color: ${colors.garnet};
  align-self: flex-end;
  width: 3.4rem;
  height: 3.4rem;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  border: none;
  flex-shrink: 0;
  cursor: pointer;
  :focus {
    outline: 0.2rem solid ${colors.black};
  }
`;

export const VisibilityImg = styled(VisibilityIcon)`
  width: 2rem;
  height: 1.3rem;
  @media (max-width: ${breakPoints.landscapeTablet *
    percentBaseResponsive}rem) {
    width: 1.4rem;
    height: 0.9rem;
  }
`;

export const RestartImg = styled.img`
  width: 1.8rem;
  height: 1.8rem;
  @media (max-width: ${breakPoints.landscapeTablet *
    percentBaseResponsive}rem) {
    width: 1.4rem;
    height: 1.4rem;
  }
`;

export const EditImg = styled.img`
  width: 1.5rem;
  height: 1.7rem;
  @media (max-width: ${breakPoints.landscapeTablet *
    percentBaseResponsive}rem) {
    width: 1.2rem;
    height: 1.4rem;
  }
`;

export const SaveImg = styled.img`
  width: 1.8rem;
  height: 1.8rem;
  @media (max-width: ${breakPoints.landscapeTablet *
    percentBaseResponsive}rem) {
    width: 1.4rem;
    height: 1.4rem;
  }
`;

export const AddImg = styled.img`
  width: 2rem;
  height: 2rem;
`;

export const DeleteImg = styled.img`
  width: 1.7rem;
  height: 1.7rem;
  @media (max-width: ${breakPoints.landscapeTablet *
    percentBaseResponsive}rem) {
    width: 1.4rem;
    height: 1.4rem;
  }
`;

export const CheckImg = styled.img`
  width: 2rem;
  height: 2rem;
`;

export const CancelImg = styled.img`
  width: 2rem;
  height: 2rem;
`;

export const DianaImg = styled.img`
  width: 2rem;
  height: 2rem;
`;

export const SearchImg = styled.img`
  width: 2rem;
  height: 2rem;
`;

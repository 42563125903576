import styled, { css } from 'styled-components';

// Colors
import { colors } from '../../../../../styles/colors';

// Components
import { Typography } from '../../../Typography';

// Types
import { displayProps, inputProps } from './types';

// Spaces
import {
  breakPoints,
  percentBaseResponsive,
  spaces,
} from '../../../../../styles/spaces';
import DoubleSwitch from '../../../../inputs/buttons/SwitchButton/Double';
import { SwitchDouble } from '../../../../inputs/buttons/SwitchButton/Double/styles';

export const Container = styled.div`
  width: 100%;
  max-width: 133rem;
`;

export const Table = styled.table`
  width: 100%;
  background-color: ${colors.greyVerySoft};
`;

export const Thead = styled.thead`
  color: white;
  background-color: ${colors.darkGrey};
`;

export const TheadTrailer = styled(Thead)`
  background-color: ${colors.greySilver};
`;

export const TheadDamages = styled(Thead)`
  background-color: ${colors.greySilver};
`;

export const Tr = styled.tr``;

export const Th = styled.th``;

export const ThVehicles = styled.th`
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
`;

export const TextHeader = styled(Typography)`
  padding: 0.5rem 2rem;
`;

export const TrBody = styled.tr`
  :not(:last-of-type) {
    border-bottom: 0.1rem solid ${colors.greyLight};
  }
`;

export const Td = styled.td`
  vertical-align: middle;
  padding-right: 2rem;
  :first-of-type div {
    padding-left: 2rem;
    max-width: 24rem;
  }

  :nth-of-type(2) div {
    max-width: 28rem;
  }

  :last-of-type div {
    max-width: 24.7rem;
  }

  @media (max-width: ${breakPoints.landscapeTablet *
    percentBaseResponsive}rem) {
    :first-of-type div {
      max-width: 30rem;
    }

    :last-of-type div {
      max-width: 26rem;
    }
  }

  @media (max-width: ${breakPoints.portraitTablet * percentBaseResponsive}rem) {
    :first-of-type div {
      max-width: initial;
    }
  }
`;

export const Tbody = styled.tbody<displayProps>`
  ${({ show }) =>
    !show &&
    css`
      display: none;
    `}
`;

export const Cell = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${spaces.xs}rem;
  height: 5.7rem;
  transition: 0.2s;
`;

export const DamagesCell = styled(Cell)`
  height: 8rem;
`;

export const TextCell = styled(Typography)``;

export const InputCell = styled.input<inputProps>`
  width: 100%;
  max-width: 13rem;
  padding: 0 1rem;
  height: 3.3rem;
  border: ${({ borderError }) => (borderError ? `0.1rem solid red` : 'none')};
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type='number'] {
    -moz-appearance: textfield;
    appearance: textfield;
  }
  :focus {
    outline: none;
  }
  ::placeholder {
    color: ${colors.greySoftLight};
  }
  :disabled {
    background-color: ${colors.greyVerySoft};
  }
  transition: 0.2s;
`;

export const TdTextArea = styled.td`
  :first-of-type div {
    padding: 0 2rem;
  }
`;

export const DamagesTextArea = styled.textarea`
  padding: 0.4rem 1rem;
  width: 100%;
  border: none;
  resize: none;
`;

export const ContainerTrailerHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 2rem;
`;

export const Icon = styled.img`
  cursor: pointer;
  height: 1.6rem;
  width: 1.6rem;
`;

export const IconContainer = styled.div`
  display: flex;
  border-left: 0.1rem solid grey;
  padding: 0rem 1.5rem;
`;

export const IconsContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const HeaderVehiclesContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StyledDoubleSwitch = styled(DoubleSwitch)`
  ${SwitchDouble} {
    &:before {
      background: ${colors.black};
    }
  }
`;

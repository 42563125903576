import React from 'react';

type ArrowBottomProps = {
  className?: string;
};

export const ArrowBottom = ({ className }: ArrowBottomProps) => {
  return (
    <svg
      width="11"
      height="8"
      viewBox="0 0 11 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M5.89989 7.4672C5.69989 7.73368 5.30011 7.73368 5.10011 7.4672L0.471386 1.30014C0.224006 0.970546 0.459175 0.5 0.871279 0.5L10.1287 0.500001C10.5408 0.500001 10.776 0.970547 10.5286 1.30014L5.89989 7.4672Z"
        fill="#721C34"
      />
    </svg>
  );
};

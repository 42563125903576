import { takeBase } from '../../../components/display/tables/types';
import { SET_TAKE } from '../../actions/ui/takeTables';

const initialState = {
  take: takeBase,
};

const reducer = (
  state = initialState,
  action: { type: string; payload: number }
) => {
  switch (action.type) {
    case SET_TAKE:
      return {
        ...state,
        take: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;

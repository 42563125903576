import styled from 'styled-components';

// Styles
import {
  breakPoints,
  percentBaseResponsive,
  spaces,
} from '../../../styles/spaces';

export const Container = styled.div`
  display: flex;
  padding: 0;
  width: 100%;
  overflow-x: clip;
  overflow-y: visible;
  flex-direction: column;
`;

export const BodyContainer = styled.div`
  display: flex;
  padding: ${spaces.l}rem ${spaces.xxl}rem;
  flex-direction: column;

  @media (max-width: ${breakPoints.landscapeTablet *
    percentBaseResponsive}rem) {
    padding: ${spaces.l}rem;
  }
`;

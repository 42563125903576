import React, {
  useState,
  useMemo,
  useRef,
  useCallback,
  useEffect,
} from 'react';

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  Row,
  useReactTable,
} from '@tanstack/react-table';

// Types
import {
  ExternalCompaniesSelectionType,
  columnNames,
  ExternalProps,
  cellExternalCompaniesTypes,
} from './types';
import { ImperativeRefInput } from '../../../../inputs/DropDown/DropDownInput/types';

import { OptionSelected } from '../../../../inputs/DropDown/DropDownList/types';

// Styled Components
import {
  Table,
  Tbody,
  Thead,
  Tr,
} from '../../summaryTables/SummaryTablesStyles';
import {
  TdStyled,
  InputNumber,
  ContainerFilters,
  Container,
  StyledTableContainer,
  Img,
  SubContainer,
  ContainerInputCompanies,
  SubContainerFilters,
  ThStyled,
  TRBody,
} from './styles';

// Components
import InputText from '../../../../inputs/InputText';
import { MainButton } from '../../../../inputs/buttons/MainButton';
import { Typography } from '../../../Typography';
import DropDownInput from '../../../../inputs/DropDown/DropDownInput';

// Icons
import RemoveIcon from '../../../../../assets/icons/remove-file-icon.svg';

// Mocks
import { onChangeValue } from '../../../../inputs/DropDown/DropDownSelectGroup/types';
import {
  compressSessionStorage,
  decompressSessionStorage,
} from '../../../../../utils/sessionStorage';
import { externalCompaniesNaming } from './constants';

export const ExternalCompaniesSelectionTable = ({
  defaultValues,
  setValue,
  getValues,
  setDefaultExternals,
  externalCompanies,
  ...props
}: ExternalProps) => {
  const [data, setData] = useState<ExternalCompaniesSelectionType[]>(
    defaultValues ?? []
  );
  const [selectedOption, setSelectedOption] = useState<OptionSelected>();
  const companies = externalCompanies.map((company) => ({
    value: company.id,
    label: company.companyName,
  }));
  const [newCompanyInputState, setNewCompanyInputState] =
    useState<boolean>(true);

  const companyRef = useRef<ImperativeRefInput>(null);
  const newCompanyRef = useRef<HTMLInputElement>(null);
  const operatorsNumberRef = useRef<HTMLInputElement>(null);

  const companiesColumnHelper =
    createColumnHelper<ExternalCompaniesSelectionType>();

  const columns = useMemo(
    () => [
      companiesColumnHelper.accessor((row) => row.company, {
        id: columnNames.company.id,
        header: () => columnNames.company.label,
        cell: (info) => info.getValue(),
      }),

      companiesColumnHelper.accessor((row) => row.operatorsNumber, {
        id: columnNames.operatorsNumber.id,
        header: () => columnNames.operatorsNumber.label,
      }),
      companiesColumnHelper.accessor((row) => row.remove, {
        id: columnNames.remove.id,
        header: () => (
          <Typography colorText="white" size="S">
            -
          </Typography>
        ),
        cell: ({ row }) => (
          <Img src={RemoveIcon} onClick={() => removeRow(row)} />
        ),
      }),
    ],
    []
  );

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });

  const addData = useCallback(() => {
    const newRow: ExternalCompaniesSelectionType = {
      company: !newCompanyInputState
        ? newCompanyRef.current?.value
        : selectedOption?.label,
      operatorsNumber: operatorsNumberRef.current?.value,
      id: selectedOption?.value,
    };
    if (
      selectedOption !== undefined &&
      operatorsNumberRef.current?.value !== '' &&
      parseInt(operatorsNumberRef.current?.value as string) > 0 &&
      data.some((item) => item.company === selectedOption?.label)
    ) {
      const previousValues = getValues('externals');
      const idToChange =
        previousValues.findIndex(
          (item) => item.company === selectedOption.label
        ) ?? {};

      previousValues[idToChange].operatorsNumber =
        operatorsNumberRef.current?.value;
      setValue('externals', previousValues);
      setData(previousValues);
    }

    if (newRow.id === 'new_company' && newCompanyRef.current?.value === '') {
      return;
    }

    if (
      (companies.some((company) => company.label === newRow.company) &&
        newRow.id === 'new_company') ||
      data.some((company) => company.company === newRow.company)
    ) {
      const previousValues = getValues('externals');
      const idToChange =
        previousValues.findIndex((item) => item.company === newRow.company) ??
        {};

      previousValues[idToChange]?.operatorsNumber &&
        (previousValues[idToChange].operatorsNumber = newRow?.operatorsNumber);
      setValue('externals', previousValues);
      setData(previousValues);
      return;
    }
    if (
      selectedOption !== undefined &&
      operatorsNumberRef.current?.value !== '' &&
      parseInt(operatorsNumberRef.current?.value as string) > 0 &&
      !data.some((item) => item.company === selectedOption?.label)
    ) {
      setData((prevData) => {
        const newData = [...prevData, newRow];
        return newData;
      });
      setValue('externals', [...data, newRow]);

      if (companyRef) companyRef.current?.clearSelected();
      if (newCompanyRef.current) newCompanyRef.current.value = '';
      if (operatorsNumberRef.current) operatorsNumberRef.current.value = '';
      setNewCompanyInputState(true);
      const currentPath = window.location.pathname.split('/');

      if (currentPath.some((item) => item === 'actions-validation')) {
        const previousWorksToAdd = sessionStorage.getItem(
          externalCompaniesNaming.EXTERNAL_WORKS_TO_ADD
        );

        let previousWorkToAddParsed;

        if (previousWorksToAdd) {
          previousWorkToAddParsed = JSON.parse(
            decompressSessionStorage(previousWorksToAdd as string)
          );
        }

        const itemsToAdd = previousWorkToAddParsed
          ? [newRow, ...previousWorkToAddParsed]
          : [newRow];

        sessionStorage.setItem(
          externalCompaniesNaming.EXTERNAL_WORKS_TO_ADD,
          compressSessionStorage(JSON.stringify(itemsToAdd))
        );
      }
    }
  }, [selectedOption, data]);

  const removeRow = useCallback((row: Row<ExternalCompaniesSelectionType>) => {
    setData((prevData) => {
      const newData = [...prevData];
      const dataFilter = newData.filter(
        (company) => company.company !== row.original.company
      );
      setValue(`externals`, dataFilter);
      return dataFilter;
    });
    if (row.original.workId) {
      const previousWorksToDelete = sessionStorage.getItem(
        externalCompaniesNaming.EXTERNAL_WORKS_TO_DELETE
      );

      let previousWorksToDeleteParsed;

      if (previousWorksToDelete) {
        previousWorksToDeleteParsed = JSON.parse(
          decompressSessionStorage(previousWorksToDelete as string)
        );
      }

      const idsToDelete = previousWorksToDeleteParsed
        ? [row.original.workId, ...previousWorksToDeleteParsed]
        : [row.original.workId];

      sessionStorage.setItem(
        externalCompaniesNaming.EXTERNAL_WORKS_TO_DELETE,
        compressSessionStorage(JSON.stringify(idsToDelete))
      );
    }
  }, []);

  const handleSelectedCompany = useCallback((option: OptionSelected) => {
    if (option.value === 'new_company') {
      setNewCompanyInputState(false);
    } else {
      setNewCompanyInputState(true);
    }
    setSelectedOption(option);
  }, []);

  useEffect(() => {
    setDefaultExternals(data);
  }, [data]);

  return (
    <Container {...props}>
      <SubContainer>
        <Typography size="L" variant="semiBold">
          Afegir treball
        </Typography>
        <ContainerFilters>
          <ContainerInputCompanies>
            <DropDownInput
              ref={companyRef}
              inputSize="M"
              typeDropDown="Default"
              placeholder=""
              labelText="Empresa"
              options={[
                {
                  value: 'new_company',
                  label: 'Nova Empresa',
                },
                ...companies,
              ]}
              onChangeSelected={handleSelectedCompany as onChangeValue}
            />
            <InputText
              disabled={newCompanyInputState}
              ref={newCompanyRef}
              typeInput="normal"
              placeholder="Nom de l'empresa"
            />
          </ContainerInputCompanies>
          <SubContainerFilters>
            {/* <InputText
              ref={workRef}
              typeInput="normal"
              label="Treball"
              placeholder="Escriu aqui"
            /> */}

            <InputNumber
              ref={operatorsNumberRef}
              typeInput="normal"
              label="Nº OP"
              type={'number'}
              placeholder={'1'}
              defaultValue={'1'}
            />
            <MainButton type="button" text="⮕" onClick={addData} />
          </SubContainerFilters>
        </ContainerFilters>
      </SubContainer>
      <SubContainer>
        <Typography size="L" variant="semiBold">
          Empreses
        </Typography>
        <StyledTableContainer>
          <Table>
            <Thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <Tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <ThStyled
                      key={header.id}
                      cell={header.id as cellExternalCompaniesTypes}
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                    </ThStyled>
                  ))}
                </Tr>
              ))}
            </Thead>
            <Tbody>
              {table.getRowModel().rows.map((row) => (
                <TRBody key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <TdStyled key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TdStyled>
                  ))}
                </TRBody>
              ))}
            </Tbody>
          </Table>
        </StyledTableContainer>
      </SubContainer>
    </Container>
  );
};

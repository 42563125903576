// Dependencies
import { redirect } from 'react-router-dom';
import { ApolloQueryResult, FetchResult } from '@apollo/client';

//Types
import { Vehicle } from '../types';
import { OrderState, OrderType } from '../../../../../types/orders';

// Constants
import {
  directoryNaming,
  formAccidentNaming,
} from '../../../../../components/forms/AddBasicInfoAccidentForm/constants';

// GQL
import { client } from '../../../../../gql/client';
import { NEW_ACCIDENT } from '../../../../../gql/fragments/accident';
import { NEW_IMAGE } from '../../../../../gql/fragments/image';
import {
  CREATE_NEW_ACCIDENT,
  CREATE_NEW_ATTENTION_ACCIDENT,
  CREATE_NEW_IMAGE,
} from '../../../../../gql/mutations/accidents';

import { GET_ATTENTION_ACCIDENT_BY_ID } from '../../../../../gql/queries/accidents/attentionAccidents';
import {
  CREATE_NEW_ORDER,
  DELETE_ORDER_BY_ID,
} from '../../../../../gql/mutations/orders';
import { GET_ORDER_BY_ID } from '../../../../../gql/queries/orders/orders';
import {
  GET_VEHICLE_BY_ID,
  GET_VEHICLE_OCCUPANT_BY_ID,
} from '../../../../../gql/queries/vehicles';
import {
  CREATE_NEW_VEHICLE,
  NEW_VEHICLE_OCCUPANT_ACCIDENT,
} from '../../../../../gql/mutations/vehicles';

// Utils
import { UploadImage } from '../../../../../utils/image';
import { decompressSessionStorage } from '../../../../../utils/sessionStorage';
import { AccidentRoute } from '../../../../../types/route';
import { removeNewAccidentStorage } from '../../../../../utils/resetData';
import { NEW_ORDER } from '../../../../../gql/fragments/orders';
import {
  tableIds,
  takeBase,
} from '../../../../../components/display/tables/types';
import { setCursor } from '../../../../../state/actions/ui/cursorTables';
import { store } from '../../../../../state/configureStore';
import { truncateArrayAfterNull } from '../../../../../utils/mergeArrays';
import { getCursor } from '../../../../../state/selectors/ui/tables';
// Sentry
import * as Sentry from '@sentry/react';
export const saveData = async (
  formData: FormData,
  accidentRouteInner: string
) => {
  let orderId;
  const newAccidentForm = sessionStorage.getItem(
    formAccidentNaming.NEW_ACCIDENT_FORM
  );

  const accidentDataForm = JSON.parse(
    decompressSessionStorage(newAccidentForm as string)
  );
  try {
    const dispatch = store.dispatch;
    const cursorMultimedia = getCursor(tableIds.MULTIMEDIA)(store.getState());
    const cursorAccident = getCursor(tableIds.ACCIDENTS)(store.getState());

    const basicInfoAccidentForm = sessionStorage.getItem(
      formAccidentNaming.ADD_BASIC_INFO_ACCIDENT_FORM
    );

    const basicInfoAccidentDataForm = JSON.parse(
      decompressSessionStorage(basicInfoAccidentForm as string)
    );

    const creationDate = new Date().toISOString();

    const damages = formData.get('damages');

    const switchValue = JSON.parse(
      formData.get('involvedVehiclesSwitch') as string
    );

    const imageArray = await UploadImage(
      OrderType.Accident,
      directoryNaming.CANVAS
    );

    const vehicles: Vehicle[] = Object.values(
      JSON.parse(formData.get('vehicles') as string)
    );

    const orderInput = {
      type: OrderType.Accident,
      state: OrderState.Open,
      creationDate,
      registerInitDate: accidentDataForm.registerInitDate,
      description: accidentDataForm.description,
      classification: accidentDataForm.classification,
      capitol: accidentDataForm.capitol,
      subCapitol: accidentDataForm.subCapitol,
      currentExpeditionIndex: Number(
        (accidentDataForm.orderParentCotic as string).split('.')[2]
      ) as number,
      parentExpeditionOrderID: accidentDataForm.orderParentId as string,
    };

    const dataOrder = await client.mutate({
      mutation: CREATE_NEW_ORDER,
      variables: {
        input: orderInput,
      },
      update(cache, { data: { createOrder } }) {
        cache.modify({
          fields: {
            orders(variables = {}) {
              const newOrderRef = cache.writeFragment({
                data: createOrder,
                fragment: NEW_ORDER,
              });

              const truncatedArray = truncateArrayAfterNull(variables.orders);

              const newOrdersArray = [newOrderRef, ...truncatedArray];

              newOrdersArray.splice(-2, 1);

              const setCursorMultimedia = {
                ...cursorMultimedia,
                take: -takeBase,
                cursor: '',
              };

              if (variables?.orders?.length > 0) {
                dispatch(setCursor(tableIds.MULTIMEDIA, setCursorMultimedia));
              }

              return {
                ...variables,
                orders: newOrdersArray,
                totalCount: variables.totalCount + 1,
                pageInfo: {},
              };
            },
          },
        });
        cache.modify({
          id: `Order:${orderInput.parentExpeditionOrderID}`,
          fields: {
            childExpeditionOrderIDs(variables = [], { readField }) {
              const newOrderRef = cache.writeFragment({
                data: createOrder,
                fragment: NEW_ORDER,
              });
              const newId = readField('id', newOrderRef);
              return [...variables, newId];
            },
          },
        });
      },
    });

    const vehicleIDs: string[] = [];
    const vehicleOccupantIDs: string[] = [];
    let vehicleAndVehicleOccupantsIDs;
    if (switchValue) {
      vehicleAndVehicleOccupantsIDs = await Promise.all(
        vehicles.map(async (vehicle) => {
          let dataTrailer: FetchResult | undefined = undefined;
          const dataVehicle = await client.mutate({
            mutation: CREATE_NEW_VEHICLE,
            variables: {
              input: {
                plate: vehicle.registVehicle,
                model: vehicle.modelVehicle,
                color: vehicle.colorVehicle,
                insurance: vehicle.insurerVehicle,
                policyNumber: vehicle.policyVehicle,
                isInternal: false,
                isTrailer: false,
                isActive: false,
              },
            },
          });

          vehicleIDs.push(dataVehicle.data.createVehicle.id);

          const dataVehicleOccupant = await client.mutate({
            mutation: NEW_VEHICLE_OCCUPANT_ACCIDENT,
            variables: {
              input: {
                numberOccupants: parseInt(vehicle.occupantsVehicle) || 0,
                vehicleId: dataVehicle.data.createVehicle.id,
              },
            },
          });

          vehicleOccupantIDs.push(
            dataVehicleOccupant.data.createVehicleOccupantAccident.id
          );
          if (
            vehicle?.insurerTrailer ||
            vehicle?.registTrailer ||
            vehicle?.policyTrailer
          ) {
            dataTrailer = await client.mutate({
              mutation: CREATE_NEW_VEHICLE,
              variables: {
                input: {
                  plate: vehicle?.registTrailer,
                  insurance: vehicle?.insurerTrailer,
                  policyNumber: vehicle?.policyTrailer,
                  isInternal: false,
                  isTrailer: true,
                  parentTrailerRelationshipId:
                    dataVehicle.data.createVehicle.id,
                  isActive: false,
                },
              },
            });

            vehicleIDs.push(dataTrailer.data?.createVehicle.id);
          }

          return {
            vehicle: dataVehicle.data.createVehicle.id,
            vehicleTrailer: dataTrailer?.data?.createVehicle?.id ?? undefined,
            vehicleOccupant:
              dataVehicleOccupant.data.createVehicleOccupantAccident.id,
          };
        })
      );
    }

    orderId = dataOrder.data.createOrder.id;

    const dataAttentionAccident = await client.mutate({
      mutation: CREATE_NEW_ATTENTION_ACCIDENT,
      variables: {
        input: {
          noticeConservationCenterDate:
            basicInfoAccidentDataForm?.noticeConservationCenterDate,
          arrivalConservationCenterDate:
            basicInfoAccidentDataForm?.arrivalConservationCenterDate,
          departureConservationCenterDate:
            basicInfoAccidentDataForm?.departureConservationCenterDate,
          noticeSquadBoysDate: basicInfoAccidentDataForm?.noticeSquadBoysDate,
          arrivalSquadBoysDate: basicInfoAccidentDataForm?.arrivalSquadBoysDate,
          departureSquadBoysDate:
            basicInfoAccidentDataForm?.departureSquadBoysDate,
          noticeRoadTeamDate: basicInfoAccidentDataForm.noticeRoadTeamDate,
          arrivalRoadTeamDate: basicInfoAccidentDataForm.arrivalRoadTeamDate,
          departureRoadTeamDate:
            basicInfoAccidentDataForm.departureRoadTeamDate,
          noticeControlCenterDate:
            basicInfoAccidentDataForm?.noticeControlCenterDate,
          arrivalControlCenterDate:
            basicInfoAccidentDataForm?.arrivalControlCenterDate,
          departureControlCenterDate:
            basicInfoAccidentDataForm?.departureControlCenterDate,
          noticeAmbulanceCraneAssistanceDate:
            basicInfoAccidentDataForm?.noticeAmbulanceCraneAssistanceDate,
          arrivalAmbulanceCraneAssistanceDate:
            basicInfoAccidentDataForm?.arrivalAmbulanceCraneAssistanceDate,
          departureAmbulanceCraneAssistanceDate:
            basicInfoAccidentDataForm?.departureAmbulanceCraneAssistanceDate,
          otherName: basicInfoAccidentDataForm?.otherName,
          noticeOtherDate: basicInfoAccidentDataForm?.noticeOtherDate,
          arrivalOtherDate: basicInfoAccidentDataForm?.arrivalOtherDate,
          departureOtherDate: basicInfoAccidentDataForm?.departureOtherDate,
        },
      },
    });

    await Promise.all(
      imageArray.map(
        async (image) =>
          await client.mutate({
            mutation: CREATE_NEW_IMAGE,
            variables: {
              input: {
                name: image.name,
                creatingDate: image.creatingDate,
                cloudinaryPublicId: image.cloudinaryPublicId,
                orderId: dataOrder.data.createOrder.id,
              },
            },
            update(cache, { data: { createImage } }) {
              cache.modify({
                fields: {
                  images(existingImages = []) {
                    const newImageRef = cache.writeFragment({
                      data: createImage,
                      fragment: NEW_IMAGE,
                    });
                    return [...existingImages, newImageRef];
                  },
                },
              });
            },
          })
      )
    );

    const pkInitArray = accidentDataForm.pkInit.replace(/ /g, '').split('+');
    const pkEndArray = accidentDataForm.pkEnd.replace(/ /g, '').split('+');

    await client.mutate({
      mutation: CREATE_NEW_ACCIDENT,
      variables: {
        input: {
          laneCutting: basicInfoAccidentDataForm.laneCutting,
          policeReport: basicInfoAccidentDataForm.policeReport,
          policeReportNumber: basicInfoAccidentDataForm.policeReportNumber,
          roadConditionDescription:
            basicInfoAccidentDataForm.roadConditionDescription,
          orderId: dataOrder.data.createOrder.id,
          surveillanceBodyId: basicInfoAccidentDataForm.surveillanceBody,
          mediumKnowledgeId: basicInfoAccidentDataForm.mediumKnowledge,
          weatherId: basicInfoAccidentDataForm.weather,
          surfaceConditionId: basicInfoAccidentDataForm.surfaceCondition,
          luminosityId: basicInfoAccidentDataForm.luminosity,
          conditionsDrivingId: basicInfoAccidentDataForm.conditionsDriving,
          concessionId: accidentDataForm.concession,
          roadId: accidentDataForm.road,
          track: accidentDataForm.track,
          direction: accidentDataForm.direction,
          margin: accidentDataForm.margin,
          lane: accidentDataForm.lane,
          pkInitKm: Number(pkInitArray[0]),
          pkInitMeter: Number(pkInitArray[1]),
          pkEndKm: Number(pkEndArray[0]),
          pkEndMeter: Number(pkEndArray[1]),
          vehicleOccupantRelationships: vehicleOccupantIDs,
          vehicleRelationships: vehicleIDs,
          attentionAccidentId:
            dataAttentionAccident.data.createAttentionAccident.id,
          damages,
          creationDate,
          registerInitDate: accidentDataForm.registerInitDate,
        },
      },
      update(cache, { data: { createAccident } }) {
        cache.modify({
          fields: {
            accidents(variables = {}) {
              const newAccidentRef = cache.writeFragment({
                data: createAccident,
                fragment: NEW_ACCIDENT,
              });

              const truncatedArray = truncateArrayAfterNull(
                variables.accidents
              );

              const newAccidentArray = [newAccidentRef, ...truncatedArray];

              newAccidentArray.splice(-2, 1);

              const setCursorAccident = {
                ...cursorAccident,
                take: -takeBase,
                cursor: '',
              };

              if (variables?.accidents?.length > 0) {
                dispatch(setCursor(tableIds.ACCIDENTS, setCursorAccident));
              }
              return {
                ...variables,
                accidents: newAccidentArray,
                totalCount: variables.totalCount + 1,
                pageInfo: {},
              };
            },
          },
        });
      },
    });

    const unresolvedPromiseVehicles: Promise<ApolloQueryResult<object>>[] = [];

    (vehicleAndVehicleOccupantsIDs || []).forEach(
      (vehicleAndVehicleOccupantId) => {
        unresolvedPromiseVehicles.push(
          client.query({
            fetchPolicy: 'network-only',
            query: GET_VEHICLE_BY_ID,
            variables: { id: vehicleAndVehicleOccupantId.vehicle },
          })
        );

        if (vehicleAndVehicleOccupantId.vehicleTrailer) {
          unresolvedPromiseVehicles.push(
            client.query({
              fetchPolicy: 'network-only',
              query: GET_VEHICLE_BY_ID,
              variables: { id: vehicleAndVehicleOccupantId.vehicleTrailer },
            })
          );
        }

        unresolvedPromiseVehicles.push(
          client.query({
            fetchPolicy: 'network-only',
            query: GET_VEHICLE_OCCUPANT_BY_ID,
            variables: { id: vehicleAndVehicleOccupantId.vehicleOccupant },
          })
        );
      }
    );

    await Promise.all([
      client.query({
        fetchPolicy: 'network-only',
        query: GET_ORDER_BY_ID,
        variables: { id: dataOrder.data.createOrder.id },
      }),
      ...unresolvedPromiseVehicles,
      client.query({
        fetchPolicy: 'network-only',
        query: GET_ATTENTION_ACCIDENT_BY_ID,
        variables: {
          id: dataAttentionAccident.data.createAttentionAccident.id,
        },
      }),
    ]);

    removeNewAccidentStorage();
    return redirect(
      `${
        accidentRouteInner === AccidentRoute.CAMPAIGN ? '../' : ''
      }../../../accidents/${dataOrder.data.createOrder.id}`
    );
  } catch (error) {
    if (orderId) {
      await client.mutate({
        mutation: DELETE_ORDER_BY_ID,
        variables: {
          id: orderId,
        },
      });

      await client.query({
        query: GET_ORDER_BY_ID,
        variables: {
          id: accidentDataForm.orderParentId,
        },
        fetchPolicy: 'network-only',
      });
    }
    Sentry.captureException(error);
    console.log('Error save Accident:', error);
  }
  return null;
};

export const machineryMocksData = [
  {
    id: '1',
    auxMachineId: 'M00001',
    name: 'Equip de talls oxiacetilènic',
    type: 'MAQ. AUXILIAR',
    typeOfMeasurement: 'H',
    machineryUnitIDs: [],
  },
  {
    id: '2',
    auxMachineId: 'M00002',
    name: 'Aglomerat en fred en pots de 25 Kg',
    type: 'MAQUINARIA O.P.',
    typeOfMeasurement: 'H',
    machineryUnitIDs: [],
  },
  {
    id: '3',
    auxMachineId: 'M00003',
    name: 'Lloguer Grup electrògen mobil',
    type: 'MAQ. AUXILIAR',
    typeOfMeasurement: 'H',
    machineryUnitIDs: [],
  },
  {
    id: '4',
    auxMachineId: 'M00004',
    name: 'Lloguer Compressor dos martells 20-30 kg',
    type: 'MAQUINARIA O.P.',
    typeOfMeasurement: 'H',
    machineryUnitIDs: [],
  },
];

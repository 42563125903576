import { gql } from '@apollo/client';

export const GET_POSITION_ACTIONS = gql`
  query GeoPositionAction($id: ID!) {
    geoPositionAction(id: $id) {
      id
      pkInitKm
      pkInitMeter
      pkEndKm
      pkEndMeter
      track
      direction
      margin
      lane
      latitude
      longitude
      roadIDs
      concessionIDs
      actionIDs
    }
  }
`;

// Dependencies
import React, { useMemo } from 'react';

import {
  useReactTable,
  getCoreRowModel,
  createColumnHelper,
  flexRender,
} from '@tanstack/react-table';

// Types
import { MaterialsType, TableProps, columnNames } from './types';

// Mocks
import { mockData } from './mocks';

// Styles
import {
  Container,
  TableContainer,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '../SummaryTablesStyles';

export const MaterialsTable = ({ data, ...props }: TableProps): JSX.Element => {
  const dataAll = data ? data : mockData;

  const columnHelper = createColumnHelper<MaterialsType>();

  const columns = useMemo(
    () => [
      columnHelper.accessor((row) => row.codiMat, {
        id: columnNames.codiMat.id,
        header: () => columnNames.codiMat.label,
      }),
      columnHelper.accessor((row) => row.material, {
        id: columnNames.material.id,
        header: () => columnNames.material.label,
      }),
      columnHelper.accessor((row) => row.ut, {
        id: columnNames.ut.id,
        header: () => columnNames.ut.label,
      }),
      columnHelper.accessor((row) => row.um, {
        id: columnNames.um.id,
        header: () => columnNames.um.label,
      }),
    ],
    []
  );

  const table = useReactTable({
    data: dataAll,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <Container>
      <TableContainer>
        <Table {...props}>
          <Thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <Th key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {table.getRowModel().rows.map((row) => (
              <Tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <Td key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Td>
                ))}
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Container>
  );
};

import React, { useLayoutEffect } from 'react';
import { useAsyncValue, useNavigate, useRevalidator } from 'react-router-dom';
import { format } from 'date-fns';
import { useDispatch } from 'react-redux';

// Types
import { NewCommunication } from '../../../../../../types/communication';
import { OrderType } from '../../../../../../types/orders';

// Components
import ContentCardResume from '../../../../../../components/display/ContentCardResume';

// Styles
import { Container } from './styles';

//Redux
import { coticOTSelected } from '../../../../../../state/actions/ui/coticOTSelectedGeneralView';

export const CommunicationSummaryGeneralView = (): JSX.Element => {
  const loaderData = useAsyncValue() as NewCommunication;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const revalidator = useRevalidator();

  useLayoutEffect(() => {
    if (!loaderData) navigate('..');
    dispatch(
      coticOTSelected(
        OrderType.Communication,
        loaderData.cotic.join('.') as unknown as string,
        loaderData.id as string
      )
    );
  }, [loaderData]);

  const reftechData = () => {
    revalidator.revalidate();
  };

  return (
    <Container>
      {loaderData && (
        <ContentCardResume
          title="Resum de la comunicació"
          cotic={loaderData.cotic as string[]}
          operator={loaderData.operator}
          data={format(
            new Date(loaderData.registerInitDate as string),
            'dd / MM / yyyy'
          )}
          hour={format(
            new Date(loaderData.registerInitDate as string),
            'HH:mm'
          )}
          groupInter={loaderData.groupInter}
          media={loaderData.media}
          sendStatus={loaderData.sendStatus}
          capitol={loaderData.capitol}
          subCapitol={loaderData.subCapitol}
          classification={loaderData.classification}
          team={loaderData.team}
          concession={loaderData.concession}
          road={loaderData.road}
          pkInit={`${loaderData.pkInit} km`}
          pkEnd={`${loaderData.pkEnd} km`}
          track={loaderData.track}
          roadDirections={loaderData.roadDirections}
          direction={loaderData.direction}
          city={loaderData.city}
          lane={loaderData.lane}
          margin={loaderData.margin}
          leftButton={'Tornar'}
          onEditCommunication={() => navigate('..')}
          description={loaderData.description}
          storedDataOTSelected={loaderData.storedDataOTSelected}
          changeStateTag={true}
          revalidatorFunction={reftechData}
          state={loaderData.state}
          orderId={loaderData.parentOrderId?.[0]}
        />
      )}
    </Container>
  );
};

import { useAsyncValue, useNavigate, useSubmit } from 'react-router-dom';
import React, { useCallback, useLayoutEffect } from 'react';
import { useDispatch } from 'react-redux';

// Components
import AddVehicleForm from '../../../../components/forms/AddVehicleForm';

// Utils
import { createFormDataFromValues } from '../../../../utils/forms';

// Constants
import { formVehicleNaming } from '../../../../components/forms/AddVehicleForm/constants';

// Styles
import { Container } from './styles';

// Types
import {
  type AddBasicInfoAccidentLoaderWithDynamicTypes,
  type AddBasicInfoAccidentWithDynamicKeys,
} from './types';
import { OrderType } from '../../../../types/orders';

// Redux
import { coticOTSelected } from '../../../../state/actions/ui/coticOTSelectedGeneralView';
export default function AddVehiclePage(): JSX.Element {
  const navigate = useNavigate();
  const loaderData =
    useAsyncValue() as AddBasicInfoAccidentLoaderWithDynamicTypes;
  const dispatch = useDispatch();

  const submit = useSubmit();

  const handleReturn = useCallback(() => {
    navigate(-1);
  }, []);

  useLayoutEffect(() => {
    if (!loaderData) {
      navigate('..');
    } else {
      dispatch(
        coticOTSelected(
          OrderType.Accident,
          loaderData.orderParentCotic,
          loaderData.orderParentId
        )
      );
    }
  }, []);

  const onFormSubmit = async (values: AddBasicInfoAccidentWithDynamicKeys) => {
    const formData = createFormDataFromValues({
      ...values,
      vehicles: JSON.stringify(values.vehicles),
    });

    submit(formData, {
      action: '.',
      method: 'POST',
    });
  };

  return (
    <Container>
      <AddVehicleForm
        onFormSubmit={onFormSubmit}
        namingPersistForm={formVehicleNaming.NEW_VEHICLE_FORM}
        onReturn={handleReturn}
        initialData={loaderData}
      />
    </Container>
  );
}

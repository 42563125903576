import React from 'react';

type TextIconProps = {
  className?: string;
};

export const Text = ({ className }: TextIconProps) => {
  return (
    <svg
      width="14"
      height="16"
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M12.3552 0V1.39787L8.03272 1.39787L8.03272 13.1665L5.96248 13.1665L5.96248 1.39787L1.61725 1.39787L1.61725 0L12.3552 0Z"
        fill="color"
      />
      <path
        d="M0.84375 15.0555L13.1514 15.0555V16L0.84375 16L0.84375 15.0555Z"
        fill="color"
      />
    </svg>
  );
};

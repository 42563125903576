import { defer, LoaderFunction } from 'react-router-dom';

// Sentry
import * as Sentry from '@sentry/react';

// GraphQL
import {
  FETCH_AUX_MACHINE_TYPE_BY_ID,
  FETCH_AUX_MACHINE_TYPES,
  FETCH_AUX_MACHINES,
} from '../../../../../gql/queries/actions/auxMachines';
import { client } from '../../../../../gql/client';
import { AuthContextType } from '../../../../../hooks/useAuth';
import { selectMachineriesValue } from '../../../../../gql/selectors/actions';

// Constants
import {
  formActionNaming,
  formActionValidationNaming,
} from '../../../../../components/forms/ActionForms/constants';

// Utils
import { decompressSessionStorage } from '../../../../../utils/sessionStorage';
import { GET_MEASURE_TYPES_BY_ID } from '../../../../../gql/queries/actions/measureTypes';
import { GET_ORDER_BY_ID } from '../../../../../gql/queries/orders/orders';

const loadFormValues = async (
  userData: AuthContextType | null,
  actionId: string
) => {
  if (userData?.user) {
    try {
      const formValues = await selectMachineriesValue(actionId);
      return formValues;
    } catch (err) {
      Sentry.captureException(err);
      console.log(`> Error loading machinery values`, err);
    }
  }

  return {};
};

export const MachineryValidationLoader: (
  userData: AuthContextType | null
) => LoaderFunction | undefined = (userData) => async () => {
  const stringifyForm = sessionStorage.getItem(
    formActionValidationNaming.NEW_VALIDATION_MACHINERY_FORM
  );

  const stringifyActionData = sessionStorage.getItem(
    formActionNaming.NEW_VALIDATION_ACTION_DATA
  );

  let sessionStorageData;
  let actionData;
  let parentExpeditionOrderStatus;
  let state;

  if (stringifyForm) {
    sessionStorageData = JSON.parse(decompressSessionStorage(stringifyForm));
  }

  if (stringifyActionData) {
    actionData = JSON.parse(decompressSessionStorage(stringifyActionData));
    const {
      data: { order },
    } = await client.query({
      query: GET_ORDER_BY_ID,
      variables: {
        id: actionData.orderId,
      },
    });
    const parentOrder = await client.query({
      query: GET_ORDER_BY_ID,
      variables: {
        id: order.parentExpeditionOrderIDs[0],
      },
    });
    parentExpeditionOrderStatus = parentOrder.data.order.state;
    state = order.state;
  }

  const {
    data: { auxMachines: auxMachineUnsorted },
  } = await client.query({
    query: FETCH_AUX_MACHINES,
  });

  await client.query({
    query: FETCH_AUX_MACHINE_TYPES,
  });

  const auxMachinesData = await Promise.all(
    auxMachineUnsorted.map(
      async (machine: {
        id: string;
        auxMachineId: string;
        description: string;
        type: string;
        typeOfMeasurement: string;
        measureTypeIDs: string[];
        auxMachineTypeIDs: string[];
      }) => {
        const [
          {
            data: { measureType },
          },
          {
            data: { auxMachineType },
          },
        ] = await Promise.all([
          client.query({
            query: GET_MEASURE_TYPES_BY_ID,
            variables: {
              id: machine?.measureTypeIDs[0],
            },
            fetchPolicy: 'cache-only',
          }),
          client.query({
            query: FETCH_AUX_MACHINE_TYPE_BY_ID,
            variables: {
              id: machine?.auxMachineTypeIDs[0],
            },
            fetchPolicy: 'cache-only',
          }),
        ]);
        return {
          id: machine.id,
          auxMachineId: machine.auxMachineId,
          description: machine.description,
          type: auxMachineType.name,
          typeOfMeasurement: measureType.name,
        };
      }
    )
  );
  const auxMachines = [...auxMachinesData].sort((a, b) =>
    a.auxMachineId.localeCompare(b.auxMachineId)
  );
  const loaderToResolve = await loadFormValues(userData, actionData.id);
  const combinedData = {
    ...loaderToResolve,
    ...sessionStorageData,
    ...actionData,
    parentExpeditionOrderStatus,
    state,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    machines: auxMachines.map((auxMachine: any) => ({
      ...auxMachine,
      auxMachineUnitId: null,
    })),
    dataFromServer: { ...loaderToResolve, machines: auxMachines },
  };

  return defer({
    data: combinedData,
  });
};

export const activeVehiclesMocksData = [
  {
    id: '1',
    plate: 'AAASSBB',
    model: 'Nissan',
    isInternal: true,
    category: 'ex blanc',
    isTrailer: false,
    isActive: true,
  },
  {
    id: '2',
    plate: 'SSSBB',
    model: 'Nissan',
    isInternal: true,
    category: 'ex blanc',
    isTrailer: false,
    isActive: true,
  },
  {
    id: '3',
    plate: 'MNBJS452',
    model: 'Ford',
    isInternal: true,
    category: 'ex blanc',
    isTrailer: false,
    isActive: true,
  },
  {
    id: '4',
    plate: 'MXNS93',
    model: 'Peugeot',
    isInternal: true,
    category: 'ex blanc',
    isTrailer: false,
    isActive: true,
  },
];

export const inactiveVehiclesMocksData = [
  {
    id: '1',
    plate: 'AAASSBB',
    model: 'Nissan',
    isInternal: true,
    category: 'ex blanc',
    isTrailer: false,
    isActive: false,
  },
  {
    id: '2',
    plate: 'SSSBB',
    model: 'Nissan',
    isInternal: true,
    category: 'ex blanc',
    isTrailer: false,
    isActive: false,
  },
  {
    id: '3',
    plate: 'MNBJS452',
    model: 'Ford',
    isInternal: true,
    category: 'ex blanc',
    isTrailer: false,
    isActive: false,
  },
  {
    id: '4',
    plate: 'MXNS93',
    model: 'Peugeot',
    isInternal: true,
    category: 'ex blanc',
    isTrailer: false,
    isActive: false,
  },
];

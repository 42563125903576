import styled from 'styled-components';
import { Link } from 'react-router-dom';

// Styles utils
import { colors } from '../../../styles/colors';
import { breakPoints, percentBaseResponsive } from '../../../styles/spaces';
import { fontSize } from '../../../styles/fonts';
import { spaces } from '../../../styles/spaces';

// Components
import { MainButton } from '../../inputs/buttons/MainButton';
import { Text } from '../../inputs/buttons/MainButton/styles';
import { Typography } from '../Typography';
import { Container as ContainerButton } from '../../inputs/buttons/MainButton/styles';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.8rem;
  max-width: 23.6rem;
  max-height: 28.4rem;
  box-shadow: 0.1rem 0.3rem 1.5rem rgba(0, 0, 0, 0.12);
  border-radius: 1rem;
  padding: ${spaces.m}rem 3rem ${spaces.m}rem ${spaces.m}rem;
  background-color: ${colors.white};
  width: 100%;
  align-self: flex-end;
  position: absolute;
  z-index: 999999;
  margin-top: 5.8rem;

  @media (max-width: ${breakPoints.landscapeTablet *
    percentBaseResponsive}rem) {
    max-width: 24.9rem;
    max-height: 28.8rem;
  }
`;

export const ContainerHeader = styled.div`
  display: flex;
  align-items: center;
  gap: ${spaces.xxs}rem;
`;

export const HorizontalLine = styled.div`
  border-bottom: 0.1rem solid ${colors.greySoftLight};
  max-width: 18.1rem;
`;

export const ContainerUser = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  @media (max-width: ${breakPoints.landscapeTablet *
    percentBaseResponsive}rem) {
    gap: 0.4rem;
  }
`;

export const ContainerUserNameRole = styled.div``;

export const ButtonSession = styled(MainButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  width: 14.9rem;
  padding: 0;

  @media (max-width: ${breakPoints.landscapeTablet *
    percentBaseResponsive}rem) {
    &${ContainerButton} {
      height: 4rem;
    }
  }

  ${Text} {
    @media (max-width: ${breakPoints.landscapeTablet *
      percentBaseResponsive}rem) {
      font-size: ${fontSize.S};
    }
  }
`;

export const ContainerButtonPolicy = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
`;

export const LinkPolicy = styled(Link)`
  text-decoration: none;
`;

export const TextSection = styled(Typography)`
  font-size: 1.2rem;
  @media (max-width: ${breakPoints.landscapeTablet *
    percentBaseResponsive}rem) {
    font-size: ${fontSize.XXXS};
  }
`;

export const PolicyVersionContainer = styled.div``;

export const EmailText = styled(Typography)`
  overflow: hidden;
  text-overflow: ellipsis;
`;

import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';

// Components Styled
import { Container, ContainerIframe, Iframe, SubContainer } from './styles';
import {
  DropDownSelectStyled,
  NavContainer,
} from '../comunications/CommunicationsLayout/styles';

// Components
import { BreadCrumbs } from '../../../components/navigation/Breadcrumbs';

// Icons
import HomeIcon from '../../../assets/icons/home.svg';

// Routes utils
import { options } from '../../../router/definitionGloblaRoutes';
import { createRoutes } from '../../../router/labelToRoutes';

// Media
import { useIsSmallDesktop } from '../../../hooks/useMediaBreakPoints';
import { Role } from '../../../types/auth';
import { AuthContext } from '../../../hooks/useAuth';
import { filterAllowedRoutes } from '../../../router/utils';
import { OptionSelected } from '../../../components/inputs/DropDown/DropDownList/types';

export default function DashboardPage(): JSX.Element {
  const location = useLocation();
  const routes = location.pathname.split('/');
  routes?.shift();

  const routesBreadCrumb = createRoutes(routes, [
    {
      content: <img alt="Pagina principal" src={HomeIcon} />,
      path: '/',
      pathAbsolute: '/',
    },
  ]);

  const isSmallDesktop = useIsSmallDesktop();
  const userRole: Role | undefined = useContext(AuthContext)?.user?.user
    ?.rolActive as Role | undefined;

  const optionsFiltered = userRole
    ? filterAllowedRoutes(options, userRole)
    : options;
  return (
    <Container>
      <BreadCrumbs data={routesBreadCrumb} />
      <SubContainer isVertical={routes[0] === 'dashboard'}>
        <NavContainer>
          {isSmallDesktop ? null : (
            <>
              <DropDownSelectStyled
                options={optionsFiltered as OptionSelected[]}
                defaultOption={routes[0]}
              />
            </>
          )}
        </NavContainer>
        <ContainerIframe>
          <Iframe
            src="https://app.powerbi.com/view?r=eyJrIjoiYjI2YmVlOTMtZWM0OC00MmM2LWJlZjQtMTcxYTY0YmM5M2M3IiwidCI6ImQ4NmM3ZDc3LTNlY2ItNDhlOC05ODcxLWNiNDQzNWEzZjc3YyIsImMiOjl9"
            frameBorder="0"
            allowFullScreen
          />
        </ContainerIframe>
      </SubContainer>
    </Container>
  );
}

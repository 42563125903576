// Styled Component
import styled from 'styled-components';

//Dependencies
import { Link as RouterLink } from 'react-router-dom';

// Components
import { ButtonRight } from '../../../assets/icons/ButtonRight';

// Spaces
import {
  breakPoints,
  percentBaseResponsive,
  spaces,
} from '../../../styles/spaces';

// Styles utils
import { colors } from '../../../styles/colors';

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  gap: ${spaces.s}rem 0;
`;

export const Item = styled.div`
  display: flex;
  flex-direction: row;
  padding-right: 1rem;
  align-items: center;

  svg > path {
    fill: ${colors.greyDark};
  }

  @media (max-width: ${breakPoints.landscapeTablet *
    percentBaseResponsive}rem) {
    padding-right: ${spaces.xs}rem;
  }
`;

export const Link = styled(RouterLink)`
  text-decoration: none;
  display: flex;
  align-items: center;
  img {
    width: 1.6rem;
    height: 1.4rem;
  }
  p {
    margin-bottom: -0.2rem;
  }

  @media (max-width: ${breakPoints.landscapeTablet *
    percentBaseResponsive}rem) {
    img {
      width: 1.057rem;
      height: 0.925rem;
    }
  }
`;

export const RightButton = styled(ButtonRight)`
  width: 0.5rem;
  height: 0.9rem;
  margin-left: 1rem;
  @media (max-width: ${breakPoints.landscapeTablet *
    percentBaseResponsive}rem) {
    width: 0.33rem;
    height: 0.595rem;
    margin-left: 0.7rem;
  }
`;

import styled from 'styled-components';

// Styles generals
import {
  spaces,
  breakPoints,
  percentBaseResponsive,
} from '../../../../styles/spaces';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spaces.xl}rem;
  width: calc(100% - 40rem);
  @media (max-width: ${breakPoints.smallDesktop * percentBaseResponsive}rem) {
    width: 100%;
  }
`;

export const ContainerButton = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: ${spaces.s}rem;
  @media (max-width: ${breakPoints.portraitTablet * percentBaseResponsive}rem) {
    justify-content: center;
  }
`;

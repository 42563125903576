export const colors = {
  greyVeryLight: '#F4F1F2',
  greyVerySoft: '#F6F6F6',
  greyLight: '#D9D9D9',
  greySilver: '#959595',
  lightGreyWhite: '#CCCCCC',
  greyBackground: '#19191D66',
  greyBoard: '#4A4B57',
  greyVeryDark: '#575757',
  greyDark: '#757575',
  greyClear: '#F5F5F5',
  greySoftLight: '#A0A0A0',
  paleGrey: '#EAEAEA',
  paleDarkGrey: '#AEAEAE',
  darkGrey: '#3D3D3D',
  mediumGrey: '#9F9F9F',
  darkGrayishGrey: '#747474',
  pureGrey: '#808080',
  softRed: '#FEE8E7',
  white: '#FFFFFF',
  softDarkWhite: '#F0EAEC',
  red: '#C8372D',
  lightRed: '#FFF7F6',
  verySoftRed: '#FAF1F0',
  veryClearRed: '#F8EBEB',
  clearRed: '#FFD9D7',
  lightRose: '#FBE2E0',
  lightCoralRed: '#F6655A',
  darkBrickRed: '#A62F27',
  deepBrickRed: '#691E19',
  garnet: '#721C34',
  garnetLight: '#721C3433',
  garnetClear: '#721C341A',
  darkBurgundyRed: '#521324',
  veryDarkBurgundyRed: '#541325',
  deepRedWine: '#440F1D',
  DustyRose: '#D88C87',
  lightDustyRose: '#AE7F8C',
  darkRoseRed: '#8E495C',
  paleRose: '#F1EAEC',
  lightPink: '#DC8E88',
  lightPastelPink: '#F1D8D6',
  veryClearPink: '#F1DDE3',
  softGreen: '#ECF7ED',
  green: '#78B85A',
  veryClearGreen: '#EEF5EF',
  mossGreen: '#5B8B44',
  forestGreen: '#37833B',
  darkForestGreen: '#426531',
  lightPearGreen: '#B1D3A0',
  veryLightPastelGreen: '#EAFDED',
  sageGreen: '#8DB38E',
  black: '#111111',
  gold: '#FFA02F',
  goldSoft: '#F8C28A',
  pink: '#F9DFFF',
  purple: '#A92DC8',
  blue: '#3387D4',
  blueSoft: '#9AC0DA',
};

import React, { useLayoutEffect } from 'react';
import { useAsyncValue, useLocation, useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { useDispatch } from 'react-redux';

//Components
import ActionContentCardResume from '../../../../../components/display/ActionContentCardResume';

// Types
import { WorkOrder } from '../../../../../types/workOrder';
import { LoaderType } from './types';
import { OrderType } from '../../../../../types/orders';

// Redux
import {
  coticOTSelected,
  coticOTSelectedReset,
} from '../../../../../state/actions/ui/coticOTSelectedGeneralView';
import { removeNewActionStorage } from '../../../../../utils/resetData';

export default function ActionSummaryPage(): JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();
  const loaderData = useAsyncValue() as WorkOrder &
    LoaderType & {
      action: LoaderType;
      isActionEdited: string;
      externalOperatorsCount: number;
    };
  const dispatch = useDispatch();
  const routes = location.pathname.split('/');
  const isActionEditedBoolean = loaderData.isActionEdited === 'true';
  routes?.shift();

  const isActionValidation = routes.some(
    (item) => item === 'actions-validation'
  );

  useLayoutEffect(() => {
    if (!loaderData) {
      navigate('..');
    }

    if (loaderData) {
      dispatch(
        coticOTSelected(
          routes?.length > 0 && routes[1] === 'actions-validation'
            ? 'actions-validation'
            : OrderType.Action,
          loaderData.cotic.join('.'),
          loaderData.id as string
        )
      );
    }
  }, []);

  const handleEditAction = () => {
    if (isActionValidation) {
      navigate(`../${loaderData.id}/operators-and-vehicles`);
    } else {
      navigate(`../details`);
    }
  };

  const handleContinueAction = () => {
    if (isActionValidation) {
      navigate('..');
    } else {
      dispatch(coticOTSelectedReset());
      removeNewActionStorage();
      navigate('/work-orders');
    }
  };

  const totalHours = `${Math.floor(loaderData.minutesTotals / 60)
    .toString()
    .padStart(2, '0')}:${(loaderData.minutesTotals % 60)
    .toString()
    .padStart(2, '0')}`;
  return (
    <>
      {loaderData && (
        <ActionContentCardResume
          works={loaderData.works}
          routes={routes}
          cotic={loaderData.cotic}
          date={format(new Date(loaderData.date), 'dd/MM/yy')}
          hour={format(new Date(loaderData.date), 'HH:mm')}
          capitol={loaderData.capitol}
          subCapitol={loaderData.subCapitol}
          concession={loaderData.concession}
          roadDirections={loaderData.roadDirections}
          direction={loaderData.direction}
          margin={loaderData.margin}
          road={loaderData.road}
          pkInit={loaderData.pkInit}
          pkEnd={loaderData.pkEnd}
          track={loaderData.track}
          city={loaderData.city}
          lane={loaderData.lane}
          element={loaderData.elementOrder}
          laneCutting={loaderData.action.laneCutting}
          images={loaderData.images}
          description={loaderData.description}
          leftButton={
            !isActionEditedBoolean && isActionValidation
              ? 'Continuar'
              : 'Editar'
          }
          rightButton={
            !isActionEditedBoolean && isActionValidation ? 'Tornar' : 'Gravar'
          }
          isActionEditedBoolean={isActionEditedBoolean}
          onEditAction={handleEditAction}
          onContinueAction={handleContinueAction}
          asset={loaderData.assetValue}
          assetSwitch={loaderData.assetSwitch}
          activity={loaderData.activityValue}
          mapCenter={loaderData.centerMap}
          path={loaderData?.path ?? []}
          geolocation={loaderData.geolocation}
          operators={loaderData.operators}
          vehicles={loaderData.vehicles}
          isMaterialTableDisplayed={!!loaderData.materialsValue}
          materialsData={loaderData.materialsValue ?? []}
          isAnimalsTableDisplayed={!!loaderData.animals}
          animalsData={loaderData.animals ?? []}
          isMachineryTableDisplayed={!!loaderData.machineryValue}
          machineryData={loaderData.machineryValue ?? []}
          startDate={format(
            new Date(loaderData.registerInitDate),
            'dd/MM/yyyy'
          )}
          startHour={format(new Date(loaderData.registerInitDate), 'HH:mm')}
          endDate={format(new Date(loaderData.registerEndDate), 'dd/MM/yyyy')}
          endHour={format(new Date(loaderData.registerEndDate), 'HH:mm')}
          totalHours={totalHours}
          totalOperators={`${
            loaderData.operators.length || loaderData.externalOperatorsCount
          }`}
          isExternal={loaderData.isExternal}
        />
      )}
    </>
  );
}

import styled, { css } from 'styled-components';

// Components
import { DayPicker } from '../Calendar/DayPicker';
import { Typography } from '../../display/Typography';

// Styles utils
import { colors } from '../../../styles/colors';
import {
  spaces,
  breakPoints,
  percentBaseResponsive,
} from '../../../styles/spaces';
import { fontSize, fontWeight } from '../../../styles/fonts';

export const Container = styled.div`
  position: relative;
`;

export const ContainerCalendar = styled.div``;

export const ContainerInputs = styled.div`
  display: flex;
  align-items: flex-end;
  gap: ${spaces.xxs}rem;
`;

export const SmallContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spaces.xxxs}rem;
`;

export const Label = styled(Typography)`
  white-space: nowrap;
  @media (max-width: ${breakPoints.landscapeTablet *
    percentBaseResponsive}rem) {
    font-size: ${fontSize.XS};
  }
`;

type inputBorder = {
  borderError?: boolean;
  isFocus?: boolean;
};

export const InputDate = styled.input<inputBorder>`
  width: 15rem;
  height: 4rem;
  border-radius: 0.5rem;
  padding: 0 1rem;
  border: 0.1rem solid ${colors.black};
  ${({ disabled }) =>
    disabled &&
    css`
      font-weight: ${fontWeight.medium};
      color: ${colors.greyDark};
      background-color: ${colors.greyLight};
      border: none;
    `}
  ${({ borderError }) =>
    borderError &&
    css`
      border: 0.1rem solid ${colors.lightCoralRed};
    `}

  :focus {
    outline: 0.1rem solid ${colors.black};
  }

  ::placeholder {
    color: ${colors.greySoftLight};
    opacity: 1;
  }

  @media (max-width: ${breakPoints.landscapeTablet *
    percentBaseResponsive}rem) {
    font-size: ${fontSize.XS};
  }
`;

export const Calendar = styled(DayPicker)`
  position: absolute;
  margin-top: ${spaces.xxxs}rem;
  left: -2.5rem;
  z-index: 999;
  padding: 2.5rem;

  @media (max-width: ${breakPoints.landscapeTablet *
    percentBaseResponsive}rem) {
    position: fixed;
    max-height: 65rem;
    bottom: 0;
    margin: 0;
    left: 0;
    width: 100%;
    padding: 3rem 17rem;
  }
  @media (max-width: ${breakPoints.portraitTablet * percentBaseResponsive}rem) {
    width: 100%;
    top: 25rem;
    left: 0;
    padding: 5rem 10rem;
    padding-bottom: 1rem;
    max-height: 100%;
  }
`;

export const InputTime = styled.div<inputBorder>`
  display: flex;
  align-items: center;
  height: 4rem;
  padding: 0 1rem;
  border-radius: 0.5rem;
  background: ${colors.white};
  ${(props) =>
    props['aria-disabled'] &&
    css`
      font-weight: ${fontWeight.medium};
      color: ${colors.greyDark};
      background: ${colors.greyLight};
    `}
  border: ${(props) =>
    props['aria-disabled']
      ? `none`
      : props[`borderError`]
      ? `0.1rem solid ${colors.lightCoralRed}`
      : `0.1rem solid ${colors.black}`};
  outline: none;
  ${({ isFocus }) =>
    isFocus &&
    css`
      outline: 0.1rem solid ${colors.black};
    `}
`;

export const InputNumberHours = styled.input`
  text-align: right;
  width: 2ch;
  padding: 0;
  appearance: none;
  border: none;
  background-color: transparent;
  ${({ disabled }) =>
    disabled &&
    css`
      font-weight: ${fontWeight.medium};
      color: ${colors.greyDark};
    `}
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type='number'] {
    -moz-appearance: textfield;
  }

  &:focus-visible {
    outline: none;
  }

  ::placeholder {
    color: ${colors.greySoftLight};
    opacity: 1;
  }

  @media (max-width: ${breakPoints.landscapeTablet *
    percentBaseResponsive}rem) {
    font-size: ${fontSize.XS};
  }
`;

export const InputNumberMinutes = styled.input`
  width: 2ch;
  padding: 0;
  appearance: none;
  border: none;
  background-color: transparent;
  ${({ disabled }) =>
    disabled &&
    css`
      font-weight: ${fontWeight.medium};
      color: ${colors.greyDark};
    `}
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type='number'] {
    -moz-appearance: textfield;
  }

  &:focus-visible {
    outline: none;
  }

  ::placeholder {
    color: ${colors.greySoftLight};
    opacity: 1;
  }

  @media (max-width: ${breakPoints.landscapeTablet *
    percentBaseResponsive}rem) {
    font-size: ${fontSize.XS};
  }
`;

export const Span = styled.span`
  font-size: ${fontSize.S};
  @media (max-width: ${breakPoints.landscapeTablet *
    percentBaseResponsive}rem) {
    font-size: ${fontSize.XS};
  }
`;

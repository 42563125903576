export const formActionNaming = {
  NEW_ACTION_DATA: 'new-action-data',
  NEW_OPERATORS_AND_VEHICLE_FORM: 'new-operators-and-vehicle-form',
  NEW_LOCATION_FORM: 'new-location-form',
  NEW_EDITION_FORM: 'new-edition-form',
  NEW_MACHINERY_FORM: 'new-machinery-form',
  NEW_ANIMALS_FORM: 'new-animals-form',
  NEW_MATERIALS_FORM: 'new-materials-form',
  SELECTED_ACTIONS_FILES: 'selected-actions-files',
  NEW_VALIDATION_ACTION_DATA: 'new-validation-action-data',
  DRIVERS: 'drivers',
};

export const formActionValidationNaming = {
  NEW_VALIDATION_OPERATORS_AND_VEHICLE_FORM:
    'new-validation-operators-and-vehicle-form',
  NEW_VALIDATION_LOCATION_FORM: 'new-validation-location-form',
  NEW_VALIDATION_EDITION_FORM: 'new-validation-edition-form',
  NEW_VALIDATION_MACHINERY_FORM: 'new-validation-machinery-form',
  NEW_VALIDATION_ANIMALS_FORM: 'new-validation-animals-form',
  NEW_VALIDATION_MATERIALS_FORM: 'new-validation-materials-form',
  DATA_CHANGES: 'data-changes',
};

export const dataTransferNaming = {
  SUB_ORDER_ACCIDENT: 'sub-order-accident',
  SUB_ORDER_ACTION: 'sub-order-action',
  SUB_ORDER_COMMUNICATION: 'sub-order-communication',
};

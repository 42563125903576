import styled from 'styled-components';

import { Typography } from '../Typography';

import { spaces } from '../../../styles/spaces';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Title = styled(Typography)`
  white-space: nowrap;
`;

export const Value = styled(Typography)`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-left: ${spaces.xxs}rem;
`;

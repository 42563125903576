import { object, string } from 'yup';
import { pkValidation } from '../../../../utils/pkValidation';

export const schemaOT = object({
  capitol: string().required(),
  subCapitol: string().required(),
  concession: string().required(),
  road: string().required(),
  pkInitRoad: string().required(),
  pkEndRoad: string().required(),
  pkInit: string()
    .test(function (value) {
      const regex = new RegExp(/^\d+\s?\+\s?\d{3,4}$/);
      return regex.test(value || '') && pkValidation(value || '', this.parent);
    })
    .required(),
  pkEnd: string()
    .test(function (value) {
      const regex = new RegExp(/^\d+\s?\+\s?\d{3,4}$/);
      return regex.test(value || '') && pkValidation(value || '', this.parent);
    })
    .required(),
  date: string().required(),
  description: string().required(),
});

import { ActionFunction } from 'react-router-dom';
import { Store } from 'redux';

// Sentry
import * as Sentry from '@sentry/react';

// GQL
import { formNaming } from '../../../../../components/forms/NewCommunicationForm/constants';

// Selector
import { getCurrentIndexTabFormByTab } from '../../../../../state/selectors/ui/tabs';
import { selectedOT } from '../../../../../state/selectors/ui/modal';
// Store

import { RootState } from '../../../../../state/configureStore';
// Actions
import { passData } from './ActionUtils/passData';
import { saveData } from './ActionUtils/saveData';
import { tabsType } from '../../../../../state/reducers/ui/tabs';

export const EditCommunicationSummary: (
  store: Store<RootState>
) => ActionFunction =
  (store) =>
  async ({ request }) => {
    const formData = await request.formData();
    const intent = formData.get('intent');
    const storedDataOTSelected = selectedOT(formNaming.EDIT_COMMUNICATION_FORM)(
      store.getState() as RootState
    );
    const tabSelected = getCurrentIndexTabFormByTab(
      tabsType.EDIT_COMMUNICATION
    )(store.getState() as RootState);

    if (intent === 'pass-data') {
      try {
        return passData({
          formData,
          storedDataOTSelected,
        });
      } catch (err) {
        Sentry.captureException(err);
        console.log('Error:', err);
      }
    }
    if (intent === 'save-data') {
      try {
        return saveData({
          formData,
          store,
          tabSelected,
          storedDataOTSelected,
        });
      } catch (err) {
        Sentry.captureException(err);
        console.log('> CommunicationSummary - error editing:', err);
      }
    }
    return null;
  };

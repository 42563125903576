import { gql } from '@apollo/client';

export const CREATE_NEW_ACTION = gql`
  mutation CreateAction($input: NewAction!) {
    createAction(input: $input) {
      id
      typeOfHour
      minutesTotals
      isValidate
      concessionIDs
      roadIDs
      teamIDs
      pkInitKm
      pkInitMeter
      pkEndKm
      pkEndMeter
      track
      direction
      margin
      lane
      userIDs
      geoPositionActionIDs
      vehicleOccupantIDs
      activityIDs
      assetIDs
      materialUnitIDs
      auxMachineUnitIDs
      animalUnitIDs
      orderIDs
    }
  }
`;

export const UPDATE_ACTION = gql`
  mutation UpdateAction($input: UpdateAction!) {
    updateAction(input: $input) {
      id
      typeOfHour
      minutesTotals
      isValidate
      concessionIDs
      roadIDs
      teamIDs
      pkInitKm
      pkInitMeter
      pkEndKm
      pkEndMeter
      track
      direction
      margin
      lane
      assetSwitch
      userIDs
      geoPositionActionIDs
      vehicleOccupantIDs
      activityIDs
      assetIDs
      materialUnitIDs
      auxMachineUnitIDs
      animalUnitIDs
      orderIDs
      descriptionValidated
      isExternal
    }
  }
`;

export const UPDATE_ACTION_RELATIONSHIPS = gql`
  mutation UpdateActionRelationships($input: UpdateActionRelationships!) {
    updateActionRelationships(input: $input) {
      id
      typeOfHour
      minutesTotals
      isValidate
      concessionIDs
      roadIDs
      teamIDs
      pkInitKm
      pkInitMeter
      pkEndKm
      pkEndMeter
      track
      direction
      margin
      lane
      userIDs
      geoPositionActionIDs
      vehicleOccupantIDs
      activityIDs
      assetIDs
      materialUnitIDs
      auxMachineUnitIDs
      animalUnitIDs
      orderIDs
    }
  }
`;

export const CONNECT_MANY_USERS_TO_ACTION = gql`
  mutation connectManyUsers($input: ActionAndUsersIDs!) {
    connectManyUsers(input: $input) {
      userIDs
    }
  }
`;

export const DISCONNECT_MANY_USERS_FROM_ACTION = gql`
  mutation disconnectManyUsers($input: ActionAndUsersIDs!) {
    disconnectManyUsers(input: $input) {
      userIDs
    }
  }
`;

export const UPDATE_MANY_VEHICLE_OCCUPANTS_ACTION = gql`
  mutation updateManyVehicleOccupantsAction(
    $input: [UpdatedVehicleOccupantsItems!]!
  ) {
    updateManyVehicleOccupantsAction(input: $input) {
      userDriverIDs
    }
  }
`;

export const DELETE_MANY_VEHICLE_OCCUPANTS_ACTION = gql`
  mutation deleteManyVehicleOccupantsAction($ids: [ID!]!) {
    deleteManyVehicleOccupantsAction(ids: $ids) {
      id
      userDriverIDs
    }
  }
`;

export const DELETE_ACTION_BY_ID = gql`
  mutation deleteAction($id: ID!) {
    deleteAction(id: $id) {
      id
      typeOfHour
      minutesTotals
      isValidate
      concessionIDs
      roadIDs
      teamIDs
      pkInitKm
      pkInitMeter
      pkEndKm
      pkEndMeter
      track
      direction
      margin
      lane
      userIDs
      geoPositionActionIDs
      vehicleOccupantIDs
      activityIDs
      assetIDs
      materialUnitIDs
      auxMachineUnitIDs
      animalUnitIDs
      orderIDs
    }
  }
`;

// GraphQL
import { client } from '../../../../../../gql/client';
import {
  GET_INTERLOCUTOR_GROUP_BY_ID,
  GET_MEDIA_OF_COMMUNICATION_BY_ID,
} from '../../../../../../gql/queries/communication';
import {
  GET_CAPITOL_BY_ID,
  GET_CLASSIFICATION_BY_ID_OR_COTIC,
  GET_SUB_CAPITOL_BY_ID,
} from '../../../../../../gql/queries/orders/predefinedData';
import {
  GET_CONCESSION_BY_ID,
  GET_ROAD_BY_ID,
} from '../../../../../../gql/queries/geoPositions/roads';

// Types
import { PassDataParams } from './types';
import { groupLanes, groupTracks } from '../../../../../../data/orders';
import { GET_TEAM_BY_ID } from '../../../../../../gql/queries/users';

export const passData = async ({
  formData,
  tabSelected,
  storedDataOTSelected,
}: PassDataParams) => {
  let classificationPass, capitolPass, subCapitolPass;
  const operator = formData.get('operator');
  const registerInitDate = formData.get('registerInitDate');
  const groupInterId = formData.get('groupInter');
  const mediaId = formData.get('media');
  const sendStatus = formData.get('sendStatus');
  const concessionId = formData.get('concession');
  const roadId = formData.get('road');
  const classificationId = formData.get('classification');
  const capitolId = formData.get('capitol');
  const subCapitolId = formData.get('subCapitol');
  const teamId = formData.get('team');

  const [
    {
      data: { concession },
    },
    {
      data: { road },
    },
    {
      data: { interlocutorGroup },
    },
    {
      data: { mediaOfCommunication },
    },
  ] = await Promise.all([
    client.query({
      query: GET_CONCESSION_BY_ID,
      variables: {
        id: concessionId,
      },
    }),
    client.query({
      query: GET_ROAD_BY_ID,
      variables: {
        id: roadId,
      },
    }),
    client.query({
      query: GET_INTERLOCUTOR_GROUP_BY_ID,
      variables: {
        id: groupInterId,
      },
    }),
    client.query({
      query: GET_MEDIA_OF_COMMUNICATION_BY_ID,
      variables: {
        id: mediaId,
      },
    }),
  ]);

  let teamValue = null;

  if (teamId) {
    const {
      data: { team },
    } = await client.query({
      query: GET_TEAM_BY_ID,
      variables: {
        id: teamId,
      },
    });

    teamValue = team;
  }

  if (tabSelected === 0) {
    const [
      {
        data: { classification },
      },
      {
        data: { capitol },
      },
      {
        data: { subCapitol },
      },
    ] = await Promise.all([
      client.query({
        query: GET_CLASSIFICATION_BY_ID_OR_COTIC,
        variables: {
          id: classificationId,
        },
      }),
      client.query({
        query: GET_CAPITOL_BY_ID,
        variables: {
          id: capitolId,
        },
      }),
      client.query({
        query: GET_SUB_CAPITOL_BY_ID,
        variables: {
          id: subCapitolId,
        },
      }),
    ]);
    classificationPass = classification.name;
    capitolPass = capitol.name;
    subCapitolPass = subCapitol.name;
  } else {
    classificationPass = storedDataOTSelected?.classification;
    capitolPass = storedDataOTSelected?.capitol;
    subCapitolPass = storedDataOTSelected?.subCapitol;
  }
  const city = formData.get('city');
  const pkInitRoad = formData.get('pkInitRoad');
  const pkEndRoad = formData.get('pkEndRoad');
  const trackValue = formData.get('track');
  const direction = formData.get('direction');
  const margin = formData.get('margin');
  const laneValue = formData.get('lane');
  const pkInit = formData.get('pkInit');
  const pkEnd = formData.get('pkEnd');
  const description = formData.get('description');
  const specialTag = formData.get('specialTag');

  return {
    operator,
    registerInitDate,
    groupInter: interlocutorGroup?.name,
    media: mediaOfCommunication?.name,
    sendStatus,
    concession: concession?.name,
    city,
    pkInitRoad,
    pkEndRoad,
    road: road?.name,
    track: groupTracks.find((track) => track.value === trackValue)?.name,
    direction,
    margin,
    lane: groupLanes.find((lane) => lane.value === laneValue)?.name,
    pkInit,
    pkEnd,
    roadDirections: road.directions,
    description,
    specialTag,
    classification: classificationPass,
    capitol: capitolPass,
    subCapitol: subCapitolPass,
    team: teamValue?.name,
    storedDataOTSelected,
  };
};

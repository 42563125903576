export const createFormDataFromValues = <T extends object>(values: T) => {
  const formData = new FormData();

  Object.entries(values).forEach(([key, value]) => {
    if (value) {
      formData.append(key, value);
    }
  });

  return formData;
};

export const mergeFormData = (
  firstFormData: FormData,
  secondFormData: FormData
): FormData => {
  const mergedFormData = new FormData();

  for (const [key, value] of firstFormData.entries()) {
    mergedFormData.append(key, value);
  }

  for (const [key, value] of secondFormData.entries()) {
    mergedFormData.append(key, value);
  }

  return mergedFormData;
};

export const parseValueFromObjectOfObjects = <T>(
  input: Record<string, T>
): Record<string, string> => {
  const parsedValues: Record<string, string> = {};

  for (const key in input) {
    if (Object.prototype.hasOwnProperty.call(input, key)) {
      if (typeof input[key] === 'string') {
        parsedValues[key] = input[key] as string;
      } else {
        parsedValues[key] = JSON.stringify(input[key]);
      }
    }
  }

  return parsedValues;
};

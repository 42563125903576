import { Role } from '../../../../types/auth';

export const data = [
  {
    title: 'Usuari',
    path: '',
    permissions: [Role.SuperAdmin, Role.Ceconsa],
  },
  {
    title: 'Vehicles',
    path: 'vehicles',
    permissions: [Role.SuperAdmin, Role.Ceconsa],
  },
  {
    title: 'Materials',
    path: 'materials',
    permissions: [Role.SuperAdmin, Role.Ceconsa],
  },
  {
    title: 'Maquinària Aux.',
    path: 'auxiliar-machinery',
    permissions: [Role.SuperAdmin, Role.Ceconsa],
  },
  {
    title: 'Animals',
    path: 'animals',
    permissions: [Role.SuperAdmin, Role.Ceconsa],
  },
];

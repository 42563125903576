import { defer, LoaderFunction } from 'react-router-dom';

// Sentry
import * as Sentry from '@sentry/react';

// GraphQL
import { client } from '../../../../../gql/client';
import { selectWorkOrderById } from '../../../../../gql/selectors/orders';
import { GET_USER_BY_ID } from '../../../../../gql/queries/users';

const loadWorkOrderSummary = async (idOrder: string) => {
  try {
    const workOrderData = await selectWorkOrderById(idOrder);

    const operatorData = await client.query({
      query: GET_USER_BY_ID,
      variables: {
        input: {
          id: workOrderData.userId,
        },
      },
    });

    return {
      ...workOrderData,
      operator: `${operatorData.data.user.name} ${
        operatorData.data.user.surName.split(' ')[0]
      }`,
    };
  } catch (err) {
    Sentry.captureException(err);
    console.log('> Work order Summary loading error:', err);
    return false;
  }
};

export const LoaderSearchAndCreateHistoricalWorkOrderSummary: LoaderFunction =
  async ({ params }) => {
    if (!params.id) return null;
    if (Object.keys(client.cache.extract()).length === 0) return null;

    const loaderToResolve = loadWorkOrderSummary(params.id);
    return defer({ data: loaderToResolve });
  };

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getCursor = (namingForm: string) => (state: any) => {
  return state.cursorTables[namingForm];
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getTake = () => (state: any) => {
  return state.takeTables;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getIsLoadingData = (namingForm: string) => (state: any) => {
  return state.loadingData[namingForm]?.isLoadingData;
};

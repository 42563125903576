import styled from 'styled-components';

// Dependencies
import { Link } from 'react-router-dom';

// Components
import { Typography } from '../../display/Typography';
import { MainButton } from '../../inputs/buttons/MainButton';
import InputText from '../../inputs/InputText';

// Styles utils
import { breakPoints, percentBaseResponsive } from '../../../styles/spaces';
import { colors } from '../../../styles/colors';

export const ContainerLogo = styled.div`
  display: flex;
  flex: 0 0 50%;
  width: 40%;
  justify-content: space-between;

  @media (max-width: ${breakPoints.portraitTablet * percentBaseResponsive}rem) {
    width: 100%;
    flex: initial;
  }
`;

export const ContainerForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 60%;
  flex: 0 0 100%;
  order: 2;
  position: relative;

  @media (max-width: ${breakPoints.portraitTablet * percentBaseResponsive}rem) {
    order: 0;
    width: 100%;
    flex: initial;
    margin-top: 5rem;
    margin-bottom: 4rem;
  }
`;

export const LogoCedinsa = styled.img`
  width: 15.254rem;
  height: 4.3rem;
  margin-top: -1.6rem;

  @media (max-width: ${breakPoints.landscapeTablet *
    percentBaseResponsive}rem) {
    width: 11.593rem;
    height: 3.265rem;
    margin-top: -1rem;
  }
  @media (max-width: ${breakPoints.portraitTablet * percentBaseResponsive}rem) {
    margin-top: 0;
  }
`;

export const Title = styled(Typography)`
  font-size: 2.6rem;
  margin-bottom: 4rem;

  @media (max-width: ${breakPoints.landscapeTablet *
    percentBaseResponsive}rem) {
    font-size: 2.2rem;
    margin-bottom: 3rem;
  }
`;

export const Label = styled.label`
  margin-bottom: 0.5rem;
`;

export const TextInput = styled(InputText)`
  position: relative;
  margin-bottom: 4rem;
  > p {
    position: absolute;
    bottom: -2rem;
  }

  @media (max-width: ${breakPoints.landscapeTablet *
    percentBaseResponsive}rem) {
    margin-bottom: 3rem;
  }
`;

export const InputTextPassword = styled(InputText)`
  position: relative;
  > p {
    position: absolute;
    bottom: -2rem;
  }
`;

export const ContainerLinkPassword = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const LinkPassword = styled(Link)`
  margin-top: 2.621rem;
  margin-bottom: 4.558rem;
  width: max-content;

  :link {
    color: ${colors.black};
  }

  :visited {
    color: ${colors.black};
  }

  @media (max-width: ${breakPoints.landscapeTablet *
    percentBaseResponsive}rem) {
    margin-top: 1.535rem;
    margin-bottom: 3.3rem;
  }
  @media (max-width: ${breakPoints.portraitTablet * percentBaseResponsive}rem) {
    margin-top: 0.835rem;
  }
`;

export const PrincipalButton = styled(MainButton)`
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
  width: 100%;

  @media (max-width: ${breakPoints.landscapeTablet *
    percentBaseResponsive}rem) {
    padding: 1.7rem;
    margin-bottom: 1.9rem;
  }
`;

export const LinkPolicy = styled(Link)`
  text-decoration: none;
`;

export const ContainerOra = styled.div`
  display: flex;
  width: 40%;
  flex: 0 0 50%;

  @media (max-width: ${breakPoints.portraitTablet * percentBaseResponsive}rem) {
    width: 100%;
    flex: initial;
  }
`;

export const OraLogo = styled.img`
  width: 11rem;
  height: 3.236rem;
  align-self: flex-end;

  @media (max-width: ${breakPoints.portraitTablet * percentBaseResponsive}rem) {
    width: 7.353rem;
    height: 2.156rem;
  }
`;

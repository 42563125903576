import styled from 'styled-components';

// Utils
import {
  breakPoints,
  percentBaseResponsive,
  spaces,
} from '../../../../styles/spaces';
import { Typography } from '../../../display/Typography';

export const TimeContainer = styled.div`
  display: flex;
  gap: ${spaces.l}rem;
  @media (max-width: ${breakPoints.smallDesktop * percentBaseResponsive}rem) {
    flex-wrap: wrap;
  }
`;

export const StyledTypography = styled(Typography)`
  margin: 4rem 0 2.8rem;
`;

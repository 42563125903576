import { gql } from '@apollo/client';

export const NEW_VEHICLE_OCCUPANT_ACCIDENT = gql`
  mutation CreateVehicleOccupantAccident($input: NewVehicleOccupantAccident!) {
    createVehicleOccupantAccident(input: $input) {
      id
      numberOccupants
      accidentIDs
      vehicleIDs
    }
  }
`;

export const NEW_VEHICLE_OCCUPANT_ACTION = gql`
  mutation CreateVehicleOccupantAction($input: NewVehicleOccupantAction!) {
    createVehicleOccupantAction(input: $input) {
      id
      userDriverIDs
      actionIDs
      vehicleIDs
    }
  }
`;

export const CREATE_NEW_VEHICLE = gql`
  mutation CreateVehicle($input: NewVehicle!) {
    createVehicle(input: $input) {
      id
      insurance
      plate
      model
      color
      policyNumber
      isTrailer
      isActive
      accidentIDs
      isInternal
      parentVehicleIDs
      childVehicleIDs
      vehicleCategoryIDs
      concessionIDs
    }
  }
`;

export const UPDATE_VEHICLE = gql`
  mutation UpdateVehicle($input: UpdateVehicle!) {
    updateVehicle(input: $input) {
      id
      isActive
    }
  }
`;

import React from 'react';
import { Form } from 'react-router-dom';

// Components
import { Typography } from '../Typography';

// Types
import { DropDownUserMenuProps, Roles } from './types';

// Styled Components
import {
  ButtonSession,
  Container,
  ContainerButtonPolicy,
  ContainerUser,
  HorizontalLine,
  LinkPolicy,
  PolicyVersionContainer,
  TextSection,
  EmailText,
  ContainerHeader,
  ContainerUserNameRole,
} from './styles';

// Media
import { useIsLandscapeTablet } from '../../../hooks/useMediaBreakPoints';

// Utils
import { version } from '../../../../package.json';
import { ButtonUser } from '../../inputs/DropDown/DropDownSelectGroup/styles';

export const DropDownUserMenu = ({
  userName,
  userMail,
  userRole,
}: DropDownUserMenuProps): JSX.Element => {
  const isLandscapeTablet = useIsLandscapeTablet();
  return (
    <Container>
      {isLandscapeTablet ? (
        <ContainerHeader>
          <ButtonUser initials={userName} />
          <ContainerUserNameRole>
            <Typography size="M" variant="semiBold">
              {userName}
            </Typography>
            <Typography size="XS">{Roles[userRole]}</Typography>
          </ContainerUserNameRole>
        </ContainerHeader>
      ) : (
        <Typography size="S">{Roles[userRole]}</Typography>
      )}
      <HorizontalLine />
      <ContainerUser>
        <Typography
          size={isLandscapeTablet ? 'XS' : 'S'}
          colorText="greyDark"
          variant="medium"
        >
          Informació de l’usuari
        </Typography>
        <EmailText size={isLandscapeTablet ? 'XS' : 'S'} colorText="greyDark">
          {userMail}
        </EmailText>
      </ContainerUser>
      <HorizontalLine />
      <ContainerButtonPolicy>
        <Form action="logout" method="delete">
          <ButtonSession text="Tancar sessió" variant="secondary" />
        </Form>
        <PolicyVersionContainer>
          <LinkPolicy to={'#'}>
            <TextSection colorText="greyDark">
              Política de privacitat
            </TextSection>
          </LinkPolicy>
          <TextSection colorText="greyDark">Versión: {version}</TextSection>
        </PolicyVersionContainer>
      </ContainerButtonPolicy>
    </Container>
  );
};

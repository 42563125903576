import { gql } from '@apollo/client';

export const FETCH_ACCIDENTS = gql`
  query FetchAccidents($input: FilterAccidentList!) {
    accidents(input: $input) {
      accidents {
        id
        attentionAccidentIDs
        concessionIDs
        conditionsDrivingIDs
        damages
        direction
        lane
        laneCutting
        luminosityIDs
        margin
        mediumKnowledgeIDs
        orderIDs
        pkInitKm
        pkInitMeter
        pkEndKm
        pkEndMeter
        policeReport
        policeReportNumber
        roadConditionDescription
        roadIDs
        surfaceConditionIDs
        surveillanceBody
        surveillanceBodyIDs
        teamIDs
        track
        vehicleIDs
        vehicleOccupantIDs
        weatherIDs
      }
      totalCount
      pageInfo {
        lastCursor
        hasPreviousPage
        hasNextPage
      }
    }
  }
`;

export const GET_ACCIDENT_BY_ID = gql`
  query getAccidentById($id: ID!) {
    accident(id: $id) {
      id
      attentionAccidentIDs
      concessionIDs
      conditionsDrivingIDs
      direction
      damages
      lane
      laneCutting
      margin
      luminosityIDs
      mediumKnowledgeIDs
      orderIDs
      pkEndKm
      pkEndMeter
      pkInitKm
      pkInitMeter
      policeReport
      policeReportNumber
      roadConditionDescription
      roadIDs
      surfaceConditionIDs
      surveillanceBodyIDs
      teamIDs
      track
      vehicleIDs
      vehicleOccupantIDs
      weatherIDs
    }
  }
`;

export const GET_CONDITIONS_DRIVING_BY_ID = gql`
  query GetConditionsDrivingById($id: ID!) {
    conditionsDriving(id: $id) {
      id
      accidentsIDs
      name
    }
  }
`;

export const GET_IMAGE_BY_ID = gql`
  query GetImageById($id: ID!) {
    image(id: $id) {
      id
      name
      creatingDate
      cloudinaryPublicId
      orderIDs
    }
  }
`;

export const GET_LUMINOSITY_BY_ID = gql`
  query GetLuminosityById($id: ID!) {
    luminosity(id: $id) {
      id
      accidentsIDs
      name
    }
  }
`;

export const GET_MEDIUM_KNOWLEDGE_BY_ID = gql`
  query GetMediumKnowledgeById($id: ID!) {
    mediumKnowledge(id: $id) {
      id
      accidentsIDs
      name
    }
  }
`;

export const GET_SURFACE_CONDITION_BY_ID = gql`
  query GetSurfaceConditionById($id: ID!) {
    surfaceCondition(id: $id) {
      id
      accidentsIDs
      name
    }
  }
`;

export const GET_SURVEILLANCE_BODY_BY_ID = gql`
  query GetSurveillanceBodyById($id: ID!) {
    surveillanceBody(id: $id) {
      id
      accidentsIDs
      name
    }
  }
`;

export const GET_WEATHER_BY_ID = gql`
  query GetWeatherById($id: ID!) {
    weather(id: $id) {
      id
      accidentsIDs
      name
    }
  }
`;

import styled, { css } from 'styled-components';

// Types
import { TypeMaxLength } from './types';

// Styles
import { colors } from '../../../styles/colors';
import { fontSize } from '../../../styles/fonts';
import { breakPoints, percentBaseResponsive } from '../../../styles/spaces';

type InputContainerProps = {
  disabled: boolean | undefined;
  maxLength: TypeMaxLength;
};

export const InputContainer = styled.div<InputContainerProps>`
  :nth-of-type(2) input {
    width: calc(2.2rem + 4ch);
  }
  :nth-of-type(3) input {
    width: calc(2.2rem + 7ch);
  }
`;

export const InputValue = styled.input`
  padding: 0 1rem;
  height: 4rem;
  width: calc(2.2rem + 2ch);
  border-radius: 0.5rem;
  font-size: ${fontSize.S};
  border: ${({ disabled }) =>
    !disabled ? `0.1rem solid ${colors.black}` : 'none'};
  ${({ disabled }) =>
    !disabled &&
    css`
      background-color: ${colors.white};
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      background-color: ${colors.greyLight};
    `}

  :focus {
    outline: 0.08rem solid ${colors.black};
  }

  ::placeholder {
    color: ${colors.greySoftLight};
    opacity: 1;
  }

  @media (max-width: ${breakPoints.landscapeTablet *
    percentBaseResponsive}rem) {
    font-size: ${fontSize.XS};
  }
`;

import styled from 'styled-components';
import { spaces } from '../../../../styles/spaces';
import { colors } from '../../../../styles/colors';

export const CanvasPreview = styled.img``;

export const ButtonsContainer = styled.div`
  display: flex;
  padding: ${spaces.l}rem;
  justify-content: space-between;
  width: 100%;
  background: ${colors.greyVerySoft};
  box-shadow: -5px 8px 19px -7px rgba(0, 0, 0, 0.74);
`;

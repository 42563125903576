// Sentry
import * as Sentry from '@sentry/react';

// GQL
import { client } from '../client';

// Queries
import {
  GET_ACCIDENT_BY_ID,
  GET_CONDITIONS_DRIVING_BY_ID,
  GET_IMAGE_BY_ID,
  GET_LUMINOSITY_BY_ID,
  GET_MEDIUM_KNOWLEDGE_BY_ID,
  GET_SURFACE_CONDITION_BY_ID,
  GET_SURVEILLANCE_BODY_BY_ID,
  GET_WEATHER_BY_ID,
} from '../queries/accidents/accidents';
import {
  GET_VEHICLE_BY_ID,
  GET_VEHICLE_OCCUPANT_BY_ID,
  // GET_VEHICLE_OCCUPANT_BY_ID,
} from '../queries/vehicles';
import { GET_CONCESSIONS, GET_ORDER_BY_ID } from '../queries/orders/orders';
import {
  FETCH_CAPITOLS,
  FETCH_SUB_CAPITOLS,
  GET_CAPITOL_BY_ID,
  GET_SUB_CAPITOL_BY_ID,
} from '../queries/orders/predefinedData';
import {
  GET_CONCESSION_BY_ID,
  GET_ROADS,
  GET_ROAD_BY_ID,
} from '../queries/geoPositions/roads';
import { GET_TEAM_BY_ID } from '../queries/users';
import { GET_ATTENTION_ACCIDENT_BY_ID } from '../queries/accidents/attentionAccidents';

// Utils
import { pkFormatter } from '../../utils/transform';

export const selectAccidentByOrderId = async (orderId: string) => {
  try {
    const {
      data: { order },
    } = await client.query({
      query: GET_ORDER_BY_ID,
      variables: { id: orderId },
    });

    const orderExpeditionParent = await client.query({
      query: GET_ORDER_BY_ID,
      variables: {
        id: order.parentExpeditionOrderIDs[0],
      },
    });

    const {
      data: { accident },
    } = await client.query({
      query: GET_ACCIDENT_BY_ID,
      variables: {
        id: order.accidentIDs[0],
      },
    });

    const [
      {
        data: { capitols },
      },
      {
        data: { concessions },
      },
      {
        data: { roads },
      },
      {
        data: { subCapitols },
      },
    ] = await Promise.all([
      client.query({
        query: FETCH_CAPITOLS,
      }),
      client.query({
        query: GET_CONCESSIONS,
      }),
      client.query({
        query: GET_ROADS,
      }),
      client.query({
        query: FETCH_SUB_CAPITOLS,
      }),
    ]);

    let dataSurveillance;

    if (accident.surveillanceBodyIDs.length > 0) {
      const {
        data: { surveillanceBody },
      } = await client.query({
        query: GET_SURVEILLANCE_BODY_BY_ID,
        variables: {
          id: accident.surveillanceBodyIDs[0],
        },
      });
      dataSurveillance = surveillanceBody;
    }

    const [
      {
        data: { attentionAccident },
      },
      {
        data: { conditionsDriving },
      },
      {
        data: { concession },
      },
      {
        data: { luminosity },
      },
      {
        data: { mediumKnowledge },
      },
      {
        data: { road },
      },
      {
        data: { surfaceCondition },
      },
      {
        data: { weather },
      },
      {
        data: { capitol },
      },
      {
        data: { subCapitol },
      },
      vehicles,
      images,
    ] = await Promise.all([
      client.query({
        query: GET_ATTENTION_ACCIDENT_BY_ID,
        variables: {
          id: accident.attentionAccidentIDs[0],
        },
      }),
      client.query({
        query: GET_CONDITIONS_DRIVING_BY_ID,
        variables: {
          id: accident.conditionsDrivingIDs[0],
        },
      }),
      client.query({
        query: GET_CONCESSION_BY_ID,
        variables: {
          id: accident.concessionIDs[0],
        },
      }),
      client.query({
        query: GET_LUMINOSITY_BY_ID,
        variables: {
          id: accident.luminosityIDs[0],
        },
      }),
      client.query({
        query: GET_MEDIUM_KNOWLEDGE_BY_ID,
        variables: {
          id: accident.mediumKnowledgeIDs[0],
        },
      }),
      client.query({
        query: GET_ROAD_BY_ID,
        variables: {
          id: accident.roadIDs[0],
        },
      }),
      client.query({
        query: GET_SURFACE_CONDITION_BY_ID,
        variables: {
          id: accident.surfaceConditionIDs[0],
        },
      }),
      client.query({
        query: GET_WEATHER_BY_ID,
        variables: {
          id: accident.weatherIDs[0],
        },
      }),
      client.query({
        query: GET_CAPITOL_BY_ID,
        variables: {
          id: order.capitolIDs[0],
        },
      }),
      client.query({
        query: GET_SUB_CAPITOL_BY_ID,
        variables: {
          id: order.subCapitolIDs[0],
        },
      }),
      Promise.all(
        accident.vehicleIDs.map(async (vehicleID: string) => {
          const {
            data: { vehicle },
          } = await client.query({
            query: GET_VEHICLE_BY_ID,
            variables: {
              id: vehicleID,
              isTrailer: false,
            },
          });
          if (
            vehicle !== null &&
            !vehicle.isTrailer &&
            vehicle.vehicleOccupantIDs.length > 0
          ) {
            const {
              data: { vehicleOccupant },
            } = await client.query({
              query: GET_VEHICLE_OCCUPANT_BY_ID,
              variables: {
                id: vehicle.vehicleOccupantIDs[0],
              },
            });

            return {
              ...vehicle,
              numberOccupants: vehicleOccupant.numberOccupants,
            };
          }
          return vehicle;
        })
      ),
      Promise.all(
        order?.imageIDs?.map(async (imageID: string) => {
          const {
            data: { image },
          } = await client.query({
            query: GET_IMAGE_BY_ID,
            variables: {
              id: imageID,
            },
          });
          return image;
        })
      ),
    ]);

    let team;

    if (accident?.teamIDs?.[0]) {
      const {
        data: { team: teamRaw },
      } = await client.query({
        query: GET_TEAM_BY_ID,
        variables: {
          id: accident.teamIDs[0],
        },
      });

      team = teamRaw;
    }

    return {
      id: accident.id,
      direction: accident.direction,
      lane: accident.lane,
      laneCutting: accident.laneCutting,
      margin: accident.margin,
      pkInit: pkFormatter(accident.pkInitKm, accident.pkInitMeter),
      pkEnd: pkFormatter(accident.pkEndKm, accident.pkEndMeter),
      policeReport: accident.policeReport,
      policeReportNumber: accident?.policeReportNumber,
      roadConditionDescription: accident.roadConditionDescription,
      track: accident.track,
      concession: concession.name,
      conditionsDriving: conditionsDriving.name,
      luminosity: luminosity.name,
      mediumKnowledge: mediumKnowledge.name,
      road: road.name,
      surfaceCondition: surfaceCondition.name,
      surveillanceBody: dataSurveillance?.name,
      team: team?.name,
      vehicles: vehicles,
      weather: weather.name,
      orderId: order.id,
      cotic: order.cotic,
      description: order.description,
      state: order.state,
      type: order.type,
      tag: order.specialTag,
      registerInitDate: order.registerInitDate,
      capitol: capitol.id,
      subCapitol: subCapitol.id,
      capitols: capitols,
      concessions: concessions,
      roadsValues: roads,
      subCapitolsValues: subCapitols,
      noticeConservationCenterDate:
        attentionAccident?.noticeConservationCenterDate,
      arrivalConservationCenterDate:
        attentionAccident?.arrivalConservationCenterDate,
      departureConservationCenterDate:
        attentionAccident?.departureConservationCenterDate,
      noticeControlCenterDate: attentionAccident?.noticeControlCenterDate,
      arrivalControlCenterDate: attentionAccident?.arrivalControlCenterDate,
      departureControlCenterDate: attentionAccident?.departureControlCenterDate,
      noticeSquadBoysDate: attentionAccident?.noticeSquadBoysDate,
      arrivalSquadBoysDate: attentionAccident?.arrivalSquadBoysDate,
      departureSquadBoysDate: attentionAccident?.departureSquadBoysDate,
      noticeAmbulanceCraneAssistanceDate:
        attentionAccident?.noticeAmbulanceCraneAssistanceDate,
      arrivalAmbulanceCraneAssistanceDate:
        attentionAccident?.arrivalAmbulanceCraneAssistanceDate,
      departureAmbulanceCraneAssistanceDate:
        attentionAccident?.departureAmbulanceCraneAssistanceDate,
      noticeRoadTeamDate: attentionAccident?.noticeRoadTeamDate,
      arrivalRoadTeamDate: attentionAccident?.arrivalRoadTeamDate,
      departureRoadTeamDate: attentionAccident?.departureRoadTeamDate,
      noticeOtherDate: attentionAccident?.noticeOtherDate,
      arrivalOtherDate: attentionAccident?.arrivalOtherDate,
      departureOtherDate: attentionAccident?.departureOtherDate,
      otherName: attentionAccident?.otherName,
      images: images,
      damages: accident.damages,
      stateOrderExpeditionParent: orderExpeditionParent.data.order.state,
    };
  } catch (err) {
    Sentry.captureException(err);
    console.log('> Error getting the accident data', err);
  }
};

export const selectAccidentOrders = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  accidents: any[] = [],
  reFetch = false
) => {
  return Promise.all(
    accidents.map(async (accident) => {
      const {
        data: { order },
      } = await client.query({
        query: GET_ORDER_BY_ID,
        variables: {
          id: accident.orderIDs[0],
        },
        fetchPolicy: reFetch ? 'network-only' : 'cache-first',
      });

      const [
        {
          data: { concession },
        },
        {
          data: { mediumKnowledge },
        },
        {
          data: { weather },
        },
        {
          data: { road },
        },
      ] = await Promise.all([
        client.query({
          query: GET_CONCESSION_BY_ID,
          variables: {
            id: accident.concessionIDs[0],
          },
          fetchPolicy: reFetch ? 'network-only' : 'cache-first',
        }),
        client.query({
          query: GET_MEDIUM_KNOWLEDGE_BY_ID,
          variables: {
            id: accident.mediumKnowledgeIDs[0],
          },
          fetchPolicy: reFetch ? 'network-only' : 'cache-first',
        }),
        client.query({
          query: GET_WEATHER_BY_ID,
          variables: {
            id: accident.weatherIDs[0],
          },
          fetchPolicy: reFetch ? 'network-only' : 'cache-first',
        }),
        client.query({
          query: GET_ROAD_BY_ID,
          variables: {
            id: accident.roadIDs[0],
          },
          fetchPolicy: reFetch ? 'network-only' : 'cache-first',
        }),
      ]);

      return {
        id: order?.id,
        cotic: order?.cotic.split('.'),
        registerInitDate: order.registerInitDate,
        creationDate: order.creationDate,
        concession: concession.name,
        road: road.name,
        description: order?.description,
        pkInit: `${accident.pkInitKm}+${accident.pkInitMeter}`,
        pkEnd: `${accident.pkEndKm}+${accident.pkEndMeter}`,
        weather: weather.name,
        mediumKnowledge: mediumKnowledge.name,
        accidentID: accident.id,
        state: order.state,
      };
    })
  );
};

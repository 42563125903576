import { useAsyncValue, useSubmit } from 'react-router-dom';
import React from 'react';

// Components Styled
import { Container } from './styles';

// Components
import NewWorkOrderForm from '../../../../components/forms/NewWorkOrderForm';

// Utils
import { createFormDataFromValues } from '../../../../utils/forms';

// types
import { type WorkOrder } from '../../../../types/workOrder';
import { formOrderNaming } from '../../../../components/forms/NewWorkOrderForm/constants';

export default function NewCampaignPage(): JSX.Element {
  const loaderData = useAsyncValue() as WorkOrder;
  const submit = useSubmit();

  const onFormSubmit = async (values: WorkOrder) => {
    const formData = createFormDataFromValues(values);
    submit(formData, {
      method: 'POST',
      action: '../summary',
    });
  };

  return (
    <Container>
      <NewWorkOrderForm
        onFormSubmit={onFormSubmit}
        initialData={loaderData}
        namingPersistForm={formOrderNaming.NEW_CAMPAIGN_FORM}
        isCampaign={true}
        titleBasicInfo={'Informació bàsica'}
      />
    </Container>
  );
}

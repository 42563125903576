export type TableProps = {
  data?: VehiclesInvolvedType[];
};

export type ChildVehicleType = {
  vehicle?: string;
  plate: string;
  insurance: string;
  policyNumber: string;
  isTrailer?: boolean;
  id: string;
  model?: string;
  numberOccupants?: string;
};

export type VehiclesInvolvedType = {
  vehicle?: string;
  plate: string;
  damage?: string;
  model?: string;
  insurance: string;
  policyNumber: string;
  numberOccupants?: string | number;
  isTrailer?: boolean;
  parentVehicleIDs: string[];
  childVehicleIDs: string[];
  id: string;
  childVehicle: ChildVehicleType[];
};

type columnIDTypes = { [key: string]: { id: string; label: string } };

export const columnNames: columnIDTypes = {
  vehicle: {
    id: 'vehicle',
    label: 'Vehicle',
  },
  licensePlate: {
    id: 'plate',
    label: 'Matrícula',
  },
  model: {
    id: 'model',
    label: 'Model',
  },
  cia: {
    id: 'insurance',
    label: 'CIA Asse.',
  },
  policy: {
    id: 'policyNumber',
    label: 'Núm. Poli.',
  },
  numberOccupants: {
    id: 'numberOccupants',
    label: 'Personas',
  },
};

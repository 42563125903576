import { gql } from '@apollo/client';

export const CREATE_NEW_AUX_MACHINERY = gql`
  mutation CreateAuxMachine($input: NewAuxMachine!) {
    createAuxMachine(input: $input) {
      id
      auxMachineId
      description
      auxMachineTypeIDs
      measureTypeIDs
    }
  }
`;

/**
 * @function getCurrentModal:
 * @description Selects the current modal being played.
 * @param state
 */

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getCurrentModal = (state: any) => state.modal;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getCurrentRowDataModal = (namingForm: string) => (state: any) =>
  state.selectedDataTableModal[namingForm] ?? {};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const selectedOT = (namingForm: string) => (state: any) =>
  state.selectedDataTableModal[namingForm]?.data ?? {};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getCoticOTSelectedGV = (state: any) => state.coticOTSelected;

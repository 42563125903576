import React from 'react';
import { useAsyncValue, useNavigate, useSubmit } from 'react-router-dom';

// Components Styled
import { Container, DuplicateTag } from './styles';

//Components

// Utils
import { createFormDataFromValues } from '../../../../../utils/forms';

// Types and Const
import NewCommunicationForm from '../../../../../components/forms/NewCommunicationForm';
import { NewCommunication } from '../../../../../types/communication';
import { formNaming } from '../../../../../components/forms/NewCommunicationForm/constants';
import { variant } from '../../../../../components/display/SpecialTag/types';

// Store
import { tabsType } from '../../../../../state/reducers/ui/tabs';

export default function NewCommunicationPage(): JSX.Element {
  const loaderData = useAsyncValue() as NewCommunication;
  const submit = useSubmit();
  const navigate = useNavigate();

  const onFormSubmit = async (values: NewCommunication) => {
    const formData = createFormDataFromValues(values);
    submit(formData, {
      method: 'POST',
      action: '../summary',
    });
  };

  const closeTab = () => {
    sessionStorage.removeItem(formNaming.NEW_DUPLICATE_COMMUNICATION_FORM);
    navigate('..');
  };

  return (
    <Container>
      <DuplicateTag variant={variant.Duplicate} onClick={closeTab} />
      <NewCommunicationForm
        isNecessaryTagSpecial={false}
        initialData={loaderData}
        onFormSubmit={onFormSubmit}
        namingPersistForm={formNaming.NEW_DUPLICATE_COMMUNICATION_FORM}
        namingTabsType={tabsType.DUPLICATE_COMMUNICATION}
      />
    </Container>
  );
}

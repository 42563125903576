import React, { useState, useCallback } from 'react';

// Styled Components
import {
  ContainerTab,
  InputContainer,
  Input,
  PencilIcon,
  FiguresIcon,
  TextIcon,
  EraserIcon,
  FiguresContainer,
  CircleIcon,
  SquareIcon,
  InputFigureContainer,
  ClearIcon,
  SelectionIcon,
} from './styles';

// Types
import { SketchTabProps, ButtonsTab, ButtonVariant } from './types';

export const SketchTab = ({
  onAddRectangle,
  onAddCircle,
  onAddText,
  removeAll,
  setDraw,
  removeFigure,
  ...props
}: SketchTabProps): JSX.Element => {
  const [selected, setSelected] = useState<ButtonsTab>();
  const [isDisplayedFigures, setIsDisplayedFigures] = useState(false);
  const [isDisplayedEraser, setIsDisplayedEraser] = useState(false);
  const [isDrawing, setIsDrawing] = useState(true);

  setDraw(isDrawing);

  const buttons = ['pencil', 'figures', 'text', 'eraser'];

  const handlePencil = useCallback(() => {
    setIsDrawing(true);
    setIsDisplayedFigures(false);
    setIsDisplayedEraser(false);
  }, [isDisplayedEraser, isDisplayedFigures, setDraw]);

  const handleFigures = useCallback(() => {
    setIsDrawing(false);
    setIsDisplayedFigures(!isDisplayedFigures);
    setIsDisplayedEraser(false);
  }, [isDisplayedFigures, isDisplayedEraser, setDraw]);

  const handleClickSquare = useCallback(() => {
    onAddRectangle();
    setIsDisplayedFigures(false);
  }, [isDisplayedFigures]);

  const handleClickCircle = useCallback(() => {
    onAddCircle();
    setIsDisplayedFigures(false);
  }, [isDisplayedFigures]);

  const handleText = useCallback(() => {
    setIsDrawing(false);
    onAddText();
    setIsDisplayedFigures(false);
    setIsDisplayedEraser(false);
  }, [isDisplayedEraser, isDisplayedFigures, setDraw]);

  const handleEraser = useCallback(() => {
    setIsDrawing(false);
    setIsDisplayedFigures(false);
    setIsDisplayedEraser(!isDisplayedEraser);
  }, [isDisplayedEraser, isDisplayedFigures, setDraw]);

  const handleClick = (button: ButtonsTab) => {
    setSelected(button);
    switch (button) {
      case ButtonVariant.PENCIL:
        handlePencil();
        break;
      case ButtonVariant.FIGURES:
        handleFigures();
        break;
      case ButtonVariant.TEXT:
        handleText();
        break;
      case ButtonVariant.ERASER:
        handleEraser();
        break;
    }
  };

  const renderIcon = (button: ButtonsTab) => {
    switch (button) {
      case ButtonVariant.PENCIL:
        return <PencilIcon />;
      case ButtonVariant.FIGURES:
        return <FiguresIcon />;
      case ButtonVariant.TEXT:
        return <TextIcon />;
      case ButtonVariant.ERASER:
        return <EraserIcon />;
    }
  };

  return (
    <ContainerTab {...props}>
      {buttons.map((button, index) => {
        return (
          <InputContainer
            key={index}
            onClick={() => handleClick(button as ButtonsTab)}
            isSelected={
              !selected ? button === ButtonVariant.PENCIL : button === selected
            }
          >
            <Input
              type={'radio'}
              name={'sketch'}
              checked={
                !selected
                  ? button === ButtonVariant.PENCIL
                  : selected === button
              }
              readOnly
            />
            {renderIcon(button as ButtonsTab)}
            {isDisplayedFigures && index === 1 && (
              <FiguresContainer>
                <InputFigureContainer onClick={handleClickCircle}>
                  <CircleIcon className="icon" />
                </InputFigureContainer>
                <InputFigureContainer onClick={handleClickSquare}>
                  <SquareIcon className="icon" />
                </InputFigureContainer>
              </FiguresContainer>
            )}
            {isDisplayedEraser && index === 3 && (
              <FiguresContainer>
                <InputFigureContainer onClick={removeFigure}>
                  <SelectionIcon className="icon" />
                </InputFigureContainer>
                <InputFigureContainer onClick={removeAll}>
                  <ClearIcon className="icon" />
                </InputFigureContainer>
              </FiguresContainer>
            )}
          </InputContainer>
        );
      })}
    </ContainerTab>
  );
};

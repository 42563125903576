import React, { useCallback } from 'react';
import { useAsyncValue, useRevalidator } from 'react-router-dom';

// Components
import { VehiclesManagementTable } from '../../../../components/display/tables/formTables/managementTables/VehiclesManagementTable';
import { AddVehicleManagementForm } from '../../../../components/forms/AddVehicleManagementForm';

// Types
import { VehicleManagementLoader } from './types';
import { VehicleManagement } from '../../../../components/forms/AddVehicleManagementForm/types';

// Styles
import { Container, VehiclesManagementSection } from './styles';

// Constants
import { formVehicleManagementNaming } from '../../../../components/forms/AddVehicleManagementForm/constants';

// GQL
import { client } from '../../../../gql/client';
import {
  CREATE_NEW_VEHICLE,
  UPDATE_VEHICLE,
} from '../../../../gql/mutations/vehicles';
import { NEW_VEHICLE } from '../../../../gql/fragments/vehicles';
import { GET_CONCESSION_BY_ID } from '../../../../gql/queries/geoPositions/roads';

export default function VehiclesManagementPage(): JSX.Element {
  const loaderData = useAsyncValue() as VehicleManagementLoader;
  const revalidator = useRevalidator();

  const allVehiclesPlates = [
    ...loaderData.activeVehicles,
    ...loaderData.inactiveVehicles,
  ].map((vehicle) => vehicle.plate);

  const onReFetchData = useCallback(() => {
    revalidator.revalidate();
  }, []);

  const onFormSubmit = async (data: VehicleManagement) => {
    if (allVehiclesPlates.some((plate) => plate === data.plate)) {
      return 'La placa ja existeix';
    }

    await client.mutate({
      mutation: CREATE_NEW_VEHICLE,
      variables: {
        input: {
          plate: data.plate,
          model: data.model,
          vehicleCategoryIDs: [data.category],
          isActive: true,
          isTrailer: data.isTrailer,
          isInternal: true,
          concessionId: data.concession,
        },
      },

      update(cache, { data: { createVehicle } }) {
        cache.modify({
          fields: {
            vehicles(existingVehicles = []) {
              const createVehicleRef = client.writeFragment({
                fragment: NEW_VEHICLE,
                data: createVehicle,
              });

              return [...existingVehicles, createVehicleRef];
            },
          },
        });
      },
    });

    await client.query({
      query: GET_CONCESSION_BY_ID,
      variables: {
        id: data.concession,
      },
      fetchPolicy: 'network-only',
    });

    onReFetchData();
  };

  const onAddActiveVehicle = async (id: string) => {
    await client.mutate({
      mutation: UPDATE_VEHICLE,
      variables: {
        input: {
          id: id,
          isActive: false,
        },
      },
    });

    onReFetchData();
  };

  const onAddInactiveVehicle = async (id: string) => {
    await client.mutate({
      mutation: UPDATE_VEHICLE,
      variables: {
        input: {
          id: id,
          isActive: true,
        },
      },
    });
    onReFetchData();
  };
  return (
    <Container>
      <VehiclesManagementSection>
        <AddVehicleManagementForm
          initialData={loaderData}
          onFormSubmit={onFormSubmit}
          namingPersistForm={
            formVehicleManagementNaming.NEW_VEHICLE_MANAGEMENT_FORM
          }
        />
      </VehiclesManagementSection>
      <VehiclesManagementSection>
        <VehiclesManagementTable
          onAddActiveVehicle={onAddActiveVehicle}
          onAddInactiveVehicle={onAddInactiveVehicle}
          data={{
            activeVehicles: loaderData.activeVehicles,
            inactiveVehicles: loaderData.inactiveVehicles,
          }}
        />
      </VehiclesManagementSection>
    </Container>
  );
}

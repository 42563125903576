import { Role } from '../../../../types/auth';

export const data = [
  {
    title: 'Vista general d´O.T.',
    path: '',
    permissions: [Role.Ceconsa, Role.SuperAdmin, Role.Tablet, Role.RoomTeam],
    subItems: [
      {
        text: 'Nova O.T',
        path: 'new-order',
        permissions: [Role.Ceconsa, Role.SuperAdmin, Role.RoomTeam],
      },
      {
        text: 'Duplicar O.T.',
        path: 'duplicate-order',
        permissions: [Role.Ceconsa, Role.SuperAdmin, Role.RoomTeam],
      },
    ],
  },
  {
    title: 'Buscar i crear històrics',
    path: 'search-create-historical-order',
    permissions: [Role.Ceconsa, Role.SuperAdmin],
  },
  {
    title: 'Campanyes',
    path: 'campaign',
    permissions: [Role.Ceconsa, Role.SuperAdmin],
    subItems: [
      {
        text: 'Nova campanya',
        path: 'campaign/new-campaign',
        permissions: [Role.Ceconsa, Role.SuperAdmin],
      },
    ],
  },
  {
    title: 'Validacio d’actuacions',
    path: 'actions-validation',
    permissions: [Role.Ceconsa, Role.SuperAdmin],
  },
];
